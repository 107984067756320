import React, {useEffect, useState} from 'react';
import {Box, Button, FormHelperText, Grid, InputBase, InputLabel, Snackbar, Typography} from "@material-ui/core";
import {CustomTab, CustomTabs} from "../../../../commons/components/tabs";
import {BaseResponse} from "../../../../../api/baseService";
import {AlertMessage, CustomAlert} from "../../../../commons/components/alert";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ThongTinChungGPComponent from "../Modal/ThongTinChungGPComponent";
import {TapDinhKem} from "../Modal/tapDinhKem";
import {GiayPhepATTP, TinhTrangString} from "../../../../../api/VSATTP/Model/VSATTPModel";
import {BienDongTrangThaiGPVSATTPDTO} from "../../../../../api/VSATTP/Model/BienDongTrangThaiGPVSATTPDTO";
import {BienDongTrangThaiGPVSATTPService} from '../../../../../api/VSATTP/Services/BienDongTrangThaiGPVSATTPService';
import {useHistory} from 'react-router';
import StringUtil from '../../../../utils/stringUtils';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import {hideLoading, LoadingAction, showLoading} from '../../../../../redux/actions/applicationAction';
import {ConfirmationDialogRaw} from '../../../../commons/components/dialog/confirmDialog';
import {LOAIBIENDONG_HOANTRA_THUHOI, TINHTRANG_HOATDONG} from '../../../../Constants/ATTPConstants';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const BienDongTrangThaiGPVSATTP_INIT: BienDongTrangThaiGPVSATTPDTO = {
    id: 0,
    giayPhepId: 0,
    soGiayPhep: '',
    ngayBienDong: 0,
    ngayQuyetDinh: 0,
    soQuyetDinh: '',
    soLan: '',
    ghiChu: "",
    noiDung: "",
    // lyDoTamNgung: "",
    // lyDoHTTamNgung: "",
    maTinhTrang: TINHTRANG_HOATDONG.ma,
    tenTinhTrang: TINHTRANG_HOATDONG.ten,
    maBienDong: LOAIBIENDONG_HOANTRA_THUHOI.ma,
    tenBienDong: LOAIBIENDONG_HOANTRA_THUHOI.ten
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
interface FormValues {
    requestDTO: BienDongTrangThaiGPVSATTPDTO,
}

const HoanTraThuHoiGPVSATTPComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [indexTab, setIndexTab] = React.useState(0);
    const [data, setData] = React.useState({} as GiayPhepATTP);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const bienDongTrangThaiGPVSATTPService = new BienDongTrangThaiGPVSATTPService();
    const [openResetData, setOpenResetData] = useState(false);
    const history = useHistory();
    const [submit, setSubmit] = useState(false);
    const [thongTinThuHoi, setThongTinThuHoi] = React.useState<BienDongTrangThaiGPVSATTPDTO>({
        giayPhepId: data?.id,
        soGiayPhep: data?.soGiayPhep,
        soLan: '',
        ngayBienDong: 0,
        ngayQuyetDinh: 0,
        soQuyetDinh: '',
        ghiChu: "",
        noiDung: "",
        id: 0,
        maTinhTrang: TINHTRANG_HOATDONG.ma,
        tenTinhTrang: TINHTRANG_HOATDONG.ten,
        maBienDong: LOAIBIENDONG_HOANTRA_THUHOI.ma,
        tenBienDong: LOAIBIENDONG_HOANTRA_THUHOI.ten
    } as BienDongTrangThaiGPVSATTPDTO);

    useEffect(() => {
        initData();
    }, []);

    const handleClickXoa = () => {
        setOpenResetData(!openResetData);
    }
    const handleConfirm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
        }
        setOpenResetData(!openResetData);
    };
    const resetData = () => {
        const dataNew: BienDongTrangThaiGPVSATTPDTO = {
            ...BienDongTrangThaiGPVSATTP_INIT,
            giayPhepId: thongTinThuHoi.giayPhepId,
            soGiayPhep: thongTinThuHoi.soGiayPhep,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            maBienDong: TinhTrangString.HOAT_DONG
        };
        setThongTinThuHoi(dataNew);

    }
    const initData = () => {
        setData(history.location.state);
        setThongTinThuHoi({
            ...thongTinThuHoi,
            giayPhepId: history.location.state.id,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            maBienDong: TinhTrangString.HOAT_DONG
        })
    }

    const submitData = async () => {
        setSubmit(true);

        if (StringUtil.isEmty(thongTinThuHoi.noiDung)) {
            return;
        }
        if (thongTinThuHoi.ngayBienDong == 0 || !thongTinThuHoi.ngayBienDong) {
            return;
        }
        setSubmit(false);
        dispatchLoading(showLoading());
        await bienDongTrangThaiGPVSATTPService.luuVaCapNhatKhoiPhuc(thongTinThuHoi).then(res => {
            handleSubmitData(res);
        }).catch(error => {

        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const handleSubmitData = (resp: BaseResponse) => {
        if (resp.isSuccess) {
            setThongTinThuHoi({ ...thongTinThuHoi, id: resp.data.id })
            showMessageAlert('Yêu cầu thực hiện thành công!');
        } else {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        }
    }
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let name = e.target.name;
        let value = isCheckbox ? e.target.checked : e.target.value;
        let dataNew = { ...thongTinThuHoi, [name]: value };
        setThongTinThuHoi(dataNew);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...thongTinThuHoi, [filedName]: date?.toDate()?.getTime() };
        setThongTinThuHoi(dataNew);
    }

    return (
        <>
            {
                <Grid container>
                    {/* <OverlayLoading /> */}
                    {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    <span>HOÀN TRẢ THU HỒI GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM</span>
                                </div>
                            </div>
                            {/* </h6>
                        <br></br>
                    </Grid> */}
                    <br />
                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab} >
                                <CustomTab label="Thông tin chung" />
                                <CustomTab label="Tập đính kèm" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {
                        indexTab === 0 &&
                        <TabPanel value={indexTab} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="grid-item">
                                    <ThongTinChungGPComponent data={data} />
                                </Grid>
                                <Grid item xs={9} className="grid-item">
                                    <InputLabel required>Lý do hoàn trả thu hồi</InputLabel>
                                    <InputBase fullWidth
                                        error={submit && StringUtil.isEmty(thongTinThuHoi.noiDung)}
                                        value={thongTinThuHoi.noiDung} name="noiDung"
                                        onChange={handleChange} />
                                    {
                                        submit && StringUtil.isEmty(thongTinThuHoi.noiDung) &&
                                        <FormHelperText className="-error"
                                        >Vui lòng nhập lý do thu hồi</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel required>Ngày hoàn trả thu hồi</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            placeholder="dd/MM/yyyy"
                                            value={thongTinThuHoi.ngayBienDong > 0 ? new Date(thongTinThuHoi.ngayBienDong) : null}
                                            error={submit && (thongTinThuHoi.ngayBienDong == 0 || !thongTinThuHoi.ngayBienDong)}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayBienDong")}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {
                                        submit && (thongTinThuHoi.ngayBienDong == 0 || !thongTinThuHoi.ngayBienDong) &&
                                        <FormHelperText className="-error" >Vui lòng chọn ngày thu hồi</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel >Ghi chú</InputLabel>
                                    <InputBase fullWidth
                                        multiline
                                        rows="4"
                                        value={thongTinThuHoi.ghiChu} name="ghiChu" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} className="gridItem">
                                    <Box marginTop="5px">
                                        <Button type="submit"
                                            onClick={submitData}
                                            variant="contained" color="primary" disableElevation
                                        > Lưu
                                                </Button>
                                        <Button variant="contained" onClick={() => handleClickXoa()} color="secondary" disableElevation> Xóa</Button>
                                        <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openResetData}
                                            title="Xóa dữ liệu"
                                            content="Bạn có đồng ý xóa?" onClose={handleConfirm}
                                        />
                                        <Button type="button" variant="contained" color="primary"
                                            onClick={(e) => history.go(-1)}
                                            style={{ fontSize: '14px', width: '100px' }}>Trở về
                                                </Button>&nbsp;&nbsp;
                                            </Box>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    }
                    {
                        indexTab === 1 &&
                        <TabPanel value={indexTab} index={1}>
                            <TapDinhKem dataList={data?.files} />
                        </TabPanel>
                    }
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                </Grid>
            }
        </>
    )
}

export default HoanTraThuHoiGPVSATTPComponent;
