import { Box, NativeSelect } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useRef } from "react";
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import { CustomInput } from "../../../commons/components/input/customInput";
import { NavLink } from 'react-router-dom';
import { GiayPhepXayDungDaCapDTO } from '../../../../api/CPXD/model/CapPhepXayDungModel';
import moment from 'moment';
import ThaoTacQuanLyGiayPhepCPXD from './ThaoTacQuanLyGiayPhepCPXD';
import { ROUTE_CPXD_CHITIETGIAYPHEP } from '../../../../routes/route-cn-cpxd';

interface TableProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    dataList: GiayPhepXayDungDaCapDTO[];
    doSearch: () => void;
}
const DanhSachGiayPhepCPXD = (props: TableProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        setTotalPage(Math.ceil(props.count / props.rowsPerPage));
        setRowsPerPage(props.rowsPerPage);
    }, [props.count, props.rowsPerPage]);


    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                            <Cell component="th" scope="row" align="center" width="15%">Loại giấy phép</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Số giấy phép</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Ngày cấp</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Ngày hết hạn</Cell>
                            <Cell component="th" scope="row" align="center" width="10%" >Chủ đầu tư</Cell>
                            <Cell component="th" scope="row" align="center">Vị trí xây dựng</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Đơn vị tư vấn</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Tình trạng</Cell>
                            <Cell component="th" scope="row" align="center" width="5%">Thao tác</Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.dataList.length > 0 ?
                                props.dataList.map((row, i) => (
                                    <Row key={row.id}>
                                        <Cell>
                                            {((props.page - 1) * rowsPerPage) + i + 1}
                                        </Cell>
                                        <Cell>
                                            <NavLink style={{ textDecoration: 'none' }} exact
                                                to={{
                                                    pathname: ROUTE_CPXD_CHITIETGIAYPHEP.duongDan,
                                                    state: { giayPhepXayDaCapId: row.id }
                                                }}>
                                                {row.tenLoaiGiayPhep}
                                            </NavLink >
                                        </Cell>
                                        <Cell>
                                            <NavLink style={{ textDecoration: 'none' }} exact
                                                to={{
                                                    pathname: ROUTE_CPXD_CHITIETGIAYPHEP.duongDan,
                                                    state: { giayPhepXayDaCapId: row.id }
                                                }}>
                                                {row.soGiayPhep}
                                            </NavLink >
                                        </Cell>
                                        <Cell>
                                            {(row.ngayCapPhep && row.ngayCapPhep !== undefined && row.ngayCapPhep !== null) ? moment(new Date(row.ngayCapPhep)).format("DD/MM/YYYY") : ""}
                                        </Cell>
                                        <Cell>
                                            {(row.ngayHetHan && row.ngayHetHan !== undefined && row.ngayHetHan !== null) ? moment(new Date(row.ngayHetHan)).format("DD/MM/YYYY") : ""}
                                        </Cell>
                                        <Cell>
                                            {row.chuDauTu}
                                        </Cell>
                                        <Cell>
                                            {row.viTriXayDung}
                                        </Cell>
                                        <Cell>
                                            {row.donViTuVan}
                                        </Cell>
                                        <Cell>
                                            {row.tenTrangThai}
                                        </Cell>
                                        <Cell align="center">
                                            <ThaoTacQuanLyGiayPhepCPXD data={row} />
                                        </Cell>
                                    </Row>
                                )) :
                                <Row>
                                    <Cell colSpan={10} scope="row" align="center"> Không có dữ liệu</Cell>
                                </Row>
                        }
                    </TableBody>
                </Table>
                {props.dataList.length > 0 &&
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium" color="primary" shape="rounded"
                            showFirstButton showLastButton onChange={props.onChangePage} />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                            <NativeSelect input={<CustomInput />} onChange={props.onChangeRowsPerPage} >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">Mẫu tin {props.page * props.rowsPerPage - props.rowsPerPage + 1} -{" "}
                            {props.count - (props.page - 1) * props.rowsPerPage < props.rowsPerPage ? props.count : props.page * props.rowsPerPage} /{" "}
                            {props.count}
                        </Box>
                    </Box>
                }
            </TableContainer>
        </>
    )
}
export default DanhSachGiayPhepCPXD;
