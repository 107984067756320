import AXIOS_INSTANCE from "..";
import { DanhMucI, SearchRequest } from "../Common/model/CommonModel";
export class LinhVucNoiCapService {
    constructor(){}
    search = (request : SearchRequest) => {
       return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-noi-cap/search', request);
    }
    getByHoatDong = (hoatDong : boolean) => {
       return AXIOS_INSTANCE.get('dkkd/dm-linh-vuc-noi-cap/get-by-hoat-dong/' + hoatDong);
    }
    addOrUpdate = (request : DanhMucI) => {
        return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-noi-cap/addOrUpdate', request);
     }
     delete = (id: number) => {
      return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-noi-cap/delete/'+ id);
    }
}
