import MomentUtils from '@date-io/moment';
import {
    FormHelperText,
    Grid,
    InputBase,
    InputLabel
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import { useEffect, useState } from 'react';
import { IFileWithMeta } from 'react-dropzone-uploader';
import AXIOS_INSTANCE from '../../../../../api';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { CAP_MOI_SX_RUOU_INIT, LichSuGiayPhepRuouDTO_INIT } from '../../../../../api/CPBLR/Init/CapMoiSXRuouInit';
import { GiayPhepSXRuou, HoSoThuLyBLR } from '../../../../../api/CPBLR/model/HoSoThuLyBLR';
import { LichSuGiayPhepRuouDTO } from '../../../../../api/CPBLR/model/LichSuGiayPhepRuouDTO';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import StringUtil from '../../../../utils/stringUtils';


interface ChiTietHoanTraHuyThongTinGPRuouProps {
    data: GiayPhepSXRuou;
    hoSo: HoSoThuLyBLR;
    submit: boolean;
    lichSu: LichSuGiayPhepRuouDTO;
    onUpdateData: (name: string, value: any) => void;
}
const ChiTietHoanTraHuyThongTinGPRuou = (props: ChiTietHoanTraHuyThongTinGPRuouProps) => {
    const [data, setData] = useState({} as GiayPhepSXRuou);
    const [hoSo, setHoSo] = useState<HoSoThuLyBLR>({ ...CAP_MOI_SX_RUOU_INIT });
    const [lichSu, setLichSu] = useState<LichSuGiayPhepRuouDTO>({ ...LichSuGiayPhepRuouDTO_INIT });
    const [submit, setSubmit] = useState(false);
    const fileServerService = new FileServerService();
    useEffect(() => {
        setData(props.data);
        setHoSo(props.hoSo);
        setSubmit(props.submit);
        setLichSu(props.lichSu);
    }, [props.data, props.hoSo, props.submit, props.lichSu]);
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...lichSu.files];
        mems = mems.concat(memData);
        props.onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let mems = [...lichSu.files];
        mems.splice(index, 1);
        props.onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && StringUtil.isNotEmpty(file.url)){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    return (
        <>
            {
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Số giấy phép</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.soGiayPhep}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.ngayCapPhep ? moment(data?.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Tên thương nhân</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{data.tenThuongNhan}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Địa chỉ trụ sở chính</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{data.diaChiDayDu}</InputLabel>
                    </Grid>

                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Điện thoại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data.dienThoaiTruSo}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Fax</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data.faxTruSo}</InputLabel>
                    </Grid>

                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Số Giấy CNĐKDN/CNĐKKD</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data.giayCNDK}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Ngày cấp lần đầu</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.ngayCap ? moment(data?.ngayCap).format("DD/MM/YYYY") : ''}</InputLabel>
                    </Grid>

                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Nơi cấp</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{data?.noiCap}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Địa chỉ sản xuất/kinh doanh</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{data?.diaChiDayDuSX}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Điện thoại của điểm SX/KD</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.dienThoaiSX}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Fax của địa điểm SX/KD</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.faxSX}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Tình trạng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.tenTinhTrang}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Lần cấp lại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo.soLan}</InputLabel>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <InputLabel >THÔNG TIN HOÀN TRẢ HỦY</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" >
                        <InputLabel>Số quyết định</InputLabel>
                        <InputBase fullWidth
                            value={lichSu.soQuyetDinh} name="soQuyetDinh" onChange={(e) => props.onUpdateData("soQuyetDinh", e.target.value)} />
                    </Grid>
                    <Grid item xs={3} className="grid-item" >
                        <InputLabel>Ngày quyết định</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                value={(lichSu.ngayQuyetDinh !== 0 && lichSu.ngayQuyetDinh && lichSu.ngayQuyetDinh !== null && lichSu.ngayQuyetDinh !== undefined) ? new Date(lichSu.ngayQuyetDinh) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => props.onUpdateData("ngayQuyetDinh", date?.toDate()?.getTime())}

                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item" >
                        <InputLabel required>Ngày thu hoàn trả hủy</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                error={submit && !(lichSu.ngayBienDong !== 0 && lichSu.ngayBienDong && lichSu.ngayBienDong !== null && lichSu.ngayBienDong !== undefined)}
                                value={(lichSu.ngayBienDong !== 0 && lichSu.ngayBienDong && lichSu.ngayBienDong !== null && lichSu.ngayBienDong !== undefined) ? new Date(lichSu.ngayBienDong) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => props.onUpdateData("ngayBienDong", date?.toDate()?.getTime())}

                            />
                        </MuiPickersUtilsProvider>
                        {
                            submit && !(lichSu.ngayBienDong !== 0 && lichSu.ngayBienDong && lichSu.ngayBienDong !== null && lichSu.ngayBienDong !== undefined) &&
                            <FormHelperText className="-error"  >Vui lòng nhập ngày hoàn trả hủy</FormHelperText>
                        }
                    </Grid>

                    <Grid item xs={12} className="grid-item" >
                        <InputLabel required>Lý do hoàn trả hủy</InputLabel>
                        <InputBase fullWidth
                            multiline
                            rows="3"
                            rowsMax="3"
                            name="noiDung"
                            error={submit && StringUtil.isNullOrEmty(lichSu.noiDung)}
                            value={lichSu.noiDung} onChange={(e) => props.onUpdateData("noiDung", e.target.value)} />
                        {
                            submit && StringUtil.isNullOrEmty(lichSu.noiDung) &&
                            <FormHelperText className="-error"  >Vui lòng nhập lý do hoàn trả hủy</FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} className="grid-item" >
                        <InputLabel >Ghi chú</InputLabel>
                        <InputBase fullWidth
                            multiline
                            rows="3"
                            rowsMax="3"
                            name="ghiChu"
                            value={lichSu.ghiChu} onChange={(e) => props.onUpdateData("ghiChu", e.target.value)} />
                    </Grid>
                    <DropZoneCustom
                        acceptFiles={ACCEPT_FILES}
                        attachFileServer={lichSu.files}
                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/ruou"}
                        handleChangeStatus={handleChangeStatus}
                        handleDeleteFileServer={handleDeleteFileServer}
                        handleDownloadFile={handleDownloadFile}
                    />
                </Grid>
            }
        </>
    )
}
export default ChiTietHoanTraHuyThongTinGPRuou;