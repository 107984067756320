import { Box, Button, Dialog, DialogContent, Grid, InputLabel, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import DateUtil from "../../../../utils/dateUtils";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { DialogTitle } from "../../../../commons/components/dialog/dialogCommons";
import { CustomInput } from "../../../../commons/components/input/customInput";
import { TepDinhKemPopper } from "../../../../commons/components/hoso/tepTin/TepDinhKemPopper";
import { Cell, Row } from "../../../../commons/components/table/tableCommons";
import { HoSoCuaToiMgtService, VanBanDuThaoDTO, VanBanDuThaoDTO_INIT } from "../../../../../api/HoSoCuaToi/HoSoCuaToiMgtService";
import { HoSoThuLyDTO } from "../../../../../api/DKKD/model/hoSoThuLyDTO";
import moment from "moment";
import StringUtil from "../../../../utils/stringUtils";

interface DanhSachDuThaoModalProps {
    show: boolean;
    onToggle: () => void;
    dataFromBD: HoSoThuLyDTO;
}
const ShowSoCMNDModal = (props: DanhSachDuThaoModalProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [show, setShow] = useState(false);
    const [rows, setRows] = useState<VanBanDuThaoDTO[]>([]);
    const { onToggle, dataFromBD } = props;
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShow(props.show);
        if (props.show) {
            initData();
        }
    }, [props.show]);
    const initData = () => {
    };
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
    };
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    };
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        dispatchLoading(showLoading());
    };
    const handleToggle = () => {
        setShowModal(!showModal);
    };
    const reloadData = () => {
        initData();
    };
    return (
        <>
            <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show}>
                <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                    Thông tin giấy phép
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid container>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Số giấy chứng nhận</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel>{props.dataFromBD.soGiayPhep}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Ngày cấp</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel>{(props.dataFromBD.ngayCapPhep != null && props.dataFromBD.ngayCapPhep != null && props.dataFromBD.ngayCapPhep > 0) ? moment(props.dataFromBD.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Cơ quan cấp</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel>{props.dataFromBD.tenQuan}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel style={{fontSize: '15px', marginRight: '0.5rem', fontWeight: 'bold'}}>Tình trạng</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel style={{color: StringUtil.setColor(props.dataFromBD.tinhTrang)}}>{props.dataFromBD.tenTinhTrang}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Tên hộ kinh doanh/bảng hiệu</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel>{props.dataFromBD.tenHoKinhDoanh}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Địa điểm kinh doanh</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel>{props.dataFromBD.diaChiDayDu}</InputLabel>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <Box marginTop="5px">
                                    <Button variant="contained" color="default" disableElevation onClick={onToggle}>
                                        Đóng
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default ShowSoCMNDModal;
