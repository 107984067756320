import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Pageable } from "../../App/commons/components/table/commonTableChuyenNganhLazy";
import { MarkerDTO, Pagination } from "../../componentMap";
import { Collapse, Typography } from "@material-ui/core";
import TraCuuBanDo from "../../componentMap/index";
import { BangDoService } from "../../api/BangDo/BangDoService";
export interface WrapperMapTraCuuProps { }

const WrapperMapTraCuu: React.FC<WrapperMapTraCuuProps> = ({ }) => {
    const title = "TRA CỨU BẢN ĐỒ";
    const [type, setType] = React.useState<Number>();

    React.useEffect(() => {
        document.title = title.toLowerCase().charAt(0).toUpperCase() + title.toLowerCase().slice(1);
    }, []);
    async function handleSubmitSearch(values: any, pageParams: Pageable) {
        const data = await new BangDoService().search({
            ...values, pageSize: pageParams.rowsPerPage,
            pageNumber: pageParams.page
        });
        return Promise.resolve({
            rows: data.data.data,
            pageSize: pageParams.rowsPerPage,
            pageNumber: pageParams.page,
            total: data.data.total,
        } as unknown as Pagination<MarkerDTO[]>);
    }

    function renderDetails(item: MarkerDTO, showDetail: boolean): JSX.Element {
        return (
            <>
                <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                    <strong>Số giấy chứng nhận </strong> <span>{item.soGiayPhep}</span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                    <strong>Tên cơ sở</strong> <span>{item.tenCoSo}</span>
                </Typography>
                <Collapse in={showDetail}>
                    <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                        <strong>Địa chỉ</strong> <span>{item.fullDiaChi}</span>
                    </Typography>
                </Collapse>
            </>
        );
    }

    async function handleSaveLatLong(values: any) {
        return Promise.resolve({ ...values });
    }

    return <TraCuuBanDo id={99} handleSubmitSearch={handleSubmitSearch} handleSaveLatLong={handleSaveLatLong} renderDetails={renderDetails} />;
};

export default WrapperMapTraCuu;
