import React, { useEffect, useState } from 'react';
import { GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { Box, Grid, InputLabel, FormHelperText, InputBase, TextareaAutosize } from '@material-ui/core';
import StringUtil from '../../../../utils/stringUtils';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { CapPhepXayDungService } from '../../../../../api/CPXD/CapPhepXayDungService';
import { TINHTRANG_HOATDONG } from '../../../../Constants/CapPhepXayDungConstants';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import AXIOS_INSTANCE from '../../../../../api';
interface NhaORiengLeGiaHanNoiDungProps {
    data: GiayPhepXayDungDTO;
    submit: boolean;
    onUpdateData: (name: string, value: any) => void;
    loadGiayPhepDaCap: (soGiayPhepCu: string) => void;
}


const NhaORiengLeGiaHanNoiDungComponent = (props: NhaORiengLeGiaHanNoiDungProps) => {
    const [data, setData] = React.useState({} as GiayPhepXayDungDTO);
    const [submit, setSubmit] = React.useState(false);
    const { onUpdateData, loadGiayPhepDaCap } = props;
    const [dataAutocomple, setDataAutocomple] = useState<GiayPhepXayDungDTO[]>(new Array<GiayPhepXayDungDTO>());
    const capPhepXayDungService = new CapPhepXayDungService();
    const fileServerService = new FileServerService();
    useEffect(() => {
        setData(props.data);
        setSubmit(props.submit);
    }, [props.data, props.submit, props.onUpdateData]);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }
    const handleSelectGiayPhepDaCap = (newValue: GiayPhepXayDungDTO) => {
        onUpdateData('soGiayPhepCu', newValue.soGiayPhep);
        loadGiayPhepDaCap(newValue.soGiayPhep);
    }
    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        onUpdateData('soGiayPhepCu', soGiayPhepCuNew);
        capPhepXayDungService.getAutocompleteBySoGiayPhep_MaTrangThai_PageSize(soGiayPhepCuNew, "", 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setDataAutocomple([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const setThongTinGiaHanParam = (event: any) => {
        setSubmit(false);
        let name = event.target.name;
        let value = event.target.value;
        let thongTinGiaHanGiayPhepXayDungDTO = { ...data.thongTinGiaHanGiayPhepXayDungDTO, [name]: value };
        onUpdateData('thongTinGiaHanGiayPhepXayDungDTO', thongTinGiaHanGiayPhepXayDungDTO);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        setSubmit(false);
        let thongTinGiaHanGiayPhepXayDungDTO = { ...data.thongTinGiaHanGiayPhepXayDungDTO, [filedName]: date?.toDate()?.getTime() };
        onUpdateData('thongTinGiaHanGiayPhepXayDungDTO', thongTinGiaHanGiayPhepXayDungDTO);
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
   

    return (
        <>
            {
                <Box padding={0}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Số giấy phép </InputLabel>
                                    <Autocomplete id="soGiayPhepCu"
                                        options={dataAutocomple}
                                        value={data}
                                        noOptionsText={"Không có dữ liệu"}
                                        getOptionLabel={(option) => option.soGiayPhep}
                                        onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                        renderInput={(params) => {
                                            return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                                <InputBase fullWidth
                                                    error={submit && (StringUtil.isNullOrEmty(data.maLoaiGiayPhep) || StringUtil.isNullOrEmty(data.soGiayPhepCu))}
                                                    {...params.inputProps}
                                                    value={data.soGiayPhepCu}
                                                    onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}
                                                />
                                            </div>
                                        }}
                                    />
                                    {
                                        submit && (StringUtil.isNullOrEmty(data.maLoaiGiayPhep) || StringUtil.isNullOrEmty(data.soGiayPhepCu)) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập số giấy phép </FormHelperText>
                                    }

                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Ngày cấp phép </InputLabel>
                                    <InputBase fullWidth
                                        value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu !== null) ? moment(data.ngayCapPhepCu).format("DD/MM/YYYY") : ''} disabled name="ngayCapPhepCu" />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Loại giấy phép </InputLabel>
                                    <InputBase fullWidth
                                        value={data.tenLoaiGiayPhep} disabled name="tenLoaiGiayPhep" />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Phân loại xây dựng </InputLabel>
                                    <InputBase fullWidth
                                        value={data.tenPhanLoaiCongTrinh} disabled name="tenLoaiGiayPhep" />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel >Địa chỉ công trình </InputLabel>
                                    <InputBase fullWidth
                                        value={(data.soNha ? data.soNha + ", " : "") + (data.tenDuong ? data.tenDuong + ", " : "") + (data.tenPhuong ? data.tenPhuong + ", " : "") + (data.tenHuyen ? data.tenHuyen + ", " : "") + data.tenTinh} disabled name="tenLoaiGiayPhep" />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel >Thông tin gia hạn </InputLabel>
                                    <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Số quyết định </InputLabel>
                                    <InputBase fullWidth
                                        value={data.thongTinGiaHanGiayPhepXayDungDTO?.soQuyetDinh} name="soQuyetDinh" onChange={setThongTinGiaHanParam} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Ngày quyết định </InputLabel>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.thongTinGiaHanGiayPhepXayDungDTO?.ngayQuyetDinh  != null && data.thongTinGiaHanGiayPhepXayDungDTO?.ngayQuyetDinh !== undefined && data.thongTinGiaHanGiayPhepXayDungDTO?.ngayQuyetDinh != 0) ? new Date(data.thongTinGiaHanGiayPhepXayDungDTO?.ngayQuyetDinh) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayQuyetDinh')}

                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Gia hạn từ</InputLabel>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHan  != null &&  data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHan !== undefined && data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHan != 0) ? new Date(data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHan) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        error={submit && (data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHan === 0 || data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHan === undefined)}
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayGiaHan')}
                                    />
                                    {
                                        submit && (data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHan === 0 || data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHan === undefined) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập ngày gia hạn từ</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Ngày gia hạn đến </InputLabel>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHanDen  != null && data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHanDen !== undefined && data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHanDen != 0) ? new Date(data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHanDen) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        error={submit && (data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHanDen === 0 || data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHanDen === undefined)}
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayGiaHanDen')}
                                    />
                                    {
                                        submit && (data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHanDen == 0 || data.thongTinGiaHanGiayPhepXayDungDTO?.ngayGiaHanDen === undefined) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập ngày gia hạn đến</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel >Ghi chú </InputLabel>
                                    <InputBase fullWidth
                                        multiline
                                        rows="8"
                                        name="ghiChu"
                                        value={data.thongTinGiaHanGiayPhepXayDungDTO?.ghiChu} onChange={setThongTinGiaHanParam} />
                                </Grid>

                                <DropZoneCustom
                                    acceptFiles={ACCEPT_FILES}
                                    attachFileServer={data.files}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/cpxd"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Box>
                    </MuiPickersUtilsProvider>

                </Box>
            }
        </>
    )
}
export default NhaORiengLeGiaHanNoiDungComponent;