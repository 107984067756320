import AXIOS_INSTANCE from "../..";
import { DanhMucI, SearchRequest } from "../../Common/model/CommonModel";

export class DanhMucDonViCapPhepService {
    constructor(){}
    search = (request : SearchRequest) => {
       return AXIOS_INSTANCE.post('kt/dm-don-vi-cap-phep/search', request);
    }
    getByHoatDong = (hoatDong : boolean) => {
       return AXIOS_INSTANCE.get('kt/dm-don-vi-cap-phep/get-by-hoat-dong/' + hoatDong);
    }
    addOrUpdate = (request : DanhMucI) => {
        return AXIOS_INSTANCE.post('kt/dm-don-vi-cap-phep/addOrUpdate', request);
     }
     delete = (id: number) => {
      return AXIOS_INSTANCE.post('kt/dm-don-vi-cap-phep/delete/'+ id);
    }
}
