import { Box, NativeSelect } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useRef } from "react";
import { GiayPhepXayDungDaCapDTO } from '../../../../api/CPXD/model/CapPhepXayDungModel';
import { CustomInput } from "../../../commons/components/input/customInput";
import { Cell, Row } from "../../../commons/components/table/tableCommons";

interface TableProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    dataList: GiayPhepXayDungDaCapDTO[];
    doSearch: () => void;
}
const DanhSachBaoCaoCPXD = (props: TableProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        setTotalPage(Math.ceil(props.count / props.rowsPerPage));
        setRowsPerPage(props.rowsPerPage);
    }, [props.count, props.rowsPerPage]);

  
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                            <Cell component="th" scope="row" align="center" width="15%">Loại giấy phép</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Số giấy phép</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Ngày cấp</Cell>
                            <Cell component="th" scope="row" align="center" width="10%" >Chủ đầu tư</Cell>
                            <Cell component="th" scope="row" align="center" width="10%" >Diện tích xây dựng</Cell>
                            <Cell component="th" scope="row" align="center" width="10%" >Tổng diện tích sàn</Cell>
                            <Cell component="th" scope="row" align="center">Vị trí xây dựng</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Đơn vị thiết kế</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Tình trạng</Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.dataList.length > 0 ?
                                props.dataList.map((row, i) => (
                                    <Row key={row.id}>
                                        <Cell>
                                            {((props.page - 1) * rowsPerPage) + i + 1}
                                        </Cell>
                                        <Cell>
                                             {row.tenLoaiGiayPhep}
                                        </Cell>
                                        <Cell>
                                            {row.soGiayPhep}
                                        </Cell>
                                        <Cell>
                                            {(row.ngayCapPhep && row.ngayCapPhep !== undefined && row.ngayCapPhep !== null) ? moment(new Date(row.ngayCapPhep)).format("DD/MM/YYYY") : ""}
                                        </Cell>
                                        <Cell>
                                            {row.chuDauTu}
                                        </Cell>

                                        <Cell>
                                            {row.dienTichXayDung}
                                        </Cell>
                                        <Cell>
                                            {row.tongDienTichSan}
                                        </Cell>

                                        <Cell>
                                            {row.viTriXayDung}
                                        </Cell>
                                        <Cell>
                                            {row.donViTuVan}
                                        </Cell>
                                        <Cell>
                                            {row.tenTrangThai}
                                        </Cell>
                                    </Row>
                                )) :
                                <Row>
                                    <Cell colSpan={10} scope="row" align="center"> Không có dữ liệu</Cell>
                                </Row>
                        }
                    </TableBody>
                </Table>
                {props.dataList.length > 0 &&
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium" color="primary" shape="rounded"
                            showFirstButton showLastButton onChange={props.onChangePage} />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                            <NativeSelect input={<CustomInput />} onChange={props.onChangeRowsPerPage} >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">Mẫu tin {(props.page * rowsPerPage) + 1} - {(props.page * rowsPerPage) + rowsPerPage} / {props.count}</Box>
                    </Box>
                }
            </TableContainer>
        </>
    )
}
export default DanhSachBaoCaoCPXD;
