import AXIOS_INSTANCE from "../index";
export class XacMinhThucDiaService {

    url: string = "/cpxd/xac-minh-thuc-dia";
    insertOrUpdate = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/cpxd/xac-minh-thuc-dia/add-or-update', requestBody);
    }
    
    getTokenRequestHeaders = () => {
        return {
            headers: {
                "Content-Type": "application/json"
            }
        };
    };

    getByMaHoSo = (maHoSo : string) => {
        return AXIOS_INSTANCE.post(`${this.url}/get-by-ma-ho-so`, {maHoSo: maHoSo})
    }

    getById = (id : number) => {
        return AXIOS_INSTANCE.post(`${this.url}/get-by-id`, {id: id})
    }


}