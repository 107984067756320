import { useEffect, useState } from "react";
import SubKhenThuongService from "../api/KhenThuong/chuyenNganh/subKhenThuongService";
import { SubKhenThuongDTO } from "../api/KhenThuong/models/khenThuongDTO";

export const useGetSubDSKhenThuong = (idSubKhenThuong: Number | null) => {
    const [dsSubKhenThuong, setDsSubKhenThuong] = useState<SubKhenThuongDTO[]>([]);
    useEffect(() => {
        async function getDetailSubKhenThuong() {
            if (idSubKhenThuong != null) {
                const data = await new SubKhenThuongService().getSubDSKhenThuong(idSubKhenThuong);
                setDsSubKhenThuong(data.data);
            }
        }
        getDetailSubKhenThuong();
        return () => { };
    }, [idSubKhenThuong]);
    return dsSubKhenThuong;
};
