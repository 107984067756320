export type LGPChaiConstants = {
    ten : string;
    ma : string;
}
export const LOAIBIENDONG_CAPMOI :  LGPChaiConstants = {
    ten : "Cấp mới",
    ma : "1"
}
export const LOAIBIENDONG_CAPLAI :  LGPChaiConstants = {
    ten : "Cấp lại",
    ma : "2"
}
export const LOAIBIENDONG_HUY :  LGPChaiConstants = {
    ten : "Hủy",
    ma : "3"
}
export const LOAIBIENDONG_THUHOI :  LGPChaiConstants = {
    ten : "Thu hồi",
    ma : "4"
}
export const LOAIBIENDONG_HOANTRA_HUY :  LGPChaiConstants = {
    ten : "Hoàn trả hủy",
    ma : "5"
}
export const LOAIBIENDONG_HOANTRA_THUHOI :  LGPChaiConstants = {
    ten : "Hoàn trả thu hồi",
    ma : "6"
}
export const LOAIBIENDONG_CAPDOI :  LGPChaiConstants = {
    ten : "Cấp đổi",
    ma : "7"
}
export const LOAIBIENDONG_HETHAN :  LGPChaiConstants = {
    ten : "Hết hạn",
    ma : "8"
}
export const LIST_BIENDONG : LGPChaiConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_CAPLAI,
    LOAIBIENDONG_HUY,
    LOAIBIENDONG_THUHOI,
    LOAIBIENDONG_HOANTRA_HUY,
    LOAIBIENDONG_HOANTRA_THUHOI,
    LOAIBIENDONG_CAPDOI
]
export const TINHTRANG_HOATDONG :  LGPChaiConstants = {
    ten : "Đang hoạt động",
    ma : "1"
}
export const TINHTRANG_HETHAN :  LGPChaiConstants = {
    ten : "Hết hạn",
    ma : "2"
}
export const TINHTRANG_THUHOI :  LGPChaiConstants = {
    ten : "Thu hồi",
    ma : "3"
}
export const TINHTRANG_HUY :  LGPChaiConstants = {
    ten : "Hủy",
    ma : "4"
}
export const LIST_TINHTRANG : LGPChaiConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_HETHAN,
    TINHTRANG_THUHOI,
    TINHTRANG_HUY
]
