export type ATTPConstants = {
    ten : string;
    ma : string;
}

export const TINHTRANG_HOATDONG :  ATTPConstants = {
    ten : "Hoạt động",
    ma : "HD"
}

export const TINHTRANG_THUHOI :  ATTPConstants = {
    ten : "Thu hồi",
    ma : "TH"
}

export const TINHTRANG_TAMNGUNG: ATTPConstants = {
    ten: "Tạm ngưng",
    ma: "TN"
}

export const TINHTRANG_GANHETHAN: ATTPConstants = {
    ten: "Gần hết hạn",
    ma: "GHH"
}

export const TINHTRANG_HETHAN: ATTPConstants = {
    ten: "Hết hạn",
    ma: "HH"
}

export const LIST_TINHTRANG : ATTPConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_THUHOI,
    TINHTRANG_TAMNGUNG,
    TINHTRANG_GANHETHAN,
    TINHTRANG_HETHAN
]
export const LOAIBIENDONG_CAPMOI :  ATTPConstants = {
    ten : "Cấp mới",
    ma : "1"
}

export const LOAIBIENDONG_THUHOI :  ATTPConstants = {
    ten : "Thu hồi",
    ma : "3"
}

export const LOAIBIENDONG_HOANTRA_THUHOI :  ATTPConstants = {
    ten : "Hoàn trả thu hồi",
    ma : "6"
}
export const LOAIBIENDONG_TAMNGUNG :  ATTPConstants = {
    ten : "Tạm ngưng",
    ma : "7"
}
export const LOAIBIENDONG_KHOIPHUC :  ATTPConstants = {
    ten : "Hòa trả tạm ngưng",
    ma : "8"
}

export const LIST_BIENDONG : ATTPConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_THUHOI,
    LOAIBIENDONG_HOANTRA_THUHOI,
]