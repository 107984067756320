import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { BieuMauAttachFileDTO } from '../../../../../api/QuanTri/Model/danhMucBieuMau';
import InBieuMauButton from "./InBieuMauButton";

interface ThuLyButtonProps {
    onSubmit: () => void;
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    onDelete: () => void;
    showIn?: boolean;
}
const ThuLyButtonComponent = (props: ThuLyButtonProps) => {
    const { onSubmit, onDownloadFileBieuMau, onDelete, showIn } = props;
    const history = useHistory();
    return (
        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
            <Button onClick={onSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
            {
                showIn &&
                <InBieuMauButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
            }
            <Button onClick={onDelete} variant="contained" color="secondary" disableElevation> Xóa</Button>
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
            {/* <Button onClick={onDelete} variant="contained" color="secondary" disableElevation> Chuyển</Button>
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Dự thảo</Button> */}
        </Grid>
    )
}
export default ThuLyButtonComponent;
