import StringUtil from '../../App/utils/stringUtils';
import * as Types from '../actions/actionTypes';
const initialState = {
    listCategory:Array<any>(), total : 0, 
    listDTCategory:Array<any>(), total2 : 0,
    delete:Array<any>()
};
export const categoryReducer = (state = initialState, action: any) => {
    const { index, category, dtCategory, indexEdit, dtCategorys} = action;
    switch (action.type) {
        case Types.ADD_CATEGORY:
            let myCategory = category;
            const chiTietHangMucDTOs = [...state.listDTCategory];
            myCategory['chiTietHangMucDTOs'] = StringUtil.isEmty(chiTietHangMucDTOs) ? []: chiTietHangMucDTOs;
            state.listCategory.push(myCategory);   
            state.total= state.listCategory ? state.listCategory.length : 0 ;   
            state.total2 = StringUtil.isEmty(myCategory) ? 0 : myCategory.length;
            return {
               ...state,
            };
        case Types.ADD_DTCATEGORY:
            state.listDTCategory.push(dtCategory);
            state.total2 = state.listDTCategory ? state.listDTCategory.length : 0 ;
             return {
               ...state,
            };
        case Types.ADD_ALL_DTCATEGORY :
            const newDTCategory = [...dtCategorys];
            state.total2  = StringUtil.isEmty(newDTCategory) ?  0 : newDTCategory.length ; 
            state.listDTCategory = newDTCategory;
            return {
                ...state,
            };          
        case Types.UPDATE_CATEGORY:
            let categoryUpdate = category;
            const chiTietHangMucUpdate = [...state.listDTCategory];
            categoryUpdate['chiTietHangMucDTOs'] = StringUtil.isEmty(chiTietHangMucUpdate) ? []: chiTietHangMucUpdate;
            state.listCategory[index] = categoryUpdate;
            return {
                ...state,
             };
        case Types.DELETE_CATEGORY:
            state.listCategory.splice(index, 1)
            state.total  = StringUtil.isEmty( state.listCategory) ? 0 : state.listCategory.length; 
            return {
                ...state,     
            };
        case Types.DELETE_DTCATEGORY:
            state.listDTCategory.splice(index, 1);
            state.total2  = StringUtil.isEmty( state.listDTCategory) ? 0 : state.listDTCategory.length; 
            if(indexEdit && indexEdit >=0){
                if(state.listCategory[indexEdit] && state.listCategory[indexEdit].chiTietHangMucDTOs){
                  state.listCategory[indexEdit].chiTietHangMucDTOs[index].splice(index, 1);
              }  
            }
            return {
                ...state,     
            }; 

        case Types.ADD_ALL_CATEGORY :
            const {categorys} = action;
            const newList = [...categorys];
            state.total  = StringUtil.isEmty(newList) ?  0 : newList.length   ; 
            return {
                ...state,
                listCategory : newList
            };    
        default:
        return {
            ...state,
            };
    }
};