import AXIOS_INSTANCE from "../index";

export class CapMoiSXRuouService {

    insertOrUpdate =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('ruou/ho-so-thu-ly/add-or-update', requestBody);
    }

    insertOrUpdateDauKy =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('ruou/giay-phep/add-or-update-dau-ky', requestBody);
    }

    getByMaHoSo =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('ruou/ho-so-thu-ly/by-ma-ho-so', requestBody);
    }

    getByHoSoThuLyId =  (hoSoThuLyId : number)=> {
        return AXIOS_INSTANCE.post('ruou/ho-so-thu-ly/by-ho-so-thu-ly-id', {hoSoThuLyId : hoSoThuLyId});
    }
    downloadFile(request: any) {
        return AXIOS_INSTANCE.post('ruou/ho-so-thu-ly/download', request, {
            responseType: 'blob',
        });
    }
    downloadFileByGiayPhepId(request: any) {
        return AXIOS_INSTANCE.post('ruou/ho-so-thu-ly/download-by-id', request, {
            responseType: 'blob',
        });
    }
     //Giấy phép đã cấp
     getBySoGiayPhep_LoaiGiayPhep = (requestBody: any) => {
        return AXIOS_INSTANCE.post('ruou/ho-so-thu-ly/by-so-giay-phep-loai-giay-phep', requestBody);
    }
    getAutocompleteBySoGiayPhep_LoaiGiayPhep_PageSize = (soGiayPhep: string,loaiGiayPhep : string, pageSize: number) => {
        let body = {
            soGiayPhep: soGiayPhep,
            loaiGiayPhep : loaiGiayPhep,
            pageSize: pageSize
        }
        return AXIOS_INSTANCE.post('ruou/ho-so-thu-ly/autocomplete-by-so-giay-phep-loai-giay-phep', body);
    }

}