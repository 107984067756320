import { useEffect, useState } from 'react';
import { TINH_TP } from '../../../../../api/QuanTri/DanhMucFakeService';
import NhaORiengLeDonViTuVanModal from '../Modal/NhaORiengLeDonViTuVanModal';
import NhaORiengLeSoKyHieuBangVeModal from '../Modal/NhaORiengLeSoKyHieuBangVeModal';
import { ChiTietKhuonVienDTO, DienTichKhuonVienDTO, GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { Box, Grid, InputLabel, FormHelperText, InputBase, TextField, InputAdornment } from '@material-ui/core';
import StringUtil from '../../../../utils/stringUtils';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete } from '@material-ui/lab';
import NhaORiengLeLoDatXayDungComponent from '../Table/NhaORiengLeLoDatXayDungComponent';
import SearchIcon from '@material-ui/icons/Search';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import HangMucTable from '../Table/HangMucTable';
import AXIOS_INSTANCE from '../../../../../api';
import { CapCongTrinhService } from '../../../../../api/CPXD/CapCongTrinhService';
import { LoaiCongTrinhService } from '../../../../../api/CPXD/LoaiCongTrinhService';
import { PhanLoaiCongTrinhService } from '../../../../../api/CPXD/PhanLoaiCongTrinhService';
import { DanhMucIView } from '../../../../../api/Common/model/CommonModel';
import { DanhMucDiaChiDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import DateUtil from '../../../../utils/dateUtils';
import DienTichKhuonVienModal from '../Modal/DienTichKhuonVienModal';
import { DienTichKhuonVienDTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import DienTichKhuonVienTable from '../Table/DienTichKhuonVienTable';
interface NhaORiengLeCapMoiNoiDungComponentProps {
    data: GiayPhepXayDungDTO;
    submit: boolean;
    onUpdateData: (name: string, value: any) => void;
    isNhapDauKy: boolean;
}
interface DienTichKhuonVienModalDataProps {
    data: DienTichKhuonVienDTO;
    show: boolean;
    indexEdit: number;
}
const NhaORiengLeCapMoiNoiDungComponent = (props: NhaORiengLeCapMoiNoiDungComponentProps) => {

    const [data, setData] = useState({} as GiayPhepXayDungDTO);
    const [submit, setSubmit] = useState(false);

    const [openDonViTuVanModal, setOpenDonViTuVanModal] = useState(false);
    const [openSoKyHieuBangVeModal, setOpenSoKyHieuBangVeModal] = useState(false);
    const { onUpdateData } = props;
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const fileServerService = new FileServerService();

    const [capCongTrinhs, setCapCongTrinhs] = useState<DanhMucIView[]>([]);
    const [loaiCongTrinhs, setLoaiCongTrinhs] = useState<DanhMucIView[]>([]);
    const [phanLoaiCongTrinhs, setPhanLoaiCongTrinhs] = useState<DanhMucIView[]>([]);
    const capCongTrinhService = new CapCongTrinhService();
    const loaiCongTrinService = new LoaiCongTrinhService();
    const phanLoaiCongTrinhService = new PhanLoaiCongTrinhService();
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);

    const [chiGioiDuongDo, setChiGioiDuongDo] = useState('');
    const [chiGioiXayDung, setChiGioiXayDung] = useState('');

    useEffect(() => {
        capCongTrinhService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setCapCongTrinhs(res.data.data);
            }
        });
        loaiCongTrinService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setLoaiCongTrinhs(res.data.data);
            }
        });
        phanLoaiCongTrinhService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setPhanLoaiCongTrinhs(res.data.data);
            }
        });
        loadQuans("92");
    }, []);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadPhuongs = async () => {
        if(StringUtil.isNotEmpty(data?.maHuyen)){
          
          await  danhMucService
          .getDanhMucDiaChi(data?.maHuyen)
          .then((res) => {
              if (res &&  res.length > 0) {
                  setPhuongs(res);
              }
          })
          .catch((error) => {
              console.log(error);
          });
      }  
  };
    const handleChangeSelectQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText,  ['maPhuong'] : '', ['tenPhuong'] : ''};
        onUpdateData("data", dataNew);
    };

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
        onUpdateData("data", dataNew);
    };

    useEffect(() => {
        let dataNew = {
            ...props.data,
        }
        setData(dataNew);
        setSubmit(props.submit);
        setIsNhapDauKy(props.isNhapDauKy);
        
        if (!StringUtil.isNullOrEmty(props.data.chiGioiDuongDo)){
            setChiGioiDuongDo(props.data.chiGioiDuongDo);
        }
        if (!StringUtil.isNullOrEmty(props.data.chiGioiXayDung)){
            setChiGioiXayDung(props.data.chiGioiXayDung);
        }

    }, [props.data, props.submit, props.onUpdateData]);

    useEffect(() => {
            props.onUpdateData('chiGioiDuongDo', chiGioiDuongDo);
    }, [chiGioiDuongDo]);

    useEffect(() => {
            props.onUpdateData('chiGioiXayDung', chiGioiXayDung);
    }, [chiGioiXayDung]);

    const setQuyenSuDungDatParam = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        let giayToQuyenSuDungDat = { ...data.giayToQuyenSuDungDat, [name]: value };
        setData(data => ({
            ...data,
            giayToQuyenSuDungDat: giayToQuyenSuDungDat
        }));
        onUpdateData('giayToQuyenSuDungDat', giayToQuyenSuDungDat);
    }

    // const handleChangeNumberQuyenSuDungDat = (e: any) => {
    //     let value = e.target.value;
    //     if (Number.isNaN(Number.parseInt(value))) {
    //         value = 0;
    //     }
    //     if (Number.parseInt(value) < 0) {
    //         return;
    //     }
    //     let giayToQuyenSuDungDat = { ...data.giayToQuyenSuDungDat, [e.target.name]: Number.parseInt(value) };
    //     onUpdateData('giayToQuyenSuDungDat', giayToQuyenSuDungDat);
    // }

    const handleChangeNumberQuyenSuDungDat = (e: any) => {
        let value = e.target.value;
        console.log('handleChangeNumberQuyenSuDungDat :' , value);
        if(StringUtil.allowNumberOrDot(value)){
            let valueString : string =  StringUtil.getNumberDot(value);
            let giayToQuyenSuDungDat = { ...data.giayToQuyenSuDungDat, [e.target.name]: valueString };
            onUpdateData('giayToQuyenSuDungDat', giayToQuyenSuDungDat);
        }
    }
    const handleUpdateData = (name: string, value: string) => {
        onUpdateData(name, value);
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    const handleChangeChiGioiDuongDo = (e: any) => {
        setChiGioiDuongDo(e.target.value);
    }

    const handleChangeChiGioiXayDung = (e: any) => {
        setChiGioiXayDung(e.target.value);
    }
   

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    //đơn vị tư vấn
    const handleDonViTuVanModalToggle = () => {
        setOpenDonViTuVanModal(!openDonViTuVanModal);
    }
    //Số ký hiệu bảng vẽ
    const handleSoKyHieuBangVeModalToggle = () => {
        setOpenSoKyHieuBangVeModal(!openSoKyHieuBangVeModal);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

   const handleChangeNgayCap =  (date: any) =>{
        onUpdateData("HetHan", date?.toDate()?.getTime());
   };
   const handleChangeNgayKiemTraBanVe =  (date: any) =>{
    onUpdateData("ngayKiemTraBanVe", date?.toDate()?.getTime());
    };
    const handleChangeNgayHetHan =  (date: any) =>{
        onUpdateData("ngayHetHan", date?.toDate()?.getTime());
        };


   const handleChangeNumberDienTich =  (e: any) => {
        let value = e.target.value;
        let name = e.target.name;
        if(StringUtil.allowNumberOrDot(value)){
            let valueString : string =  StringUtil.getNumberDot(value);
            let giayToQuyenSuDungDat;
            if(name === 'dienTichKhuonVien' && (data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu === 0 || data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu === null)){
                giayToQuyenSuDungDat = {
                    ...data.giayToQuyenSuDungDat,
                    'dienTichKhuonVien' : valueString,
                    'dienTichGiuLai' :  valueString,
                }
            }else if(name === 'dienTichKhuonVienHienHuu' && (data?.giayToQuyenSuDungDat?.dienTichKhuonVien ===0 || data?.giayToQuyenSuDungDat?.dienTichKhuonVien === null)){
                giayToQuyenSuDungDat = {
                    ...data.giayToQuyenSuDungDat,
                    'dienTichKhuonVienHienHuu' : valueString,
                }
            }else if((name === 'dienTichKhuonVienHienHuu') &&
                    (data?.giayToQuyenSuDungDat?.dienTichKhuonVien && data?.giayToQuyenSuDungDat?.dienTichKhuonVien >= 0 && 
                    Number(valueString) >= 0 &&
                    data?.giayToQuyenSuDungDat?.dienTichKhuonVien >= Number(valueString))){
                        giayToQuyenSuDungDat = {
                            ...data.giayToQuyenSuDungDat,
                            'dienTichKhuonVienHienHuu' : valueString,   
                            'dienTichGiuLai' : StringUtil.tinhDienTich(data?.giayToQuyenSuDungDat?.dienTichKhuonVien, Number(valueString)),
                        }
            }else if((name === 'dienTichKhuonVien') &&
                (data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu && data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu >= 0 && 
                Number(valueString) >= 0 &&
                Number(valueString) >= data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu)){
                    giayToQuyenSuDungDat = {
                    ...data.giayToQuyenSuDungDat,
                    'dienTichKhuonVien' : valueString,   
                    'dienTichGiuLai' : StringUtil.tinhDienTich(Number(valueString), data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu),
                }
            }else{
                giayToQuyenSuDungDat = { ...data.giayToQuyenSuDungDat, [e.target.name]: valueString};
            }
            onUpdateData('giayToQuyenSuDungDat', giayToQuyenSuDungDat);
        }
    }

   

    return (
        <>
            {
                <Box padding={0}>
                    <NhaORiengLeSoKyHieuBangVeModal
                        onUpdateData={handleUpdateData}
                        onModalToggle={handleSoKyHieuBangVeModalToggle}
                        show={openSoKyHieuBangVeModal} />

                    <NhaORiengLeDonViTuVanModal
                        onUpdateData={handleUpdateData}
                        onModalToggle={handleDonViTuVanModalToggle}
                        show={openDonViTuVanModal} >
                    </NhaORiengLeDonViTuVanModal>
                    <Box padding={0}>
                        <Grid container>
                            {isNhapDauKy? (
                                <Grid container>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required>Số giấy phép</InputLabel>
                                        <InputBase fullWidth
                                            error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                            value={data.soGiayPhep} name="soGiayPhep" onChange={handleChange} />
                                        {
                                            submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập số giấy phép</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required>Ngày cấp</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                error={submit && !(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined)}
                                                value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined) ? new Date(data.ngayCapPhepCu) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={handleChangeNgayCap}

                                            />
                                        </MuiPickersUtilsProvider>
                                        {
                                            submit && (data.ngayCapPhepCu === 0 || !data.ngayCapPhepCu || data.ngayCapPhepCu === null || data.ngayCapPhepCu === undefined) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập ngày cấp phép</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel >Ngày hết hạn</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                // error={submit && !(data.ngayHetHan !== 0 && data.ngayHetHan && data.ngayHetHan !== null && data.ngayHetHan !== undefined)}
                                                value={(data.ngayHetHan !== 0 && data.ngayHetHan && data.ngayHetHan !== null && data.ngayHetHan !== undefined) ? new Date(data.ngayHetHan) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={handleChangeNgayHetHan}

                                            />
                                        </MuiPickersUtilsProvider>
                            </Grid>
                                </Grid>
                            ) : 
                            (
                                <Grid container>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel>Số giấy phép</InputLabel>
                                        <InputBase fullWidth
                                            value={data.soGiayPhep} name="soGiayPhep" onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel>Ngày cấp</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined) ? new Date(data.ngayCapPhepCu) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={handleChangeNgayCap}

                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            )
                            }
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Tên công trình </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data.tenCongTrinh)}
                                    value={data.tenCongTrinh} name="tenCongTrinh" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.tenCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên công trình</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Tỉnh/Thành phố </InputLabel>
                                <Autocomplete id="maTinh"
                                    options={TINH_TP}
                                    value={{ ma: data.maTinh ? data.maTinh : "", ten: data.tenTinh ? data.tenTinh : "" }}
                                    getOptionLabel={(option) => option.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maTinh', 'tenTinh')}
                                    disabled={true}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maTinh)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maTinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Tỉnh/Thành phố</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete
                                    id="maHuyen"
                                    options={quans}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: data.maHuyen, name: data.tenHuyen }}
                                   
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete
                                        id="maPhuong"
                                        options={phuongs}
                                        onFocus={loadPhuongs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maPhuong, name: data.tenPhuong }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maPhuong) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Cấp công trình </InputLabel>
                                <Autocomplete id="maCapCongTrinh"
                                    options={capCongTrinhs}
                                    value={{ ma: data.maCapCongTrinh ? data.maCapCongTrinh : "", ten: data.tenCapLoaiCongTrinh ? data.tenCapLoaiCongTrinh : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maCapCongTrinh', 'tenCapLoaiCongTrinh')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maCapCongTrinh)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maCapCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Cấp công trình </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Khu vực </InputLabel>
                                <InputBase fullWidth
                                    value={data.tenDuong} name="tenDuong" onChange={handleChange} />
                                {/* {
                                    submit && StringUtil.isNullOrEmty(data.tenDuong) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập Đường </FormHelperText>
                                } */}
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Đường</InputLabel>
                                <InputBase fullWidth
                                    value={data.soNha} name="soNha" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Loại công trình </InputLabel>
                                <Autocomplete id="maLoaiCongTrinh"
                                    options={loaiCongTrinhs}
                                    value={{ ma: data.maLoaiCongTrinh ? data.maLoaiCongTrinh : "11", ten: data.tenLoaiCongTrinh ? data.tenLoaiCongTrinh : "Nhà ở riêng lẻ" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maLoaiCongTrinh', 'tenLoaiCongTrinh')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maLoaiCongTrinh)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maLoaiCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Loại công trình </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel >Địa chỉ cũ </InputLabel>
                                <InputBase fullWidth
                                    value={data.diaChiCu} name="diaChiCu" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} >
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel required>Phân loại xây dựng </InputLabel>
                                        <Autocomplete id="maPhanLoaiCongTrinh"
                                            options={phanLoaiCongTrinhs}
                                            value={{ ma: data.maPhanLoaiCongTrinh ? data.maPhanLoaiCongTrinh : "1A", ten: data.tenPhanLoaiCongTrinh ? data.tenPhanLoaiCongTrinh : "Nhà ở riêng lẻ" }}
                                            getOptionLabel={(option) => option?.ten}
                                            onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maPhanLoaiCongTrinh', 'tenPhanLoaiCongTrinh')}
                                            renderInput={(params) => (
                                                <TextField  {...params} variant="outlined"
                                                    error={submit && StringUtil.isNullOrEmty(data.maPhanLoaiCongTrinh)}
                                                />
                                            )}
                                        />
                                        {
                                            submit && StringUtil.isNullOrEmty(data.maPhanLoaiCongTrinh) &&
                                            <FormHelperText className="-error"  >Vui lòng chọn Phân loại xây dựng </FormHelperText>
                                        }
                                    </Grid>
                                </Box>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Đơn vị tư vấn </InputLabel>
                                        <InputBase style={{ width: '90%' }}
                                            value={data.donViTuVan} name="donViTuVan" onChange={handleChange} />
                                        <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleDonViTuVanModalToggle} />
                                    </Grid>
                                </Box>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Số ký hiệu bản vẽ </InputLabel>
                                        <InputBase style={{ width: '90%' }}
                                            value={data.soKyHieuBangVe} name="soKyHieuBangVe" onChange={handleChange} />
                                        <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleSoKyHieuBangVeModalToggle} />
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel >Lô đất xây dựng </InputLabel>
                                <NhaORiengLeLoDatXayDungComponent data={data.loDatXayDungDTOs} onUpdateData={handleUpdateData} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                        <InputLabel >Ngày kiểm tra bản vẽ</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                // error={submit && !(data.ngayKiemTraBanVe !== 0 && data.ngayKiemTraBanVe && data.ngayKiemTraBanVe !== null && data.ngayKiemTraBanVe !== undefined)}
                                                value={(data.ngayKiemTraBanVe !== 0 && data.ngayKiemTraBanVe && data.ngayKiemTraBanVe !== null && data.ngayKiemTraBanVe !== undefined) ? new Date(data.ngayKiemTraBanVe) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={handleChangeNgayKiemTraBanVe}

                                            />
                                        </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Dự kiến hoàn thành </InputLabel>
                                <InputBase fullWidth
                                    value={data.duKienHoanThanh} name="duKienHoanThanh" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Thời hạn công trình </InputLabel>
                                <InputBase fullWidth
                                    value={data.thoiHanCongTrinh} name="thoiHanCongTrinh" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Chỉ giới đường đỏ</InputLabel>
                                <InputBase fullWidth
                                    multiline
                                    rows="4"
                                    rowsMax="4"
                                    value={chiGioiDuongDo} 
                                    name="chiGioiDuongDo" 
                                    onChange={handleChangeChiGioiDuongDo} />
                            </Grid>
                    
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Chỉ giới xây dựng </InputLabel>
                                <InputBase 
                                    fullWidth
                                    multiline
                                    rows="4"
                                    rowsMax="4"
                                    value={chiGioiXayDung} 
                                    name="chiGioiXayDung" 
                                    onChange={handleChangeChiGioiXayDung} />
                            </Grid>
                            
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Giấy tờ về QSD đất </InputLabel>
                                <Box style={{ borderBottom: " 1px solid #ced4da", marginBottom: "10px" }}></Box>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Diện tích khuôn viên đất </InputLabel>
                                <div style={{display:'inline-flex', width:'100%'}}>
                                <InputBase fullWidth 
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichKhuonVien ? data?.giayToQuyenSuDungDat?.dienTichKhuonVien.toString() : "") } name="dienTichKhuonVien" onChange={handleChangeNumberDienTich} />
                                 <DienTichKhuonVienTable
                                    data={data.dienTichKhuonVienDTOs}
                                    onUpdateData={handleUpdateData}
                                 />
                                </div>
                          
                            </Grid>
                           
                           
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích khuôn viên hiện hữu</InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu ? data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu.toString() : "") } name="dienTichKhuonVienHienHuu" onChange={handleChangeNumberDienTich} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích ban công</InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichBanCong ? data?.giayToQuyenSuDungDat?.dienTichBanCong.toString() : "") } name="dienTichBanCong" onChange={handleChangeNumberQuyenSuDungDat} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích chênh lệch</InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichChenhLech ? data?.giayToQuyenSuDungDat?.dienTichChenhLech.toString() : "") } name="dienTichChenhLech" onChange={handleChangeNumberQuyenSuDungDat} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích giữ lại </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichGiuLai ? data?.giayToQuyenSuDungDat?.dienTichGiuLai.toString() : "") } name="dienTichGiuLai" onChange={handleChangeNumberQuyenSuDungDat} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Mật độ cho phép xây dựng </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.matDoGiayPhep ? data?.giayToQuyenSuDungDat?.matDoGiayPhep.toString() : "")} name="matDoGiayPhep" onChange={handleChangeNumberQuyenSuDungDat} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Cốt nền </InputLabel>
                                <InputBase fullWidth
                                    value={data?.giayToQuyenSuDungDat?.cotNen} name="cotNen" onChange={setQuyenSuDungDatParam} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Lý do chênh lệch</InputLabel>
                                <InputBase fullWidth
                                    value={data?.giayToQuyenSuDungDat?.lyDo} name="lyDo" onChange={setQuyenSuDungDatParam} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Danh sách hạng mục </InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <HangMucTable
                                    data={data.hangMucXayDungDTOs}
                                    onUpdateData={handleUpdateData}
                                />
                            </Grid>
                           

                            <DropZoneCustom
                                acceptFiles={ACCEPT_FILES}
                                attachFileServer={data.files}
                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/cpxd"}
                                handleChangeStatus={handleChangeStatus}
                                handleDeleteFileServer={handleDeleteFileServer}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </Grid>
                    </Box>

                </Box>
            }
        </>
    )
}
export default NhaORiengLeCapMoiNoiDungComponent;