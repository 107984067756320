import { Grid, InputBase, InputLabel, Snackbar } from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { ProfileUpdateService } from '../../../../api/userMgt/profileUpdateService';
import { AlertMessage, CustomAlert } from '../../../../App/commons/components/alert';
import { bootstrapTextFieldHook } from '../../../../App/commons/components/input/bootstrap';
import DateUtil from '../../../../App/utils/dateUtils';
import { getString } from "../../../../helpers/localStorage";
import { UserUpdate, USER_INFO_INIT } from '../../../../model/user';
import { hideLoading, LoadingAction, showLoading } from '../../../../redux/actions/applicationAction';

export const ProfileUpdate = () => {

    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const C_USE = localStorage.getItem("C_USER");
    const [userUpdateDTO, setUserUpdateDTO] =React.useState({} as UserUpdate);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    useEffect(() => {
        async function initialData() {
            dispatch(showLoading());
            await new ProfileUpdateService().getUserInfo(getString("C_USER")).then(resp => {
                const data = {
                    ...resp,
                }
                setUserUpdateDTO(data);
            }).finally(() => dispatch(hideLoading()));
        }
        initialData();
    }, [])

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setUserUpdateDTO(userUpdateDTO => ({
            ...userUpdateDTO,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }

    const handleSubmitData = () => {
        dispatch(showLoading());
        new ProfileUpdateService().update(userUpdateDTO).then(p => {
            showMessageAlert(p.message, p.isSuccess);
        }).finally(() => dispatch(hideLoading()));
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    return (
        <>
                <div className="dash-content">
                    <div className="dash-title">
                        <span>Thông tin cá nhân</span>
                    </div>
                    <form noValidate autoComplete="off">
                        <Grid container className="gridItem">
                            <Grid item xs={8} container spacing={2} className="gridItem">
                                <Grid item xs={4} >
                                    <InputLabel>Họ và tên</InputLabel>
                                    <InputBase fullWidth disabled readOnly
                                        name="fullName"  value={userUpdateDTO.fullName ? userUpdateDTO.fullName : ''}/>
                                </Grid>
                                <Grid item xs={4} >
                                    <InputLabel>Tên đăng nhập</InputLabel>
                                    <InputBase fullWidth disabled readOnly
                                        name="userName"  value={userUpdateDTO.userName}/>
                                </Grid>
                                <Grid item xs={4} >
                                    <InputLabel>Ngày sinh</InputLabel>
                                    <InputBase fullWidth disabled readOnly
                                        name="ngaySinh"  value={userUpdateDTO.ngaySinh ? DateUtil.toDDMMYYYY(userUpdateDTO.ngaySinh) : ''}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel classes={labelStyles}>Giới tính</InputLabel>
                                    <InputBase fullWidth disabled readOnly
                                        name="tenGioiTinh"  value={userUpdateDTO.tenGioiTinh}/>
                                </Grid>
                                <Grid item xs={4} >
                                    <InputLabel classes={labelStyles}>Số điện thoại</InputLabel>
                                    <InputBase fullWidth disabled readOnly  name="soDienThoai"  value={userUpdateDTO.soDienThoai}/>
                                </Grid>
                                <Grid item xs={4} >
                                    <InputLabel classes={labelStyles}>Email</InputLabel>
                                    <InputBase fullWidth disabled readOnly  name="email"  value={userUpdateDTO.email}/>
                                </Grid>
                                <Grid item xs={4} >
                                    <InputLabel classes={labelStyles}>Số CMND/ Số định danh</InputLabel>
                                    <InputBase fullWidth disabled readOnly  name="soCMT"  value={userUpdateDTO.soCMT}/>    
                                </Grid>
                                <Grid item xs={4} >
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <InputBase fullWidth disabled readOnly  name="ngayCapCMT"  value={userUpdateDTO.ngayCapCMT ? DateUtil.toDDMMYYYY(userUpdateDTO.ngayCapCMT) : ''}/>    
                                </Grid>
                                <Grid item xs={4} >
                                    <InputLabel classes={labelStyles}>Nơi cấp CMND</InputLabel>
                                    <InputBase fullWidth disabled readOnly  name="noiCapCMT"  value={userUpdateDTO?.noiCapCMT}/>    
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel classes={labelStyles}>Địa chỉ</InputLabel>
                                    <InputBase fullWidth disabled readOnly  name="diaChi"  value={userUpdateDTO?.diaChi ? userUpdateDTO?.diaChi : ''}/>       
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        open={alertMessage.open}
                        onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <CustomAlert
                            onClose={handleCloseAlertMessage}
                            severity={alertMessage.severity}
                        >
                            {alertMessage.content}
                        </CustomAlert>
                    </Snackbar>
                </div >
        </>
    )
}