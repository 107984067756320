import { BaseService, PageRequest, Pagination } from "../../baseService";
import api from "../../index";
import { DanhSachGiayPhepDKKDDTO } from "../model/DanhSachGiayPhepDKKDDTO";

export interface RequestDTO extends PageRequest {
    soGiayChungNhan: string;
    tenHoKinhDoanh: string;
    maPhuong: string;
    tenPhuong?: string;
    ngayCapFrom: number;
    ngayCapTo: number;
    tongSoVonTu: number;
    tongSoVonDen: number;
    maTinhTrang: string;
    tenTinhTrang?: string;
    exportType?: string;
    exportFileName?: string;
    diaChiDayDu?: string;
    maQuanHuyen: string;
    tenQuanHuyen: string;
    traCuuATTP : boolean;
    soGiayToTuyThan: string;
    hoTen: string;
    xuLyOrDauKy?: string;
}

export class QuanLyDKKDService extends BaseService {
    loadDanhSach = async (request: RequestDTO): Promise<Pagination<DanhSachGiayPhepDKKDDTO[]>> => {
        let result: Pagination<DanhSachGiayPhepDKKDDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }

        await api.post('/giay-phep-kinh-doanh/danh-sach-quan-ly', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    loadLoaiBienDong(maTinhTrang: string) {
        return api.get('/danh-muc/loai-bien-dong-by-tinh-trang?maTinhTrang=' + maTinhTrang);
    }

    loadDanhMucTinhTrang() {
        return api.get('/danh-muc/tinh-trang');
    }

    downloadFile = (request: RequestDTO) => {
        return api.post('giay-phep-kinh-doanh/download-danh-sach-quan-ly', request, {
            responseType: 'blob',
        });
    }

}
