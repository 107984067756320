import React, { useEffect, useState } from 'react';
import { BrowserHistory } from 'history';
import { Box, createStyles, Grid, makeStyles, Paper, Snackbar, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import { VSATTPService } from '../../../../../api/VSATTP/Services/VSATTPService';
import DauKyVSATTPNoiDungComponent from './DauKyVSATTPNoiDungComponent';
import { LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from '../../../../Constants/ATTPConstants';
import { useHistory } from 'react-router';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { HoSoThuLyATTPDTO } from '../../../../../api/VSATTP/Model/VSATTPModel';
import { HoSoThuLyATTPDTO_INIT } from '../../../../../api/VSATTP/Init/VSATTPModel_INIT';
import { getObject } from '../../../../../helpers/localStorage';
import { GiayPhepRequest } from "../../../../../api/QuanTri/Model/MotCuaModel";
import { LoaiGiayPhepEnum } from "../../../../../api/loaiGiayPhep/loaiGiayPhepEnum";
import LayKetQuaGiayPhepService from "../../../../../api/QuanTri/LayKetQuaGiayPhepService";
import { FileEntry } from '../../../../../api/Common/fileEntry';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const DauKyVSATTPComponent = () => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [tabRequired, setTabRequired] = React.useState(-1);
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const [vsattpData, setData] = React.useState<HoSoThuLyATTPDTO>({
        ...HoSoThuLyATTPDTO_INIT,
        maHuyenThiTpDSXKD: C_USER_DATA.maQuanHuyen,
        tenHuyenThiTpDSXKD: C_USER_DATA.tenQuanHuyen,
        maHuyenThiTp: '',
        tenHuyenThiTp: '',
        files: [],
    });


    const vsattpService = new VSATTPService();
    const [submit, setSubmit] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const history = useHistory();
    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        let hoSoThuLyId = history?.location?.state?.hoSoThuLyId;
        if (hoSoThuLyId && hoSoThuLyId > 0) {
            new VSATTPService().getByHoSoId(hoSoThuLyId).then(res => {
                if (res.data.data !== null && res.data.data !== undefined) {
                    setData({ ...res.data.data, giayPhepId: history?.location?.state.id, id: history?.location?.state?.hoSoThuLyId });
                }
            }).catch(error => {
                console.log(error);
            });

        } else {
            const dataNew: HoSoThuLyATTPDTO = {
                ...vsattpData,
                maHoSo: "",
                maThuTuc: "",
                maTrangThai: TINHTRANG_HOATDONG.ma,
                tenTrangThai: TINHTRANG_HOATDONG.ten,
                maBienDong: LOAIBIENDONG_CAPMOI.ma,
                tenBienDong: LOAIBIENDONG_CAPMOI.ten,
            };
            setData(dataNew);
        }
    };
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const handleToggle = () => {
        setShowModal(!showModal);
    }
    const handleSubmit = (success: boolean, mess: string) => {
        if (success) {
            setMessage(mess);
            setSuccess(true);
            handleToggle();
        } else {
            setMessage(mess);
            setSuccess(false);
        }
        setShowAlert(true);

    }
    const resetForm = () => {
        let dataNew = {
            ...HoSoThuLyATTPDTO_INIT, id: vsattpData.id,
            maHoSo: vsattpData.maHoSo,
            maThuTuc: vsattpData.maThuTuc,
        };
        setData(dataNew);
        setMessage("Đã xóa dư liệu");
        setSuccess(true);
        setShowAlert(true);
    }
    return (
        <>
            {<Paper square>
                <Grid container>
                    <OverlayLoading />
                    {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                        <br></br>
                        <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                        <div className="dash-content">
                            <div className="dash-title">
                                <span>NHẬP ĐẦU KỲ GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM</span>
                            </div>
                        </div>
                            {/* </h6>
                        <br></br>
                    </Grid> */}
                    <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setShowAlert(false)} severity={success ? 'success' : 'error'}>{message}</Alert>
                    </Snackbar>
                    <br />
                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChange}>
                                <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                        {indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <DauKyVSATTPNoiDungComponent
                                    vsattpData={vsattpData}
                                    submit={submit}
                                    resetForm={resetForm}
                                    handleSubmit={handleSubmit}
                                    refresh={initData}
                                />
                            </TabPanel>}
                    </Grid>
                </Grid>
            </Paper>}
        </>
    );
}
export default DauKyVSATTPComponent;
