import { Button, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { BieuMauAttachFileDTO } from '../../../../../api/QuanTri/Model/danhMucBieuMau';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import InBieuMauGiayPhepXayDungButton from './InBieuMauGiayPhepXayDungButton';
interface GiayPhepXayDungButtonProps {
    onToggleModal: () => void;
    onSubmit: () => void;
    resetData: () => void;
    showIn?: boolean;
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    onToggleModalThucDia: () => void;
   
}
const GiayPhepXayDungButtonComponent = (props: GiayPhepXayDungButtonProps) => {
    const { onSubmit, onDownloadFileBieuMau, showIn, onToggleModalThucDia, resetData, onToggleModal } = props;
    const history = useHistory();
    const [openResetData, setOpenResetData] = useState(false);
    const handleClickXoa = () => {
        setOpenResetData(!openResetData);
    }
    const handleConfirm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
        }
        setOpenResetData(!openResetData);
    };
    return (
        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
            <Button className="btn-group-thuly" onClick={onSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
            {
                showIn &&
                <InBieuMauGiayPhepXayDungButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
            }
            <Button className="btn-group-thuly" variant="contained" onClick={() => handleClickXoa()} color="secondary" disableElevation> Xóa</Button>
            <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openResetData}
                title="Xóa dữ liệu"
                content="Bạn có đồng ý xóa?" onClose={handleConfirm}
            />
            <Button className="btn-group-thuly" onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
            <Button style={{ width: '200px' }} onClick={onToggleModal} variant="contained" color="primary" disableElevation> Kiểm tra ngăn chặn</Button>
            <Button style={{ width: '200px' }} variant="contained" color="primary" disableElevation onClick={onToggleModalThucDia}> Xác minh thực địa</Button>
            
        </Grid>
    )
}
export default GiayPhepXayDungButtonComponent;