import { Button } from "@material-ui/core";
import React from "react";
import { BaseResponse } from "../../../../api/baseService";
import { DanhMucBieuMauService } from "../../../../api/QuanTri/danhMucBieuMauService";

export interface CellUploadProps {
    callbackUploadFile: (res: BaseResponse, index: number) => void;
    index: number;
    isEdit?: boolean;
}

const CellUpload: React.FC<CellUploadProps> = ({ callbackUploadFile, index, isEdit }) => {
    return (
        <>
            <input
                disabled={!Boolean(isEdit)}
                accept="image/*,.doc,.docx,.xls,.xlsx,.pdf*"
                id={`contained-button-file-${index}`}
                type="file"
                hidden
                onChange={async (e) => {
                    if (e.target.files !== null) {
                        await new DanhMucBieuMauService().uploadFile(e.target.files[0]).then((res) => {
                            callbackUploadFile(res, index);
                        });
                    }
                }}
            />
            <label htmlFor={`contained-button-file-${index}`}>
                <Button disabled={!Boolean(isEdit)} variant="contained" color="primary" component="span" size="small">
                    Chọn file
                </Button>
            </label>
        </>
    );
};

export default CellUpload;
