export type DKKDConstants = {
    ten: string;
    ma: string;
}

export const TINHTRANG_HOATDONG: DKKDConstants = {
    ten: "Đang hoạt động",
    ma: "HD"
}
export const TINHTRANG_NGUNGHOATDONG: DKKDConstants = {
    ten: 'Ngưng hoạt động',
    ma: 'NG'
}
export const TINHTRANG_THUHOI: DKKDConstants = {
    ten: 'Thu hồi',
    ma: 'TH'
}
export const TINHTRANG_HUY: DKKDConstants = {
    ten: 'Hủy',
    ma: 'HH'
}
export const TINHTRANG_TAMNGUNG: DKKDConstants = {
    ten: 'Tạm ngưng hoạt động',
    ma: 'TN'
}
export const TINHTRANG_CHAMDUT: DKKDConstants = {
    ten: 'Chấm dứt',
    ma: 'CD'
}
export const LIST_TINHTRANG: DKKDConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_NGUNGHOATDONG,
    TINHTRANG_THUHOI,
    TINHTRANG_HUY,
    TINHTRANG_TAMNGUNG,
    TINHTRANG_CHAMDUT
]
export const LOAIBIENDONG_CAPMOI: DKKDConstants = {
    ten: "Cấp mới",
    ma: "CM"
}
export const LOAIBIENDONG_CAPLAI: DKKDConstants = {
    ten: "Cấp lại",
    ma: "CL"
}
export const LOAIBIENDONG_DIEUCHINH: DKKDConstants = {
    ten: "Điều chỉnh",
    ma: "DC"
}
export const LOAIBIENDONG_TAMNGUNG: DKKDConstants = {
    ten: "Tạm ngưng",
    ma: "TN"
}
export const LOAIBIENDONG_NGUNGHOATDONG: DKKDConstants = {
    ten: "Ngừng hoạt động",
    ma: "NG"
}
export const LOAIBIENDONG_CHAMDUT: DKKDConstants = {
    ten: "Chấm dứt",
    ma: "CD"
}
export const LOAIBIENDONG_HUY: DKKDConstants = {
    ten: "Hủy",
    ma: "HH"
}
export const LOAIBIENDONG_HOANTRAHUY: DKKDConstants = {
    ten: "Hoàn trả hủy",
    ma: "HT-HH"
}
export const LOAIBIENDONG_THUHOI: DKKDConstants = {
    ten: "Thu hồi",
    ma: "TH"
}
export const LOAIBIENDONG_HOANTRATHUHOI: DKKDConstants = {
    ten: "Hoàn trả thu hồi",
    ma: "HT-TH"
}
export const LOAIBIENDONG_KHOIPHUC: DKKDConstants = {
    ten: "Khôi phục",
    ma: "KP-TT"
}
export const LOAIBIENDONG_CAPDOI: DKKDConstants = {
    ten: "Cấp đổi",
    ma: "DC"
}

export const LIST_BIENDONG: DKKDConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_CAPLAI,
    LOAIBIENDONG_DIEUCHINH,
    LOAIBIENDONG_TAMNGUNG,
    LOAIBIENDONG_NGUNGHOATDONG,
    LOAIBIENDONG_CHAMDUT,
    LOAIBIENDONG_HUY,
    LOAIBIENDONG_HOANTRAHUY,
    LOAIBIENDONG_THUHOI,
    LOAIBIENDONG_HOANTRATHUHOI,
    LOAIBIENDONG_KHOIPHUC,
    LOAIBIENDONG_CAPDOI
]
export const LIST_BAOCAO: DKKDConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_CAPLAI,
    LOAIBIENDONG_CAPDOI,
    LOAIBIENDONG_TAMNGUNG,
    LOAIBIENDONG_CHAMDUT
]
