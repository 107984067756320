import { RoutePropsI } from "."
import { BaoCaoThongKeTinhHinhCapPhep } from "../App/Components/CPBLTL/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep"
import HoanTraHuyGiayPhepKinhDoanhThuocLa from "../App/Components/CPBLTL/BienDongTrangThaiGP/HoanTraHuyGPTL"
import HoanTraThuHoiGiayPhepKinhDoanhThuocLa from "../App/Components/CPBLTL/BienDongTrangThaiGP/HoanTraThuHoiGPTL"
import HuyGiayPhepKinhDoanhThuocLa from "../App/Components/CPBLTL/BienDongTrangThaiGP/HuyGPTL"
import ThuHoiGiayPhepKinhDoanhThuocLa from "../App/Components/CPBLTL/BienDongTrangThaiGP/ThuHoiGPTL"
import { BieuDoTronThuocLa } from "../App/Components/CPBLTL/BieuDoTron/BieuDoTronThuocLa"
import { HoSoCapSoGiayPhepKinhDoanhThuocLa } from "../App/Components/CPBLTL/CapSoGiayPhep/HoSoCapSoGiayPhepKinhDoanhThuocLa"
import GiayPhepKDTLComponent from "../App/Components/CPBLTL/ChiTietGP/GiayPhepKDComponent"
import { ThongKeTinhHinhCapPhep } from "../App/Components/CPBLTL/ThongKe/ThongKeTinhHinhCapPhep"
import CapLaiBLThuocLaComponent from "../App/Components/CPBLTL/ThuLyHoSo/CapLai/CapLaiBLThuocLaComponent"
import CapMoiBLThuocLaComponent from "../App/Components/CPBLTL/ThuLyHoSo/CapMoi/CapMoiBLThuocLaComponent"
import CapSuaDoiBLThuocLaComponent from "../App/Components/CPBLTL/ThuLyHoSo/CapSuaDoi/CapSuaDoiBLThuocLaComponent"
import DauKyBLThuocLaComponent from "../App/Components/CPBLTL/ThuLyHoSo/DauKy/DauKyBLThuocLaComponent"
import { TraCuuKDTLComponent } from "../App/Components/CPBLTL/TraCuuKDTL/TraCuuKDTLComponent"
export const ROUTE_CPTL_CAPMOI = {
    ten: 'CẤP GIẤY PHÉP BÁN LẺ SẢN PHẨM THUỐC LÁ',
    giaoDien: CapMoiBLThuocLaComponent,
    duongDan: '/ban-le-thuoc-la/cap-moi',
}

export const ROUTE_CPTL_CAPSUADOI = {
    ten: 'CẤP SỬA ĐỔI, BỔ SUNG GIẤY PHÉP BÁN LẺ SẢN PHẨM THUỐC LÁ',
    giaoDien: CapSuaDoiBLThuocLaComponent,
    duongDan: '/ban-le-thuoc-la/cap-sua-doi',
}

export const ROUTE_CPTL_CAPLAI = {
    ten: 'CẤP LẠI GIẤY PHÉP BÁN LẺ SẢN PHẨM THUỐC LÁ',
    giaoDien: CapLaiBLThuocLaComponent,
    duongDan: '/ban-le-thuoc-la/cap-lai',
}

export const ROUTE_CPTL_DAUKY = {
    ten: 'ĐẦU KỲ CẤP GIẤY PHÉP BÁN LẺ SẢN PHẨM THUỐC LÁ',
    giaoDien: DauKyBLThuocLaComponent,
    duongDan: '/ban-le-thuoc-la/dau-ky',
}
export const ROUTE_CN_TL: RoutePropsI[] = [
    ROUTE_CPTL_CAPMOI,
    ROUTE_CPTL_CAPSUADOI,
    ROUTE_CPTL_CAPLAI,
    ROUTE_CPTL_DAUKY
];
export const ROUTE_PAGE_TL: RoutePropsI[] = [
    {
        ten: "QUẢN LÝ KINH DOANH THUỐC LÁ",
        duongDan: '/ban-le-thuoc-la/tra-cuu',
        giaoDien: TraCuuKDTLComponent,
    },

    {
        ten: "THỐNG KÊ TÌNH HÌNH CẤP PHÉP KINH DOANH THUỐC LÁ",
        duongDan: '/ban-le-thuoc-la/thong-ke-cap-phep',
        giaoDien: ThongKeTinhHinhCapPhep,
    },
    {
        ten: "BÁO CÁO TÌNH HÌNH CẤP PHÉP KINH DOANH THUỐC LÁ",
        duongDan: '/ban-le-thuoc-la/bao-cao-thong-ke-cap-phep',
        giaoDien: BaoCaoThongKeTinhHinhCapPhep,
    },
    {
        ten: "DANH SÁCH CẤP SỐ GIẤY PHÉP KINH DOANH THUỐC LÁ",
        duongDan: '/ban-le-thuoc-la/danh-sach-cap-so',
        giaoDien: HoSoCapSoGiayPhepKinhDoanhThuocLa,
    },
    {
        ten: "BIỂU ĐỒ TRÒN TÌNH HÌNH CẤP PHÉP KINH DOANH THUỐC LÁ",
        duongDan: '/ban-le-thuoc-la/thong-ke-bieu-do-tron',
        giaoDien: BieuDoTronThuocLa,
    },
];

export const ROUTE_DS_TL: RoutePropsI[] = [
    {
        ten: "QUẢN LÝ KINH DOANH THUỐC LÁ",
        duongDan: '/ban-le-thuoc-la/tra-cuu',
        giaoDien: TraCuuKDTLComponent,
    },

    {
        ten: "THỐNG KÊ TÌNH HÌNH CẤP PHÉP KINH DOANH THUỐC LÁ",
        duongDan: '/ban-le-thuoc-la/thong-ke-cap-phep',
        giaoDien: ThongKeTinhHinhCapPhep,
    },
    {
        ten: "BÁO CÁO TÌNH HÌNH CẤP PHÉP KINH DOANH THUỐC LÁ",
        duongDan: '/ban-le-thuoc-la/bao-cao-thong-ke-cap-phep',
        giaoDien: BaoCaoThongKeTinhHinhCapPhep,
    },
];

export const ROUTE_TL_GUEST: RoutePropsI[] = [
    {
        ten: 'CHI TIẾT GIẤY PHÉP KINH DOANH THUỐC LÁ',
        giaoDien: GiayPhepKDTLComponent,
        duongDan: '/ban-le-thuoc-la/chi-tiet-giay-phep',
    },
    {
        ten: 'HỦY GIẤY PHÉP KINH DOANH THUỐC LÁ',
        giaoDien: HuyGiayPhepKinhDoanhThuocLa,
        duongDan: '/ban-le-thuoc-la/huy-giay-phep',
    },
    {
        ten: 'HOÀN TRẢ HỦY GIẤY PHÉP KINH DOANH THUỐC LÁ',
        giaoDien: HoanTraHuyGiayPhepKinhDoanhThuocLa,
        duongDan: '/ban-le-thuoc-la/hoan-tra-huy-giay-phep',
    },
    {
        ten: 'THU HỒI GIẤY PHÉP KINH DOANH THUỐC LÁ',
        giaoDien: ThuHoiGiayPhepKinhDoanhThuocLa,
        duongDan: '/ban-le-thuoc-la/thu-hoi-giay-phep',
    },
    {
        ten: 'HOÀN TRẢ THU HỒI GIẤY PHÉP KINH DOANH THUỐC LÁ',
        giaoDien: HoanTraThuHoiGiayPhepKinhDoanhThuocLa,
        duongDan: '/ban-le-thuoc-la/hoan-tra-thu-hoi-giay-phep',
    }
];




