import AXIOS_INSTANCE from "../..";
import { BaseService } from "../../baseService";

export interface DashboardKhenThuongDTO {
    thang: number;
    tenThang: string;
    caNhan: number;
    toChuc: number;
    chienSyThiDua: number;
    tapTheXuatSac: number;
    coThiDua: number;
}
export class DashboarKhenThuongService extends BaseService {
    loadDataDashboard = (nam: number) => {
        return AXIOS_INSTANCE.post("khen-thuong/dashboard/load-data", { nam: nam });
    };

}
