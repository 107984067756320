import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TabQuanLyDangKyKinhDoanh } from "./tabQuanLyDangKyKinhDoanh";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        faIcon: {
            fontSize: 36,
            padding: theme.spacing(1)
        }
    }),
);

export const QuanLyDangKyKinhDoanh = () => {
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>QUẢN LÝ ĐĂNG KÝ KINH DOANH</span>
            </div>
            <br />
            <TabQuanLyDangKyKinhDoanh isAnUong = {false} />
        </div >
    )
}
