import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { BieuMauAttachFileDTO } from '../../../../../api/QuanTri/Model/danhMucBieuMau';
import InBieuMauDangKyKinhDoanhButton from "./InBieuMauDangKyKinhDoanhButton";

interface DangKySanXuatRuouButtonProps {
    onSubmit: () => void;
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    onDelete: () => void;
    showIn?: boolean;
}
const DangKySanXuatRuouButtonComponent = (props: DangKySanXuatRuouButtonProps) => {
    const { onSubmit, onDownloadFileBieuMau, onDelete, showIn } = props;
    const history = useHistory();
    return (
        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
            <Button className="btn-group-thuly" onClick={onSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
            {
                showIn &&
                <InBieuMauDangKyKinhDoanhButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
            }
            <Button className="btn-group-thuly" onClick={onDelete} variant="contained" color="secondary" disableElevation> Xóa</Button>
            <Button className="btn-group-thuly" onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
        </Grid>
    )
}
export default DangKySanXuatRuouButtonComponent;