import { Box, createStyles, IconButton, makeStyles, NativeSelect, Snackbar, SvgIcon, SvgIconProps, Table, TableBody, TableContainer, TableHead, TableRow, Theme } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Pagination } from "@material-ui/lab";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UserManagementService } from "../../../../api/userMgt/userManagementService";
import { CustomAlert } from "../../../../App/commons/components/alert";
import { CustomInput } from "../../../../App/commons/components/input/customInput";
import { Cell, Row } from "../../../../App/commons/components/table/tableCommons";
import DateUtil from "../../../../App/utils/dateUtils";
import { getString } from "../../../../helpers/localStorage";
import { PasswordHistoryInterface } from "../../../../model/passwordHistory";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        snackBar: {
            top: "70px"
        }
    }),
);

export const PasswordHistory = () => {
    const classes = useStyles();
    const [alertError, setAlertError] = useState(false);
    const [messageNotify, setMessageNotify] = useState('');
    const [alertType, setAlertType] = useState<'success' | 'error' | undefined>(undefined);

    const [rows, setRows] = useState<PasswordHistoryInterface[]>([]);
    const [page, setPage] = React.useState(0);
    const [size, setSize] = React.useState(5);
    const [total, setTotal] = React.useState(0);

    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSize(Number(event.target.value));
    }

    const loadDataTable = () => {
        dispatch(showLoading());
        new UserManagementService().passwordHistory(getString("C_USER"), page * size, size).then((p) => {
            setRows(p.rows);
            setTotal(p.total);
        });
        dispatch(hideLoading());
    }

    const HiddenPasswordIcon = (props: SvgIconProps) => {
        return (
            <>
                <SvgIcon {...props}>
                    <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" />
                </SvgIcon>
                <SvgIcon {...props}>
                    <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" />
                </SvgIcon>
                <SvgIcon {...props}>
                    <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" />
                </SvgIcon>
                <SvgIcon {...props}>
                    <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" />
                </SvgIcon>
                <SvgIcon {...props}>
                    <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" />
                </SvgIcon>
            </>
        );
    }

    const showOrHiddenPassword = (id: number, isShow: boolean) => {
        const cloneData = [...rows];
        cloneData.forEach((item, i) => {
            if (item.id === id) {
                cloneData.splice(i, 1, { ...item, showPass: isShow });
            }
        });
        setRows(cloneData);
    }

    const handleDeleteItem = (id: number) => {
        dispatch(showLoading());
        new UserManagementService().deletePasswordHistory(id).then((p) => {
            setAlertError(true);
            if (Boolean(p.data)) {
                setMessageNotify('Xóa lịch sử mật khẩu thành công');
                setAlertType('success');
                loadDataTable();
            } else {
                setMessageNotify('Xóa lịch sử mật khẩu không thành công');
                setAlertType('error');
            }
        });
        dispatch(hideLoading());
    }


    useEffect(() => {
        loadDataTable();
    }, [page, size]);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <TableContainer>
                <Table aria-label="customized table" size="small">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                            <Cell component="th" scope="row" align="center" width="40%">Ngày thay đổi</Cell>
                            <Cell component="th" scope="row" align="center" width="45%">Mật khẩu mới</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Thao tác</Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {i + 1}
                                    </Cell>
                                    <Cell align="center">
                                        {DateUtil.toDDMMYYYY_HH_MM_SS(row.changedTime)}
                                    </Cell>
                                    <Cell align="left">
                                        {
                                            Boolean(row.showPass) ? row.passValue : <HiddenPasswordIcon fontSize="small" />
                                        }
                                    </Cell>
                                    <Cell align="center">
                                        <Box alignContent="center">
                                            {
                                                !Boolean(row.showPass) ?
                                                    <IconButton onClick={() => showOrHiddenPassword(row.id, true)} color="primary" aria-label="view" size="small">
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                    :
                                                    <IconButton onClick={() => showOrHiddenPassword(row.id, false)} color="primary" aria-label="view" size="small">
                                                        <VisibilityOffIcon />
                                                    </IconButton>
                                            }
                                            <IconButton color="secondary" onClick={() => handleDeleteItem(row.id)} aria-label="delete" size="small">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Cell>
                                </Row>
                            ))
                        }
                    </TableBody>
                </Table>
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={Math.ceil(total / size)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {(page * size) + 1} - {(page * size) + size} / {total}</Box>
                </Box>
            </TableContainer>
            <Snackbar className={classes.snackBar} open={alertError} onClose={() => setAlertError(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={() => setAlertError(false)} severity={alertType}>{messageNotify}</CustomAlert>
            </Snackbar>
        </>
    )
}