import MomentUtils from "@date-io/moment";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputBase,
    InputLabel, Radio,
    RadioGroup,
    Snackbar,
    TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from 'react-redux';
import AXIOS_INSTANCE from "../../../../../api";
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileEntry } from "../../../../../api/Common/fileEntry";
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { SearchRequest } from '../../../../../api/Common/model/CommonModel';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { DanhMucDanTocDTO, DanhMucDiaChiDTO, DanhMucGioiTinhDTO, DanhMucLinhVucDTO, DanhMucNoiCapDTO, DanhMucQuocTichDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { DangKyKinhDoanhService } from '../../../../../api/DKKD/DangKyKinhDoanhService';
import { DanhMucNganhNgheKinhDoanhService } from '../../../../../api/DKKD/DanhMucNganhNgheKinhDoanhService';
import { HoSoThuLyDTO_INIT } from "../../../../../api/DKKD/init/HoSoThuLyInit";
import { GiayPhepKinhDoanhDTO } from "../../../../../api/DKKD/model/giayPhepKinhDoanhDTO";
import { HoSoThuLyDTO, NganhNgheKinhDoanh } from "../../../../../api/DKKD/model/hoSoThuLyDTO";
import { CHU_THE_HKD, LOAI_GIAY_TO } from '../../../../../api/QuanTri/DanhMucFakeService';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { AlertMessage, CustomAlert } from '../../../../commons/components/alert';
import DropZoneCustom from "../../../../commons/components/CustomDropzone/DropZoneCustom";
import { ACCEPT_FILES } from '../../../../Constants/constants';
import { TINHTRANG_HOATDONG } from '../../../../Constants/DKKDConstants';
import DateUtil from '../../../../utils/dateUtils';
import StringUtil from "../../../../utils/stringUtils";
import ValidatorUtil from '../../../../utils/validatorUtil';
import TableNhomCaNhanComponent from "../../NhapDauKyHKD/table/TableNhomCaNhanComponent";
import ShowSoCMNDModal from '../CapMoi/showSoCMNDModal';

interface ThanhLapHKDThayDoiNoiDungComponentProps {
    data: HoSoThuLyDTO;
    onUpdateData: (name: string, value: any) => void;
    loadGiayPhepDaCap: (soGiayPhep: string) => void;
    submit: boolean;
}


const ThanhLapHKDThayDoiNoiDungComponent = (props: ThanhLapHKDThayDoiNoiDungComponentProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = React.useState({ ...HoSoThuLyDTO_INIT } as HoSoThuLyDTO);
    const [listGiayPhep, setListGiayPhep] = React.useState([] as GiayPhepKinhDoanhDTO[]);
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const [dataGioiTinh, setDataGioiTinh] = useState<DanhMucGioiTinhDTO[]>([]);
    const { loadGiayPhepDaCap, onUpdateData, submit } = props;
    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const fileServerService = new FileServerService();
    const danhMucNganhNgheKinhDoanhService = new DanhMucNganhNgheKinhDoanhService();
    const autoCompleteRef = useRef<HTMLDivElement>(null);
    const [nganhNgheKinhDoanhs, setNganhNgheKinhDoanhs] = useState<NganhNgheKinhDoanh[]>([]);
    const [dataQuocTich, setDataQuocTich] = useState<DanhMucQuocTichDTO[]>([]);
    const [dataDanToc, setDataDanToc] = useState<DanhMucDanTocDTO[]>([]);
    const [dataNoiCap, setDataNoiCap] = useState<DanhMucNoiCapDTO[]>([]);
    const [dataLinhVuc, setDataLinhVuc] = useState<DanhMucLinhVucDTO[]>([]);
    const [dataFromBD, setDataFromBD] = React.useState({} as HoSoThuLyDTO);
    const [checkCMND, setCheckCMND] = React.useState(false);
    const [dataQuanHuyen, setDataQuanHuyen] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataXaPhuong, setDataXaPhuong] = useState<DanhMucDiaChiDTO[]>([]);

    const getLabelNganhNghe = (nganhNghe: any): string => {
        let label: string = " ".repeat(nganhNghe.cap > 0 ? nganhNghe.cap - 1 : 0) + nganhNghe[`${'maNganhNgheCap'}${nganhNghe.cap}`] + "-" + nganhNghe.tenSuDung;
        return label;
    };
    const getNganhNgheAutocomplete = (keywords: any) => {
        let request: SearchRequest = {
            keywords: keywords,
            pageSize: 10,
            pageNumber: 1,
            hoatDong: 1
        }
        danhMucNganhNgheKinhDoanhService.getAutocompleteByKeyword(request).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setNganhNgheKinhDoanhs([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    useEffect(() => {
        async function getDmGioiTinh() {
            await new DanhMucService().getDanhMucGioiTinh('').then((resp) => {
                setDataGioiTinh(resp);
            });
        }
        getDmGioiTinh();
        async function getDmQuocTich() {
            await new DanhMucService().getDanhMucQuocTich('').then((resp) => {
                setDataQuocTich(resp);
            });
        }
        getDmQuocTich();
        async function getDmDanToc() {
            await new DanhMucService().getDanhMucDanToc('').then((resp) => {
                setDataDanToc(resp);
            });
        }
        getDmDanToc();
        async function getDmNoiCap() {
            await new DanhMucService().getDanhMucNoiCap('').then((resp) => {
                setDataNoiCap(resp);
            });
        }
        getDmNoiCap();
        async function getDmLinhVuc() {
            await new DanhMucService().getDanhMucLinhVuc('').then((resp) => {
                setDataLinhVuc(resp);
            });
        }
        getDmLinhVuc();
        async function getDm() {
            await new DanhMucService().getDanhMucDiaChi(String(92)).then((resp) => {
                setDataQuanHuyen(resp);
            });
        }
        getDm();
    }, []);
    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data.files);
    }, [props.data, props.onUpdateData]);

    async function handleChangeNoiCap(value: any, nameValue: string, nameText: string) {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function onBlurPhuongXa() {
        if (data?.maQuan) {
            await new DanhMucService().getDanhMucDiaChi(data?.maQuan).then((resp) => {
                setDataXaPhuong(resp);
            });
        }
    }

    async function handleChangeLinhVuc(value: any, nameValue: string, nameText: string) {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    const handleCheckBox = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        if (isCheckbox) {
            let dataNew = { ...data, checkHKTT: isCheckbox ? e.target.checked : e.target.value, choO: data.hoKhau };
            onUpdateData("data", dataNew);
        }
    }

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function handleChangeQuanHuyen(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText, maPhuongXa: '', tenPhuongXa: '' };
        onUpdateData("data", dataNew);
    }

    async function handleChangePhuongXa(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChangeNumber = (e: any) => {
        let value = e.target.value;
        onUpdateData(e.target.name, StringUtil.convertToNumber(value) != "" ? StringUtil.convertToNumber(value) : 0);
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const handleToggle = () => {
        setCheckCMND(!checkCMND);
    };

    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        let dataNew = {
            ...data,
            soGiayPhepCu: soGiayPhepCuNew
        };
        onUpdateData('data', dataNew);
        dangKyKinhDoanhService.autocompleteBySoGiayPhep(soGiayPhepCuNew, TINHTRANG_HOATDONG.ma, 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setListGiayPhep([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    const handleSelectGiayPhepDaCap = (newValue: GiayPhepKinhDoanhDTO) => {
        loadGiayPhepDaCap(newValue.soGiayPhep);
    }

    async function handleChangeGioiTinh(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    async function handleChangeQuocTich(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    async function handleChangeDanToc(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const onSearchCMND = (cmnd: string) => {
        if (StringUtil.isNullOrEmty(cmnd)) {
            showMessageAlert("Số giấy tờ tùy thân không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoCMND({ soCMND: cmnd }).then(res => {
                let dataNew = res?.data.data;
                if (dataNew !== null && dataNew !== undefined) {
                    if (dataNew.error_code === 'SUCCESSFUL' && dataNew.data && dataNew.data != null) {
                        // setData({
                        //     ...data,
                        //     noiCap: dataNew.data.cmt_NoiCap,
                        //     ngayCap: dataNew.data.cmt_NgayCap,
                        //     hoTen: dataNew.data.tenCongDan,
                        //     ngaySinh: dataNew.data.ngayThangNamSinh,
                        //     tenDanToc: dataNew.data.tenDanToc,
                        //     hoKhau: dataNew.data.queQuan,
                        // });
                        let dataCMND = {
                            ...data,
                            noiCap: dataNew.data.cmt_NoiCap,
                            ngayCap: dataNew.data.cmt_NgayCap,
                            hoTen: dataNew.data.hoVaTenCongDan,
                            ngaySinh: (dataNew.data.ngayThangNamSinh && !StringUtil.isNullOrEmty(dataNew.data.ngayThangNamSinh)) ? DateUtil.stringDDMMYYYtoDate(dataNew.data.ngayThangNamSinh) : dataNew.data.ngayThangNamSinh,
                            tenDanToc: dataNew.data.tenDanToc,
                            hoKhau: dataNew.data.queQuan,
                            maGioiTinh: dataNew.data.maGioiTinh,
                            tenGioiTinh: dataNew.data.tenGioiTinh,
                        };
                        onUpdateData("data", dataCMND);
                    } else {
                        setAlertMessage({ open: true, content: "Số giấy tờ tùy thân không tồn tại trong dữ liệu", severity: 'warning' });
                    }
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
    };

    const onLoadGP = (soGiayToTuyThan: string) => {
        if (StringUtil.isNullOrEmty(soGiayToTuyThan)) {
            setAlertMessage({ open: true, content: "Số giấy tờ tùy thân không được trống!", severity: "warning" });
        } else {
            dangKyKinhDoanhService.getInfoByKeyWord(soGiayToTuyThan)
                .then((res) => {
                    let dataNew = res?.data.data;
                    if (dataNew !== null && dataNew !== undefined) {
                        setDataFromBD({
                            ...dataNew,
                        });
                        setCheckCMND(!checkCMND);
                    } else {
                        setAlertMessage({ open: true, content: "Số giấy tờ tùy thân không tồn tại!", severity: "warning" });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const handleUpdateData = (name: string, value: string) => {
        onUpdateData(name, value);
    }

    return (
        <>
            {
                <Box padding={0}>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Số giấy chứng nhận </InputLabel>
                                <Autocomplete id="soGiayPhep"
                                    noOptionsText="Không có dữ liệu"
                                    options={listGiayPhep}
                                    getOptionLabel={(option) => option?.soGiayPhep}
                                    onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}
                                                value={data?.soGiayPhepCu}
                                                error={submit && StringUtil.isNullOrEmty(data?.soGiayPhepCu)}
                                                onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.soGiayPhepCu) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số giấy chứng nhận </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Ngày cấp phép </InputLabel>
                                <InputBase fullWidth disabled readOnly
                                    value={DateUtil.timeToDDMMYYYY(data.ngayCapPhep)} />                            
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Ngày hết hạn </InputLabel>
                                <InputBase fullWidth disabled readOnly
                                    value={DateUtil.timeToDDMMYYYY(data.ngayHetHan)} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Ngày thay đổi</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        error={submit && !(data.ngayBienDong !== 0 && data.ngayBienDong !== null && data.ngayBienDong > 0)}
                                        value={(data?.ngayBienDong != null && data?.ngayBienDong !== undefined && data?.ngayBienDong != 0) ? new Date(data.ngayBienDong) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayBienDong')}

                                    />
                                </MuiPickersUtilsProvider>
                                {
                                    submit && (data.ngayBienDong == 0 || data.ngayBienDong === null || data.ngayBienDong === undefined) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập ngày thay đổi</FormHelperText>
                                }
                            </Grid>
                        </Grid>
                    </Box>

                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >THÔNG TIN ĐẠI DIỆN HỘ KINH DOANH </InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Loại giấy tờ tùy thân </InputLabel>
                                <Autocomplete id="loaiGiayTo"
                                    options={LOAI_GIAY_TO}
                                    value={{ ma: data?.loaiGiayTo ? data.loaiGiayTo : 0, ten: data?.tenGiayTo ? data.tenGiayTo : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'loaiGiayTo', 'tenGiayTo')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && (StringUtil.isNullOrEmty(data?.loaiGiayTo) || data?.loaiGiayTo < 0)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && (StringUtil.isNullOrEmty(data?.loaiGiayTo) || data?.loaiGiayTo < 0) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn loại giấy tờ </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Số giấy tờ tùy thân </InputLabel>
                                <InputBase
                                    endAdornment={<InputAdornment position="end">
                                        <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '3px' }}
                                            onClick={(e) => onSearchCMND(data.soGiayToTuyThan)} /></InputAdornment>}
                                    fullWidth
                                    error={submit &&
                                        (
                                            StringUtil.isNullOrEmty(data?.soGiayToTuyThan)
                                            || !ValidatorUtil.validatorSoGiayTo(data.loaiGiayTo, data.soGiayToTuyThan)
                                        )
                                    }
                                    value={data?.soGiayToTuyThan}
                                    name="soGiayToTuyThan"
                                    onChange={handleChange}
                                />
                                {submit && StringUtil.isNullOrEmty(data?.soGiayToTuyThan) && (
                                    <FormHelperText className="-error">Vui lòng nhập số giấy tờ tùy thân</FormHelperText>
                                )}
                                {submit && !StringUtil.isNullOrEmty(data?.soGiayToTuyThan) && !ValidatorUtil.validatorSoGiayTo(data.loaiGiayTo, data.soGiayToTuyThan) && (
                                    <FormHelperText className="-error">
                                        Vui lòng nhập số giấy tờ hợp lệ
                                        <br />
                                        Chứng minh nhân dân : 9 hoặc 12 chữ số
                                        <br />
                                        Căn cước công dân : 12 chữ số
                                        <br />
                                        Hộ chiếu : Bắt đầu bằng ký tự chữ viết hoa và 8 chữ số kế tiếp
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <Button type="button" variant="contained" onClick={(e) => onLoadGP(data?.soGiayToTuyThan)}
                                    style={{ fontSize: '14px', marginTop: '20px', width: '100px' }}>Kiểm tra
                                </Button>&nbsp;&nbsp;
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Nơi cấp </InputLabel>
                                {/* <InputBase fullWidth
                                    value={data?.tenNoiCap} name="noiCap" onChange={handleChange} /> */}
                                <Autocomplete id="maHuyen"
                                    options={dataNoiCap}
                                    value={{
                                        ma: data.maNoiCap ? data.maNoiCap : "",
                                        ten: data.tenNoiCap ? data.tenNoiCap : ""
                                    }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeNoiCap(newValue, 'maNoiCap', 'tenNoiCap')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref}>
                                            <TextField  {...params} variant="outlined" />
                                        </div>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayCap != null && data?.ngayCap !== undefined && data?.ngayCap != 0) ? new Date(data.ngayCap) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCap')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            {/* <Grid item xs={3} className="grid-item">
                                <InputLabel >Ngày hết hạn</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayHetHan != null && data?.ngayHetHan !== undefined && data?.ngayHetHan != 0) ? new Date(data.ngayHetHan) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHan')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid> */}
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Quốc tịch </InputLabel>
                                <Autocomplete id="quocTich"
                                    options={dataQuocTich}
                                    value={{ code: data?.maQuocTich ? data?.maQuocTich : "", name: data?.tenQuocTich ? data?.tenQuocTich : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeQuocTich(newValue, 'maQuocTich', 'tenQuocTich')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Họ tên </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data.hoTen)}
                                    value={data?.hoTen} name="hoTen" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.hoTen) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên đại diện hộ kinh doanh</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày sinh</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngaySinh != null && data?.ngaySinh !== undefined && data?.ngaySinh != 0) ? new Date(data?.ngaySinh) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngaySinh')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Nơi sinh </InputLabel>
                                <InputBase fullWidth
                                    value={data?.noiSinh} name="noiSinh" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Dân tộc </InputLabel>
                                <Autocomplete id="danToc"
                                    options={dataDanToc}
                                    value={{ code: data?.maDanToc ? data?.maDanToc : "", name: data?.tenDanToc ? data?.tenDanToc : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeDanToc(newValue, 'maDanToc', 'tenDanToc')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Chức vụ </InputLabel>
                                <InputBase fullWidth
                                    value={data?.chucVu} name="chucVu" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Giới tính </InputLabel>
                                <Autocomplete
                                    id="maGioiTinh"
                                    options={dataGioiTinh}
                                    value={{ code: data?.maGioiTinh ? data?.maGioiTinh : "", name: data?.tenGioiTinh ? data?.tenGioiTinh : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeGioiTinh(newValue, "maGioiTinh", "tenGioiTinh")}
                                    renderInput={(params) => {
                                        return (
                                            <div className="padding-top-8px" ref={params.InputProps.ref}>
                                                <InputBase fullWidth {...params.inputProps} />
                                            </div>
                                        );
                                    }}
                                />
                                {/* <InputLabel >Giới tính </InputLabel>
                                <NativeSelect fullWidth name="gioiTinh" value={data?.gioiTinh} input={<CustomNativeSelect />} onChange={handleChange} >
                                    <option value="">Chọn giới tính</option>
                                    <option value="1">Nam</option>
                                    <option value="2">Nữ</option>
                                    <option value="0">Khác</option>
                                </NativeSelect> */}
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Nơi đăng ký hộ khẩu thường trú </InputLabel>
                                <InputBase fullWidth
                                    value={data?.hoKhau} name="hoKhau" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <Checkbox name="checkHKTT" checked={data.checkHKTT} color="default" size="small"
                                    value={data.checkHKTT} onChange={handleCheckBox} title="Chổ ở hiện tại lấy theo Nơi Đăng ký hộ khẩu thường trú" />
                                Chỗ ở hiện tại là hộ khẩu thường trú
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Chỗ ở hiện tại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.choO} name="choO" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >THÔNG TIN KINH DOANH </InputLabel>
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <InputLabel>Chủ thể thành lập hộ kinh doanh</InputLabel>
                                <Autocomplete id="loaiChuThe"
                                    options={CHU_THE_HKD}
                                    value={{
                                        ma: data?.loaiChuThe ? data.loaiChuThe : 0,
                                        ten: data?.tenChuThe ? data.tenChuThe : ""
                                    }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'loaiChuThe', 'tenChuThe')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref}>
                                            <TextField  {...params} variant="outlined" />
                                        </div>
                                    }}
                                />
                                {/* <RadioGroup row aria-label="position" name="loaiChuThe" defaultValue="end" onChange={handleChange}>
                                    <FormControlLabel
                                        checked={data?.loaiChuThe == 1}
                                        value="1"
                                        control={<Radio color="primary" size="small" />}
                                        label="Nhóm cá nhân"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        checked={data?.loaiChuThe == 2}
                                        value="2"
                                        control={<Radio color="primary" size="small" />}
                                        label="Cá nhân"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                                {
                                    submit && StringUtil.isNullOrEmty(data?.loaiChuThe) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn thủ thể thành lập HKD</FormHelperText>
                                } */}
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                            </Grid>
                            {data.loaiChuThe == 2 &&
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel>Thành viên hộ gia đình </InputLabel>
                                    {/* <InputBase fullWidth
                                        value={data?.tenNhomCaNhan} name="tenNhomCaNhan" onChange={handleChange} /> */}
                                    <TableNhomCaNhanComponent data={data.nhomCaNhans} onUpdateData={handleUpdateData} />
                                </Grid>
                            }
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required>Tên hộ kinh doanh/bảng hiệu </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.tenHoKinhDoanh)}
                                    value={data?.tenHoKinhDoanh} name="tenHoKinhDoanh" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.tenHoKinhDoanh) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên hộ kinh doanh</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Tổng số vốn (VNĐ) </InputLabel>
                                <InputBase fullWidth
                                    value={StringUtil.formatForDot(data?.tongVon)} name="tongVon" onChange={handleChangeNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số lượng lao động </InputLabel>
                                <InputBase fullWidth
                                    value={StringUtil.formatForDot(data?.soLuongLaoDong)} name="soLuongLaoDong" onChange={handleChangeNumber} />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <InputLabel>Lĩnh vực kinh doanh </InputLabel>
                                {/* <InputBase fullWidth
                                    value={data?.tenLinhVuc} name="tenLinhVuc" onChange={handleChange} /> */}
                                <Autocomplete id="tenLinhVuc"
                                    options={dataLinhVuc}
                                    value={{
                                        ma: data?.maLinhVuc ? data?.maLinhVuc : "",
                                        ten: data?.tenLinhVuc ? data?.tenLinhVuc : ""
                                    }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeLinhVuc(newValue, 'maLinhVuc', 'tenLinhVuc')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref}>
                                            <TextField  {...params} variant="outlined" />
                                        </div>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <InputLabel>Mặt hàng kinh doanh</InputLabel>
                                <InputBase fullWidth
                                    value={data?.matHang} name="matHang" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Ngành, nghề kinh doanh</InputLabel>
                                <Autocomplete multiple options={nganhNgheKinhDoanhs}
                                    ref={autoCompleteRef}
                                    getOptionLabel={(option: any) => getLabelNganhNghe(option)}
                                    onChange={(_, val) => {
                                        let dataNew = { ...data, nganhNgheKinhDoanhs: val };
                                        onUpdateData("data", dataNew);
                                    }}
                                    onFocus={() => getNganhNgheAutocomplete('')}
                                    value={data.nganhNgheKinhDoanhs && data.nganhNgheKinhDoanhs.length > 0 ? data.nganhNgheKinhDoanhs : []}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref}>
                                            <TextField  {...params} variant="outlined" />
                                        </div>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Chi tiết ngành, nghề kinh doanh</InputLabel>
                                <InputBase rows={3} multiline fullWidth value={data?.chiTietNganhNghe} name="chiTietNganhNghe" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >ĐỊA ĐIỂM KINH DOANH </InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete id="maHuyen"
                                    options={dataQuanHuyen}
                                    value={{
                                        code: data.maQuan ? data.maQuan : "",
                                        name: data.tenQuan ? data.tenQuan : ""
                                    }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeQuanHuyen(newValue, 'maQuan', 'tenQuan')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maQuan)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maQuan) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={dataXaPhuong}
                                    value={{
                                        code: data.maPhuong ? data.maPhuong : "",
                                        name: data.tenPhuong ? data.tenPhuong : ""
                                    }}
                                    getOptionLabel={(option) => option?.name}
                                    onFocus={onBlurPhuongXa}
                                    onChange={(event: any, newValue: any) => handleChangePhuongXa(newValue, 'maPhuong', 'tenPhuong')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maPhuong)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maPhuong) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Đường </InputLabel>
                                <InputBase error={submit && StringUtil.isNullOrEmty(data?.tenDuong)} fullWidth value={data.tenDuong} name="tenDuong" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.tenDuong) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập Đường</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số nhà, đường </InputLabel>
                                <InputBase fullWidth
                                    value={data?.soNha} name="soNha" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Điện thoại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.dienThoai} name="dienThoai" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Fax </InputLabel>
                                <InputBase fullWidth
                                    value={data?.fax} name="fax" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Email </InputLabel>
                                <InputBase fullWidth
                                    value={data?.email} name="email" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >website </InputLabel>
                                <InputBase fullWidth
                                    value={data?.website} name="website" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Nội dung thay đổi </InputLabel>
                                <InputBase fullWidth
                                    value={data?.noiDungThayDoi} name="noiDungThayDoi" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel > Số lần thay đổi </InputLabel>
                                <InputBase fullWidth
                                    value={data?.soLanCapDoi} name="soLanCapDoi" onChange={handleChangeNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel > Số lần cấp lại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.soLanCapLai} name="soLanCapLai" onChange={handleChangeNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                {/* <InputLabel > Ngày cấp lại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.ngayCapLai} name="soLanCapDoi" onChange={handleChangeNumber} /> */}
                                <InputLabel >Ngày cấp lại</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayCapLai != null && data?.ngayCapLai !== undefined && data?.ngayCapLai != 0) ? new Date(data.ngayCapLai) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapLai')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={ACCEPT_FILES}
                                    attachFileServer={data.files}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dkkd"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
            {
                checkCMND && (
                    <ShowSoCMNDModal dataFromBD={dataFromBD} onToggle={handleToggle} show={checkCMND} />
                )}
        </>
    )
};
export default ThanhLapHKDThayDoiNoiDungComponent;
