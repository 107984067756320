import React, { useEffect, useState } from "react";
import { Box, createStyles, Grid, makeStyles, Paper, Snackbar, Theme, Typography } from "@material-ui/core";
import { BrowserHistory } from "history";
import { CustomTab, CustomTabs } from "../../../../commons/components/tabs";
import ThanhLapHKDThayDoiNoiDungComponent from "./ThanhLapHKDThayDoiNoiDungComponent";
import { HoSoThuLyDTO, NhomCaNhanDTO } from "../../../../../api/DKKD/model/hoSoThuLyDTO";
import { DangKyKinhDoanhService } from "../../../../../api/DKKD/DangKyKinhDoanhService";
import { ThanhLapHKDThanhVienGopVon } from "../../Modal/ThanhLapHKDThanhVienGopVon";
import { ThanhVienGopVonDTO } from "../../../../../api/DKKD/model/thanhVienGopVonDTO";
import StringUtil from "../../../../utils/stringUtils";
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { BieuMauAttachFileDTO } from "../../../../../api/QuanTri/Model/danhMucBieuMau";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { loadModules } from "esri-loader";
import { API_KEY_MAP } from "../../../../Constants/const";
import { LOAIBIENDONG_DIEUCHINH, TINHTRANG_HOATDONG } from "../../../../Constants/DKKDConstants";
import { HoSoThuLyDTO_INIT } from "../../../../../api/DKKD/init/HoSoThuLyInit";
import ThuLyButtonComponent from "../../../../commons/components/hoso/thuly/ThuLyButtonComponent";
import ThayDoiHKDNoiDungCuComponent from "./ThayDoiHKDNoiDungCuComponent";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../../commons/components/overlayLoading/overlayLoading";
import ValidatorUtil from "../../../../utils/validatorUtil";
import { ThongTinChuyenNhanhUtil } from "../../../../utils/thongTinChuyenNhanhUtil";
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import DateUtil from "../../../../utils/dateUtils";
import { UserData } from "../../../../../api/userMgt/userManagementService";
import { HoSoCuaToiMgtService } from "../../../../../api/HoSoCuaToi/HoSoCuaToiMgtService";

type StateHoSo = {
    maThuTuc: string;
    maHoSo: string;
};
type ThanhLapHkdProps = {
    history: BrowserHistory<StateHoSo>;
};
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:focus": {
                border: "none!important",
            },
        },
        textarea: {
            // boxSizing: 'inherit',
            width: "100%",
            borderRadius: 4,
            position: "relative",
            backgroundColor: theme.palette.common.white,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#ced4da",
            fontSize: 14,
            padding: "0.5rem .75rem",
            transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
            fontFamily: fontFamily,
            "&:focus": {
                outline: "none",
                borderRadius: 4,
                borderColor: "#ced4da",
            },
        },
    })
);
const ThanhLapHKDThayDoiComponent = (props: ThanhLapHkdProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [diaChiKinhDo, setDiaChiKinhDo] = useState<Number>();
    const [diaChiViDo, setDiaChiViDo] = useState<Number>();
    const [data, setData] = React.useState({...HoSoThuLyDTO_INIT} as HoSoThuLyDTO);
    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const { history } = props;
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    let C_USER_DATA_DE = {} as UserData;
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    useEffect(() => {
        initData();
    }, []);
    async function initData() {
        let maHoSo = history?.location?.state?.maHoSo;
        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        //Chi Tiet - Mot Cua
        let maGT_ChiTiet = "", tenGT_ChiTiet = "";
        let loaiGiayTo_ChiTiet = 2, tenGiayTo_ChiTiet = "";
        let soGiayToTuyThan_ChiTiet = "";
        let maTinh_ChiTiet = "", tenTinh_ChiTiet = "";
        let maQuan_ChiTiet = "", tenQuan_ChiTiet = "";
        let maPhuong_ChiTiet = "", tenPhuong_ChiTiet = "";
        let diaChiNguoiNop_ChiTiet = "";
        let choOHienTai_ChiTiet = "";
        if (!StringUtil.isNullOrEmty(C_USER_DATA_DE.maCoQuan)) {
            hoSoCuaToiMgtService.chiTiet(C_USER_DATA_DE.maCoQuan, history?.location?.state?.maHoSo).then(async (p) => {
                if (p.data && p.data.data) {
                    let dataMotCua = p.data.data;
                    hoTenNguoiNop = p.data.data.nguoiNop;
                    soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
                    //HienLT52 - Filter data một cửa điền vào form đăng ký
                    if(StringUtil.isNotEmpty(dataMotCua.gioiTinh)) {
                        switch (dataMotCua.gioiTinh) {
                            case "Nam":
                                maGT_ChiTiet = "1";
                                tenGT_ChiTiet = "Nam";
                                break;
                            case "Nữ":
                                maGT_ChiTiet = "2";
                                tenGT_ChiTiet = "Nữ";
                                break;
                            default:
                                maGT_ChiTiet = "3";
                                tenGT_ChiTiet = "Chưa có thông tin";
                                break;
                        }
                    }
                    if(StringUtil.isNotEmpty(dataMotCua.soCCCD)){
                        loaiGiayTo_ChiTiet = 2;
                        tenGiayTo_ChiTiet = "Căn cước công dân";
                        soGiayToTuyThan_ChiTiet = dataMotCua.soCCCD;
                    }
                    if (!StringUtil.isNullOrEmty(dataMotCua?.DiaChi_MaTinh)) {
                        let dataTinhThanh = await new DanhMucService().getDanhMucDiaChiByCode(dataMotCua?.DiaChi_MaTinh);
                        maTinh_ChiTiet = dataTinhThanh[0].code;
                        tenTinh_ChiTiet = dataTinhThanh[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataMotCua?.DiaChi_MaQuanHuyen)) {
                        let dataQuan = await new DanhMucService().getDanhMucDiaChiByCode(dataMotCua?.DiaChi_MaQuanHuyen);
                        maQuan_ChiTiet = dataQuan[0].code;
                        tenQuan_ChiTiet = dataQuan[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataMotCua?.DiaChi_MaQuanHuyen)) {
                        let dataHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataMotCua?.DiaChi_MaPhuongXa);
                        maPhuong_ChiTiet = dataHuyen[0].code;
                        tenPhuong_ChiTiet = dataHuyen[0].name;
                    }
                    if(!StringUtil.isNullOrEmty(dataMotCua?.diaChiNguoiNop)) {
                        diaChiNguoiNop_ChiTiet = dataMotCua?.diaChiNguoiNop;
                        choOHienTai_ChiTiet = diaChiNguoiNop_ChiTiet + " " + tenPhuong_ChiTiet + " " + tenQuan_ChiTiet + " " + tenTinh_ChiTiet;
                    }

                }
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
        if (StringUtil.isNotEmpty(maHoSo)) {
            dispatchLoading(showLoading());
            dangKyKinhDoanhService.getByMaHoSo({ maHoSo: maHoSo }).then(async (res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    let resData = {
                        ...res?.data?.data,
                        maHoSo: history?.location?.state?.maHoSo,
                        maThuTuc: history?.location?.state?.maThuTuc,
                        tinhTrang: TINHTRANG_HOATDONG.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_DIEUCHINH.ma,
                        tenBienDong: LOAIBIENDONG_DIEUCHINH.ten,
                    };
                    setData(resData);
                } else {
                    let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHoSo);
                    let maQuan = "";
                    let tenQuan = "";
                    let maPhuong = "";
                    let tenPhuong = "";
                    let maQT = "", tenQT = "";
                    let maGT = "", tenGT = "";
                    let noiCapCM = "";
                    if (!StringUtil.isNullOrEmty(dataEform?.quanhuyen)) {
                        let dataQuan = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                        maQuan = dataQuan[0].code;
                        tenQuan = dataQuan[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.xaphuong)) {
                        let dataPhuongXa = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                        maPhuong = dataPhuongXa[0].code;
                        tenPhuong = dataPhuongXa[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.quoctich)) {
                        let dataQuocTich = await new DanhMucService().getDanhMucQuocTich(dataEform?.quoctich);
                        maQT = dataQuocTich[0].code;
                        tenQT = dataQuocTich[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.gioitinh)) {
                        let dataGioiTinh = await new DanhMucService().getDanhMucGioiTinh(dataEform?.gioitinh);
                        maGT = dataGioiTinh[0].code;
                        tenGT = dataGioiTinh[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.noicapcmnd)) {
                        let dataHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.noicapcmnd);
                        noiCapCM = dataHuyen[0].name;
                    }
                    const dataNew: HoSoThuLyDTO = {
                        ...HoSoThuLyDTO_INIT,
                        userName: C_USER_DATA_DE.userName,
                        nguoiTao: C_USER_DATA_DE.userName,
                        nguoiThuLy: C_USER_DATA_DE.userName,
                        maCoQuan: C_USER_DATA_DE.maCoQuan,
                        maHoSo: history?.location?.state?.maHoSo,
                        maThuTuc: history?.location?.state?.maThuTuc,
                        tinhTrang: TINHTRANG_HOATDONG.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_DIEUCHINH.ma,
                        tenBienDong: LOAIBIENDONG_DIEUCHINH.ten,
                        //soGiayToTuyThan: dataEform?.cmnd ? dataEform?.cmnd : HoSoThuLyDTO_INIT.soGiayToTuyThan,
                        // chucVu: dataEform?.chucvunguoidaidien ? dataEform?.chucvunguoidaidien : HoSoThuLyDTO_INIT.chucVu,
                        tenNoiCap: noiCapCM,
                        ngayCap: dataEform?.ngaycapcmnd ? StringUtil.stringDateToNumber(dataEform?.ngaycapcmnd) : HoSoThuLyDTO_INIT.ngayCap,
                        // hoTen: dataEform?.hovaten ? dataEform?.hovaten : HoSoThuLyDTO_INIT.hoTen,
                        hoTen: hoTenNguoiNop != "" ? hoTenNguoiNop : dataEform?.hovaten ? dataEform?.hovaten : HoSoThuLyDTO_INIT.hoTen,
                        ngaySinh: dataEform?.ngaythangsinh ? StringUtil.stringDateToNumber(dataEform?.ngaythangsinh) : HoSoThuLyDTO_INIT.ngaySinh,
                        hoKhau: dataEform?.hokhauthuongtru ? dataEform?.hokhauthuongtru : HoSoThuLyDTO_INIT.hoKhau,
                        //choO: dataEform?.noiohiennay ? dataEform?.noiohiennay : HoSoThuLyDTO_INIT.choO,
                        //maQuan: dataEform?.quanhuyen ? dataEform?.quanhuyen : HoSoThuLyDTO_INIT.maQuan,
                        //tenQuan: tenQuan,
                        //maPhuong: dataEform?.xaphuong ? dataEform?.xaphuong : HoSoThuLyDTO_INIT.maPhuong,
                        //tenPhuong: tenPhuong,
                        // dienThoai: dataEform?.sdt ? dataEform?.sdt : HoSoThuLyDTO_INIT.dienThoai, soDienThoaiNguoiNop
                        dienThoai: soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt ? dataEform?.sdt : HoSoThuLyDTO_INIT.dienThoai,
                        fax: dataEform?.fax ? dataEform?.fax : HoSoThuLyDTO_INIT.fax,
                        email: dataEform?.email ? dataEform?.email : HoSoThuLyDTO_INIT.email,
                        website: dataEform?.website ? dataEform?.website : HoSoThuLyDTO_INIT.website,
                        maQuocTich: maQT != null && maQT != undefined && maQT != "" ? maQT : HoSoThuLyDTO_INIT.maQuocTich,
                        tenQuocTich: tenQT != null && tenQT != undefined && tenQT != "" ? tenQT : HoSoThuLyDTO_INIT.tenQuocTich,
                        //maGioiTinh: maGT,
                        //tenGioiTinh: tenGT,

                        //HienLT52 - Filter MC to PMCN
                        loaiGiayTo: loaiGiayTo_ChiTiet,
                        tenGiayTo: tenGiayTo_ChiTiet,
                        soGiayToTuyThan: soGiayToTuyThan_ChiTiet != null ? soGiayToTuyThan_ChiTiet : dataEform?.cmnd ? dataEform?.cmnd : HoSoThuLyDTO_INIT.soGiayToTuyThan,
                        maQuan: maQuan_ChiTiet != null ? maQuan_ChiTiet : dataEform?.quanhuyen ? dataEform?.quanhuyen : HoSoThuLyDTO_INIT.maQuan,
                        tenQuan: tenQuan_ChiTiet != null ? tenQuan_ChiTiet : tenQuan,
                        maPhuong: maPhuong_ChiTiet != null ? maPhuong_ChiTiet : dataEform?.phuongxa ? dataEform?.phuongxa : HoSoThuLyDTO_INIT.maPhuong,
                        tenPhuong: tenPhuong_ChiTiet != null ? tenPhuong_ChiTiet: tenPhuong,
                        maGioiTinh: maGT != null && maGT != undefined && maGT != "" ? maGT : maGT_ChiTiet,
                        tenGioiTinh: tenGT != null && tenGT != undefined && tenGT != "" ? tenGT : tenGT_ChiTiet,
                        tenDuong: diaChiNguoiNop_ChiTiet,
                        choO: choOHienTai_ChiTiet != null ? choOHienTai_ChiTiet : dataEform?.noiohiennay ? dataEform?.noiohiennay : HoSoThuLyDTO_INIT.choO,
                    };
                    setData(dataNew);
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });

        }
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value,
            };
            setData(dataNew);
        }
    };
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataMems = (memData: ThanhVienGopVonDTO, isEdit: Boolean, index: number) => {
        let mems = [...data.thanhVienGopVons];
        if (isEdit) {
            const idx = mems.findIndex((e) => e.ngayTao === memData.ngayTao);
            mems[idx] = memData;
        } else {
            let mem = { ...memData, ngayTao: new Date().getTime() };
            mems.push(mem);
        }

        let dataNew = {
            ...data,
            thanhVienGopVons: mems,
        };
        setData(dataNew);
    };

    const onDeleteMems = (index: number) => {
        let mems = [...data.thanhVienGopVons];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            thanhVienGopVons: mems,
        };
        setData(dataNew);
    };

    const handleSubmit = () => {
        if (StringUtil.isNullOrEmty(data.maHoSo)) {
            setTabRequired(-0);
            showMessageAlert("Không có mã hồ sơ", false);
            return;
        }

        setTabRequired(-1);
        setSubmit(true);
        if (
            StringUtil.isNullOrEmty(data.soGiayPhepCu) ||
            StringUtil.isNullOrEmty(data.hoTen) ||
            data.loaiGiayTo <= 0 ||
            StringUtil.isNullOrEmty(data.soGiayToTuyThan) ||
            // data.loaiChuThe <= 0 ||
            StringUtil.isNullOrEmty(data.tenHoKinhDoanh) ||
            StringUtil.isNullOrEmty(data.maQuan) ||
            StringUtil.isNullOrEmty(data.maPhuong) ||
            StringUtil.isNullOrEmty(data.tenDuong) ||
            data.ngayBienDong == 0 || data.ngayBienDong === null || data.ngayBienDong === undefined
        ) {
            setTabRequired(0);
            return;
        }
        if (!ValidatorUtil.validatorSoGiayTo(data.loaiGiayTo, data.soGiayToTuyThan)) {
            setTabRequired(-0);
            return;
        }
        if (data?.ngaySinh && data?.ngaySinh > 0) {
            if (!DateUtil.is18Years(data?.ngaySinh)) {
                showMessageAlert('Ngày sinh phải lớn hơn hoặc bằng 18 tuổi', false);
                setTabRequired(-0);
                return;
            }

        }
        setSubmit(false);
        dispatchLoading(showLoading());
        dangKyKinhDoanhService.insertOrUpdate({ ...data, diaChiKinhDo, diaChiViDo }).then((res) => {
            if (res != null) {
                if (res.data.error_code == "SUCCESSFUL") {
                    setDataParam("data", res.data.data);
                    showMessageAlert("Yêu cầu thực hiện thành công", true);
                } else {
                    showMessageAlert(res.data.error_message, false);
                }
            } else {
                showMessageAlert("Yêu cầu thực hiện thất bại", false);
            }
        }).catch((error) => {
            showMessageAlert("Yêu cầu thực hiện thất bại", false);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    };

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert("Yêu cầu thực hiện thành công", true);
        }
        setOpenDeleteUserDlg(false);
    };

    const resetData = () => {
        const dataNew = {
            ...HoSoThuLyDTO_INIT,
            soGiayPhepCu: "",
            id: data.id,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc,
            tinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten,
            maBienDong: LOAIBIENDONG_DIEUCHINH.ma,
            tenBienDong: LOAIBIENDONG_DIEUCHINH.ten,
        };
        setData(dataNew);
    };

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };
    const loadGiayPhepDaCap = (soGiayPhep: string) => {
        dangKyKinhDoanhService
            .getInfoAutoBySoGiayPhep(soGiayPhep, TINHTRANG_HOATDONG.ma)
            .then((res) => {
                let dataNew = res?.data.data;
                if (dataNew !== null && dataNew !== undefined) {
                    debugger
                    setData({
                        ...dataNew,
                        id: data.id,
                        files: data.files,
                        giayPhepCu: { ...dataNew, hoSoThuLyId: dataNew.id, id: data.giayPhepCu?.giayPhepCuId },
                        soGiayPhepCu: soGiayPhep,
                        ngayBienDong: data.ngayBienDong,
                        maHoSo: history?.location?.state?.maHoSo,
                        maThuTuc: history?.location?.state?.maThuTuc,
                        tinhTrang: TINHTRANG_HOATDONG.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_DIEUCHINH.ma,
                        tenBienDong: LOAIBIENDONG_DIEUCHINH.ten,
                    });
                } else {
                    setAlertMessage({ open: true, content: "Số giấy phép không tồn tại trong dữ liệu", severity: "warning" });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dispatchLoading(showLoading());
        dangKyKinhDoanhService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo, soGiayPhep: data.soGiayPhep }).then((res) => {
            if (res && res.data) {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    };

    const [locater, setLocater] = useState<any>();

    useEffect(() => {
        loadModules(["esri/config", "esri/tasks/Locator"]).then(([esriConfig, Locator]) => {
            esriConfig.apiKey = API_KEY_MAP;
            const locater = new Locator({
                url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
            });
            setLocater(locater);
        });
    }, []);

    useEffect(() => {
        if (locater) {
            const address = {
                SingleLine: StringUtil.diaChiToString({
                    diaChiTinhName: "Thành phố Cần Thơ",
                    diaChiHuyenName: data.tenQuan,
                    diaChiXaName: data.tenPhuong,
                    duong: data.tenDuong,
                    soNha: data.soNha,
                }),
            };

            const params = {
                address: address,
                forStorage: false,
            };
            locater.addressToLocations(params).then((response: any) => {
                if (response && response.length > 0) {
                    const { location } = response[0];
                    const { longitude, latitude } = location;
                    setDiaChiKinhDo(longitude);
                    setDiaChiViDo(latitude);
                }
            });
        }
    }, [locater, data]);

    return (
        <>
            {
                <Paper square>
                    <OverlayLoading />
                    <Snackbar
                        open={alertMessage.open}
                        onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                            {alertMessage.content}
                        </CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw
                        id="confirm-dialog-delete-dkkd"
                        keepMounted
                        open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                            <br></br>
                            <h6 style={{ fontSize: "15px", textDecoration: "underline", fontWeight: "bold" }}> */}
                        <div className="dash-content">
                            <div className="dash-title">
                                ĐĂNG KÝ THAY ĐỔI NỘI DUNG ĐĂNG KÝ HỘ KINH DOANH - MÃ HỒ SƠ : {data.maHoSo}
                            </div>
                        </div>
                        {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange}>
                                    <CustomTab style={tabRequired == 0 ? { color: "red" } : {}} label="Thông tin chung" />
                                    {data.loaiChuThe == 1 ? (
                                        <CustomTab style={tabRequired == 1 ? { color: "red" } : {}} label="Thành viên góp vốn" />
                                    ) : (
                                        <CustomTab style={tabRequired == 1 ? { color: "red" } : {}} label="Nội dung cũ" />
                                    )}
                                    {data.loaiChuThe == 1 ? <CustomTab style={tabRequired == 2 ? { color: "red" } : {}} label="Nội dung cũ" /> : ""}
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {indexTab === 0 && (
                            <TabPanel value={indexTab} index={0}>
                                <ThanhLapHKDThayDoiNoiDungComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    data={data}
                                    loadGiayPhepDaCap={loadGiayPhepDaCap}
                                />
                            </TabPanel>
                        )}
                        {data.loaiChuThe == 1
                            ? indexTab === 1 && (
                                <TabPanel value={indexTab} index={1}>
                                    <ThanhLapHKDThanhVienGopVon onSetDataMems={setDataMems} onDeleteMems={onDeleteMems} dataList={data.thanhVienGopVons} />
                                </TabPanel>
                            )
                            : indexTab === 1 && (
                                <TabPanel value={indexTab} index={1}>
                                    <ThayDoiHKDNoiDungCuComponent data={data} />
                                </TabPanel>
                            )}
                        {data.loaiChuThe == 1
                            ? indexTab === 2 && (
                                <TabPanel value={indexTab} index={2}>
                                    <ThayDoiHKDNoiDungCuComponent data={data} />
                                </TabPanel>
                            )
                            : ""}
                        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                            <ThuLyButtonComponent
                                showIn={data.id > 0}
                                onSubmit={handleSubmit}
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onDelete={handleDelete}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    );
};
export default ThanhLapHKDThayDoiComponent;
