import moment from "moment";
import { DiaChiDTO } from "../../api/danhMuc/model/DanhMucDTO";
export default class StringUtil {

    static viewNumberDot(value: string): string {
        if(!StringUtil.isNullOrEmty(value)&& value.includes('.')){
            return value.replace('.',',');
        }
        return value;
    }
    static getNumberDot(value: string): string {
        if(!StringUtil.isNullOrEmty(value) && value.includes(',')){
            return value.replace(',','.');
        }
        return  value;
     }

    static isNullOrEmty(str: any): boolean {
        if (typeof str === "undefined" || !str || str.length === 0 || str === "" || !/[^\s]/.test(str) || /^\s*$/.test(str)) return true;
        else return false;
    }

    static isNotEmpty(str: any): boolean {
        if (typeof str !== "undefined" && str !== null && str.length !== null && str.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    static isEmty(str: any): boolean {
        if (!str || 0 === str.length) {
            return true;
        } else {
            return false;
        }
    }

    static getcurrentDate(): string {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate());
        let dates = new String();
        dates = currentDate.toISOString().substr(0, 10);
        return dates?.toString();
    }

    static toDate(): string {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate());
        currentDate.setMonth(currentDate.getMonth() + 1);
        let dates = new String();
        dates = currentDate.toISOString().substr(0, 10);
        return dates?.toString();
    }

    static convertDatToSecond(date: String): number {
        if (date) {
            const myDate = new Date(date.toString());
            var seconds = myDate.getTime();
            return seconds;
        }
        return 0;
    }

    static objectIsNotNull(data: any): Boolean {
        if (Object.keys(data).length > 0) {
            return true;
        }
        return false;
    }

    static isEmtyArray(data: any): Boolean {
        if (data === undefined || data.length === 0) {
            return true;
        }
        return false;
    }
    static fomatDate(data: any): String {
        if (data && data > 0) {
            let myDate = new Date(data);
            return moment(myDate.toLocaleDateString()).format("YYYY-MM-DD")?.toString();
        }
        return "";
    }

    static formatMoney(str: any): string {
        if (!StringUtil.isNullOrEmty(str)) {
            str = str.toString().replace(/\D/g, "");
            return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        return "";
    }

    static convertToNumber(str: string): string {
        return str.replace(/[^\d]/g, "");
    }


    static checkSingleYear(str: string) {
        if (str.match(/^\d{1,4}$/g)) {
            return str;
        }
    }
    static checkFromToYear(str: string) {
        if (str.match(/^\d{1,4}-$/g)) {
            return str;
        }

        if (str.match(/^\d{0,4}-\d{0,4}$/g)) {
            return str;
        }

        if (str.match(/^\d{1,4}$/g)) {
            if (str.match(/^\d{0,4}-{0,1}\d{0,4}$/g)) {
                return str;
            }
            return str;
        }
    }

    static dateSecond(): number {
        const myDate = new Date(new Date());
        myDate.setHours(23, 59, 59, 59);
        var seconds = myDate.getTime();
        return seconds;
    }

    static diaChiToString(values: DiaChiDTO): string {
        return Object.entries(values)
            .map(([key, value], index) => {
                if (key.match("soNha")) {
                    return value;
                }
                if (key.match("diaChiXaName")) {
                    return value;
                }
                if (key.match("diaChiHuyenName")) {
                    return value;
                }
                if (key.match("diaChiTinhName")) {
                    return value;
                }
            })
            .filter((item) => item !== null && item)
            .join(", ");
    }

    static  stringDateToNumber(value:any): number{
        let result= 0;
        let date= null;
        if(value != null && !StringUtil.isNullOrEmty(value)){
            date = moment(value, 'DD/MM/YYYY').toDate();
            if(date){
                result=  date.getTime();
            }
        }
        return result;
    }

    static  stringDateToNumber2(value:any): number{
        let result= 0;
        let date= null;
        if(value != null && !StringUtil.isNullOrEmty(value)){
            date = moment(value, 'YYYY-MM-DD HH:MM:SS').toDate();
            if(date){
                result=  date.getTime();
            }
        }
        return result;
    }

    static  replaceDots(value:string): string{
        let result= "";
        if(!StringUtil.isNullOrEmty(value)){
            result = value.replace(/\./g,"")
        }
        return result;
    }

    static listIsNotEmty(arr: any): boolean {
        if (arr !== null && arr !== undefined && arr.length > 0) {
            return true;
        }
        return false;
    }

    static checkInputNumber(str: string) {
        if (/^[1-9]?[\d]+$/g.test(str)) {
            return true;
        }
        return false;
    }

    static formatForDot(str: any): string {
        if (!StringUtil.isNullOrEmty(str)) {
            str = str.toString().replace(/\D/g, "");
            return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
        }
        return "";
    }

    static allowNumberOrDot(str: string): boolean {
       const rgx = /^[0-9]*\,?[0-9]*$/;
       return rgx.test(str);
    }

    static tinhDienTich(dienTichKhuonVien : Number , dienTichKhuonVienHienHuu : Number) : Number{
        const dienTich  :Number = (Number(dienTichKhuonVien) -  Number(dienTichKhuonVienHienHuu));
        return Number(dienTich);
    }

    static getMaMau(maMauTrangThai : Number ) : string{
        if(maMauTrangThai === 0){
            return "none";
        }else if(maMauTrangThai === 1){
            return "red";
        }else{
            return "yellow";
        }

    }

    static setColor(maTrangThai: string) : string{
        if (maTrangThai == "HD") {
            return "blue";
        } else if (maTrangThai == "TH") {
            return "gray";
        } else {
            return "red";
        }
    }

}
