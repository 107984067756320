import { BaseResponse, BaseService, PageRequest, Pagination } from "../../baseService";
import axios from "../../index";

export interface DanhMucKhenThuongDTO {
    id: string;
    name: string;
    description: string;
    isActive: boolean;
    type: Type;
}
export enum Type {
    HINH_THUC = 1,
    DANH_HIEU = 2
}
export interface DanhMucKhenThuongRequest extends PageRequest {
    type?: Type;
    isActive?: Boolean;
}
export class DanhMucKhenThuongService extends BaseService {

    getDMKhenThuong = async (request: DanhMucKhenThuongRequest): Promise<Pagination<DanhMucKhenThuongDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        } as Pagination<DanhMucKhenThuongDTO[]>;
        await axios.get('/danh-muc-khen-thuong/search', {
            params: request
        }).then(response => {
            if (response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content
                }
            }
        }).catch();
        return result;
    }

    insertOrUpdate = async (request: DanhMucKhenThuongDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc-khen-thuong/insertOrUpdate', JSON.stringify(request), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }

    deleteById = async (id: string): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc-khen-thuong/delete', JSON.stringify({ id: id }), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }


}
