import React, { Dispatch, useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { Button, Grid, InputBase, InputLabel, SvgIcon, Link, Snackbar } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { DanhSachGiayPhepKD } from "./danhSachGiayPhep";
import { QuanLyDKKDService, RequestDTO } from "../../../../api/DKKD/QuanLyDangKyKinhDoanh/quanLyDKKDService";
import { TinhTrangDTO } from "../../../../api/DKKD/model/TinhTrangDTO";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import { DanhSachGiayPhepDKKDDTO } from "../../../../api/DKKD/model/DanhSachGiayPhepDKKDDTO";
import { DanhMucDiaChiDTO } from "../../../../api/danhMuc/model/DanhMucDTO";
import { DanhMucService } from "../../../../api/danhMuc/danhMucService";
import StringUtil from "../../../utils/stringUtils";
import { LIST_TINHTRANG } from "../../../Constants/DKKDConstants";
import { useHistory } from "react-router-dom";
import { getObject } from "../../../../helpers/localStorage";
import ShowSoCMNDModal from "../ThuLyHoSo/CapMoi/showSoCMNDModal";
import { HoSoThuLyDTO } from "../../../../api/DKKD/model/hoSoThuLyDTO";
import { DangKyKinhDoanhService } from "../../../../api/DKKD/DangKyKinhDoanhService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        faIcon: {
            fontSize: 36,
            padding: theme.spacing(1)
        }
    }),
);
interface TabQuanLyDangKyKinhDoanhProps {
    traCuuATTP?: boolean;
    isAnUong: boolean;

}
export const TabQuanLyDangKyKinhDoanh = (props: TabQuanLyDangKyKinhDoanhProps) => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataList, setDataList] = useState<DanhSachGiayPhepDKKDDTO[]>([]);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [dataTinhTrang, setDataTinhTrang] = useState<TinhTrangDTO[]>([]);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [phuongTruSos, setPhuongTruSos] = useState<DanhMucDiaChiDTO[]>([]);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [checkCMND, setCheckCMND] = React.useState(false);
    const danhMucService = new DanhMucService();
    const [dataFromBD, setDataFromBD] = React.useState({} as HoSoThuLyDTO);
    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const xuLyOrDauKy: { code: string, name: string }[] = [
        { "code": "XU_LY", "name": "Xử lý" },
        { "code": "DAU_KY", "name": "Đầu kỳ" },
    ];
    const formik = useFormik({
        initialValues: {
            request: {
                pageNumber: 1,
                pageSize: 5,
                soGiayChungNhan: '',
                tenHoKinhDoanh: '',
                maPhuong: '',
                tenPhuong: '',
                ngayCapFrom: 0,
                ngayCapTo: 0,
                tongSoVon: 0,
                maTinhTrang: '',
                tenTinhTrang: 'Chọn trạng thái',
                exportType: '',
                exportFileName: '',
                tongSoVonTu: 0,
                tongSoVonDen: 0,
                diaChiDayDu: '',
                maQuanHuyen: C_USER_DATA.maQuanHuyen,
                tenQuanHuyen: C_USER_DATA.tenQuanHuyen,
                traCuuATTP: props.traCuuATTP ? props.traCuuATTP : false,
                soGiayToTuyThan: '',
                hoTen: '',
                xuLyOrDauKy: '',
            } as RequestDTO,
        },
        onSubmit: () => loadDataTable(),
    });
   
    useEffect(() => {
        async function getDm() {
            await new DanhMucService().getDanhMucDiaChi(String(92)).then((resp) => {
                setQuans(resp);
            });
        }
        getDm();
    }, []);

    useEffect(() => {
        loadTinhTrang();
        let request = {
            ...formik.values.request,
            pageSize: size,
            pageNumber: page,
        } as RequestDTO;
        dispatchLoading(showLoading());
        new QuanLyDKKDService().loadDanhSach(request).then((p) => {
            setDataList(p.rows);
            setTotal(p.total);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }, [page, size])

    const loadTinhTrang = async () => {
        dispatchLoading(showLoading());
        await new QuanLyDKKDService().loadDanhMucTinhTrang().then((p) => {
            setDataTinhTrang(p.data.data);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const loadDataTable = async () => {
        let request = {
            ...formik.values.request,
            pageSize: size,
            pageNumber: 1,
        } as RequestDTO;
        dispatchLoading(showLoading());
        await new QuanLyDKKDService().loadDanhSach(request).then((p) => {
            setDataList(p.rows);
            setTotal(p.total);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const downloadFile = async (extension: string) => {
        let request = {
            ...formik.values.request,
            exportType: extension,
            exportFileName: 'Danh_sach_ho_so_thu_ly'
        } as RequestDTO;
        dispatchLoading(showLoading());
        await new QuanLyDKKDService().downloadFile(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'Danh_sach_ho_so_thu_ly');
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1);
        setSize(Number(event.target.value));
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const handleChangeNumber = (e: any) => {
        let value = e.target.value;
        formik.setFieldValue(`${e.target.name}`, StringUtil.convertToNumber(value));
    }

    const loadPhuongs = (maQuan: string) => {
        if (!StringUtil.isEmty(maQuan)) {
            danhMucService.getDanhMucDiaChi(maQuan).then(res => {
                setPhuongTruSos(res);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const handleToggle = () => {
        setCheckCMND(!checkCMND);
    };

    const onLoadGP = (soGiayToTuyThan: string) => {
        if (StringUtil.isNullOrEmty(soGiayToTuyThan)) {
            setAlertMessage({ open: true, content: "Số giấy tờ tùy thân không được trống!", severity: "warning" });
        } else {
            dangKyKinhDoanhService.getInfoByKeyWord(soGiayToTuyThan)
                .then((res) => {
                    let dataNew = res?.data.data;
                    if (dataNew !== null && dataNew !== undefined) {
                        setDataFromBD({
                            ...dataNew,
                        });
                        setCheckCMND(!checkCMND);
                    } else {
                        setAlertMessage({ open: true, content: "Số giấy tờ tùy thân chưa được cấp giấy phép kinh doanh", severity: "warning" });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    return (
        <>
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <OverlayLoading />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                </Snackbar>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Số giấy chứng nhận</InputLabel>
                            <InputBase fullWidth name="request.soGiayChungNhan"
                                value={formik.values.request.soGiayChungNhan} onChange={formik.handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Ngày cấp từ</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    placeholder="dd/MM/yyyy"
                                    value={formik.values.request?.ngayCapFrom > 0 ? new Date(formik.values.request?.ngayCapFrom) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true, }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => formik.setFieldValue("request.ngayCapFrom", date?.toDate()?.getTime())}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Ngày cấp đến</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    placeholder="dd/MM/yyyy"
                                    value={formik.values.request?.ngayCapTo > 0 ? new Date(formik.values.request.ngayCapTo) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true, }}
                                    invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                    onChange={(date: any, value: any) => formik.setFieldValue("request.ngayCapTo", date?.toDate()?.getTime())}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Trạng thái</InputLabel>
                            <Autocomplete id="tinhTrang" options={LIST_TINHTRANG}
                                value={{ ma: formik.values?.request?.maTinhTrang ? formik.values.request?.maTinhTrang : "", ten: formik.values?.request?.tenTinhTrang ? formik.values?.request?.tenTinhTrang : "" }}
                                getOptionLabel={(option) => option.ten}
                                onChange={(event: any, newValue: any) => {
                                    formik.setFieldValue("request.maTinhTrang", newValue?.ma);
                                    formik.setFieldValue("request.tenTinhTrang", newValue?.ten);
                                }}

                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth {...params.inputProps} />
                                    </div>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Huyện/Thị/TP</InputLabel>
                            {
                                StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                                <Autocomplete id="maQuanHuyen" options={quans}
                                    value={{ code: formik.values?.request?.maQuanHuyen ? formik.values.request?.maQuanHuyen : "", name: formik.values?.request?.tenQuanHuyen ? formik.values.request.tenQuanHuyen : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => {
                                        formik.setFieldValue("request.maPhuong", "");
                                        formik.setFieldValue("request.tenPhuong", "");
                                        formik.setFieldValue("request.maQuanHuyen", newValue?.code);
                                        formik.setFieldValue("request.tenQuanHuyen", newValue?.name);
                                    }}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth {...params.inputProps} />
                                        </div>
                                    }}
                                />
                            }
                            {
                                !StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                                <InputBase disabled fullWidth value={formik.values.request.tenQuanHuyen} />
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Phường/Xã/TT</InputLabel>
                            <Autocomplete id="maPhuong" options={phuongTruSos}
                                onFocus={(e) => loadPhuongs(formik.values?.request?.maQuanHuyen)}
                                value={{ code: formik.values?.request?.maPhuong ? formik.values.request?.maPhuong : "", name: formik.values?.request?.tenPhuong ? formik.values.request.tenPhuong : "" }}
                                getOptionLabel={(option) => option?.name}
                                onChange={(event: any, newValue: any) => {
                                    formik.setFieldValue("request.maPhuong", newValue?.code);
                                    formik.setFieldValue("request.tenPhuong", newValue?.name);
                                }}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth {...params.inputProps} />
                                    </div>
                                }}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Số giấy tờ tùy thân</InputLabel>
                            <InputBase fullWidth name="request.soGiayToTuyThan"
                                value={formik.values.request.soGiayToTuyThan} onChange={formik.handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                                <Button type="button" variant="contained" style={{ fontSize: "14px", marginTop: "20px", width: "100px" }} onClick={(e) => onLoadGP(formik.values?.request?.soGiayToTuyThan)}>
                                    Kiểm tra
                                </Button>
                                &nbsp;&nbsp;
                            </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Tổng số vốn từ</InputLabel>
                            <InputBase fullWidth name="request.tongSoVonTu"
                                value={StringUtil.formatForDot(formik.values.request.tongSoVonTu)} onChange={handleChangeNumber} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Tổng số vốn đến</InputLabel>
                            <InputBase fullWidth name="request.tongSoVonDen"
                                value={StringUtil.formatForDot(formik.values.request.tongSoVonDen)} onChange={handleChangeNumber} />
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <InputLabel >Địa chỉ đầy đủ</InputLabel>
                            <InputBase fullWidth name="request.diaChiDayDu"
                                value={formik.values.request.diaChiDayDu} onChange={formik.handleChange} />
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <InputLabel>Họ tên</InputLabel>
                            <InputBase fullWidth name="request.hoTen"
                                value={formik.values.request.hoTen} onChange={formik.handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Tên hộ kinh doanh</InputLabel>
                            <InputBase fullWidth name="request.tenHoKinhDoanh"
                                value={formik.values.request.tenHoKinhDoanh} onChange={formik.handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Xử lý hoặc đầu kỳ</InputLabel>
                            <Autocomplete id="xuLyOrDauKy" options={xuLyOrDauKy}
                                getOptionLabel={(option) => option.name}
                                onChange={(event: any, newValue: any) => {
                                    formik.setFieldValue("request.xuLyOrDauKy", newValue?.code);
                                }}

                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth {...params.inputProps} />
                                    </div>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} className="grid-item" container direction="row" justify="flex-end" alignItems="center">
                            <Button onClick={loadDataTable} type="button" variant="contained" color="primary"
                                className="none-margin" disableElevation >
                                Tìm kiếm
                            </Button>
                        </Grid>
                        <Grid item xs={6} className="grid-item" container direction="row" justify="flex-start" alignItems="center">
                            <Button onClick={() => history.push('/giay-phep-kinh-doanh/nhap-dau-ky')} type="button" variant="contained" color="primary"
                                className="none-margin" disableElevation>
                                Nhập đầu kỳ
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('docx');
                        }}>
                            <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('pdf');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('xlsx');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                            </SvgIcon>
                        </Link>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className="grid-item">
                            <DanhSachGiayPhepKD dataList={dataList} isAnUong={props?.isAnUong}
                                pageProps={{
                                    page: page, count: total, rowsPerPage: size,
                                    onChangePage: handleOnChangePage,
                                    onChangeRowsPerPage: handOnChangeRowsPerPage
                                }} />
                        </Grid>
                    </Grid>
                    {
                            checkCMND && (
                                <ShowSoCMNDModal dataFromBD={dataFromBD} onToggle={handleToggle} show={checkCMND} />
                            )}
                </MuiPickersUtilsProvider>
            </form>
           
            
        </>
    )
}
