import { RoutePropsI } from "..";
import { DashboardPublic } from "../../pages/dashboard/DashboardPublic";
import WrapperMapTraCuu from "../../pages/traCuuBanDo/wrapperTraCuuBanDo";
import { ROUTE_PAGE_PUBLIC_ATTP } from "../route-cn-attp";
import { ROUTE_PAGE_PUBLIC_CPXD } from "../route-cn-cpxd";
import { ROUTE_PAGE_PUBLIC_DKKD } from "../route-cn-dkkd";
import { ROUTE_PAGE_PUBLIC_LPG } from "../route-cn-lpg";
import { ROUTE_PAGE_PUBLIC_TDKT } from "../route-cn-tdkt";

export const ROUTE_PAGE_PUBLIC: RoutePropsI[] = [
    ...ROUTE_PAGE_PUBLIC_ATTP,
    ...ROUTE_PAGE_PUBLIC_CPXD,
    ...ROUTE_PAGE_PUBLIC_DKKD,
    ...ROUTE_PAGE_PUBLIC_LPG,
    ...ROUTE_PAGE_PUBLIC_TDKT,
    {
        ten: "Dashboard",
        giaoDien: <DashboardPublic />,
        duongDan: "/dashboard",
    },
    {
        ten: "Tra cứu bản đồ hộ kinh doanh",
        giaoDien: <WrapperMapTraCuu />,
        duongDan: "/tra-cuu-ban-do-ho-kinh-doanh",
    },
    {
        ten: "Tra cứu bản dồ ATTP",
        giaoDien: <WrapperMapTraCuu />,
        duongDan: "/tra-cuu-ban-do-attp",
    },
];
