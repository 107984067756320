import React, { useEffect } from 'react';
import { createStyles, FormControlLabel, Grid, InputLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import moment from "moment";
import { HoSoThuLyDTO } from "../../../../api/DKKD/model/hoSoThuLyDTO";
import StringUtil from '../../../utils/stringUtils';

interface ThongTinGPComponentComponentProps {
    data: HoSoThuLyDTO;
}

makeStyles(() =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
    }),
);
const ThongTinGPComponent = (props: ThongTinGPComponentComponentProps) => {
    const [data, setData] = React.useState({} as HoSoThuLyDTO);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <>
            {
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số giấy chứng nhận</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhep?.soGiayPhep}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.giayPhep != null && data?.giayPhep?.ngayCapPhep != null && data?.giayPhep?.ngayCapPhep > 0) ? moment(data?.giayPhep?.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Thông tin về đại diện hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Họ tên</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.hoTen}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Giới tính</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenGioiTinh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày sinh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.ngaySinh != null && data?.ngaySinh > 0) ? moment(data?.ngaySinh).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Dân tộc</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenDanToc}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Quốc tịch</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenQuocTich}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Loại giấy tờ tùy thân</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.loaiGiayTo == 1 ? 'Chứng minh nhân dân' : (data?.loaiGiayTo == 2 ? 'Căn cước công dân' : data?.loaiGiayTo == 3 ? 'Hộ chiếu' : '')}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số giấy tờ tùy thân</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soGiayToTuyThan}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.ngayCap != null && data?.ngayCap > 0) ? moment(data?.ngayCap).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Nơi cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenNoiCap}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày hết hạn</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.ngayHetHan != null && data?.ngayHetHan > 0) ? moment(data?.ngayHetHan).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chủ thể thành lập hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            {
                                data?.loaiChuThe == 1 &&
                                <InputLabel>{data?.tenChuThe}</InputLabel>
                            }
                            {/* <RadioGroup row aria-label="position">
                                <FormControlLabel
                                    checked={data?.loaiChuThe == 1}
                                    value="1"
                                    control={<Radio color="primary" size="small" />}
                                    label="Nhóm cá nhân"
                                    labelPlacement="end"
                                    disabled
                                />
                                <FormControlLabel
                                    checked={data?.loaiChuThe == 2}
                                    disabled
                                    value="2"
                                    control={<Radio color="primary" size="small" />}
                                    label="Cá nhân"
                                    labelPlacement="end"
                                />
                            </RadioGroup> */}
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Nơi đăng ký hộ khẩu thường trú</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.hoKhau}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chỗ ở hiện tại</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.choO}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Tên hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenHoKinhDoanh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Tổng số vốn (VNĐ)</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{StringUtil.formatForDot(data?.tongVon)}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số lượng lao động</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{StringUtil.formatForDot(data?.soLuongLaoDong)}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Lĩnh vực kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenLinhVuc}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Mặt hàng kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.matHang}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Ngành, nghề kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.tenDanhSachNganhNgheKinhDoanh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chi tiết ngành, nghề kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.chiTietNganhNghe}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Địa điểm kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.diaChiDayDu}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Điện thoại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.dienThoai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Fax</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.fax}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Email</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.email}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Website</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.website}</InputLabel>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
};
export default ThongTinGPComponent;
