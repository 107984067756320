import { Box, Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { useRef, useState } from "react";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FileEntry } from "../../../../../api/Common/fileEntry";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { FileServerService } from "../../../../../api/Common/FileServerService";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { AttachIcon } from "../../AttachFileTooltip/AttachFileTooltip";
import StringUtil from "../../../../utils/stringUtils";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0.5),
        },
        menuItem: {
            color: "#0821af",
            textDecoration: "none",
            padding: "10px 15px",
            '&:hover': {
                color: "#0821af",
                cursor: "pointer",
                backgroundColor: "#bfc1cc",
                fontWeight: "bold",
            },
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px'
        }
    }),
);

export const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
        },
        sizeSmall: {
            fontSize: '13px'
        },
        startIcon: {
            marginRight: theme.spacing(0.5)
        },
        '&:focus': {
            outlineWidth: '0px',
            outlineStyle: 'none'
        }
    }),
)(Button);

export interface TepDinhKemPopperProps {
    data: FileEntry[];
}

export const TepDinhKemPopper = (props: TepDinhKemPopperProps) => {
    const classes = useStyles();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const fileServerService = new FileServerService();
    const showHidePopperThaoTac = () => {
        setOpen(!open);
    }
    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const handleToggle = () => {
        setShowModal(!showModal);
    }

     const handleDownloadFile = async (file: FileEntry) => {
        if (file.motCua && StringUtil.isNotEmpty(file.url)){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            // fileServerService.downloadFile(file).then((res) => {
            //     downLoadFile(res.data, createFileType(file.extension), file.name);
            // });
            await fileServerService.downloadFileMethodGet(file).then((res) => {
                let blob = new Blob([res.data], { type: res.data.type });
                let url = window.URL.createObjectURL(blob);
                let link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${file.name}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            });
        }
    };
    return (
        <>
            <CustomButton className={classes.margin} startIcon={<AttachFileIcon />}
                size="small" color="primary" disableRipple
                ref={anchorRef} aria-haspopup="true"
                aria-controls={open ? 'menu-list-grow' : undefined} onClick={(e) => {
                    showHidePopperThaoTac();
                }} >
                {"(" + (props.data ? props.data.length : '0') + ")"}
            </CustomButton>
            <Popper style={{ zIndex: 9999 }} disablePortal open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                    {
                                        props.data?.map((file, k) => {
                                            return (
                                                <MenuItem key={k}>
                                                    <CustomButton className={classes.margin}
                                                        size="small" color="primary" disableRipple
                                                        onClick={() => handleDownloadFile(file)}
                                                        startIcon={<AttachIcon fileName={file.name} />}
                                                    >
                                                        {file.name}
                                                    </CustomButton>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}