import MomentUtils from "@date-io/moment";
import { Box, Paper, Snackbar, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useLayoutEffect, useState } from "react";
import { HoSoKhenThuongService } from "../../../../../api/KhenThuong/chuyenNganh/hoSoKhenThuongService";
import { HoSoKhenThuongDTO } from "../../../../../api/KhenThuong/models/hoSoKhenThuongDTO";
import { useQuerySoBienNhan } from "../../../../../hooks/useQuery";
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { CustomTab, CustomTabs } from "../../../../commons/components/tabs";
import { DANH_SACH_TEN_NGHIEP_VU } from "../../../../Constants/constants";
import { initDefaultValue } from "../commonsPropsInterface";
import { KhenThuongTapTheWrapper } from "./khenThuongTapThe/khenThuongTapTheWrapper";
import { ThongTinChung } from "./thongTinChung";
import { useLocation } from "react-router";
import StringUtil from "../../../../utils/stringUtils";
import { getObject } from "../../../../../helpers/localStorage";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`bussiness-tabpanel-${index}`} aria-labelledby={`bussiness-tab-${index}`} {...other}>
            <Box p={1}>
                <Typography component="div">{children}</Typography>
            </Box>
        </div>
    );
};

export const ChuyenNganhGiayPhepGiaDinhDauKy = () => {
    const soBienNhan = useQuerySoBienNhan();
    const [value, setValue] = useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const location = useLocation<any>();
    const labelTabs: String[] = ["Thông tin chung", "Khen thưởng gia đình"];

    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    function showMessageAlert(content: string, isSuccess: boolean = true) {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    }
    function handleCloseAlertMessage() {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    }

    const [formValueTTChung, setFormValueTTChung] = useState<HoSoKhenThuongDTO>(initDefaultValue);

    useLayoutEffect(() => {
        let id = location.state?.id;
        function initialData() {
            if (!StringUtil.isNullOrEmty(soBienNhan)) {
            new HoSoKhenThuongService().getBySoBienNhan(soBienNhan).then((resp) => {
                if (resp) {
                    setFormValueTTChung(resp);
                } else {
                    setFormValueTTChung((prev) => ({
                        ...prev,
                        soBienNhan: String(soBienNhan),
                    }));
                }
            });
            }else if (id) {
                new HoSoKhenThuongService().getById(id).then((resp) => {
                    if (resp) {
                        setFormValueTTChung(resp);
                    } else {
                        setFormValueTTChung((prev) => ({
                            ...prev,
                            soBienNhan: String(soBienNhan),
                            // id: id,
                        }));
                    }
                });
            }
    }
        initialData();
    }, [soBienNhan]);

    async function handleSubmitFormThongTinChung(dto: HoSoKhenThuongDTO) {
        dto.maCoQuan = C_USER_DATA.maCoQuan;
        dto.tenCoQuan = C_USER_DATA.tenCoQuan;
        dto.maQuanHuyen = C_USER_DATA.maQuanHuyen;
        dto.tenQuanHuyen = C_USER_DATA.tenQuanHuyen;
        dto.userId = C_USER_DATA.userId;
        await new HoSoKhenThuongService().insertOrUpdate(dto).then((resp) => {
            if (resp.isSuccess) {
                setFormValueTTChung(resp.data);
                showMessageAlert(resp.message);
            } else {
                showMessageAlert(resp.message, false);
            }
        });
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="dash-content">
                <div className="dash-title">
                    <span>{DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_6.NAME}</span>
                </div>
                <Paper component="div">
                    <Box mt="5px" bgcolor="#f2f2f2">
                        <CustomTabs value={value} onChange={handleChange}>
                            <CustomTab label="Thông tin chung" />
                            <CustomTab label="Khen thưởng gia đình" disabled={formValueTTChung.id==null}/>
                        </CustomTabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ThongTinChung formValues={formValueTTChung} onSubmitFormThongTinChung={handleSubmitFormThongTinChung} />
                    </TabPanel>
                    { Number(formValueTTChung.id) > 0 ?
                        <TabPanel value={value} index={1}>
                            <KhenThuongTapTheWrapper formValues={formValueTTChung}/>
                        </TabPanel>
                    : ""}
                </Paper>
            </div>
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                    {alertMessage.content}
                </CustomAlert>
            </Snackbar>
        </MuiPickersUtilsProvider>
    );
};
