import {
    Button,
    createStyles,
    Grid,
    InputBase,
    InputLabel,
    Link,
    makeStyles,
    NativeSelect,
    Snackbar,
    SvgIcon,
    TextField,
    Theme
} from "@material-ui/core";
import React, {Dispatch, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {hideLoading, LoadingAction, showLoading} from "../../../../redux/actions/applicationAction";
import {OverlayLoading} from "../../../commons/components/overlayLoading/overlayLoading";
import {PageRequest} from "../../../../api/baseService";
import {AlertMessage, CustomAlert} from "../../../commons/components/alert";
import {DanhSachThongKeCapPhep} from "./DanhSachThongKeCapPhep";
import {HUYEN, PHUONG} from "../../../../api/QuanTri/DanhMucFakeService";
import {Autocomplete} from "@material-ui/lab";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {useFormik} from "formik";
import {RequestBaoCaoThongKeDTO} from "../BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import {createFileType, downLoadFile} from "../../../../api/Common/exportUtil";
import { BienDongDTO, ThongKeTinhHinhCapPhepResponse } from "../../../../api/VSATTP/Model/VSATTPModel";
import { ThongKeService } from "../../../../api/VSATTP/Services/ThongKeService";
import { DanhMucService } from "../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../api/danhMuc/model/DanhMucDTO";
import StringUtil from "../../../utils/stringUtils";
import { getObject } from "../../../../helpers/localStorage";
import { ChiTietThongKeCapPhep } from "./ChiTietThongKeCapPhep";

export interface RequestThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
    thangThongKe: number;
    tenThangThongKe:string;
    loaiNuaNam: number;
    tenLoaiNuaNam: string;
    quyThongKe: number;
    tenQuyThongKe: string;
    maPhuongXa: string;
    tenPhuongXa: string;
    tinhTrang: string;
    tenTinhTrang: string;
    tuNgay: number;
    denNgay: number;
    kyThongKe: number;
    keywords: string;
    maQuanHuyen : string;
    tenQuanHuyen: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px"
        },
    }),
);

export const ThongKeTinhHinhCapPhep = () => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<ThongKeTinhHinhCapPhepResponse>();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'warning', open: false } as AlertMessage);
    const [showDate, setShowDate] = useState(true);
    const [dataBienDong, setDataBienDong] = useState<BienDongDTO[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [dataThangThongKe, setDataThangThongKe] = useState<any[]>([]);
    const [dataQuyThongKe, setDataQuyThongKe] = useState<any[]>([]);
    const [dataNuaNamThongKe, setDataNuaNamThongKe] = useState<any[]>([]);
    const danhMucService = new DanhMucService();
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const thongKeService = new ThongKeService();
    const [submit, setSubmit] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    useEffect(() => {
        formik.setValues({
            request: {
                namThongKe: new Date().getFullYear(),
                tenNamThongKe: 'Năm ' + new Date().getFullYear(),
                thangThongKe: 0,
                tenThangThongKe:'Chọn tháng',
                loaiNuaNam: 0,
                quyThongKe: 0,
                maPhuongXa: '',
                tenPhuongXa: '',
                tinhTrang: '',
                tenTinhTrang: 'Chọn tình trạng',
                tuNgay: 0,
                denNgay: 0,
                kyThongKe: 1,
                keywords: '',
                maQuanHuyen : C_USER_DATA.maQuanHuyen,
                tenQuanHuyen : C_USER_DATA.tenQuanHuyen 
            } as RequestThongKeDTO,
        });
        namThongKeList();
        thangThongKeList();
        quyThongKeList();
        nuaNamList();
        loadDataTable();
        loadDataBienDong();
        loadQuan();
    }, []);

    const formik = useFormik({
        initialValues: {
            request: {
                namThongKe: new Date().getFullYear(),
                tenNamThongKe: 'Năm ' + new Date().getFullYear(),
                thangThongKe: 0,
                tenThangThongKe:'Chọn tháng',
                loaiNuaNam: 0,
                tenLoaiNuaNam:'Chọn',
                quyThongKe: 0,
                tenQuyThongKe:'Chọn quý',
                maPhuongXa: '',
                tenPhuongXa: '',
                tinhTrang: '',
                tenTinhTrang: 'Chọn tình trạng',
                tuNgay: 0,
                denNgay: 0,
                kyThongKe: 1,
                keywords: '',
                maQuanHuyen : C_USER_DATA.maQuanHuyen
            } as RequestThongKeDTO,
        },
        onSubmit: () => loadDataTable(),
    });

    const [detailRequest, setDetailRequest] = useState<any>({ ...formik.values.request });

    useEffect(() => {
        setDetailRequest({...detailRequest, ...formik.values.request});
    }, [formik.values]);

    const loadDataTable = async () => {
        if (StringUtil.isEmty(formik.values.request?.maQuanHuyen)) {
            showMessageAlert('Vui lòng chọn Huyện/Thị/TP', false);
            return;
        }

        if(formik.values.request?.kyThongKe == 2){
            if(formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay){
                showMessageAlert('Từ ngày không được lớn hơn đến này', false);
                return;
            }
        }
        if(formik.values.request?.kyThongKe == 1){
            if(formik.values.request?.thangThongKe != 0 && formik.values?.request?.namThongKe == 0){
                showMessageAlert('Vui lòng chọn năm', false);
                return;
            }
        }
        if(formik.values.request?.kyThongKe == 2){
            if(formik.values.request?.quyThongKe != 0 && formik.values?.request?.namThongKe == 0){
                showMessageAlert('Vui lòng chọn năm', false);
                return;
            }
        }
        if(formik.values.request?.kyThongKe == 3){
            if(formik.values.request?.loaiNuaNam != 0 && formik.values?.request?.namThongKe == 0){
                showMessageAlert('Vui lòng chọn năm', false);
                return;
            }
        }
        if(formik.values.request?.kyThongKe == 4){
            if(formik.values?.request?.namThongKe == 0){
                showMessageAlert('Vui lòng chọn năm', false);
                return;
            }
        }
        let request = {
            ...formik.values.request,
        } as RequestThongKeDTO;
        setDetailRequest({ ...formik.values.request });
        dispatchLoading(showLoading());
        await new ThongKeService().thongKe(request).then((p) => {
            setData(p);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [];
        for(let i = namHienTai; i >= namHienTai - 5; --i){
            var temp = {nam: i, name: 'Năm ' + i};
            temps.push(temp);
        }
        setDataNamThongKe(temps);
    }
    const thangThongKeList = () => {
        let temps = [{thang: 0, name: 'Chọn tháng'}];
        for(let i = 0 ; i <= 11; i++){
            var temp = {thang: (1 + i), name: 'Tháng ' + (1+i)};
            temps.push(temp);
        }
        setDataThangThongKe(temps);
    }
    const quyThongKeList = () => {
        let temps = [{quy: 0, name: 'Chọn qúy'}];
        for(let i = 0 ; i < 4; i++){
            var temp = {quy: (1 + i), name: 'Quý ' + (1+i)};
            temps.push(temp);
        }
        setDataQuyThongKe(temps);
    }
    const nuaNamList = () => {
        let temps = [{loaiNuaNam: 0, name: 'Chọn'},
        {loaiNuaNam: 1, name: '6 tháng đầu năm'},
        {loaiNuaNam: 2, name: '6 tháng cuối năm'},
    ];
        setDataNuaNamThongKe(temps);
    }
    const loadDataBienDong = async () => {
        let request = {
            ...formik.values.request
        } as RequestBaoCaoThongKeDTO;
        dispatchLoading(showLoading());
        await new ThongKeService().danhMucBienDong(request).then((p) => {
            setDataBienDong(p.rows);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'warning' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const handleChangeKyThongKe = (value: any) => {
        // if(value == 1){
        //     setShowDate(true);
        // } else{
        //     setShowDate(false);
        // }
    }
    const downloadFile = async (extension: string) => {
        if(formik.values.request?.kyThongKe == 1){
            if(formik.values.request?.thangThongKe != 0 && formik.values?.request?.namThongKe == 0){
                showMessageAlert('Vui lòng chọn năm', false);
                return;
            }
        }
        if(formik.values.request?.kyThongKe == 2){
            if(formik.values.request?.quyThongKe != 0 && formik.values?.request?.namThongKe == 0){
                showMessageAlert('Vui lòng chọn năm', false);
                return;
            }
        }
        if(formik.values.request?.kyThongKe == 3){
            if(formik.values.request?.loaiNuaNam != 0 && formik.values?.request?.namThongKe == 0){
                showMessageAlert('Vui lòng chọn năm', false);
                return;
            }
        }
        if(formik.values.request?.kyThongKe == 4){
            if(formik.values?.request?.namThongKe == 0){
                showMessageAlert('Vui lòng chọn năm', false);
                return;
            }
        }

        let request = {
            ...formik.values.request,
            exportType: extension,
            exportFileName: 'Danh_sach_thong_ke_cap_phep',
            tenThongke: 'THỐNG KÊ TÌNH HÌNH CẤP PHÉP GIẤY CHỨNG NHẬN VỆ SINH AN TOÀN THỰC PHẨM'
        };
        dispatchLoading(showLoading());
        await new ThongKeService().downloadFileThongKe(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'Danh_sach_thong_ke_cap_phep');
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const loadPhuongs = async () => {
        await  danhMucService
        .getDanhMucDiaChi(formik.values?.request?.maQuanHuyen)
        .then((res) => {
            if (res &&  res?.length > 0) {
                setPhuongs(res);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const loadQuan = async () => {
        await  danhMucService
        .getDanhMucDiaChi("92")
        .then((res) => {
            if (res &&  res?.length > 0) {
                setQuans(res);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };


    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>THỐNG KÊ TÌNH HÌNH CẤP PHÉP VSATTP</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Kỳ thống kê</InputLabel>
                        <NativeSelect fullWidth disableUnderline name="kyThongKe" value={formik.values?.request?.kyThongKe} onChange ={
                            (event: any) => {
                                formik.setFieldValue("request.kyThongKe", event.target.value);
                                // handleChangeKyThongKe(event.target.value);
                            }
                        } >
                             <option value="1">Tháng</option>
                            <option value="2">Quý</option>
                            <option value="3">6 tháng</option>
                            <option value="4">Năm</option>
                            <option value="5">Khoảng thời gian</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={!(formik.values?.request?.kyThongKe == 1)}>
                        <InputLabel >Tháng </InputLabel>
                        <NativeSelect value={formik.values?.request?.thangThongKe} name="thangThongKe"
                            onChange={(event: any) => {
                                formik.setFieldValue("request.thangThongKe", event.target.value);
                            }} input={<InputBase fullWidth />} >
                            {
                                dataThangThongKe.map((thang, i) => (
                                    <option key={i} value={thang.thang}>{thang.name}</option>
                                ))
                            }
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={3} className="grid-item" hidden={!(formik.values?.request?.kyThongKe == 2)}>
                    <InputLabel >Quý </InputLabel>
                        <NativeSelect value={formik.values?.request?.quyThongKe} name="quyThongKe"
                            onChange={(event: any) => {
                                formik.setFieldValue("request.quyThongKe", event.target.value);
                            }} input={<InputBase fullWidth />} >
                            {
                                dataQuyThongKe.map((quy, i) => (
                                    <option key={i} value={quy.quy}>{quy.name}</option>
                                ))
                            }
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={3} className="grid-item" hidden={!(formik.values?.request?.kyThongKe == 3)}>
                    <InputLabel >6 tháng </InputLabel>
                        <NativeSelect value={formik.values?.request?.loaiNuaNam} name="loaiNuaNam"
                            onChange={(event: any) => {
                                formik.setFieldValue("request.loaiNuaNam", event.target.value);
                            }} input={<InputBase fullWidth />} >
                            {
                                dataNuaNamThongKe.map((loaiNuaNam, i) => (
                                    <option key={i} value={loaiNuaNam.loaiNuaNam}>{loaiNuaNam.name}</option>
                                ))
                            }
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={formik.values?.request?.kyThongKe == 5}>
                        <InputLabel >Năm </InputLabel>
                        <NativeSelect value={formik.values?.request?.namThongKe} name="namThongKe"
                            onChange={(event: any) => {
                                formik.setFieldValue("request.namThongKe", event.target.value);
                            }} input={<InputBase fullWidth />} >
                            {
                                dataNamThongKe.map((nam, i) => (
                                    <option key={i} value={nam.nam}>{nam.name}</option>
                                ))
                            }
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item"  hidden={!(formik.values?.request?.kyThongKe == 5)}>
                        <InputLabel >Ngày cấp từ</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request.tuNgay > 0 ? new Date(formik.values.request.tuNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{className: "button-date-picker"}}
                                variant="inline" 
                                InputProps={{disableUnderline: true}}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) =>  formik.setFieldValue("request.tuNgay", date?.toDate()?.getTime()) }
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={!(formik.values?.request?.kyThongKe == 5)}>
                        <InputLabel >Ngày cấp đến</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request?.denNgay > 0 ? new Date(formik.values.request?.denNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{className: "button-date-picker"}}
                                variant="inline" 
                                InputProps={{disableUnderline: true}}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) =>  formik.setFieldValue("request.denNgay", date?.toDate()?.getTime()) }
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item"></Grid>
                    <Grid item xs={3} className="grid-item" hidden={!(formik.values?.request?.kyThongKe == 4)}></Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Huyện/Thị/TP</InputLabel>
                        {
                            StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                            <Autocomplete id="maQuanHuyen" options={quans}
                                value={{ code: formik.values?.request?.maQuanHuyen ? formik.values.request?.maQuanHuyen : "", name: formik.values?.request?.tenQuanHuyen ? formik.values.request.tenQuanHuyen : "" }}
                                getOptionLabel={(option) => option?.name}
                                onChange={(event: any, newValue: any) => {
                                    formik.setFieldValue("request.maPhuongXa", "");
                                    formik.setFieldValue("request.tenPhuongXa", "");
                                    formik.setFieldValue("request.maQuanHuyen", newValue?.code);
                                    formik.setFieldValue("request.tenQuanHuyen", newValue?.name);
                                }}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth {...params.inputProps} />
                                    </div>
                                }}
                            />
                        }
                        {
                            !StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                            <InputBase disabled fullWidth value={formik.values.request.tenQuanHuyen} />
                        }
                    </Grid> 
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Phường/Xã/TT</InputLabel>
                        <Autocomplete options={phuongs}
                            onFocus={loadPhuongs}
                            value={{ code: formik.values?.request?.maPhuongXa ? formik.values.request?.maPhuongXa : "", name: formik.values?.request?.tenPhuongXa ? formik.values.request.tenPhuongXa : "" }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maPhuongXa", newValue?.code);
                                formik.setFieldValue("request.tenPhuongXa", newValue?.name);
                            }}
                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <TextField  {...params} variant="outlined" />
                                </div>
                            }}
                        />
                    </Grid>
                   
                    <Grid item xs={9} className="grid-item">
                    </Grid>
                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <InputLabel ></InputLabel>
                        <Button onClick={loadDataTable} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tổng hợp
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('docx');
                        }}>
                            <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('pdf');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('xlsx');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                            </SvgIcon>
                        </Link>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <DanhSachThongKeCapPhep 
                            data={data}
                            detailRequest={detailRequest}
                            setDetailRequest={setDetailRequest}
                            setOpenDialog={setOpenDialog}
                        />
                    </Grid>
                </Grid>
            </form>
            {openDialog && (
                <ChiTietThongKeCapPhep
                    request={detailRequest}
                    openDialog={openDialog}
                    onDialogClose={() => {
                        setOpenDialog(false);
                    }}
                />
            )}
            <OverlayLoading />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div >
    )
}

