import {BaseResponse, BaseService} from "../baseService";
import api from "../index";
import {HoSoThuLyBLThuocLa, HoSoThuLyBLThuocLaBienDong} from "./model/HoSoThuLyBLThuocLa";
import {BienDongTrangThaiGPTLDTO} from "./model/BienDongTrangThaiGPKTLDTO";

export interface BienDongTrangThaiGPTLResponse {
    giayPhep: HoSoThuLyBLThuocLa;
    bienDong: BienDongTrangThaiGPTLDTO;
}

export class BienDongTrangThaiGPTLService extends BaseService{
    luuVaCapNhatKhoiPhuc = async (khoiPhuc: BienDongTrangThaiGPTLDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api.post('cptl/bien-dong-trang-thai/add-or-update', JSON.stringify(khoiPhuc), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message,
                data: response.data.data
            }
        }).catch(() => {
            result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' }
        });
        return result;
    }

    getGiayPhepKinhDoanhId = async (json: any): Promise<HoSoThuLyBLThuocLaBienDong> => {
        let result = {} as HoSoThuLyBLThuocLaBienDong;
        await api.post('cptl/bien-dong-trang-thai/get-by-giay-phep-id', json, this.getTokenRequestHeaders()).then(response => {
            if (response != null && response.data != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }
        }).catch(() => {
        });
        return result;
    }
}
