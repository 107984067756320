import { Box, createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { blue } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { AttachFileDTO } from "../../../../api/KhenThuong/models/attachFile";
import { AttachIcon } from "../AttachFileTooltip/AttachFileTooltip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        nameFile: {
            textDecoration: "none",
            color: blue[500],
            marginRight: "auto",
        },
        button: {
            margin: "0px 5px",
        },
        container: {
            marginTop: "5px",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            borderWidth: "2px",
            borderRadius: "2px",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            color: "#bdbdbd",
            outline: "none",
        },
        buttonFile: {
            justifyContent: "flex-start",
            color: blue[500],
        },
    })
);

interface PreviewDropzoneI {
    files: AttachFileDTO[];
    onDelete?: (f: AttachFileDTO) => void;
    onDownload?: (f: AttachFileDTO) => void;
}

const PreviewDropzoneFromServerKT: React.FC<PreviewDropzoneI> = ({ files, onDelete, onDownload }) => {
    const classes = useStyles();
    return (
        <>
            {files.map(
                (item, i) =>
                    !Boolean(item.isDeleted) && (
                        <Box display="flex">
                            <Box display="flex" flexGrow="1" flexDirection="column" key={i}>
                                <Button
                                    fullWidth
                                    size="small"
                                    className={classes.buttonFile}
                                    disableRipple
                                    startIcon={<AttachIcon fileName={`${item.fileName}`} />}
                                    onClick={() => onDownload && onDownload(item)}
                                >
                                    <Box component="span" textAlign="left">
                                        {item.fileName}
                                    </Box>
                                </Button>
                            </Box>

                            <IconButton onClick={() => onDelete && onDelete(item)} size="small" disableRipple color="secondary">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )
            )}
        </>
    );
};

export default PreviewDropzoneFromServerKT;
