import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import RefreshIcon from "@material-ui/icons/Refresh";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from '@material-ui/icons/Close';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Dispatch, useRef, useState } from "react";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { DanhSachGiayPhepLGPChaiDTO } from "../../../../api/LPGChai/model/DanhSachGiayPhepLGPChaiDTO";
import { QuanLyKDTLService } from "../../../../api/CPBLTL/QuanLyKDTLService";
import {
    ROUTE_LGP_DAUKY
} from "../../../../routes/route-cn-lpg";
import { TINHTRANG_HOATDONG, TINHTRANG_HUY, TINHTRANG_THUHOI } from "../../../Constants/LGPChaiConstants";

export interface ThaoTacProps {
    row: DanhSachGiayPhepLGPChaiDTO;
}

export const ThaoTacComponent = (props: ThaoTacProps) => {

    const { row } = props;
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    let history = useHistory();

    const showHidePopperThaoTac = async (event: React.MouseEvent<HTMLButtonElement>, maTinhTrang: string, maBienDong: string, maHoSo: string) => {
        setOpen(!open);
    }

    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const handleEdit = () => {
        history.push(ROUTE_LGP_DAUKY.duongDan, { ...row, soGiayPhep: row.soGiayPhep, giayPhepId: row.id, hoSoThuLyId: row.hoSoThuLyId });
    };
    const handleHuy = () => {
        history.push('/giay-chung-nhan-lpgchai/huy-giay-phep', { ...row, soGiayPhep: row.soGiayPhep, giayPhepId: row.id, hoSoThuLyId: row.hoSoThuLyId });
    };
    const handleThuHoi = () => {
        history.push('/giay-chung-nhan-lpgchai/thu-hoi-giay-phep', { ...row, soGiayPhep: row.soGiayPhep, giayPhepId: row.id, hoSoThuLyId: row.hoSoThuLyId });
    };
    const handleHoanTraHuy = () => {
        history.push('/giay-chung-nhan-lpgchai/hoan-tra-huy-giay-phep', { ...row, soGiayPhep: row.soGiayPhep, giayPhepId: row.id, hoSoThuLyId: row.hoSoThuLyId });
    };
    const handleHoanThuHoi = () => {
        history.push('/giay-chung-nhan-lpgchai/hoan-tra-thu-hoi-giay-phep', { ...row, soGiayPhep: row.soGiayPhep, giayPhepId: row.id, hoSoThuLyId: row.hoSoThuLyId });
    };
    return (
        <>
            <Button startIcon={<ListIcon />}
                size="small" color="primary" disableRipple
                ref={anchorRef} aria-haspopup="true"
                aria-controls={open ? 'menu-list-grow' : undefined}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => showHidePopperThaoTac(e, row.maTinhTrang, row.maBienDong, row.maHoSo)} >
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                    <MenuItem key={"menu-item-edit"} style={{ padding: "unset" }}>
                                        <Button onClick={(e) => handleEdit()} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                            Chỉnh sửa
                                        </Button>
                                    </MenuItem>
                                    {
                                        (row.maTinhTrang === TINHTRANG_HOATDONG.ma || row.maTinhTrang === TINHTRANG_HUY.ma) &&
                                        <>
                                            <MenuItem key={"menu-item-huy"} style={{ padding: "unset" }}>
                                                <Button onClick={(e) => handleHuy()} startIcon={row.maTinhTrang === TINHTRANG_HOATDONG.ma ? <CloseIcon color="secondary" /> : <EditIcon color="secondary" />} size="small" disableRipple>
                                                    {row.maTinhTrang === TINHTRANG_HOATDONG.ma ? 'Hủy' : 'Cập nhật hủy'}
                                                </Button>
                                            </MenuItem>
                                        </>
                                    }
                                    {
                                        row.maTinhTrang === TINHTRANG_HUY.ma &&
                                        <>
                                            <MenuItem key={"menu-item-huy"} style={{ padding: "unset" }}>
                                                <Button onClick={(e) => handleHoanTraHuy()} startIcon={<RefreshIcon color="primary" />} size="small" disableRipple>
                                                    Hoàn trả hủy
                                                </Button>
                                            </MenuItem>
                                        </>
                                    }
                                    {
                                        (row.maTinhTrang === TINHTRANG_HOATDONG.ma || row.maTinhTrang === TINHTRANG_THUHOI.ma) &&
                                        <>
                                            <MenuItem key={"menu-item-huy"} style={{ padding: "unset" }}>
                                                <Button onClick={(e) => handleThuHoi()} startIcon={row.maTinhTrang === TINHTRANG_HOATDONG.ma ? <RefreshIcon color="secondary" /> : <EditIcon color="secondary" />} size="small" disableRipple>
                                                    {row.maTinhTrang === TINHTRANG_HOATDONG.ma ? 'Thu hồi' : 'Cập nhật thu hồi'}
                                                </Button>
                                            </MenuItem>
                                        </>
                                    }
                                    {
                                        row.maTinhTrang === TINHTRANG_THUHOI.ma &&
                                        <>
                                            <MenuItem key={"menu-item-huy"} style={{ padding: "unset" }}>
                                                <Button onClick={(e) => handleHoanThuHoi()} startIcon={<RefreshIcon color="secondary" />} size="small" disableRipple>
                                                    Hoàn trả thu hồi
                                                </Button>
                                            </MenuItem>
                                        </>
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
