import React, { useEffect, useState, Dispatch } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import { ThongTinChungComponent } from './ThongTinChungComponent';
import { LPGChaiService } from '../../../../../api/LPGChai/LPGChaiService';
import { BieuMauAttachFileDTO } from '../../../../../api/QuanTri/Model/danhMucBieuMau';
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import StringUtil from '../../../../utils/stringUtils';
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { HO_SO_THU_LY_LPGCHAI_INIT } from "../../../../../api/LPGChai/model/GiayPhepLGPChaiInit";
import { LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from '../../../../Constants/LGPChaiConstants';
import { HoSoThuLyLPGChaiDTO } from '../../../../../api/LPGChai/model/HoSoThuLyLPGChai';
import { useHistory } from 'react-router-dom';
import ThuLyButtonComponent from '../../../../commons/components/hoso/thuly/ThuLyButtonComponent';
import { useGetThongTinChuyenNganh } from '../../../../../pages/hoSoCuaToi/useGetThongTinChuyenNganh';
import ThongTinChuyenNganhUtil from '../../../../../pages/hoSoCuaToi/ThongTinChuyenNganhUtil';
import { ThongTinChuyenNhanhUtil } from '../../../../utils/thongTinChuyenNhanhUtil';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { useDispatch } from "react-redux";
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { UserData } from '../../../../../api/userMgt/userManagementService';
import { LPG_CN } from '../../../../Constants/constants';
import { LinhVucCapSoGPService } from '../../../../../api/DKKD/LinhVucCapSoGPService';
import { HoSoCuaToiMgtService } from '../../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const CapMoiGiayChungNhanLPGChai = () => {

    const [indexTab, setIndexTab] = useState(0);
    const [tabRequired, setTabRequired] = useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [ngayHenTra, setNgayHenTra] = useState<number>(0);
    const [data, setData] = useState({} as HoSoThuLyLPGChaiDTO);
    const [submit, setSubmit] = useState(false);
    const history = useHistory();
    const giayChungNhanLPGChaiService = new LPGChaiService();
    const thongTinChuyenNganh = useGetThongTinChuyenNganh(history?.location?.state?.maHoSo);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const service = new LinhVucCapSoGPService();
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    let C_USER_DATA_DE = {} as UserData;
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    useEffect(() => {
        initData();

    }, []);

    const initData = async () => {
        dispatchLoading(showLoading());
        let maHoSo = history?.location?.state?.maHoSo;
        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        if (!StringUtil.isNullOrEmty(C_USER_DATA_DE.maCoQuan)) {
            hoSoCuaToiMgtService.chiTiet(C_USER_DATA_DE.maCoQuan, history?.location?.state?.maHoSo).then((p) => {
                if (p.data && p.data.data) {
                    hoTenNguoiNop = p.data.data.nguoiNop;
                    soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
                    let date = new Date(p.data.data.ngayHenTra);
                    setNgayHenTra(date.getTime());
                }
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
        await giayChungNhanLPGChaiService.getByMaHoSo({ "maHoSo": maHoSo }).then(async (res) => {
            if (res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.data?.id > 0) {
                let resData = {
                    ...res?.data?.data,
                    maBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                } as HoSoThuLyLPGChaiDTO;
                setData(resData);
            } else {
                let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHoSo);
                let maQuan = "";
                let tenQuan = "";
                let maPhuong = "";
                let tenPhuong = "";
                if (!StringUtil.isNullOrEmty(dataEform?.quanhuyen)) {
                    let dataQuan = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                    maQuan = (dataQuan && dataQuan.length > 0) ? dataQuan[0].code : "";
                    tenQuan = (dataQuan && dataQuan.length > 0) ? dataQuan[0].name : "";
                }
                if (!StringUtil.isNullOrEmty(dataEform?.xaphuong)) {
                    let dataHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                    maPhuong = (dataHuyen && dataHuyen.length > 0) ? dataHuyen[0].code : "";
                    tenPhuong = (dataHuyen && dataHuyen.length > 0) ? dataHuyen[0].name : "";
                }
                let soGiayPhepDayDu = "";
                await service.getGPTuDong(C_USER_DATA_DE.maCoQuan, LPG_CN.value).then(resSGP => {
                    if (resSGP && resSGP.data && resSGP.data.error_code == 'SUCCESSFUL') {
                        soGiayPhepDayDu = resSGP.data.data.soGPDayDu;
                    }
                }).catch(errorSGP => {
                    return;
                });
                const dataNew: HoSoThuLyLPGChaiDTO = {
                    ...HO_SO_THU_LY_LPGCHAI_INIT,
                    maCoQuan: C_USER_DATA_DE.maCoQuan,
                    maChuyenNganh: LPG_CN.value,
                    soGiayPhep: soGiayPhepDayDu,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    maBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    tenThuongNhan: hoTenNguoiNop != "" ? hoTenNguoiNop : dataEform?.hotennguoidaidien ? dataEform?.hotennguoidaidien : HO_SO_THU_LY_LPGCHAI_INIT.tenThuongNhan,
                    fax: dataEform?.fax ? dataEform?.fax : HO_SO_THU_LY_LPGCHAI_INIT.fax,
                    soDienThoai: soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt ? StringUtil.replaceDots(dataEform?.sdt) : HO_SO_THU_LY_LPGCHAI_INIT.soDienThoai,
                    soGiayCNDKKD: dataEform?.giaydkkd ? dataEform?.giaydkkd : HO_SO_THU_LY_LPGCHAI_INIT.soGiayCNDKKD,
                    noiCapCNDKKD: dataEform?.noicapdkkd ? dataEform?.noicapdkkd : HO_SO_THU_LY_LPGCHAI_INIT.noiCapCNDKKD,
                    ngayCapCNDKKD: dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : HO_SO_THU_LY_LPGCHAI_INIT.ngayCapCNDKKD,
                    maQuanHuyen: dataEform?.quanhuyen ? dataEform?.quanhuyen : HO_SO_THU_LY_LPGCHAI_INIT.maQuanHuyen,
                    tenQuanHuyen: tenQuan,
                    maPhuongXa: dataEform?.xaphuong ? dataEform?.xaphuong : HO_SO_THU_LY_LPGCHAI_INIT.maPhuongXa,
                    tenPhuongXa: tenPhuong,
                    tenDuong: dataEform?.diadiem
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const handleSubmit = () => {
        if (StringUtil.isNullOrEmty(data.maHoSo)) {
            setTabRequired(-0);
            showMessageAlert('Không có mã hồ sơ', false);
            return;
        }
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan)
            || StringUtil.isNullOrEmty(data.maPhuongXa)
            || StringUtil.isNullOrEmty(data.soGiayCNDKKD)) {
            setTabRequired(0);
            return;
        }
        if (data?.donViCapPhep) {
            if (StringUtil.isNullOrEmty(data.soGiayPhep)
                || StringUtil.isNullOrEmty(data.ngayCapPhep)) {
                setTabRequired(-0);
                return;
            }
        }
        setSubmit(false);
        let dataRequest: HoSoThuLyLPGChaiDTO = { ...data };
        if (!data.donViCapPhep) {
            dataRequest = {
                ...data,
                soGiayPhep: '',
                ngayCapPhep: 0,
                ngayHetHanGP: 0
            }
        }
        giayChungNhanLPGChaiService.insertOrUpdate({...dataRequest, maCoQuan: C_USER_DATA_DE.maCoQuan, ngayHenTra:ngayHenTra}).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL' && parseInt(res.data.data.id) > 0) {
                    let resData = {
                        ...res?.data?.data
                    };
                    setData(resData);
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else if (res.data.error_code == 'DUPLICATE_CODE') {
                    setAlertMessage({ open: true, content: 'Trùng số giấy phép', severity: 'warning' });
                } else {
                    showMessageAlert('Yêu cầu thực hiện thất bại', false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            console.log(error);
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }

    const resetData = () => {
        const dataNew = {
            ...HO_SO_THU_LY_LPGCHAI_INIT,
            id: data.id,
            giayPhepId: data.giayPhepId,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc,
            maBienDong: LOAIBIENDONG_CAPMOI.ma,
            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten
        }
        setData(dataNew);
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        giayChungNhanLPGChaiService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            if (res && res.data) {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
            }

        });
    }

    return (
        <>
            {
                <Paper square >
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd"
                        keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <br></br>
                                <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                        <div className="dash-content">
                            <div className="dash-title">
                                <span>CẤP GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN CỬA HÀNG BÁN LẺ LPG CHAI {StringUtil.isNotEmpty(data?.maHoSo) ? "- MÃ HỒ SƠ :  " + data.maHoSo : ""} </span>
                            </div>
                        </div>
                        {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <ThongTinChungComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    data={data} />

                                <Grid container direction="row">
                                    <Grid item xs={12} style={{ float: 'left' }} className="grid-item" >
                                        <ThuLyButtonComponent
                                            showIn={data.id > 0}
                                            onSubmit={handleSubmit}
                                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                            onDelete={handleDelete}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        }
                    </Grid>
                </Paper>
            }
        </>
    )
}
