import { RoutePropsI } from "."
import { BaoCaoThongKeTinhHinhCapPhep } from "../App/Components/DKKD/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep"
import HoanTraHuyGiayPhepKinhDoanh from "../App/Components/DKKD/BienDongTrangThaiGPKD/HoanTraHuyGPKD"
import HuyGiayPhepKinhDoanh from "../App/Components/DKKD/BienDongTrangThaiGPKD/HuyGPKD"
import KhoiPhucTrangThaiGPKD from "../App/Components/DKKD/BienDongTrangThaiGPKD/KhoiPhucTrangThaiGPKD"
import NgungHoatDongGiayPhepKinhDoanh from "../App/Components/DKKD/BienDongTrangThaiGPKD/ngungHoatDongGPKD"
import TamNgungGiayPhepKinhDoanh from "../App/Components/DKKD/BienDongTrangThaiGPKD/tamNgungGPKD"
import ThuHoiGiayPhepKinhDoanh from "../App/Components/DKKD/BienDongTrangThaiGPKD/thuHoiGPKD"
import { BieuDoThongKeTinhHinhCapPhep } from "../App/Components/DKKD/BieuDoTK/BieuDoThongKeTinhHinhCapPhep"
import { CapLaiGiayChungNhanDKHKD } from "../App/Components/DKKD/ThuLyHoSo/CapLaiHKD/CapLaiHKD"
import { HoSoCapSoGiayPhepKinhDoanh } from "../App/Components/DKKD/CapSoGiayPhep/hoSoCapSoGiayPhep"
import { ChamDutHoatDongHKD } from "../App/Components/DKKD/ThuLyHoSo/ChamDutHDHKinhDoanh/ChamDutHKD"
import { LinhVucKinhDoanhComponent } from "../App/Components/DKKD/DanhMuc/LinhVucKinhDoanh/main"
import { PhuongThucKinhDoanhComponent } from "../App/Components/DKKD/DanhMuc/PhuongThucKinhDoanh/main"
import GiayPhepKDComponent from "../App/Components/DKKD/GiayPhepKinhDoanh/GiayPhepKDComponent"
import NhapDauKyThanhLapHKDComponent from "../App/Components/DKKD/NhapDauKyHKD/NhapDauKyThanhLapHKDComponent"
import { QuanLyDangKyKinhDoanh } from "../App/Components/DKKD/QuanLyDKKD/quanLyDangKyKinhDoanh"
import { TamNgungHoatDongHKD } from "../App/Components/DKKD/ThuLyHoSo/TamNgungHKD/TamNgungHoatDongHKD"
import ThanhLapHKDCapMoiComponent from "../App/Components/DKKD/ThuLyHoSo/CapMoi/ThanhLapHKDCapMoiComponent"
import ThanhLapHKDThayDoiComponent from "../App/Components/DKKD/ThuLyHoSo/ThayDoi/ThanhLapHKDThayDoiComponent"
import HoanTraThuHoiGPDKKD from "../App/Components/DKKD/BienDongTrangThaiGPKD/HoanTraThuHoiGPDKKD";
import { ThongKeTinhHinhCapPhep } from "../App/Components/DKKD/ThongKe/ThongKeTinhHinhCapPhep"
import { LinhVucNoiCapComponent } from "../App/Components/DKKD/DanhMuc/LinhVucNoiCap/main"
import { DMCungCapGPComponent } from "../App/Components/DKKD/DanhMuc/LinhVucCapSoGP/main"
import ChamDutHoatDongGiayPhepKinhDoanh from "../App/Components/DKKD/BienDongTrangThaiGPKD/chamDutHoatDongGPKD"
import { BieuDoTronDKKD } from "../App/Components/DKKD/BieuDoTron/BieuDoTronDKKD"
export const ROUTE_DKKD_DAUKY = {
    ten: 'NHẬP ĐẦU KỲ GIẤY PHÉP KINH DOANH',
    giaoDien: NhapDauKyThanhLapHKDComponent,
    duongDan: '/giay-phep-kinh-doanh/nhap-dau-ky',
}

export const ROUTE_DKKD_CAPMOI = {
    ten: 'ĐĂNG KÝ KINH DOANH',
    giaoDien: ThanhLapHKDCapMoiComponent,
    duongDan: '/dang-ky-kinh-doanh',
}
export const ROUTE_DKKD_DIEUCHINH = {
    ten: 'THAY ĐỔI KINH DOANH',
    giaoDien: ThanhLapHKDThayDoiComponent,
    duongDan: '/thay-doi-dang-ky-kinh-doanh',
}
export const ROUTE_DKKD_TAMNGUNG = {
    ten: 'TẠM NGỪNG HOẠT ĐỘNG HỘ KINH DOANH',
    giaoDien: TamNgungHoatDongHKD,
    duongDan: '/dang-ky-kinh-doanh/tam-ngung-hoat-dong-hkd',
}
export const ROUTE_DKKD_CHAMDUT = {
    ten: 'CHẤM DỨT HOẠT ĐỘNG HỘ KINH DOANH',
    giaoDien: ChamDutHoatDongHKD,
    duongDan: '/dang-ky-kinh-doanh/cham-dut-hoat-dong-ho-kinh-doanh',
}
export const ROUTE_DKKD_CAPLAI = {
    ten: 'CẤP LẠI GIẤY CHỨNG NHẬN ĐĂNG KÝ HỘ KINH DOANH',
    giaoDien: CapLaiGiayChungNhanDKHKD,
    duongDan: '/dang-ky-kinh-doanh/cap-lai-hoat-dong-ho-kinh-doanh',
}
export const ROUTE_CN_DKKD: RoutePropsI[] = [
    ROUTE_DKKD_CAPMOI,
    ROUTE_DKKD_DIEUCHINH,
    ROUTE_DKKD_TAMNGUNG,
    ROUTE_DKKD_CHAMDUT,
    ROUTE_DKKD_CAPLAI,
];
export const ROUTE_PAGE_DKKD: RoutePropsI[] = [
    {
        ten: "TRA CỨU ĐĂNG KÝ KINH DOANH",
        duongDan: '/quan-ly-dang-ky-kinh-doanh',
        giaoDien: QuanLyDangKyKinhDoanh,
    },

    {
        ten: "DANH SÁCH CẤP SỐ ĐĂNG KÝ KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/danh-sach-cap-so',
        giaoDien: HoSoCapSoGiayPhepKinhDoanh,
    },

    {
        ten: "THỐNG KÊ TÌNH HÌNH CẤP PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/thong-ke-cap-phep',
        giaoDien: ThongKeTinhHinhCapPhep,
    },
    {
        ten: "BÁO CÁO DANH SÁCH ĐĂNG KÝ THÀNH LẬP HỘ KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/bao-cao-thong-ke-cap-phep',
        giaoDien: BaoCaoThongKeTinhHinhCapPhep,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/bieu-do',
        giaoDien: BieuDoThongKeTinhHinhCapPhep,
    },
    {
        ten: "DANH MỤC LĨNH VỰC KINH DOANH",
        duongDan: '/dkkd/dm-linh-vuc-kinh-doanh',
        giaoDien: LinhVucKinhDoanhComponent,
    },
    {
        ten: "DANH MỤC LĨNH VỰC NƠI CẤP",
        duongDan: '/dkkd/dm-linh-vuc-noi-cap',
        giaoDien: LinhVucNoiCapComponent,
    },
    {
        ten: "DANH MỤC CUNG CẤP SỐ GIẤY PHÉP",
        duongDan: '/dkkd/dm-cung-cap-so-gp',
        giaoDien: DMCungCapGPComponent,
    },
    {
        ten: "DANH MỤC PHƯƠNG THỨC KINH DOANH",
        duongDan: '/dkkd/dm-phuong-thuc-kinh-doanh',
        giaoDien: PhuongThucKinhDoanhComponent,
    },
    {
        ten: "BIỂU ĐỒ TRÒN THỐNG KÊ GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/bieu-do-tron',
        giaoDien: BieuDoTronDKKD ,
    }
    
];

export const ROUTE_DS_DKKD: RoutePropsI[] = [
    {
        ten: "TRA CỨU ĐĂNG KÝ KINH DOANH",
        duongDan: '/quan-ly-dang-ky-kinh-doanh',
        giaoDien: QuanLyDangKyKinhDoanh,
    },

    {
        ten: "THỐNG KÊ TÌNH HÌNH CẤP PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/thong-ke-cap-phep',
        giaoDien: ThongKeTinhHinhCapPhep,
    },
    {
        ten: "BÁO CÁO DANH SÁCH ĐĂNG KÝ THÀNH LẬP HỘ KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/bao-cao-thong-ke-cap-phep',
        giaoDien: BaoCaoThongKeTinhHinhCapPhep,
    },
];

export const ROUTE_PAGE_PUBLIC_DKKD: RoutePropsI[] = [
    {
        ten: "BIỂU ĐỒ THỐNG KÊ GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/bieu-do',
        giaoDien: <BieuDoThongKeTinhHinhCapPhep />,
    },
    
];
export const ROUTE_DKKD_GUEST: RoutePropsI[] = [
    {
        ten: 'CHI TIẾT GIẤY PHÉP KINH DOANH',
        giaoDien: GiayPhepKDComponent,
        duongDan: '/giay-phep-kinh-doanh/chi-tiet-giay-phep-kinh-doanh'
    },
    {
        ten: "CHẤM DỨT GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/cham-dut-hoat-dong-giay-phep',
        giaoDien: ChamDutHoatDongGiayPhepKinhDoanh,
    },
    {
        ten: "HOÀN TRẢ HỦY GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/hoan-tra-huy-giay-phep',
        giaoDien: HoanTraHuyGiayPhepKinhDoanh,
    },
    {
        ten: 'HOÀN TRẢ THU HỒI GIẤY PHÉP KINH DOANH',
        giaoDien: HoanTraThuHoiGPDKKD,
        duongDan: '/giay-phep-kinh-doanh/hoan-tra-thu-hoi-gpkd',
    },
    {
        ten: "TẠM NGƯNG GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/tam-ngung-giay-phep',
        giaoDien: TamNgungGiayPhepKinhDoanh,
    },
    {
        ten: "NGƯNG GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/ngung-hoat-dong-giay-phep',
        giaoDien: NgungHoatDongGiayPhepKinhDoanh,
    },
    {
        ten: "KHÔI PHỤC TRẠNG THÁI HOẠT ĐỘNG GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/khoi-phuc-trang-thai-giay-phep',
        giaoDien: KhoiPhucTrangThaiGPKD,
    },
    {
        ten: "HỦY GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/huy-giay-phep',
        giaoDien: HuyGiayPhepKinhDoanh,
    },
    {
        ten: "THU HỒI GIẤY PHÉP KINH DOANH",
        duongDan: '/giay-phep-kinh-doanh/thu-hoi-giay-phep',
        giaoDien: ThuHoiGiayPhepKinhDoanh,
    },
    ROUTE_DKKD_DAUKY
];



