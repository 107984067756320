import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { NativeSelect } from '@material-ui/core';
import { CustomInput } from '../../../../../commons/components/input/customInput';
import { HangMucXayDungDTO } from '../../../../../../api/CPXD/model/CapPhepXayDungModel';

interface chiTietProps {
    data: HangMucXayDungDTO[];
}



const TableHangMuc = (props: chiTietProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<HangMucXayDungDTO[]>(new Array<HangMucXayDungDTO>());
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = ((pageNumberNew - 1) * pageSizeNew);
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    }
    return (
        
        <div>
            <table className="table table-bordered" style={{ fontSize: '14px' }}>
                <thead>
                        <tr className="table-secondary">
                            <th>STT</th>
                            <th>Hạng mục</th>
                            <th>Số tầng</th>
                            <th>Kết cấu</th>
                            <th>Diện tích xây dựng</th>
                        </tr>
                </thead>
                <tbody>
                    {dataPagi?.length > 0 ? dataPagi?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="cell-number">{((pageNumber - 1) * pageSize) + index + 1}</td>
                                <td>{item.hangMuc}</td>
                                <td>{item.soTang}</td>
                                <td>{item.ketCau}</td>
                                <td>{item.tongDienTichSan}</td>
                            </tr>
                        )
                    }) :
                        <tr>
                            <td className="text-center" colSpan={5}> Không có dữ liệu</td>
                        </tr>
                    }
                </tbody>
            </table>

            <div className="row">
                {/* pagination*/}
                <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                    <Pagination count={Math.ceil(props.data?.length / pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleChangePage} />
                    <div>
                        <span> Số mẫu tin trên trang </span>
                        <NativeSelect value={pageSize} input={<CustomInput />} onChange={handleChangePageSize} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                        <span>Trang {pageNumber}  / {(props.data?.length === 0 ||  props.data == null) ? 1 : Math.ceil(props.data?.length / pageSize)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TableHangMuc;