import { BaseService, Pagination } from "../baseService";
import AXIOS_INSTANCE from "../index";
import { RequestThongKeDTO } from "../../pages/dashboard/DKKD/DashboardThanhLapHoKinhDoanh";
import { RequestBaoCaoThongKeDTO } from "../../App/Components/DKKD/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import { ThongKeTinhHinhCapPhepResponse } from "../DKKD/model/ThongKeTinhHinhCapPhepDTO";
import { GiayPhepKinhDoanhLPGChaiDTO } from "./model/GiayPhepKinhDoanhLPGChai";
import { BienDongDTO } from "../DKKD/model/BienDongDTO";
import { RequestBieuDoThongKeDTO } from "../../App/Components/DKKD/BieuDoTK/BieuDoThongKeTinhHinhCapPhep";
import { ChiTietThongKeCapPhepDTO } from "./model/ChiTietThongKeCapPhepDTO";

export class ThongKeService extends BaseService {
    thongKe = async (request: RequestThongKeDTO): Promise<ThongKeTinhHinhCapPhepResponse> => {
        let result = {} as ThongKeTinhHinhCapPhepResponse;
        await AXIOS_INSTANCE.post('/bao-cao-thong-ke-lpgchai/thong-ke-tinh-hinh-cap-phep', request, this.getTokenRequestHeaders()).then(response => {
            if (response && response.data && response.data.data) {
                result = response.data.data;
            }
        }).catch();
        return result;
    }

    chiTietThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<ChiTietThongKeCapPhepDTO[]>> => {
        let result: Pagination<ChiTietThongKeCapPhepDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('bao-cao-thong-ke-lpgchai/thong-ke-tinh-hinh-cap-phep/chi-tiet', request, this.getTokenRequestHeaders()).then(response => {
            console.log(response);
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    baoCaoThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<GiayPhepKinhDoanhLPGChaiDTO[]>> => {
        let result: Pagination<GiayPhepKinhDoanhLPGChaiDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('/bao-cao-thong-ke-lpgchai/bao-cao-thong-ke-giay-phep', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    chartThongKe = (request: RequestBieuDoThongKeDTO) => {
        return AXIOS_INSTANCE.post('/bao-cao-thong-ke-lpgchai/chart-bao-cao-thong-ke-lpgchai', request, this.getTokenRequestHeaders());
    }

    downloadFileBaoCao = (request: RequestBaoCaoThongKeDTO) => {
        return AXIOS_INSTANCE.post('/bao-cao-thong-ke-lpgchai/export-bao-cao-thong-ke-giay-phep', request, {
            responseType: 'blob'
        });

    }

    downloadFileThongKe = (request: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.post('/bao-cao-thong-ke-lpgchai/export-thong-ke-tinh-hinh-cap-phep', request, {
            responseType: 'blob'
        });

    }

    danhMucBienDong = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<BienDongDTO[]>> => {
        let result: Pagination<BienDongDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('/danh-muc/bien-dong', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    thongKeBieuDoTron = async (request: RequestBieuDoThongKeDTO) => {
        return AXIOS_INSTANCE.post('/bao-cao-thong-ke-lpgchai/thong-ke-bieu-do-trong-lpgchai', request, this.getTokenRequestHeaders());
    }
}
