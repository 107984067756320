import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { BrowserHistory } from 'history';
import { ConfirmationDialogRaw } from "../../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../../commons/components/tabs";
import { HoSoThuLyBLR, TinhTrang } from "../../../../../../api/CPBLR/model/HoSoThuLyBLR";
import { CAP_MOI_SX_RUOU_INIT } from "../../../../../../api/CPBLR/Init/CapMoiSXRuouInit";
import StringUtil from "../../../../../utils/stringUtils";
import { CapMoiSXRuouService } from "../../../../../../api/CPBLR/CapMoiSXRuouService";
import DangKySanXuatRuouButtonComponent from "../../Action/DangKySanXuatRuouButtonComponent";
import { BieuMauAttachFileDTO } from "../../../../../../api/QuanTri/Model/danhMucBieuMau";
import { createFileType, downLoadFile } from "../../../../../../api/Common/exportUtil";
import { AlertMessage, CustomAlert } from "../../../../../commons/components/alert";
import CapLaiBLRuouNoiDungComponent from './CapLaiBLRuouNoiDungComponent';
import { LOAIBIENDONG_CAPLAI, TINHTRANG_HOATDONG } from '../../../../../Constants/RuouConstants';
import ThuLyButtonComponent from '../../../../../commons/components/hoso/thuly/ThuLyButtonComponent';

type StateHoSo = {
    maThuTuc: string,
    maHoSo: string
}
type CapLaiBLRuouProps = {
    history: BrowserHistory<StateHoSo>
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const CapLaiBLRuouComponent = (props: CapLaiBLRuouProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = React.useState<HoSoThuLyBLR>({ ...CAP_MOI_SX_RUOU_INIT });
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const capMoiSXRuouService = new CapMoiSXRuouService();
    const { history } = props;

    useEffect(() => {
        initData();
    }, [])

    const initData = () => {
        capMoiSXRuouService.getByMaHoSo({ maHoSo: history?.location?.state?.maHoSo }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let resData: HoSoThuLyBLR = {
                    ...res?.data?.data,
                    maBienDong: LOAIBIENDONG_CAPLAI.ma,
                    tenBienDong: LOAIBIENDONG_CAPLAI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                };
                setData(resData);
                setShowInfo(true);
            } else {
                const dataNew: HoSoThuLyBLR = {
                    ...CAP_MOI_SX_RUOU_INIT,
                    maBienDong: LOAIBIENDONG_CAPLAI.ma,
                    tenBienDong: LOAIBIENDONG_CAPLAI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    loaiGiayPhep: 'BLR',
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }

    const resetData = () => {
        const dataNew: HoSoThuLyBLR = {
            ...CAP_MOI_SX_RUOU_INIT,
            id: data.id,
            maBienDong: LOAIBIENDONG_CAPLAI.ma,
            tenBienDong: LOAIBIENDONG_CAPLAI.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.lyDo)) {
            setTabRequired(-0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.soGiayPhepCu)) {
            setTabRequired(-0);
            return;
        }
        setSubmit(false);
        capMoiSXRuouService.insertOrUpdate(data).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL') {
                    setDataParam('data', { ...data, id: res.data.data.id, giayPhepId: res.data.data.giayPhepId });
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else {
                    showMessageAlert(res.data.error_message, false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        capMoiSXRuouService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const loadGiayPhepDaCap = (soGiayPhep: string) => {
        capMoiSXRuouService.getBySoGiayPhep_LoaiGiayPhep({ soGiayPhep: soGiayPhep, loaiGiayPhep: 'BLR' }).then(res => {
            let dataNew = res?.data.data;
            if (dataNew !== null && dataNew !== undefined) {
                setData({
                    ...dataNew,
                    id: data.id,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    donViCapPhep: false,
                    maBienDong: LOAIBIENDONG_CAPLAI.ma,
                    tenBienDong: LOAIBIENDONG_CAPLAI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    lyDo: data.lyDo,
                    soLan: dataNew.soLan + 1,
                    ngayYeuCau: data.ngayYeuCau,
                    files: data.files
                });
                setShowInfo(true);
            } else {
                setAlertMessage({ open: true, content: "Số giấy phép không tồn tại trong dữ liệu", severity: 'warning' });
            }

        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="dash-content">
            <>
                {
                    <Paper square>
                        <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                            <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                        </Snackbar>
                        <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                            title="Xóa thông tin"
                            content="Bạn có đồng ý xóa thông tin đã nhập?"
                            onClose={handleCloseDeleteInfo}
                        />
                        <Grid container>
                            {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                                <br></br>
                                    <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                                    <div className="dash-content">
                                        <div className="dash-title">
                                            CẤP LẠI GIẤY PHÉP BÁN LẺ RƯỢU {"- MÃ HỒ SƠ :  " + data.maHoSo} 
                                        </div>
                                    </div>
                                    {/* </h6>
                                <br></br>
                            </Grid> */}
                            <br />
                            <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                                <Box mt="5px" bgcolor="#f2f2f2">
                                    <CustomTabs value={indexTab} onChange={handleChange}>
                                        <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                    </CustomTabs>
                                </Box>

                            </Grid>
                            {
                                indexTab === 0 &&
                                <TabPanel value={indexTab} index={0}>
                                    <CapLaiBLRuouNoiDungComponent
                                        showInfo={showInfo}
                                        onUpdateData={setDataParam}
                                        submit={submit}
                                        loadGiayPhepDaCap={loadGiayPhepDaCap}
                                        data={data} />

                                </TabPanel>
                            }

                            <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                                <ThuLyButtonComponent
                                    showIn={data.id > 0}
                                    onSubmit={handleSubmit}
                                    onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                    onDelete={handleDelete}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </>
        </div>
    );

};
export default CapLaiBLRuouComponent;