import { getObject } from "../../helpers/localStorage";
import { BaseService } from "../baseService";
import { SearchRequest } from "../Common/model/CommonModel";
import AXIOS_INSTANCE from "../index";
import { DanhMucCoQuanDTO, DanhMucDanTocDTO, DanhMucDiaChiDTO, DanhMucGioiTinhDTO, DanhMucLinhVucDTO, DanhMucNoiCapDTO, DanhMucQuocTichDTO } from "./model/DanhMucDTO";

export type DanhMucDTO = {
    id: number,
    ma: string,
    ten: string,
    ext?: any
}
export type CoQuanDTO = {
    id: number;
    kyHieu: string;
    ten: string;
    ma: string;
    thamGiaHeThong: string;
    hoatDong: boolean;
    maQuanHuyen: string;
    tenQuanHuyen: string;
}

export interface SearchRequestUser {
    maCoQuan: string;
    keywords: string;
    tenPhongBan: string;
    pageSize: number;
    pageNumber: number;
}

export const searchRequestUser_INIT: SearchRequestUser = {
    maCoQuan: '',
    keywords: '',
    tenPhongBan: '',
    pageSize: 5,
    pageNumber: 0,
}

export class DanhMucService extends BaseService {

    getLoaiVanBanByMaCoQuan_HoatDong = async (maCoQuan: string, hoatDong: boolean): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.post('danh-muc/loai-van-ban/get-by-ma-co-quan-hoat-dong', { maCoQuan: maCoQuan, hoatDong: hoatDong }, this.getTokenRequestHeaders())
            .then(response => {
                if (response && response.data && response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.loaiVanBan, ma: '', ten: item.tenLoaiVanBan });
                    });
                }
            }).catch();
        return result;
    }

    getLinhVucByMaCoQuan_HoatDong = async (maCoQuan: string, hoatDong: boolean): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.post('danh-muc/linh-vuc/get-by-ma-co-quan-hoat-dong', { maCoQuan: maCoQuan, hoatDong: hoatDong }, this.getTokenRequestHeaders())
            .then(response => {
                if (response && response.data && response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.ma, ten: item.ten });
                    });
                }
            }).catch();
        return result;
    }
    getDMThuTuc = async (maLinhVuc: string, hoatDong: boolean, maCoQuan?: string): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        let C_USER_DATA_DE = getObject("C_USER_DATA");
        await AXIOS_INSTANCE.post('/danh-muc/thu-tuc', { maCoQuan: C_USER_DATA_DE.maCoQuan ? C_USER_DATA_DE.maCoQuan : maCoQuan, maLinhVuc: maLinhVuc, hoatDong: hoatDong }, this.getTokenRequestHeaders())
            .then(response => {
                if (response && response.data && response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: 0, ma: item.ma, ten: item.ten });
                    });
                }
            }).catch();
        return result;
    }

    getDMLoaiThuTuc = async (): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.get('/danh-muc-loai-thu-tuc', this.getTokenRequestHeaders())
            .then(response => {
                if (response && response.data && response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.maLoaiThuTuc, ten: item.tenLoaiThuTuc });
                    });
                }
            }).catch();
        return result;
    }

    getDMPhongBan = async (): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.get('/danh-muc-phong-ban', this.getTokenRequestHeaders())
            .then(response => {
                if (response && response.data && response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.maPhongBan, ten: item.tenPhongBan });
                    });
                }
            }).catch();
        return result;
    }
    searchCoQuan = (request: SearchRequest) => {
        return AXIOS_INSTANCE.post('/danh-muc-co-quan/search', request)
    }
    configCoQuan = (id: number, maQuanHuyen: string, tenQuanHuyen: string) => {
        return AXIOS_INSTANCE.post('/danh-muc-co-quan/config', { id: id, maQuanHuyen: maQuanHuyen, tenQuanHuyen: tenQuanHuyen });
    }
    getDanhMucDiaChi = async (parentCode: String): Promise<DanhMucDiaChiDTO[]> => {
        let result: DanhMucDiaChiDTO[] = [];
        await AXIOS_INSTANCE
            .post(`/danh-muc-dia-chi/findAllDiaChiCommon`, { parentCode })
            .then((response) => {
                if (response && response.data && response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getDanhMucQuocTich = async (parentCode: String): Promise<DanhMucQuocTichDTO[]> => {
        let result: DanhMucQuocTichDTO[] = [];
        await AXIOS_INSTANCE
            .post(`/danh-muc-quoc-tich/findAllCommon`, { parentCode })
            .then((response) => {
                if (response && response.data && response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getDanhMucLinhVuc = async (parentCode: String): Promise<DanhMucLinhVucDTO[]> => {
        let result: DanhMucLinhVucDTO[] = [];
        await AXIOS_INSTANCE
            .post(`/dkkd/dm-linh-vuc-kinh-doanh/findAllCommon`, { parentCode })
            .then((response) => {
                if (response && response.data && response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getDanhMucDanToc = async (parentCode: String): Promise<DanhMucDanTocDTO[]> => {
        let result: DanhMucDanTocDTO[] = [];
        await AXIOS_INSTANCE
            .post(`/danh-muc-dan-toc/findAllCommon`, { parentCode })
            .then((response) => {
                if (response && response.data && response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getDanhMucGioiTinh = async (parentCode: String): Promise<DanhMucGioiTinhDTO[]> => {
        let result: DanhMucGioiTinhDTO[] = [];
        await AXIOS_INSTANCE
            .post(`/danh-muc-gioi-tinh/findAllCommon`, { parentCode })
            .then((response) => {
                if (response && response.data && response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getBySoCMND = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/lgsp/info-cong-dan-by-cmnd', requestBody);
    }

    getBySoDKKD = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/lgsp/info-doanh-nghiep-by-ma-so', requestBody);
    }

    getDanhMucDiaChiByCode = async (code: String): Promise<any> => {
        let result;
        await AXIOS_INSTANCE.post(`/danh-muc-dia-chi/findDataByCode`, { code })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getAllTinh = async (): Promise<any> => {
        let result;
        await AXIOS_INSTANCE.post(`/danh-muc-dia-chi/get-all-tinh-thanh`, {})
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getDanhMucCoQuan_HoatDong = async (hoatDong: boolean): Promise<any> => {
        let result;
        await AXIOS_INSTANCE.post(`/danh-muc-co-quan/findAll`, { hoatDong })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getDanhMucNoiCap = async (parentCode: string): Promise<DanhMucNoiCapDTO[]> => {
        let result: DanhMucNoiCapDTO[] = [];
        await AXIOS_INSTANCE
            .post(`/dkkd/dm-linh-vuc-noi-cap/findAllCommon`, { parentCode })
            .then((response) => {
                if (response && response.data && response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    findByToken = async (): Promise<any> => {
        let result : DanhMucCoQuanDTO[] = [];
        await AXIOS_INSTANCE.get(`/danh-muc-co-quan/findByToken`)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ ma: item.ma, ten: item.ten });
                    });
                }
            })
            .catch();
        return result;
    };

    getAllCoQuan = (ma: string) => {
        return AXIOS_INSTANCE.post('/danh-muc-co-quan/get-all-co-quan', ma)
    }

    delete = (id: number) => {
        return AXIOS_INSTANCE.post('/dkkd/dm-linh-vuc-cap-so-gp/delete', { id: id }, this.getTokenRequestHeaders());
      }
}
