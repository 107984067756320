import moment from "moment";
import StringUtil from "./stringUtils";
export default class DateUtil {
    static toDDMMYYYY_HH_MM_SS(date: Date): string {
        if (date != null) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        } else {
            return '';
        }
    }

    static toDDMMYYYY(date: null | Date): string {
        if (date != null) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return '';
        }
    }

    static timeToDDMMYYYY(dateTime: undefined | number): string| undefined {
        if (dateTime && dateTime != 0) {
            return moment(new Date(dateTime)).format("DD/MM/YYYY");
        } else {
            return '';
        }
    }

    static isDate(date: null | Date): boolean {
        if (date !== null && date !== undefined) {
            return true;
        }
        return false;
    }

    static  stringDDMMYYYtoDate(value:string): Date|null {
        let result= null;
        if(!StringUtil.isNullOrEmty(value)){
            result = moment(value, 'DD/MM/YYYY').toDate();
        }
        return result;
    }
    static  stringDDMMYYYHHMMSStoDate(value:string): Date|null {
        let result= null;
        if(!StringUtil.isNullOrEmty(value)){
            result = moment(value, 'DD/MM/YYYY HH:mm:ss').toDate();
        }
        return result;
    }

    static is18Years(date: null | number): boolean {
        if (date != null && date > 0) {
            let today =  moment(new Date());
            let birthDate  = moment(new Date(date));
            let age = today.year() - birthDate.year();
            let m = today.month() - birthDate.month();
            if (m < 0 || (m === 0 && today.date() > birthDate.date())) {
                 age = age -1;
            }
            if(age >= 18){
                return true;
            }
          
        }
        return false; 
    }

    static getOneYear(dateTime: null | number): number {
        if (dateTime) {
            const ngayCap = new Date(dateTime);
            const nam = ngayCap.getFullYear();
            const thang = ngayCap.getMonth();
            var ngay = ngayCap.getDate();
            return new Date(nam+1, thang, ngay).getTime();
        } 
        return new Date().getTime();
    }


}