import { RoutePropsI } from "."
import BaoCaoRuouComponent from "../App/Components/CPBLR/BaoCaoRuou/BaoCaoRuouComponent"
import { BieuDoTronThongKeRuou } from "../App/Components/CPBLR/BieuDoTron/BieuDoTronThongKeRuou"
import CapSoGiayPhepRuouComponent from "../App/Components/CPBLR/CapSoGiayPhep/CapSoGiayPhepRuouComponent"
import ChiTietGPRuou from "../App/Components/CPBLR/QuanLyDKRuou/ChiTiet/ChiTietGPRuou"
import HoanTraHuyGiayPhepRuouComponent from "../App/Components/CPBLR/QuanLyDKRuou/HoanTraHuy/HoanTraHuyGiayPhepRuouComponent"
import HoanTraThuHoiGiayPhepRuouComponent from "../App/Components/CPBLR/QuanLyDKRuou/HoanTraThuHoi/HoanTraThuHoiGiayPhepRuouComponent"
import HuyGiayPhepRuouComponent from "../App/Components/CPBLR/QuanLyDKRuou/Huy/HuyGiayPhepRuouComponent"
import { QuanLyKDRuouComponent } from "../App/Components/CPBLR/QuanLyDKRuou/QuanLyKDRuouComponent"
import ThuHoiGiayPhepRuouComponent from "../App/Components/CPBLR/QuanLyDKRuou/ThuHoi/ThuHoiGiayPhepRuouComponent"
import ThongKeRuouComponent from "../App/Components/CPBLR/ThongKeRuou/ThongKeRuouComponent"
import CapDoiBLRuouComponent from "../App/Components/CPBLR/ThuLyHoSo/BanLe/CapDoi/CapDoiBLRuouComponent"
import CapLaiBLRuouComponent from "../App/Components/CPBLR/ThuLyHoSo/BanLe/CapLai/CapLaiBLRuouComponent"
import CapMoiBLRuouComponent from "../App/Components/CPBLR/ThuLyHoSo/BanLe/CapMoi/CapMoiBLRuouComponent"
import DauKyBLRuouComponent from "../App/Components/CPBLR/ThuLyHoSo/BanLe/DauKy/DauKyBLRuouComponent"
import CapDoiSXRuouComponent from "../App/Components/CPBLR/ThuLyHoSo/SanXuat/CapDoi/CapDoiSXRuouComponent"
import CapLaiSXRuouComponent from "../App/Components/CPBLR/ThuLyHoSo/SanXuat/CapLai/CapLaiSXRuouComponent"
import CapMoiSXRuouComponent from "../App/Components/CPBLR/ThuLyHoSo/SanXuat/CapMoi/CapMoiSXRuouComponent"
import DauKySXRuouComponent from "../App/Components/CPBLR/ThuLyHoSo/SanXuat/DauKy/DauKySXRuouComponent"
export const ROUTE_CN_RUOU: RoutePropsI[] = [
    {
        ten: 'CẤP GIẤY PHÉP SẢN XUẤT RƯỢU THỦ CÔNG NHẰM MỤC ĐÍCH KINH DOANH',
        giaoDien: CapMoiSXRuouComponent,
        duongDan: '/cap-moi-sx-ruou',
    },
    {
        ten: 'CẤP GIẤY PHÉP BÁN LẺ RƯỢU',
        giaoDien: CapMoiBLRuouComponent,
        duongDan: '/cap-moi-bl-ruou',
    },
    {
        ten: 'CẤP ĐỔI GIẤY PHÉP BÁN LẺ RƯỢU',
        giaoDien: CapDoiBLRuouComponent,
        duongDan: '/cap-doi-bl-ruou',
    },
    {
        ten: 'CẤP LẠI GIẤY PHÉP BÁN LẺ RƯỢU',
        giaoDien: CapLaiBLRuouComponent,
        duongDan: '/cap-lai-bl-ruou',
    },
    {
        ten: 'CẤP LẠI GIẤY PHÉP SẢN XUẤT RƯỢU THỦ CÔNG NHẰM MỤC ĐÍCH KINH DOANH',
        giaoDien: CapLaiSXRuouComponent,
        duongDan: '/cap-lai-sx-ruou',
    },
    {
        ten: 'CẤP SỬA ĐỔI, BỔ SUNG GIẤY PHÉP SẢN XUẤT RƯỢU THỦ CÔNG NHẰM MỤC ĐÍCH KINH DOANH',
        giaoDien: CapDoiSXRuouComponent,
        duongDan: '/cap-doi-sx-ruou',
    }
]
export const ROUTE_CN_PAGE: RoutePropsI[] = [
    {
        ten: 'QUẢN LÝ KINH DOANH RƯỢU',
        giaoDien: QuanLyKDRuouComponent,
        duongDan: '/quan-ly-ruou/danh-sach',
    },
    {
        ten: 'BÁO CÁO TÌNH HÌNH CẤP PHÉP KINH DOANH RƯỢU',
        giaoDien: BaoCaoRuouComponent,
        duongDan: '/ruou/bao-cao',
    },
    {
        ten: 'THỐNG KÊ TÌNH HÌNH CẤP PHÉP KINH DOANH RƯỢU',
        giaoDien: ThongKeRuouComponent,
        duongDan: '/ruou/thong-ke',
    },
    {
        ten: 'CẤP SỐ GIẤY PHÉP RƯỢU',
        giaoDien: CapSoGiayPhepRuouComponent,
        duongDan: '/ruou/cap-so',
    },
    {
        ten: 'BIỂU ĐỒ TRÒN TÌNH HÌNH CẤP PHÉP RƯỢU',
        giaoDien: BieuDoTronThongKeRuou,
        duongDan: '/ruou/thong-ke-bieu-do-tron-ruou',
    },
]

export const ROUTE_DS_RUOU: RoutePropsI[] = [
    {
        ten: 'QUẢN LÝ KINH DOANH RƯỢU',
        giaoDien: QuanLyKDRuouComponent,
        duongDan: '/quan-ly-ruou/danh-sach',
    },
    {
        ten: 'BÁO CÁO TÌNH HÌNH CẤP PHÉP KINH DOANH RƯỢU',
        giaoDien: BaoCaoRuouComponent,
        duongDan: '/ruou/bao-cao',
    },
    {
        ten: 'THỐNG KÊ TÌNH HÌNH CẤP PHÉP KINH DOANH RƯỢU',
        giaoDien: ThongKeRuouComponent,
        duongDan: '/ruou/thong-ke',
    },
]

export const ROUTE_CHITIET_GP_RUOU: RoutePropsI = {
    ten: 'CHI TIẾT GIẤY PHÉP RƯỢU',
    giaoDien: ChiTietGPRuou,
    duongDan: '/quan-ly-ruou/chi-tiet-gp',
}
export const ROUTE_DAUKY_SX: RoutePropsI = {
    ten: 'NHẬP ĐẦU KỲ GIẤY PHÉP SẢN XUẤT RƯỢU',
    giaoDien: DauKySXRuouComponent,
    duongDan: '/quan-ly-ruou/nhap-dau-ky-sx-ruou',
}
export const ROUTE_DAUKY_BL: RoutePropsI = {
    ten: 'NHẬP ĐẦU KỲ GIẤY PHÉP BÁN LẺ RƯỢU',
    giaoDien: DauKyBLRuouComponent,
    duongDan: '/quan-ly-ruou/nhap-dau-ky-bl-ruou',
}

export const ROUTE_DAUKY_RUOU: RoutePropsI[] = [
    ROUTE_DAUKY_SX
    ,
    ROUTE_DAUKY_BL
];
export const ROUTE_THUHOI_GP_RUOU: RoutePropsI = {
    ten: 'THU HỒI',
    giaoDien: ThuHoiGiayPhepRuouComponent,
    duongDan: '/quan-ly-ruou/thu-hoi-gp',
}
export const ROUTE_HOANTRA_THUHOI_GP_RUOU: RoutePropsI = {
    ten: 'HOÀN TRẢ THU HỒI',
    giaoDien: HoanTraThuHoiGiayPhepRuouComponent,
    duongDan: '/quan-ly-ruou/hoan-tra-thu-hoi-gp',
}
export const ROUTE_HUY_GP_RUOU: RoutePropsI = {
    ten: 'HỦY',
    giaoDien: HuyGiayPhepRuouComponent,
    duongDan: '/quan-ly-ruou/huy-gp',
}
export const ROUTE_HOANTRA_HUY_GP_RUOU: RoutePropsI = {
    ten: 'HOÀN TRẢ HỦY',
    giaoDien: HoanTraHuyGiayPhepRuouComponent,
    duongDan: '/quan-ly-ruou/hoan-tra-huy-gp',
}

export const ROUTE_GUEST_RUOU: RoutePropsI[] = [
    ROUTE_CHITIET_GP_RUOU,
    ROUTE_THUHOI_GP_RUOU,
    ROUTE_HOANTRA_THUHOI_GP_RUOU,
    ROUTE_HUY_GP_RUOU,
    ROUTE_HOANTRA_HUY_GP_RUOU,
    ...ROUTE_DAUKY_RUOU
];







