import React, { useEffect, useRef, useState } from 'react';
import { BrowserHistory } from "history";
import { Box, Button, FormHelperText, Grid, InputBase, InputLabel, Paper, Snackbar, Typography } from "@material-ui/core";
import { CustomTab, CustomTabs } from "../../../commons/components/tabs";
import { BaseResponse } from "../../../../api/baseService";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { BienDong, TinhTrangString } from "../../../../api/DKKD/model/BienDongTrangThaiGPKDDTO";
import ThongTinChungGPComponent from "../Modal/ThongTinChungGPComponent";
import { BienDongTrangThaiGPTLService } from "../../../../api/CPBLTL/BienDongTrangThaiGPTLService";
import { BienDongTrangThaiGPTLDTO } from "../../../../api/CPBLTL/model/BienDongTrangThaiGPKTLDTO";
import { TapDinhKem } from "../../DKKD/Modal/tapDinhKem";
import { HoSoThuLyBLThuocLa, HoSoThuLyBLThuocLaBienDong } from "../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import DropZoneCustom from "../../../commons/components/CustomDropzone/DropZoneCustom";
import { FileEntry } from "../../../../api/Common/fileEntry";
import { FileServerService } from "../../../../api/Common/FileServerService";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import axios from "../../../../api/index";
import { LOAIBIENDONG_THUHOI, TINHTRANG_THUHOI } from '../../../Constants/ThuocLaConstants';
import { useHistory } from 'react-router-dom';

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
interface FormValues {
    requestDTO: BienDongTrangThaiGPTLDTO,
}

const ThuHoiGiayPhepKinhDoanhThuocLa = () => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [data, setData] = React.useState({} as HoSoThuLyBLThuocLaBienDong);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const autoCompleteRef = useRef<HTMLDivElement>(null);
    const bienDongTrangThaiGPTLService = new BienDongTrangThaiGPTLService();
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const fileServerService = new FileServerService();
    const history = useHistory();
    const validateionSchema = Yup.object().shape({
        requestDTO: Yup.object().shape({
            noiDung: Yup.string().required('Vui lòng nhập lý do thu hồi giấy phép!'),
            ngayBienDong: Yup.string().test(
                "test-ctype",
                "Vui lòng chọn ngày thu hồi giấy phép!",
                function (value) {
                    return ((value && (value != '0' && value != undefined && value != 'NaN')) ? true : false);
                }
            ).required('Vui lòng chọn ngày thu hồi giấy phép!'),
        })
    });

    const initDefaultValue: FormValues = {
        requestDTO: {
            hoSoThuLyId: data?.id,
            giayPhepId: data?.giayPhepId,
            soGiayPhep: data?.soGiayPhep,
            ngayBienDong: 0,
            ngayQuyetDinh: 0,
            soQuyetDinh: '',
            ghiChu: "",
            noiDung: "",
            id: 0,
            maBienDong: LOAIBIENDONG_THUHOI.ma,
            tenBienDong: LOAIBIENDONG_THUHOI.ten,
            maTinhTrang: TINHTRANG_THUHOI.ma,
            tenTinhTrang: TINHTRANG_THUHOI.ten,
            files: [],
            fileTrash: [],
        } as BienDongTrangThaiGPTLDTO
    }

    const formik = useFormik({
        initialValues: initDefaultValue,
        validationSchema: validateionSchema,
        onSubmit: () => submitData(),
    });

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        var temp = { giayPhepId: history?.location?.state?.id };
        bienDongTrangThaiGPTLService.getGiayPhepKinhDoanhId(temp).then(res => {
            if (res) {
                setData(res);
                if (res?.lichSuThayDoiGanNhat?.maBienDong === LOAIBIENDONG_THUHOI.ma) {
                    setAttachFileServer(res?.fileBienDongs ? res?.fileBienDongs : []);
                    formik.setValues({
                        requestDTO: {
                            hoSoThuLyId: res?.id,
                            giayPhepId: res?.giayPhepId,
                            soGiayPhep: res?.soGiayPhep,
                            ngayBienDong: res?.lichSuThayDoiGanNhat?.ngayBienDong,
                            ngayQuyetDinh: res?.lichSuThayDoiGanNhat?.ngayQuyetDinh,
                            soQuyetDinh: res?.lichSuThayDoiGanNhat?.soQuyetDinh,
                            ghiChu: res?.lichSuThayDoiGanNhat?.ghiChu,
                            noiDung: res?.lichSuThayDoiGanNhat?.noiDung,
                            id: res?.lichSuThayDoiGanNhat?.id,
                            maBienDong: LOAIBIENDONG_THUHOI.ma,
                            tenBienDong: LOAIBIENDONG_THUHOI.ten,
                            maTinhTrang: TINHTRANG_THUHOI.ma,
                            tenTinhTrang: TINHTRANG_THUHOI.ten,
                            files: res?.fileBienDongs ? res?.fileBienDongs : [],
                            fileTrash: [],
                        } as BienDongTrangThaiGPTLDTO,
                    });
                } else {
                    formik.setValues({
                        requestDTO: {
                            hoSoThuLyId: res?.id,
                            giayPhepId: res?.giayPhepId,
                            soGiayPhep: res?.soGiayPhep,
                            ngayBienDong: 0,
                            ngayQuyetDinh: 0,
                            soQuyetDinh: '',
                            ghiChu: "",
                            noiDung: "",
                            id: 0,
                            maBienDong: LOAIBIENDONG_THUHOI.ma,
                            tenBienDong: LOAIBIENDONG_THUHOI.ten,
                            maTinhTrang: TINHTRANG_THUHOI.ma,
                            tenTinhTrang: TINHTRANG_THUHOI.ten,
                            files: [],
                            fileTrash: []
                        } as BienDongTrangThaiGPTLDTO,
                    });
                }
            } else {
                const dataNew = {} as HoSoThuLyBLThuocLaBienDong;
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const submitData = async () => {
        await bienDongTrangThaiGPTLService.luuVaCapNhatKhoiPhuc(formik.values.requestDTO).then(res => {
            handleSubmitData(res);
        });
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const handleSubmitData = (resp: BaseResponse) => {
        if (resp.isSuccess) {
            if (formik.values.requestDTO.id > 0) {
                formik.setFieldValue("requestDTO", resp.data);
            } else {
                formik.setFieldValue("requestDTO", resp.data);
            }
            showMessageAlert('Yêu cầu thực hiện thành công!');
        } else {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        }
    }
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...formik.values.requestDTO?.files];
        mems = mems.concat(memData);
        formik.setFieldValue("requestDTO.files", mems);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let mems = [...formik.values.requestDTO?.files];
        let memTrashs = formik.values.requestDTO?.fileTrash ? formik.values.requestDTO?.fileTrash : [];
        if (item?.fileParentId > 0) {
            memTrashs.push(item);
        }
        mems.splice(index, 1);
        setAttachFileServer(mems);
        formik.setFieldValue("requestDTO.files", mems);
        formik.setFieldValue("requestDTO.fileTrash", memTrashs);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    const resetData = () => {
        formik.setTouched({});
        formik.setErrors({});
        formik.setValues({
            requestDTO: {
                ...formik.values.requestDTO,
                ngayBienDong: 0,
                ngayQuyetDinh: 0,
                soQuyetDinh: '',
                ghiChu: '',
                noiDung: '',
                files: [],
            } as BienDongTrangThaiGPTLDTO,
        });
        setAttachFileServer([]);
    }
    return (
        <>
            {
                <Paper square >
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    THU HỒI GIẤY PHÉP KINH DOANH THUỐC LÁ
                                </div>
                            </div>
                            {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab label="Thông tin chung" />
                                    <CustomTab label="Tập đính kèm" />
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className="grid-item">
                                            <ThongTinChungGPComponent data={data} />
                                        </Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <InputLabel>THÔNG TIN THU HỒI</InputLabel>
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel>Số quyết định</InputLabel>
                                            <InputBase fullWidth
                                                value={formik.values.requestDTO?.soQuyetDinh} name="requestDTO.soQuyetDinh"
                                                onChange={formik.handleChange} />
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel>Ngày quyết định</InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    placeholder="dd/MM/yyyy"
                                                    value={formik.values.requestDTO?.ngayQuyetDinh > 0 ? new Date(formik.values.requestDTO?.ngayQuyetDinh) : null}
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => formik.setFieldValue("requestDTO.ngayQuyetDinh", date?.toDate()?.getTime())}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel required>Ngày thu hồi</InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    placeholder="dd/MM/yyyy"
                                                    value={formik.values.requestDTO?.ngayBienDong > 0 ? new Date(formik.values.requestDTO?.ngayBienDong) : null}
                                                    error={formik.touched.requestDTO?.ngayBienDong && Boolean(formik.errors.requestDTO?.ngayBienDong)}
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => formik.setFieldValue("requestDTO.ngayBienDong", date?.toDate()?.getTime())}
                                                />
                                            </MuiPickersUtilsProvider>
                                            {
                                                formik.touched.requestDTO?.ngayBienDong && Boolean(formik.errors.requestDTO?.ngayBienDong) &&
                                                <FormHelperText className="-error"
                                                >{formik.errors.requestDTO?.ngayBienDong}</FormHelperText>
                                            }
                                        </Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <InputLabel required>Lý do thu hồi</InputLabel>
                                            <InputBase fullWidth
                                                error={formik.touched.requestDTO?.noiDung && Boolean(formik.errors.requestDTO?.noiDung)}
                                                value={formik.values.requestDTO?.noiDung} name="requestDTO.noiDung"
                                                onChange={formik.handleChange} />
                                            {
                                                formik.touched.requestDTO?.noiDung && Boolean(formik.errors.requestDTO?.noiDung) &&
                                                <FormHelperText className="-error"
                                                >{formik.errors.requestDTO?.noiDung}</FormHelperText>
                                            }
                                        </Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <InputLabel >Ghi chú</InputLabel>
                                            <InputBase fullWidth
                                                multiline
                                                rows="4"
                                                value={formik.values.requestDTO?.ghiChu} name="requestDTO.ghiChu" onChange={formik.handleChange} />
                                        </Grid>
                                        <Grid item xs={12} container className="grid-item">
                                            <DropZoneCustom
                                                acceptFiles={`${acceptFiles}`}
                                                attachFileServer={attachFileServer}
                                                url={axios.defaults.baseURL + "/file-server/upload/thuocla"}
                                                handleChangeStatus={handleChangeStatus}
                                                handleDeleteFileServer={handleDeleteFileServer}
                                                handleDownloadFile={handleDownloadFile}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="gridItem">
                                            <Box marginTop="5px">
                                                <Button type="submit" variant="contained" color="primary" disableElevation
                                                > Lưu
                                                </Button>
                                                <Button variant="contained" color="secondary" disableElevation
                                                    onClick={() => {
                                                        if (autoCompleteRef.current) {
                                                            let clearButton = autoCompleteRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
                                                            clearButton.click();
                                                        }
                                                        resetData();
                                                    }}
                                                > Xóa
                                                </Button>
                                                <Button type="button" variant="contained" color="primary"
                                                    onClick={(e) => history.go(-1)}
                                                    style={{ fontSize: '14px', width: '100px' }}>Trở về
                                                </Button>&nbsp;&nbsp;
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </TabPanel>
                        }
                        {
                            indexTab === 1 &&
                            <TabPanel value={indexTab} index={1}>
                                <TapDinhKem dataList={data?.files} />
                            </TabPanel>
                        }
                    </Grid>
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                </Paper>
            }
        </>
    )
}
export default ThuHoiGiayPhepKinhDoanhThuocLa;
