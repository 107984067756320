import React, { useEffect, useState } from 'react';
import {
    Box,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel
} from '@material-ui/core';
import StringUtil from "../../../../../utils/stringUtils";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { GiayPhepSXRuou, HoSoThuLyBLR } from "../../../../../../api/CPBLR/model/HoSoThuLyBLR";
import { TBPhanPhoiRuouDTO } from "../../../../../../api/CPBLR/model/TBPhanPhoiRuouDTO";
import { TBDiaDiemBLRuouDTO } from "../../../../../../api/CPBLR/model/TBDiaDiemBLRuouDTO";
import DropZoneCustom from "../../../../../commons/components/CustomDropzone/DropZoneCustom";
import { FileEntry } from "../../../../../../api/Common/fileEntry";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { createFileType, downLoadFile } from "../../../../../../api/Common/exportUtil";
import { FileServerService } from "../../../../../../api/Common/FileServerService";
import AXIOS_INSTANCE from "../../../../../../api";
import { CapMoiSXRuouService } from '../../../../../../api/CPBLR/CapMoiSXRuouService';
import moment from 'moment';
interface CapLaiSXRuouNoiDungProps {
    data: HoSoThuLyBLR;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
    showInfo: boolean;
    loadGiayPhepDaCap: (soGiayPhep: string) => void;
}

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

const CapLaiSXRuouNoiDungComponent = (props: CapLaiSXRuouNoiDungProps) => {
    const [data, setData] = React.useState({} as HoSoThuLyBLR);
    const { onUpdateData, submit, loadGiayPhepDaCap } = props;
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const fileServerService = new FileServerService();
    const capMoiSXRuouService = new CapMoiSXRuouService();
    const [dataAutocomple, setDataAutocomple] = useState<GiayPhepSXRuou[]>(new Array<GiayPhepSXRuou>());

    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
        setShowInfo(props.showInfo)
    }, [props.data, props.showInfo, props.onUpdateData]);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }
    
    const handleChangeNumber =  (e: any) => {
        let value =  e.target.value;
        onUpdateData(e.target.name, StringUtil.checkInputNumber(value) || value === "" ? value :  "");
    }

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }

    const setPhanPhoiRuous = (memData: TBPhanPhoiRuouDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data.phanPhoiRuous];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            phanPhoiRuous: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const setDiaDiemBLRuou = (memData: TBDiaDiemBLRuouDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data.diaDiemBLRuous];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            diaDiemBLRuous: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ?  data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            files: mems
        };
        onUpdateData("data", dataNew);
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let files = data.files ?  data.files : [];
        let mems = [...files];
        let memTrashs = data.fileTrash ? data.fileTrash : [];
        if (item?.fileParentId > 0) {
            memTrashs.push(item);
        }
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            files: mems,
            fileTrash: memTrashs
        };
        setAttachFileServer(mems);
        onUpdateData("data", dataNew);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    const handleSelectGiayPhepDaCap = (newValue: GiayPhepSXRuou) => {
        onUpdateData('soGiayPhepCu', newValue.soGiayPhep);
        loadGiayPhepDaCap(newValue.soGiayPhep);
    }
    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        let dataNew = { ...data, soGiayPhepCu: soGiayPhepCuNew };
        onUpdateData("data", dataNew);
        capMoiSXRuouService.getAutocompleteBySoGiayPhep_LoaiGiayPhep_PageSize(soGiayPhepCuNew, 'SXR', 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setDataAutocomple([...dataAutocompleNew]);
            }else{
                setDataAutocomple([]);
            }
        }).catch(error => {
            setDataAutocomple([]);
            console.log(error);
        });
    }
    return (
        <>
            {
                <Box padding={0}>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Số giấy phép </InputLabel>
                                <Autocomplete id="soGiayPhepCu"
                                    options={dataAutocomple}
                                    noOptionsText={"Không có dữ liệu"}
                                    getOptionLabel={(option) => option.soGiayPhep}
                                    onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data.soGiayPhepCu)}
                                                {...params.inputProps}
                                                value={data.soGiayPhepCu}
                                                onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}
                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.soGiayPhepCu) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số giấy phép </FormHelperText>
                                }

                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày cấp </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={true}
                                        readOnly={true}
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayCapPhep != null && data?.ngayCapPhep !== undefined && data?.ngayCapPhep != 0) ? new Date(data?.ngayCapPhep) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        disabled={true}
                                        readOnly={true}
                                        showTodayButton={true}
                                        value={(data?.ngayHetHanGP != null && data?.ngayHetHanGP !== undefined && data?.ngayHetHanGP != 0) ? new Date(data?.ngayHetHanGP) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item container hidden={!showInfo} >
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Tên thương nhân</InputLabel>
                                </Grid>
                                <Grid item xs={9} className="grid-item" >
                                    <InputLabel className="detail-label">{data?.tenThuongNhan}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Địa chỉ</InputLabel>
                                </Grid>
                                <Grid item xs={9} className="grid-item" >
                                    <InputLabel className="detail-label">{data?.diaChiDayDu}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Điện thoại</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel className="detail-label">{data?.dienThoaiTruSo}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Fax</InputLabel>   
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel className="detail-label">{data?.faxTruSo}</InputLabel>    
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Giấy CNĐKKD/DN/HTX/HKD</InputLabel> 
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel className="detail-label">{data?.giayCNDK}</InputLabel> 
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Ngày cấp</InputLabel> 
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel className="detail-label">{data?.ngayCap && data?.ngayCap !== undefined && data?.ngayCap !== null ? moment(data?.ngayCap).format("DD/MM/YYYY") : ""}</InputLabel>
                                </Grid>
                                <Grid item xs={3} className="grid-item" container justify="flex-end">
                                    <InputLabel >Nơi cấp</InputLabel> 
                                </Grid>
                                <Grid item xs={9} className="grid-item" >
                                    <InputLabel className="detail-label">{data?.noiCap}</InputLabel> 
                                </Grid>

                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required>Lý do cấp lại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.lyDo}
                                    name="lyDo"
                                    onChange={handleChange}
                                    error={submit && StringUtil.isNullOrEmty(data.lyDo)}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.lyDo) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập lý do</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Lần cấp lại </InputLabel>
                                <InputBase fullWidth 
                                    value={data?.soLan} name="soLan"  onChange={handleChangeNumber} type="number" />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày cấp lại</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayYeuCau != null && data?.ngayYeuCau !== undefined && data?.ngayYeuCau != 0) ? new Date(data?.ngayYeuCau) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayYeuCau')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>


                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={`${acceptFiles}`}
                                    attachFileServer={attachFileServer}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/ruou"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
        </>
    )
}
export default CapLaiSXRuouNoiDungComponent;
