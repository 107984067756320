import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";
import { BaoCaoThongKeGPRuouRequest } from "../model/BaoCaoThongKeGPRuouRequest";
import { HoSoThuLyBLR, TinhTrang } from "../model/HoSoThuLyBLR";
import { LichSuGiayPhepRuouDTO } from "../model/LichSuGiayPhepRuouDTO";

export const LichSuGiayPhepRuouDTO_INIT: LichSuGiayPhepRuouDTO = {
    id: 0,

    ngayCapNhat: 0,

    ngayTao: 0,

    maBienDong: '',

    tenBienDong: '',

    maTinhTrang: '',

    tenTinhTrang: '',

    ngayBienDong: 0,

    soQuyetDinh: '',

    ngayQuyetDinh: 0,

    soGiayPhep: '',

    giayPhepId: 0,

    hoSoThuLyId: 0,

    hoSoThuLyCuId: 0,

    soLan: 0,

    noiDung: '',

    ghiChu: '',

    maQuanHuyen: '',

    tenQuanHuyen: '',

    maPhuongXa: '',

    tenPhuongXa: '',

    tenTinhThanh: '',

    maTinhThanh: '',

    hoatDong: true,

    maLoaiGiayPhep: '',

    tenLoaiGiayPhep: '',

    nguoiKy: '',

    files: []
}

export const CAP_MOI_SX_RUOU_INIT: HoSoThuLyBLR = {
    id: 0,
    giayPhepId: 0,
    maHoSo: '',
    maThuTuc: '',
    tenToChuc: '',
    tenThuongNhan: '',
    tenTinhThanh: '',
    maTinhThanh: '',
    maQuanTruSo: '',
    tenQuanTruSo: '',
    maPhuongTruSo: '',
    tenPhuongTruSo: '',
    maDuongTruSo: '',
    tenDuongTruSo: '',
    soNhaTruSo: '',
    dienThoaiTruSo: '',
    faxTruSo: '',
    giayCNDK: '',
    ngayCap: 0,
    noiCap: '',
    noiNhan: '',
    maQuanSX: '',
    tenQuanSX: '',
    maPhuongSX: '',
    tenPhuongSX: '',
    maDuongSX: '',
    tenDuongSX: '',
    soNhaSX: '',
    dienThoaiSX: '',
    faxSX: '',
    maTinhTrang: '',
    tenTinhTrang: '',
    maBienDong: '',
    tenBienDong: '',
    soGiayPhep: '',
    soGiayPhepCu: '',
    ngayCapPhep: 0,
    ngayHetHanGP: 0,
    ngaySuaDoi: 0,
    donViCapPhep: true,
    loaiGiayPhep: '',
    lyDo: '',
    lyDoSuaDoi: '',
    soLan: 0,
    lanSuaDoi: '',
    ngayYeuCau: 0,
    sanXuatRuous: [],
    phanPhoiRuous: [],
    diaDiemBLRuous: [],
    files: [],
    fileTrash: [],
    diaChiDayDu: '',
    diaChiDayDuSX: '',
    ghiChu: '',
    nguoiKy: '',
    maCoQuan: '',
    maChuyenNganh: '',
    lichSuThuHoi: { ...LichSuGiayPhepRuouDTO_INIT },

    loaiKetQua: LoaiKetQuaEnum.CHUA_LAY_KET_QUA,
}

export const BaoCaoThongKeGPRuouRequest_INIT: BaoCaoThongKeGPRuouRequest = {
    ky: 1,
    nam: 0,
    tuNgay: 0,
    denNgay: 0,
    maPhuongXa: '',
    maQuanHuyen: '',
    tenPhuongXa: '',
    tenQuanHuyen: '',
    maTinhTrang: '',
    exportType: '',
    exportFileName: '',
    tenNamThongKe : '',
    pageSize: 10,
    pageNumber: 1,
    keywords: '',
    hoatDong: 0
}