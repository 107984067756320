import { BaseService, Pagination } from "../baseService";
import { GiayPhepSXRuou } from "./model/HoSoThuLyBLR";
import AXIOS_INSTANCE from "..";
import { LichSuGiayPhepRuouDTO } from "./model/LichSuGiayPhepRuouDTO";
import { RequestBaoCaoThongKeDTO } from "./model/BaoCaoThongKeGPRuouRequest";

export class QuanLyDKRuouService extends BaseService {

    loadDanhSach = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<GiayPhepSXRuou[]>> => {
        let result: Pagination<GiayPhepSXRuou[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('ruou/giay-phep/danh-sach-quan-ly', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }
    getByGiayPhepId = (giayPhepId: number, pageNumber: number, pageSize: number) => {
        return AXIOS_INSTANCE.post('ruou/giay-phep/lich-su-by-giay-phep-id', { giayPhepId: giayPhepId, pageNumber: pageNumber, pageSize: pageSize }, this.getTokenRequestHeaders());

    }
    capNhatTinhTrangGiayPhep = (lichSuGiayPhepRuouDTO: LichSuGiayPhepRuouDTO) => {
        return AXIOS_INSTANCE.post('ruou/giay-phep/cap-nhap-tinh-trang-giay-phep', lichSuGiayPhepRuouDTO, this.getTokenRequestHeaders());

    }
    downloadFileBaoCao = (request: RequestBaoCaoThongKeDTO) => {
        return AXIOS_INSTANCE.post('ruou/giay-phep/export-danh-sach-giay-phep', request,{
            responseType: 'blob',
        });
    }
}
