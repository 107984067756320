import { Box, Button, IconButton, NativeSelect } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import React, { Dispatch, useEffect, useState } from 'react';
import { CustomInput } from "../../../../commons/components/input/customInput";
import { Cell, Row } from "../../../../commons/components/table/tableCommons";
import { DiaDiemToChucBanLeDTO } from "../../../../../api/CPBLTL/model/TableBLThuocLa";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { useDispatch } from "react-redux";
import DiaDiemToChucBanLeModal from "../Modal/DiaDiemToChucBanLeModal";
import DeleteIcon from "@material-ui/icons/Delete";

interface IDataListProps {
    dataList: DiaDiemToChucBanLeDTO[];
    deleteItem?: (id: string) => void;
    onSetDatas: (memData: DiaDiemToChucBanLeDTO, isEdit: Boolean, isDelete: Boolean) => void;
}

export const TableDiaDiemToChucBanLeComponent = (props: IDataListProps) => {

    const { onSetDatas, deleteItem } = props;
    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataTepms, setDataTepms] = useState<DiaDiemToChucBanLeDTO[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [itemSeleted, setItemSeleted] = useState<DiaDiemToChucBanLeDTO>({} as DiaDiemToChucBanLeDTO);

    useEffect(() => {
        setDataTepms(props.dataList);
        setTotal(props.dataList?.length);
        handleSearch({ page: 1, size: size });
    }, [props.dataList, total, size]);

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        handleSearch({ page: value, size: size });
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1)
        setSize(Number(event.target.value));
        handleSearch({ page: 1, size: Number(event.target.value) });
    }

    const handleSearch = (search: any) => {
        setRows(dataTepms?.slice((search.page - 1) * search.size, search.page * search.size));
    }

    const showAddDialog = async () => {
        dispatchLoading(showLoading());
        setOpenDialog(true);
        setOpenEdit(false);
        dispatchLoading(hideLoading());
    }

    const showEditDialog = async (item: DiaDiemToChucBanLeDTO) => {
        dispatchLoading(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        setOpenEdit(true);
        dispatchLoading(hideLoading());
    }

    const handleAddOrUpdate = (memData: DiaDiemToChucBanLeDTO, isEdit: Boolean) => {
        let mems = [...dataTepms];
        if (isEdit) {
            const idx = dataTepms.findIndex(e => e.ngayTao === memData.ngayTao);
            dataTepms[idx] = memData;
        } else {
            let mem = { ...memData, ngayTao: new Date().getTime() };
            mems.unshift(mem);
        }
        onSetDatas(memData, isEdit, false);
        setOpenDialog(false);
    }
    const onDeleteMems = (memData: DiaDiemToChucBanLeDTO) => {
        onSetDatas(memData, false, true);
    }
    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            {openDialog &&
                <DiaDiemToChucBanLeModal openDialog={openDialog} onDialogClose={() => { setOpenDialog(false) }} itemEdit={itemSeleted} onSubmitData={handleAddOrUpdate} openEdit={openEdit} />
            }
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="30%">Tên</Cell>
                        <Cell component="th" scope="row" align="center" width="40%">Địa chỉ</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Điện thoại</Cell>
                        <Cell component="th" scope="row" align="center" width="5%">Thao tác</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {(page - 1) * size + i + 1}
                                    </Cell>
                                    <Cell align="left">{row.tenChiNhanhVanPhong}</Cell>
                                    <Cell align="left">{row.diaChi}</Cell>
                                    <Cell align="left">{row.dienThoai}</Cell>
                                    <Cell align="center">
                                        <Box display="flex" justifyContent="center">
                                            <IconButton title="Chỉnh sửa" color="primary" aria-label="edit" size="small" onClick={() => { showEditDialog(row) }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton title="Xóa" color="secondary" aria-label="delete" size="small"
                                                onClick={() => { onDeleteMems(row) }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Cell>
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={5} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                <Pagination count={Math.ceil(total / size)} defaultPage={1} siblingCount={1} variant="outlined"
                    size="medium"
                    color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                    <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                    <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </NativeSelect>
                </Box>
                <Box component="span" paddingX="5px">Mẫu tin {rows?.length > 0 ? (page - 1) * size + 1 : 0} - {page * size > total ? total : (page * size)} / {total}</Box>

                <Button onClick={showAddDialog} variant="contained" color="primary" disableElevation>
                    Thêm mới
                </Button>
            </Box>
        </TableContainer>
    )
}
