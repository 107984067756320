import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";
import { GiayPhepKinhDoanhCuDTO, GiayPhepKinhDoanhDTO } from "../model/giayPhepKinhDoanhDTO";
import { HoSoThuLyDTO, initialValuesDiaChi, NhomCaNhanDTO } from "../model/hoSoThuLyDTO";

export const HoSoThuLyDTO_INIT: HoSoThuLyDTO = {
    id: 0,

    userName: '',

    nguoiTao: '',

    nguoiThuLy: '',

    maHoSo: '',

    maThuTuc: '',

    hoTen: '',

    maGioiTinh: '',     //1: nam, 2: nữ, 3: khác 

    tenGioiTinh: '',

    ngaySinh: 0,

    maDanToc: '01',

    tenDanToc: 'Kinh',

    maQuocTich: 'VN',

    tenQuocTich: 'Việt Nam',

    loaiGiayTo: 1, // 1:cmnd, 2: căn cước, 3: hộ chiếu

    tenGiayTo: '',
    soGiayToTuyThan: '',

    diaChi: initialValuesDiaChi,

    ngayCap: 0,

    tenNoiCap: '',

    maNoiCap: '',

    ngayHetHan: 0,

    ngayCapPhep: 0,

    loaiChuThe: 1,  //1: nhóm cá nhân, 2: cá nhân

    tenChuThe: 'Cá nhân',

    tenNhomCaNhan: '',

    hoKhau: '',

    choO: '',

    tenHoKinhDoanh: '',

    tongVon: 0,

    soLuongLaoDong: 0,

    tenDanhSachNganhNgheKinhDoanh: '',

    chiTietNganhNghe: '',

    tenLinhVuc: '',

    maLinhVuc: '',

    matHang: '',

    maQuan: '',

    tenQuan: '',

    maPhuong: '',

    tenPhuong: '',

    maDuong: '',

    tenDuong: '',

    soNha: '',

    diaChiKinhDo: 0,

    diaChiViDo: 0,

    dienThoai: '',

    fax: '',

    email: '',

    website: '',

    ngayTao: 0,

    ngayCapNhat: 0,

    diaChiDayDu: '',

    noiSinh: '',

    chucVu: '',

    maBienDong: '',

    tenBienDong: '',

    tinhTrang: '',

    tenTinhTrang: '',

    soGiayPhep: '',

    soGiayPhepCu: '',

    ngayBienDong: 0,

    lyDoThayDoi: '',

    noiDungThayDoi: '',

    giayPhepId: 0,
    //ReadAndWrite
    files: [],
    thanhVienGopVons: [],
    nganhNgheKinhDoanhs: [],
    //Read
    lichSuGiayPheps: [],
    giayPhepCu: {} as GiayPhepKinhDoanhCuDTO,
    giayPhep: {} as GiayPhepKinhDoanhDTO,
    checkHKTT: false,
    maSoThue: '',
    checkNamSinh: false,
    soLanCapDoi: 0,
    soLanCapLai: 0,
    ngayCapDoi: 0,
    ngayCapLai: 0,

    tuNgay: 0,
    denNgay: 0,
    noiNhan: '',
    nhomCaNhans: new Array<NhomCaNhanDTO>(),

    loaiKetQua: LoaiKetQuaEnum.CHUA_LAY_KET_QUA,

    ngayHenTra: 0,
}
