import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CoQuanDTO, DanhMucService } from "../../../api/danhMuc/danhMucService";
import { DanhMucBieuMauService, RequestDM } from "../../../api/QuanTri/danhMucBieuMauService";
import { DanhMucBieuMauDTO } from "../../../api/QuanTri/Model/danhMucBieuMau";
import { UserData } from "../../../api/userMgt/userManagementService";
import { AlertMessage, CustomAlert } from "../../../App/commons/components/alert";
import { ConfirmationDialogRaw } from "../../../App/commons/components/dialog/confirmDialog";
import { getObject } from "../../../helpers/localStorage";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import DataListBieuMau from "./dataListBieuMau";
import DialogBieuMau from "./dialogBieuMau";

export interface DanhMucBieuMauProps { }

export const DanhMucBieuMau: React.SFC<DanhMucBieuMauProps> = () => {
    // Constant
    let C_USER_DATA = getObject("C_USER_DATA") as UserData;
    // Hook
    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    // State
    const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [itemSeleted, setItemSeleted] = useState<DanhMucBieuMauDTO | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataList, setDataList] = useState<DanhMucBieuMauDTO[]>([]);
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);
    const [keyword, setKeyword] = useState("");
    const [isActive, setIsActive] = useState("0");
    const [coQuan, setCoQuan] = useState<string>('');
    const [coQuans, setCoQuans] = useState<CoQuanDTO[]>([]);
    // Control
    const loadDataTable = async (coQuan: string) => {
        const request = {
            maCoQuan: coQuan,
            pageNumber: page,
            pageSize: size,
            name: keyword,
            isActive: isActive === '0' ? null : (isActive === '1' ? true : false)
        } as RequestDM;
        await new DanhMucBieuMauService().searchDanhMucBieuMau(request).then((res) => {
            setDataList(res.rows);
            setTotal(res.total);
        });
    };

    useEffect(() => {
        loadDataTable(coQuan);
    }, [page, size]);

    useEffect(() => {
        async function getDuLieu() {
            const result = await new DanhMucService().findByToken();
            if (result && result.length > 0) {
                // setCoQuan(result[0]?.ma);
                loadDataTable(coQuan);
            }
            setCoQuans(result)
        }
        getDuLieu();

    }, []);

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const showEditDialog = (item: DanhMucBieuMauDTO) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const handleCloseDeleteRole = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            // if (itemSeleted.id) {
            // showMessageAlert("Chức năng đang phát triển", false);
            //todo...
            if (itemSeleted && itemSeleted.id) {
                await new DanhMucBieuMauService().deleteBieuMau(itemSeleted).then((res) => {
                    if (Boolean(res.isSuccess)) {
                        loadDataTable(coQuan);
                        showMessageAlert('Xóa cấu hình biểu mẫu thành công');
                    } else {
                        showMessageAlert('Xóa cấu hình biểu mẫu không thành công', false);
                    }
                });
            }
        }
        setOpenDeleteConfirmDlg(false);
    };

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSize(Number(event.target.value));
    };

    const handleDeleteItem = (item: DanhMucBieuMauDTO) => {
        if (item) {
            setItemSeleted(item);
            setOpenDeleteConfirmDlg(true);
        }
    };

    const handleSubmitData = async (data: DanhMucBieuMauDTO) => {
        await new DanhMucBieuMauService().saveDanhMucBieuMau(data).then(resp => {
            if (resp.isSuccess) {
                setOpenDialog(false);
                loadDataTable(coQuan);
                showMessageAlert('Yêu cầu thực hiện thành công', true);
            } else {
                showMessageAlert(resp.message, false);
            }
        });
    };

    const handleSearch = async () => {
        loadDataTable(coQuan);
    };

    const handleChange = (event: any) => {
        setCoQuan(event.target.value);
    };

    return (
        <div className="dash-content">
            <div className="dash-title" style={{ marginBottom: 10 }}>
                <span>Danh Mục Biểu Mẫu</span>
            </div>
            <form noValidate autoComplete="off">
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={4} className="grid-item">
                        <InputLabel >Cơ quan</InputLabel> 
                        <NativeSelect input={<InputBase fullWidth />} name="maCoQuan" value={coQuan} onChange={handleChange}>
                            <option value={''}>Chọn</option>
                            {
                                coQuans?.map((row, i) => (
                                    <option value={row?.ma}>{row?.ten}</option>
                                ))
                            }
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={4} className="grid-item">
                        <InputLabel >Từ khóa</InputLabel>
                        <InputBase
                            fullWidth
                            placeholder="Nhập từ khóa tìm kiếm"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}

                            inputProps={{ "aria-label": "description" }}
                        />
                    </Grid>
                    <Grid item xs={4} className="gridItem">
                        <InputLabel >Hoạt động</InputLabel>
                        <NativeSelect value={isActive} onChange={(e) => setIsActive(e.target.value)}
                            input={<InputBase fullWidth />}  >
                            <option value="0"></option>
                            <option value="1">Hoạt động</option>
                            <option value="2">Ngừng hoạt động</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={12} className="button-group-center">
                        <Button variant="contained" color="primary" disableElevation style={{ marginRight: 10 }} onClick={handleSearch}>
                            Tìm kiếm
                        </Button>
                        <Button variant="contained" color="primary" disableElevation onClick={() => setOpenDialog(true)}>
                            Thêm mới
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <DataListBieuMau
                            dataList={dataList}
                            deleteItem={handleDeleteItem}
                            pageProps={{
                                page: page,
                                count: total,
                                rowsPerPage: size,
                                onChangePage: handleOnChangePage,
                                onChangeRowsPerPage: handOnChangeRowsPerPage,
                            }}
                            onEdit={showEditDialog}
                            modalEdit={{ open: true, openEditDialog: showEditDialog }}
                        />
                    </Grid>
                </Grid>

                <ConfirmationDialogRaw id="confirm-dialog-delete-dm-bieumau" keepMounted open={openDeleteConfirmDlg}
                    title="Xóa cấu hình biểu mẫu"
                    content="Bạn có đồng ý xóa cấu hình biểu mẫu này?" onClose={handleCloseDeleteRole}
                />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                        {alertMessage.content}
                    </CustomAlert>
                </Snackbar>
            </form>
            {
                openDialog && <DialogBieuMau openDialog={openDialog} onDialogClose={() => setOpenDialog(false)}
                    itemEdit={itemSeleted} onSubmitData={handleSubmitData} />
            }
        </div >
    );
};
