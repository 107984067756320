import * as Types from '../Constants/action-types';
var initialState = {};
const editConsultingUnits = (state = initialState, action) => {
    switch (action.type) { 
        case Types.UPDATE_CONSULTING:
            return action.consulting;
        default:
            return {...state};    
    }
}
export default editConsultingUnits;