import { Box, Button, NativeSelect } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useRef } from "react";
import { KiemTranNganChanDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { Cell, Row } from '../../../../commons/components/table/tableCommons';

interface TableProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    dataList: KiemTranNganChanDTO[];
    search: () => void;
    onUpdate:(itemEdit: KiemTranNganChanDTO) => void;
    onDelete:(id : number)=> void;
}
const TableKiemTraNganChans= (props: TableProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    
    useEffect(() => {
        setTotalPage(Math.ceil(props.count / props.rowsPerPage));
        setRowsPerPage(props.rowsPerPage);
    }, [props.count, props.rowsPerPage]);

    const handleUpdate = (itemEdit: KiemTranNganChanDTO) => {
        props.onUpdate(itemEdit);
    };
    const handleDelete = (id: number) => {
       props.onDelete(id); 
    };
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                            <Cell component="th" scope="row" align="center" width="15%">Nội dung ngăn chặn </Cell>
                            <Cell component="th" scope="row" align="center" width="15%">Phường, xã</Cell>
                            <Cell component="th" scope="row" align="center" width="15%">Quận, huyện</Cell>
                            <Cell component="th" scope="row" align="center" width="5%" >Số tờ</Cell>
                            <Cell component="th" scope="row" align="center" width="5%">Số thửa</Cell>
                            <Cell component="th" scope="row" align="center" width="15%">Chủ sử dụng</Cell>
                            <Cell component="th" scope="row" align="center" >Chi tiết</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Thao tác</Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props?.dataList?.length > 0 ?
                                props?.dataList?.map((row, i) => (
                                    <Row key={i}>
                                        <Cell align ="center">
                                            {((props.page - 1) * rowsPerPage) + i + 1}
                                        </Cell>
                                        <Cell>
                                            {row.noiDung}
                                        </Cell>
                                        <Cell>
                                            {row.tenPhuong}   
                                        </Cell>
                                        <Cell>
                                            {row.tenHuyen}   
                                        </Cell>
                                        <Cell>
                                            {row.soTo}    
                                        </Cell>
                                        <Cell>
                                            {row.soThua}
                                        </Cell>
                                        <Cell>
                                            {row.chuSoHuu}
                                        </Cell>
                                        <Cell>
                                            {row.chiTiet}
                                        </Cell>
                                        <Cell > 
                                            <ButtonGroup disableElevation hidden={row?.id > 0 ? false : true}>
                                                <Button onClick={(e) => handleUpdate(row)}  style={{border:'none'}} startIcon={<EditIcon color="primary" />} size="small" />
                                                <Button   onClick={(e) => handleDelete(row?.id)}  style={{border:'none'}} startIcon={<CloseIcon color="secondary" />} size="small" />
                                            </ButtonGroup>
                                        </Cell>
                                    </Row>
                                )) :
                                <Row>
                                    <Cell colSpan={9} scope="row" align="center"> Không có dữ liệu</Cell>
                                </Row>
                        }
                    </TableBody>
                </Table>
                {props.dataList.length > 0 &&
                   <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                   <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined"
                       size="medium" color="primary" shape="rounded"
                       showFirstButton showLastButton onChange={props.onChangePage} />
                   <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                       <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                       <NativeSelect input={<CustomInput />} onChange={props.onChangeRowsPerPage} >
                           <option value={5}>5</option>
                           <option value={10}>10</option>
                           <option value={25}>20</option>
                       </NativeSelect>
                   </Box>
                   <Box component="span" paddingX="5px">Mẫu tin {props.page * props.rowsPerPage - props.rowsPerPage + 1} -{" "}
                       {props.count - (props.page - 1) * props.rowsPerPage < props.rowsPerPage ? props.count : props.page * props.rowsPerPage} /{" "}
                       {props.count}
                   </Box>
               </Box>
                }
            </TableContainer>
        </>
    )

}

export default TableKiemTraNganChans