import { Box, createStyles, Grid, InputBase, InputLabel, makeStyles, NativeSelect, TextField, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import AttachFileService from "../../../../api/Common/attachFileService";
import { DanhMucKhenThuongDTO } from "../../../../api/KhenThuong/danhMuc/danhMucKhenThuong";
import { AttachFileDTO } from "../../../../api/KhenThuong/models/attachFile";
import { SubKhenThuongDTO } from "../../../../api/KhenThuong/models/khenThuongDTO";
import DropZoneCustomKT from "../CustomDropzone/dropzoneCustomeKT";
import { DialogContent, DialogTitle } from "../dialog/dialogCommons";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

interface IModalProps {
    handleCloseDialog: () => void;
    openDialog: boolean;
    danhHieuThiDua: DanhMucKhenThuongDTO[];
    hinhThucKhenThuong: DanhMucKhenThuongDTO[];
    handleAddTableKT: (data: SubKhenThuongDTO) => void;
    handleSubmitEdit: (data: SubKhenThuongDTO, edit: SubKhenThuongDTO) => void;
    itemEdit?: SubKhenThuongDTO | null;
    urlAttachFileUpload: string;
}

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

const DialogKhenThuong: React.FC<IModalProps> = ({ openDialog, handleCloseDialog, danhHieuThiDua, hinhThucKhenThuong, handleAddTableKT, itemEdit, handleSubmitEdit, urlAttachFileUpload }) => {
    const classes = useStyles();


    const [attachFileServer, setAttachFileServer] = useState<AttachFileDTO[]>([]);

    const initialValues: SubKhenThuongDTO = {
        id: null,
        soQuyetDinh: "",
        coQuanBanHanh: "",
        ngayQuyetDinh: null,
    };

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (itemEdit) {
            formik.setValues({ ...itemEdit });
            formik.setFieldValue("ngayQuyetDinh", itemEdit.ngayQuyetDinh);
            if (itemEdit.attachFiles) {
                const files = itemEdit.attachFiles;
                if (files.length > 0) {
                    formik.setFieldValue("attachFiles", [...files]);
                    setAttachFileServer((prevState) => [...files]);
                }
            }
        }
    }, [itemEdit]);

    function handleSubmit(data: SubKhenThuongDTO) {
        if (itemEdit) {
            handleSubmitEdit(formik.values, itemEdit);
        }

        if (!itemEdit) {
            let newData = { ...data };
            newData.attachFiles = [...attachFileServer];
            handleAddTableKT(newData);
        }
        formik.resetForm();
        setAttachFileServer([]);
        handleCloseDialog();
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            formik.setFieldValue("attachFiles", [...attachFileServer, ...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: AttachFileDTO) {
        let newValues = attachFileServer.filter((file: AttachFileDTO) => file.fileId !== item.fileId);
        setAttachFileServer([...newValues]);
        formik.setFieldValue("attachFiles", [...newValues]);
    }

    async function handleDownloadFile(file: AttachFileDTO) {
        await new AttachFileService().downloadFile(file).then((res) => {
            let blob = new Blob([res.data], { type: res.data.type });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    function closeDialog() {
        formik.resetForm();
        setAttachFileServer([]);
        handleCloseDialog();
    }

    function handleReset() {
        formik.resetForm();
        setAttachFileServer(attachFileServer.map((item: AttachFileDTO) => ({ ...item, isDeleted: true })));
    }

    return (
        <Dialog fullWidth maxWidth="sm" aria-labelledby="customized-dialog-title" open={openDialog} onClose={closeDialog}>
            <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                Thêm mới
            </DialogTitle>
            <DialogContent dividers>
                <Box padding={0}>
                    <Grid container>
                        <Grid item xs={6} className="grid-item">
                            <InputLabel >Danh hiệu thi đua</InputLabel>
                            <NativeSelect
                                name="danhHieuThiDuaId"
                                value={formik.values.danhHieuThiDuaId}
                                onChange={(e) => {
                                    formik.setFieldValue("danhHieuThiDuaId", e.target.value);
                                    formik.setFieldValue("danhHieuThiDuaString", e.target.options[e.target.selectedIndex].text);
                                }}
                                input={<InputBase fullWidth  />}
                            >
                                <option value="">Chọn danh hiệu thi đua</option>
                                {danhHieuThiDua &&
                                    danhHieuThiDua.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <InputLabel >Hình thức khen thưởng</InputLabel>
                            <NativeSelect
                                name="hinhThucKhenThuongId"
                                value={formik.values.hinhThucKhenThuongId}
                                onChange={(e) => {
                                    formik.setFieldValue("hinhThucKhenThuongId", e.target.value);
                                    formik.setFieldValue("hinhThucKhenThuongString", e.target.options[e.target.selectedIndex].text);
                                }}
                                input={<InputBase fullWidth  />}
                            >
                                <option value="">Chọn hình thức khen thưởng</option>
                                {hinhThucKhenThuong &&
                                    hinhThucKhenThuong.map((item, i) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <TextField
                                fullWidth
                                label={"Số quyết định"}
                                name="soQuyetDinh"
                                value={formik.values.soQuyetDinh}
                                onChange={formik.handleChange}
                                InputProps={{ disableUnderline: true}}
                            />
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <InputLabel >Ngày quyết định</InputLabel>
                            <KeyboardDatePicker
                                value={formik.values.ngayQuyetDinh}
                                onChange={(date, _val) => {
                                    formik.setFieldValue("ngayQuyetDinh", date);
                                }}
                                autoOk={true}
                                fullWidth
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true}}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <InputLabel >Cơ quan ban hành</InputLabel>
                            <InputBase name="coQuanBanHanh" value={formik.values.coQuanBanHanh} onChange={formik.handleChange} fullWidth rows={2} multiline  />
                        </Grid>
                        <Grid item xs={12} container className="gridItem">
                            <DropZoneCustomKT
                                acceptFiles={`${acceptFiles}`}
                                attachFileServer={attachFileServer}
                                url={urlAttachFileUpload}
                                handleChangeStatus={handleChangeStatus}
                                handleDeleteFileServer={handleDeleteFileServer}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <Box marginTop="5px">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    className={classes.button}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                >
                                    Lưu
                                </Button>
                                <Button variant="contained" color="default" onClick={handleReset} disableElevation className={classes.button}>
                                    Xóa
                                </Button>
                                <Button variant="contained" color="default" disableElevation className={classes.button} onClick={closeDialog}>
                                    Hủy
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DialogKhenThuong;
