import AXIOS_INSTANCE from "../index";

export class CapMoiBLThuocLaService {

    getByMaHoSo = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-thuoc-la/by-ma-ho-so', requestBody);
    }

    insertOrUpdate = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-thuoc-la/add-or-update', requestBody);
    }

    autocompleteBySoGiayPhep = (soGiayPhep: string, maTrangThai: string, limit: number) => {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-thuoc-la/autocomplete-by-so-giay-phep', {
            soGiayPhep: soGiayPhep,
            maTrangThai: maTrangThai,
            limit: limit
        });
    }
    downloadFile(request: any) {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-thuoc-la/download', request, {
            responseType: 'blob',
        });
    }

    downloadFileCapSua(request: any) {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-thuoc-la/download-cap-sua', request, {
            responseType: 'blob',
        });
    }

    downloadFileCapLai(request: any) {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-thuoc-la/download-cap-lai', request, {
            responseType: 'blob',
        });
    }
    getGiayPhepBySoGP = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-thuoc-la/get-by-giay-phep', requestBody);
    }

}
