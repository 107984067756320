import React, { Dispatch, useEffect, useState } from 'react';
import { Box, FormHelperText, Grid, InputBase, InputLabel, Snackbar, TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import StringUtil from "../../../../utils/stringUtils";
import { TableChiNhanhVanPhongComponent } from "../Table/TableChiNhanhVanPhongComponent";
import {
    ChiNhanhVanPhongDaiDienDTO,
    DiaDiemToChucBanLeDTO,
    ThuongNhanBanBuonDTO
} from "../../../../../api/CPBLTL/model/TableBLThuocLa";
import { HoSoThuLyBLThuocLa } from "../../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import { TableDiaDiemToChucBanLeComponent } from "../Table/TableDiaDiemToChucBanLeComponent";
import { TableThuongNhanBanBuonComponent } from "../Table/TableThuongNhanBanBuonComponent";
import { CAP_MOI_BL_THUOC_LA_INIT } from "../../../../../api/CPBLTL/init/CapMoiBLThuocLaInit";
import DropZoneCustom from "../../../../commons/components/CustomDropzone/DropZoneCustom";
import { FileEntry } from "../../../../../api/Common/fileEntry";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { FileServerService } from "../../../../../api/Common/FileServerService";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import SearchIcon from "@material-ui/icons/Search";
import AXIOS_INSTANCE from '../../../../../api';
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../api/danhMuc/model/DanhMucDTO";
import { GIAY_PHEP_BL_THUOC_LA_INIT } from "../../../../../api/CPBLTL/init/GiayPhepBLThuocLaInit";
import { GiayPhepBLThuocLaDTO } from "../../../../../api/CPBLTL/model/GiayPhepBLThuocLaDTO";
import { LIST_TINHTRANG } from '../../../../Constants/ThuocLaConstants';
import { AlertMessage, CustomAlert } from '../../../../commons/components/alert';
import DateUtil from '../../../../utils/dateUtils';
import { useDispatch } from 'react-redux';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

interface DauKyBLThuocLaNoiDungComponentProps {
    data: HoSoThuLyBLThuocLa;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
}

const DauKyBLThuocLaNoiDungComponent = (props: DauKyBLThuocLaNoiDungComponentProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = React.useState({ ...CAP_MOI_BL_THUOC_LA_INIT } as HoSoThuLyBLThuocLa);
    const { onUpdateData, submit } = props;
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const [dataQuanHuyen, setDataQuanHuyen] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataXaPhuong, setDataXaPhuong] = useState<DanhMucDiaChiDTO[]>([]);
    const fileServerService = new FileServerService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
        async function getDm() {
            await new DanhMucService().getDanhMucDiaChi(String(92)).then((resp) => {
                setDataQuanHuyen(resp);
            });
        }
        getDm();
    }, [props.data, props.onUpdateData]);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    async function handleChangeQuanHuyen(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText, maPhuongXa: '', tenPhuongXa: '' };
        onUpdateData("data", dataNew);
    }

    async function handleChangePhuongXa(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChangeDuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function onBlurPhuongXa() {
        if (data?.maQuanHuyen) {
            await new DanhMucService().getDanhMucDiaChi(data?.maQuanHuyen).then((resp) => {
                setDataXaPhuong(resp);
            });
        }
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    };

    const setChiNhanhVanPhongDaiDiens = (memData: ChiNhanhVanPhongDaiDienDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data?.chiNhanhVanPhongDaiDiens];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            chiNhanhVanPhongDaiDiens: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const setThuongNhanBanBuons = (memData: ThuongNhanBanBuonDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data?.thuongNhanBanBuons];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            thuongNhanBanBuons: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);

    }

    const setDiaDiemToChucBanLes = (memData: DiaDiemToChucBanLeDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data?.diaDiemToChucBanLes];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            diaDiemToChucBanLes: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);

    }

    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    };
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const onSearchDKKD = (maSoDoanhNghiep: string) => {
        if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
            showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoDKKD({ maSoDoanhNghiep: maSoDoanhNghiep }).then(res => {
                let dataNew = res?.data.data;
                if (dataNew !== null && dataNew !== undefined) {
                    if (dataNew.error_code === 'SUCCESSFUL' && dataNew.data && dataNew.data != null) {
                        const tested = DateUtil.stringDDMMYYYtoDate(dataNew.data.ngayThanhLap.split(' ')[0]);
                        let dataDKKD = {
                            ...data,
                            dienThoai: dataNew.data.dienThoai,
                            tenDuong: dataNew.data.soNha,
                            fax: dataNew.data.fax,
                            ngayDangKyLanDau: tested !== null ? tested.getTime() : 0,
                        };
                        onUpdateData("data", dataDKKD);
                
                    } else {
                        setAlertMessage({ open: true, content: "Giấy CNĐKKD/DN/HTX/HKD không tồn tại trong dữ liệu", severity: 'warning' });
                    }
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
    };

    const handleChangeNumber =  (e: any) => {
        let value =  e.target.value;
        onUpdateData(e.target.name, StringUtil.checkInputNumber(value) || value === "" ? value :  "");
    }

    return (
        <>
            {
                <Box padding={0}>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <Box padding={0}>
                        <Grid container>
                            
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Số giấy phép </InputLabel>
                                <InputBase fullWidth
                                    value={data.soGiayPhep} name="soGiayPhep"
                                    error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                    onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                    <FormHelperText className="-error">Vui lòng nhập số giấy phép</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Ngày cấp </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        error={submit && !(data.ngayCapPhep !== 0 && data.ngayCapPhep !== null && data.ngayCapPhep !== undefined)}
                                        value={(data.ngayCapPhep !== undefined && data.ngayCapPhep > 0) ? new Date(data.ngayCapPhep) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}

                                    />
                                    {
                                        submit && !(data.ngayCapPhep !== 0 && data.ngayCapPhep !== null && data.ngayCapPhep !== undefined) &&
                                        <FormHelperText className="-error">Vui lòng nhập ngày cấp</FormHelperText>
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.ngayHetHanGP !== undefined && data.ngayHetHanGP > 0) ? new Date(data.ngayHetHanGP) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Giấy CNĐKKD/DN/HTX/HKD </InputLabel>
                                <InputBase style={{ width: '85%' }}
                                    error={submit && StringUtil.isNullOrEmty(data?.soCNDKKD)}
                                    value={data?.soCNDKKD} name="soCNDKKD" onChange={handleChange} />
                                <SearchIcon fontSize="default" style={{ cursor: 'pointer', marginTop: '0px', marginLeft: '3px' }}
                                    onClick={(e) => onSearchDKKD(data?.soCNDKKD)}
                                />

                                {
                                    submit && StringUtil.isNullOrEmty(data?.soCNDKKD) &&
                                    <FormHelperText className="-error">Vui lòng nhập số giấy CNĐKKD/DN/HTX/HKD</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel required>Tên thương nhân </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.tenThuongNhan)}
                                    value={data?.tenThuongNhan} name="tenThuongNhan" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.tenThuongNhan) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên thương nhân</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete id="maHuyen"
                                    options={dataQuanHuyen}
                                    value={{ code: data?.maQuanHuyen ? data?.maQuanHuyen : "", name: data?.tenQuanHuyen ? data?.tenQuanHuyen : "" }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    onChange={(event: any, newValue: any) => handleChangeQuanHuyen(newValue, 'maQuanHuyen', 'tenQuanHuyen')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maQuanHuyen)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maQuanHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={dataXaPhuong}
                                    onFocus={onBlurPhuongXa}
                                    value={{ code: data?.maPhuongXa ? data?.maPhuongXa : "", name: data?.tenPhuongXa ? data?.tenPhuongXa : "" }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    onChange={(event: any, newValue: any) => handleChangePhuongXa(newValue, 'maPhuongXa', 'tenPhuongXa')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maPhuongXa)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maPhuongXa) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Đường </InputLabel>
                                <InputBase fullWidth value={data.tenDuong} name="tenDuong" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số nhà</InputLabel>
                                <InputBase fullWidth
                                    value={data?.soNha} name="soNha" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Điện thoại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.dienThoai} name="dienThoai" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Fax </InputLabel>
                                <InputBase fullWidth
                                    value={data?.fax} name="fax" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Nơi cấp Giấy CNĐKDN/HKD</InputLabel>
                                <InputBase fullWidth
                                    value={data?.noiCapCNDKKD} name="noiCapCNDKKD" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Cấp đăng ký lần đầu ngày</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayDangKyLanDau !== undefined && data?.ngayDangKyLanDau > 0) ? new Date(data?.ngayDangKyLanDau) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayDangKyLanDau')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Đăng ký thay đổi lần thứ </InputLabel>
                                <InputBase fullWidth
                                    value={data?.lanThayDoi} name="lanThayDoi" onChange={handleChangeNumber}  />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày thay đổi</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayThayDoi !== undefined && data?.ngayThayDoi > 0) ? new Date(data?.ngayThayDoi) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayThayDoi')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Chi nhánh, văn phòng đại diện hoặc địa điểm kinh doanh</InputLabel>
                                <TableChiNhanhVanPhongComponent dataList={data?.chiNhanhVanPhongDaiDiens}
                                    onSetDatas={setChiNhanhVanPhongDaiDiens}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Được phép mua sản phẩm thuốc lá của các thương nhân bán buôn sản phẩm thuốc lá có tên sau</InputLabel>
                                <TableThuongNhanBanBuonComponent dataList={data?.thuongNhanBanBuons}
                                    onSetDatas={setThuongNhanBanBuons}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Được phép tổ chức bán lẻ sản phẩm thuốc lá tại các địa điểm</InputLabel>
                                <TableDiaDiemToChucBanLeComponent dataList={data?.diaDiemToChucBanLes}
                                    onSetDatas={setDiaDiemToChucBanLes}
                                />
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={`${acceptFiles}`}
                                    attachFileServer={attachFileServer}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/thuocla"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
        </>
    )
};

export default DauKyBLThuocLaNoiDungComponent;
