import { useState, useEffect } from "react";
import { HoSoCuaToiMgtService } from "../../api/HoSoCuaToi/HoSoCuaToiMgtService";
import { UserData } from "../../api/userMgt/userManagementService";

export function useGetThongTinChuyenNganh(maHoSo: string) {
    const [chiTiet, setChiTiet] = useState<any>([]);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    let C_USER_DATA_DE = {} as UserData;
    if(localStorage.getItem("C_USER_DATA") != null){
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    useEffect(() => {
        (async function getDuLieu() {
            if (maHoSo !== "") {
                await hoSoCuaToiMgtService.chiTiet(C_USER_DATA_DE.maCoQuan, maHoSo).then((p) => {
                     if (p.data) {
                         if(p.data.data.thongTinChuyenNganh && p.data.data.thongTinChuyenNganh.length>0){
                             const result = [...p.data.data.thongTinChuyenNganh[0].data]
                             setChiTiet(result);
                         }
         
                     }
                 });
             }
        })();
    }, [maHoSo]);
    return chiTiet;
}