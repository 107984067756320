import React, { Dispatch, useEffect, useState } from 'react';
import {Button,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputBase,
    InputLabel,
    Snackbar
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import StringUtil from "../../../../utils/stringUtils";
import { TableChiNhanhVanPhongComponent } from "../Table/TableChiNhanhVanPhongComponent";
import {
    ChiNhanhVanPhongDaiDienDTO,
    DiaDiemToChucBanLeDTO,
    ThuongNhanBanBuonDTO
} from "../../../../../api/CPBLTL/model/TableBLThuocLa";
import { HoSoThuLyBLThuocLa } from "../../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import { TableDiaDiemToChucBanLeComponent } from "../Table/TableDiaDiemToChucBanLeComponent";
import { TableThuongNhanBanBuonComponent } from "../Table/TableThuongNhanBanBuonComponent";
import { CAP_MOI_BL_THUOC_LA_INIT } from "../../../../../api/CPBLTL/init/CapMoiBLThuocLaInit";
import DropZoneCustom from "../../../../commons/components/CustomDropzone/DropZoneCustom";
import { FileEntry } from "../../../../../api/Common/fileEntry";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { FileServerService } from "../../../../../api/Common/FileServerService";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import SearchIcon from '@material-ui/icons/Search';
import AXIOS_INSTANCE from '../../../../../api';
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../api/danhMuc/model/DanhMucDTO";
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import DateUtil from '../../../../utils/dateUtils';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { useDispatch } from "react-redux";
import { LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from '../../../../Constants/ThuocLaConstants';
import { GiayPhepRequest } from "../../../../../api/QuanTri/Model/MotCuaModel";
import { LoaiGiayPhepEnum } from "../../../../../api/loaiGiayPhep/loaiGiayPhepEnum";
import LayKetQuaGiayPhepService from "../../../../../api/QuanTri/LayKetQuaGiayPhepService";
import { Warning } from '@material-ui/icons';
import { KetQuaGiayPhepDTO } from '../../../../../api/Common/KetQuaGiayPhepDTO';
const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

interface CapMoiSXRuouNoiDungComponentProps {
    data: HoSoThuLyBLThuocLa;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
    showLayKetQua: boolean;
}

const CapMoiBLThuocLaNoiDungComponent = (props: CapMoiSXRuouNoiDungComponentProps) => {
    const [data, setData] = React.useState({ ...CAP_MOI_BL_THUOC_LA_INIT } as HoSoThuLyBLThuocLa);
    const { onUpdateData, submit, showLayKetQua } = props;
    const [showCapSo, setShowCapSo] = useState<boolean>(false);
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const [dataQuanHuyen, setDataQuanHuyen] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataXaPhuong, setDataXaPhuong] = useState<DanhMucDiaChiDTO[]>([]);
    const fileServerService = new FileServerService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    useEffect(() => {
        async function getDm() {
            await new DanhMucService().getDanhMucDiaChi(String(92)).then((resp) => {
                setDataQuanHuyen(resp);
            });
        }
        getDm();
    }, []);
    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
        setShowCapSo(props.data?.donViCapPhep)
    }, [props.data, props.onUpdateData]);


    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        console.log("value ",value);
        console.log("e",e.target.name);
        onUpdateData(e.target.name, value);
    }
    const showMessageAlert = (content: string, isSuccess: boolean = true, isWarning?: boolean) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : isWarning? "warning":"error" });
    };

    async function handleChangeQuanHuyen(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText, maPhuongXa: '', tenPhuongXa: '' };
        onUpdateData("data", dataNew);
    }

    async function handleChangePhuongXa(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function handleChangeDuong(value: any, nameValue: string, nameText: string) {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    async function onBlurPhuongXa() {
        if (data?.maQuanHuyen) {
            await new DanhMucService().getDanhMucDiaChi(data?.maQuanHuyen).then((resp) => {
                setDataXaPhuong(resp);
            });
        }
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }

    const setChiNhanhVanPhongDaiDiens = (memData: ChiNhanhVanPhongDaiDienDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data?.chiNhanhVanPhongDaiDiens];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            chiNhanhVanPhongDaiDiens: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const setThuongNhanBanBuons = (memData: ThuongNhanBanBuonDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data?.thuongNhanBanBuons];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            thuongNhanBanBuons: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);

    }

    const setDiaDiemToChucBanLes = (memData: DiaDiemToChucBanLeDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data?.diaDiemToChucBanLes];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            diaDiemToChucBanLes: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);

    }

    const handleChangeDonViCapPhep = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        if (value) {
            let dataNew: HoSoThuLyBLThuocLa = {
                ...data,
                soGiayPhep: data.soGiayPhep,
                ngayCapPhep: data.ngayCapPhep,
                ngayHetHanGP: data.ngayHetHanGP,
                [e.target.name]: value
            }
            onUpdateData("data", dataNew);
        } else {
            let dataNew: HoSoThuLyBLThuocLa = {
                ...data,
                soGiayPhep: "",
                ngayCapPhep: 0,
                ngayHetHanGP: 0,
                [e.target.name]: value
            }
            onUpdateData("data", dataNew);
        }

        setShowCapSo(value);

    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const setDataFiles = (memData: FileEntry[], addMore?:boolean) => {
        if (addMore){
            let mems = [...data?.files];
            mems = mems.concat(memData);
            let dataNew = {
                ...data,
                files: mems
            };
            onUpdateData("data", dataNew);
        }else{
            let dataNew = {
                ...data,
                files: memData,
            };
            onUpdateData("data", dataNew);
        }
    }


    const onSearchDKKD = (maSoDoanhNghiep: string) => {
        if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
            showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoDKKD({ maSoDoanhNghiep: maSoDoanhNghiep }).then(res => {
                let dataNew = res?.data.data;
                console.log("data", dataNew);
                
                if (dataNew !== null && dataNew !== undefined) {
                    if (dataNew.error_code === 'SUCCESSFUL' && dataNew.data && dataNew.data != null) {
                        let da = {
                            ...data,
                            dienThoai: dataNew.data.dienThoai,
                            soNha: dataNew.data.soNha,
                            fax: dataNew.data.fax,
                            ngayDangKyLanDau: DateUtil.stringDDMMYYYHHMMSStoDate(dataNew?.data?.ngayThanhLap)?.getTime(),
                        };
                        onUpdateData("data", da);
                    } else {
                        setAlertMessage({ open: true, content: "Giấy CNĐKKD/DN/HTX/HKD không tồn tại trong dữ liệu", severity: 'warning' });
                    }
                }
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
    };

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let mems = [...data.files];
        let memTrashs = data.fileTrash ? data.fileTrash : [];
        if (item?.fileParentId > 0) {
            memTrashs.push(item);
        }
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            files: mems,
            fileTrash: memTrashs
        };
        setAttachFileServer(mems);
        onUpdateData("data", dataNew);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    
    const handleChangeNumber =  (e: any) => {
        let value =  e.target.value;
        onUpdateData(e.target.name, StringUtil.checkInputNumber(value) || value === "" ? value :  "");
    }

    const layKetQuaSerVice = new LayKetQuaGiayPhepService();    
    const handleDongBoGiayPhep =  async () => {
        dispatchLoading(showLoading());
        await layKetQuaSerVice.layKetQuaByMaHoSo(data.maHoSo) .then((res) => {  
            if (res != null && res?.data?.error_code == "SUCCESSFUL" && res?.data?.data!==null) {
                    if (res?.data?.data?.files && res?.data?.data?.files.length>0){
                        const files: FileEntry[] = [];
                        for (let i = 0 ; i<res?.data?.data?.files.length;i++){
                            const file : FileEntry = {
                                fileEntryId:0,
                                folderId: 0,
                                userId: "",
                                userName: "",
                                name: res.data.data.files[i].fileName,
                                createdDate: 0,
                                extension: "",
                                mimeType: "",
                                orginName: "",
                                description: "",
                                version: "",
                                docId: "",
                                fileParentId: 0,
                                motCua: true,
                                url: res.data.data.files[i].url,
                            }
                           files.push(file);
                        }
                        data.files= files;
                    }
                    data.soGiayPhep = res.data.data.soGiayPhep ? res.data.data.soGiayPhep : null;
                    data.ngayCapPhep = res.data.data.ngayCap ? res.data.data.ngayCap : null;
                    if (res.data.data.ngayHetHan!==null){
                        data.ngayHetHanGP = res.data.data.ngayHetHan ? res.data.data.ngayHetHan : null;
                    }
                    const ketQuaGiayPhep: KetQuaGiayPhepDTO={
                        soGiayPhep: res.data.data.soGiayPhep,
                        ngayHetHan : res.data.data.ngayHetHan,
                        files: res.data.data.files,
                        ngayCap: res.data.data.ngayCap,
                    }
                    data.ketQuaGiayPhep = ketQuaGiayPhep;
                    showMessageAlert(res.data.error_message);
                } else{
                    showMessageAlert(res.data.error_message, false, true);
                }}
            
        ).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    return (
        <>
            {
                <Box padding={0}>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={3} className="grid-item">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend"></FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value={data?.donViCapPhep}
                                            onChange={handleChangeDonViCapPhep}
                                            name="donViCapPhep"
                                            control={<Checkbox color="primary" checked={data?.donViCapPhep} />}
                                            label="Đơn vị cấp số giấy phép"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            {showLayKetQua && showCapSo   ?
                                        <Grid item xs={9} className="grid-item" >
                                                <Box className="button-group" marginTop="15px" >
                                                    <Button variant="outlined" disableElevation   onClick={
                                                        () => handleDongBoGiayPhep()
                                                        }>
                                                        Lấy kết quả
                                                    </Button>
                                                </Box>
                                        </Grid> :
                                        <Grid item xs={9} className="grid-item" >

                                        </Grid> 
                                    } 
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Giấy CNĐKKD/DN/HTX/HKD </InputLabel>
                                <InputBase style={{ width: '85%' }}
                                    error={submit && StringUtil.isNullOrEmty(data?.soCNDKKD)}
                                    value={data?.soCNDKKD} name="soCNDKKD" onChange={handleChange} />
                                <SearchIcon fontSize="default" style={{ cursor: 'pointer', marginTop: '0px', marginLeft: '3px' }} onClick={(e) => onSearchDKKD(data?.soCNDKKD)} />

                                {
                                    submit && StringUtil.isNullOrEmty(data?.soCNDKKD) &&
                                    <FormHelperText className="-error">Vui lòng nhập số giấy CNĐKKD/DN/HTX/HKD</FormHelperText>
                                }
                                {/*<Button startIcon={<SearchIcon></SearchIcon>}></Button>*/}
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                                <InputLabel required>Số giấy phép </InputLabel>
                                <InputBase fullWidth
                                    value={data.soGiayPhep} name="soGiayPhep"
                                    error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                    onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                    <FormHelperText className="-error">Vui lòng nhập số giấy phép</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                                <InputLabel required>Ngày cấp </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        error={submit && data.ngayCapPhep == 0}
                                        showTodayButton={true}
                                        value={(data.ngayCapPhep !== undefined && data.ngayCapPhep !== 0 && data.ngayCapPhep != null) ? new Date(data.ngayCapPhep) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}

                                    />
                                    {
                                        submit && data.ngayCapPhep == 0 &&
                                        <FormHelperText className="-error">Vui lòng nhập ngày cấp phép</FormHelperText>
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                                <InputLabel>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.ngayHetHanGP !== undefined && data.ngayHetHanGP > 0) ? new Date(data.ngayHetHanGP) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel required>Tên thương nhân </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.tenThuongNhan)}
                                    value={data?.tenThuongNhan} name="tenThuongNhan" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.tenThuongNhan) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên thương nhân</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete id="maHuyen"
                                    options={dataQuanHuyen}
                                    value={{ code: data?.maQuanHuyen ? data?.maQuanHuyen : "", name: data?.tenQuanHuyen ? data?.tenQuanHuyen : "" }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    onChange={(event: any, newValue: any) => handleChangeQuanHuyen(newValue, 'maQuanHuyen', 'tenQuanHuyen')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maQuanHuyen)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maQuanHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={dataXaPhuong}
                                    onFocus={onBlurPhuongXa}
                                    value={{ code: data?.maPhuongXa ? data?.maPhuongXa : "", name: data?.tenPhuongXa ? data?.tenPhuongXa : "" }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    onChange={(event: any, newValue: any) => handleChangePhuongXa(newValue, 'maPhuongXa', 'tenPhuongXa')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maPhuongXa)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maPhuongXa) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Đường </InputLabel>
                                <InputBase fullWidth value={data.tenDuong} name="tenDuong" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số nhà</InputLabel>
                                <InputBase fullWidth
                                    value={data?.soNha} name="soNha" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Điện thoại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.dienThoai} name="dienThoai" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Fax </InputLabel>
                                <InputBase fullWidth
                                    value={data?.fax} name="fax" onChange={handleChange} />
                            </Grid>
                            
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Nơi cấp Giấy CNĐKDN/HKD</InputLabel>
                                <InputBase fullWidth
                                    value={data?.noiCapCNDKKD} name="noiCapCNDKKD" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Cấp đăng ký lần đầu ngày</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        name="ngayDangKyLanDau"
                                        value={(data?.ngayDangKyLanDau !== undefined && data?.ngayDangKyLanDau > 0) ? new Date(data?.ngayDangKyLanDau) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayDangKyLanDau')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Đăng ký thay đổi lần thứ </InputLabel>
                                <InputBase fullWidth
                                    value={data?.lanThayDoi} name="lanThayDoi" onChange={handleChangeNumber} type="number"/>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày thay đổi</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayThayDoi !== undefined && data?.ngayThayDoi > 0) ? new Date(data?.ngayThayDoi) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayThayDoi')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Địa điểm kinh doanh</InputLabel>
                                <InputBase fullWidth
                                    value={data?.diaDiemKinhDoanh} name="diaDiemKinhDoanh" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Trách nhiệm thực hiện</InputLabel>
                                <InputBase fullWidth
                                    value={data?.trachNhiemThucHien} name="trachNhiemThucHien" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Nơi nhận</InputLabel>
                                <InputBase fullWidth
                                    value={data?.noiNhan} name="noiNhan" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Chi nhánh, văn phòng đại diện hoặc địa điểm kinh doanh</InputLabel>
                                <TableChiNhanhVanPhongComponent dataList={data?.chiNhanhVanPhongDaiDiens}
                                    onSetDatas={setChiNhanhVanPhongDaiDiens}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Được phép mua sản phẩm thuốc lá của các thương nhân bán buôn sản phẩm thuốc lá có tên sau</InputLabel>
                                <TableThuongNhanBanBuonComponent dataList={data?.thuongNhanBanBuons}
                                    onSetDatas={setThuongNhanBanBuons}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Được phép tổ chức bán lẻ sản phẩm thuốc lá tại các địa điểm</InputLabel>
                                <TableDiaDiemToChucBanLeComponent dataList={data?.diaDiemToChucBanLes}
                                    onSetDatas={setDiaDiemToChucBanLes}
                                />
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={`${acceptFiles}`}
                                    attachFileServer={attachFileServer}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/thuocla"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
        </>
    )
};

export default CapMoiBLThuocLaNoiDungComponent;
