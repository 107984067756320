import { Button, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { BieuMauAttachFileDTO } from '../../../../api/QuanTri/Model/danhMucBieuMau';
import { ConfirmationDialogRaw } from '../../../commons/components/dialog/confirmDialog';
import InBieuMauATTPButton from './InBieuMauATTPButton';
// import InBieuMauATTPButton from './InBieuMauATTPButton';
interface ButtonATTPProps {
    onSubmit: () => void;
    resetForm: () => void;
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    dauKy? : boolean;
}
const ButtonATTPComponent = (props: ButtonATTPProps) => {
    const {dauKy, resetForm, onSubmit, onDownloadFileBieuMau } = props;
    const history = useHistory();
    const [openResetData , setOpenResetData] = useState(false);
    const handleClickXoa = () => {
        setOpenResetData(!openResetData);
    }
    const handleConfirm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetForm();
        }
        setOpenResetData(!openResetData);
    };
    return (
        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
            <Button type="button" variant="contained" color="primary" className="btn-group-thuly" onClick={onSubmit}>Lưu
                            </Button>&nbsp;&nbsp;
            {
                !dauKy && (
                    <InBieuMauATTPButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
                )
            }
            <Button  className="btn-group-thuly" variant="contained" onClick={() => handleClickXoa()} color="secondary" disableElevation> Xóa</Button>
            <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openResetData}
                title="Xóa dữ liệu"
                content="Bạn có đồng ý xóa?" onClose={handleConfirm}
            />
            <Button className="btn-group-thuly" onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation>Trở về</Button>
        </Grid>
    )
}
export default ButtonATTPComponent;