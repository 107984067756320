import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Box, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { LichSuGiayPhepRuouDTO } from '../../../../../api/CPBLR/model/LichSuGiayPhepRuouDTO';
import moment from "moment";
import { QuanLyDKRuouService } from '../../../../../api/CPBLR/QuanLyDKRuouService';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { Cell, Row } from '../../../../commons/components/table/tableCommons';
import { TepDinhKemPopper } from '../../../../commons/components/hoso/tepTin/TepDinhKemPopper';

interface ChiTietLichSuGPRuouProps {
    giayPhepId: number
}
const ChiTietLichSuGPRuou = (props: ChiTietLichSuGPRuouProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<LichSuGiayPhepRuouDTO[]>(new Array<LichSuGiayPhepRuouDTO>());
    const quanLyDKRuouService = new QuanLyDKRuouService();
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, []);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        quanLyDKRuouService.getByGiayPhepId(props.giayPhepId, pageNumberNew, pageSizeNew).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                if (res?.data?.data.data != null) {
                    setPataPagi(res?.data?.data.data);
                    setTotal(res?.data?.data.total);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }
    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="25%">Loại biến động</Cell>
                        <Cell component="th" scope="row" align="center" width="20%">Ngày biến động</Cell>
                        <Cell component="th" scope="row" align="center" width="20%">Số lần</Cell>
                        <Cell component="th" scope="row" align="center" >Nội dung</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Tệp tin
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataPagi?.length > 0 ? dataPagi?.map((item, index) => {
                        return (
                            <Row key={item.id}>
                                <Cell scope="row" align="center">
                                    {(pageNumber - 1) * pageSize + index + 1}
                                </Cell>
                                <Cell align="left">{item.tenBienDong}</Cell>
                                <Cell align="center">{(item.ngayBienDong !== 0 && item.ngayBienDong !== null) ? moment(item.ngayBienDong).format("DD/MM/YYYY") : ''}</Cell>
                                <Cell align="center">{item.soLan}</Cell>
                                <Cell align="left">{item.noiDung}</Cell>
                                <Cell>
                                    <TepDinhKemPopper data={item.files} />
                                </Cell>
                            </Row>
                        )
                    }) :
                        <Row>
                            <Cell colSpan={13} scope="row" align="center"> Không có dữ liệu</Cell>
                        </Row>
                    }
                </TableBody>
            </Table>

            {dataPagi.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={Math.ceil(total / pageSize)} defaultPage={1} siblingCount={1} variant="outlined" page={pageNumber}
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={handleChangePageSize} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {((pageNumber - 1) * pageSize) + 1} - {pageSize * pageNumber > total ? total : (pageSize * pageNumber)} / {total}</Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
export default ChiTietLichSuGPRuou;