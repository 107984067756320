import MomentUtils from "@date-io/moment";
import { Button,Box, FormHelperText, Grid, InputAdornment, InputBase, InputLabel, TextField, Snackbar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { loadModules } from "esri-loader";
import moment from "moment";
import { useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { FileEntry } from "../../../../../api/Common/fileEntry";
import { FileServerService } from "../../../../../api/Common/FileServerService";
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../api/danhMuc/model/DanhMucDTO";
import { BieuMauAttachFileDTO } from "../../../../../api/QuanTri/Model/danhMucBieuMau";
import { HoSoThuLyATTPDTO_INIT } from "../../../../../api/VSATTP/Init/VSATTPModel_INIT";
import { HoSoThuLyATTPDTO } from "../../../../../api/VSATTP/Model/VSATTPModel";
import { VSATTPService } from "../../../../../api/VSATTP/Services/VSATTPService";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { API_KEY_MAP } from "../../../../Constants/const";
import StringUtil from "../../../../utils/stringUtils";
import ButtonATTPComponent from "../../Action/ButtonATTPComponent";
import { Alert } from "@material-ui/lab";
import DateUtil from "../../../../utils/dateUtils";
import { AlertMessage } from "../../../../commons/components/alert";
import { getObject } from "../../../../../helpers/localStorage";
import DropZoneCustom from "../../../../commons/components/CustomDropzone/DropZoneCustom";
import { ACCEPT_FILES } from "../../../../Constants/constants";
import api from "../../../../../api";
import LayKetQuaGiayPhepService from "../../../../../api/QuanTri/LayKetQuaGiayPhepService";
import { KetQuaGiayPhepDTO } from "../../../../../api/Common/KetQuaGiayPhepDTO";


interface VSATTPProp {
    vsattpData: HoSoThuLyATTPDTO;
    submit: boolean;
    resetForm: () => void;
    handleSubmit: (success: boolean, mess: string) => void;
    refresh: () => void;
}
const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

export const DauKyVSATTPNoiDungComponent = (props: VSATTPProp) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const [vsattpData, setData] = useState<HoSoThuLyATTPDTO>({
        ...HoSoThuLyATTPDTO_INIT,
        maHuyenThiTpDSXKD: C_USER_DATA.maQuanHuyen,
        tenHuyenThiTpDSXKD: C_USER_DATA.tenQuanHuyen,
    });
    const [submit, setSubmit] = useState(false);
    const vsattpService = new VSATTPService();
    const fileServerService = new FileServerService();
    const [diaChiKinhDo, setDiaChiKinhDo] = useState<Number>();
    const [diaChiViDo, setDiaChiViDo] = useState<Number>();
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [tinhs, setTinhs] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongXaTTDSXKDs, setPhuongXaTTDSXKDs] = useState<DanhMucDiaChiDTO[]>([]);
    const { handleSubmit, resetForm, refresh } = props;
    const [showAlert, setShowAlert] = useState(false);
    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false);

    const [message, setMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);

    useEffect(() => {
        setData(props.vsattpData);
        // loadQuans("92");
        loadTinhs();
    }, [props.vsattpData]);

    // const initData = () => {

    // }
    const handleChangeDatePickerNgayCap = (date: any, value: any, filedName: string) => {
        const oneNextYear = date?.toDate();
        oneNextYear.setFullYear(oneNextYear.getFullYear() + 3);
        let dataNew = { ...vsattpData, ngayCapPhep: date?.toDate()?.getTime(), ngayHetHan: oneNextYear.getTime() };
        setData(dataNew);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...vsattpData, [filedName]: date?.toDate()?.getTime() };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.name !== "" ? value?.name : "";
        let dataNew = { ...vsattpData, [nameValue]: value?.code, [nameText]: valueText, maHuyenThiTp: "", tenHuyenThiTp: "" };
        setData(dataNew);
    };

   
  
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let name = e.target.name;
        let value = isCheckbox ? e.target.checked : e.target.value;
        let dataNew = { ...vsattpData, [name]: value };
        setData(dataNew);
    };

    const handleChangeNumber = (e: any) => {
        let value = e.target.value;
        let name = e.target.name;
        const regExpNumber = new RegExp("^[0-9]*$");
        if (regExpNumber.test(value)) {
            let dataNew = { ...vsattpData, [name]: value };
            setData(dataNew);
        }
    };

    const onSubmit = () => {
        setSubmit(true);
        if (
            StringUtil.isNullOrEmty(vsattpData.soGiayPhep) ||
            StringUtil.isNullOrEmty(vsattpData.tenChuCoSo) ||
            StringUtil.isNullOrEmty(vsattpData.maPhuongXaTT) ||
            StringUtil.isNullOrEmty(vsattpData.maPhuongXaTTDSXKD)
        ) {
            return;
        }
        if (vsattpData.ngayCapPhep > StringUtil.dateSecond()) {
            setMessage("Ngày cấp không được lớn hơn ngày hiện tại");
            setSuccess(false);
            setShowAlert(true);
            return;
        }
        if (!vsattpData.ngayCapPhep || vsattpData.ngayCapPhep === undefined || vsattpData.ngayCapPhep === 0) {
            return;
        }
        if (!vsattpData.ngayHetHan || vsattpData.ngayHetHan === undefined || vsattpData.ngayHetHan === 0) {
            return;
        }
        if (vsattpData.ngayCapPhep > vsattpData.ngayHetHan) {
            return;
        }
        setSubmit(false);
        dispatchLoading(showLoading());
        let dataInfo = { ...vsattpData, diaChiKinhDo, diaChiViDo };

        vsattpService
            .insertOrUpdateGiayPhepVSTTP({...dataInfo})
            .then((res) => {
                if (res?.data?.error_code == "SUCCESSFUL" && res?.data?.data !== null && res?.data?.data !== undefined) {
                    refresh();
                    handleSubmit(true, "Yêu cầu thực hiện thành công");
                } else {
                    handleSubmit(false, res.data.error_message);
                }
            })
            .catch((error) => {
                handleSubmit(false, "Yêu cầu thực hiện không thành công");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dispatchLoading(showLoading());
        vsattpService
            .downloadFileGiayPhep({ templateId: bieuMau.fileId, maHoSo: vsattpData.maHoSo })
            .then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
            })
            .catch((error) => {
                handleSubmit(false, "Yêu cầu thực hiện không thành công");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        if (!vsattpData.files){
            vsattpData.files = [];
        }
        let mems = [...vsattpData.files];
        mems = mems.concat(memData);
        setData({ ...vsattpData, files: mems });
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...vsattpData.files];
        mems.splice(index, 1);
        setData({ ...vsattpData, files: mems });
    };
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && StringUtil.isNotEmpty(file.url)){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const [locater, setLocater] = useState<any>();

    useEffect(() => {
        loadModules(["esri/config", "esri/tasks/Locator"]).then(([esriConfig, Locator]) => {
            esriConfig.apiKey = API_KEY_MAP;
            const locater = new Locator({
                url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
            });
            setLocater(locater);
        });
    }, []);

    useEffect(() => {
        if (locater) {
            const address = {
                SingleLine: StringUtil.diaChiToString({
                    diaChiTinhName: "Thành phố Cần Thơ",
                    diaChiHuyenName: vsattpData.tenHuyenThiTpDSXKD,
                    diaChiXaName: vsattpData.tenPhuongXaTTDSXKD,
                    duong: vsattpData.tenDuong,
                    soNha: vsattpData.soNha,
                }),
            };

            const params = {
                address: address,
                forStorage: false,
            };
            locater.addressToLocations(params).then((response: any) => {
                if (response && response.length > 0) {
                    const { location } = response[0];
                    const { longitude, latitude } = location;
                    setDiaChiKinhDo(longitude);
                    setDiaChiViDo(latitude);
                }
            });
        }
    }, [locater, vsattpData]);

    const loadQuans = (maThanhPho: string) => {
        if (!StringUtil.isEmty(maThanhPho)) {
            danhMucService
                .getDanhMucDiaChi(maThanhPho)
                .then((res) => {
                    if (res && res.length > 0) {
                        setQuans(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const loadTinhs = async () => {
        await danhMucService
            .getAllTinh()
            .then((res) => {
                if (res && res.length > 0) {
                    setTinhs(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };

    const loadPhuongs = (maHuyen: string) => {
        if (!StringUtil.isEmty(maHuyen)) {
            danhMucService
                .getDanhMucDiaChi(maHuyen)
                .then((res) => {
                    if (res && res.length > 0) {
                        setPhuongs(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const loadPhuongXaTTDSXKDs = () => {
        if (StringUtil.isNotEmpty(vsattpData?.maHuyenThiTpDSXKD)) {
            danhMucService
                .getDanhMucDiaChi(vsattpData?.maHuyenThiTpDSXKD)
                .then((res) => {
                    if (res && res.length > 0) {
                        setPhuongXaTTDSXKDs(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...vsattpData, [nameValue]: value?.code, [nameText]: valueText, maHuyenThiTp: '', tenHuyenThiTp: '', maPhuongXaTT: '', tenPhuongXaTT: '' };
        setData(dataNew);
    };

    const handleChangeSelectTinhTruSo = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...vsattpData, maHuyenThiTp: '', tenHuyenThiTp: '', maPhuongXaTT: '', tenPhuongXaTT: '', [nameValue]: value?.code, [nameText]: valueText };
        setData(dataNew);
    }

    const handleChangeSelectPhuongXaTTDSXKD = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...vsattpData, [nameValue]: value?.code, [nameText]: valueText };
        setData(dataNew);
    };

    const showMessageAlert = (content: string, isSuccess: boolean = true, isWarning?: boolean) => {
        if (isWarning){
            setWarning(true);
        }
        setMessage(content);
        setSuccess(isSuccess);
        setShowAlert(true);
    };

    const handleChangeSelectQuanTruSo = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...vsattpData,
            maPhuongXaTT: '',
            tenPhuongXaTT: '',
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const onSearchDKKD = (maSoDoanhNghiep: string) => {
        if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
            showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoDKKD({ maSoDoanhNghiep: maSoDoanhNghiep }).then(res => {
                let dataNew = res?.data.data;
                let tenNguoiDaiDien = "";
                for (let index = 0; index < res?.data?.data?.data?.listThanhVien.length; index++) {
                    if (res?.data?.data?.data?.listThanhVien[index].isNguoiDaiDienPhapLuat == "1") {
                        tenNguoiDaiDien = res?.data?.data?.data?.listThanhVien[index].hoTen;
                    };
                }
                if (dataNew !== null && dataNew !== undefined) {
                    if (dataNew.error_code === 'SUCCESSFUL' && dataNew.data && dataNew.data != null) {
                        const tested = DateUtil.stringDDMMYYYtoDate(dataNew.data.ngayThanhLap.split(' ')[0]);

                        setData({
                            ...vsattpData,
                            dienThoai: dataNew.data.dienThoai,
                            soNha: dataNew.data.soNha,
                            fax: dataNew.data.fax,
                            ngayCapCNDKKDHKD: tested !== null ? tested.getTime() : 0,
                            tenPhuongXaTT: dataNew.data.tenPhuongXa,
                            tenDuong: dataNew.data.tenDuong,
                            tenCoSo: dataNew.data.tenDoanhNghiep_VN,
                            tenChuCoSo: tenNguoiDaiDien,
                        });
                    } else {
                        showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không tồn tại trong dữ liệu", false);
                    }
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
    };

    const layKetQuaSerVice = new LayKetQuaGiayPhepService();    
    const handleDongBoGiayPhep =  async () => {
        dispatchLoading(showLoading());
        await layKetQuaSerVice.layKetQuaByMaHoSo(vsattpData.maHoSo) .then((res) => {  
            if (res != null && res?.data?.error_code == "SUCCESSFUL" && res?.data?.data!==null) {
                if (res?.data?.error_code == "SUCCESSFUL" && res?.data?.data!==null) {
                    if (res?.data?.data?.files && res?.data?.data?.files.length>0){
                        const files: FileEntry[] = [];
                        for (let i = 0 ; i<res?.data?.data?.files.length;i++){
                            const file : FileEntry = {
                                fileEntryId:0,
                                folderId: 0,
                                userId: "",
                                userName: "",
                                name: res.data.data.files[i].fileName,
                                createdDate: 0,
                                extension: "",
                                mimeType: "",
                                orginName: "",
                                description: "",
                                version: "",
                                docId: "",
                                fileParentId: 0,
                                motCua: true,
                                url: res.data.data.files[i].url,
                            }
                           files.push(file);
                        }
                        vsattpData.files=files;
                    }
                    vsattpData.soGiayPhep = res.data.data.soGiayPhep ? res.data.data.soGiayPhep : null;
                    vsattpData.ngayCapPhep = res.data.data.ngayCap ? res.data.data.ngayCap : null;
                    vsattpData.ngayHetHan = res.data.data.ngayHetHan ? res.data.data.ngayHetHan : vsattpData.ngayHetHan;
                    const ketQuaGiayPhep: KetQuaGiayPhepDTO={
                        soGiayPhep: res.data.data.soGiayPhep,
                        ngayHetHan : res.data.data.ngayHetHan,
                        files: res.data.data.files,
                        ngayCap: res.data.data.ngayCap,
                    }
                    vsattpData.ketQuaGiayPhep = ketQuaGiayPhep;
                    showMessageAlert(res.data.error_message);
                } else{
                    showMessageAlert(res.data.error_message, false, true);
                }}
            }
        ).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    return (
        <>
            {
                <Box padding={0}>
                    <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() =>{setShowAlert(false); setWarning(false)} } severity={success ? 'success' : warning? 'warning':'error'}>{message}</Alert>
                    </Snackbar>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Số giấy phép </InputLabel>
                                <InputBase
                                    fullWidth
                                    error={submit && StringUtil.isNullOrEmty(vsattpData.soGiayPhep)}
                                    value={vsattpData.soGiayPhep}
                                    name="soGiayPhep"
                                    onChange={handleChange}
                                />
                                {submit && StringUtil.isNullOrEmty(vsattpData.soGiayPhep) && (
                                    <FormHelperText className="-error">Vui lòng nhập số giấy phép</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        error={submit && (!vsattpData.ngayCapPhep || vsattpData.ngayCapPhep === undefined || vsattpData.ngayCapPhep === 0)}
                                        value={
                                            vsattpData.ngayCapPhep !== 0 &&
                                                vsattpData.ngayCapPhep &&
                                                vsattpData.ngayCapPhep !== null &&
                                                vsattpData.ngayCapPhep !== undefined
                                                ? new Date(vsattpData.ngayCapPhep)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePickerNgayCap(date, value, "ngayCapPhep")}
                                    />
                                </MuiPickersUtilsProvider>
                                {submit && (!vsattpData.ngayCapPhep || vsattpData.ngayCapPhep === undefined || vsattpData.ngayCapPhep === 0) && (
                                    <FormHelperText className="-error">Vui lòng nhập lại ngày cấp phép</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        error={
                                            submit && !(vsattpData.ngayHetHan !== 0 && vsattpData.ngayHetHan !== null && vsattpData.ngayHetHan !== undefined)
                                        }
                                        value={
                                            vsattpData.ngayHetHan !== 0 && vsattpData.ngayHetHan !== null && vsattpData.ngayHetHan !== undefined
                                                ? new Date(vsattpData.ngayHetHan)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                    />
                                </MuiPickersUtilsProvider>
                                {submit && vsattpData.ngayHetHan < vsattpData.ngayCapPhep && (
                                    <FormHelperText className="-error">Vui lòng nhập lại ngày hết hạn</FormHelperText>
                                )}
                            </Grid>
                            {vsattpData.soGiayPhep && vsattpData.maHoSo ?
                                    <Grid item xs={3} className="grid-item" >
                                        <Box className="button-group" marginTop="15px" >
                                            <Button variant="outlined" disableElevation onClick={
                                                () => handleDongBoGiayPhep()
                                            }>
                                                Lấy kết quả
                                            </Button>
                                        </Box>
                                    </Grid> :
                                    <Grid item xs={3} className="grid-item" >

                                    </Grid>
                                }
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Số Giấy CNĐKKD/HKD</InputLabel>
                                <InputBase
                                    fullWidth
                                    name="maSoGiayCNDKKDHKD"
                                    value={vsattpData.maSoGiayCNDKKDHKD}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {" "}
                                            <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "10px", marginLeft: "3px" }} onClick={(e) => onSearchDKKD(vsattpData.maSoGiayCNDKKDHKD)} />
                                        </InputAdornment>
                                    }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={
                                            vsattpData.ngayCapCNDKKDHKD !== 0 &&
                                                vsattpData.ngayCapCNDKKDHKD !== null &&
                                                vsattpData.ngayCapCNDKKDHKD !== undefined
                                                ? new Date(vsattpData.ngayCapCNDKKDHKD)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapCNDKKDHKD")}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Điện thoại</InputLabel>
                                <InputBase fullWidth name="dienThoai" value={vsattpData.dienThoai} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Fax</InputLabel>
                                <InputBase fullWidth name="fax" value={vsattpData.fax} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel required>Tên chủ cơ sở</InputLabel>
                                <InputBase
                                    fullWidth
                                    error={submit && StringUtil.isNullOrEmty(vsattpData.tenChuCoSo)}
                                    value={vsattpData.tenChuCoSo}
                                    name="tenChuCoSo"
                                    onChange={handleChange}
                                />
                                {submit && StringUtil.isNullOrEmty(vsattpData.tenChuCoSo) && (
                                    <FormHelperText className="-error">Vui lòng nhập tên chủ cơ sở</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Tên cơ sở</InputLabel>
                                <InputBase
                                    fullWidth
                                    error={submit && StringUtil.isNullOrEmty(vsattpData.tenCoSo)}
                                    value={vsattpData.tenCoSo}
                                    name="tenCoSo"
                                    onChange={handleChange}
                                />
                                {/* {submit && StringUtil.isNullOrEmty(vsattpData.tenCoSo) && (
                                    <FormHelperText className="-error">Vui lòng nhập tên cơ sở</FormHelperText>
                                )} */}
                            </Grid>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Thành phố </InputLabel>
                                {/* <InputBase fullWidth readOnly
                                        value={data?.tenTinhThanh} name="tenTinhThanh" /> */}
                                <Autocomplete id="maTinhThanh"
                                    disableListWrap
                                    options={tinhs}
                                    value={{ code: vsattpData?.maTinhTp ? vsattpData?.maTinhTp : "", name: vsattpData?.tenTinhTp ? vsattpData?.tenTinhTp : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectTinhTruSo(newValue, 'maTinhTp', 'tenTinhTp')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(vsattpData?.maTinhTp)}
                                                {...params.inputProps}
                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(vsattpData?.maTinhTp) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Thành phố</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                {/* <InputBase fullWidth readOnly disabled
                                    value={data?.tenQuanTruSo} name="tenQuanTruSo" /> */}
                                <Autocomplete id="maHuyen"
                                    disableListWrap
                                    options={quans}
                                    value={{ code: vsattpData?.maHuyenThiTp ? vsattpData?.maHuyenThiTp : "", name: vsattpData?.tenHuyenThiTp ? vsattpData?.tenHuyenThiTp : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectQuanTruSo(newValue, 'maHuyenThiTp', 'tenHuyenThiTp')}
                                    onFocus={(e) => loadQuans(vsattpData.maTinhTp)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(vsattpData?.maHuyenThiTp)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(vsattpData?.maHuyenThiTp) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={phuongs}
                                    value={{ code: vsattpData?.maPhuongXaTT ? vsattpData?.maPhuongXaTT : "", name: vsattpData?.tenPhuongXaTT ? vsattpData?.tenPhuongXaTT : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectPhuongXaTTDSXKD(newValue, 'maPhuongXaTT', 'tenPhuongXaTT')}
                                    onFocus={(e) => loadPhuongs(vsattpData.maHuyenThiTp)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth

                                                error={submit && StringUtil.isNullOrEmty(vsattpData?.maPhuongXaTT)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(vsattpData?.maPhuongXaTT) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Đường </InputLabel>
                                <InputBase fullWidth value={vsattpData.tenDuong} name="tenDuong" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Số nhà</InputLabel>
                                <InputBase fullWidth name="soNha" value={vsattpData.soNha} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Khu vực, ấp</InputLabel>
                                <InputBase fullWidth name="khuVucAp" value={vsattpData.khuVucAp} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={6} className="grid-item">
                                <InputLabel>Nơi cấp Giấy CNĐKDN/HKD</InputLabel>
                                <InputBase fullWidth name="noiCapCNDKKDHKD" value={vsattpData.noiCapCNDKKDHKD} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Địa chỉ sản xuất/kinh doanh</InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Huyện/Thị/TP</InputLabel>
                                <InputBase fullWidth readOnly disabled
                                    value={vsattpData?.tenHuyenThiTpDSXKD} name="maHuyenThiTpDSXKD" />
                                {/* <Autocomplete
                                    id="maHuyenThiTpDSXKD"
                                    noOptionsText={"Không có Huyện/Thị/TP"}
                                    options={quans}
                                    value={{code: vsattpData.maHuyenThiTpDSXKD , name: vsattpData.tenHuyenThiTpDSXKD }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectQuan(newValue, "maHuyenThiTpDSXKD", "tenHuyenThiTpDSXKD");
                                    }}    
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                /> */}
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Phường/Xã/TT</InputLabel>
                                <Autocomplete
                                    id="maPhuongXaTTDSXKD"
                                    options={phuongXaTTDSXKDs}
                                    onFocus={loadPhuongXaTTDSXKDs}
                                    value={{ code: vsattpData.maPhuongXaTTDSXKD, name: vsattpData.tenPhuongXaTTDSXKD }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectPhuongXaTTDSXKD(newValue, "maPhuongXaTTDSXKD", "tenPhuongXaTTDSXKD")}
                                    renderInput={(params) => (
                                        <TextField error={submit && StringUtil.isNullOrEmty(vsattpData.maPhuongXaTTDSXKD)} {...params} variant="outlined" />
                                    )}
                                />
                                {submit && StringUtil.isNullOrEmty(vsattpData.maPhuongXaTTDSXKD) && (
                                    <FormHelperText className="-error">Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Đường </InputLabel>
                                <InputBase fullWidth value={vsattpData.tenDuongDSXKD} name="tenDuongDSXKD" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Số nhà</InputLabel>
                                <InputBase fullWidth name="soNhaDSXKD" value={vsattpData.soNhaDSXKD} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Khu vực, ấp</InputLabel>
                                <InputBase fullWidth name="khuVucApDSXKD" value={vsattpData.khuVucApDSXKD} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Điện thoại</InputLabel>
                                <InputBase fullWidth name="dienThoaiDSXKD" value={vsattpData.dienThoaiDSXKD} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Fax</InputLabel>
                                <InputBase fullWidth name="faxDSXKD" value={vsattpData.faxDSXKD} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Quy mô kinh doanh dự kiến </InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>(tổng số suất ăn/lần phục vụ)</InputLabel>
                                <InputBase fullWidth name="quyMoKinhDoanhDSXKD" value={vsattpData.quyMoKinhDoanhDSXKD} onChange={handleChangeNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Số lượng người lao động</InputLabel>
                                <InputBase fullWidth name="soLuongNguoiLaoDongDSXKD" value={vsattpData.soLuongNguoiLaoDongDSXKD} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Số lượng lao động trực tiếp</InputLabel>
                                <InputBase
                                    fullWidth
                                    name="soLuongLaoDongTrucTiepDSXKD"
                                    value={vsattpData.soLuongLaoDongTrucTiepDSXKD}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Số lượng lao động gián tiếp</InputLabel>
                                <InputBase
                                    fullWidth
                                    name="soLuongLaoDongGianTiepDSXKD"
                                    value={vsattpData.soLuongLaoDongGianTiepDSXKD}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Kết quả thẩm định</InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item"></Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Người thẩm định</InputLabel>
                                <InputBase fullWidth name="nguoiThamDinh" value={vsattpData.nguoiThamDinh} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày thẩm định</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={
                                            vsattpData.ngayThamDinh !== 0 && vsattpData.ngayThamDinh !== null && vsattpData.ngayThamDinh !== undefined
                                                ? new Date(vsattpData.ngayThamDinh)
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayThamDinh")}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Kết quả thẩm định</InputLabel>
                                <InputBase fullWidth name="ketQuaThamDinh" multiline rows="4" value={vsattpData.ketQuaThamDinh} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={ACCEPT_FILES}
                                    attachFileServer={vsattpData.files}
                                    url={api.defaults.baseURL + "/file-server/upload/attp"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                            <ButtonATTPComponent dauKy onSubmit={onSubmit} resetForm={resetForm} onDownloadFileBieuMau={handleDownloadFileBieuMau} />
                        </Grid>
                    </Box>
                </Box>
            }
        </>
    );
};
export default DauKyVSATTPNoiDungComponent;
