import { Button, Grid, InputLabel, NativeSelect, InputBase } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { OverlayLoading } from '../../App/commons/components/overlayLoading/overlayLoading';
import { ThongKeTinhHinhCapPhep } from '../../App/Components/ATTP/ThongKe/ThongKeTinhHinhCapPhep';
import { BieuDoThongKeTinhHinhCapPhep } from '../../App/Components/DKKD/BieuDoTK/BieuDoThongKeTinhHinhCapPhep';
import { ThongKeThiDuaKT } from '../../App/Components/KhenThuong/thongKeThiDuaKT';
import { LoadingAction } from '../../redux/actions/applicationAction';
import { DashboardBieuDoThongKeCPXD } from './CPXD/DashboardBieuDoThongKeCPXD';
import { DashboardBieuDoKhenThuong } from './KhenThuong/DashboardBieuDoKhenThuong';
export const DashboardPublic = () => {
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const [loaiDashboard, setLoaiDashboard] = useState<string>('1');
    useEffect(() => {
    }, []);
    return (
        <>
            <Grid container spacing={2} direction="row" alignItems="center">
                <OverlayLoading />
                <Grid item xs={6} className="gridItem">
                    <InputLabel >Dashboard</InputLabel>
                    <NativeSelect input={<InputBase fullWidth />}
                        value={loaiDashboard} onChange={(e) => setLoaiDashboard(e.target.value as string)} >
                        <option value={'1'}>Thành lập và hoạt động doanh nghiệp</option>
                        <option value={'2'}>Vệ sinh an toàn thực phẩm và dinh dưỡng</option>
                        <option value={'3'}>Thi đua khen thưởng</option>
                        <option value={'4'}>Lĩnh vực quản lý hoạt động xây dựng</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} className="gridItem">
                    {
                        loaiDashboard === '1' &&
                        <BieuDoThongKeTinhHinhCapPhep />
                    }
                    {
                        loaiDashboard === '2' &&
                        <ThongKeTinhHinhCapPhep />
                    }
                    {
                        loaiDashboard === '3' &&
                        <DashboardBieuDoKhenThuong />
                    }
                    {
                        loaiDashboard === '4' &&
                        <DashboardBieuDoThongKeCPXD />
                    }
                </Grid>
            </Grid>
        </>
    )
}
