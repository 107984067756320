import { Box, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import { CustomInput } from "../../../commons/components/input/customInput";
import moment from "moment";
import { GiayPhepKinhDoanhDTO } from "../../../../api/DKKD/model/giayPhepKinhDoanhDTO";
import { Link } from "react-router-dom";
import { DanhSachGiayPhepDKKDDTO } from "../../../../api/DKKD/model/DanhSachGiayPhepDKKDDTO";
import { LOAIBIENDONG_DIEUCHINH } from '../../../Constants/DKKDConstants';
import StringUtil from '../../../utils/stringUtils';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface IDataListProps {
    dataList: DanhSachGiayPhepDKKDDTO[];
    pageProps: TablePaginationActionsProps;
}

export const DanhSachBaoCaoThongKeGiayPhep = (props: IDataListProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1);

    useEffect(() => {
        setRows(props.dataList);
        console.log('props.dataList : ',props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.page);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Số giấy phép</Cell>
                        <Cell component="th" scope="row" align="center" width="6%">Ngày cấp</Cell>
                        <Cell component="th" scope="row" align="center" width="6%">Ngày biến động</Cell>
                        {/* <Cell component="th" scope="row" align="center" width="10%">Ngày hết hiệu lực</Cell> */}
                        <Cell component="th" scope="row" align="center" width="15%">Tên hộ kinh doanh</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Ngành nghề kinh doanh</Cell>
                        <Cell component="th" scope="row" align="center" width="6%">Tổng số vốn(VND)</Cell>
                        <Cell component="th" scope="row" align="center" width="6%">Số lượng lao động</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Địa chỉ</Cell>
                        <Cell component="th" scope="row" align="center" width="8%">Điện thoại</Cell>
                        <Cell component="th" scope="row" align="center" width="16%">Tình trạng</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {((props.pageProps.page - 1) * props.pageProps.rowsPerPage) + i + 1}
                                    </Cell>
                                    <Cell align="left">
                                        <Link to={{
                                            pathname: '/giay-phep-kinh-doanh/chi-tiet-giay-phep-kinh-doanh',
                                            state: { ...row, giayPhepId: row?.id }
                                        }}>
                                            {row.soGiayPhep}
                                        </Link>
                                    </Cell>
                                    <Cell align="left">{(row.ngayCapPhep !== 0 && row.ngayCapPhep !== null) ? moment(row.ngayCapPhep).format("DD/MM/YYYY") : ''}</Cell>
                                    <Cell align="left">{(row.ngayBienDong !== 0 && row.ngayBienDong !== null) ? moment(row.ngayBienDong).format("DD/MM/YYYY") : ''}</Cell>
                                    {/* <Cell align="left">{(row.ngayHetHanGP !== 0 && row.ngayHetHanGP !== null) ? moment(row.ngayHetHanGP).format("DD/MM/YYYY") : ''}</Cell> */}
                                    <Cell align="left">{row.tenHoKinhDoanh}</Cell>
                                    <Cell align="left">{row.chiTietNganhNghe}</Cell>
                                    <Cell align="center">{StringUtil.formatForDot(row.tongVon)}</Cell>
                                    <Cell align="center">{row.soLuongLaoDong}</Cell>
                                    <Cell align="left">{row.diaChiDayDu}</Cell>
                                    <Cell align="left">{row.dienThoai}</Cell>
                                    <Cell align="left">{row.maBienDong === LOAIBIENDONG_DIEUCHINH.ma ? 'Cấp đổi' : row.tenBienDong}</Cell>
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={13} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            {rows.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined" page={props.pageProps.page}
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={props.pageProps.onChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage} >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {(props.pageProps.page * rowsPerPage)} - {props.pageProps.rowsPerPage * rowsPerPage > props.pageProps.count ? props.pageProps.count : (props.pageProps.rowsPerPage * rowsPerPage)} / {props.pageProps.count}</Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
