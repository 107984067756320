import AXIOS_INSTANCE from "../index";
import { KiemTranNganChanRequest } from "./model/CapPhepXayDungModel";
export class KiemTraNganChanServices {
    insertOrUpdate = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/cpxd/kiem-tra-ngan-chan/add-or-update', requestBody);
    }
    
    getTokenRequestHeaders = () => {
        return {
            headers: {
                "Content-Type": "application/json"
            }
        };
    };

    timKiem = (requestBody : any) =>{
        return AXIOS_INSTANCE.post('/cpxd/kiem-tra-ngan-chan/tim-kiem', requestBody, this.getTokenRequestHeaders())
    }

    delete =  (id: number)=> {
        return AXIOS_INSTANCE.post('/cpxd/kiem-tra-ngan-chan/xoa/' + id, this.getTokenRequestHeaders())
    }

    downloadFileKiemTraNganChan =  (request: KiemTranNganChanRequest): Promise<any> => {
        return AXIOS_INSTANCE.post('/cpxd/kiem-tra-ngan-chan/export-file', request, {
            params: {},
            data: {},
            responseType: "blob",
            timeout: 30000,
        });
    };
}