import React, { useEffect, useState, Dispatch } from 'react';
import {
    Button,
    Box,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputBase,
    InputLabel,
    makeStyles,
    Snackbar,
    Theme
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { HoSoThuLyLPGChaiDTO } from "../../../../../api/LPGChai/model/HoSoThuLyLPGChai";
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { IFileWithMeta } from 'react-dropzone-uploader';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import SearchIcon from '@material-ui/icons/Search';
import MomentUtils from "@date-io/moment";
import StringUtil from '../../../../utils/stringUtils';
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../api/danhMuc/model/DanhMucDTO";
import AXIOS_INSTANCE from "../../../../../api";
import { AlertMessage, CustomAlert } from '../../../../commons/components/alert';
import DateUtil from '../../../../utils/dateUtils';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { useDispatch } from "react-redux";
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { ThongTinChuyenNhanhUtil } from '../../../../utils/thongTinChuyenNhanhUtil';
import { LoaiGiayPhepEnum } from '../../../../../api/loaiGiayPhep/loaiGiayPhepEnum';
import LayKetQuaGiayPhepService from '../../../../../api/QuanTri/LayKetQuaGiayPhepService';
import { GiayPhepRequest } from '../../../../../api/QuanTri/Model/MotCuaModel';

interface CapMoiLPGChaiProps {
    data: HoSoThuLyLPGChaiDTO;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textSearch: {
            width: "90%"
        }
    }),
);

export const ThongTinChungComponent = (props: CapMoiLPGChaiProps) => {

    const classes = useStyles();
    const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";
    const { onUpdateData, submit } = props;
    const [data, setData] = useState({} as HoSoThuLyLPGChaiDTO);
    const [showCapSo, setShowCapSo] = useState<boolean>(false);
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const [dataQuanHuyen, setDataQuanHuyen] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataXaPhuong, setDataXaPhuong] = useState<DanhMucDiaChiDTO[]>([]);
    const fileServerService = new FileServerService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const thongTinChuyenNganh = new ThongTinChuyenNhanhUtil();
    const handleChange = (e: any) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        onUpdateData(target.name, value);
    }
    const handleChangeAutoComplete = (ma: string, ten: string, value: any) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [ma]: value?.ma, [ten]: valueText };
        onUpdateData("data", dataNew);
    }
    async function handleChangeQuanHuyen(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText, maPhuongXa: '', tenPhuongXa: '' };
        onUpdateData("data", dataNew);
    }

    async function handleChangePhuongXa(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function onBlurPhuongXa() {
        if (data?.maQuanHuyen) {
            await new DanhMucService().getDanhMucDiaChi(data?.maQuanHuyen).then((resp) => {
                setDataXaPhuong(resp);
            });
        }
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const setDataFiles = (fileData: FileEntry[]) => {
        let fileUpload = [...data?.files];
        fileUpload = fileUpload.concat(fileData);
        let dataNew = {
            ...data,
            files: fileUpload
        };
        onUpdateData("data", dataNew);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let files = data.files && data.files != null ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            files: mems,
        };
        setAttachFileServer(mems);
        onUpdateData("data", dataNew);
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true, isWarning?: boolean ) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : isWarning ? "warning" : "error"  });
    };

    const onSearchDKKD = async (maSoDoanhNghiep: string) => {
        if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
            showMessageAlert("Số Giấy CNĐKDN/HKD không được trống", false);
            return;
        } else {
            let tenThuongNhan = "" as string;
            dispatchLoading(showLoading());
            await new DanhMucService().getBySoDKKD({ maSoDoanhNghiep: maSoDoanhNghiep }).then(res => {
                let dataNew = res?.data.data;
                if (dataNew !== null && dataNew !== undefined) {
                    if (dataNew.error_code === 'SUCCESSFUL' && res?.data?.data?.data?.maSoDoanhNghiep) {
                        tenThuongNhan = new ThongTinChuyenNhanhUtil().getTenThuongNhan(res?.data?.data?.data?.listThanhVien);
                        let dataDKKD = {
                            ...data,
                            soNha: dataNew.data.soNha,
                            fax: dataNew.data.fax,
                            ngayCapCNDKKD: res?.data?.data?.data?.ngayThanhLap ? DateUtil.stringDDMMYYYtoDate(res?.data?.data?.data?.ngayThanhLap) : data?.ngayCapCNDKKD,
                            maSoThue: res?.data?.data?.data?.maSoThue,
                            soDienThoai: res?.data?.data?.data?.dienThoai ? StringUtil.replaceDots(res?.data?.data?.data?.dienThoai) : data?.soDienThoai,
                            email: res?.data?.data?.data?.email,
                            tenThuongNhan: tenThuongNhan
                        };
                        onUpdateData("data", dataDKKD);
                    } else {
                        setAlertMessage({ open: true, content: "Số Giấy CNĐKDN/HKD không tồn tại trong dữ liệu", severity: 'warning' });
                    }
                }
            }).catch(error => {
                console.log(error);
            })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        }
    };
    useEffect(() => {
        async function getDm() {
            await new DanhMucService().getDanhMucDiaChi(String(92)).then((resp) => {
                setDataQuanHuyen(resp);
            });
        }
        getDm();
    }, []);
    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
        setShowCapSo(props.data?.donViCapPhep);
    }, [props.data, props.onUpdateData]);

    const handleChangeDonViCapPhep = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setShowCapSo(value);
        onUpdateData(e.target.name, value);
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

   
    return (
        <>
            {
                <Box padding={0}>
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <Grid container direction="row">
                      
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={data?.donViCapPhep}
                                        onChange={handleChangeDonViCapPhep}
                                        name="donViCapPhep"
                                        control={<Checkbox color="primary" checked={data?.donViCapPhep == true ? true : false} />}
                                        label="Đơn vị cấp số giấy phép"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                            <InputLabel required>Số giấy phép</InputLabel>
                            <InputBase fullWidth name="soGiayPhep" value={data.soGiayPhep} onChange={handleChange}
                                error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                            />
                            {
                                submit && data.donViCapPhep && (data.soGiayPhep === "" || data.soGiayPhep === undefined) &&
                                <FormHelperText className="-error">Vui lòng nhập số giấy phép</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                            <InputLabel required>Ngày cấp</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={(data.ngayCapPhep !== undefined && data.ngayCapPhep > 0) ? new Date(data.ngayCapPhep) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    error={submit && (data.ngayCapPhep === 0 || data.ngayCapPhep === undefined)}
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}
                                />
                                {
                                    submit && data.donViCapPhep && (data.ngayCapPhep === 0 || data.ngayCapPhep === undefined) &&
                                    <FormHelperText className="-error">Vui lòng nhập ngày cấp</FormHelperText>
                                }
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                            <InputLabel>Ngày hết hạn</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={(data.ngayHetHanGP !== undefined && data.ngayHetHanGP > 0) ? new Date(data.ngayHetHanGP) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline" InputLabelProps={{}}
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>



                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel required>Số Giấy CNĐKDN/HKD</InputLabel>
                            <InputBase className={classes.textSearch} fullWidth name="soGiayCNDKKD"
                                value={data.soGiayCNDKKD} onChange={handleChange}
                                error={submit && StringUtil.isNullOrEmty(data?.soGiayCNDKKD)}
                            />
                            <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '3px', marginLeft: '3px' }}
                                onClick={(e) => onSearchDKKD(data?.soGiayCNDKKD)}
                            />
                            {
                                submit && StringUtil.isNullOrEmty(data.soGiayCNDKKD) &&
                                <FormHelperText className="-error">Vui lòng nhập số Giấy CNĐKDN/HKD</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel required>Tên thương nhân</InputLabel>
                            <InputBase fullWidth name="tenThuongNhan" value={data.tenThuongNhan} onChange={handleChange}
                                error={submit && StringUtil.isNullOrEmty(data?.tenThuongNhan)}
                            />
                            {
                                submit && StringUtil.isNullOrEmty(data.tenThuongNhan) &&
                                <FormHelperText className="-error">Vui lòng nhập tên thương nhân</FormHelperText>
                            }
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel required>Huyện/Thị/TP </InputLabel>
                            <Autocomplete id="maHuyen"
                                options={dataQuanHuyen}
                                value={{ code: data?.maQuanHuyen ? data?.maQuanHuyen : "", name: data?.tenQuanHuyen ? data?.tenQuanHuyen : "" }}
                                getOptionLabel={(option) => `${option.name}`}
                                onChange={(event: any, newValue: any) => handleChangeQuanHuyen(newValue, 'maQuanHuyen', 'tenQuanHuyen')}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth
                                            error={submit && StringUtil.isNullOrEmty(data?.maQuanHuyen)}
                                            {...params.inputProps}

                                        />
                                    </div>
                                }}
                            />
                            {
                                submit && StringUtil.isNullOrEmty(data?.maQuanHuyen) &&
                                <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel required>Phường/Xã/TT </InputLabel>
                            <Autocomplete id="maPhuong"
                                options={dataXaPhuong}
                                onFocus={onBlurPhuongXa}
                                value={{ code: data?.maPhuongXa ? data?.maPhuongXa : "", name: data?.tenPhuongXa ? data?.tenPhuongXa : "" }}
                                getOptionLabel={(option) => `${option.name}`}
                                onChange={(event: any, newValue: any) => handleChangePhuongXa(newValue, 'maPhuongXa', 'tenPhuongXa')}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth
                                            error={submit && StringUtil.isNullOrEmty(data?.maPhuongXa)}
                                            {...params.inputProps}

                                        />
                                    </div>
                                }}
                            />
                            {
                                submit && StringUtil.isNullOrEmty(data?.maPhuongXa) &&
                                <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Đường </InputLabel>
                            <InputBase fullWidth value={data.tenDuong} name="tenDuong" onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Số nhà</InputLabel>
                            <InputBase fullWidth name="soNha" value={data.soNha} onChange={handleChange} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Điện thoại</InputLabel>
                            <InputBase fullWidth name="soDienThoai" value={data.soDienThoai} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Fax</InputLabel>
                            <InputBase fullWidth name="fax" value={data.fax} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Ngày cấp</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={(data?.ngayCapCNDKKD !== undefined && data?.ngayCapCNDKKD > 0) ? new Date(data?.ngayCapCNDKKD) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapCNDKKD')}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Nơi cấp Giấy CNĐKDN/HKD</InputLabel>
                            <InputBase fullWidth name="noiCapCNDKKD" value={data.noiCapCNDKKD} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Mã số thuế</InputLabel>
                            <InputBase fullWidth name="maSoThue" value={data.maSoThue} onChange={handleChange}
                                error={submit && StringUtil.isNullOrEmty(data?.maSoThue)}
                            />
                            {/* {
                                submit && StringUtil.isNullOrEmty(data.maSoThue) &&
                                <FormHelperText className="-error">Vui lòng nhập mã số thuế</FormHelperText>
                            } */}
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Theo đề nghị của</InputLabel>
                            <InputBase fullWidth name="theoDeNghi" value={data.theoDeNghi} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Nơi nhận</InputLabel>
                            <InputBase fullWidth name="noiNhan" value={data.noiNhan} onChange={handleChange} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container className="grid-item">
                        <InputLabel>Những điều quy định</InputLabel>
                        <InputBase fullWidth name="dieuQuyDinh" value={data.dieuQuyDinh} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} container className="grid-item">
                        <DropZoneCustom
                            acceptFiles={`${acceptFiles}`}
                            attachFileServer={attachFileServer}
                            url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/lpg"}
                            handleChangeStatus={handleChangeStatus}
                            handleDeleteFileServer={handleDeleteFileServer}
                            handleDownloadFile={handleDownloadFile}
                        />
                    </Grid>
                </Box>
            }
        </>
    )
};
