import { Box, Button, Grid, InputBase, InputLabel, NativeSelect, Paper,TextField,Checkbox,FormControlLabel,Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { bootstrapTextFieldHook } from "../../commons/components/input/bootstrap";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TRA_CUU_THONG_TIN_CONG_DAN_INIT ,TraCuuThongTinCDRequest, ChuHo, ThanhVien} from "../../../api/CongDan/model/ThongTinCongDan";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import GridView from "../../commons/components/gridView";
import { DanhSachThanhVienCongDan } from "./DanhSachThanhVienCongDan";
import { CustomTab, CustomTabs } from "../../commons/components/tabs";
import { TraCuuCaNhan } from "./TraCuuCaNhan";
import { TraCuuHoGiaDinh } from "./TraCuuHoGiaDinh";
import { TabPanelProps } from "@material-ui/lab";
export const TraCuuThongTinCongDan = () => {
    const [indexTab, setIndexTab] = React.useState(0);
    const labelTabs: String[] = ["Tra cứu thông tin cá nhân", "Tra cứu hộ gia đình"];
    interface TabPanelProps {
        children?: React.ReactNode;
        index: any;
        value: any;
    }

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;
    
        return (
            <div style={{ width: '100%' }}
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={1}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>TRA CỨU THÔNG TIN CÔNG DÂN</span>
            </div>
            <Grid item xs={12} className="grid-item">
                <Paper component="div">
                    <Box mt="5px" bgcolor="#f2f2f2">
                        <CustomTabs value={indexTab} onChange={handleChange} >
                            <CustomTab label="Tra cứu thông tin cá nhân" />
                            <CustomTab label="Tra cứu hộ gia đình" />
                        </CustomTabs>
                    </Box> 
                    {
                        indexTab === 0 &&
                        <TabPanel value={indexTab} index={0}>
                            <TraCuuCaNhan />
                        </TabPanel>
                    }
                    {
                        indexTab === 1 &&
                        <TabPanel value={indexTab} index={1}>
                            <TraCuuHoGiaDinh />
                        </TabPanel>
                    }
                </Paper>
            </Grid>
        </div>
    );

}