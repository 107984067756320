import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { BrowserHistory } from 'history';
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../commons/components/tabs";
import { HoSoThuLyBLThuocLa } from "../../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import { TINH_TRANG } from "../../../../../api/QuanTri/DanhMucFakeService";
import { CAP_MOI_BL_THUOC_LA_INIT } from "../../../../../api/CPBLTL/init/CapMoiBLThuocLaInit";
import StringUtil from "../../../../utils/stringUtils";
import { CapMoiBLThuocLaService } from "../../../../../api/CPBLTL/CapMoiBLThuocLaService";
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { BieuMauAttachFileDTO } from "../../../../../api/QuanTri/Model/danhMucBieuMau";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import CapSuaDoiBLThuocLaNoiDungComponent from "./CapSuaDoiBLThuocLaNoiDungComponent";
import { SearchGiayPhepDTO } from "../../../../../api/CPBLTL/model/SearchGiayPhepDTO";
import { QuanLyKDTLService } from "../../../../../api/CPBLTL/QuanLyKDTLService";
import { BienDong } from "../../../../../api/DKKD/model/BienDongTrangThaiGPKDDTO";
import ThuLyButtonComponent from '../../../../commons/components/hoso/thuly/ThuLyButtonComponent';
import { LOAIBIENDONG_CAPDOI, LOAIBIENDONG_CAPLAI, TINHTRANG_HOATDONG } from '../../../../Constants/ThuocLaConstants';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';

type StateHoSo = {
    maThuTuc: string,
    maHoSo: string
}
type CapMoiSXRuouProps = {
    history: BrowserHistory<StateHoSo>
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const CapSuaDoiBLThuocLaComponent = (props: CapMoiSXRuouProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = React.useState({} as HoSoThuLyBLThuocLa);
    const capMoiBLThuocLaService = new CapMoiBLThuocLaService();
    const quanLyKDTLService = new QuanLyKDTLService();
    const { history } = props;

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        capMoiBLThuocLaService.getByMaHoSo({ maHoSo: history?.location?.state?.maHoSo }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let resData = {
                    ...res?.data?.data,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten
                } as HoSoThuLyBLThuocLa;
                setData(resData);
            } else {
                const dataNew = {
                    ...CAP_MOI_BL_THUOC_LA_INIT,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    donViCapPhep: false,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });

    }
    const handleSubmit = () => {
        if (StringUtil.isNullOrEmty(data.maHoSo)) {
            setTabRequired(-0);
            showMessageAlert('Không có mã hồ sơ', false);
            return;
        }
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan)
            || StringUtil.isNullOrEmty(data.soCNDKKD)
            || StringUtil.isNullOrEmty(data.maPhuongXa)
            || StringUtil.isNullOrEmty(data.lyDo)) {
            setTabRequired(-0);
            return;
        }
        setSubmit(false);
        capMoiBLThuocLaService.insertOrUpdate(data).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL' && parseInt(res.data.data.id) > 0) {
                    let resData = {
                        ...res?.data?.data
                    };
                    setData(resData);
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else {
                    showMessageAlert('Yêu cầu thực hiện thất bại', false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }
    const resetData = () => {
        const dataNew = {
            ...CAP_MOI_BL_THUOC_LA_INIT,
            maBienDong: LOAIBIENDONG_CAPDOI.ma,
            tenBienDong: LOAIBIENDONG_CAPDOI.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten,
            donViCapPhep: false,
            id: data.id,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc,
            fileTrash: data?.files
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        capMoiBLThuocLaService.downloadFileCapSua({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            if (res && res.data) {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
            }

        });
    }

    const changeSoGiayPhep = (value: number) => {
        quanLyKDTLService.getChiTietGPKDById({ giayPhepId: value }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let dataNew = {
                    ...res?.data?.data,
                    id: data.id,
                    giayPhepId: value,
                    soGiayPhepCu: res?.data?.data?.soGiayPhep,
                    lyDo: data.lyDo,
                    soLanCapLaiOrSuaDoi: res?.data?.data?.soLanCapLaiOrSuaDoi + 1,
                    ngayCapLaiOrSuaDoi: data.ngayCapLaiOrSuaDoi,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    donViCapPhep: false,
                    files: data.files,
                    fileTrash: [],
                };
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="dash-content">
            <>
                {
                    <Paper square>
                        <OverlayLoading />
                        <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                            <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                        </Snackbar>
                        <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                            title="Xóa thông tin"
                            content="Bạn có đồng ý xóa thông tin đã nhập?"
                            onClose={handleCloseDeleteInfo}
                        />
                        <Grid container>
                            {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                                <br></br>
                                    <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                                <div className="dash-content">
                                    <div className="dash-title">
                                        CẤP SỬA ĐỔI, BỔ SUNG GIẤY PHÉP BÁN LẺ SẢN PHẨM THUỐC LÁ - MÃ HỒ SƠ : {data.maHoSo}
                                    </div>
                                </div>
                                    {/* </h6>
                                <br></br>
                            </Grid> */}
                            <br />
                            <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                                <Box mt="5px" bgcolor="#f2f2f2">
                                    <CustomTabs value={indexTab} onChange={handleChange}>
                                        <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                    </CustomTabs>
                                </Box>
                            </Grid>
                            {
                                indexTab === 0 &&
                                <TabPanel value={indexTab} index={0}>
                                    <CapSuaDoiBLThuocLaNoiDungComponent
                                        onUpdateData={setDataParam}
                                        submit={submit}
                                        data={data}
                                        loadGiayPhepDaCap={changeSoGiayPhep}
                                    />
                                </TabPanel>
                            }
                            <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                                <ThuLyButtonComponent showIn={data.id > 0} onSubmit={handleSubmit} onDownloadFileBieuMau={handleDownloadFileBieuMau} onDelete={handleDelete} />
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </>
        </div>
    )
};

export default CapSuaDoiBLThuocLaComponent;
