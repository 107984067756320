import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { CustomTab, CustomTabs } from "../../../commons/components/tabs";
import { HoSoThuLyBLThuocLaBienDong } from "../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import { QuanLyKDTLService } from "../../../../api/CPBLTL/QuanLyKDTLService";
import ThongTinGPComponent from "./ThongTinGPComponent";
import { useHistory } from 'react-router-dom';
import { LichSuGiayPhepComponent } from '../../../commons/components/hoso/lichsu/LichSuGiayPhepComponent';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const GiayPhepKDTLComponent = () => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [data, setData] = React.useState({} as HoSoThuLyBLThuocLaBienDong);
    const quanLyKDTLService = new QuanLyKDTLService();
    const history = useHistory();

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        quanLyKDTLService.getChiTietGPKDById({ giayPhepId: history?.location?.state?.id }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setData(res?.data?.data);
            }
        }).catch(error => {
            console.log(error);
        });

    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    return (
        <>
            {
                <Paper square >
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    XEM THÔNG TIN CHI TIẾT GIẤY PHÉP KINH DOANH THUỐC LÁ
                                </div>
                            </div>
                                {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab label="Thông tin chung" />
                                    <CustomTab label="Lịch sử" />
                                </CustomTabs>
                            </Box>

                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <ThongTinGPComponent
                                    data={data} />
                            </TabPanel>
                        }
                        {
                            indexTab === 1 &&
                            <TabPanel value={indexTab} index={1}>
                                <LichSuGiayPhepComponent dataList={data.lichSuBLThuocLas} />
                            </TabPanel>
                        }
                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Button type="button" variant="contained" color="primary"
                                onClick={(e) => history.go(-1)}
                                style={{ fontSize: '14px', width: '100px' }}>Trở về
                            </Button>&nbsp;&nbsp;
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    )
};
export default GiayPhepKDTLComponent;
