import React, {useEffect, useRef, useState} from 'react';
import {
    Box, Button,
    createStyles,
    Dialog,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    makeStyles,
    Theme
} from '@material-ui/core';
import * as Yup from 'yup';
import {useFormik} from "formik";
import {TBSanXuatRuouDTO} from "../../../../../../api/CPBLR/model/TBSanXuatRuouDTO";
import {DialogContent, DialogTitle} from "../../../../../commons/components/dialog/dialogCommons";
import {TBDiaDiemBLRuouDTO} from "../../../../../../api/CPBLR/model/TBDiaDiemBLRuouDTO";

interface DiaDiemBLRuouModalProps {
    onSubmitData: (resp: TBDiaDiemBLRuouDTO,  isEdit: Boolean) => void;
    openDialog: boolean;
    itemEdit: TBDiaDiemBLRuouDTO;
    onDialogClose: () => void;
    openEdit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
    }),
);

interface FormValues {
    requestDTO: TBDiaDiemBLRuouDTO,
}

const DiaDiemBLRuouModal = (props: DiaDiemBLRuouModalProps) => {
    const {onSubmitData} = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    const initDefaultValue: FormValues = {
        requestDTO: {
            tenDiaDiem: '',
            diaChi: ''
        } as TBDiaDiemBLRuouDTO
    }

    const validateionSchema = Yup.object().shape({
        requestDTO: Yup.object().shape({
            tenDiaDiem: Yup.string().required('Vui lòng nhập địa điểm!'),
            diaChi: Yup.string().required('Vui lòng nhập địa chỉ!'),
        }).required('Vui lòng nhập thông tin địa điểm bán lẻ rượu!')
    });

    const formik = useFormik({
        initialValues: initDefaultValue,
        validationSchema: validateionSchema,
        onSubmit: () => submitData()
    });

    useEffect(() => {
        if(props.openEdit){
            formik.setValues({
                requestDTO: {
                    tenDiaDiem: props.itemEdit.tenDiaDiem,
                    diaChi: props.itemEdit.diaChi,
                    ngayTao: props.itemEdit.ngayTao
                } as TBDiaDiemBLRuouDTO,
            });
        } else {
            formik.setValues({
                requestDTO: {
                    tenDiaDiem: '',
                    diaChi: ''
                } as TBDiaDiemBLRuouDTO,
            });
        }
        setOpenEdit(props.openEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    const submitData = async () => {
        onSubmitData(formik.values.requestDTO, openEdit);
    }

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="sm" aria-labelledby="customized-dialog-title"
                    open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {'CẬP NHẬT THÔNG TIN ĐỊA ĐIỂM BÁN LẺ RƯỢu'}
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel  required>Tên địa điểm</InputLabel>
                                    <InputBase fullWidth
                                               error={formik.touched.requestDTO?.tenDiaDiem && Boolean(formik.errors.requestDTO?.tenDiaDiem)}
                                               value={formik.values.requestDTO.tenDiaDiem} name="requestDTO.tenDiaDiem"
                                               onChange={formik.handleChange}/>
                                    {
                                        formik.touched.requestDTO?.tenDiaDiem && Boolean(formik.errors.requestDTO?.tenDiaDiem) &&
                                        <FormHelperText className="-error"
                                        >{formik.errors.requestDTO?.tenDiaDiem}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel  required>địa chỉ</InputLabel>
                                    <InputBase fullWidth
                                               error={formik.touched.requestDTO?.diaChi && Boolean(formik.errors.requestDTO?.diaChi)}
                                               value={formik.values.requestDTO.diaChi} name="requestDTO.diaChi"
                                               onChange={formik.handleChange}/>
                                    {
                                        formik.touched.requestDTO?.diaChi && Boolean(formik.errors.requestDTO?.diaChi) &&
                                        <FormHelperText className="-error"
                                        >{formik.errors.requestDTO?.diaChi}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} className="gridItem">
                                    <Box marginTop="5px">
                                        <Button type="submit" variant="contained" color="primary" disableElevation
                                        >
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="secondary" disableElevation
                                                onClick={() => {
                                                    formik.resetForm({values: initDefaultValue,});
                                                }}
                                        > Làm mới
                                        </Button>
                                        <Button variant="contained" color="primary" disableElevation
                                                onClick={props.onDialogClose}>
                                            Đóng
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default DiaDiemBLRuouModal;
