import React, { useEffect } from 'react';
import { Box, Grid, InputLabel } from '@material-ui/core';
import moment from "moment";
import { HoSoThuLyLPGChaiDTO } from "../../../../api/LPGChai/model/HoSoThuLyLPGChai";

interface ThongTinGPComponentComponentProps {
    data: HoSoThuLyLPGChaiDTO;
}

const ThongTinChungGPComponent = (props: ThongTinGPComponentComponentProps) => {

    const [data, setData] = React.useState({} as HoSoThuLyLPGChaiDTO);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <>
            {
                <Box padding={0}>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Số giấy phép</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soGiayPhep}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.ngayCapPhep != 0 && data?.ngayCapPhep > 0 ? moment(data?.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Tên thương nhân</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.tenThuongNhan}</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Địa chỉ</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.diaChiDayDu}</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Điện thoại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soDienThoai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Fax</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.fax}</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Số Giấy CNĐKDN/CNĐKKD</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soGiayCNDKKD}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.ngayCapCNDKKD != null && data?.ngayCapCNDKKD > 0) ? moment(data?.ngayCapCNDKKD).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Nơi cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.noiCapCNDKKD}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Mã số thuế</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.maSoThue}</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Tình trạng</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenTinhTrang}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Lần cấp lại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.lanCap}</InputLabel>
                        </Grid>
                    </Grid>
                </Box>
            }
        </>
    )
};
export default ThongTinChungGPComponent;
