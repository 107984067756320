import React, { Dispatch, useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Box, Button, IconButton, NativeSelect } from '@material-ui/core';
import { TBSanXuatRuouDTO } from "../../../../../../api/CPBLR/model/TBSanXuatRuouDTO";
import { CustomInput } from "../../../../../commons/components/input/customInput";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Cell, Row } from "../../../../../commons/components/table/tableCommons";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import TableContainer from "@material-ui/core/TableContainer";
import { hideLoading, LoadingAction, showLoading } from "../../../../../../redux/actions/applicationAction";
import { useDispatch } from "react-redux";
import { AlertMessage } from "../../../../../commons/components/alert";
import { TBPhanPhoiRuouDTO } from "../../../../../../api/CPBLR/model/TBPhanPhoiRuouDTO";
import PhanPhoiRuouModal from "../Modal/PhanPhoiRuouModal";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConfirmationDialogRaw } from '../../../../../commons/components/dialog/confirmDialog';

interface TablePhanPhoiRuouProps {
    data: TBPhanPhoiRuouDTO[];
    onUpdateData?: (memData: TBPhanPhoiRuouDTO, isEdit: Boolean, isDelete: Boolean) => void;
    view?: boolean;
}
const TablePhanPhoiRuouComponent = (props: TablePhanPhoiRuouProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataTepms, setDataTepms] = useState<TBPhanPhoiRuouDTO[]>([]);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [itemSeleted, setItemSeleted] = useState<TBPhanPhoiRuouDTO>({} as TBPhanPhoiRuouDTO);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [rowDelete, setRowDelete] = useState<TBPhanPhoiRuouDTO>({} as TBPhanPhoiRuouDTO);
    const { onUpdateData } = props;

    useEffect(() => {
        setDataTepms(props.data);
        setTotal(props.data?.length);
        handleSearch({ page: 1, size: size });
    }, [props.data, total, size]);

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        handleSearch({ page: value, size: size });
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1)
        setSize(Number(event.target.value));
        handleSearch({ page: 1, size: Number(event.target.value) });
    }

    const handleSearch = (search: any) => {
        setRows(dataTepms?.slice((search.page - 1) * search.size, search.page * search.size));
    }

    const showAddDialog = async () => {
        dispatchLoading(showLoading());
        setOpenDialog(true);
        setOpenEdit(false);
        dispatchLoading(hideLoading());
    }

    const showEditDialog = async (item: TBPhanPhoiRuouDTO) => {
        dispatchLoading(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        setOpenEdit(true);
        dispatchLoading(hideLoading());
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleAddOrUpdate = (memData: TBPhanPhoiRuouDTO, isEdit: Boolean) => {
        let mems = [...dataTepms];
        if (isEdit) {
            const idx = dataTepms.findIndex(e => e.ngayTao === memData.ngayTao);
            dataTepms[idx] = memData;
        } else {
            let mem = { ...memData, ngayTao: new Date().getTime() };
            mems.unshift(mem);
        }
        if (onUpdateData) {
            onUpdateData(memData, isEdit, false);
        }

        setOpenDialog(false);
    }

    const onDeleteMems = () => {
        if (onUpdateData) {
            onUpdateData(rowDelete, false, true);
        }

    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            onDeleteMems();
        }
        setOpenConfirmDelete(!openConfirmDelete);
    }
    const handleDeleteRow = (memData: TBPhanPhoiRuouDTO) => {
        setOpenConfirmDelete(true);
        setRowDelete(memData);
    }
    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            {openDialog &&
                <PhanPhoiRuouModal onSubmitData={handleAddOrUpdate} openDialog={openDialog} onDialogClose={() => { setOpenDialog(false) }} itemEdit={itemSeleted} openEdit={openEdit} />
            }
            <ConfirmationDialogRaw id="confirm-delete" keepMounted open={openConfirmDelete}
                title="Xóa thông tin"
                content="Bạn có đồng ý xóa?"
                onClose={handleCloseDeleteInfo}
            />
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="30%">Tên công ty/ Nhà cung cấp</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Loại rượu</Cell>
                        <Cell component="th" scope="row" align="center" width="40%">Địa chỉ</Cell>
                        <Cell hidden={props.view} component="th" scope="row" align="center" width="10%">Thao tác</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {((page - 1) * size) + i + 1}
                                    </Cell>
                                    <Cell align="left">{row.tenCongTy}</Cell>
                                    <Cell align="left">{row.loaiRuou}</Cell>
                                    <Cell align="left">{row.diaChi}</Cell>
                                    <Cell hidden={props.view} align="center">
                                        <Box display="flex" justifyContent="center">
                                            <IconButton title="Chỉnh sửa" color="primary" aria-label="edit" size="small" onClick={() => { showEditDialog(row) }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton title="Xóa" color="secondary" aria-label="delete" size="small"
                                                onClick={() => { handleDeleteRow(row) }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Cell>
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={6} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            {
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={Math.ceil(total / size)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {((page - 1) * size) + 1} - {((page - 1) * size) + size} / {page}</Box>
                    <Button hidden={props.view} onClick={showAddDialog} variant="contained" color="primary" disableElevation>
                        Thêm mới
                    </Button>
                </Box>
            }
        </TableContainer>
    )
}
export default TablePhanPhoiRuouComponent;