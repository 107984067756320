import {ThanhVienGopVonDTO} from "../model/thanhVienGopVonDTO";


export const THANH_VIEN_GOP_VON_INIT : ThanhVienGopVonDTO  = {
    id: 0,
    hoSoThuLyId: 0,
    hoTen: '',
    maGioiTinh: '',
    tenGioiTinh: '',
    ngaySinh: 0,
    loaiGiayTo: -1,
    tenGiayTo: '',
    soGiayToTuyThan: '',
    ngayCap: 0,
    noiCap: '',
    ngayHetHan: 0,
    hoKhau: '',
    choO: '',
    giaTriGopVon: 0,
    loaiTaiSan: '',
    tenTaiSan: '',
    tyLe: 0,
    soLuong: 0,
    giaTriTaiSanGopVon: 0,
    thoiDiemGopVon: '',
    maDanToc: '',
    tenDanToc: '',
    maQuocTich: '',
    tenQuocTich: '',
    ngayTao: 0,
    ngayCapNhat: 0
}