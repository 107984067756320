import AXIOS_INSTANCE from "../index";
export default class DonViTuVanService {
    constructor(){}
    insertOrUpdate  = async (requestBody) =>{
       return this.insert(requestBody);
    }
    insert = async (requestBody)=> {
        let result = false ;
        await AXIOS_INSTANCE.post('/cpxd/don-vi-tu-van/add-or-update', requestBody, this.getTokenRequestHeaders())
        .then(response => {
            if (response.status === 200) {
               
                result = true;
            }else{
                result = false ;
            }
        }).catch((error) => {
            result = false ;
        });
        return result;
    }
    getTokenRequestHeaders = () => {
        return {
            headers: {
                "Content-Type": "application/json"
            }
        };
    };

    timKiem = (requestBody) =>{
        return AXIOS_INSTANCE.post('/cpxd/don-vi-tu-van/tim-kiem', requestBody, this.getTokenRequestHeaders())
    }

    delete = async (id)=> {
        let result = false ;
        await AXIOS_INSTANCE.post('/cpxd/don-vi-tu-van/xoa/' + id, this.getTokenRequestHeaders())
        .then(response => {
            if (response.data.error_code === 'SUCCESSFUL') {
                result = true;
            }else{
                result = false ;
            }
        }).catch((error) => {
            result = false ;
        });
        return result;
    }

}