import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        backgroundColor: "#367FA9",
        padding: "15px 30px",
        fontSize: "14px",
        color: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        '& > div *': {
            display: "flex",
            alignItems: "center",
        },

    },
    footerItem: {
        '& > span': {
            marginTop: "10px",
        }

    }
});

export const Footer = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {/* <div className={classes.footerItem}>
                <span>Lượt truy cập: 123456</span>
                <span>Trực tuyến: 850</span>
            </div> */}

            <div className={classes.footerItem} style={{ flexGrow: 1 }}>
                <span>CHÍNH QUYỀN ĐIỆN TỬ THÀNH PHỐ CẦN THƠ</span>
                <span>
                    © BẢN QUYỀN THUỘC SỞ THÔNG TIN TRUYỀN THÔNG CẦN THƠ
                </span>
            </div>
            <div className={classes.footerItem} style={{marginRight : '10px'}}>
                <a href="https://tinnhiemmang.vn/danh-ba-tin-nhiem/congchuccanthogovvn-1636962598" title="Chung nhan Tin Nhiem Mang" target="_blank"><img src="https://tinnhiemmang.vn/handle_cert?id=congchuc.cantho.gov.vn" width="150px" height="auto" alt="Chung nhan Tin Nhiem Mang"></img></a>
            </div>
            <div className={classes.footerItem}>
                <span>Hỗ trợ trực tuyến: 0292.3762.333</span>
                <span>Email: baotri1cua@cantho.gov.vn</span>
            </div>
        </div>
    )
}