import {Box, IconButton, NativeSelect} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import React, {useEffect, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import {Cell, Row} from "../../../commons/components/table/tableCommons";
import {CustomInput} from "../../../commons/components/input/customInput";
import {HoSoThuLyDTO} from "../../../../api/DKKD/model/hoSoThuLyDTO";

interface IModalProps {
    openEditDialog: (itemSelected: HoSoThuLyDTO) => void;
    open: boolean,
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
interface IDataListProps {
    dataList: any[];
    deleteItem?: (id: string) => void;
    pageProps: TablePaginationActionsProps;
    modalEdit: IModalProps;
}

export const DanhSachHoSoCapSoGPKD = (props: IDataListProps) => {

    const [rows, setRows] = useState<any[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1);

    let { url } = useRouteMatch();
    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.page);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Mã hồ sơ</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Số giấy phép</Cell>
                        <Cell component="th" scope="row" align="center" width="25%">Tên hộ kinh doanh</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Chủ thể thành lập hộ kinh doanh</Cell>
                        <Cell component="th" scope="row" align="center" width="35o%">Địa chỉ </Cell>
                        <Cell component="th" scope="row" align="center" width="5%">Thao tác</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.length > 0 ?
                            rows.map((row, i) => (
                            <Row key={row.id}>
                                <Cell scope="row" align="center">
                                    {((props.pageProps.page -1) * props.pageProps.rowsPerPage) + i + 1}
                                </Cell>
                                <Cell align="left">{row.maHoSo}</Cell>
                                <Cell align="left">{row.soGiayPhep}</Cell>
                                <Cell align="left">{row.tenHoKinhDoanh}</Cell>
                                <Cell align="center">{row.loaiChuThe == 1 ? 'Nhóm cá nhân' : 'Cá nhân'}</Cell>
                                <Cell align="left">{row.diaChiDayDu}</Cell>
                                <Cell align="center">
                                    <Box display="flex" justifyContent="center">
                                        <IconButton color="primary" aria-label="edit" size="small" onClick={() => { props.modalEdit.openEditDialog(row) }}>
                                            <EditIcon />
                                        </IconButton>
                                    </Box>
                                </Cell>
                            </Row>
                        )) :
                        <Row>
                            <Cell colSpan={7} scope="row" align="center"> Không có dữ liệu</Cell>
                        </Row>
                    }
                </TableBody>
            </Table>
            {rows.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined" page={props.pageProps.page}
                                size="medium"
                                color="primary" shape="rounded" showFirstButton showLastButton onChange={props.pageProps.onChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {(props.pageProps.page * rowsPerPage)} - {props.pageProps.rowsPerPage * rowsPerPage > props.pageProps.count ? props.pageProps.count : (props.pageProps.rowsPerPage * rowsPerPage)} / {props.pageProps.count}</Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
