import {Button, Grid} from '@material-ui/core';
import {useHistory} from 'react-router';
import { BieuMauAttachFileDTO } from '../../../../api/QuanTri/Model/danhMucBieuMau';
import InBieuMauButton from '../thuly/InBieuMauButton';

interface NhapDauKyButtonProps {
    showIn?: boolean;
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    onSubmit: () => void;
    onDelete: () => void;
}
 const NhapDauKyButtonComponent = (props: NhapDauKyButtonProps) => {
    const {showIn,onDownloadFileBieuMau, onSubmit, onDelete } = props;
    const history = useHistory();
    return (
        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
            <Button onClick={onSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
            {
                showIn &&
                <InBieuMauButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
            }
            <Button onClick={onDelete} variant="contained" color="secondary" disableElevation> Xóa</Button>
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
        </Grid>
    )
}
export default NhapDauKyButtonComponent;
