import { useEffect, useState } from 'react';
import { CapPhepXayDungService } from '../../../../../api/CPXD/CapPhepXayDungService';
import { GiayPhepXayDungDTO, XacMinhThucDiaDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { Box, Grid, InputLabel, Snackbar, Typography, InputBase } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import NhaORiengLeChuDauTuComponent from '../Table/NhaORiengLeChuDauTuComponent';
import { GIAY_PHEP_XAY_DUNG_INIT, XAC_MINH_THUC_DIA_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import SearchIcon from '@material-ui/icons/Search';
import NhaORiengLeCapMoiNoiDungComponent from './NhaORiengLeCapMoiNoiDungComponent';
import StringUtil from '../../../../utils/stringUtils';
import ModalKiemTraNganChans from '../../common/modal/ModalKiemTraNganChans';
import { LOAIBIENDONG_CAPMOI, LOAIGIAYPHEP_NORL, TINHTRANG_HOATDONG } from '../../../../Constants/CapPhepXayDungConstants';
import GiayPhepXayDungButtonComponent from '../Action/GiayPhepXayDungButtonComponent';
import { useLocation } from 'react-router';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import ThongTinThamDinhTable from '../Table/ThongTinThamDinhTable';
import { BieuMauAttachFileDTO } from '../../../../../api/QuanTri/Model/danhMucBieuMau';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import ModalXacMinhThucDia from '../../common/modal/ModalXacMinhThucDia';
import GiayPhepXayDungDauKyButtonComponent from '../Action/GiayPhepXayDungDauKyButtonComponent';
import { ThongTinChuyenNhanhUtil } from '../../../../utils/thongTinChuyenNhanhUtil';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import DateUtil from '../../../../utils/dateUtils';
import { getObject } from '../../../../../helpers/localStorage';
import GiayToVeDatDaiModal from '../Modal/GiayToVeDatDaiModal';
import CanCuModal from '../Modal/CanCuModal';
import DeXuatModal from '../Modal/DeXuatModal';
import QuyHoachModal from '../Modal/QuyHoachModal';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const NhaORiengLeCapMoiComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation();

    const [data, setData] = useState<GiayPhepXayDungDTO>({ ...GIAY_PHEP_XAY_DUNG_INIT, maLoaiCongTrinh: "11", tenLoaiCongTrinh: "Nhà ở riêng lẻ", maPhanLoaiCongTrinh: "1A", tenPhanLoaiCongTrinh: "Nhà ở riêng lẻ", });
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);

    const capPhepXayDungService = new CapPhepXayDungService();
    const [openDialog, setOpenDialog] = useState(false);
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [openDialogThucDia, setOpenDialogThucDia] = useState(false);
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const [openGiayToVeDatDaiModal, setOpenGiayToVeDatDaiModal] = useState(false);
    const [openCanCuModal, setOpenCanCuModal] = useState(false);
    const [openDeXuatModal, setOpenDeXuatModal] = useState(false);
    const [openQuyHoachModal, setOpenQuyHoachModal] = useState(false);


    const handleGiayToVeDatDaiModalToggle = () => {
        setOpenGiayToVeDatDaiModal(!openGiayToVeDatDaiModal);
    }

    const handleCanCuModalToggle = () => {
        setOpenCanCuModal(!openCanCuModal);
    }

    const handleDeXuatModalToggle = () => {
        setOpenDeXuatModal(!openDeXuatModal);
    }

    const handleQuyHoachModalToggle = () => {
        setOpenQuyHoachModal(!openQuyHoachModal);
    }

    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        let nhapDauKy = location?.state?.isNhapDauKy;
        let giayPhepXayDaCapId = location?.state?.giayPhepXayDaCapId;
        setIsNhapDauKy(nhapDauKy);

        if (nhapDauKy) {
            if (giayPhepXayDaCapId > 0) {
                capPhepXayDungService.getGiayPhepXayDungDaCapById(giayPhepXayDaCapId).then(async (res) => {
                    if (res?.data?.data?.giayPhepXayDungDTO !== null && res?.data?.data?.giayPhepXayDungDTO !== undefined) {
                        const dataNew = {
                            ...res?.data?.data?.giayPhepXayDungDTO,
                        }
                        setData(dataNew);
                    }
                }).catch(error => {
                    console.log(error);
                });
            } else {
                const dataNew = {
                    ...GIAY_PHEP_XAY_DUNG_INIT,
                    maHoSo: location?.state?.maHoSo,
                    maThuTuc: location?.state?.maThuTuc,
                    tenLoaiGiayPhep: LOAIGIAYPHEP_NORL.ten,
                    maLoaiGiayPhep: LOAIGIAYPHEP_NORL.ma,
                    tenLoaiBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maLoaiBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenTrangThai: TINHTRANG_HOATDONG.ten,
                    maTrangThai: TINHTRANG_HOATDONG.ma,
                    maTinh: "92",
                    tenTinh: "Thành phố Cần Thơ",
                    maLoaiCongTrinh: "11",
                    tenLoaiCongTrinh: "Nhà ở riêng lẻ",
                    maPhanLoaiCongTrinh: "1A",
                    tenPhanLoaiCongTrinh: "Nhà ở riêng lẻ",
                    maHuyen: C_USER_DATA.maQuanHuyen,
                    tenHuyen: C_USER_DATA.tenQuanHuyen,
                }
                setData(dataNew);
            }
        } else {
            capPhepXayDungService.getByMaHoSo({ maHoSo: location.state?.maHoSo }).then(async(res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    const dataNew = {
                        ...res?.data?.data,
                        maHoSo: location?.state?.maHoSo,
                        maThuTuc: location?.state?.maThuTuc,
                        tenLoaiGiayPhep: LOAIGIAYPHEP_NORL.ten,
                        maLoaiGiayPhep: LOAIGIAYPHEP_NORL.ma,
                        tenLoaiBienDong: LOAIBIENDONG_CAPMOI.ten,
                        maLoaiBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        maLoaiCongTrinh: "11",
                        tenLoaiCongTrinh: "Nhà ở riêng lẻ",
                        maPhanLoaiCongTrinh: "1A",
                        tenPhanLoaiCongTrinh: "Nhà ở riêng lẻ",
                    }
                    setData(dataNew);
                } else {
                    let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(location?.state?.maHoSo);
                    let maQuan = "";
                    let tenQuan = "";
                    let maPhuong = "";
                    let tenPhuong = "";
                    if (!StringUtil.isNullOrEmty(dataEform?.quanhuyen)) {
                        let dataQuan = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                        maQuan = dataQuan[0].code;
                        tenQuan = dataQuan[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.xaphuong)) {
                        let dataHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                        maPhuong = dataHuyen[0].code;
                        tenPhuong = dataHuyen[0].name;
                    }
                    const dataNew = {
                        ...GIAY_PHEP_XAY_DUNG_INIT,
                        maHoSo: location?.state?.maHoSo,
                        maThuTuc: location?.state?.maThuTuc,
                        tenLoaiGiayPhep: LOAIGIAYPHEP_NORL.ten,
                        maLoaiGiayPhep: LOAIGIAYPHEP_NORL.ma,
                        tenLoaiBienDong: LOAIBIENDONG_CAPMOI.ten,
                        maLoaiBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        maTinh: "92",
                        tenTinh: "Thành phố Cần Thơ",
                        maHuyen: dataEform?.quanhuyen ? dataEform?.quanhuyen : C_USER_DATA.maQuanHuyen,
                        tenHuyen: tenQuan != "" ? tenQuan : C_USER_DATA.tenQuanHuyen,
                        maPhuong: dataEform?.xaphuong ? dataEform?.xaphuong : GIAY_PHEP_XAY_DUNG_INIT.maPhuong,
                        tenPhuong: tenPhuong,
                        tenDuong: dataEform?.diadiem ? dataEform?.diadiem : GIAY_PHEP_XAY_DUNG_INIT.tenDuong,
                        maLoaiCongTrinh: "11",
                        tenLoaiCongTrinh: "Nhà ở riêng lẻ",
                        maPhanLoaiCongTrinh: "1A",
                        tenPhanLoaiCongTrinh: "Nhà ở riêng lẻ",
                    }
                    setData(dataNew);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else if (name === "HetHan") {
            let dataNew = {
                ...data,
                ['ngayCapPhepCu']: value,
                ['ngayHetHan']: DateUtil.getOneYear(value)
            }
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const setParams = (event: any) => {
        setTabRequired(-1);
        let name = event.target.name;
        let value = event.target.value;
        let dataNew = { ...data, [name]: value };
        setData(dataNew);
    }
    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (isNhapDauKy) {
            if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
                setTabRequired(0);
                return;
            }
            if (data.ngayCapPhepCu === null || !data.ngayCapPhepCu || data.ngayCapPhepCu === undefined || data.ngayCapPhepCu === 0) {
                setTabRequired(0);
                return;
            }
            // if (data.ngayHetHan === null || !data.ngayHetHan || data.ngayHetHan === undefined || data.ngayHetHan === 0) {
            //     setTabRequired(0);
            //     return;
            // }
        }
        if (StringUtil.isNullOrEmty(data.tenCongTrinh)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.maTinh)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHuyen)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuong)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.maCapCongTrinh)) {
            setTabRequired(0);
            return;
        }
        // if (StringUtil.isNullOrEmty(data.tenDuong)) {
        //     setTabRequired(0);
        //     return;
        // }
        if (StringUtil.isNullOrEmty(data.maLoaiCongTrinh)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhanLoaiCongTrinh)) {
            setTabRequired(0);
            return;
        }
        //HienLT52 - Cập nhật giá trị = 0 khi không nhập
        if (data.giayToQuyenSuDungDat != null && StringUtil.isNullOrEmty(data.giayToQuyenSuDungDat.dienTichKhuonVien)) {
            data.giayToQuyenSuDungDat.dienTichKhuonVien = 0;
        }
        if (data.giayToQuyenSuDungDat != null && StringUtil.isNullOrEmty(data.giayToQuyenSuDungDat.dienTichKhuonVienHienHuu)) {
            data.giayToQuyenSuDungDat.dienTichKhuonVienHienHuu = 0;
        }
        if (data.giayToQuyenSuDungDat != null && StringUtil.isNullOrEmty(data.giayToQuyenSuDungDat.dienTichBanCong)) {
            data.giayToQuyenSuDungDat.dienTichBanCong = 0;
        }
        if (data.giayToQuyenSuDungDat != null && StringUtil.isNullOrEmty(data.giayToQuyenSuDungDat.dienTichChenhLech)) {
            data.giayToQuyenSuDungDat.dienTichChenhLech = 0;
        }
        if (data.giayToQuyenSuDungDat != null && StringUtil.isNullOrEmty(data.giayToQuyenSuDungDat.dienTichGiuLai)) {
            data.giayToQuyenSuDungDat.dienTichGiuLai = 0;
        }
        if (data.giayToQuyenSuDungDat != null && StringUtil.isNullOrEmty(data.giayToQuyenSuDungDat.matDoGiayPhep)) {
            data.giayToQuyenSuDungDat.matDoGiayPhep = 0;
        }
        dispatchLoading(showLoading());
        if (isNhapDauKy) {
            data.xacMinhThucDiaDTO.maHoSo=location?.state?.maHoSo;
            capPhepXayDungService.insertOrUpdateNhapDauKy(data).then(res => {
                if (res != null) {
                    if (res.data.error_code == 'SUCCESSFUL') {
                        let dataNew = {
                            ...data,
                            id: parseInt(res.data.data.id),
                            giayPhepXayDungDaCapId: parseInt(res.data.data.giayPhepXayDungDaCapId)
                        }
                        setData(dataNew);
                        setMessage('Yêu cầu thực hiện thành công');
                        setSeverity("success");
                        setShowAlert(true);
                    } else if (res.data.error_code === '409') {
                        setMessage("Số giấy phép đã tồn tại");
                        setSeverity("error");
                        setShowAlert(true);
                    } else {
                        setMessage(res.data.error_message);
                        setSeverity("error");
                        setShowAlert(true);
                    }
                } else {
                    setMessage('Yêu cầu thực hiện thất bại');
                    setSeverity("error");
                    setShowAlert(true);
                }
            }).catch(error => {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        } else {
            data.xacMinhThucDiaDTO.maHoSo=location?.state?.maHoSo;
            capPhepXayDungService.insertOrUpdate(data).then(res => {
                if (res != null) {
                    if (res.data.error_code == 'SUCCESSFUL') {
                        let dataNew = {
                            ...data,
                            id: parseInt(res.data.data.id),
                            giayPhepXayDungDaCapId: parseInt(res.data.data.giayPhepXayDungDaCapId),
                        }
                        setData(dataNew);
                        setMessage('Yêu cầu thực hiện thành công');
                        setSeverity("success");
                        setShowAlert(true);
                    } else {
                        setMessage(res.data.error_message);
                        setSeverity("error");
                        setShowAlert(true);
                    }
                } else {
                    setMessage('Yêu cầu thực hiện thất bại');
                    setSeverity("error");
                    setShowAlert(true);
                }
            }).catch(error => {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }

    }


    const showMessage = (mess: string, severity: string) => {
        setMessage(mess);
        setSeverity(severity);
        setShowAlert(true);
    }

    const handleToggleModal = () => {
        setOpenDialog(!openDialog)
    }

    const handleToggleModalThucDia = () => {
        setOpenDialogThucDia(!openDialogThucDia);
        initData();
    }

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dispatchLoading(showLoading());
        if (StringUtil.isEmty(data.maHoSo)) {
            capPhepXayDungService.downloadFileByGiayPhepXayDungDaCapId({ templateId: bieuMau.fileId, giayPhepXayDungDaCapId: data.giayPhepXayDungDaCapId }).then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
            }).catch(error => {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        } else {
            capPhepXayDungService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
            }).catch(error => {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
    }
    const resetData = () => {
        setSubmit(false);
        const dataNew = {
            ...GIAY_PHEP_XAY_DUNG_INIT,
            giayPhepXayDaCapId: data.giayPhepXayDungDaCapId,
            maThuTuc: data.maThuTuc,
            maHoSo: data.maHoSo,
            id: data.id,
            tenLoaiGiayPhep: data.tenLoaiGiayPhep,
            maLoaiGiayPhep: data.maLoaiGiayPhep,
            tenLoaiBienDong: data.tenLoaiBienDong,
            maLoaiBienDong: data.maLoaiBienDong,
            tenTrangThai: data.tenTrangThai,
            maTrangThai: data.maTrangThai
        }
        setData(dataNew);

    }

    const handleUpdateData = (name : string ,value: string) => {
        let dataNew = {
            ...data,
            [name]: value
        };
        setData(dataNew);
    }

      return (
        <div className="dash-content">
            <>
                {

                    <Grid container>

                        <CanCuModal
                            onUpdateData={handleUpdateData}
                            onModalToggle={handleCanCuModalToggle}
                            show={openCanCuModal} >
                        </CanCuModal>

                        <DeXuatModal
                            onUpdateData={handleUpdateData}
                            onModalToggle={handleDeXuatModalToggle}
                            show={openDeXuatModal} >
                        </DeXuatModal>

                        <QuyHoachModal
                            onUpdateData={handleUpdateData}
                            onModalToggle={handleQuyHoachModalToggle}
                            show={openQuyHoachModal} >
                        </QuyHoachModal>

                        <GiayToVeDatDaiModal
                            onUpdateData={handleUpdateData}
                            onModalToggle={handleGiayToVeDatDaiModalToggle}
                            show={openGiayToVeDatDaiModal} >
                        </GiayToVeDatDaiModal>

                        <OverlayLoading />
                        <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                            <Alert onClose={() => setShowAlert(false)} severity={severity as Color}>{message}</Alert>
                        </Snackbar>

                        <Grid container style={{ float: 'left', paddingTop: '20px' }} item xs={12} className="grid-item" >
                            <div className="dash-title">
                                <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>CẤP GIẤY PHÉP XÂY DỰNG NHÀ Ở RIÊNG LẺ {!isNhapDauKy ? "- MÃ HỒ SƠ :  " + data.maHoSo : ""} </h6>
                            </div>
                        </Grid>
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Nội dung giấy phép" />
                                    <CustomTab style={tabRequired == 1 ? { color: 'red' } : {}} className="test" label="Chủ đầu tư" />
                                    <CustomTab style={tabRequired == 2 ? { color: 'red' } : {}} label="Giấy tờ về đất đai" />
                                    <CustomTab style={tabRequired == 3 ? { color: 'red' } : {}} label="Thông tin thẩm định" />
                                    <CustomTab style={tabRequired == 4 ? { color: 'red' } : {}} label="Thông tin đề xuất" />
                                </CustomTabs>
                            </Box>

                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <NhaORiengLeCapMoiNoiDungComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    data={data}
                                    isNhapDauKy={isNhapDauKy}
                                />
                            </TabPanel>
                        }
                        {
                            indexTab === 1 &&
                            <TabPanel value={indexTab} index={1}>
                                <NhaORiengLeChuDauTuComponent
                                    onUpdateData={setDataParam}
                                    data={data.chuDauTuDTOs}
                                />
                            </TabPanel>

                        }
                        {
                            indexTab === 2 &&
                            <TabPanel value={indexTab} index={2}>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Giấy tờ về đất đai </InputLabel>
                                        <InputBase style={{width: '90%'}}
                                            rows="8"
                                            multiline
                                            name="giayToVeDat"
                                            value={data.giayToVeDat} onChange={setParams} />
                                        <SearchIcon 
                                        fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleGiayToVeDatDaiModalToggle} />
                                    </Grid>
                                </Box>
                            </TabPanel>

                        }
                        {
                            indexTab === 3 &&
                            <TabPanel value={indexTab} index={3}>
                                <ThongTinThamDinhTable
                                    onUpdateData={setDataParam}
                                    data={data.thongTinThamDinhDTOs}
                                />
                            </TabPanel>

                        }
                        {
                            indexTab === 4 &&
                            <TabPanel value={indexTab} index={4}>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Căn cứ</InputLabel>
                                        <InputBase style={{width:'90%'}}
                                            multiline
                                            rows="8"
                                            rowsMax="8"
                                            name="ketLuan"
                                            value={data.ketLuan} onChange={setParams} />
                                            <SearchIcon 
                                        fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleCanCuModalToggle} />
                                    </Grid>
                                </Box>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Đề xuất </InputLabel>
                                        <InputBase style={{width:'90%'}}
                                            multiline
                                            rows="8"
                                            name="deXuat"
                                            value={data.deXuat} onChange={setParams} />
                                                  <SearchIcon 
                                        fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleDeXuatModalToggle} />
                                    </Grid>
                                </Box>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Quy hoạch</InputLabel>
                                        <InputBase style={{width:'90%'}}
                                            multiline
                                            rows="8"
                                            name="quyHoach"
                                            value={data.quyHoach} onChange={setParams} />
                                                  <SearchIcon 
                                        fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleQuyHoachModalToggle} />
                                    </Grid>
                                </Box>
                            </TabPanel>

                        }
                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <GiayPhepXayDungButtonComponent
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onSubmit={handleSubmit}
                                showIn={data.id > 0 && !isNhapDauKy}
                                resetData={resetData}
                                onToggleModal={handleToggleModal}
                                onToggleModalThucDia={handleToggleModalThucDia}
                            ></GiayPhepXayDungButtonComponent>
                        </Grid>
                    </Grid>
                }
                {
                    openDialog &&
                    <ModalKiemTraNganChans show={openDialog} onToggle={handleToggleModal} ></ModalKiemTraNganChans>
                }
                {
                    openDialogThucDia &&
                    <ModalXacMinhThucDia 
                    show={openDialogThucDia} 
                    onToggle={handleToggleModalThucDia}
                    id={data.xacMinhThucDiaDTO.id}
                    ></ModalXacMinhThucDia>
                }
            </>
        </div>
    )
}
export default NhaORiengLeCapMoiComponent;