import { BaseResponse, BaseService, PageRequest, Pagination } from "../../baseService";
import api from "../../index";
import { HoSoKhenThuongDTO } from "../models/hoSoKhenThuongDTO";
import { TraCuuCaNhanToChucDTO } from "../models/traCuuCaNhanToChuc";

export interface DanhSachKhenThuongRequest extends PageRequest {
    isActive?: Boolean;
}

export interface DanhSachQuyetDinhKhenThuongRequest extends PageRequest {
    isActive?: Boolean;
    soBienNhan?: String;
    thuTucId?: Number;
    maQuanHuyen?: string;
    tenQuanHuyen?: string
}

export interface TraCuuCaNhanRequest extends PageRequest {
    isActive?: Boolean;
    type?: Number;
    downloadType?: string;
    maQuanHuyen: String;
    tenQuanHuyen: String; 
}

export interface BussinessTemplateDownloadRequest {
    idChuyenNganh: number;
    idHoSoKhenThuong: number;
    filedId: string;
}

export class HoSoKhenThuongService extends BaseService {
    getBySoBienNhan = async (soBienNhan: String): Promise<HoSoKhenThuongDTO | null> => {
        let result = null;
        await api
            .get("/ho-so-khen-thuong/get", {
                params: { soBienNhan: soBienNhan },
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch(() => (result = null));
        return result;
    };

    getById = async (id: Number): Promise<HoSoKhenThuongDTO | null> => {
        let result = null;
        await api
            .get("/ho-so-khen-thuong/get", {
                params: { id: id},
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch(() => (result = null));
        return result;
    };

    insertOrUpdate = async (request: HoSoKhenThuongDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api
            .post("/ho-so-khen-thuong/insertOrUpdate", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    getListQuyetDinh = async (request: DanhSachQuyetDinhKhenThuongRequest): Promise<Pagination<HoSoKhenThuongDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: [],
        } as Pagination<HoSoKhenThuongDTO[]>;
        await api
            .get("/ho-so-khen-thuong/ds-cap-nhat-quyet-dinh", {
                params: request,
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = {
                        ...result,
                        total: response.data.data.total,
                        rows: response.data.data.content,
                    };
                }
            })
            .catch();
        return result;
    };

    updateQuyetDinhKhenThuong = async (request: HoSoKhenThuongDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api
            .post("/ho-so-khen-thuong/cap-nhat-quyet-dinh", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    loadDetailHoSoQuyetDinhKT = async (idHoSo: Number): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api
            .get(`/ho-so-khen-thuong/load-ho-so-cap-nhat-quyet-dinh/${idHoSo}`, this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    getListTraCuu = async (request: TraCuuCaNhanRequest): Promise<Pagination<TraCuuCaNhanToChucDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: [],
        } as Pagination<TraCuuCaNhanToChucDTO[]>;
        await api
            .get("/ho-so-khen-thuong/tra-cuu", {
                params: request,
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = {
                        ...result,
                        total: response.data.data.total,
                        rows: response.data.data.content,
                    };
                }
            })
            .catch();
        return result;
    };

    // exportFile = async (request: TraCuuCaNhanRequest): Promise<any> => {
    //     return api.get("/ho-so-khen-thuong/tra-cuu/export", { params: request, responseType: "blob", timeout: 30000 });
    // };

    downloadFile = (request: TraCuuCaNhanRequest) => {
        return api.get('ho-so-khen-thuong/tra-cuu/export', {
            responseType: 'blob',
            params: request
        });
    }

    huyQuyetDinhKhenThuong = async (request: HoSoKhenThuongDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api
            .post("/ho-so-khen-thuong/huy-quyet-dinh", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    loadDetailHuyQuyetDinh = async (idHoSo: Number): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api
            .get(`/ho-so-khen-thuong/load-ho-so-huy-quyet-dinh/${idHoSo}`, this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    /**
     * @description xử lý download file khi thực hiện in ấn
     * @param attachFile
     * @returns
     */
    handleDownloadTemplateFile = async (request: BussinessTemplateDownloadRequest): Promise<any> => {
        return api.get("/ho-so-khen-thuong/download-bussiness-template-file", {
            responseType: "blob",
            params: request,
        });
    };
}
