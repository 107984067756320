import { Box, Grid } from "@material-ui/core";
import { lazy, useEffect, useState } from "react";
import { CustomTab, CustomTabs } from "../../../commons/components/tabs";
const TabQuanLyDangKyKinhDoanh = lazy(() => import('../../DKKD/QuanLyDKKD/tabQuanLyDangKyKinhDoanh').then(({ TabQuanLyDangKyKinhDoanh }) => ({ default: TabQuanLyDangKyKinhDoanh })));
const TabQuanLyGiayPhepATTP = lazy(() => import('./tabQuanLyGiayPhepATTP').then(({ TabQuanLyGiayPhepATTP }) => ({ default: TabQuanLyGiayPhepATTP })));

const QuanLyGiayPhepATTP = () => {
    const [indexTab, setIndexTab] = useState(0);
    useEffect(() => {
    }, [])
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    return (
        <div className="dash-content">
             <div className="dash-title">
                <span>TRA CỨU THÔNG TIN GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM </span>
            </div>
            <br></br>
            <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={indexTab} onChange={handleChange} >
                        <CustomTab label="CHỨNG NHẬN AN TOÀN THỰC PHẨM" />
                        <CustomTab label="CHỨNG NHẬN ĐĂNG KÝ KINH DOANH CHƯA CẤP ĐỦ ĐIỀU KIỆN LĨNH VỰC ĂN UỐNG" />
                    </CustomTabs>
                </Box>

            </Grid>
           
            {
                indexTab === 0 &&
                <TabQuanLyGiayPhepATTP />
            }
            {
                indexTab === 1 &&
                <TabQuanLyDangKyKinhDoanh traCuuATTP  isAnUong = {true} />
            }
        </div >
    )
}
export default QuanLyGiayPhepATTP;