import { lazy } from 'react';
import { DanhMucBieuMau } from "../pages/quanTriHeThong/danhMucBieuMau";
import { ROUTE_CN_CPXD, ROUTE_CPXD_NHAPDAUKY, ROUTE_DS_CPXD, ROUTE_GUEST_CPXD, ROUTE_PAGE_CPXD } from './route-cn-cpxd';
import { ROUTE_CN_ATTP, ROUTE_DS_ATTP, ROUTE_GUEST_ATTP, ROUTE_PAGE_ATTP } from './route-cn-attp';
import { ROUTE_CN_PAGE, ROUTE_CN_RUOU, ROUTE_DS_RUOU, ROUTE_GUEST_RUOU } from './route-cn-ruou';
import { ROUTE_CN_DKKD, ROUTE_DKKD_GUEST, ROUTE_DS_DKKD, ROUTE_PAGE_DKKD } from './route-cn-dkkd';
import { ROUTE_CN_TL, ROUTE_DS_TL, ROUTE_PAGE_TL, ROUTE_TL_GUEST } from './route-cn-tl';
import { ROUTE_CN_LPG, ROUTE_DS_LPG, ROUTE_PAGE_LPG } from './route-cn-lpg';
import { ROUTE_CN_DAU_KY_TDKT, ROUTE_CN_TDKT, ROUTE_DS_TDKT, ROUTE_PAGE_TDKT } from './route-cn-tdkt';
import { DongBoMotCuaComponent } from '../pages/quanTriHeThong/DongBoMotCua/DongBoMotCuaComponent';
import { DongBoDuLieuComponent } from '../pages/quanTriHeThong/DongBoDuLieu/DongBoDuLieuComponent';
import { ImportDauKyComponent } from '../pages/importDauKy/ImportDauKyComponent';
import { DongBoCanBoCongChucComponent } from '../pages/quanTriHeThong/DongBoCanBo/DongBoCanBoCongChucComponent';
import { UserManagement } from '../pages/quanTriHeThong/user/mgt/userManagement';
import { ProfileUpdate } from '../pages/quanTriHeThong/user/update/profileUpdate';
import { PasswordManagement } from '../pages/quanTriHeThong/user/passwordMgt/passwordManagement';
import { DanhMucCoQuanComponent } from '../pages/quanTriHeThong/coQuanHanhChinh/main';
import { TraCuuThongTinCongDan } from '../App/Components/CMND/TraCuuThongTinCongDan';
const HoSoCuaToiMgt = lazy(() => import('../pages/hoSoCuaToi/hoSoCuaToiMgt').then(({ HoSoCuaToiMgt }) => ({ default: HoSoCuaToiMgt })));
const CauHinhNghiepVuMgt = lazy(() => import('../pages/quanTriHeThong/cauHinhNghiepVu/cauHinhNghiepVuMgt').then(({ CauHinhNghiepVuMgt }) => ({ default: CauHinhNghiepVuMgt })));
const HuongDanSuDungComponent = lazy(() => import('../pages/quanTriHeThong/huongDanSuDung/huongDanSuDungComponent').then(({ HuongDanSuDungComponent }) => ({ default: HuongDanSuDungComponent })));
const MenuRoleManagement = lazy(() => import('../pages/quanTriHeThong/Menu/MenuRoleManagement').then(({ MenuRoleManagement }) => ({ default: MenuRoleManagement })));
const GroupRoleManagementComponent = lazy(() => import('../pages/quanTriHeThong/NhomVaiTro/GroupRoleManagementComponent').then(({ GroupRoleManagementComponent }) => ({ default: GroupRoleManagementComponent })));
const PhanQuyenNguoiDungComponentV2 = lazy(() => import('../pages/quanTriHeThong/PhanQuyenNguoiDung/PhanQuyenNguoiDungComponentV2').then(({ PhanQuyenNguoiDungComponentV2 }) => ({ default: PhanQuyenNguoiDungComponentV2 })));
const RoleManagementComponent = lazy(() => import('../pages/quanTriHeThong/VaiTro/RoleManagementComponent').then(({ RoleManagementComponent }) => ({ default: RoleManagementComponent })));

export interface RoutePropsI {
    ten: string;
    giaoDien: any;
    duongDan: string;
    routes?: RoutePropsI[];
}
export const ROUTE_PAGE_HOSOCUATOI = {
    ten: "HỒ SƠ CỦA TÔI",
    duongDan: '/ho-so-cua-toi',
    giaoDien: HoSoCuaToiMgt,
}

export const ROUTE_PAGE_TRA_CUU_CMND = {
    ten: "TRA CỨU THÔNG TIN CÔNG DÂN",
    duongDan: '/tra-cuu-thong-tin-cong-dan',
    giaoDien: TraCuuThongTinCongDan,
}

export const ROUTE_CN: RoutePropsI[] = [
    // LPGChai
    ...ROUTE_CN_LPG,
    // ATVSTP
    ...ROUTE_CN_ATTP,
    //[DKKD]
    ...ROUTE_CN_DKKD,
    // [CPXD]
    ...ROUTE_CN_CPXD,
    //[SXKDR]
    ...ROUTE_CN_RUOU,
    //[BLTL]
    ...ROUTE_CN_TL,
    //[TDKT]
    ...ROUTE_CN_TDKT,
    ...ROUTE_CN_DAU_KY_TDKT,
    ...ROUTE_DKKD_GUEST
];

export const ROUTE_CN_CAUHINH: RoutePropsI[] = [
    // LPGChai
    ...ROUTE_CN_LPG,
    // ATVSTP
    ...ROUTE_CN_ATTP,
    //[DKKD]
    ...ROUTE_CN_DKKD,
    // [CPXD]
    ...ROUTE_CN_CPXD,
    //[SXKDR]
    ...ROUTE_CN_RUOU,
    //[BLTL]
    ...ROUTE_CN_TL,
    //[TDKT]
    ...ROUTE_CN_TDKT,
    ...ROUTE_DKKD_GUEST
];

export const ROUTE_CN_IN: RoutePropsI[] = [
    // LPGChai
    ...ROUTE_CN_LPG,
    // ATVSTP
    ...ROUTE_CN_ATTP,
    //[DKKD]
    ...ROUTE_CN_DKKD,
    // [CPXD]
    ...ROUTE_CN_CPXD,
    //[SXKDR]
    ...ROUTE_CN_RUOU,
    //[BLTL]
    ...ROUTE_CN_TL,
    //[TDKT]
    ...ROUTE_CN_TDKT,
    ...ROUTE_CN_DAU_KY_TDKT,
    ...ROUTE_DKKD_GUEST
];

export const ROUTE_PAGE: RoutePropsI[] = [
    ROUTE_PAGE_HOSOCUATOI,
    ROUTE_PAGE_TRA_CUU_CMND,
    // LPGChai
    ...ROUTE_PAGE_LPG,
    //DKKD
    ...ROUTE_PAGE_DKKD,
    //[CPXD]
    ...ROUTE_PAGE_CPXD,
    //[SXKDR]
    ...ROUTE_CN_PAGE,
    //BLTL
    ...ROUTE_PAGE_TL,
    //[ATTP]
    ...ROUTE_PAGE_ATTP,
    //[Thi đua khen thưởng]
    ...ROUTE_PAGE_TDKT,
    {
        ten: 'Hướng dẫn sử dụng',
        giaoDien: HuongDanSuDungComponent,
        duongDan: '/huong-dan-su-dung',
    },
    {
        ten: 'Cấu hình cơ quan',
        giaoDien: DanhMucCoQuanComponent,
        duongDan: '/cau-hinh-co-quan',
    },
    {
        ten: 'Cấu hình nghiệp vụ',
        giaoDien: CauHinhNghiepVuMgt,
        duongDan: '/cau-hinh-nghiep-vu',
    },
    {
        ten: 'Phân quyền người dùng',
        giaoDien: PhanQuyenNguoiDungComponentV2,
        duongDan: '/phan-quyen-nguoi-dung',
    },
    {
        ten: 'Quản lý vai trò',
        giaoDien: RoleManagementComponent,
        duongDan: '/quan-ly-vai-tro',
    },
    {
        ten: 'Quản lý nhóm vai trò',
        giaoDien: GroupRoleManagementComponent,
        duongDan: '/quan-ly-nhom-vai-tro',
    },
    {
        ten: 'Quản lý menu',
        giaoDien: MenuRoleManagement,
        duongDan: '/quan-ly-menu',

    },
    {
        ten: 'Đồng bộ một cửa',
        giaoDien: DongBoMotCuaComponent,
        duongDan: '/dong-bo-mot-cua',
    },
    {
        ten: 'Đồng bộ dữ liệu',
        giaoDien: DongBoDuLieuComponent,
        duongDan: '/dong-bo-du-lieu',
    },
    {
        ten: 'Import đầu kỳ',
        giaoDien: ImportDauKyComponent,
        duongDan: '/import-dau-ky',
    },
    {
        ten: 'DM biểu mẫu',
        giaoDien: DanhMucBieuMau,
        duongDan: '/danh-muc-bieu-mau',
    },
    {
        ten: 'Đồng bộ người dùng',
        giaoDien: DongBoCanBoCongChucComponent,
        duongDan: '/dong-bo-nguoi-dung',
    },
    {
        ten: 'Quản trị người dùng',
        giaoDien: UserManagement,
        duongDan: '/quan-tri-nguoi-dung',
    }
    
    
]
export const ROUTE_ADMIN: RoutePropsI[] = [
   
];

export const ROUTE_GUEST_ADMIN : RoutePropsI[] =[
    {
        ten: 'Thông tin người dùng',
        giaoDien: ProfileUpdate,
        duongDan: '/user-info',
    },
    {
        ten: 'Quản lý mật khẩu',
        giaoDien: PasswordManagement,
        duongDan: '/password-mgt',
    },
]

export const ROUTE_GUEST: RoutePropsI[] = [
    ...ROUTE_GUEST_ATTP,
    ...ROUTE_GUEST_RUOU,
    ...ROUTE_CPXD_NHAPDAUKY,
    ...ROUTE_DKKD_GUEST,
    ...ROUTE_GUEST_CPXD,
    ...ROUTE_TL_GUEST,
    ...ROUTE_GUEST_ADMIN
];

export const ROUTE_PAGE_DS: RoutePropsI[] = [
    // ATTP
    ...ROUTE_DS_ATTP,
    // CPXD
    ...ROUTE_DS_CPXD,
    // DKKD
    ...ROUTE_DS_DKKD,
    // LGPCHAI
    ...ROUTE_DS_LPG,
    // RUOU
    ...ROUTE_DS_RUOU,
    // TDKT
    ...ROUTE_DS_TDKT,
    // TL
    ...ROUTE_DS_TL
]
    

