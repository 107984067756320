import React, { useEffect } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { BrowserHistory } from "history";
import { CustomTab, CustomTabs } from "../../../commons/components/tabs";
import { HoSoThuLyDTO } from "../../../../api/DKKD/model/hoSoThuLyDTO";
import ThongTinGPComponent from "./ThongTinGPComponent";
import { ThanhVienGopVon } from "../Modal/thanhVienGopVon";
import { DangKyKinhDoanhService } from "../../../../api/DKKD/DangKyKinhDoanhService";
import { LichSuGiayPhepComponent } from '../../../commons/components/hoso/lichsu/LichSuGiayPhepComponent';

type StateHoSo = {
    hoSoThuLyId: number
}
type GiayPhepKDProps = {
    history: BrowserHistory<StateHoSo>
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const GiayPhepKDComponent = (props: GiayPhepKDProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [data, setData] = React.useState({} as HoSoThuLyDTO);

    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const { history } = props;

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        dangKyKinhDoanhService.getChiTietGPKDById({ hoSoThuLyId: history?.location?.state?.hoSoThuLyId }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setData(res?.data?.data);
            }
        }).catch(error => {
            console.log(error);
        });

    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    return (
        <>
            {
                <Paper square >
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                XEM THÔNG TIN CHI TIẾT GIẤY PHÉP KINH DOANH
                                </div>
                            </div>
                            {/* <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab label="Thông tin chung" />
                                    <CustomTab label="Thành viên góp vốn" />
                                    <CustomTab label="Lịch sử" />
                                </CustomTabs>
                            </Box>

                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <ThongTinGPComponent
                                    data={data} />
                            </TabPanel>
                        }
                        {
                            indexTab === 1 &&
                            <TabPanel value={indexTab} index={1}>
                                <ThanhVienGopVon
                                    dataList={data.nhomCaNhans}
                                />
                            </TabPanel>
                        }
                        {
                            indexTab === 2 &&
                            <TabPanel value={indexTab} index={2}>
                                <LichSuGiayPhepComponent
                                    dataList={data.lichSuGiayPheps}
                                />
                            </TabPanel>
                        }
                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Button type="button" variant="contained" color="primary"
                                onClick={(e) => history.go(-1)}
                                style={{ fontSize: '14px', width: '100px' }}>Trở về
                            </Button>&nbsp;&nbsp;
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    )
};
export default GiayPhepKDComponent;
