import { BaseResponse, BaseService, PageRequest, Pagination } from "../baseService";
import api from "../index";
import { HoSoThuLyDTO } from "./model/hoSoThuLyDTO";
import {CapSoGiayPhepKinhDoanhDTO} from "./model/BienDongTrangThaiGPKDDTO";

export interface RequestDTO extends PageRequest {
    maHoSo: string;
    tenHoKinhDoanh: string;
    maPhuong: string;
    tenPhuong: string;
    maQuan : string;
    tenQuan : string;
    loaiChuThe: number;
}

export const REQUEST_DTO_INIT : RequestDTO= {
    maHoSo: '',
    tenHoKinhDoanh: '',
    maPhuong: '',
    tenPhuong: '',
    maQuan : '',
    tenQuan : '',
    loaiChuThe: 0,
    pageSize: 5,
    pageNumber: 1,
    keywords: ''
}

export class CapSoGiayPhepKinhDoanhService extends BaseService{
    timKiem = async (request: RequestDTO): Promise<Pagination<HoSoThuLyDTO[]>> => {
        let result: Pagination<HoSoThuLyDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }

        await api.post('/dkkd/cap-so-giay-phep/danh-sach-cap-so', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    luuVaCapNhat = async (capPhep: CapSoGiayPhepKinhDoanhDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api.post('/dkkd/cap-so-giay-phep/add-or-update', JSON.stringify(capPhep), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_code == 'DUPLICATE_CODE' ? 'Số giấy phép đã được sử dụng' : response.data.error_message
            }
        }).catch(() => {
            result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' }
        });
        return result;
    }

    getCapPhepByGiayPhepId = async (json: any): Promise<CapSoGiayPhepKinhDoanhDTO> => {
        let result = {} as CapSoGiayPhepKinhDoanhDTO;
        await api.post('/dkkd/cap-so-giay-phep/get-by-giay-phep-id', JSON.stringify(json), this.getTokenRequestHeaders()).then(response => {
            if (response != null && response.data != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }
        }).catch(() => {
        });
        return result;
    }
}
