export const VIEW_PAGE = "VIEW_PAGE";
export const ROLES_ADMIN = "ADMIN_CPXD";
export const FETCH_Prevent = 'FETCH_KTNC';
export const FETCH_LAND = "FETCH_LAND";
export const FETCH_APPRAISAL = "FETCH_APPRAISAL";
export const FETCH_DTCATEGORY = "FETCH_DTCATEGORY";
export const ADD_EXPERTISE = "ADD_EXPERTISE";
export const ADD_LAND = "ADD_LAND";
export const ADD_ALL_LAND = "ADD_ALL_LAND";
export const ADD_DTCATEGORY = "ADD_DTCATEGORY";
export const ADD_ALL_DTCATEGORY = "ADD_ALL_DTCATEGORY";
export const ADD_CONSULTING_UNIT = "ADD_CONSULTING_UNIT";
export const ADD_APPRAISAL = "ADD_APPRAISAL";
export const ADD_ALL_APPRAISAL = "ADD_ALL_APPRAISAL";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_ALL_CATEGORY = "ADD_ALL_CATEGORY";
export const ADD_INVESTOR = "ADD_INVESTOR";
export const ADD_ALL_INVESTOR = "ADD_ALL_INVESTOR";
export const UPDATE_LAND = "UPDATE_LAND";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_INVESTOR = "UPDATE_INVESTOR";
export const UPDATE_CONSULTING = "UPDATE_CONSULTING";
export const UPDATE_APPRAISAL = "UPDATE_APPRAISAL";
export const UPDATE_DIALOG = "UPDATE_DIALOG";
export const UPDATE_DESIGN = "UPDATE_DESIGN";
export const DELETE_LAND = "DELETE_LAND";
export const DELETE_LAND_BY_ID = "DELETE_LAND_BY_ID";
export const DELETE_CONSULTING = "DELETE_CONSULTING";
export const DELETE_APPRAISAL = "DELETE_APPRAISAL";
export const DELETE_APPRAISAL_BY_ID = "DELETE_APPRAISAL_BY_ID";
export const DELETE_DTCATEGORY = "DELETE_DTCATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_BY_ID = "DELETE_CATEGORY_BY_ID";
export const DELETE_INVESTOR = "DELETE_INVESTOR";
export const DELETE_INVESTOR_BY_ID = "DELETE_INVESTOR_BY_ID";
export const FILTER_LAND = "FILTER_LAND";