import { BaseService, BaseResponse } from "../../baseService";
import api from "../../index";
import { BienDongTrangThaiGPVSATTPDTO } from "../Model/BienDongTrangThaiGPVSATTPDTO";
import { GiayPhepATTP, HoSoThuLyATTPDTO } from "../Model/VSATTPModel";

export interface BienDongTrangThaiGPVSATTPResponse {
    giayPhep: HoSoThuLyATTPDTO;
    bienDong: BienDongTrangThaiGPVSATTPDTO;
}
export class BienDongTrangThaiGPVSATTPService extends BaseService{
    luuVaCapNhatKhoiPhuc = async (khoiPhuc: BienDongTrangThaiGPVSATTPDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api.post('vsattp/bien-dong-trang-thai/add-or-update', JSON.stringify(khoiPhuc), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message,
                data: response.data.data
            }
        }).catch(() => {
            result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' }
        });
        return result;
    }

    getGiayPhepKinhDoanhId = async (json: any): Promise<GiayPhepATTP> => {
        let result = {} as GiayPhepATTP;
        await api.post('vsattp/bien-dong-trang-thai/get-by-giay-phep-id', json, this.getTokenRequestHeaders()).then(response => {
            if (response != null && response.data != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }
        }).catch(() => {
        });
        return result;
    }
    getLichSuByMaBienDong = async (json: any): Promise<BienDongTrangThaiGPVSATTPDTO> => {
        let result = {} as BienDongTrangThaiGPVSATTPDTO;
        await api.post('vsattp/bien-dong-trang-thai/get-by-lich-su-bien-dong', json, this.getTokenRequestHeaders()).then(response => {
            if (response != null && response.data != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }
        }).catch(() => {
        });
        return result;
    }
}