import { HoSoKhenThuongDTO, MocThoiGianEnum } from "../../../../api/KhenThuong/models/hoSoKhenThuongDTO";
import { LoaiKetQuaEnum } from "../../../../api/loaiGiayPhep/loaiKetQuaEnum";

export function stringToUUID(str: string = ""): String {
    if (str === undefined || !str.length)
        str = "" + Math.random() * new Date().getTime() + Math.random();

    let c = 0,
        r = "";

    for (let i = 0; i < str.length; i++)
        c = (c + (str.charCodeAt(i) * (i + 1) - 1)) & 0xfffffffffffff;

    str = str.substr(str.length / 2) + c.toString(16) + str.substr(0, str.length / 2);
    for (let i = 0, p = c + str.length; i < 32; i++) {
        if (i === 8 || i === 12 || i === 16 || i === 20)
            r += "-";

        c = p = (str[(i ** i + p + 1) % str.length]).charCodeAt(0) + p + i;
        if (i === 12)
            c = (c % 5) + 1; //1-5
        else if (i === 16)
            c = (c % 4) + 8; //8-B
        else
            c %= 16; //0-F

        r += c.toString(16);
    }
    return r;
}

export const initDefaultValue: HoSoKhenThuongDTO = {
    id: null,
    soToTrinh: '', ngayToTrinh: null,
    mocThoiGian: MocThoiGianEnum.CONG_TAC, thoiGian: '',
    trichYeu: '',
    canCu: '', deNghi: '',
    layKetQua: false,
} as HoSoKhenThuongDTO