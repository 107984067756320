import {  ChiTietKhuonVienDTO, DienTichKhuonVienDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { DienTichKhuonVienDTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import StringUtil from '../../../../utils/stringUtils';
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import React, { useEffect } from 'react';
import { Box, Button, Checkbox, Dialog, FormControlLabel, FormHelperText, Grid, InputAdornment, InputBase, InputLabel } from '@material-ui/core';
import ChiTietKhuonVienModal from './ChiTietKhuonVienModal';
import ChiTietKhuonVienTable from '../Table/ChiTietKhuonVienTable';
interface DienTichKhuonVienModalProps {
    data: DienTichKhuonVienModalDataProps;
    onToggle: () => void;
    onAddData: (value: DienTichKhuonVienDTO) => void;
    onUpdateData: (index: number, value: DienTichKhuonVienDTO) => void;
}
interface DienTichKhuonVienModalDataProps {
    data: DienTichKhuonVienDTO;
    show: boolean;
    indexEdit: number;
}
const DienTichKhuonVienModal = (props: DienTichKhuonVienModalProps) => {
    const [data, setData] = React.useState({ ...DienTichKhuonVienDTO_INIT });
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [indexEdit, setIndexEdit] = React.useState(-1);
    const { onToggle, onAddData, onUpdateData } = props;
    useEffect(() => {
        setData(props.data.data);
        setShow(props.data.show);
        setIndexEdit(props.data.indexEdit);
        setSubmit(false);
    }, [props.data]);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData(data => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }
    const handleChangeNumber = (e: any) => {
        
        // if (Number.isNaN(Number.parseInt(value))) {
        //     value = 0;
        // }
        // if (Number.parseInt(value) < 0) {
        //     return;
        // }
        // setData(data => ({
        //     ...data,
        //     [e.target.name]: Number.parseInt(value)
        // }));
        let value = e.target.value;
        if(StringUtil.allowNumberOrDot(value)){
            let valueString : string =  StringUtil.getNumberDot(value);
            setData(data => ({
                ...data,
                [e.target.name] : valueString
            }));    
        }


    }
    const handleSubmit = () => {
        setSubmit(true);
        if (validateFrom()) {

            if (indexEdit >= 0) {
                onUpdateData(indexEdit, data);
            } else {
                onAddData(data);
            }
            onToggle();
        }
    }

    const validateFrom = (): boolean => {
        let valid: boolean = true;
        // if (StringUtil.isNullOrEmty(data.ghiChu)) {
        //     valid = false;
        // }
        return valid;
    }
    const handleChiTietAdd = (rowNew: ChiTietKhuonVienDTO) => {
        let chiTietKhuonVienDTOsNew = [...data.chiTietKhuonVienDTOs];
        chiTietKhuonVienDTOsNew.push(rowNew);
        setData({ ...data, chiTietKhuonVienDTOs: chiTietKhuonVienDTOsNew });
    }
    const handleChiTietDrop = (index: number) => {
        let chiTietKhuonVienDTOsNew = [...data.chiTietKhuonVienDTOs];
        chiTietKhuonVienDTOsNew.splice(index, 1);
        setData({ ...data, chiTietKhuonVienDTOs: chiTietKhuonVienDTOsNew });
    }
    const handleChiTietUpdate = (index: number, value: ChiTietKhuonVienDTO) => {
        let chiTietKhuonVienDTOsNew = [...data.chiTietKhuonVienDTOs];
        chiTietKhuonVienDTOsNew[index] = value;
        setData({ ...data, chiTietKhuonVienDTOs: chiTietKhuonVienDTOsNew });
    }
    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        {indexEdit >= 0 ? 'CẬP NHẬT DIỆN TÍCH KHUÔN VIÊN' : 'THÊM MỚI DIỆN TÍCH KHUÔN VIÊN'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel>Ghi chú </InputLabel>
                                    <InputBase fullWidth
                                        multiline
                                        rows="8"
                                        rowsMax="8"
                                        name="ghiChu"
                                        value={data.ghiChu} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                <InputLabel>Loại đất </InputLabel>
                                    <ChiTietKhuonVienTable 
                                        data={data.chiTietKhuonVienDTOs}
                                        handleAdd={handleChiTietAdd}
                                        handleDrop={handleChiTietDrop}
                                        handleUpdate={handleChiTietUpdate} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <Box marginTop="5px">
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={handleSubmit}>
                                            Lưu
                                </Button>
                                        <Button variant="contained" color="default" disableElevation
                                            onClick={onToggle}>
                                            Đóng
                                </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>

    )
}
export default DienTichKhuonVienModal;