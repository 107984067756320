import { FileEntry } from "../../Common/fileEntry";
import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";
import { GiayPhepATTP, LichSuGiayPhepATTPDTO, HoSoThuLyATTPDTO, RequestDTO } from "../Model/VSATTPModel";

export const HoSoThuLyATTPDTO_INIT: HoSoThuLyATTPDTO = {
    id: 0,

    giayPhepId: 0,

    maHoSo: '',

    maThuTuc: '',

    soGiayPhep: '',

    ngayCapPhep: 0,

    ngayHetHan: 0,

    tenChuCoSo: '',

    chuCoSo: '',

    tenCoSo: '',

    conHan: 0,

    hetHan: 0,

    maTinhTp: '',

    tenTinhTp: '',

    maHuyenThiTp: '',

    tenHuyenThiTp: '',

    maPhuongXaTT: '',

    tenPhuongXaTT: '',

    maDuong: '',

    tenDuong: '',

    soNha: '',

    khuVucAp: '',

    dienThoai: '',

    fax: '',

    maSoGiayCNDKKDHKD: '',

    tenSoGiayCNDKKDHKD: '',

    ngayCapCNDKKDHKD: 0,

    noiCapCNDKKDHKD: '',

    maTinhTpDSXKD: '',

    tenTinhTpDSXKD: '',

    maHuyenThiTpDSXKD: '',

    tenHuyenThiTpDSXKD: '',

    maPhuongXaTTDSXKD: '',

    tenPhuongXaTTDSXKD: '',

    maDuongDSXKD: '',

    khuVucApDSXKD: '',

    tenDuongDSXKD: '',

    soNhaDSXKD: '',

    dienThoaiDSXKD: '',

    faxDSXKD: '',

    quyMoKinhDoanhDSXKD: '',

    soLuongNguoiLaoDongDSXKD: '',

    soLuongLaoDongTrucTiepDSXKD: '',

    soLuongLaoDongGianTiepDSXKD: '',

    duDieuKienAnToanThucPham: 'Đủ điều kiện an toàn thực phẩm theo quy định kinh doanh dịch vụ ăn uống',

    nguoiThamDinh: '',

    ngayThamDinh: 0,

    coSoChuaDuDieuKienATTP: false,

    ketQuaThamDinh: '',

    maTrangThai: '',

    tenTrangThai: '',

    maBienDong: '',

    tenBienDong: '',
    
    files: [],
    
    loaiKetQua: LoaiKetQuaEnum.CHUA_LAY_KET_QUA,

    ngayHenTra: 0,

    maCoQuan:'',

}

export const LichSuATTPDTO_INIT: LichSuGiayPhepATTPDTO = {
    id: 0,
    giayPhepId: 0,
    hoSoId: 0,
    maDonViCapPhep: "",
    tenDonViCapPhep: "",
    maHuyen: "",
    tenHuyen: "",
    maloaibiendong: "",
    tenLoaiBienDong: "",
    maPhuong: "",
    tenPhuong: "",
    noiDung: "",
    lyDoTamNgung: "",
    lyDoHTTamNgung: "",
    maTrangThai: "",
    tenTrangThai: "",
    ngayCapNhat: "",
    ngayKhoiTao: "",
    ngayBienDong: 0,
    ngayThongKe: "",
    ghiChu: ""
}

export const GiayPhepATTPModel_INIT: GiayPhepATTP = {
    id: 0,
    hoSoThuLyId: 0,
    maHoSo: "",
    maThuTuc: "",
    soGiayPhep: "",
    ngayCapPhep: 0,
    ngayCapPhepTu: 0,
    ngayCapPhepDen: 0,
    ngayHetHan: 0,
    chuCoSo: "",
    tenCoSo: "",
    dienThoai: "",
    diaChiDayDu: "",
    fax: "",
    maSoGiayCNDKKDHKD: "",
    ngayCapCNDKKDHKD: "",
    noiCapCNDKKDHKD: "",
    tenPhuongXaTTDSXKD: "",
    dienThoaiDSXKD: "",
    faxDSXKD: "",
    quyMoKinhDoanhDSXKD: "",
    soLuongNguoiLaoDongDSXKD: "",
    soLuongLaoDongTrucTiepDSXKD: "",
    soLuongLaoDongGianTiepDSXKD: "",
    duDieuKienAnToanThucPham: "",
    nguoiThamDinh: "",
    ngayThamDinh: 0,
    coSoChuaDuDieuKienATTP: true,
    ketQuaThamDinh: "",
    maTrangThai: "",
    maBienDong: "",
    tenBienDong: "",
    maLoaiGiayPhep: "",
    tenDonViCapPhep: '',
    maDonViCapPhep: 0,
    tenTrangThai: "",
    pageSize: 10,
    pageNumber: 1,
    keywords: "",
    hoatDong: 1,
    hoSoThuLyATTPDTO: HoSoThuLyATTPDTO_INIT,
    lichSuGiayPhepATTPDTOs: new Array<LichSuGiayPhepATTPDTO>(),
    files: new Array<FileEntry>(),
    lichSuDTO: LichSuATTPDTO_INIT,
    maMauTrangThai : 0
}
export const RequestDTO_INIT: RequestDTO = {
    soGiayPhep: '',
    chuCoSo: '',
    maPhuong: '',
    tenPhuong: '',
    
    ngayCapPhepTu: 0,
    ngayCapPhepDen: 0,
    ngayHetHanTu: 0,
    ngayHetHanDen: 0,
    trangThai: '',
    tenTrangThai: '',
    exportType: '',
    exportFileName: '',
    pageSize: 10,
    pageNumber: 1,
    keywords: '',
    maQuan: '',
    tenQuan: '',
    
}


