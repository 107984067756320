import { Modal } from 'react-bootstrap';
import React from 'react';
import StringUtil from '../../../../utils/stringUtils';
import { ICONS } from '../../../../Constants/constants';
import { Button } from '@material-ui/core';
import toastr from 'toastr';

export default class NhomCaNhanModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nhomCaNhan: {
                id: -1,
                tenThanhVien: '',
                tenQuocTich: 'Việt Nam',
                maQuocTich: 'VN',
                diaChiLienLac: '',
                diaChiThuongTru: '',
                soGiayToPhapLy: '',
                ghiChu: '',
            },
            indexEdit: -1,
            show: false,
        }
    }

    componentDidUpdate() {
        console.log("this.props.indexEdit", this.props.indexEdit);
        console.log("this.state.nhomCaNhans", this.state.nhomCaNhan);
        if (this.props.show != this.state.show) {
            this.state.show = this.props.show;
            this.setState(this.state);
        }
        if (this.props.data != null && this.props.data != this.state.nhomCaNhan) {
            this.state.nhomCaNhan = this.props.data;
            this.setState(this.state)
        }
        if (this.props.indexEdit != null && this.props.indexEdit != this.state.indexEdit) {
            this.state.indexEdit = this.props.indexEdit;
            this.setState(this.state)
        }
    }
    handleCloseModal = () => {
        this.clearData();
        this.props.onToggle();
    }
    handleChange = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.state.nhomCaNhan[name] = value;

        this.setState(this.state);
    }
    handleSubmit = () => {
        console.log("this.state.nhomCaNhans", this.state.nhomCaNhan);
        // this.props.onUpdateData(this.state.nhomCaNhan);
        // if (this.validation()) {

        // }
        if (this.state.indexEdit >= 0) {
            this.props.handleUpdate(this.state.indexEdit, this.state.nhomCaNhan);
            toastr.success('Cập nhật thành viên hộ gia đình thành công')
            this.handleCloseModal();
        } else {
            this.props.handleAdd(this.state.nhomCaNhan);
            toastr.success('Thêm mới thành viên hộ gia đình thành công')
            this.handleCloseModal();
        }
    }
    validation = () => {
        let valid = true;
        this.state.errors = {};
        // if (StringUtil.isNullOrEmty(this.state.nhomCaNhans.soTo)) {
        //     this.state.errors.soTo = 'Vui lòng nhập số tờ';
        //     valid = false;
        // }
        // if (StringUtil.isNullOrEmty(this.state.nhomCaNhans.soThua)) {
        //     this.state.errors.soThua = 'Vui lòng nhập số thửa';
        //     valid = false;
        // }
        this.setState(this.state);
        return valid;
    }
    clearData = () => {
        this.state = {
            nhomCaNhan: {
                id: -1,
                tenThanhVien: '',
                tenQuocTich: 'Việt Nam',
                maQuocTich: 'VN',
                diaChiLienLac: '',
                diaChiThuongTru: '',
                soGiayToPhapLy: '',
                ghiChu: '',
            },
            indexEdit: -1,
            show: false,
        }
        this.state.errors = {
            soTo: '',
            soThua: ''
        };
        this.setState(this.state);
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleCloseModal} on size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>Thêm nhóm cá nhân</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={ICONS.delete} /></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="tenThanhVien" style={{ fontWeight: 'bold', fontSize: ' ' }}>Tên thành viên  <span style={{ color: 'red' }}></span></label>
                                    <input type="text" value={this.state.nhomCaNhan.tenThanhVien} className="form-control" id="tenThanhVien" name="tenThanhVien" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="tenQuocTich" style={{ fontWeight: 'bold', fontSize: ' ' }}>Quốc tịch  <span style={{ color: 'red' }}></span></label>
                                    <input type="text" value={this.state.nhomCaNhan.tenQuocTich} className="form-control" id="tenQuocTich" name="tenQuocTich" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="diaChiLienLac" style={{ fontWeight: 'bold', fontSize: ' ' }}>Địa chỉ liên lạc  <span style={{ color: 'red' }}></span></label>
                                    <input type="text" value={this.state.nhomCaNhan.diaChiLienLac} className="form-control" id="diaChiLienLac" name="diaChiLienLac" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="diaChiThuongTru" style={{ fontWeight: 'bold', fontSize: ' ' }}>Địa chỉ thường trú  <span style={{ color: 'red' }}></span></label>
                                    <input type="text" value={this.state.nhomCaNhan.diaChiThuongTru} className="form-control" id="diaChiThuongTru" name="diaChiThuongTru" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="soGiayToPhapLy" style={{ fontWeight: 'bold', fontSize: ' ' }}>Sồ giấy tờ pháp lý của cá nhân  <span style={{ color: 'red' }}></span></label>
                                    <input type="text" value={this.state.nhomCaNhan.soGiayToPhapLy} className="form-control" id="soGiayToPhapLy" name="soGiayToPhapLy" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="ghiChu" style={{ fontWeight: 'bold', fontSize: ' ' }}>ghi chú  <span style={{ color: 'red' }}></span></label>
                                    <input type="text" value={this.state.nhomCaNhan.ghiChu} className="form-control" id="ghiChu" name="ghiChu" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="text-center col-md-6">
                                <Button onClick={this.handleSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
                                <Button onClick={this.handleCloseModal} variant="contained" color="default" disableElevation>Đóng</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}