import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Box, Button, NativeSelect } from '@material-ui/core';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { HangMucXayDungDTO_INIT, THONG_TIN_THAM_DINH_DTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import HangMucModal from '../Modal/HangMucModal';
import { HangMucXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import StringUtil from '../../../../utils/stringUtils';
interface HangMucTableProps {
    data: HangMucXayDungDTO[];
    onUpdateData: (name: string, value: any) => void;
}
 interface HangMucModalDataProps {
    data: HangMucXayDungDTO;
    show: boolean;
    indexEdit: number;
}
const HangMucTable = (props: HangMucTableProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<HangMucXayDungDTO[]>(new Array<HangMucXayDungDTO>());
    const [dataEdit, setDataEdit] = useState<HangMucModalDataProps>({ data: { ...HangMucXayDungDTO_INIT }, show: false, indexEdit: -1 });
    const { onUpdateData } = props;
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = ((pageNumberNew - 1) * pageSizeNew);
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    }
    const handleAdd = (rowNew: HangMucXayDungDTO) => {
        let dataNew = [...props.data];
        dataNew.unshift(rowNew);
        onUpdateData('hangMucXayDungDTOs', dataNew);
    }
    const handleDrop = (index: number) => {
        let dataNew = [...props.data];
        dataNew.splice(index, 1);
        onUpdateData('hangMucXayDungDTOs', dataNew);
    }
    const handleUpdate = (index: number, value: HangMucXayDungDTO) => {
        let dataNew = [...props.data];
        dataNew[index] = value;
        onUpdateData('hangMucXayDungDTOs', dataNew);
    }
    const handleToggle = () => {
        setDataEdit({ ...dataEdit, show: !dataEdit.show });
    }
    const handleEditToggle = (index: number) => {
        setDataEdit({ ...dataEdit, data: props.data[index], show: !dataEdit.show, indexEdit: index });
    }
    const handleAddToggle = () => {
        setDataEdit({ data: { ...HangMucXayDungDTO_INIT }, show: !dataEdit.show, indexEdit: -1 });
    }
    return (
        <div>
            <HangMucModal
                data={dataEdit}
                onToggle={handleToggle}
                onAddData={handleAdd}
                onUpdateData={handleUpdate}
            />
            <table className="table table-bordered" style={{ fontSize: '14px' }}>
                <thead>
                    <tr className="table-secondary">
                        <th>STT</th>
                        <th>Hạng mục</th>
                        <th>Số tầng</th>
                        <th>Kết cấu</th>
                        <th>Diện tích xây dựng</th>
                        <th style={{ width: "5%" }} className="text-center">Sửa</th>
                        <th style={{ width: "5%" }} className="text-center">Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {dataPagi?.length > 0 ? dataPagi?.map((item, index) => {
                        return (
                            <tr key={index}>
                                     <td className="cell-number">{((pageNumber - 1) * pageSize) + index + 1}</td>
                                <td>{item.hangMuc}</td>
                                <td>{item.soTang}</td>
                                <td>{item.ketCau}</td>
                                <td>{StringUtil.viewNumberDot(item.dienTichTangMot ? item.dienTichTangMot.toString() : "")}</td>
                                <td align="center">
                                    <Button onClick={(e) => handleEditToggle(((pageNumber - 1) * pageSize) + index)} startIcon={<EditIcon color="primary" />} size="small" disableRipple />
                                </td>
                                <td align="center">
                                    <Button onClick={(e) => handleDrop(((pageNumber - 1) * pageSize) + index)} startIcon={<CloseIcon color="secondary" />} size="small" disableRipple />
                                </td>

                            </tr>
                        )
                    }) :
                        <tr>
                            <td className="text-center" colSpan={7}> Không có dữ liệu</td>
                        </tr>
                    }
                </tbody>
            </table>

            <div className="row">
                {/* pagination*/}
                <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                    <Pagination count={Math.ceil(props.data?.length / pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleChangePage} />
                    <div>
                        <span> Số mẫu tin trên trang </span>
                        <NativeSelect value={pageSize} input={<CustomInput />} onChange={handleChangePageSize} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                        <span>Trang {pageNumber}  / {props.data?.length === 0 ? 1 : Math.ceil(props.data?.length / pageSize)}</span>
                    </div>
                </div>
                <div className="col-md-2 text-right" style={{ fontSize: '14px' }}>
                    <Button onClick={handleAddToggle} variant="contained" color="primary" disableElevation>Thêm mới</Button>
                </div>

            </div>
        </div>
    )
}
export default HangMucTable;