import AXIOS_INSTANCE from "..";
import { DanhMucI, SearchRequest } from "../Common/model/CommonModel";
export class PhanLoaiCongTrinhService {
    constructor(){}
    search = (request : SearchRequest) => {
       return AXIOS_INSTANCE.post('cpxd/dm-phan-loai-cong-trinh/search', request);
    }
    getByHoatDong = (hoatDong : boolean) => {
       return AXIOS_INSTANCE.get('cpxd/dm-phan-loai-cong-trinh/get-by-hoat-dong/' + hoatDong);
    }
    addOrUpdate = (request : DanhMucI) => {
        return AXIOS_INSTANCE.post('cpxd/dm-phan-loai-cong-trinh/addOrUpdate', request);
     }
     delete = (id: number) => {
      return AXIOS_INSTANCE.post('cpxd/dm-phan-loai-cong-trinh/delete/'+ id);
    }
}
