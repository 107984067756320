import { Grid, InputLabel } from "@material-ui/core";
import React from "react";
import { bootstrapTextFieldHook } from "./input/bootstrap";

export interface GridViewProps {
    labelLeft: string;
    valueLeft: any;
    labelRight?: string;
    valueRight?: any;
}

const GridView: React.FC<GridViewProps> = ({ labelLeft, valueLeft, labelRight, valueRight }) => {
    const labelLeftStyle = bootstrapTextFieldHook.labelLeft();
    const labelRightStyle = bootstrapTextFieldHook.labelRight();
    return (
        <Grid container>
            {labelLeft && (
                <>
                    <Grid item xs={2} className="grid-item">
                        <InputLabel classes={labelLeftStyle}>{labelLeft}</InputLabel>
                    </Grid>
                    <Grid item xs={labelRight ? 3 : 8} className="grid-item">
                        <InputLabel classes={labelRightStyle}>{valueLeft}</InputLabel>
                    </Grid>
                </>
            )}
            {labelRight && (
                <>
                    <Grid item xs={3} className="grid-item" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <InputLabel classes={labelLeftStyle}>{labelRight}</InputLabel>
                    </Grid>
                    <Grid item xs={4} className="grid-item">
                        <InputLabel classes={labelRightStyle}>{valueRight}</InputLabel>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default GridView;
