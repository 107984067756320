import {Box, NativeSelect} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import React, {useEffect, useState} from 'react';
import {CustomInput} from "../../../../commons/components/input/customInput";
import {Cell, Row} from "../../../../commons/components/table/tableCommons";
import {DiaDiemToChucBanLeDTO} from "../../../../../api/CPBLTL/model/TableBLThuocLa";

interface IDataListProps {
    dataList: DiaDiemToChucBanLeDTO[];
}

export const TableDiaDiemToChucBanLeComponent = (props: IDataListProps) => {

    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataTepms, setDataTepms] = useState<DiaDiemToChucBanLeDTO[]>([]);

    useEffect(() => {
        setDataTepms(props.dataList);
        setTotal(props.dataList?.length);
        handleSearch({page : 1, size : size});
    }, [props.dataList, total, size]);

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        handleSearch({page : value, size : size});
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1)
        setSize(Number(event.target.value));
        handleSearch({page : 1, size : Number(event.target.value)});
    }

    const handleSearch = (search : any) => {
        setRows(dataTepms?.slice((search.page - 1) * search.size, search.page * search.size));
    }

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="30%">Tên</Cell>
                        <Cell component="th" scope="row" align="center" width="40%">Địa chỉ</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Điện thoại</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) => (
                            <Row key={row.id}>
                                <Cell scope="row" align="center">
                                    {(page - 1) * size + i + 1}
                                </Cell>
                                <Cell align="left">{row.tenChiNhanhVanPhong}</Cell>
                                <Cell align="left">{row.diaChi}</Cell>
                                <Cell align="left">{row.dienThoai}</Cell>
                            </Row>
                        )) :
                        <Row>
                            <Cell colSpan={4} scope="row" align="center"> Không có dữ liệu</Cell>
                        </Row>
                    }
                </TableBody>
            </Table>
            {rows?.length > 0 ?
            <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                <Pagination count={Math.ceil(total / size)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                    <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                    <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </NativeSelect>
                </Box>
                <Box component="span" paddingX="5px">Mẫu tin {page * size} - {page * size > total ? total : (page * size)} / {total}</Box>
            </Box>:''
            }
        </TableContainer>
    )
}
