import api from "../index";
import { BaseResponse, BaseService } from "../baseService";
import { AttachFileDTO, AttachFileInteface } from "../KhenThuong/models/attachFile";

export default class AttachFileService extends BaseService {
    uploadFile = (attachFile: AttachFileInteface, callback: (data: any) => void): Promise<BaseResponse> => {
        const formData = new FormData();
        let result = { isSuccess: false } as BaseResponse;

        formData.append("file" + 0, attachFile.file, `${attachFile.fileName}`);

        return new Promise<BaseResponse>((resolve, reject) => {
            api.post("/files/upload/1", formData, {
                headers: {
                    "Content-Type": "multipart/form-data;charset=UTF-8",
                },
                onUploadProgress: callback,
            })
                .then((response) => {
                    if (response.status !== 200) {
                        result.message = "Có lỗi xảy ra, thực hiện lại!";
                    } else {
                        result.isSuccess = response.data.error_code === "SUCCESSFUL";
                        if (result.isSuccess) {
                            result.message = "upload file thành công";
                            result.data = response.data.data;
                        } else {
                            result.message = "upload file thất bại";
                        }
                    }
                    return resolve(result);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    };
    downloadFile = async (attachFile: AttachFileDTO): Promise<any> => {
        return api.get(`/files/download?fileId=${attachFile.fileId}`, { responseType: "blob" });
    };
}
