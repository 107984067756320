import React, { useEffect, useState, Dispatch } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import StringUtil from '../../../../utils/stringUtils';
import { LPGChaiService } from '../../../../../api/LPGChai/LPGChaiService';
import { ThongTinChungComponent } from './ThongTinChungComponent';
import ThuLyButtonComponent from './Action/ThuLyButtonComponent';
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { HO_SO_THU_LY_LPGCHAI_INIT } from "../../../../../api/LPGChai/model/GiayPhepLGPChaiInit";
import { BrowserHistory } from "history";
import { TINHTRANG_HUY, TINHTRANG_HETHAN, TINHTRANG_HOATDONG, TINHTRANG_THUHOI, LOAIBIENDONG_CAPMOI, LOAIBIENDONG_HETHAN, LOAIBIENDONG_THUHOI, LOAIBIENDONG_HUY } from '../../../../Constants/LGPChaiConstants';
import { HoSoThuLyLPGChaiDTO } from '../../../../../api/LPGChai/model/HoSoThuLyLPGChai';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import DateUtil from '../../../../utils/dateUtils';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { useDispatch } from "react-redux";
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { ThongTinChuyenNhanhUtil } from '../../../../utils/thongTinChuyenNhanhUtil';
import { getObject } from '../../../../../helpers/localStorage';

type StateHoSo = {
    hoSoThuLyId: number
}
type DauKyLGPChaiProps = {
    history: BrowserHistory<StateHoSo>
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const NhapDauKyGiayChungNhanLPGChai = (props: DauKyLGPChaiProps) => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const [indexTab, setIndexTab] = useState(0);
    const [tabRequired, setTabRequired] = useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [data, setData] = useState({} as HoSoThuLyLPGChaiDTO);
    const [submit, setSubmit] = useState(false);
    const { history } = props;
    const giayChungNhanLPGChaiService = new LPGChaiService();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        dispatchLoading(showLoading());
        if (history?.location?.state?.hoSoThuLyId > 0) {
            giayChungNhanLPGChaiService.getChiTietByHoSoThuLyId({ hoSoThuLyId: history?.location?.state?.hoSoThuLyId }).then(res => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    let resData = {
                        ...res?.data?.data
                    } as HoSoThuLyLPGChaiDTO;
                    setData(resData);
                } else {
                    const dataNew = {
                        ...HO_SO_THU_LY_LPGCHAI_INIT,
                        donViCapPhep: true,
                        maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                        maTinhTrang: TINHTRANG_HOATDONG.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG.ten,
                        maQuanHuyen: C_USER_DATA.maQuanHuyen,
                        tenQuanHuyen: C_USER_DATA.tenQuanHuyen,
                        maLoaiGiayPhep: 'DK'

                    }
                    setData(dataNew);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });

        } else {
            const dataNew = {
                ...HO_SO_THU_LY_LPGCHAI_INIT,
                donViCapPhep: true,
                maBienDong: LOAIBIENDONG_CAPMOI.ma,
                tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                maTinhTrang: TINHTRANG_HOATDONG.ma,
                tenTinhTrang: TINHTRANG_HOATDONG.ten,
                maQuanHuyen: C_USER_DATA.maQuanHuyen,
                tenQuanHuyen: C_USER_DATA.tenQuanHuyen,
                maLoaiGiayPhep: 'DK',
            }
            setData(dataNew);
            dispatchLoading(hideLoading());
        }
    }
    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan)
            || StringUtil.isNullOrEmty(data.maPhuongXa)
            || StringUtil.isNullOrEmty(data.soGiayCNDKKD)) {
            setTabRequired(0);
            return;
        }
        if (data?.donViCapPhep) {
            if (StringUtil.isNullOrEmty(data.soGiayPhep)
                || StringUtil.isNullOrEmty(data.ngayCapPhep)) {
                setTabRequired(-0);
                return;
            }
        }
        setSubmit(false);
        let dataRequest = { ...data };
        if (data.maTinhTrang == TINHTRANG_HETHAN.ma) {
            dataRequest = {
                ...data,
                maBienDong: LOAIBIENDONG_HETHAN.ma,
                tenBienDong: LOAIBIENDONG_HETHAN.ten,
                tenTinhTrang: TINHTRANG_HETHAN.ten
            }
        } else if (data.maTinhTrang == TINHTRANG_THUHOI.ma) {
            dataRequest = {
                ...data,
                maBienDong: LOAIBIENDONG_THUHOI.ma,
                tenBienDong: LOAIBIENDONG_THUHOI.ten,
                tenTinhTrang: TINHTRANG_THUHOI.ten
            }
        } else if (data.maTinhTrang == TINHTRANG_HUY.ma) {
            dataRequest = {
                ...data,
                maBienDong: LOAIBIENDONG_HUY.ma,
                tenBienDong: LOAIBIENDONG_HUY.ten,
                tenTinhTrang: TINHTRANG_HUY.ten
            }
        }
        giayChungNhanLPGChaiService.insertOrUpdate(dataRequest).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL' && parseInt(res.data.data.id) > 0) {
                    let resData = {
                        ...res?.data?.data
                    };
                    setData(resData);
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else if (res.data.error_code == 'DUPLICATE_CODE') {
                    showMessageAlert('Số giấy phép đã tồn tại', false);
                } else {

                    showMessageAlert('Yêu cầu thực hiện thất bại', false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }
    const resetData = () => {
        const dataNew = {
            ...HO_SO_THU_LY_LPGCHAI_INIT,
            maBienDong: LOAIBIENDONG_CAPMOI.ma,
            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten,
            id: data.id,
            giayPhepId: data.giayPhepId,
            donViCapPhep: true,
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const handleSearchDoanhNghiep = async (maSoDoanhNghiep: string) => {
        if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
            showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không được trống", false);
            return;
        } else {
            let tenThuongNhan = "" as string;
            dispatchLoading(showLoading());
            await new DanhMucService().getBySoDKKD({ maSoDoanhNghiep: maSoDoanhNghiep }).then(async res => {
                let dataNew = res?.data.data;
                let maPhuong = "";
                let tenPhuong = "";
                if (dataNew !== null && dataNew !== undefined) {
                    if (dataNew.error_code === 'SUCCESSFUL' && res?.data?.data?.data?.maSoDoanhNghiep) {
                        tenThuongNhan =  new ThongTinChuyenNhanhUtil().getTenThuongNhan(res?.data?.data?.data?.listThanhVien);
                        if (!StringUtil.isNullOrEmty(dataNew.data.maPhuongXaID)) {
                            let dataHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataNew.data.maPhuongXaID);
                            maPhuong = dataHuyen[0].code;
                            tenPhuong = dataHuyen[0].name;
                        }
                        let dataNewDKKD = {
                            ...data,
                            dienThoaiTruSo: dataNew.data.dienThoai,
                            soNhaTruSo: dataNew.data.soNha,
                            faxTruSo: dataNew.data.fax,
                            ngayCapCNDKKD: res?.data?.data?.data?.ngayThanhLap ? DateUtil.stringDDMMYYYtoDate(res?.data?.data?.data?.ngayThanhLap) : data?.ngayCapCNDKKD,
                            tenDuong: res?.data?.data?.data?.tenDuong,
                            soNha: res?.data?.data?.data?.soNha,
                            maSoThue: res?.data?.data?.data?.maSoThue,
                            soDienThoai: res?.data?.data?.data?.dienThoai ? StringUtil.replaceDots(res?.data?.data?.data?.dienThoai) : data?.soDienThoai,
                            fax: res?.data?.data?.data?.fax,
                            email: res?.data?.data?.data?.email,
                            tenThuongNhan: tenThuongNhan ? tenThuongNhan : res?.data?.data?.data?.tenDoanhNghiep_VN,
                            maPhuongXa: maPhuong,
                            tenPhuongXa: tenPhuong

                        };
                        setDataParam("data", dataNewDKKD);
                    } else {
                        setAlertMessage({ open: true, content: "Giấy CNĐKKD/DN/HTX/HKD không tồn tại trong dữ liệu", severity: 'warning' });
                    }
                }
            }).catch(error => {
                console.log(error);
            })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        }
    }
    return (
        <>
            {
                <Paper square >
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd"
                        keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                        <div className="dash-content">
                            <div className="dash-title">
                                <span>NHẬP ĐẦU KỲ GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN CỬA HÀNG BÁN LẺ LPG CHAI</span>
                            </div>
                        </div>
                        {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <ThongTinChungComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    data={data}
                                    onSeachDoanhNghiep={handleSearchDoanhNghiep}
                                />

                                <Grid container direction="row">
                                    <Grid item xs={12} style={{ float: 'left' }} className="grid-item" >
                                        <ThuLyButtonComponent onSubmit={handleSubmit} onDelete={handleDelete} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        }
                    </Grid>
                </Paper>
            }
        </>
    )
}
