import MomentUtils from "@date-io/moment";
import { Box, FormHelperText, Grid, InputAdornment, InputBase, InputLabel, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { useEffect, useState } from 'react';
import { IFileWithMeta } from 'react-dropzone-uploader';
import AXIOS_INSTANCE from '../../../../../api';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { DanhMucIView } from '../../../../../api/Common/model/CommonModel';
import { CapCongTrinhService } from '../../../../../api/CPXD/CapCongTrinhService';
import { CapPhepXayDungService } from '../../../../../api/CPXD/CapPhepXayDungService';
import { LoaiCongTrinhService } from '../../../../../api/CPXD/LoaiCongTrinhService';
import { GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { PhanLoaiCongTrinhService } from '../../../../../api/CPXD/PhanLoaiCongTrinhService';
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../api/danhMuc/model/DanhMucDTO";
import { HUYEN, PHUONG, TINH_TP } from '../../../../../api/QuanTri/DanhMucFakeService';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { TINHTRANG_HOATDONG } from '../../../../Constants/CapPhepXayDungConstants';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import StringUtil from '../../../../utils/stringUtils';
import DonViKiemTraCPXDModal from '../Modal/DonViKiemTraCPXDModal';
import NhaORiengLeDonViTuVanModal from '../Modal/NhaORiengLeDonViTuVanModal';
import NhaORiengLeSoKyHieuBangVeModal from '../Modal/NhaORiengLeSoKyHieuBangVeModal';
import NhaORiengLeLoDatXayDungComponent from '../Table/NhaORiengLeLoDatXayDungComponent';
interface NoiDungSuaChuaCaiTaoCPXDProps {
    data: GiayPhepXayDungDTO;
    submit: boolean;
    onUpdateData: (name: string, value: any) => void;
    loadGiayPhepDaCap: (soGiayPhepCu: string) => void;
    isNhapDauKy: boolean;
}
const NoiDungSuaChuaCaiTaoCPXD = (props: NoiDungSuaChuaCaiTaoCPXDProps) => {


    const [data, setData] = useState({} as GiayPhepXayDungDTO);
    const [submit, setSubmit] = useState(false);
    const [openDonViTuVanModal, setOpenDonViTuVanModal] = useState(false);
    const [openSoKyHieuBangVeModal, setOpenSoKyHieuBangVeModal] = useState(false);
    const [openDonViKiemTra, setOpenDonViKiemTra] = useState(false);
    const { onUpdateData, loadGiayPhepDaCap } = props;
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [dataAutocomple, setDataAutocomple] = useState<GiayPhepXayDungDTO[]>(new Array<GiayPhepXayDungDTO>());
    const capPhepXayDungService = new CapPhepXayDungService();
    const fileServerService = new FileServerService();
    const [capCongTrinhs, setCapCongTrinhs] = useState<DanhMucIView[]>([]);
    const [loaiCongTrinhs, setLoaiCongTrinhs] = useState<DanhMucIView[]>([]);
    const [phanLoaiCongTrinhs, setPhanLoaiCongTrinhs] = useState<DanhMucIView[]>([]);
    const capCongTrinhService = new CapCongTrinhService();
    const loaiCongTrinService = new LoaiCongTrinhService();
    const phanLoaiCongTrinhService = new PhanLoaiCongTrinhService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    useEffect(() => {
        capCongTrinhService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setCapCongTrinhs(res.data.data);
            }
        });
        loaiCongTrinService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setLoaiCongTrinhs(res.data.data);
            }
        });
        phanLoaiCongTrinhService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setPhanLoaiCongTrinhs(res.data.data);
            }
        });
        loadQuans("92");
    }, []);
    useEffect(() => {
        let dataNew = {
            ...props.data
        }
        setData(dataNew);
        setSubmit(props.submit);
        setIsNhapDauKy(props.isNhapDauKy);
    }, [props.data, props.submit, props.onUpdateData]);
    const loadQuans = async (maThanhPho: string) => {
        await danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        
    };
    const loadPhuongs = async () => {
          if(StringUtil.isNotEmpty(data?.maHuyen)){
            await  danhMucService
            .getDanhMucDiaChi(data?.maHuyen)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setPhuongs(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }  
    };
    const handleChangeSelectQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText,  ['maPhuong'] : '', ['tenPhuong'] : ''};
        onUpdateData("data", dataNew);
    };

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
        onUpdateData("data", dataNew);
    };
    const updateThongTinSuaChua = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        let thongTinSuaChuaDTO = { ...data.thongTinSuaChuaDTO, [name]: value };
        setData(data => ({
            ...data,
            thongTinSuaChuaDTO: thongTinSuaChuaDTO
        }));
        onUpdateData('thongTinSuaChuaDTO', thongTinSuaChuaDTO);
    }
    const updateThongTinSuaChuaNumber = (e: any) => {
        let value = e.target.value;
        let name = e.target.name;
        if(StringUtil.allowNumberOrDot(value)){
            let valueString : string =  StringUtil.getNumberDot(value);
            let thongTinSuaChuaDTO = { ...data.thongTinSuaChuaDTO, [name]: valueString };
            setData(data => ({
                ...data,
                thongTinSuaChuaDTO: thongTinSuaChuaDTO
            }));
            onUpdateData('thongTinSuaChuaDTO', thongTinSuaChuaDTO);
        }
    }
    const updateThongTinSuaChuaSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let thongTinSuaChuaDTO = { ...data.thongTinSuaChuaDTO, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData('thongTinSuaChuaDTO', thongTinSuaChuaDTO);
    }
    const handleUpdateData = (name: string, value: string) => {
        onUpdateData(name, value);
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    //đơn vị tư vấn
    const handleDonViTuVanModalToggle = () => {
        setOpenDonViTuVanModal(!openDonViTuVanModal);
    }
    //Số ký hiệu bảng vẽ
    const handleSoKyHieuBangVeModalToggle = () => {
        setOpenSoKyHieuBangVeModal(!openSoKyHieuBangVeModal);
    }
    //đơn vị kiểm tra
    const handleDonViKiemTraModalToggle = () => {
        setOpenDonViKiemTra(!openDonViKiemTra);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    }
    const handleSelectGiayPhepDaCap = (newValue: GiayPhepXayDungDTO) => {
        onUpdateData('soGiayPhepCu', newValue.soGiayPhep);
        loadGiayPhepDaCap(newValue.soGiayPhep);
    }
    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        onUpdateData('soGiayPhepCu', soGiayPhepCuNew);
        capPhepXayDungService.getAutocompleteBySoGiayPhep_MaTrangThai_PageSize(soGiayPhepCuNew, TINHTRANG_HOATDONG.ma, 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setDataAutocomple([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    return (
        <>
            {
                <Box padding={0}>
                    <NhaORiengLeSoKyHieuBangVeModal
                        onUpdateData={handleUpdateData}
                        onModalToggle={handleSoKyHieuBangVeModalToggle}
                        show={openSoKyHieuBangVeModal} />

                    <NhaORiengLeDonViTuVanModal
                        onUpdateData={handleUpdateData}
                        onModalToggle={handleDonViTuVanModalToggle}
                        show={openDonViTuVanModal} >
                    </NhaORiengLeDonViTuVanModal>
                    <DonViKiemTraCPXDModal
                        onUpdateData={handleUpdateData}
                        onModalToggle={handleDonViKiemTraModalToggle}
                        show={openDonViKiemTra} >
                    </DonViKiemTraCPXDModal>
                    <Box padding={0}>
                        <Grid container>
                            {isNhapDauKy && (
                                <Grid container>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required>Số giấy phép</InputLabel>
                                        <InputBase fullWidth
                                            error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                            value={data.soGiayPhep} name="soGiayPhep" onChange={handleChange} />
                                        {
                                            submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập số giấy phép</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required>Ngày cấp</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                error={submit && !(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined)}
                                                value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined) ? new Date(data.ngayCapPhepCu) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhepCu')}

                                            />
                                        </MuiPickersUtilsProvider>
                                        {
                                            submit && (data.ngayCapPhepCu === 0 || !data.ngayCapPhepCu || data.ngayCapPhepCu === null || data.ngayCapPhepCu === undefined) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập ngày cấp phép</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required>Ngày hết hạn</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                error={submit && !(data.ngayHetHan !== 0 && data.ngayHetHan && data.ngayHetHan !== null && data.ngayHetHan !== undefined)}
                                                value={(data.ngayHetHan !== 0 && data.ngayHetHan && data.ngayHetHan !== null && data.ngayHetHan !== undefined) ? new Date(data.ngayHetHan) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHan')}

                                            />
                                        </MuiPickersUtilsProvider>
                                        {
                                            submit && (data.ngayHetHan === 0 || !data.ngayHetHan || data.ngayHetHan === null || data.ngayHetHan === undefined) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập ngày hết hạn</FormHelperText>
                                        }
                                    </Grid>
                                </Grid>
                            )
                            }
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Tên công trình </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data.tenCongTrinh)}
                                    value={data.tenCongTrinh} name="tenCongTrinh" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.tenCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên công trình</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Tỉnh/Thành phố </InputLabel>
                                <Autocomplete id="maTinh"
                                    options={TINH_TP}
                                    value={{ ma: data.maTinh ? data.maTinh : "", ten: data.tenTinh ? data.tenTinh : "" }}
                                    getOptionLabel={(option) => option.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maTinh', 'tenTinh')}
                                    disabled={true}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maTinh)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maTinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Tỉnh/Thành phố</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete id="maHuyen"
                                    options={quans}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: data.maHuyen, name: data.tenHuyen }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                    }}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={phuongs}
                                    onFocus={loadPhuongs}
                                    getOptionLabel={(option) => option.name}
                                    value={{ code: data.maPhuong, name: data.tenPhuong }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                    }}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maPhuong) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Cấp công trình </InputLabel>
                                <Autocomplete id="maCapCongTrinh"
                                    options={capCongTrinhs}
                                    value={{ ma: data.maCapCongTrinh ? data.maCapCongTrinh : "", ten: data.tenCapLoaiCongTrinh ? data.tenCapLoaiCongTrinh : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maCapCongTrinh', 'tenCapLoaiCongTrinh')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maCapCongTrinh)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maCapCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Cấp công trình </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Đường </InputLabel>
                                <InputBase fullWidth
	                                value={data.tenDuong} name="tenDuong" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.tenDuong) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập Đường </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Số nhà, đường </InputLabel>
                                <InputBase fullWidth
                                    value={data.soNha} name="soNha" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Loại công trình </InputLabel>
                                <Autocomplete id="maLoaiCongTrinh"
                                    options={loaiCongTrinhs}
                                    value={{ ma: data.maLoaiCongTrinh ? data.maLoaiCongTrinh : "", ten: data.tenLoaiCongTrinh ? data.tenLoaiCongTrinh : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maLoaiCongTrinh', 'tenLoaiCongTrinh')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maLoaiCongTrinh)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maLoaiCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Loại công trình </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel >Địa chỉ cũ </InputLabel>
                                <InputBase fullWidth
                                    value={data.diaChiCu} name="diaChiCu" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} >
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel required>Phân loại xây dựng </InputLabel>
                                    <Autocomplete id="maPhanLoaiCongTrinh"
                                        options={phanLoaiCongTrinhs}
                                        value={{ ma: data.maPhanLoaiCongTrinh ? data.maPhanLoaiCongTrinh : "", ten: data.tenPhanLoaiCongTrinh ? data.tenPhanLoaiCongTrinh : "" }}
                                        getOptionLabel={(option) => option?.ten}
                                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maPhanLoaiCongTrinh', 'tenPhanLoaiCongTrinh')}
                                        renderInput={(params) => (
                                            <TextField  {...params} variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maPhanLoaiCongTrinh)}
                                            />
                                        )}
                                    />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.maPhanLoaiCongTrinh) &&
                                        <FormHelperText className="-error"  >Vui lòng chọn Phân loại xây dựng </FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel >Đơn vị tư vấn </InputLabel>
                                    <InputBase style={{ width: '90%' }}
                                        value={data.donViTuVan} name="donViTuVan" onChange={handleChange}/>
                                    <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleDonViTuVanModalToggle} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel >Số ký hiệu bản vẽ </InputLabel>
                                    <InputBase style={{ width: '90%' }}
                                        value={data.soKyHieuBangVe} name="soKyHieuBangVe"  onChange={handleChange}/>
                                    <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleSoKyHieuBangVeModalToggle} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel >Đơn vị kiểm tra </InputLabel>
                                    <InputBase style={{ width: '90%' }}
                                        value={data.donViKiemTra} name="donViKiemTra" onChange={handleChange}/>
                                    <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleDonViKiemTraModalToggle} />
                                </Grid>
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel >Lô đất xây dựng </InputLabel>
                                <NhaORiengLeLoDatXayDungComponent data={data.loDatXayDungDTOs} onUpdateData={handleUpdateData} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Ghi chú </InputLabel>
                                <InputBase fullWidth
                                    multiline
                                    rows="3"
                                    name="ghiChu"
                                    value={data.ghiChu} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Tổng diện tích sàn </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.thongTinSuaChuaDTO?.tongDienTichSan ? data?.thongTinSuaChuaDTO?.tongDienTichSan.toString() : "") } name="tongDienTichSan" onChange={updateThongTinSuaChuaNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích xây dựng tầng một </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.thongTinSuaChuaDTO?.dtXayDungTangMot ? data?.thongTinSuaChuaDTO?.dtXayDungTangMot.toString() : "") } name="dtXayDungTangMot" onChange={updateThongTinSuaChuaNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Chiều cao công trình </InputLabel>
                                <InputBase fullWidth
                                    value={StringUtil.viewNumberDot(data?.thongTinSuaChuaDTO?.chieuCaoCongTrinh ? data?.thongTinSuaChuaDTO?.chieuCaoCongTrinh.toString() : "") } name="chieuCaoCongTrinh" onChange={updateThongTinSuaChua} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số tầng</InputLabel>
                                <InputBase fullWidth
                                    value={data?.thongTinSuaChuaDTO?.soTang} name="soTang" onChange={updateThongTinSuaChua} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>Được phép sửa chữa, cải tạo với nội dung sau</h6>
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Cấp công trình </InputLabel>
                                <Autocomplete
                                    options={capCongTrinhs}
                                    value={{ ma: data?.thongTinSuaChuaDTO?.maCapCongTrinh ? data?.thongTinSuaChuaDTO?.maCapCongTrinh : "", ten: data?.thongTinSuaChuaDTO?.tenCapLoaiCongTrinh ? data?.thongTinSuaChuaDTO?.tenCapLoaiCongTrinh : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => updateThongTinSuaChuaSelect(newValue, 'maCapCongTrinh', 'tenCapLoaiCongTrinh')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Loại công trình </InputLabel>
                                <Autocomplete
                                    options={loaiCongTrinhs}
                                    value={{ ma: data?.thongTinSuaChuaDTO?.maLoaiCongTrinh ? data?.thongTinSuaChuaDTO?.maLoaiCongTrinh : "", ten: data?.thongTinSuaChuaDTO?.tenLoaiCongTrinh ? data?.thongTinSuaChuaDTO?.tenLoaiCongTrinh : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => updateThongTinSuaChuaSelect(newValue, 'maLoaiCongTrinh', 'tenLoaiCongTrinh')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích cải tạo </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.thongTinSuaChuaDTO?.dienTichCaiTao ? data?.thongTinSuaChuaDTO?.dienTichCaiTao.toString() : "")} name="dienTichCaiTao" onChange={updateThongTinSuaChuaNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Tổng diện tích sàn </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.thongTinSuaChuaDTO?.tongDienTichSanSuaChua ? data?.thongTinSuaChuaDTO?.tongDienTichSanSuaChua.toString() : "") } name="tongDienTichSanSuaChua" onChange={updateThongTinSuaChuaNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Chiều cao </InputLabel>
                                <InputBase fullWidth
                                    value={StringUtil.viewNumberDot(data?.thongTinSuaChuaDTO?.chieuCao ? data?.thongTinSuaChuaDTO?.chieuCao.toString() : "") } name="chieuCao" onChange={updateThongTinSuaChua} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số tầng </InputLabel>
                                <InputBase fullWidth
                                    value={data?.thongTinSuaChuaDTO?.soTangSuaChua} name="soTangSuaChua" onChange={updateThongTinSuaChua} />
                            </Grid>
                            {
                                !isNhapDauKy &&
                                <>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel>Số giấy phép cũ (nếu có) </InputLabel>
                                        <Autocomplete id="soGiayPhepCu"
                                            options={dataAutocomple}
                                            value={data}
                                            noOptionsText={"Không có dữ liệu"}
                                            getOptionLabel={(option) => option.soGiayPhep}
                                            onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                            renderInput={(params) => {
                                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                                    <InputBase fullWidth
                                                        {...params.inputProps}
                                                        value={data.soGiayPhepCu}
                                                        onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}
                                                    />
                                                </div>
                                            }}
                                        />

                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel>Ngày cấp phép cũ</InputLabel>
                                        <InputBase fullWidth
                                            value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu !== null) ? moment(data.ngayCapPhepCu).format("DD/MM/YYYY") : ''} disabled name="ngayCapPhepCu" />
                                    </Grid>
                                </>
                            }
                            <DropZoneCustom
                                acceptFiles={ACCEPT_FILES}
                                attachFileServer={data.files}
                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/cpxd"}
                                handleChangeStatus={handleChangeStatus}
                                handleDeleteFileServer={handleDeleteFileServer}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </Grid>
                    </Box>

                </Box>
            }
        </>
    )
}
export default NoiDungSuaChuaCaiTaoCPXD;