import {
    Box,
    Button,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    NativeSelect,
    Snackbar,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { Alert, Pagination } from "@material-ui/lab";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CapPhepXayDungService } from "../../../../api/CPXD/CapPhepXayDungService";
import { GIAY_PHEP_CHO_CAP_SO_INIT } from "../../../../api/CPXD/init/CapPhepXayDungInit";
import { GiayPhepChoCapSoDTO, GiayPhepXayDungChoCapSoRequest } from "../../../../api/CPXD/model/CapPhepXayDungModel";
import { getObject } from "../../../../helpers/localStorage";
import { LoadingAction } from "../../../../redux/actions/applicationAction";
import { CustomInput } from "../../../commons/components/input/customInput";
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import CapSoGiayPhepXayDungModal from "./CapSoGiayPhepXayDungModal";


export const CapGiayPhepXayDung = () => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false);


    const [total, setTotal] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState<GiayPhepChoCapSoDTO[]>([]);
    const [search, setSearch] = useState<GiayPhepXayDungChoCapSoRequest>({
        maQuan : C_USER_DATA.maQuanHuyen,
        maHoSo: '',
        chuDauTu: '',
        tenCongTrinh: '',
        diaChiCongTrinh: '',
        pageSize: 5,
        pageNumber: 1
    } as GiayPhepXayDungChoCapSoRequest);
    const [indexRow, setIndexRow] = useState(-1);
    const [dataRow, setDataRow] = useState({ ...GIAY_PHEP_CHO_CAP_SO_INIT } as GiayPhepChoCapSoDTO);
    const capPhepXayDungService = new CapPhepXayDungService();
    
    useEffect(() => {
        doSearch(search);
    }, []);

    const doSearch = (searchRequest: GiayPhepXayDungChoCapSoRequest) => {
        capPhepXayDungService.searchGiayPhepXayDungChoCapSo(searchRequest).then(response => {
            let result = response?.data;
            if (result && result.data) {
                setData(result?.data?.data !== undefined ? result?.data?.data : []);
                setTotal(result?.data?.total !== undefined ? result.data?.total : 0);
            } else {
                setData([]);
                setTotal(0);
            }
        }).catch(error => {
            setData([]);
            setTotal(0);
            console.log(error);
        });
    }
    const setParamSearch = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearch(search => ({
            ...search,
            [name]: value
        }));
    }
    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSearch(searchNew);
        doSearch(searchNew);
    };


    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageNumber: 1, pageSize: Number(event.target.value) };
        setSearch(searchNew);
        doSearch(searchNew);
    }

    const handSearch = () => {
        let searchNew = { ...search, pageNumber: 1, pageSize: 5 };
        setSearch(searchNew);
        doSearch(searchNew);
    }
    const handleToggle = () => {
        setShowModal(!showModal);
    }
    const handleSubmit = (success: boolean, mess: string) => {
        if (success) {
            setMessage(mess);
            setSuccess(true);
            // handleToggle();
            handSearch();
        } else {
            setMessage(mess);
            setSuccess(false);
        }
        setShowAlert(true);

    }
    const clickCapSo = (item: GiayPhepChoCapSoDTO, index: number) => {
        setDataRow(item);
        setIndexRow(index);
        setShowModal(true);
    }

    const showMessageAlert = (mess: string, success: boolean, isWarning?: boolean) =>{
        if (isWarning){
            setWarning(true);
        }
        setMessage(mess);
        setSuccess(success);
        setShowAlert(true);
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>CẬP NHẬT SỐ GIẤY PHÉP XÂY DỰNG</span>
            </div>
            <CapSoGiayPhepXayDungModal
                indexEdit={indexRow}
                data={dataRow}
                onToggle={handleToggle}
                show={showModal}
                onSubmit={handleSubmit} 
                showMessageAlert={showMessageAlert}
                />
            <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <Alert onClose={() => {setShowAlert(false); setWarning(false)}} severity={success ? 'success' : warning? 'warning':'error'}>{message}</Alert>
            </Snackbar>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={6} className="gridItem">
                    <InputLabel >Mã hồ sơ</InputLabel>
                    <InputBase fullWidth
                        name="maHoSo"
                        value={search.maHoSo} onChange={setParamSearch}
                        inputProps={{ 'aria-label': 'description' }} />
                </Grid>
                <Grid item xs={6} className="gridItem">
                    <InputLabel >Chủ đầu tư</InputLabel>
                    <InputBase fullWidth
                        name="chuDauTu"
                        value={search.chuDauTu} onChange={setParamSearch}
                        inputProps={{ 'aria-label': 'description' }} />
                </Grid>
                <Grid item xs={6} className="gridItem">
                    <InputLabel >Tên công trình</InputLabel>
                    <InputBase fullWidth
                        name="tenCongTrinh"
                        value={search.tenCongTrinh} onChange={setParamSearch}
                        inputProps={{ 'aria-label': 'description' }} />
                </Grid>
                <Grid item xs={6} className="gridItem">
                    <InputLabel >Địa chỉ công trình</InputLabel>
                    <InputBase fullWidth
                        name="diaChiCongTrinh"
                        value={search.diaChiCongTrinh} onChange={setParamSearch}
                        inputProps={{ 'aria-label': 'description' }} />
                </Grid>
                <Grid item xs={12} className="button-group-center">
                    <Button variant="contained" color="primary" disableElevation
                        onClick={handSearch}>
                        Tìm kiếm
                    </Button>
                </Grid>

                <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                                <Cell component="th" scope="row" align="center" width="10%">MÃ HỒ SƠ</Cell>
                                <Cell component="th" scope="row" align="center" width="15%">CHỦ ĐẦU TƯ</Cell>
                                <Cell component="th" scope="row" align="center" width="15%">TÊN CÔNG TRÌNH</Cell>
                                <Cell component="th" scope="row" align="center" >ĐỊA CHỈ CÔNG TRÌNH</Cell>
                                <Cell component="th" scope="row" align="center" width="10%">SỐ GIẤY PHÉP</Cell>
                                <Cell component="th" scope="row" align="center" width="15%">LOẠI GIẤY PHÉP</Cell>
                                <Cell component="th" scope="row" align="center" width="10%">THAO TÁC</Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {total > 0 ?
                                data?.map((row, i) => (
                                    <Row key={row.giayPhepXayDungId}>
                                        <Cell scope="row" align="center">
                                            {((search.pageNumber - 1) * search.pageSize) + i + 1}
                                        </Cell>
                                        <Cell align="left">{row.maHoSo}</Cell>
                                        <Cell align="left">{row.chuDuTu}</Cell>
                                        <Cell align="left">{row.tenCongTrinh}</Cell>
                                        <Cell align="left">{row.diaChi}</Cell>
                                        <Cell align="left">{row.soGiayPhep}</Cell>
                                        <Cell align="left">{row.tenLoaiGiayPhep}</Cell>
                                        <Cell align="center">
                                            <Box display="flex" justifyContent="center">
                                                <IconButton onClick={(event) => clickCapSo(row, i)} color="primary" aria-label="edit" size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            </Box>
                                        </Cell>
                                    </Row>
                                ))
                                :
                                <Row key={'empty'}>
                                    <Cell colSpan={8} scope="row" align="center">
                                        Không có dữ liệu
                                        </Cell>
                                </Row>
                            }
                        </TableBody>
                    </Table>
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination count={Math.ceil(total / search.pageSize)} page={search.pageNumber} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                            <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">Mẫu tin {((search.pageNumber - 1) * search.pageSize) + 1} -  {(search.pageNumber * search.pageSize)} / {total}</Box>
                    </Box>
                </TableContainer>
            </Grid>
        </div>
    )
}

