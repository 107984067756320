import { Button, Grid, InputBase, InputLabel, Snackbar, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { RequestDTO, REQUEST_DTO_INIT } from "../../../../api/DKKD/capSoGiayPhepKinhDoanhService";
import { BaseResponse } from "../../../../api/baseService";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { Autocomplete } from "@material-ui/lab";
import CapSoGiayPhepModal from "../CapSoGiayPhep/CapSoGiayPhepModal";
import { HoSoThuLyBLThuocLa } from "../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import { QuanLyKDTLService } from "../../../../api/CPBLTL/QuanLyKDTLService";
import { DanhSachHoSoCapSoGPKDThuocLa } from "./DanhSachHoSoCapSoGPKDThuocLa";
import { DanhMucService } from "../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../api/danhMuc/model/DanhMucDTO";
import StringUtil from "../../../utils/stringUtils";
import { getObject } from "../../../../helpers/localStorage";



export const HoSoCapSoGiayPhepKinhDoanhThuocLa = () => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [index, setIndex] = useState(-1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataList, setDataList] = useState<HoSoThuLyBLThuocLa[]>([]);
    const [itemSeleted, setItemSeleted] = useState<HoSoThuLyBLThuocLa>({} as HoSoThuLyBLThuocLa);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [phuongTruSos, setPhuongTruSos] = useState<DanhMucDiaChiDTO[]>([]);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const formik = useFormik({
        initialValues: {
            request: {
                ...REQUEST_DTO_INIT,
                maQuan : C_USER_DATA.maQuanHuyen,
                tenQuan : C_USER_DATA.tenQuanHuyen,
                maPhuong : '',
                tenPhuong : ''
            }
        },
        onSubmit: () => loadDataTable(),
    });

    useEffect(() => {
        getQuan('92');
    }, []);


    useEffect(() => {
        loadDataTable();
    }, [page, size])

   
    const getQuan = async (maQuan : string) => {
        await new DanhMucService().getDanhMucDiaChi(String(maQuan)).then((resp) => {
            setQuans(resp);
        });
        
    }

    const loadPhuongs = (maQuan: string) => {
        if (!StringUtil.isEmty(maQuan)) {
            danhMucService.getDanhMucDiaChi(maQuan).then(res => {
                setPhuongTruSos(res);
            }).catch(error => {
                console.log(error);
            });
        }
    }
    
    const loadDataTable = async (index?:number) => {
        let request = {
            ...formik.values.request,
            pageSize: size,
            pageNumber: page,
        } as RequestDTO;
        dispatchLoading(showLoading());
        await new QuanLyKDTLService().timKiem(request).then((p) => {
            setDataList(p.rows);
            setTotal(p.total);

            if (index !== undefined && index >-1){
                setItemSeleted(p.rows[index]);
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1);
        setSize(Number(event.target.value));
    }

    const showEditDialog = async (item: HoSoThuLyBLThuocLa) => {
        dispatchLoading(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatchLoading(hideLoading());
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true, isWarning?:boolean) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : isWarning? 'warning' : 'error' });
    }

    const handleSubmitData = (resp: BaseResponse, index?:number) => {
        if (resp.isSuccess) {
            // setOpenDialog(false);
            setPage(1);
            loadDataTable(index);
            showMessageAlert('Cấp số giấy phép thành công!');
        } else {
            showMessageAlert(resp.message, false);
        }
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>CẬP NHẬT SỐ GIẤY PHÉP BÁN LẺ SẢN PHẨM THUỐC LÁ</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Mã hồ sơ</InputLabel>
                        <InputBase fullWidth name="request.maHoSo"
                            value={formik.values.request.maHoSo} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Tên hộ kinh doanh</InputLabel>
                        <InputBase fullWidth name="request.keywords"
                            value={formik.values.request.keywords} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Huyện/Thị/TP</InputLabel>
                        {
                            StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                            <Autocomplete id="maQuan" options={quans}
                                value={{ code: formik.values?.request?.maQuan ? formik.values.request?.maQuan : "", name: formik.values?.request?.tenQuan ? formik.values.request.tenQuan : "" }}
                                getOptionLabel={(option) => option?.name}
                                onChange={(event: any, newValue: any) => {
                                    formik.setFieldValue("request.maPhuong", "");
                                    formik.setFieldValue("request.tenPhuong", "");
                                    formik.setFieldValue("request.maQuan", newValue?.code);
                                    formik.setFieldValue("request.tenQuan", newValue?.name);
                                }}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth {...params.inputProps} />
                                    </div>
                                }}
                            />
                        }
                        {
                            !StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                            <InputBase disabled fullWidth value={formik.values.request.tenQuan} />
                        }
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Phường/Xã/TT</InputLabel>
                        <Autocomplete id="maPhuong" options={phuongTruSos}
                            onFocus={(e) => loadPhuongs(formik.values?.request?.maQuan)}
                            value={{ code: formik.values?.request?.maPhuong ? formik.values.request?.maPhuong : "", name: formik.values?.request?.tenPhuong ? formik.values.request.tenPhuong : "" }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maPhuong", newValue?.code);
                                formik.setFieldValue("request.tenPhuong", newValue?.name);
                            }}
                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <InputBase fullWidth {...params.inputProps} />
                                </div>
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className="button-group-center">
                        <Button onClick={() => loadDataTable()} type="button" variant="contained" color="primary" disableElevation >
                            Tìm kiếm
                        </Button>
                    </Grid>

                    <Grid item xs={12} className="grid-item">
                        <DanhSachHoSoCapSoGPKDThuocLa dataList={dataList}
                            pageProps={{
                                page: page, count: total, rowsPerPage: size,
                                onChangePage: handleOnChangePage,
                                onChangeRowsPerPage: handOnChangeRowsPerPage
                            }}
                            modalEdit={{ open: true, openEditDialog: showEditDialog }}
                        />
                    </Grid>
                    {
                        openDialog &&
                            <CapSoGiayPhepModal 
                                openDialog={openDialog} 
                                onDialogClose={() => { setOpenDialog(false) }} 
                                itemEdit={itemSeleted} 
                                onSubmitData={handleSubmitData} 
                                showMessageAlert={showMessageAlert}
                        />
                    }
                </Grid>
            </form>
            <OverlayLoading />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div >
    )
}

