import { Box, Button, Checkbox, FormControlLabel, FormHelperText, Grid, InputBase, InputLabel, NativeSelect, TextField } from "@material-ui/core";
import { getIn, useFormik } from "formik";
import React from "react";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { Type } from "../../../../../../api/KhenThuong/danhMuc/danhMucKhenThuong";
import { HoSoKhenThuongDTO } from "../../../../../../api/KhenThuong/models/hoSoKhenThuongDTO";
import { KhenThuongTapTheDTO, LoaiKhenThuong } from "../../../../../../api/KhenThuong/models/khenThuongDTO";
import { useDanhMucKhenThuong } from "../../../../../../hooks/useDanhMucKhenThuong";
import { CustomNativeSelect } from "../../../../../commons/components/input/CustomNativeSelect";
import StringUtil from "../../../../../utils/stringUtils";
import { useLoaiHinhKhenThuong } from "../../../../../../hooks/useLoaiHinhKhenThuong";

interface EditFormKhenThuongCaNhanPropsI {
    onSave: (data: KhenThuongTapTheDTO) => void;
    onDoBack?: () => void;
    dataKhenThuong: KhenThuongTapTheDTO;
    formValues?: HoSoKhenThuongDTO;
}

interface FormValues {
    formDTO: KhenThuongTapTheDTO;
}

export const EditFormKhenThuongTapThe = (props: EditFormKhenThuongCaNhanPropsI) => {
    const { onDoBack, onSave, dataKhenThuong, formValues } = props;

    const dmHinhThuc = useDanhMucKhenThuong(Type.HINH_THUC);
    const dmDanhHieu = useDanhMucKhenThuong(Type.DANH_HIEU);
    const [disable, setDisable] = useState(false);
    const [disable2, setDisable2] = useState(false);
    const dmLoaiHinh = useLoaiHinhKhenThuong(false);


    const initDefaultValue: FormValues = {
        formDTO: { ...dataKhenThuong, 
            loaiHinhKhenThuongId: 0, 
            loaiHinhKhenThuongString: "" ,
            hoSoThuLyId: Number(props.formValues?.id),
        },
    };

    const validateionSchema = Yup.object().shape({
        formDTO: Yup.object({
            loaiHinhKhenThuongId: Yup.string().required("loại hình khen thưởng bắt buộc nhập"),
            tenTapThe: Yup.string().required("Tên tập thể bắt buộc nhập"),
        }),
    });

    const formik = useFormik({
        initialValues: initDefaultValue,
        enableReinitialize: false,
        validationSchema: validateionSchema,
        onSubmit: onSubmitData,
    });

    function onSubmitData() {
        onSave(formik.values.formDTO);
    }

    function onChangeDisable(e: any) {
        if (e.target.value=="0"){
            setDisable2(false);
        }else{
            setDisable2(true);
        }
    }

    function onChangeDisable2(e: any) {
        if (e.target.value=="0"){
            setDisable(false);
        }else{
            setDisable(true);
        }
    }

    useEffect(() => {
        if (!StringUtil.isNullOrEmty(formik.values.formDTO.danhHieuThiDuaId)){
            setDisable(true);
        }
        if (!StringUtil.isNullOrEmty(formik.values.formDTO.hinhThucKhenThuongId)){
            setDisable2(true);
        }
    }, []);

    return (
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid item xs={6} className="grid-item">
                    <InputLabel required >
                        Loại hình khen thưởng
                    </InputLabel>
                    <NativeSelect
                        value={formik.values.formDTO.loaiHinhKhenThuongId}
                        name="formDTO.loaiHinhKhenThuongId"
                        onChange={(e) => {
                            formik.setFieldValue("formDTO.loaiHinhKhenThuongId", e.target.value);
                            formik.setFieldValue("formDTO.loaiHinhKhenThuongString", e.target.options[e.target.selectedIndex].text);
                        }}
                        error={Boolean(getIn(formik.touched, "formDTO.loaiHinhKhenThuongId") && getIn(formik.errors, "formDTO.loaiHinhKhenThuongId"))}
                        input={<CustomNativeSelect fullWidth />}
                    >
                        <option value={""}>Chọn loại hình khen thưởng</option>
                        {dmLoaiHinh &&
                                dmLoaiHinh.map((item, i) => (
                                    <option key={i} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}

                    </NativeSelect>
                    {Boolean(getIn(formik.touched, "formDTO.loaiHinhKhenThuongId") && getIn(formik.errors, "formDTO.loaiHinhKhenThuongId")) && (
                        <FormHelperText className="-error" >
                            {formik.errors.formDTO?.loaiHinhKhenThuongId}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel required>Tên tập thể</InputLabel>
                    <InputBase
                        fullWidth
                        required
                        name="formDTO.tenTapThe"
                        value={formik.values.formDTO.tenTapThe}
                        onChange={formik.handleChange}
                        error={Boolean(getIn(formik.touched, "formDTO.tenTapThe") && getIn(formik.errors, "formDTO.tenTapThe"))}
                    />
                    {Boolean(getIn(formik.touched, "formDTO.tenTapThe") && getIn(formik.errors, "formDTO.tenTapThe")) && (
                        <FormHelperText className="-error" >
                            {formik.errors.formDTO?.tenTapThe}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <InputLabel>Địa chỉ</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.diaChi"
                        value={formik.values.formDTO.diaChi}
                        onChange={formik.handleChange}
                    />
                </Grid>

                <Grid item xs={3} className="grid-item">
                    <InputLabel>Số điện thoại</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.soDienThoai"
                        value={formik.values.formDTO.soDienThoai}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Fax</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.fax"
                        value={formik.values.formDTO.fax}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Email</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.email"
                        value={formik.values.formDTO.email}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Website</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.webSite"
                        value={formik.values.formDTO.webSite}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Tổng số cán bộ</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.tongSoCanBo"
                        value={formik.values.formDTO.tongSoCanBo}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Tổng số công chức</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.tongSoCongChuc"
                        value={formik.values.formDTO.tongSoCongChuc}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Tổng số viên chức</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.tongSoVienChuc"
                        value={formik.values.formDTO.tongSoVienChuc}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <FormControlLabel
                        style={{ paddingTop: 25 }}
                        label={<span className="label-checkbox">Doanh nghiệp</span>}
                        control={<Checkbox checked={formik.values.formDTO.doanhNghiep} name="formDTO.doanhNghiep" color="default" size="small" onChange={formik.handleChange} />}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Cơ cấu tổ chức</InputLabel>
                    <InputBase name="formDTO.coCauToChuc" value={formik.values.formDTO.coCauToChuc} onChange={formik.handleChange} fullWidth rows={3} multiline />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Chức năng, nhiệm vụ được giao</InputLabel>
                    <InputBase name="formDTO.chucNangNhiemVu" value={formik.values.formDTO.chucNangNhiemVu} onChange={formik.handleChange} fullWidth rows={3} multiline />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Hình thức khen thưởng</InputLabel>
                    <NativeSelect
                        name="formDTO.hinhThucKhenThuongId"
                        value={formik.values.formDTO.hinhThucKhenThuongId}
                        onChange={(e) => {
                            formik.setFieldValue("formDTO.hinhThucKhenThuongId", e.target.value);
                            formik.setFieldValue("formDTO.hinhThucKhenThuongString", e.target.options[e.target.selectedIndex].text);
                            onChangeDisable(e);
                        }}
                        input={<CustomNativeSelect fullWidth />}
                        hidden={disable}
                    >
                        <option value="0">Chọn hình thức khen thưởng</option>
                        {dmHinhThuc && dmHinhThuc.map((item, i) => <option value={item.id}>{item.name}</option>)}
                    </NativeSelect>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel>Danh hiệu thi đua</InputLabel>
                    <NativeSelect
                        name="formDTO.danhHieuThiDuaId"
                        value={formik.values.formDTO.danhHieuThiDuaId}
                        onChange={(e) => {
                            formik.setFieldValue("formDTO.danhHieuThiDuaId", e.target.value);
                            formik.setFieldValue("formDTO.danhHieuThiDuaString", e.target.options[e.target.selectedIndex].text);
                            onChangeDisable2(e);
                        }}
                        input={<CustomNativeSelect fullWidth />}
                        hidden={disable2}
                    >
                        <option value="0">Chọn danh hiệu thi đua</option>
                        {dmDanhHieu &&
                            dmDanhHieu.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel>Số tiền</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.soTien"
                        value={formik.values.formDTO.soTien}
                        onChange={(e) => formik.setFieldValue("formDTO.soTien", StringUtil.formatMoney(e.target.value))}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item"></Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Thành tích khen thưởng</InputLabel>
                    <InputBase name="formDTO.thanhTichKhenThuong" value={formik.values.formDTO.thanhTichKhenThuong} onChange={formik.handleChange} fullWidth rows={3} multiline />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Tóm tắt thành tích</InputLabel>
                    <InputBase name="formDTO.tomTatThanhTich" value={formik.values.formDTO.tomTatThanhTich} onChange={formik.handleChange} fullWidth rows={3} multiline />
                </Grid>

                <Grid item xs={12} className="grid-item ">
                    <Box className="button-group" marginTop="5px">
                        <Button type="submit" variant="contained" color="primary" disableElevation>
                            Lưu
                        </Button>
                        <Button variant="contained" color="default" disableElevation onClick={onDoBack}>
                            Trở về
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};
