export interface TraCuuThongTinCDRequest{
    CMND: string;
    hoVaTen: string;
    ngaySinh: Number | null;
    chiNhapNamSinh: boolean;
    isCaNhan: boolean;
    cmndThanNhan: string;
    pageNumber : number;
    pageSize :number;
    maCanBo : string
    isCCCD : number;
}

export const TRA_CUU_THONG_TIN_CONG_DAN_INIT : TraCuuThongTinCDRequest= {
    isCCCD : 0,
    CMND: '',
    hoVaTen: '',
    ngaySinh: null,
    chiNhapNamSinh: false,
    isCaNhan : true,
    cmndThanNhan: '',
    maCanBo: '',
    pageSize: 5,
    pageNumber: 1,
}

export interface DiaChiCongDan{
    maTinhThanh: string;
    tenTinhThanh: string;
    maPhuongXa: string;
    tenPhuongXa: string;
    tenQuanHuyen: string;
    chiTiet : string;
    quocGia: string;
    fullDiaChi : string;

}

export const DIA_CHI_CONG_DAN_INIT : DiaChiCongDan= {
    maTinhThanh: '',
    tenTinhThanh: '',
    maPhuongXa: '',
    tenPhuongXa: '',
    chiTiet : '',
    quocGia: '',
    fullDiaChi : '',
    tenQuanHuyen:'',
}

export interface ChuHo {
    maTrangThai : number;
    hoVaTen: string;
    soDinhDanh: string;
    soCMND: string;
    ngaySinh: string;
    tenDanToc: string;
    tenGioiTinh: string;
    tenTonGiao: string;
    tinhTrangHonNhan: string;
    tenQuocTich: string;
    soHoKhau: string;
    noiDangKyKhaiSinh: DiaChiCongDan;
    queQuan: DiaChiCongDan;
    thuongTru: DiaChiCongDan;
    noiOHienTai: DiaChiCongDan;
    thanhViens : Array<ThanhVien>;
}

export const CHU_HO_INIT : ChuHo= {
    maTrangThai : 0,
    hoVaTen: '',
    soDinhDanh: '',
    soCMND: '',
    ngaySinh: '',
    tenDanToc: '',
    tenGioiTinh: '',
    tenTonGiao: '',
    tinhTrangHonNhan: '',
    tenQuocTich: '',
    soHoKhau: '',
    noiDangKyKhaiSinh: {...DIA_CHI_CONG_DAN_INIT},
    queQuan: {...DIA_CHI_CONG_DAN_INIT},
    thuongTru: {...DIA_CHI_CONG_DAN_INIT},
    noiOHienTai: {...DIA_CHI_CONG_DAN_INIT},
    thanhViens : new Array<ThanhVien>()
}


export interface ThanhVien{
    hoVaTen: string;
    soDinhDanh: string;
    soCMND: string;
    tenQuocTich: string;
    tenQuanHe:string 
    thanhVien : string
}

