import { Box, Button, FormControlLabel, FormHelperText, Grid, InputBase, InputLabel, NativeSelect, Radio, RadioGroup, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getIn, useFormik } from "formik";
import { useState,useEffect } from "react";
import * as Yup from "yup";
import { Type } from "../../../../../../api/KhenThuong/danhMuc/danhMucKhenThuong";
import { HoSoKhenThuongDTO } from "../../../../../../api/KhenThuong/models/hoSoKhenThuongDTO";
import { DoiTuongKhenThuong, KhenThuongCaNhanDTO, LoaiKhenThuong } from "../../../../../../api/KhenThuong/models/khenThuongDTO";
import { useDanhMucKhenThuong } from "../../../../../../hooks/useDanhMucKhenThuong";
import { useDanhMucNguonKinhPhi } from "../../../../../../hooks/useDanhMucNguonKinhPhi";
import { CustomNativeSelect } from "../../../../../commons/components/input/CustomNativeSelect";
import StringUtil from "../../../../../utils/stringUtils";
import { useLoaiHinhKhenThuong } from "../../../../../../hooks/useLoaiHinhKhenThuong";


interface EditFormKhenThuongCaNhanPropsI {
    onSave: (data: KhenThuongCaNhanDTO) => void;
    onDoBack?: () => void;
    dataKhenThuong: KhenThuongCaNhanDTO;
    formValues?: HoSoKhenThuongDTO;
}

interface FormValues {
    formDTO: KhenThuongCaNhanDTO;
}

export const EditFormKhenThuongCaNhan = (props: EditFormKhenThuongCaNhanPropsI) => {
    const { onDoBack, onSave, dataKhenThuong } = props;
    const dmLoaiHinh = useLoaiHinhKhenThuong(true);

    const dmHinhThuc = useDanhMucKhenThuong(Type.HINH_THUC);
    const dmDanhHieu = useDanhMucKhenThuong(Type.DANH_HIEU);
    const dmNguonKinhPhi = useDanhMucNguonKinhPhi();

    const [disable, setDisable] = useState(false);
    const [disable2, setDisable2] = useState(false);

    const initDefaultValue: FormValues = {
        formDTO: { ...dataKhenThuong, 
            loaiHinhKhenThuongId: 0, 
            loaiHinhKhenThuongString: "",
            hoSoThuLyId: Number(props.formValues?.id),
        },
    };

    const validateionSchema = Yup.object().shape({
        formDTO: Yup.object({
            loaiHinhKhenThuongId: Yup.string().required("loại hình khen thưởng bắt buộc nhập"),
            hoTen: Yup.string().required("Họ và tên bắt buộc nhập"),
        }),
    });

    const formik = useFormik({
        initialValues: initDefaultValue,
        enableReinitialize: false,
        validationSchema: validateionSchema,
        onSubmit: onSubmitData,
    });

    function onSubmitData() {
        onSave(formik.values.formDTO);
    }

    function onChangeDisable(e: any) {
        if (e.target.value=="0"){
            setDisable2(false);
        }else{
            setDisable2(true);
        }
    }

    function onChangeDisable2(e: any) {
        if (e.target.value=="0"){
            setDisable(false);
        }else{
            setDisable(true);
        }
    }

    useEffect(() => {
        if (!StringUtil.isNullOrEmty(formik.values.formDTO.danhHieuThiDuaId)){
            setDisable(true);
        }
        if (!StringUtil.isNullOrEmty(formik.values.formDTO.hinhThucKhenThuongId)){
            setDisable2(true);
        }
    }, []);

    return (
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid item xs={3} className="grid-item">
                    <InputLabel required >
                        Loại hình khen thưởng
                    </InputLabel>
                    <NativeSelect
                        value={formik.values.formDTO.loaiHinhKhenThuongId}
                        name="formDTO.loaiHinhKhenThuongId"
                        onChange={(e) => {
                            formik.setFieldValue("formDTO.loaiHinhKhenThuongId", e.target.value);
                            formik.setFieldValue("formDTO.loaiHinhKhenThuongString", e.target.options[e.target.selectedIndex].text);
                        }}
                        error={Boolean(getIn(formik.touched, "formDTO.loaiHinhKhenThuongId") && getIn(formik.errors, "formDTO.loaiHinhKhenThuongId"))}
                        input={<CustomNativeSelect fullWidth />}
                    >
                        <option value={""}>Chọn loại hình khen thưởng</option>
                        {dmLoaiHinh &&
                                dmLoaiHinh.map((item, i) => (
                                    <option key={i} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}

                    </NativeSelect>
                    {Boolean(getIn(formik.touched, "formDTO.loaiHinhKhenThuongId") && getIn(formik.errors, "formDTO.loaiHinhKhenThuongId")) && (
                        <FormHelperText className="-error" >
                            {formik.errors.formDTO?.loaiHinhKhenThuongId}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel required>Họ và tên</InputLabel>
                    <InputBase
                        fullWidth
                        required
                        name="formDTO.hoTen"
                        value={formik.values.formDTO.hoTen}
                        onChange={formik.handleChange}
                        error={Boolean(getIn(formik.touched, "formDTO.hoTen") && getIn(formik.errors, "formDTO.hoTen"))}
                    />
                    {Boolean(getIn(formik.touched, "formDTO.hoTen") && getIn(formik.errors, "formDTO.hoTen")) && (
                        <FormHelperText className="-error" >
                            {formik.errors.formDTO?.hoTen}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel required >
                        Đối tượng
                    </InputLabel>
                    <RadioGroup row style={{ flexWrap: "inherit" }} name="formDTO.doiTuong" value={String(formik.values.formDTO.doiTuong)} onChange={formik.handleChange}>
                        <FormControlLabel value={DoiTuongKhenThuong.LANH_DAO} control={<Radio size="small" color="default" />} label="Lãnh đạo quản lý từ cấp phòng trở lên" />
                        <FormControlLabel
                            value={DoiTuongKhenThuong.CANBO}
                            control={<Radio size="small" color="default" />}
                            label="Công nhân, nông dân, chiến sĩ, người lao động trực tiếp, CC, VC không làm công tác quản lý"
                        />
                    </RadioGroup>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Ngày sinh</InputLabel>
                    <KeyboardDatePicker
                        value={formik.values.formDTO.ngaySinh}
                        onChange={(date, _val) => formik.setFieldValue("formDTO.ngaySinh", date)}
                        autoOk={true}
                        fullWidth
                        format="DD/MM/YYYY"
                        InputAdornmentProps={{ className: "button-date-picker" }}
                        variant="inline"

                        InputProps={{ disableUnderline: true }}
                        invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                        maxDate={new Date()}
                        maxDateMessage="Ngày sinh không được lớn hơn ngày hiện tại"
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Nghề nghiệp</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.ngheNghiep"
                        value={formik.values.formDTO.ngheNghiep}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel>Đơn vị</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.donVi"
                        value={formik.values.formDTO.donVi}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel>Chức vụ</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.chucVu"
                        value={formik.values.formDTO.chucVu}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel>Quê quán</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.queQuan"
                        value={formik.values.formDTO.queQuan}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <InputLabel>Nơi thường trú</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.noiThuongTru"
                        value={formik.values.formDTO.noiThuongTru}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Hình thức khen thưởng</InputLabel>
                    <NativeSelect
                        name="formDTO.hinhThucKhenThuongId"
                        value={formik.values.formDTO.hinhThucKhenThuongId}
                        onChange={(e) => {
                            formik.setFieldValue("formDTO.hinhThucKhenThuongId", e.target.value);
                            formik.setFieldValue("formDTO.hinhThucKhenThuongString", e.target.options[e.target.selectedIndex].text);
                            onChangeDisable(e);
                        }}
                        input={<CustomNativeSelect fullWidth />}
                        hidden={disable}
                    >
                        <option value="0">Chọn hình thức khen thưởng</option>
                        {dmHinhThuc && dmHinhThuc.map((item, i) => <option value={item.id}>{item.name}</option>)}
                    </NativeSelect>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Danh hiệu thi đua</InputLabel>
                    <NativeSelect
                        name="formDTO.danhHieuThiDuaId"
                        value={formik.values.formDTO.danhHieuThiDuaId}
                        onChange={(e) => {
                            formik.setFieldValue("formDTO.danhHieuThiDuaId", e.target.value);
                            formik.setFieldValue("formDTO.danhHieuThiDuaString", e.target.options[e.target.selectedIndex].text);
                            onChangeDisable2(e);
                        }}
                        input={<CustomNativeSelect fullWidth />}
                        hidden={disable2}
                    >
                        <option value="0">Chọn danh hiệu thi đua</option>
                        {dmDanhHieu &&
                            dmDanhHieu.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel>Số tiền</InputLabel>
                    <InputBase
                        fullWidth
                        name="formDTO.soTien"
                        value={formik.values.formDTO.soTien}
                        onChange={(e) => formik.setFieldValue("formDTO.soTien", StringUtil.formatMoney(e.target.value))}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Nguồn kinh phí</InputLabel>
                    <NativeSelect
                        name="formDTO.nguonKinhPhiId"
                        value={formik.values.formDTO?.nguonKinhPhiId}
                        onChange={(e) => {
                            formik.setFieldValue("formDTO.nguonKinhPhiId", e.target.value);
                            formik.setFieldValue("formDTO.nguonKinhPhi", e.target.options[e.target.selectedIndex].text);
                        }}
                        input={<CustomNativeSelect fullWidth />}
                    >
                        <option value="">Chọn nguồn kinh phí</option>
                        {dmNguonKinhPhi && dmNguonKinhPhi.map((item, i) => <option value={item.id}>{item.ten}</option>)}
                    </NativeSelect>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Thành tích khen thưởng</InputLabel>
                    <InputBase name="formDTO.thanhTichKhenThuong" value={formik.values.formDTO.thanhTichKhenThuong} onChange={formik.handleChange} fullWidth rows={3} multiline />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Tóm tắt thành tích</InputLabel>
                    <InputBase name="formDTO.tomTatThanhTich" value={formik.values.formDTO.tomTatThanhTich} onChange={formik.handleChange} fullWidth rows={3} multiline />
                </Grid>
                <Grid item xs={12} className="grid-item ">
                    <Box className="button-group" marginTop="5px">
                        <Button type="submit" variant="contained" color="primary" disableElevation>
                            Lưu
                        </Button>
                        <Button variant="contained" color="default" disableElevation onClick={onDoBack}>
                            Trở về
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};
