import { Snackbar } from "@material-ui/core";
import React, { useState } from "react";
import { DanhSachKhenThuongService } from "../../../../../../api/KhenThuong/chuyenNganh/danhSachKhenThuongService";
import { HoSoKhenThuongDTO } from "../../../../../../api/KhenThuong/models/hoSoKhenThuongDTO";
import { KhenThuongTapTheDTO, LoaiKhenThuong } from "../../../../../../api/KhenThuong/models/khenThuongDTO";
import { useQuerySoBienNhan } from "../../../../../../hooks/useQuery";
import { AlertMessage, CustomAlert } from "../../../../../commons/components/alert";
import { DANH_SACH_TEN_NGHIEP_VU } from "../../../../../Constants/constants";
import { EditFormKhenThuongTapThe } from "./editFormKhenThuongTapThe";
import { FormMain } from "./formMain";

export const initDefaultValue = {
    id: null,
    loaiHinhKhenThuongId: 0,
    tenTapThe: "",
    thuTucId: DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_3.ID,
} as KhenThuongTapTheDTO;

export interface KhenThuongTapTheWrapperPropsI {
    formValues: HoSoKhenThuongDTO;
}

export const KhenThuongTapTheWrapper = (props: KhenThuongTapTheWrapperPropsI) => {
    const soBienNhan = useQuerySoBienNhan();
    const [hideEditForm, setHideEditForm] = useState(true);
    const [dataKhenThuong, setDataKhenThuong] = useState<KhenThuongTapTheDTO>(() => ({
        ...initDefaultValue,
        soBienNhan: soBienNhan,
        hoSoThuLyId: Number(props.formValues?.id),
    }));
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);

    function showMessageAlert(content: string, isSuccess: boolean = true) {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    }
    function handleCloseAlertMessage() {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    }

    async function handleSave(data: KhenThuongTapTheDTO) {
        await new DanhSachKhenThuongService().insertOrUpdateKhenThuongTapThe(data).then((resp) => {
            if (resp.isSuccess) {
                setHideEditForm(true);
                showMessageAlert(resp.message);
            } else {
                showMessageAlert(resp.message, false);
            }
        });
    }

    function handleEditData(data: KhenThuongTapTheDTO) {
        setDataKhenThuong(data);
        setHideEditForm(false);
    }

    return (
        <>
            {hideEditForm ? (
                <FormMain showMessageAfterDelete={showMessageAlert} onEditData={handleEditData} formValues={props.formValues}/>
            ) : (
                <EditFormKhenThuongTapThe dataKhenThuong={dataKhenThuong} onSave={handleSave} formValues={props.formValues} onDoBack={() => setHideEditForm(true)} />
            )}
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                    {alertMessage.content}
                </CustomAlert>
            </Snackbar>
        </>
    );
};
