import CLOSE_ICON from "../../Assets/icons/u1233.png";
import DELETE_ICON from '../../Assets/icons/u626.png';
import EDIT_ICON from '../../Assets/icons/u624.png';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import GradeIcon from '@material-ui/icons/Grade';
import BatteryStdIcon from '@material-ui/icons/BatteryStd';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import axios from "../../api/index";
export const ICONS = {
    close: CLOSE_ICON,
    delete: DELETE_ICON,
    edit: EDIT_ICON,
}
export const DKKD_CN = {
    value: 'DKKD',
    ten: 'Đăng ký kinh doanh'
}
export const THUOCLA_CN = {
    ten: 'Kinh doanh thuốc lá',
    value: 'KDTL'
}
export const SX_RUOU_CN = {
    ten: 'Sản xuất rượu',
    value: 'SXR'
}
export const BL_RUOU_CN = {
    ten: 'Bán lẻ rượu',
    value: 'BLR'
}
export const LPG_CN =     {
    ten: 'LPG chai',
    value: 'LPG'
}
export const LIST_CN = [
    THUOCLA_CN,
    SX_RUOU_CN,
    BL_RUOU_CN,
    LPG_CN,
    {
        ten: 'An toàn thực phẩm',
        value: 'ATTP'
    },
    {
        value: 'KT',
        ten: 'Khen thưởng'
    },
    DKKD_CN,
    {
        value: 'CPXD',
        ten: 'Cấp phép xây dựng'
    }
]
export const LIST_ICONS = [
    {
        ten: 'Cài đặt',
        icon: <SettingsIcon fontSize="small" />
    },
    {
        ten: 'Tệp tin',
        icon: <DescriptionIcon fontSize="small" />
    },
    {
        ten: 'Căn hộ, chung cư',
        icon: <ApartmentIcon fontSize="small" />
    },
    {
        ten: 'Thuốc lá',
        icon: <SmokingRoomsIcon fontSize="small" />
    },
    {
        ten: 'Rượu',
        icon: <LocalBarIcon fontSize="small" />
    },
    {
        ten: 'Ăn uống',
        icon: <FastfoodIcon fontSize="small" />
    },
    {
        ten: 'Chứng nhận',
        icon: <CardMembershipIcon fontSize="small" />
    },
    {
        ten: 'Ngôi sao',
        icon: <GradeIcon fontSize="small" />
    },
    {
        ten: 'Chai',
        icon: <BatteryStdIcon fontSize="small" />
    },
    {
        ten: 'Cúp',
        icon: <EmojiEventsIcon fontSize="small" />
    }

];
export const DECRYPT_KEY = "my-secret-key@123";
export const ACCEPT_FILES = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

export const DANH_SACH_TEN_NGHIEP_VU = {
    NGHIEPVU_SO_1: {
        ID: 1,
        NAME: "Tặng Giấy khen của Chủ tịch UBND cấp huyện về thành tích đột xuất",
    },
    NGHIEPVU_SO_2: {
        ID: 2,
        NAME: "Tặng Giấy khen của Chủ tịch UBND cấp huyện về thành tích thi đua theo đợt, chuyên đề",
    },
    NGHIEPVU_SO_3: {
        ID: 3,
        NAME: "Tặng giấy khen của chủ tịch ubnd cấp huyện về thành tích thực hiện nhiệm vụ chính trị",
    },
    NGHIEPVU_SO_4: {
        ID: 4,
        NAME: "Tặng danh hiệu Lao động tiên tiến",
    },
    NGHIEPVU_SO_5: {
        ID: 5,
        NAME: "Tặng danh hiệu chiến sỹ thi đua cơ sở",
    },
    NGHIEPVU_SO_6: {
        ID: 6,
        NAME: " Tặng Giấy khen của Chủ tịch UBND cấp huyện cho gia đình",
    }

};

export const URL = axios.defaults.baseURL + '/files/upload/';
export const URL_UPLOAD_FILE_TO_ALFRESCO = {
    NGHIEPVU_SO_1: URL + DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_1.ID,
    NGHIEPVU_SO_2: URL + DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_2.ID,
    NGHIEPVU_SO_3: URL + DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_3.ID,
    NGHIEPVU_SO_4: URL + DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_4.ID,
    NGHIEPVU_SO_5: URL + DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_5.ID,
    NGHIEPVU_SO_6: URL + DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_6.ID
};