import {Box, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, {useEffect, useState} from 'react';
import {Cell, Row} from "../../../commons/components/table/tableCommons";
import {CustomInput} from "../../../commons/components/input/customInput";
import moment from "moment";
import {Link} from "react-router-dom";
import {GiayPhepSXRuou} from "../../../../api/CPBLR/model/HoSoThuLyBLR";
import { ROUTE_CHITIET_GP_RUOU } from '../../../../routes/route-cn-ruou';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface IDataListProps {
    dataList: GiayPhepSXRuou[];
    pageProps: TablePaginationActionsProps;
}
const DanhSachBaoCaoRuouComponent = (props: IDataListProps) => {
    const [rows, setRows] = useState<GiayPhepSXRuou[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1);

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.page);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Số giấy phép</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Ngày cấp</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Ngày hết hạn</Cell>
                        <Cell component="th" scope="row" align="center" width="20%">Tên thương nhân</Cell>
                        <Cell component="th" scope="row" align="center" width="20%">Địa chỉ</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Điện thoại</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Tình trạng</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {((props.pageProps.page -1) * props.pageProps.rowsPerPage) + i + 1}
                                    </Cell>
                                    <Cell align="left">
                                        <Link to={{
                                            pathname: ROUTE_CHITIET_GP_RUOU.duongDan,
                                            state: { ...row}
                                        }}>
                                            {row.soGiayPhep}
                                        </Link>
                                    </Cell>
                                    <Cell align="left">{(row.ngayCapPhep !== 0 && row.ngayCapPhep !== null)  ? moment(row.ngayCapPhep).format("DD/MM/YYYY") : ''}</Cell>
                                    <Cell align="left">{(row.ngayHetHanGP !== 0 && row.ngayHetHanGP !== null)  ? moment(row.ngayHetHanGP).format("DD/MM/YYYY") : ''}</Cell>
                                    <Cell align="left">{row.tenThuongNhan}</Cell>
                                    <Cell align="left">{row.diaChiDayDu}</Cell>
                                    <Cell align="left">{row.dienThoaiTruSo}</Cell>
                                    <Cell align="left">{row.tenTinhTrang}</Cell>
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={8} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            {rows.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined" page={props.pageProps.page}
                                size="medium"
                                color="primary" shape="rounded" showFirstButton showLastButton onChange={props.pageProps.onChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage} >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin  {props.pageProps.page * props.pageProps.rowsPerPage - props.pageProps.rowsPerPage + 1} -{" "}
                            {props.pageProps.count - (props.pageProps.page - 1) * props.pageProps.rowsPerPage < props.pageProps.rowsPerPage ? props.pageProps.count : props.pageProps.page * props.pageProps.rowsPerPage} /{" "}
                            {props.pageProps.count}
                    </Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
export default DanhSachBaoCaoRuouComponent;
