import { BaseService, Pagination } from "../../baseService";
import api from "../../index";
import {RequestBaoCaoThongKeDTO} from "../../../App/Components/ATTP/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import { BienDongDTO, GiayPhepATTP, ThongKeTinhHinhCapPhepResponse } from "../Model/VSATTPModel";
import { RequestThongKeDTO } from "../../../App/Components/ATTP/ThongKe/ThongKeTinhHinhCapPhep";
import { RequestBieuDoThongKeDTO } from "../../../App/Components/ATTP/BieuDoTK/BieuDoThongKeATTP";
import { ChiTietThongKeCapPhepDTO } from "../Model/ChiTietThongKeCapPhepDTO";

export class ThongKeService extends BaseService{
    
    thongKe = async (request: RequestThongKeDTO): Promise<ThongKeTinhHinhCapPhepResponse> => {
        let result = {} as ThongKeTinhHinhCapPhepResponse;
        await api.post('vsattp/thong-ke/thong-ke-tinh-hinh-cap-phep', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data) {
                result = response.data.data;
            }
        }).catch();
        return result;
    }

    chiTietThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<ChiTietThongKeCapPhepDTO[]>> => {
        let result: Pagination<ChiTietThongKeCapPhepDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await api.post('vsattp/thong-ke/thong-ke-tinh-hinh-cap-phep/chi-tiet', request, this.getTokenRequestHeaders()).then(response => {
            console.log(response);
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    baoCaoThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<GiayPhepATTP[]>> => {
        let result: Pagination<GiayPhepATTP[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await api.post('vsattp/thong-ke/bao-cao-thong-ke-giay-phep', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    danhMucBienDong = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<BienDongDTO[]>> => {
        let result: Pagination<BienDongDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await api.post('danh-muc/bien-dong', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    downloadFileBaoCao =  (request: RequestBaoCaoThongKeDTO)=> {
        return api.get('vsattp/thong-ke/export-bao-cao-thong-ke-giay-phep', {
            responseType: 'blob',
            params: request
        });

    }
    downloadFileThongKe =  (request: RequestThongKeDTO)=> {
        return api.get('vsattp/thong-ke/export-thong-ke-tinh-hinh-cap-phep', {
            responseType: 'blob',
            params: request
        });

    }

    bieuDoThongke = (requestBody : RequestBieuDoThongKeDTO) => {
        return api.post('/vsattp/thong-ke/bieu-do-thong-ke',requestBody);
    }

    thongKeBieuDoTron = (requestBody : RequestThongKeDTO) => {
        return api.post('/vsattp/thong-ke/bieu-do-tron-thong-ke',requestBody);
    }

    thongKeBDTron = (requestBody : RequestBieuDoThongKeDTO) => {
        return api.post('/vsattp/thong-ke/bieu-do-tron-thong-ke',requestBody);
    }

}
