import { InputBase, NativeSelect, InputLabel, Box, Button, Grid, IconButton, Paper, TextField, Typography } from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { MarkerDTO } from "./index";
import { DanhMucDiaChiDTO, DiaChiDTO } from "../api/danhMuc/model/DanhMucDTO";
import { Pageable } from "../App/commons/components/table/commonTableChuyenNganhLazy";
import ResultItem from "./resultItem";
import { Pagination } from "../api/baseService";
import { bootstrapTextFieldHook } from "../App/commons/components/input/bootstrap";
import { Autocomplete } from "@material-ui/lab";
import { DanhMucService } from "../api/danhMuc/danhMucService";
import { PhuongThucKinhDoanhService } from "../api/DKKD/PhuongThucKinhDoanhService";

export interface FormValuesTraCuuMap {
    lopBanDo: Number | String;
    soGiayPhep: String;
    tenCoSo: String;
    diaChi?: DiaChiDTO;
    diaChiHuyenCode?: String;
    diaChiXaCode?: String;
    diaChiTinhCode?: String;
}

export interface DialogTraCuuProps {
    view: any;
    setOpenDialog: (value: boolean) => void;
    setArrMarker: (value: MarkerDTO[]) => void;
    arrMarker: MarkerDTO[];
    setItemEdit: (value: MarkerDTO | null) => void;
    itemEdit: MarkerDTO | null;
    valueEdit: MarkerDTO | null;
    handleSubmitSearch: (values: FormValuesTraCuuMap, pageParams: Pageable) => Promise<Pagination<MarkerDTO[]>>;
    handleSaveLatLong: (values: MarkerDTO) => Promise<MarkerDTO>;
    renderDetails: (item: MarkerDTO, showDetail: boolean) => JSX.Element;
}

const DialogTraCuu = ({
    setOpenDialog,
    setArrMarker,
    arrMarker,
    setItemEdit,
    itemEdit,
    valueEdit,
    handleSubmitSearch,
    handleSaveLatLong,
    renderDetails,
}: DialogTraCuuProps) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [pageParams, setPageParams] = React.useState<Pageable>({ page: 0, rowsPerPage: 5, total: 0 });
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const [quans, setQuans] = React.useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = React.useState<DanhMucDiaChiDTO[]>([]);
    const initialValues: any = {
        soGiayPhep: "",
        tenCoSo: "",
        maQuan : "",
        tenQuan : "",
        maPhuong : "",
        tenPhuong : "",
        className: "quanhuyen.api.model.dkkd.GiayPhepKinhDoanh"
    };

    const formik = useFormik({
        initialValues,
        onSubmit: handleSearch,
    });
    React.useEffect(() => {
        new DanhMucService().getDanhMucDiaChi(String(92)).then((resp) => {
            setQuans(resp);
        });
    }, []);
    async function onBlurPhuongXa() {
        if (formik.values.maQuan) {
            await new DanhMucService().getDanhMucDiaChi(formik.values.maQuan).then((resp) => {
                setPhuongs(resp);
            });
        }
    }

    React.useEffect(() => {
        if (arrMarker.length === 0 && !isSubmit) {
            formik.resetForm();
        }
    }, [arrMarker]);

    React.useEffect(() => {
        if (isSubmit) {
            handleSearch(formik.values);
        }
    }, [pageParams.page]);

    async function handleSearch(values: any) {
        try {
            setIsSubmit(true);
            const { rows, total } = await handleSubmitSearch({ ...values }, pageParams);
            setArrMarker([...rows] as MarkerDTO[]);
            setPageParams((prev) => ({ ...prev, total }));
        } catch (error) {
            console.log(error);
        }
    }

    async function handleSubmitEdit(values: MarkerDTO) {
        const index = arrMarker.findIndex((item) => values.id === item.id);
        const data = valueEdit && (await handleSaveLatLong({ ...values, ...valueEdit }));
        const newValues = { ...arrMarker[index], ...data };
        setArrMarker([...arrMarker.slice(0, index), { ...newValues }, ...arrMarker.slice(index + 1)]);
        setItemEdit(null);
    }

    function handleRemove() {
        setArrMarker([...arrMarker]);
        setItemEdit(null);
    }

    function handleOnChangePage(value: number) {
        setPageParams((pageParams) => ({ ...pageParams, page: value }));
    }

    function handleReset() {
        setIsSubmit(false);
        setArrMarker([]);
        setItemEdit(null);
        setPageParams({ page: 0, total: 0, rowsPerPage: 5 });
    }

    return (
        <Paper className="dialog_map">
            <Box
                style={{
                    padding: 10,
                    backgroundColor: "#ccc",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    fontWeight: 700,
                    color: "#fff",
                    marginBottom: 5,
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box>Tìm kiếm</Box>
                <CancelPresentationIcon style={{ cursor: "pointer" }} color="secondary" onClick={() => setOpenDialog(false)} />
            </Box>
            <Box padding={0}>
                <Grid container style={{ overflow: "scroll", maxHeight: "53vh", paddingLeft: 5, paddingRight: 5, paddingBottom: 10 }}>
                    {arrMarker.length === 0 && (
                        <>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles}>Lớp bản đồ</InputLabel>
                                <NativeSelect
                                    name="className"
                                    input={<InputBase fullWidth classes={inputStyles} />}
                                    value={formik.values.className}
                                    onChange={formik.handleChange}
                                >
                                    <option value={"quanhuyen.api.model.dkkd.GiayPhepKinhDoanh"}>
                                        Cơ sở kinh doanh hộ cá thể
                                    </option>
                                    <option value={"quanhuyen.api.model.attp.GiayPhepATTP"}>
                                        Cơ sở kinh doanh An toàn thực phẩm
                                    </option>
                                </NativeSelect>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Số chứng nhận"}
                                    type="text"
                                    value={formik.values.soGiayPhep}
                                    name="soGiayPhep"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Tên cơ sở"}
                                    type="text"
                                    value={formik.values.tenCoSo}
                                    name="tenCoSo"
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete
                                    id="maQuan"
                                    options={quans}
                                    value={{ code: formik.values.maQuan, name: formik.values.tenQuan }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => {
                                        formik.setFieldValue('maQuan',newValue?.code);
                                        formik.setFieldValue('tenQuan',newValue?.name);
                                        formik.setFieldValue('maPhuong','');
                                        formik.setFieldValue('tenPhuong','');
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <div className="padding-top-8px" ref={params.InputProps.ref}>
                                                <InputBase fullWidth  {...params.inputProps} />
                                            </div>
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={phuongs}
                                    value={{ code: formik.values.maPhuong, name: formik.values.tenPhuong }}
                                    getOptionLabel={(option) => option?.name}
                                    onFocus={onBlurPhuongXa}
                                    onChange={(event: any, newValue: any) => 
                                    {
                                        formik.setFieldValue('maPhuong',newValue?.code);
                                        formik.setFieldValue('tenPhuong',newValue?.name);
                                    }}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref}>
                                            <TextField  {...params} variant="outlined" />
                                        </div>
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                    {arrMarker.length > 0 &&
                        arrMarker.map((item: any, index: number) => (
                            <ResultItem
                                renderDetails={renderDetails}
                                key={index}
                                setItemEdit={setItemEdit}
                                itemEdit={itemEdit}
                                item={item}
                                handleSubmitEdit={handleSubmitEdit}
                                handleRemove={handleRemove}
                            />
                        ))}
                </Grid>
            </Box>
            {
                arrMarker.length > 0 && (
                    <Box style={{ display: "flex", marginTop: 10, justifyContent: "space-between" }} className="grid-item">
                        <Typography variant="subtitle2" gutterBottom style={{ paddingTop: 9 }}>
                            Đang hiển thị kết quả {pageParams.page * pageParams.rowsPerPage + 1} -{" "}
                            {pageParams.total < (pageParams.page + 1) * pageParams.rowsPerPage ? pageParams.total : (pageParams.page + 1) * pageParams.rowsPerPage}
                        </Typography>
                        <Box>
                            <IconButton size="small" disabled={pageParams.page === 0} onClick={() => handleOnChangePage(pageParams.page - 1)}>
                                <KeyboardArrowLeftIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                disabled={pageParams.page + 1 === Math.ceil(pageParams.total / pageParams.rowsPerPage)}
                                onClick={() => handleOnChangePage(pageParams.page + 1)}
                            >
                                <KeyboardArrowRightIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                )
            }
            <Box className="footer_map">
                {arrMarker.length === 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => formik.handleSubmit()}
                        style={{ marginLeft: 10, marginRight: 10 }}
                    >
                        Tìm kiếm
                    </Button>
                )}
                <Button
                    style={{ marginLeft: arrMarker.length === 0 ? 0 : 10 }}
                    id="btnHuy"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleReset}
                >
                    Làm mới
                </Button>
            </Box>
        </Paper >
    );
};

export default DialogTraCuu;
