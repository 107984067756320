import { BaseService, Pagination } from "../baseService";
import { ThongKeTinhHinhCapPhepResponse } from "./model/ThongKeTinhHinhCapPhepDTO";
import { RequestThongKeDTO } from "../../pages/dashboard/DKKD/DashboardThanhLapHoKinhDoanh";
import { RequestBaoCaoThongKeDTO } from "../../App/Components/DKKD/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import { BienDongDTO } from "./model/BienDongDTO";
import { RequestBieuDoThongKeDTO } from "../../App/Components/DKKD/BieuDoTK/BieuDoThongKeTinhHinhCapPhep";
import { ChiTietThongKeCapPhepDTO, DanhSachGiayPhepDKKDDTO } from "./model/DanhSachGiayPhepDKKDDTO";
import AXIOS_INSTANCE from "..";

export class ThongKeService extends BaseService {
    thongKe = async (request: RequestThongKeDTO): Promise<ThongKeTinhHinhCapPhepResponse> => {
        let result = {} as ThongKeTinhHinhCapPhepResponse;
        await AXIOS_INSTANCE.post('dkkd/thong-ke/thong-ke-tinh-hinh-cap-phep', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data) {
                result = response.data.data;
            }
        }).catch();
        return result;
    }

    baoCaoThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<DanhSachGiayPhepDKKDDTO[]>> => {
        let result: Pagination<DanhSachGiayPhepDKKDDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('dkkd/thong-ke/bao-cao-thong-ke-giay-phep', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    chiTietThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<ChiTietThongKeCapPhepDTO[]>> => {
        let result: Pagination<ChiTietThongKeCapPhepDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('dkkd/thong-ke/thong-ke-tinh-hinh-cap-phep/chi-tiet', request, this.getTokenRequestHeaders()).then(response => {
            console.log(response);
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    danhMucBienDong = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<BienDongDTO[]>> => {
        let result: Pagination<BienDongDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('danh-muc/bien-dong-thong-ke', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    downloadFileBaoCao = (request: RequestBaoCaoThongKeDTO) => {
        return AXIOS_INSTANCE.post('dkkd/thong-ke/export-bao-cao-thong-ke-giay-phep', request, {
            responseType: 'blob'
        });

    }

    downloadFileThongKe = (request: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.post('dkkd/thong-ke/export-thong-ke-tinh-hinh-cap-phep', request, {
            responseType: 'blob'
        });

    }

    chartThongKe = (request: RequestBieuDoThongKeDTO) => {
        return AXIOS_INSTANCE.post('dkkd/thong-ke/chart-thong-ke-tinh-hinh-cap-phep', request, this.getTokenRequestHeaders());
    }

    chartThongKeBieuDoTron = (request: RequestBieuDoThongKeDTO) => {
        return AXIOS_INSTANCE.post('dkkd/thong-ke/chart-thong-ke-bieu-do-tron', request, this.getTokenRequestHeaders());
    }
}
