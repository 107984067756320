import {
    Grid,
    InputLabel
} from '@material-ui/core';
import moment from "moment";
import { useEffect, useState } from 'react';
import { GiayPhepXayDungDaCapDTO, GiayPhepXayDungDTO } from '../../../../../../api/CPXD/model/CapPhepXayDungModel';
import StringUtil from '../../../../../utils/stringUtils';
import TableHangMuc from './TableHangMuc';
import TableLoDatXaydung from './TableLoDatXaydung';
interface ChiTietNoiDungGiayPhepXayDungProps {
    data: GiayPhepXayDungDaCapDTO;
}
const ChiTietNoiDungGiayPhepXayDungComponent = (props: ChiTietNoiDungGiayPhepXayDungProps) => {
    const [data, setData] = useState({} as GiayPhepXayDungDaCapDTO);
    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    return (
        <>
            {
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Số giấy phép</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{data?.soGiayPhep}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.ngayCapPhep ? moment(data?.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Ngày hết hạn</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.ngayHetHan ? moment(data?.giayPhepXayDungDTO?.ngayHetHan).format("DD/MM/YYYY") : ''}</InputLabel>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <InputLabel >THÔNG TIN CÔNG TRÌNH</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Tên công trình</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.tenCongTrinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Cấp công trình</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.tenCapLoaiCongTrinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Loại công trình</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.tenLoaiCongTrinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Phân loại xây dựng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.tenPhanLoaiCongTrinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Đơn vị tư vấn</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.donViTuVan}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Ký hiệu bản vẽ</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.soKyHieuBangVe}</InputLabel>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <InputLabel >VỊ TRÍ XÂY DỰNG</InputLabel>
                    </Grid>

                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Địa chỉ</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.diaChiDayDu}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Địa chỉ cũ</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.diaChiCu}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Lô đất xây dựng</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <TableLoDatXaydung
                            data={data?.giayPhepXayDungDTO?.loDatXayDungDTOs}
                        />
                    </Grid>
                    <Grid item xs={12} className="grid-item" >
                        <InputLabel >THÔNG TIN XÂY DỰNG</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Dự kiến hoàn thành</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.duKienHoanThanh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Chỉ giới đường đỏ</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.chiGioiDuongDo}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Chỉ giới xây dựng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.chiGioiXayDung}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Thời hạn công trình</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.thoiHanCongTrinh}</InputLabel>
                    </Grid>
                    {(data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.id > 0) &&
                        <>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >THÔNG TIN VỀ QUYỀN SỬ DỤNG ĐẤT</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích khuôn viên đất</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel className="detail-label">{StringUtil.viewNumberDot((data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichKhuonVien && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichKhuonVien > 0) ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichKhuonVien.toString() : "" ) } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích khuôn viên hiện hữu</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel className="detail-label">{StringUtil.viewNumberDot((data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat &&  data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichKhuonVienHienHuu && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichKhuonVienHienHuu > 0) ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichKhuonVienHienHuu.toString() : "")} m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích ban công</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel className="detail-label">{StringUtil.viewNumberDot((data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichBanCong && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichBanCong > 0) ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichBanCong.toString() : "") } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích chênh lệch</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel className="detail-label">{StringUtil.viewNumberDot((data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichChenhLech && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichChenhLech > 0) ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichChenhLech.toString() : "") } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích giữ lại</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel className="detail-label">{StringUtil.viewNumberDot((data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichGiuLai && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichGiuLai > 0) ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.dienTichGiuLai.toString() : "") } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Mật độ cho phép xây dựng</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel className="detail-label">{StringUtil.viewNumberDot((data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.matDoGiayPhep && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.matDoGiayPhep > 0) ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.matDoGiayPhep.toString() : "") } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Cốt nền</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item">
                                {/* <InputLabel className="detail-label">{StringUtil.viewNumberDot((data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.cotNen && data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.cotNen > 0) ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.cotNen.toString() : "") } m&#178;</InputLabel> */}
                                <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.cotNen : ''}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Lý do chênh lệch</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item">
                                <InputLabel className="detail-label">{data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat ? data?.giayPhepXayDungDTO?.giayToQuyenSuDungDat.lyDo : ''}</InputLabel>
                            </Grid>
                        </>
                    }
                    {(data?.giayPhepXayDungDTO?.hangMucXayDungDTOs && data?.giayPhepXayDungDTO?.hangMucXayDungDTOs.length > 0) &&
                        <>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel >DANH SÁCH HẠNG MỤC</InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <TableHangMuc data={data?.giayPhepXayDungDTO?.hangMucXayDungDTOs} />
                            </Grid>
                        </>

                    }

                </Grid>
            }
        </>
    )
}
export default ChiTietNoiDungGiayPhepXayDungComponent;