import React, { useEffect, useState, Dispatch } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { BrowserHistory } from 'history';
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../commons/components/tabs";
import { TINH_TRANG } from "../../../../../api/QuanTri/DanhMucFakeService";
import StringUtil from "../../../../utils/stringUtils";
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { BieuMauAttachFileDTO } from "../../../../../api/QuanTri/Model/danhMucBieuMau";
import DieuChinhLGPChaiNoiDungComponent from "./DieuChinhLGPChaiNoiDungComponent";
import { SearchGiayPhepDTO } from "../../../../../api/CPBLTL/model/SearchGiayPhepDTO";
import { BienDong } from "../../../../../api/DKKD/model/BienDongTrangThaiGPKDDTO";
import { LPGChaiService } from "../../../../../api/LPGChai/LPGChaiService";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { HoSoThuLyLPGChaiDTO } from '../../../../../api/LPGChai/model/HoSoThuLyLPGChai';
import { LOAIBIENDONG_CAPDOI, TINHTRANG_HOATDONG } from '../../../../Constants/LGPChaiConstants';
import { HO_SO_THU_LY_LPGCHAI_INIT } from '../../../../../api/LPGChai/model/GiayPhepLGPChaiInit';
import ThuLyButtonComponent from '../../../../commons/components/hoso/thuly/ThuLyButtonComponent';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import DateUtil from '../../../../utils/dateUtils';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { useDispatch } from "react-redux";

type StateHoSo = {
    maThuTuc: string,
    maHoSo: string
}
type CapMoiSXRuouProps = {
    history: BrowserHistory<StateHoSo>
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const DieuChinhGiayChungNhanLPGChai = (props: CapMoiSXRuouProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = React.useState({} as HoSoThuLyLPGChaiDTO);
    const giayChungNhanLPGChaiService = new LPGChaiService();
    const { history } = props;
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        giayChungNhanLPGChaiService.getByMaHoSo({ maHoSo: history?.location?.state?.maHoSo }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let resData = {
                    ...res?.data?.data,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten
                } as HoSoThuLyLPGChaiDTO;
                setData(resData);
            } else {
                const dataNew = {
                    ...HO_SO_THU_LY_LPGCHAI_INIT,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    donViCapPhep: false,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });

    }
    const handleSubmit = () => {
        if (StringUtil.isNullOrEmty(data.maHoSo)) {
            setTabRequired(-0);
            showMessageAlert('Không có mã hồ sơ', false);
            return;
        }
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan) || StringUtil.isNullOrEmty(data.maPhuongXa)) {
            setTabRequired(-0);
            return;
        }
        setSubmit(false);
        giayChungNhanLPGChaiService.insertOrUpdate(data).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL' && parseInt(res.data.data.id) > 0) {
                    let resData = {
                        ...res?.data?.data
                    };
                    setData(resData);
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else {
                    showMessageAlert('Yêu cầu thực hiện thất bại', false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }
    const resetData = () => {
        const dataNew = {
            ...HO_SO_THU_LY_LPGCHAI_INIT,
            donViCapPhep: false,
            id: data.id,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc,
            maBienDong: LOAIBIENDONG_CAPDOI.ma,
            tenBienDong: LOAIBIENDONG_CAPDOI.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        giayChungNhanLPGChaiService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    }

    const loadGiayPhepDaCap = (value: number) => {
        giayChungNhanLPGChaiService.getChiTietByGiayPhepId({ giayPhepId: value }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let dataNew: HoSoThuLyLPGChaiDTO = {
                    ...res?.data?.data,
                    id: data.id,
                    soGiayPhepCu: res?.data?.data?.soGiayPhep,
                    lyDo: data.lyDo,
                    lanCap: data.lanCap,
                    ngayBienDong: data.ngayBienDong,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    donViCapPhep: data.donViCapPhep,
                    files: data.files,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten
                };
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const handleSearchDoanhNghiep = async (maSoDoanhNghiep : string) => {
        
        if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
            showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
           await new DanhMucService().getBySoDKKD({maSoDoanhNghiep: maSoDoanhNghiep}).then(res => {
            let dataNew = res?.data.data;
            if (dataNew !== null && dataNew !== undefined) {
                if (dataNew.error_code === 'SUCCESSFUL' && res?.data?.data?.data?.maSoDoanhNghiep) {
                    let dataNewDKKD = {
                        ...data,
                        dienThoaiTruSo: dataNew.data.dienThoai,
                        soNhaTruSo: dataNew.data.soNha,
                        faxTruSo: dataNew.data.fax,
                        ngayCapCNDKKD: res?.data?.data?.data?.ngayThanhLap ? DateUtil.stringDDMMYYYtoDate(res?.data?.data?.data?.ngayThanhLap) : data?.ngayCapCNDKKD,
                        tenDuong: res?.data?.data?.data?.tenDuong,
                        soNha : res?.data?.data?.data?.soNha,
                        maSoThue : res?.data?.data?.data?.maSoThue,
                        soDienThoai : res?.data?.data?.data?.dienThoai ? StringUtil.replaceDots(res?.data?.data?.data?.dienThoai) : data?.soDienThoai,
                        fax: res?.data?.data?.data?.fax,
                        email : res?.data?.data?.data?.email
                    };
                    setDataParam("data", dataNewDKKD);
                }else {
                    setAlertMessage({ open: true, content: "Giấy CNĐKKD/DN/HTX/HKD không tồn tại trong dữ liệu", severity: 'warning' });
                }
                } 
            }).catch(error => {
                console.log(error);
            })
            .finally(() => {
                 dispatchLoading(hideLoading());
            });
        } 
    }

    return (
        <>
            {
                <Paper square>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    <span>ĐIỀU CHỈNH GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN CỬA HÀNG BÁN LẺ LPG CHAI - MÃ HỒ SƠ : {data.maHoSo}</span>
                                </div>
                            </div>
                                {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange}>
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <DieuChinhLGPChaiNoiDungComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    data={data}
                                    loadGiayPhepDaCap={loadGiayPhepDaCap}
                                    onSeachDoanhNghiep = {handleSearchDoanhNghiep}
                                />
                            </TabPanel>
                        }
                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <ThuLyButtonComponent
                                showIn={data.id > 0}
                                onSubmit={handleSubmit}
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onDelete={handleDelete}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    )
};

export default DieuChinhGiayChungNhanLPGChai;
