
import { BaseResponse, BaseService, PageRequest, Pagination } from "../../baseService";
import axios from "../../index";
import { KhenThuongCaNhanDTO, KhenThuongTapTheDTO, LoaiHinhKhenThuong } from "../models/khenThuongDTO";

export interface DanhSachKhenThuongRequest extends KhenThuongCaNhanDTO, PageRequest {
    isActive?: Boolean;
    hiddenFormId?: String;
    hoSoKhenThuongId?: null | Number;
    type: LoaiHinhKhenThuong;
}
export interface DanhSachKhenThuongTapTheRequest extends KhenThuongTapTheDTO, PageRequest {
    isActive?: Boolean;
    hiddenFormId?: String;
    hoSoKhenThuongId?: null | Number;
    type: LoaiHinhKhenThuong;
}

export class DanhSachKhenThuongService extends BaseService {
    getCaNhanKhenThuong = async (request: DanhSachKhenThuongRequest): Promise<Pagination<KhenThuongCaNhanDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: [],
        } as Pagination<KhenThuongCaNhanDTO[]>;
        await axios
            .get("/danh-sach-khen-thuong/search", {
                params: request,
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = {
                        ...result,
                        total: response.data.data.total,
                        rows: response.data.data.content,
                    };
                }
            })
            .catch();
        return result;
    };

    importDataTapThe = async (soBienNhan: string, thuTucId: number, file: File, hoSoThuLyId?: number): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        let formData = new FormData();
        formData.append("soBienNhan", soBienNhan);
        formData.append("hoSoThuLyId", String(hoSoThuLyId));
        formData.append("file", file, file.name);
        await axios
            .post("/danh-sach-khen-thuong/tap-the/import/" + thuTucId, formData, {
                headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
                timeout: 30000,
            })
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                };
            })
            .catch((e) => {
                result = {
                    isSuccess: false,
                    message: "Có lỗi khi thực hiện kết nối tới server",
                };
            });
        return result;
    };

    getTapTheKhenThuong = async (request: DanhSachKhenThuongTapTheRequest): Promise<Pagination<KhenThuongTapTheDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: [],
        } as Pagination<KhenThuongTapTheDTO[]>;
        await axios
            .get("/danh-sach-khen-thuong/search", {
                params: request,
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = {
                        ...result,
                        total: response.data.data.total,
                        rows: response.data.data.content,
                    };
                }
            })
            .catch();
        return result;
    };

    insertOrUpdateKhenThuongCaNhan = async (request: KhenThuongCaNhanDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios
            .post("/danh-sach-khen-thuong/ca-nhan/insertOrUpdate", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    insertOrUpdateKhenThuongTapThe = async (request: KhenThuongTapTheDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios
            .post("/danh-sach-khen-thuong/tap-the/insertOrUpdate", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    deleteById = async (id: Number): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios
            .post("/danh-sach-khen-thuong/delete", JSON.stringify({ id: id }), this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    importDataCaNhan = async (soBienNhan: string, thuTucId: number, file: File, hoSoThuLyId?: number): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        let formData = new FormData();
        formData.append("soBienNhan", soBienNhan);
        formData.append("hoSoThuLyId", String(hoSoThuLyId));
        formData.append("file", file, file.name);
        await axios
            .post("/danh-sach-khen-thuong/ca-nhan/import/" + thuTucId, formData, {
                headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
                timeout: 30000,
            })
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                };
            })
            .catch((e) => {
                result = {
                    isSuccess: false,
                    message: "Có lỗi khi thực hiện kết nối tới server",
                };
            });
        return result;
    };


    getById = async (idHoSo: Number): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios
            .post("danh-sach-khen-thuong/get-by-id", { id: idHoSo }, this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch(() => (result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" }));
        return result;
    };

    downloadFileTemplateCaNhan = async (idChuyenNganh: number): Promise<any> => {
        return axios.get("danh-sach-khen-thuong/download-template/ca-nhan/" + idChuyenNganh, {
            responseType: "blob",
        });
    };

    downloadFileTemplateTapThe = async (idChuyenNganh: number): Promise<any> => {
        return axios.get("danh-sach-khen-thuong/download-template/tap-the/" + idChuyenNganh, {
            responseType: "blob",
        });
    };
    
}
