import {  Route, RouteProps, useHistory } from "react-router-dom";
import { getString } from "../../../helpers/localStorage";
import { AUTHORIZE_REQUEST } from "../Login/sso/config";

interface PrivateRouteProps extends RouteProps {
    component: any;
    isSignedIn: boolean;
}
export const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, isSignedIn, ...rest } = props;
    const history = useHistory();
    return !!!isSignedIn ? (
        <Route
            {...rest}
            render={() => {
                if (getString("PRIVATE_LOGIN") === "1") {
                    history.push('/login');
                } else {
                    window.location.href = AUTHORIZE_REQUEST;
                }
                return null;
            }}
        />
    ) : (
        <Route {...rest}
            render={(routeProps) =>
                <Component {...routeProps}>
                </Component>

            }
        />
    );
};
