import { FileEntry } from "../fileEntry";

export interface SearchRequest {
    pageSize: number;
    pageNumber: number;
    keywords: string;
    hoatDong: number;
}
export interface DanhMucI {
    id: number;
    ten: string;
    ma: string;
    hoatDong: boolean;
}

export interface SearchGPTuDongRequest {
    pageSize: number;
    pageNumber: number;
    keywords: string;
    hoatDong: number;
    tenCoQuan: string;
    maCoQuan: string;
}

export interface DanhMucGP {
    id: number;
    ten: string;
    ma: string;
    hoatDong: boolean;
    maCoQuan: string;
    tenCoQuan: string;
    soGP: number;
    soGPDayDu: string;
    dauSo: string;
    chuyenNganh: string;
    showChuyenNganh: boolean;

}

export interface DanhMucIView {
    ten: string;
    ma: string;
}
export const DanhMucI_INIT: DanhMucI = {
    id: 0,
    ten: '',
    ma: '',
    hoatDong: true
}

export const DanhMucGP_INIT: DanhMucGP = {
    id: 0,
    ten:  '',
    ma:  '',
    hoatDong: true,
    maCoQuan:  '',
    tenCoQuan:  '',
    soGP: 0,
    soGPDayDu:  '',
    dauSo: '',
    chuyenNganh:  '',
    showChuyenNganh: true,
}

export interface DanhMucIView_EN {
    name: string;
    code: string;
}
export interface LichSuGiayPhepDTO {
    id : number;

     ngayCapNhat: number;

     ngayTao: number;

    maBienDong: string;

    tenBienDong: string;

    maTinhTrang: string;
    
    tenTinhTrang: string;

     ngayBienDong: number;

    soQuyetDinh: string;

     ngayQuyetDinh: number;

    soGiayPhep: string;

    giayPhepId: number;

    hoSoThuLyId: number;

    hoSoThuLyCuId: number;

     soLan : number;

    noiDung: string;

    ghiChu: string;

    maQuanHuyen: string;

    tenQuanHuyen: string;

    maPhuongXa: string;

    tenPhuongXa: string;

    tenTinhThanh: string;
    
    maTinhThanh: string;

    hoatDong : boolean;
    
    files: FileEntry[];
}
