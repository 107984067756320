import { combineReducers } from "redux";
import editConsultingUnits from "../../App/reducers/editConsultingUnits";
import { applicationReducer } from "./applicationReducer";
import { consultingUnitsReducer } from "./consultingUnitsReducer";
import { appraisalsReducer } from "./appraisalsReducer";
import { authenticateReducer } from "./authenticateReducer";
import { categoryReducer } from "./categoryReducer";
import { editDesignReducer } from "./editDesignReducer";
import { landsReducer } from "./landsReducer";
import { investorReducer } from "./investorReducer";
import { examineReducer } from "./examineReducer";
import { menuReducer } from "./menuReducer";
export const rootReducer = combineReducers({
    authenticate: authenticateReducer,
    application: applicationReducer,
    appraisals: appraisalsReducer,
    category: categoryReducer,
    consultingUnits: consultingUnitsReducer,
    editConsultingUnits: editConsultingUnits,
    editDesign: editDesignReducer,
    lands: landsReducer,
    investor: investorReducer,
    examine: examineReducer,
    menuReducer: menuReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
