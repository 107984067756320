import { FileEntry } from "../../Common/fileEntry";


export interface BienDongTrangThaiGPKDDTO {
    id: number;
    giayPhepId: number;
    hoSoThuLyId: number;
    soGiayPhep: string;
    ngayBienDong: number;
    ngayQuyetDinh: number;
    soQuyetDinh: string;
    noiDung: string;
    ghiChu: string;
    maTinhTrang: string;
    tenTinhTrang: string;
    tenBienDong: string;
    maBienDong: string;
    files: any[];
    tuNgay: number;
    denNgay: number;
    tenQuan: string;
    maQuan: string;
    maPhuong: string;
    tenPhuong: string;
    noiNhan: string;
}

export interface CapSoGiayPhepKinhDoanhDTO {
    id: number;
    maHoSo: string;
    ngayCapPhep: number;
    ngayHetHanGP: number;
    soGiayPhep: string;
    soGiayPhepCu: string;
    ghiChu: string;
    nguoiKy: string;
    maBienDong: string;
    maTinhTrang: string;
    files: any[];
    fileTrash: any[];
}

export enum BienDong{
    CAP_MOI = "CM",
    CAP_LAI = "CL",
    DIEU_CHINH = "DC",
    NGUNG_HOAT_DONG = "NG",
    TAM_NGUNG = "TN",
    CHAM_DUT = "CD",
    HUY= "HH",
    HOAN_TRA_HUY = "HT-HH",
    THU_HOI = "TH",
    HOAN_TRA_THU_HOI = "HT-TH",
    KHOI_PHUC = "KP-TT",
    CAP_DOI = "CAP_DOI"
}

export enum TinhTrangString{
    DANG_HOAT_DONG = "HD",
    NGUNG_HOAT_DONG = "NG",
    THU_HOI = "TH",
    HUY = "HH",
    TAM_NGUNG = "TN",
    CHAM_DUT = "CD"
}



export enum TinhTrang{
    CM,
    CL,
    DC,
    NHD,
    TN,
    CD
}
