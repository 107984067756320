import MomentUtils from '@date-io/moment';
import {
    Box,
    Button,
    Dialog,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    Snackbar
} from '@material-ui/core';
import { Alert, Autocomplete, Color } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { KIEM_TRA_NGAN_CHAN_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { KiemTraNganChanServices } from '../../../../../api/CPXD/KiemTraNganChanServices';
import { KiemTranNganChanDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { DanhMucDiaChiDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { HUYEN, PHUONG } from '../../../../../api/QuanTri/DanhMucFakeService';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { DUPLICATE_CODE } from '../../../../Constants/CapPhepXayDungConstants';
import StringUtil from '../../../../utils/stringUtils';
interface CapMoiKiemTraNganChanProps {
    show: boolean;
    onToggle: (isUpdate : boolean) => void;
    data:  KiemTranNganChanDTO;
    addOrUpDate: (code : string) => void;
}
const CapMoiKiemTraNganChans = (props: CapMoiKiemTraNganChanProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = React.useState({}as KiemTranNganChanDTO);
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [openResetData , setOpenResetData] = useState(false);
    const kiemTraNganChanService = new KiemTraNganChanServices();
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    useEffect(() => {
        if (props?.data?.id > 0) {
            setData(props.data);
        } else {
            setData(data => ({
                ...data,
            }));
        }
        setShow(props.show);
        loadQuans("92");
    }, [props.show, props.data]);

    const loadQuans = async (maThanhPho: string) => {
        await danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        
    };

    const handleChange = (e: any) => {
        setData(data => ({
            ...data,
            [e.target.name]:  e.target.value
        }));
    }
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success"
    });
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        setData(data => ({
            ...data,
            [nameValue]: value?.ma,
            [nameText]: valueText
        }));
    }
 
    const handleSubmit = async () => {
        setSubmit(true);
        if (validateFrom()) {
            dispatchLoading(showLoading());
            await kiemTraNganChanService.insertOrUpdate(data).then(res => {
                if (res != null) {
                    if (res.data.error_code === 'SUCCESSFUL' ) {
                        props.addOrUpDate('SUCCESSFUL');
                    } else if(res.data.error_code === DUPLICATE_CODE){
                        setAlert({
                            showAlert: true,
                            message: "Số tờ/ số thửa đã tồn tại",
                            severity: "warning"
                        });
                    } 
                    else {
                        setAlert({
                            showAlert: true,
                            message: "Yêu cầu thực hiện thất bại",
                            severity: "error"
                        });
                    }
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error"
                    });
                }
            }).catch(error => {
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
            
        }
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        setData(data => ({
            ...data,
            [filedName]: date?.toDate()?.getTime(),
        }));

    }

    function validateFrom(): boolean {
        let valid: boolean = true;
        if(StringUtil.isNullOrEmty(data.chuSoHuu)){
            valid = false;
        }
        if(StringUtil.isNullOrEmty(data.soTo)){
            valid = false;
        }
        if(StringUtil.isNullOrEmty(data.soThua)){
            valid = false;
        }
        if(StringUtil.isNullOrEmty(data.noiDung)){
            valid = false;  
        }
        if(StringUtil.isNullOrEmty(data.ngayNganChan)){
            valid = false;  
        }
        if(StringUtil.isNullOrEmty(data.maPhuong)){
            valid = false;
        }
        if(StringUtil.isNullOrEmty(data.maHuyen)){
            valid = false;    
        }
        return valid;
    }
    const toggle = () => {
        props.onToggle(false);
    }
    const handleClickXoa = () => {
        setOpenResetData(!openResetData);
    }
    const handleReset = () => {
        setData({
            ...KIEM_TRA_NGAN_CHAN_INIT
        })
    }
    const handleConfirm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleReset();
        }
        setOpenResetData(!openResetData);
    };


    const handleChangeSelectQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText,  ['maPhuong'] : '', ['tenPhuong'] : ''};
        setData(dataNew);
    };

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
        setData(dataNew);
    };

    const loadPhuongs = async () => {
        if(StringUtil.isNotEmpty(data?.maHuyen)){
          await  danhMucService
          .getDanhMucDiaChi(data?.maHuyen)
          .then((res) => {
              if (res &&  res.length > 0) {
                  setPhuongs(res);
              }
          })
          .catch((error) => {
              console.log(error);
          });
      }  
  };

    return (
            <>
            {
                <Dialog onClose={toggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                <DialogTitle id="customized-dialog-title" onClose={toggle}>
                    {data?.id >0 ? 'CẬP NHẬT NGĂN CHẶN' : 'THÊM MỚI NGĂN CHẶN'}
                </DialogTitle>
                <DialogContent dividers>
                    
                    <Box padding={0}>
                        <Grid container>
                        <OverlayLoading />
                    <Snackbar open={alert.showAlert} onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>{alert.message}</Alert>
                    </Snackbar>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required>Chủ sử dụng</InputLabel>
                                <InputBase fullWidth name="chuSoHuu" value={data.chuSoHuu} 
                                    error={submit && StringUtil.isNullOrEmty(data.chuSoHuu) }
                                    onChange={handleChange}
                                    />
                                {
                                    submit && StringUtil.isNullOrEmty(data.chuSoHuu) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập chủ sử dụng</FormHelperText>
                                }
                            </Grid> 
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Địa chỉ chi tiết</InputLabel>
                                <InputBase fullWidth
                                    value={data.diaChiCuThe} name="diaChiCuThe" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required >Số tờ</InputLabel>
                                <InputBase fullWidth value={data.soTo} name="soTo" onChange={handleChange} 
                                    error={submit && StringUtil.isNullOrEmty(data.soTo)}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.soTo) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số tờ</FormHelperText>
                                }
                            </Grid>  
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required >Quận, huyện</InputLabel>
                                <Autocomplete id="maHuyen"
                                    options={quans}
                                    value={{ code: data.maHuyen ? data.maHuyen : "", name: data.tenHuyen ? data.tenHuyen : "" }}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                    }}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập quận,huyện</FormHelperText>
                                 } 
                            </Grid>  
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required >Số thửa</InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data.soThua)}
                                    value={data.soThua} name="soThua" onChange={handleChange} 
                                />
                                 {
                                    submit && StringUtil.isNullOrEmty(data.soThua) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số thửa</FormHelperText>
                                 }  
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required >Phường xã</InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={phuongs}
                                    onFocus={loadPhuongs}
                                    value={{ code: data.maPhuong ? data.maPhuong : "", name: data.tenPhuong ? data.tenPhuong : "" }}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectPhuong(newValue, 'maPhuong', 'tenPhuong')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maPhuong) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Số giấy CN </InputLabel>
                                <InputBase fullWidth
                                    value={data.soCN} name="soCN" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required>Ngày ngăn chặn</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={data.ngayNganChan && data.ngayNganChan !== 0 && data.ngayNganChan !== null && data.ngayNganChan !== undefined ? new Date(data.ngayNganChan) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayNganChan')}
                             
                                    />
                                </MuiPickersUtilsProvider>
                                {
                                    submit && (data.ngayNganChan === 0 || !data.ngayNganChan || data.ngayNganChan === null || data.ngayNganChan === undefined) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập ngày ngăn chặn</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Số vào sổ </InputLabel>
                                <InputBase fullWidth
                                    value={data.soVaoSo} name="soVaoSo" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Công văn NC </InputLabel>
                                <InputBase fullWidth
                                    value={data.congVanNC} name="congVanNC" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel required >Nội dung ngăn chặn </InputLabel>
                                <InputBase fullWidth multiline
                                    error={submit && StringUtil.isNullOrEmty(data.noiDung)}
                                    value={data.noiDung} name="noiDung" onChange={handleChange} rows={2} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.noiDung) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập nội dung ngăn chặn</FormHelperText>
                                 }      
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Ghi chú </InputLabel>
                                <InputBase fullWidth multiline
                                    value={data.ghiChu} name="ghiChu" onChange={handleChange} rows={2}   
                                    
                                    />
                            </Grid>
                            <Grid item xs={6} className="grid-item" container direction="row" justify="flex-end" alignItems="center">
                                <Button variant="contained" color="primary" disableElevation type="button" 
                                    onClick={handleSubmit}>
                                    Lưu
                                </Button>

                            </Grid>
                            <Grid item xs={6} className="grid-item" container direction="row" justify="flex-start" alignItems="center">
                                <Button  variant="contained"  color="secondary" disableElevation type="button" onClick={() => handleClickXoa()} >
                                    Xóa
                                </Button>
                            </Grid>
                            
                        </Grid>   
                    </Box>   
                </DialogContent>
                 { openResetData && 
                    <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openResetData}
                        title="Xóa dữ liệu"
                        content="Bạn có đồng ý xóa?" onClose={handleConfirm}
                    />
                 }
            </Dialog>       
            }
           
          </>  
    )
}

export default CapMoiKiemTraNganChans;

