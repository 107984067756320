import AXIOS_INSTANCE from "../index";
import { GiayPhepChoCapSoDTO, GiayPhepXayDungChoCapSoRequest, GiayPhepXayDungDaCapRequest, ThuHoiHuyDTO } from "./model/CapPhepXayDungModel";
export class CapPhepXayDungService {
    //Giấy phép thụ lý
    insertOrUpdate = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep/add-or-update', requestBody);
    }
    insertOrUpdateNhapDauKy = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep/add-or-update/nhap-dau-ky', requestBody);
    }
    getByMaHoSo = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep/by-ma-ho-so', requestBody);
    }
    searchGiayPhepXayDungChoCapSo = (body: GiayPhepXayDungChoCapSoRequest) => {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep/tim-kiem-giay-phep-cho-cap-so', body);
    }
    downloadFile(request: any) {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep/download-giay-phep', request, {
            responseType: 'blob',
        });
    }
    downloadFileByGiayPhepXayDungDaCapId(request: any) {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep/download-giay-phep-by-id', request, {
            responseType: 'blob',
        });
    }
    //Giấy phép đã cấp
    getBySoGiayPhep_MaTrangThai = (requestBody: any) => {
        return AXIOS_INSTANCE.post('cpxd/giay-phep-da-cap/by-so-giay-phep-ma-trang-thai', requestBody);
    }
    getAutocompleteBySoGiayPhep_MaTrangThai_PageSize = (soGiayPhep: string, maTrangThai: string, pageSize: number) => {
        let body = {
            soGiayPhep: soGiayPhep,
            maTrangThai: maTrangThai,
            pageSize: pageSize
        }
        return AXIOS_INSTANCE.post('/cpxd/giay-phep-da-cap/get-autocomplete-by-so-giay-phep-ma-trang-thai', body);
    }
    capNhatSoGiayPhep = (body: GiayPhepChoCapSoDTO) => {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep-da-cap/cap-nhat-so-giay-phep', body);
    }
    searchGiayPhepDaCap = (requestBody: GiayPhepXayDungDaCapRequest) => {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep-da-cap/danh-sach-gpxd', requestBody);
    }
    getGiayPhepXayDungDaCapById = (id: number) => {
        return AXIOS_INSTANCE.get('/cpxd/giay-phep-da-cap/chi-tiet/' + id);
    }
    insertOrUpdateThuHoi = (requestBody: ThuHoiHuyDTO) => {
        return AXIOS_INSTANCE.post('/cpxd/giay-phep-da-cap/add-or-update-thu-hoi', requestBody);
    }
    chiTietThuHoiHuy =  (request : any)=> {
        return AXIOS_INSTANCE.get('/cpxd/giay-phep-da-cap/chi-tiet-thu-hoi-huy', {
            params: request
        });

    }
    downloadFileDanhSachCPXD =  (request: GiayPhepXayDungDaCapRequest)=> {
        return AXIOS_INSTANCE.get('/cpxd/giay-phep-da-cap/export-danh-sach-gpxd', {
            responseType: 'blob',
            params: request,
        });

    }
    downloadBaoCaoCPXD =  (request: GiayPhepXayDungDaCapRequest)=> {
        /* return AXIOS_INSTANCE.get('/cpxd/giay-phep-da-cap/export-danh-sach-gpxd', {
            responseType: 'blob',
            params: request
        }); */

    }



}