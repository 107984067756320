import { Box, Grid, InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
//import Dropzone from "react-dropzone-uploader";
import Dropzone from "./plugin/dist/Dropzone";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { AttachFileDTO } from "../../../../api/KhenThuong/models/attachFile";
import LayoutDrop from "../LayoutDropZone/LayoutDrop";
import { PreviewDropZone } from "../LayoutDropZone/PreviewDropZone";
import PreviewDropzoneFromServerKT from "./previewFileFromServerKT";

interface IDropZoneCustom {
    acceptFiles: String;
    attachFileServer: AttachFileDTO[];
    url: String;
    handleChangeStatus: (file: IFileWithMeta) => void;
    handleDeleteFileServer: (file: AttachFileDTO) => void;
    handleDownloadFile: (file: AttachFileDTO) => void;
}

const DropZoneCustomKT: React.FC<IDropZoneCustom> = ({ acceptFiles, attachFileServer, url, handleDeleteFileServer, handleChangeStatus, handleDownloadFile }) => {

    return (
        <>
            <Grid item xs={2} className="grid-item">
                <InputLabel>Đính kèm</InputLabel>
            </Grid>
            <Grid item xs={10}>
                {attachFileServer && (
                    <label htmlFor="dzu-input">
                        <Box width="456px">
                            <PreviewDropzoneFromServerKT files={attachFileServer} onDelete={handleDeleteFileServer} onDownload={handleDownloadFile} />
                        </Box>
                    </label>
                )}
                <Box width="456px">
                    <Dropzone
                        accept={`${acceptFiles}`}
                        inputContent={
                            <Button variant="outlined" size="small" color="default" component="span" disableElevation style={{ marginRight: "10px" }}>
                                Chọn tập tin
                            </Button>
                        }
                        inputWithFilesContent={
                            <Button variant="outlined" size="small" color="default" component="span" disableElevation style={{ marginRight: "10px" }}>
                                Chọn tập tin
                            </Button>
                        }
                        multiple={true}
                        getUploadParams={(file: IFileWithMeta) => {
                            const formData = new FormData();
                            // const userId = getCookie("C_USER");
                            formData.append("file", file.file);
                            // formData.append("userId", `${userId}`);
                            //  formData.append("chuyenNganh", chuyenNganh);
                            let headers: any = {
                                'csrf-token': localStorage.getItem("csrf-token")
                            }
                            return {
                                url: `${url}`,
                                headers: headers,
                                body: formData,
                            };
                        }}
                        onChangeStatus={handleChangeStatus}
                        // InputComponent={(props: any) => <InputCustom acceptFiles={`${acceptFiles}`} {...props} />}
                        LayoutComponent={(props: any) => <LayoutDrop {...props} acceptFiles={acceptFiles} />}
                        PreviewComponent={(props: any) => <PreviewDropZone {...props} />}
                    />
                </Box>
            </Grid>
        </>
    );
};

export default DropZoneCustomKT;
