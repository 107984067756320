import { Box, Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Dispatch } from 'redux';
import { VSATTPService } from '../../../../../api/VSATTP/Services/VSATTPService';
import { LoadingAction } from '../../../../../redux/actions/applicationAction';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import { GiayPhepATTP } from '../../../../../api/VSATTP/Model/VSATTPModel';
import ChiTietNoiDungGiayPhepATTPComponent from './NoiDung/ChiTietNoiDungGiayPhepATTPComponent';
import TepTinDinhKem from './TepTin/TepTinDinhKem';
import { GiayPhepATTPModel_INIT } from '../../../../../api/VSATTP/Init/VSATTPModel_INIT';
import LichSu from './LichSu/LichSu';
import ChiTietGiayPhepATTPButtonComponent from './ChiTietGiayPhepATTPButtonComponent';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
interface ChiTietGiayPhepATTPProps {

}
const ChiTietGiayPhepATTPComponent = (props: ChiTietGiayPhepATTPProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation();
    const [giayPhepATTP, setGiayPhepATTP] = useState<GiayPhepATTP>({ ...GiayPhepATTPModel_INIT });


    useEffect(() => {
        initData();
    }, []);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const initData = () => {       
        let giayPhepATTPId = location?.state?.giayPhepATTPId;
        if (giayPhepATTPId > 0) {

            new VSATTPService().getGiayPhepATTPById(giayPhepATTPId).then(res => {
              
                if (res.data.data !== null && res.data.data !== undefined) {
                    setGiayPhepATTP({...res.data.data});
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    return (
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setShowAlert(false)} severity={severity as Color}>{message}</Alert>
                    </Snackbar>

                    <Grid container style={{ float: 'left', paddingTop: '20px' }} item xs={12} className="grid-item" >
                        <div className="dash-title">
                            <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                XEM THÔNG TIN CHI TIẾT GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM
                            </h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChange} >
                                <CustomTab label="Thông tin" />
                                <CustomTab label="Tệp đính kèm" />
                                <CustomTab label="Lịch sử" />
                            </CustomTabs>
                        </Box>

                    </Grid>
                    {
                        indexTab === 0 &&
                        <TabPanel value={indexTab} index={0}>
                            <ChiTietNoiDungGiayPhepATTPComponent data={giayPhepATTP} />
                        </TabPanel>
                    }
                    {
                        indexTab === 1 &&
                        <TabPanel value={indexTab} index={1}>
                            <TepTinDinhKem
                                data ={giayPhepATTP?.files}  
                             />
                        </TabPanel>
                    }
                    {
                        indexTab === 2 &&
                        <TabPanel value={indexTab} index={2}>
                            <LichSu
                              data ={giayPhepATTP?.lichSuGiayPhepATTPDTOs}
                              />
                        </TabPanel>
                    }
                    <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <ChiTietGiayPhepATTPButtonComponent />
                    </Grid>
                </Grid>

            }
        </>
    )
}
export default ChiTietGiayPhepATTPComponent;
