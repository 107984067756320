import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from '@material-ui/core';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageRequest } from '../../../../../api/baseService';
import { DanhMucI, DanhMucI_INIT, SearchRequest } from '../../../../../api/Common/model/CommonModel';
import { LinhVucKinhDoanhService } from '../../../../../api/DKKD/LinhVucKinhDoanhService';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { AlertMessage, CustomAlert } from '../../../../commons/components/alert';
import ButtonExt from '../../../../commons/components/button/customButton';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import { DataList } from './DataList';
import { EditDialog } from './EditDialog';



export const LinhVucKinhDoanhComponent = () => {
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataList, setDataList] = useState<DanhMucI[]>([]);
    const [itemSeleted, setItemSeleted] = useState<DanhMucI>({} as DanhMucI);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [total, setTotal] = useState(0);

    const [searchRequest, setSearchRequest] = useState<SearchRequest>({
        pageSize: 5,
        pageNumber: 1,
        hoatDong: 0,
        keywords: ""
    });
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const service = new LinhVucKinhDoanhService();

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const loadDataTable = (request: SearchRequest) => {
        dispatch(showLoading());
        service.search(request).then((p) => {
            if (p && p.data && p.data.data && p.data.error_code == 'SUCCESSFUL') {
                setDataList( p.data.data.data);
                setTotal( p.data.data.total);
            } else {
                showMessageAlert("Yêu cầu thực hiện thất bại", false)
            }

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            dispatch(hideLoading());
        });
    }
    useEffect(() => {
        loadDataTable(searchRequest);
    }, []);

    const doSearch = () => {
        let searchRequestNew = { ...searchRequest, pageNumber: 1 };
        setSearchRequest(searchRequestNew);
        loadDataTable(searchRequestNew);
    }
    const handleSubmitData = (content: string, isSuccess: boolean) => {
        console.log('isSuccess : ',isSuccess)
        if (isSuccess) {
            setOpenDialog(false);
            let searchRequestNew = { ...searchRequest, pageNumber: 1 };
            setSearchRequest(searchRequestNew);
            loadDataTable(searchRequestNew);
            showMessageAlert("Yêu cầu thực hiện thành công", true)
        } else {
            showMessageAlert("Yêu cầu thực hiện thất bại", false)
        }
    }
    const showEditDialog = (item: DanhMucI) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchRequestNew = { ...searchRequest, pageNumber: value };
        setSearchRequest(searchRequestNew);
        loadDataTable(searchRequestNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchRequestNew = { ...searchRequest, pageSize: Number(event.target.value), pageNumber: 1 };
        setSearchRequest(searchRequestNew);
        loadDataTable(searchRequestNew);
    }

    const handleDeleteItem = (request: DanhMucI) => {
        if (request) {
            setItemSeleted(request);
            setOpenDeleteUserDlg(true);
        }
    }

    const handleCloseDeleteRole = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (itemSeleted.id) {
                // showMessageAlert('Chức năng đang phát triển', false);
                service.delete(itemSeleted.id).then((p) => {
                    if(p.data.error_code === 'SUCCESSFUL'){
                        showMessageAlert('Yêu cầu thực hiện thành công');
                        loadDataTable(searchRequest);
                    }else{
                        showMessageAlert('Yêu cầu thực hiện thất bại', false);
                    }
                }).catch((e) => {
                    showMessageAlert('Yêu cầu thực hiện thất bại', false);
                    console.log(e);
                }).finally(() => {
                    dispatchLoading(hideLoading());
                });
            }
        }
        setOpenDeleteUserDlg(false);
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }))
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Lĩnh vực kinh doanh</span>
            </div>
            <form noValidate autoComplete="off">
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={6}>
                        <InputLabel >Từ khóa tìm kiếm</InputLabel>
                        <InputBase fullWidth placeholder="Nhập nội dung tìm kiếm"
                            value={searchRequest.keywords} onChange={(e) => setSearchRequest({ ...searchRequest, keywords: e.target.value })}
                            inputProps={{ 'aria-label': 'description' }} />
                    </Grid>
                    <Grid item xs={6} className="gridItem">
                        <InputLabel >Hoạt động</InputLabel>
                        <NativeSelect input={<InputBase fullWidth />}
                            value={searchRequest.hoatDong} onChange={(e) => setSearchRequest({ ...searchRequest, hoatDong: Number(e.target.value) })} >
                            <option value={0}></option>
                            <option value={1}>Hoạt động</option>
                            <option value={2}>Ngừng hoạt động</option>
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={12} className="button-group-center">
                        <Button variant="contained" color="primary" disableElevation
                            onClick={doSearch}>
                            Tìm kiếm
                        </Button>
                        <ButtonExt variant="contained" color="primary" disableElevation
                            onClick={() => showEditDialog({ ...DanhMucI_INIT })}>
                            Thêm mới
                        </ButtonExt>
                    </Grid>
                </Grid>
                <div>
                    <DataList dataList={dataList} deleteItem={handleDeleteItem}
                        pageProps={{
                            page: searchRequest.pageNumber, count: total, rowsPerPage: searchRequest.pageSize,
                            onChangePage: handleOnChangePage,
                            onChangeRowsPerPage: handOnChangeRowsPerPage
                        }}
                        modalEdit={{ open: true, openEditDialog: showEditDialog }} />
                    {
                        openDialog &&
                        <EditDialog openDialog={openDialog}
                            onDialogClose={() => setOpenDialog(false)}
                            itemEdit={itemSeleted} onSubmitData={handleSubmitData} />
                    }
                </div>
                <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openDeleteUserDlg}
                    title="Xóa vai trò"
                    content="Bạn có đồng ý xóa dữ liệu này?" onClose={handleCloseDeleteRole}
                />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                </Snackbar>
            </form>
        </div >
    )
}