import { Box, Button, createStyles, Grid, makeStyles, Snackbar, TextField, Theme } from "@material-ui/core";
import React, { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserManagementService } from "../../../../api/userMgt/userManagementService";
import { CustomAlert } from "../../../../App/commons/components/alert";
import { bootstrapTextFieldHook } from "../../../../App/commons/components/input/bootstrap";
import StringUtil from "../../../../App/utils/stringUtils";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { AuthenticateAction, doLogin } from "../../../../redux/actions/AuthenticateAction";
import { RootState } from "../../../../redux/reducers/rootReducer";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        gridItem: {
            padding: "5px 10px",
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        snackBar: {
            top: "70px"
        }
    }),
);

type PasswordDTO = {
    oldPassword: string,
    newPassword: string,
    newPasswordRepeat: string,
}
export const ChangePassword = () => {
    const classes = useStyles();
    const history = useHistory();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    const dispatchAuth = useDispatch<Dispatch<AuthenticateAction>>();
    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    const [alertError, setAlertError] = useState(false);
    const [messageNotify, setMessageNotify] = useState('');
    const [alertType, setAlertType] = useState<'success' | 'warning' | 'info' | 'error' | undefined>(undefined);

    const [errors, setErrors] = useState<PasswordDTO>({} as PasswordDTO);
    const [passwordDTO, setPasswordDTO] = useState<PasswordDTO>({} as PasswordDTO);

    const handleChange = (e: any) => {
        setPasswordDTO(passwordDTO => ({
            ...passwordDTO,
            [e.target.name]: e.target.value
        }));
    }

    /* eslint-disable no-useless-escape */
    const validateFromRequired = (): boolean => {
        let errorValidation = {} as PasswordDTO;
        if (StringUtil.isNullOrEmty(passwordDTO.oldPassword)) {
            errorValidation.oldPassword = 'Mật khẩu cũ bắt buộc nhập';
        }
        if (StringUtil.isNullOrEmty(passwordDTO.newPassword)) {
            errorValidation.newPassword = 'Mật khẩu mới bắt buộc nhập';
        } else if (passwordDTO.newPassword.length < 8) {
            errorValidation.newPassword = 'Mật khẩu mới yêu cầu tối thiểu 8 kí tự';
        } else {
            let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            if (!strongRegex.test(passwordDTO.newPassword)) {
                errorValidation.newPassword = 'Mật khẩu yêu cầu bao gồm cả chữ và số, ký tự đặc biệt: *,$,#...';
            }
        }
        if (StringUtil.isNullOrEmty(passwordDTO.newPasswordRepeat)) {
            errorValidation.newPasswordRepeat = 'Nhập lại mật khẩu mới bắt buộc nhập';
        } else if (passwordDTO.newPassword.length < 8) {
            errorValidation.newPasswordRepeat = 'Nhập lại mật khẩu yêu cầu tối thiểu 8 kí tự';
        }
        setErrors(errorValidation);
        return Object.keys(errorValidation).length === 0
    }
    const validateNewAndRepeatPassword = (): boolean => {
        let errorValidation = {} as PasswordDTO;
        if (!(StringUtil.isNullOrEmty(passwordDTO.newPassword) && StringUtil.isNullOrEmty(passwordDTO.newPasswordRepeat))) {
            if (passwordDTO.newPassword !== passwordDTO.newPasswordRepeat) {
                errorValidation.newPassword = 'Mật khẩu mới và mật khẩu mới nhập lại không khớp nhau';
                errorValidation.newPasswordRepeat = 'Mật khẩu mới và mật khẩu mới nhập lại không khớp nhau';
            }
            if (passwordDTO.newPassword === passwordDTO.oldPassword) {
                errorValidation.newPassword = 'Mật khẩu mới không được trùng với mật khẩu hiện tại';
            }
        }
        setErrors(errorValidation);
        return Object.keys(errorValidation).length === 0;
    }

    const userInfo = useSelector((state: RootState) => state.authenticate.userInfo);

    const handleSubmit = () => {
        dispatch(showLoading());
        if (validateFromRequired() && validateNewAndRepeatPassword()) {
            let userId = localStorage.getItem("C_USER");
            let requestBody = {
                userId: userId != null ? userId : "" ,
                oldPassword: passwordDTO.oldPassword,
                newPassword: passwordDTO.newPassword

            }
            new UserManagementService().changePassword(requestBody).then(p => {
                setMessageNotify(p.error_message);
                setAlertError(true);
                setAlertType('SUCCESSFUL' === p.error_code ? 'success' : 'error');
                if ('SUCCESSFUL' === p.error_code) {
                    dispatchAuth(doLogin({ ...userInfo, requireChange: false }));
                }
            });
        }
        dispatch(hideLoading());
    }

    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                    <Grid item xs={3} className={classes.gridItem}>
                        <TextField fullWidth
                            label={'Mật khẩu hiện tại'} required name="oldPassword"
                            type="password"
                            value={passwordDTO.oldPassword} onChange={handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            error={Boolean(errors.oldPassword)}
                            InputProps={{
                                disableUnderline: true, classes: inputStyles,
                            }}
                            FormHelperTextProps={{ className: '-error', classes: helperTextStyles, }}
                            helperText={Boolean(errors.oldPassword) && errors.oldPassword}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={3} className={classes.gridItem}>
                        <TextField fullWidth type="password"
                            label={'Mật khẩu mới'} required name="newPassword"
                            value={passwordDTO.newPassword} onChange={handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            error={Boolean(errors.newPassword)}
                            InputProps={{
                                disableUnderline: true, classes: inputStyles,
                            }}
                            FormHelperTextProps={{ className: '-error', classes: helperTextStyles, }}
                            helperText={Boolean(errors.newPassword) && errors.newPassword}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <TextField fullWidth type="password"
                            label={'Nhập lại mật khẩu mới'} required name="newPasswordRepeat"
                            value={passwordDTO.newPasswordRepeat} onChange={handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            error={Boolean(errors.newPasswordRepeat)}
                            InputProps={{
                                disableUnderline: true, classes: inputStyles,
                            }}
                            FormHelperTextProps={{ className: '-error', classes: helperTextStyles, }}
                            helperText={Boolean(errors.newPasswordRepeat) && errors.newPasswordRepeat}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Box display="flex" paddingLeft="10px" flexDirection="column" fontStyle="italic" fontSize="13px">
                        <Box>
                            Mật khẩu phải có ít nhất 8 ký tự.Không trùng với mật khẩu đang sử dụng!
                        </Box>
                        <Box>
                            Lựa chọn mật khẩu an toàn bao gồm cả chữ và số, nên có thêm các ký tự đặc biệt như *,$,#...để độ an toàn cao nhất
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={3} className={classes.root}>
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleSubmit()}>
                        Lưu
                    </Button>
                    <Button variant="contained" color="default" disableElevation onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Grid>
            </Grid>
            <Snackbar className={classes.snackBar} open={alertError} onClose={() => setAlertError(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={() => setAlertError(false)} severity={alertType}>{messageNotify}</CustomAlert>
            </Snackbar>
        </form>
    )
}