import {
    Grid,
    InputLabel,
    InputBase,
    FormHelperText
} from '@material-ui/core';
import moment from "moment";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { GiayPhepXayDungDTO, ThuHoiHuyDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { LOAIBIENDONG_THUHOI, TINHTRANG_THUHOI } from '../../../../Constants/CapPhepXayDungConstants';
import StringUtil from '../../../../utils/stringUtils';
import TableHangMuc from '../ChiTiet/NoiDung/TableHangMuc';
import TableLoDatXaydung from '../ChiTiet/NoiDung/TableLoDatXaydung';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import AXIOS_INSTANCE from '../../../../../api';


interface ChiTietNoiDungGiayPhepXayDungProps {
    data: GiayPhepXayDungDTO;
    onUpdateData: (name: string, value: any) => void;
    thuHoiHuy : ThuHoiHuyDTO;
    submit: boolean;
}
const ChiTietNoiDungThuHoiComponent = (props: ChiTietNoiDungGiayPhepXayDungProps) => {
    const [data, setData] = useState({} as GiayPhepXayDungDTO);
    const [thuHoiHuy, setThuHoiHuy] = useState({} as ThuHoiHuyDTO)
    const [submit, setSubmit] = useState(false);
    const location = useLocation();
    const [isThuHoi, setIsThuHoi] = useState(false);
    const [maTrangThai, setMaTrangThai] = useState('');
    const [giayPhepXayDaCapId, setGiayPhepXayDaCapId] = useState(0);
    const { onUpdateData } = props;
    const fileServerService = new FileServerService();
    useEffect(() => {
    /*     let dataNew = {
            ...props.thuHoiHuy
        } */
     setSubmit(props.submit);  
     setData(props.data);  
     setThuHoiHuy(props.thuHoiHuy);
    }, [props.data, props.submit, props.thuHoiHuy,  props.onUpdateData]);

    /* useEffect(() => {
        let isThuHoi = location?.state?.isThuHoi;
        if(isThuHoi){
            let maTrangThai = location?.state?.maTrangThai;
            let giayPhepXayDaCapId = location?.state?.giayPhepXayDaCapId;
            setIsThuHoi(isThuHoi);
            setMaTrangThai(maTrangThai);
            setGiayPhepXayDaCapId(giayPhepXayDaCapId);
        }
    }, []); */
    const handleChange = (e: any) => {
        onUpdateData(e.target.name, e.target.value);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    }
   //file
   const handleChangeStatus = (file: IFileWithMeta) => {
       if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...thuHoiHuy.files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }

    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    return (
        <>
            {
                <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số giấy phép</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soGiayPhep}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data.ngayCapPhepCu ? moment(data.ngayCapPhepCu).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                   
                    <Grid item xs={12} className="grid-item">
                        <InputLabel >THÔNG TIN CÔNG TRÌNH</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Tên công trình</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.tenCongTrinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Cấp công trình</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenCapLoaiCongTrinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Loại công trình</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.maLoaiCongTrinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Phân loại xây dựng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.maPhanLoaiCongTrinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Đơn vị tư vấn</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.donViTuVan}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Ký hiệu bản vẽ</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soKyHieuBangVe}</InputLabel>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <InputLabel >VỊ TRÍ XÂY DỰNG</InputLabel>
                    </Grid>  

                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Địa chỉ</InputLabel>
                        </Grid>
                    <Grid item xs={9} className="grid-item">
                        {/* <InputLabel>{data?.diaChiDayDu}</InputLabel> */}
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Địa chỉ cũ</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel>{data?.diaChiCu}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Lô đất xây dựng</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <TableLoDatXaydung
                             data={data.loDatXayDungDTOs} 
                        />
                    </Grid>
                    <Grid item xs={12} className="grid-item" >
                        <InputLabel >THÔNG TIN XÂY DỰNG</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Dự kiến hoàn thành</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.duKienHoanThanh ?  moment(data.duKienHoanThanh).format("DD/MM/YYYY") : '' }</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Chỉ giới đường đỏ</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.chiGioiDuongDo}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Chỉ giới xây dựng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.chiGioiXayDung}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Thời hạn công trình</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.thoiHanCongTrinh ?  moment(data.thoiHanCongTrinh).format("DD/MM/YYYY") : '' }</InputLabel>
                    </Grid>
                    { (data?.giayToQuyenSuDungDat && data?.giayToQuyenSuDungDat.id > 0) &&
                        <>
                            <Grid item xs={12} className="grid-item" >
                            <InputLabel >THÔNG TIN VỀ QUYỀN SỬ DỤNG ĐẤT</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích khuôn viên đất</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>{(data?.giayToQuyenSuDungDat && data?.giayToQuyenSuDungDat.dienTichKhuonVien && data?.giayToQuyenSuDungDat.dienTichKhuonVien > 0 )? data.giayToQuyenSuDungDat.dienTichKhuonVien : '' } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích khuôn viên hiện hữu</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>{(data?.giayToQuyenSuDungDat && data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu && data?.giayToQuyenSuDungDat.dienTichKhuonVienHienHuu > 0 )? data.giayToQuyenSuDungDat.dienTichKhuonVienHienHuu : '' } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích ban công</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>{(data?.giayToQuyenSuDungDat && data?.giayToQuyenSuDungDat.dienTichBanCong && data?.giayToQuyenSuDungDat.dienTichBanCong > 0 )? data.giayToQuyenSuDungDat.dienTichBanCong : '' } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích chênh lệch</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>{(data?.giayToQuyenSuDungDat && data?.giayToQuyenSuDungDat.dienTichChenhLech && data?.giayToQuyenSuDungDat.dienTichChenhLech > 0 )? data.giayToQuyenSuDungDat.dienTichChenhLech : '' } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Diện tích giữ lại</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>{(data?.giayToQuyenSuDungDat && data?.giayToQuyenSuDungDat.dienTichGiuLai && data?.giayToQuyenSuDungDat.dienTichGiuLai > 0 )? data.giayToQuyenSuDungDat.dienTichGiuLai : '' } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Mật độ cho phép xây dựng</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>{(data?.giayToQuyenSuDungDat && data?.giayToQuyenSuDungDat.matDoGiayPhep && data?.giayToQuyenSuDungDat.matDoGiayPhep > 0 )? data.giayToQuyenSuDungDat.matDoGiayPhep : '' } m&#178;</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Cốt nền</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item">
                                {/* <InputLabel>{(data?.giayToQuyenSuDungDat && data?.giayToQuyenSuDungDat.cotNen && data?.giayToQuyenSuDungDat.cotNen > 0 )? data.giayToQuyenSuDungDat.cotNen: '' } m&#178;</InputLabel> */}
                                <InputLabel>{data?.giayToQuyenSuDungDat ? data.giayToQuyenSuDungDat.cotNen: '' }</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Lý do chênh lệch</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item">
                                <InputLabel>{data?.giayToQuyenSuDungDat ? data.giayToQuyenSuDungDat.lyDo: '' }</InputLabel>
                            </Grid>
                        </>
                    }
                     {(data?.hangMucXayDungDTOs && data?.hangMucXayDungDTOs.length > 0) &&
                        <>
                        <Grid item xs={12} className="grid-item">
                        <InputLabel >DANH SÁCH HẠNG MỤC</InputLabel>
                        </Grid>    
                        <Grid item xs={12} className="grid-item">
                            <TableHangMuc data ={data?.hangMucXayDungDTOs}/>
                        </Grid>     
                        </>

                     }   
                    <Grid item xs={12} className="grid-item">
                        <InputLabel >{thuHoiHuy.maTinhTrang === TINHTRANG_THUHOI.ma ? 'THÔNG TIN THU HỒI ': 'THÔNG TIN HỦY'}</InputLabel>
                    </Grid> 
                    <Grid item xs={3} className="grid-item" >
                        <InputLabel required>Số quyết định</InputLabel>
                        <InputBase fullWidth
                            error={submit && StringUtil.isNullOrEmty(thuHoiHuy.soQuyetDinh)}
                            value={thuHoiHuy.soQuyetDinh} name="soQuyetDinh" onChange={handleChange} />
                        {
                            submit && StringUtil.isNullOrEmty(thuHoiHuy.soQuyetDinh) &&
                            <FormHelperText className="-error"  >Vui lòng nhập Số quyết định </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={3} className="grid-item" >
                        <InputLabel required>Ngày quyết định</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                error={submit && !(thuHoiHuy.ngayQuyetDinh !== 0 && thuHoiHuy.ngayQuyetDinh && thuHoiHuy.ngayQuyetDinh !== null && thuHoiHuy.ngayQuyetDinh !== undefined)}
                                value={(thuHoiHuy.ngayQuyetDinh !== 0 && thuHoiHuy.ngayQuyetDinh && thuHoiHuy.ngayQuyetDinh !== null && thuHoiHuy.ngayQuyetDinh !== undefined) ? new Date(thuHoiHuy.ngayQuyetDinh) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayQuyetDinh')}

                            />
                        </MuiPickersUtilsProvider>
                        {
                            submit && (thuHoiHuy.ngayQuyetDinh  === 0 || !thuHoiHuy.ngayQuyetDinh  || thuHoiHuy.ngayQuyetDinh  === null || thuHoiHuy.ngayQuyetDinh  === undefined) &&
                            <FormHelperText className="-error"  >Vui lòng nhập ngày quyết định</FormHelperText>
                        }
                        </Grid>  
                        <Grid item xs={6} className="grid-item" >
                        <InputLabel >Người ký</InputLabel>
                        <InputBase fullWidth
                            value={thuHoiHuy.nguoiKy} name="nguoiKy" onChange={handleChange} />
                    </Grid>        
                        <Grid item xs={6} className="grid-item" >
                        <InputLabel >Lý do hủy</InputLabel>
                        <InputBase fullWidth
                            multiline
                            rows="6"
                            rowsMax="6"
                            name="lyDoHuy"
                            value={thuHoiHuy.lyDoHuy} onChange={handleChange} />
                    </Grid>  
                    <Grid item xs={6} className="grid-item" >
                        <InputLabel >Ghi chú</InputLabel>
                        <InputBase fullWidth
                            multiline
                            rows="6"
                            rowsMax="6"
                            name="ghiChu"
                            value={thuHoiHuy.ghiChu} onChange={handleChange} />
                    </Grid>  
                    <DropZoneCustom
                        acceptFiles={ACCEPT_FILES}
                        attachFileServer={thuHoiHuy.files}
                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/cpxd"}
                        handleChangeStatus={handleChangeStatus}
                        handleDeleteFileServer={handleDeleteFileServer}
                        handleDownloadFile={handleDownloadFile}
                    />      
                </Grid>
            }
        </>
    )
}
export default ChiTietNoiDungThuHoiComponent;