import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";
import { AttachFileDTO } from "./attachFile";

export interface HoSoKhenThuongDTO {
    id: null | number;
    thuTucId?: number;
    tenThuTuc?: String;
    soBienNhan?: String;

    soQuyetDinh?: String;
    ngayQuyetDinh?: null | Date;
    trichYeuQuyetDinh?: String;
    nguoiKyQuyetDinh?: String;
    donViKhenThuong?: String;
    maDonViKhenThuong?: String;

    soQuyetDinhHuy?: String;
    ngayQuyetDinhHuy?: null | Date;
    noiDungHuy?: String;
    nguoiKyQuyetDinhHuy?: String;

    soToTrinh?: String;
    ngayToTrinh?: null | Date;
    mocThoiGian?: MocThoiGianEnum;
    thoiGian?: String;
    trichYeu?: String;
    canCu?: String;
    deNghi?: String;
    attachFiles?: AttachFileDTO[];
    tenTapTheCaNhan?: String;

    tenQuanHuyen?: string;
    maQuanHuyen?: string;
    tenCoQuan?: String;
    maCoQuan?: String;
    userId?: String;

    loaiKetQua?: LoaiKetQuaEnum;
}

export enum MocThoiGianEnum {
    CONG_TAC = "0",
    NAM_HOC = "1",
}
