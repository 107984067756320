import { MenuAction } from "../actions/menuAction";

export interface MenuState {
    subMenuID: number;
    menuID: number;
    isSupperAdmin: boolean;
    menuActive: Number[];
    parentID: number;
    length: number;
}

const initialState: MenuState = {
    subMenuID: NaN,
    menuID: NaN,
    isSupperAdmin: false,
    menuActive: [],
    parentID: NaN,
    length: 0,
};

export const menuReducer = (state: MenuState = initialState, action: MenuAction) => {
    switch (action.type) {
        case "MENU":
            return { ...state, menuID: action.id, subMenuID: NaN, parentID: NaN, menuActive: [] } as MenuState;
        case "SUBMENU_ADD_CHIL":
            return {
                ...state,
                subMenuID: action.id,
                menuActive: [...state.menuActive, action.id],
                parentID: action.parentID ? action.parentID : state.parentID,
                length: action.length,
            } as MenuState;
        case "SELECT_OTHER_MENU":
            return {
                ...state,
                subMenuID: action.id,
                menuActive: [action.id],
                parentID: NaN,
            } as MenuState;
        case "SELECT_OTHER_MENU_CHIL":
            let newArr = [...state.menuActive];
            newArr[Number(state.length)] = Number(action.id);
            return {
                ...state,
                subMenuID: action.id,
                menuActive: newArr,
            } as MenuState;
        case "LOAD_MENU":
            return { ...state } as MenuState;
        case "RESET":
            return { ...state, menuID: NaN, menuActive: [], length: 0, parentID: NaN, subMenuID: NaN } as MenuState;
        default:
            return state;
    }
};
