import React, { Dispatch, useEffect, useState  } from 'react';
import { GIOI_TINH, HUYEN, LOAI_GIAY_TO, NOI_CAP, PHUONG, TINH_TP } from '../../../../../api/QuanTri/DanhMucFakeService';
import { ChuDauTuModel, ChuDauTuModel_init, DoiTuongChonTuKho } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { Autocomplete, Pagination } from '@material-ui/lab';
import {
    Box,
    Button,
    Dialog,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    TextField,
    InputAdornment,
    Snackbar,
    FormControlLabel,
    RadioGroup,
    Radio,
    NativeSelect,

} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import StringUtil from '../../../../utils/stringUtils';
import { DanhMucDiaChiDTO, DanhMucGioiTinhDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import SearchIcon from '@material-ui/icons/Search';
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { CustomInput } from '../../../../commons/components/input/customInput';
import DateUtil from '../../../../utils/dateUtils';
import { Cell } from '../../../../commons/components/table/tableCommons';
import { useDispatch } from 'react-redux';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';

interface NhaORiengLeChuDauTuModalProps {
    data: ChuDauTuModel;
    show: boolean;
    indexEdit: number;
    onToggle: () => void;
    onAddData: (value: ChuDauTuModel) => void;
    onUpdateData: (index: number, value: ChuDauTuModel) => void;

}
const NhaORiengLeChonTuKhoModal = (props: NhaORiengLeChuDauTuModalProps) => {
    const [dataPagi, setPataPagi] = useState<ChuDauTuModel[]>([]);
    const [data, setData] = React.useState<ChuDauTuModel>({ ...ChuDauTuModel_init });
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [indexEdit, setIndexEdit] = React.useState(-1);
    const { onToggle, onAddData, onUpdateData } = props;
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataGioiTinh, setDataGioiTinh] = useState<DanhMucGioiTinhDTO[]>([]);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [checked, setChecked] = React.useState(false);
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    const [showDate, setShowDate] = useState(true);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    useEffect(() => {
        if (props.indexEdit >= 0) {
            setData(props.data);
        } else {
            setData(data => ({
                ...ChuDauTuModel_init,
                loaiDoiTuong: 0,
                ngayCap: 0,
                ngaySinh: 0,
                maTinh: "92",
                tenTinh: "Thành phố Cần Thơ",
            }));
        }
        setShow(props.show);
        setIndexEdit(props.indexEdit);
        setSubmit(false);
        async function getDmGioiTinh() {
            await new DanhMucService().getDanhMucGioiTinh('').then((resp) => {
                setDataGioiTinh(resp);
            });
        }
        getDmGioiTinh();
    }, [props.data, props.show, props.indexEdit]);

    const handleChangeChecked = () => {
        setChecked(!checked);
        setShowDate(!showDate);
        setData({
            ...ChuDauTuModel_init,
        });
    }

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData(data => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }
   
    const handleSubmit = () => {
        setSubmit(true);
        if (validateFrom()) {
            onAddData(data);
            onToggle();
        }
    }

    const onSearchCMND = (cmnd: string) => {
        if (StringUtil.isNullOrEmty(cmnd)) {
            showMessageAlert("Số giấy tờ tùy thân không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoCMND({soCMND: cmnd}).then(res => {
            let dataNew = res?.data.data;
            
            if (dataNew !== null && dataNew !== undefined) {
                if(dataNew.error_code === 'SUCCESSFUL') {
                    let dataN = {
                        ...data,
                        loaiDoiTuong: 1,
                        hoTen: dataNew.data.hoVaTenCongDan,
                        ngaySinh: dataNew.data.ngayThangNamSinh,
                        tenGioiTinh: dataNew.data.maGioiTinh == "2" ? "Nam" : "Nữ",
                        soGiayTo: dataNew.data.soCMND,
                        diaChiChiTiet: dataNew.data.diaChi,
                    }
                    
                    setData(dataN);
                }else {
                    setAlertMessage({ open: true, content: "Số giấy tờ tùy thân không tồn tại trong dữ liệu", severity: 'warning' });
                }
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    };

const handleCloseAlertMessage = () => {
    setAlertMessage(alertMessage => ({
        ...alertMessage,
        open: false
    }));
}

const onSearchDKKD = (maSoDoanhNghiep: string) => {
    if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
        showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không được trống", false);
        return;
    } else {
        dispatchLoading(showLoading());
        new DanhMucService().getBySoDKKD({maSoDoanhNghiep: maSoDoanhNghiep}).then(res => {
        let dataNew = res?.data.data;
        let tenNguoiDaiDien = "",loaiTC="";
            for (let index = 0; index < res?.data?.data?.data?.listThanhVien.length; index++) {
                if (res?.data?.data?.data?.listThanhVien[index].isNguoiDaiDienPhapLuat=="1"){
                    tenNguoiDaiDien=res?.data?.data?.data?.listThanhVien[index].hoTen;
                    loaiTC=res?.data?.data?.data?.listThanhVien[index].loai;
                };
            }
        if (dataNew !== null && dataNew !== undefined) {
            if (dataNew.error_code === 'SUCCESSFUL' && dataNew.data && dataNew.data != null) {
            
            //const tested = DateUtil.stringDDMMYYYtoDate(dataNew.data.ngayThanhLap.split(' ')[0]);
            let dataN = {
                ...data,
                loaiDoiTuong : 2,
                tenToChuc: dataNew.data.tenDoanhNghiep_VN,
                //ngaySinh: tested!==null? tested.getTime():0,
                loaiToChuc: loaiTC,
                nguoiDaiDien: tenNguoiDaiDien,
                diaChiDoanhNghiep: dataNew.data.fullAddress,
            };
            setData(dataN);
            } else {
                setAlertMessage({ open: true, content: "Giấy CNĐKKD/DN/HTX/HKD không tồn tại trong dữ liệu", severity: 'warning' });
            }
        } 
    }).catch(error => {
        console.log(error);
    }).finally(() => {
        dispatchLoading(hideLoading());
    });
}
};

    const radioChange = () =>{
        let dataNew = {
            ...data,
            checked: 1,
            chonTuKho: 1,
        }
        setData(dataNew);
    }

  function validateFrom(): boolean {
        let valid: boolean = true;
        if (data.checked == 0) {
            valid = false;
        }
        return valid;
    }
    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        Chọn chủ đầu tư từ kho
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={12} className="grid-item" >
                                    <RadioGroup row style={{ flexWrap: "inherit" }} name="formDTO.doiTuong" value={String(data.loaiDoiTuong)}>
                                    <InputLabel >Loại đối tượng </InputLabel>
                                        <FormControlLabel 
                                            checked={checked} 
                                            value={DoiTuongChonTuKho.CA_NHAN} 
                                            control={<Radio size="small" color="default" />} 
                                            label="Cá nhân" 
                                            onChange={handleChangeChecked}
                                        />
                                        <FormControlLabel
                                            checked={!checked}
                                            value={DoiTuongChonTuKho.DOANH_NGHIEP}
                                            control={<Radio size="small" color="default" />}
                                            label="Doanh nghiệp"
                                            onChange={handleChangeChecked}
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel>Thông tin tìm kiếm</InputLabel>
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputBase fullWidth
                                        error={submit && StringUtil.isNullOrEmty(data.thongTinTimKiem)}
                                        value={data.thongTinTimKiem} name="thongTinTimKiem" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <Box marginTop="5px" hidden={showDate}>
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={(e) => onSearchCMND(data?.thongTinTimKiem)}>
                                            Tìm kiếm
                                        </Button>
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={(e) => handleSubmit()}>
                                            Chọn
                                    </Button>
                                    </Box>
                                    <Box marginTop="5px" hidden={!showDate}>
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={(e) => onSearchDKKD(data?.thongTinTimKiem)}>
                                            Tìm kiếm
                                        </Button>
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={(e) => handleSubmit()}>
                                            Chọn
                                    </Button>
                                    </Box>
                                </Grid>
                                
                    <Grid item xs={12} className="grid-item" hidden={showDate}>
                        <table className="table table-bordered" style={{ fontSize: '14px' }}>
                            <thead>
                                <tr className="table-secondary">
                                    <th style={{ width: "5%" }}>STT</th>
                                    <th></th>
                                    <th>Họ tên</th>
                                    <th>Ngày sinh</th>
                                    <th>Giới tính</th>
                                    <th>Số giấy tờ</th>
                                    <th>Địa chỉ</th>                            
                                </tr>
                            </thead>
                            <tbody>
                            {!StringUtil.isNullOrEmty(data?.soGiayTo) && (
                                <tr>
                                    <td className="cell-number">1</td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" value={data.checked} 
                                            onChange={(e) => radioChange()}/>
                                        </div>
                                    </td>
                                    <td>{data.hoTen}</td>
                                    <td>{data.ngaySinh}</td>
                                    <td>{data.tenGioiTinh}</td>
                                    <td>{data.soGiayTo}</td>
                                    <td>{data.diaChiChiTiet}</td>
                                </tr>
                            )}
                            {StringUtil.isNullOrEmty(data?.soGiayTo) && (
                                <Cell colSpan={7} style={{ padding: "8px", textAlign: "center" }}>
                                    Không có dữ liệu
                                </Cell>
                            )}
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} className="grid-item" hidden={!showDate}>
                        <table className="table table-bordered" style={{ fontSize: '14px' }}>
                            <thead>
                                <tr className="table-secondary">
                                    <th style={{ width: "5%" }}>STT</th>
                                    <th></th>
                                    <th>Tên tổ chức</th>
                                    <th>Người đại diện</th>
                                    <th>Địa chỉ</th>                        
                                </tr>
                            </thead>
                            <tbody>
                                {!StringUtil.isNullOrEmty(data?.diaChiDoanhNghiep) && (
                                    <tr>
                                        <td className="cell-number">1</td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios" value={data.checked} 
                                                onChange={(e) => radioChange()}/>
                                            </div>
                                        </td>
                                        <td>{data.tenToChuc}</td>
                                        <td>{data.nguoiDaiDien}</td>
                                        <td>{data.diaChiDoanhNghiep}</td>
                                    </tr>
                                )}
                                {StringUtil.isNullOrEmty(data?.diaChiDoanhNghiep) && (
                                    <Cell colSpan={7} style={{ padding: "8px", textAlign: "center" }}>
                                        Không có dữ liệu
                                    </Cell>
                                )}
                            </tbody>
                        </table>
                    </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
                </Dialog>
            }
        </>
    )
}
export default NhaORiengLeChonTuKhoModal;
