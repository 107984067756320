import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  ThongKeTinhHinhRespone,
  TongSo,
} from "../../../../api/CPXD/model/ThongKeTinhHinhCPXD";
import { Cell, Row } from "../../../commons/components/table/tableCommons";

interface IDataListProps {
  data: ThongKeTinhHinhRespone;
  maLoaiThongke: string;
  detailRequest: any;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  setDetailRequest: Dispatch<SetStateAction<any>>;
}

export const TableThongKe = (props: IDataListProps) => {
  const [data, setData] = useState<ThongKeTinhHinhRespone>();
  const [tongSo, setTongSo] = useState<TongSo>();

  useEffect(() => {
    setData(props.data);
    setTongSo(props.data?.tongSo);
  }, [props.data?.thongKeTinhHinhs]);

  const handleOnClickChiTietRow = (row: any, type: string, count: number) => {
    props.setOpenDialog(true);
    props.setDetailRequest({
      ...props.detailRequest,
      maLoaiThongKe: props.maLoaiThongke,
      maBienDong: type,
      maPhuongDetail: props.maLoaiThongke === "1" ? row.ma : null,
      maLoaiGiayPhep:
        props.maLoaiThongke === "2" || props.maLoaiThongke === "3"
          ? row.ma
          : null,
      maTinhTrang: props.maLoaiThongke === "3" ? type : null,
      label: row.ten !== null ? row.ten : "",
      total: count,
    });
  };

  const handleOnClickChiTietCol = (type: string, count: number) => {
    props.setOpenDialog(true);
    props.setDetailRequest({
      ...props.detailRequest,
      maLoaiThongKe: props.maLoaiThongke,
      maBienDong: type,
      maPhuongDetail: null,
      maLoaiGiayPhep: null,
      maTinhTrang: props.maLoaiThongke === "3" ? type : null,
      label: null,
      total: count,
    });
  };

  return (
    <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <Cell component="th" scope="row" align="center" width="5%">
              STT
            </Cell>
            {props.maLoaiThongke === "1" && (
              <Cell component="th" scope="row" align="center" width="20%">
                Phường/xã
              </Cell>
            )}
            {(props.maLoaiThongke === "2" || props.maLoaiThongke === "3") && (
              <Cell
                component="th"
                scope="row"
                align="center"
                width={props.maLoaiThongke === "2" ? "20%" : "40%"}
              >
                Loại giấy phép
              </Cell>
            )}
            {props.maLoaiThongke === "3" && (
              <>
                <Cell component="th" scope="row" align="center" width="15%">
                  Đang hoạt động
                </Cell>
                <Cell component="th" scope="row" align="center" width="15%">
                  Hết hạn
                </Cell>
                <Cell component="th" scope="row" align="center" width="15%">
                  Thu hồi
                </Cell>
                <Cell component="th" scope="row" align="center" width="15%">
                  Hủy
                </Cell>
              </>
            )}
            {(props.maLoaiThongke === "1" || props.maLoaiThongke === "2") && (
              <>
                <Cell component="th" scope="row" align="center">
                  Cấp mới
                </Cell>
                <Cell component="th" scope="row" align="center">
                  Cấp lại
                </Cell>
                <Cell component="th" scope="row" align="center">
                  Gia hạn
                </Cell>
                <Cell component="th" scope="row" align="center">
                  Điều chỉnh
                </Cell>
                <Cell component="th" scope="row" align="center">
                  Thu hồi
                </Cell>
                <Cell component="th" scope="row" align="center">
                  Hủy
                </Cell>
              </>
            )}
          </TableRow>
        </TableHead>
        {(props.maLoaiThongke === "1" || props.maLoaiThongke === "2") && (
          <>
            <TableBody>
              {data?.thongKeTinhHinhs && data?.thongKeTinhHinhs.length > 0 ? (
                data.thongKeTinhHinhs.map((row, i) => (
                  <Row key={i}>
                    <Cell scope="row" align="center">
                      {i + 1}
                    </Cell>
                    <Cell align="left">{row.ten}</Cell>
                    <Cell align="center" className="text-center">
                      <span
                        style={{
                          cursor: row.tongCapMoi > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongCapMoi > 0) {
                            handleOnClickChiTietRow(row, "1", row.tongCapMoi);
                          }
                        }}
                      >
                        {row.tongCapMoi}
                      </span>
                    </Cell>
                    <Cell align="center">
                      <span
                        style={{
                          cursor: row.tongCapLai > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongCapLai > 0) {
                            handleOnClickChiTietRow(row, "2", row.tongCapLai);
                          }
                        }}
                      >
                        {row.tongCapLai}
                      </span>
                    </Cell>
                    <Cell align="center">
                      <span
                        style={{
                          cursor: row.tongGiaHan > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongGiaHan > 0) {
                            handleOnClickChiTietRow(row, "5", row.tongGiaHan);
                          }
                        }}
                      >
                        {row.tongGiaHan}
                      </span>
                    </Cell>
                    <Cell align="center">
                      <span
                        style={{
                          cursor: row.tongDieuChinh > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongDieuChinh > 0) {
                            handleOnClickChiTietRow(
                              row,
                              "3",
                              row.tongDieuChinh
                            );
                          }
                        }}
                      >
                        {row.tongDieuChinh}
                      </span>
                    </Cell>
                    <Cell align="center">
                      <span
                        style={{
                          cursor: row.tongThuHoi > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongThuHoi > 0) {
                            handleOnClickChiTietRow(row, "7", row.tongThuHoi);
                          }
                        }}
                      >
                        {row.tongThuHoi}
                      </span>
                    </Cell>
                    <Cell align="center">
                      <span
                        style={{
                          cursor: row.tongHuy > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongHuy > 0) {
                            handleOnClickChiTietRow(row, "6", row.tongHuy);
                          }
                        }}
                      >
                        {row.tongHuy}
                      </span>
                    </Cell>
                  </Row>
                ))
              ) : (
                <Row>
                  <Cell colSpan={8} scope="row" align="center">
                    {" "}
                    Không có dữ liệu
                  </Cell>
                </Row>
              )}
              {(props.maLoaiThongke === "2" || props.maLoaiThongke === "1") && (
                <>
                  {data?.thongKeTinhHinhs && data?.thongKeTinhHinhs.length > 0 && (
                    <Row>
                      <Cell scope="row" align="center"></Cell>
                      <Cell align="left" className="font-weight-bold">
                        {" "}
                        Tổng số
                      </Cell>
                      <Cell
                        align="center"
                        className="font-weight-bold text-center"
                      >
                        <span
                          style={{
                            cursor:
                              tongSo?.tongCapMoi && tongSo?.tongCapMoi > 0
                                ? "pointer"
                                : "",
                          }}
                          onClick={() => {
                            if (tongSo?.tongCapMoi && tongSo?.tongCapMoi > 0)
                              handleOnClickChiTietCol(
                                "1",
                                tongSo?.tongCapMoi ? tongSo?.tongCapMoi : 0
                              );
                          }}
                        >
                          {tongSo?.tongCapMoi}
                        </span>
                      </Cell>
                      <Cell align="center" className="font-weight-bold">
                        <span
                          style={{
                            cursor:
                              tongSo?.tongCapLai && tongSo?.tongCapLai > 0
                                ? "pointer"
                                : "",
                          }}
                          onClick={() => {
                            if (tongSo?.tongCapLai && tongSo?.tongCapLai > 0)
                              handleOnClickChiTietCol(
                                "2",
                                tongSo?.tongCapLai ? tongSo?.tongCapLai : 0
                              );
                          }}
                        >
                          {tongSo?.tongCapLai}
                        </span>
                      </Cell>
                      <Cell align="center" className="font-weight-bold">
                        <span
                          style={{
                            cursor:
                              tongSo?.tongGiaHan && tongSo?.tongGiaHan > 0
                                ? "pointer"
                                : "",
                          }}
                          onClick={() => {
                            if (tongSo?.tongGiaHan && tongSo?.tongGiaHan > 0)
                              handleOnClickChiTietCol(
                                "5",
                                tongSo?.tongGiaHan ? tongSo?.tongGiaHan : 0
                              );
                          }}
                        >
                          {tongSo?.tongGiaHan}
                        </span>
                      </Cell>
                      <Cell align="center" className="font-weight-bold">
                        <span
                          style={{
                            cursor:
                              tongSo?.tongDieuChinh && tongSo?.tongDieuChinh > 0
                                ? "pointer"
                                : "",
                          }}
                          onClick={() => {
                            if (
                              tongSo?.tongDieuChinh &&
                              tongSo?.tongDieuChinh > 0
                            )
                              handleOnClickChiTietCol(
                                "3",
                                tongSo?.tongDieuChinh
                                  ? tongSo?.tongDieuChinh
                                  : 0
                              );
                          }}
                        >
                          {tongSo?.tongDieuChinh}
                        </span>
                      </Cell>
                      <Cell align="center" className="font-weight-bold">
                        <span
                          style={{
                            cursor:
                              tongSo?.tongThuHoi && tongSo?.tongThuHoi > 0
                                ? "pointer"
                                : "",
                          }}
                          onClick={() => {
                            if (tongSo?.tongThuHoi && tongSo?.tongThuHoi > 0)
                              handleOnClickChiTietCol(
                                "7",
                                tongSo?.tongThuHoi ? tongSo?.tongThuHoi : 0
                              );
                          }}
                        >
                          {tongSo?.tongThuHoi}
                        </span>
                      </Cell>
                      <Cell align="center" className="font-weight-bold">
                        <span
                          style={{
                            cursor:
                              tongSo?.tongHuy && tongSo?.tongHuy > 0
                                ? "pointer"
                                : "",
                          }}
                          onClick={() => {
                            if (tongSo?.tongHuy && tongSo?.tongHuy > 0)
                              handleOnClickChiTietCol(
                                "6",
                                tongSo?.tongHuy ? tongSo?.tongHuy : 0
                              );
                          }}
                        >
                          {tongSo?.tongHuy}
                        </span>
                      </Cell>
                    </Row>
                  )}
                </>
              )}
            </TableBody>
          </>
        )}
        {props.maLoaiThongke === "3" && (
          <>
            <TableBody>
              {data?.thongKeTinhHinhs && data?.thongKeTinhHinhs.length > 0 ? (
                data.thongKeTinhHinhs.map((row, i) => (
                  <Row key={i}>
                    <Cell scope="row" align="center">
                      {i + 1}
                    </Cell>
                    <Cell align="left">{row.ten}</Cell>
                    <Cell align="center" className="text-center">
                      <span
                        style={{
                          cursor: row.tongDangHoatDong > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongDangHoatDong > 0) {
                            handleOnClickChiTietRow(
                              row,
                              "1",
                              row.tongDangHoatDong
                            );
                          }
                        }}
                      >
                        {row.tongDangHoatDong}
                      </span>
                    </Cell>
                    <Cell align="center">
                      <span
                        style={{
                          cursor: row.tongHetHan > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongHetHan > 0) {
                            handleOnClickChiTietRow(row, "2", row.tongHetHan);
                          }
                        }}
                      >
                        {row.tongHetHan}
                      </span>
                    </Cell>
                    <Cell align="center">
                      <span
                        style={{
                          cursor: row.tongThuHoi > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongThuHoi > 0) {
                            handleOnClickChiTietRow(row, "3", row.tongThuHoi);
                          }
                        }}
                      >
                        {row.tongThuHoi}
                      </span>
                    </Cell>
                    <Cell align="center">
                      <span
                        style={{
                          cursor: row.tongHuy > 0 ? "pointer" : "",
                        }}
                        onClick={() => {
                          if (row.tongHuy > 0) {
                            handleOnClickChiTietRow(row, "4", row.tongHuy);
                          }
                        }}
                      >
                        {row.tongHuy}
                      </span>
                    </Cell>
                  </Row>
                ))
              ) : (
                <Row>
                  <Cell colSpan={6} scope="row" align="center">
                    {" "}
                    Không có dữ liệu
                  </Cell>
                </Row>
              )}
              {data?.thongKeTinhHinhs && data?.thongKeTinhHinhs.length > 0 && (
                <Row>
                  <Cell scope="row" align="center"></Cell>
                  <Cell align="left" className="font-weight-bold">
                    Tổng số
                  </Cell>
                  <Cell align="center" className="font-weight-bold text-center">
                    <span
                      style={{
                        cursor:
                          tongSo?.tongDangHoatDong &&
                          tongSo?.tongDangHoatDong > 0
                            ? "pointer"
                            : "",
                      }}
                      onClick={() => {
                        if (
                          tongSo?.tongDangHoatDong &&
                          tongSo?.tongDangHoatDong > 0
                        )
                          handleOnClickChiTietCol(
                            "1",
                            tongSo?.tongDangHoatDong
                              ? tongSo?.tongDangHoatDong
                              : 0
                          );
                      }}
                    >
                      {tongSo?.tongDangHoatDong}
                    </span>
                  </Cell>
                  <Cell align="center" className="font-weight-bold">
                    <span
                      style={{
                        cursor:
                          tongSo?.tongHetHan && tongSo?.tongHetHan > 0
                            ? "pointer"
                            : "",
                      }}
                      onClick={() => {
                        if (tongSo?.tongHetHan && tongSo?.tongHetHan > 0)
                          handleOnClickChiTietCol(
                            "2",
                            tongSo?.tongHetHan ? tongSo?.tongHetHan : 0
                          );
                      }}
                    >
                      {tongSo?.tongHetHan}
                    </span>
                  </Cell>
                  <Cell align="center" className="font-weight-bold">
                    <span
                      style={{
                        cursor:
                          tongSo?.tongThuHoi && tongSo?.tongThuHoi > 0
                            ? "pointer"
                            : "",
                      }}
                      onClick={() => {
                        if (tongSo?.tongThuHoi && tongSo?.tongThuHoi > 0)
                          handleOnClickChiTietCol(
                            "3",
                            tongSo?.tongThuHoi ? tongSo?.tongThuHoi : 0
                          );
                      }}
                    >
                      {tongSo?.tongThuHoi}
                    </span>
                  </Cell>
                  <Cell align="center" className="font-weight-bold">
                    <span
                      style={{
                        cursor:
                          tongSo?.tongHuy && tongSo?.tongHuy > 0
                            ? "pointer"
                            : "",
                      }}
                      onClick={() => {
                        if (tongSo?.tongHuy && tongSo?.tongHuy > 0)
                          handleOnClickChiTietCol(
                            "4",
                            tongSo?.tongHuy ? tongSo?.tongHuy : 0
                          );
                      }}
                    >
                      {tongSo?.tongHuy}
                    </span>
                  </Cell>
                </Row>
              )}
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};
