import { lazy } from "react";
import { RoutePropsI } from ".";
import { NguonKinhPhiComponent } from "../App/Components/DKKD/DanhMuc/NguonKinhPhi/NguonKinhPhi";
import { ChuyenNganhGiayPhepGiaDinh } from "../App/Components/KhenThuong/chuyenNganh/giayKhenGiaDinh";
import { ChuyenNganhLaoDongTienTien } from "../App/Components/KhenThuong/chuyenNganh/laoDongTienTien";
import { ChuyenNganhNhiemVuChinhTri } from "../App/Components/KhenThuong/chuyenNganh/nhiemVuChinhTri";
import { ChuyenNganhThiDuaTheoDotChuyenDe } from "../App/Components/KhenThuong/chuyenNganh/thiDuaTheoDotChuyenDe";
import { ChuyenNganhChienSyThiDua } from "../App/Components/KhenThuong/chuyenNganh/tienSyThiDua";
import { DonViCapPhepComponent } from "../App/Components/KhenThuong/danhMuc/donViCapPhep/main";
import { ChuyenNganhGiayPhepGiaDinhDauKy } from "../App/Components/KhenThuong/chuyenNganhDauKy/giayKhenGiaDinh";
import { ChuyenNganhLaoDongTienTienDauKy } from "../App/Components/KhenThuong/chuyenNganhDauKy/laoDongTienTien";
import { ChuyenNganhNhiemVuChinhTriDauKy } from "../App/Components/KhenThuong/chuyenNganhDauKy/nhiemVuChinhTri";
import { ChuyenNganhThiDuaTheoDotChuyenDeDauKy } from "../App/Components/KhenThuong/chuyenNganhDauKy/thiDuaTheoDotChuyenDe";
import { ChuyenNganhChienSyThiDuaDauKy } from "../App/Components/KhenThuong/chuyenNganhDauKy/tienSyThiDua";
const DanhMucLoaiHinhMgt = lazy(() => import("../App/Components/KhenThuong/danhMuc/loaiHinhKhenThuong/danhMucLoaiHinhKhenThuongMgt").then(({ DanhMucLoaiHinhMgt }) => ({ default: DanhMucLoaiHinhMgt })));
const DanhMucHinhThucMgt = lazy(() => import("../App/Components/KhenThuong/danhMuc/hinhThuc/danhMucHinhThucMgt").then(({ DanhMucHinhThucMgt }) => ({ default: DanhMucHinhThucMgt })));
const DanhMucDanhHieuMgt = lazy(() => import("../App/Components/KhenThuong/danhMuc/danhHieu/danhMucDanhHieuMgt").then(({ DanhMucDanhHieuMgt }) => ({ default: DanhMucDanhHieuMgt })));
const ChuyenNganhSo1 = lazy(() => import("../App/Components/KhenThuong/chuyenNganh/chuyenNganhSo1/index").then(({ ChuyenNganhSo1 }) => ({ default: ChuyenNganhSo1 })));
const ChuyenNganhSo1DauKy = lazy(() => import("../App/Components/KhenThuong/chuyenNganhDauKy/chuyenNganhSo1/index").then(({ ChuyenNganhSo1DauKy }) => ({ default: ChuyenNganhSo1DauKy })));
const CapNhatQuyetDinhKT = lazy(() => import("../App/Components/KhenThuong/capNhatQuyetDinhKT/index").then(({ CapNhatQuyetDinhKT }) => ({ default: CapNhatQuyetDinhKT })));
const TraCuuQuyetDinhKT = lazy(() => import("../App/Components/KhenThuong/traCuu/index").then(({ TraCuu }) => ({ default: TraCuu })));
const BaoCaoThiDuaKT = lazy(() => import("../App/Components/KhenThuong/baoCaoThiDuaKT/index").then(({ BaoCaoThiDuaKT }) => ({ default: BaoCaoThiDuaKT })));
const ThongKeThiDuaKT = lazy(() => import("../App/Components/KhenThuong/thongKeThiDuaKT/index").then(({ ThongKeThiDuaKT }) => ({ default: ThongKeThiDuaKT })));

export const ROUTE_CN_TDKT: RoutePropsI[] = [
    {
        ten: "Tặng Giấy khen của Chủ tịch UBND cấp huyện về thành tích đột xuất",
        giaoDien: ChuyenNganhSo1,
        duongDan: "/chuyen-nganh-tdkt/so-1",
    },
    {
        ten: "Tặng Giấy khen của Chủ tịch UBND cấp huyện về thành tích thi đua theo đợt, chuyên đề",
        giaoDien: ChuyenNganhThiDuaTheoDotChuyenDe,
        duongDan: "/chuyen-nganh-tdkt/so-2",
    },
    {
        ten: "Tặng giấy khen của chủ tịch ubnd cấp huyện về thành tích thực hiện nhiệm vụ chính trị",
        giaoDien: ChuyenNganhNhiemVuChinhTri,
        duongDan: "/chuyen-nganh-tdkt/so-3",
    },
    {
        ten: "Tặng danh hiệu Lao động tiên tiến",
        giaoDien: ChuyenNganhLaoDongTienTien,
        duongDan: "/chuyen-nganh-tdkt/so-4",
    },
    {
        ten: "Tặng danh hiệu Chiến sỹ thi đua cơ sở",
        giaoDien: ChuyenNganhChienSyThiDua,
        duongDan: "/chuyen-nganh-tdkt/so-5",
    },
    {
        ten: "Tặng Giấy khen của Chủ tịch UBND cấp huyện cho gia đình",
        giaoDien: ChuyenNganhGiayPhepGiaDinh,
        duongDan: "/chuyen-nganh-tdkt/so-6",
    },
    // {
    //     ten: "Nhập đầu kỳ tặng giấy khen của Chủ tịch UBND cấp huyện về thành tích đột xuất",
    //     giaoDien: ChuyenNganhSo1DauKy,
    //     duongDan: "/chuyen-nganh-tdkt/so-1-dau-ky",
    // },
    // {
    //     ten: "Nhập đầu kỳ tặng giấy khen của Chủ tịch UBND cấp huyện về thành tích thi đua theo đợt, chuyên đề",
    //     giaoDien: ChuyenNganhThiDuaTheoDotChuyenDeDauKy,
    //     duongDan: "/chuyen-nganh-tdkt/so-2-dau-ky",
    // },
    // {
    //     ten: "Nhập đầu kỳ tặng giấy khen của chủ tịch ubnd cấp huyện về thành tích thực hiện nhiệm vụ chính trị",
    //     giaoDien: ChuyenNganhNhiemVuChinhTriDauKy,
    //     duongDan: "/chuyen-nganh-tdkt/so-3-dau-ky",
    // },
    // {
    //     ten: "Nhập đầu kỳ tặng danh hiệu Lao động tiên tiến",
    //     giaoDien: ChuyenNganhLaoDongTienTienDauKy,
    //     duongDan: "/chuyen-nganh-tdkt/so-4-dau-ky",
    // },
    // {
    //     ten: "Nhập đầu kỳ tặng danh hiệu Chiến sỹ thi đua cơ sở",
    //     giaoDien: ChuyenNganhChienSyThiDuaDauKy,
    //     duongDan: "/chuyen-nganh-tdkt/so-5-dau-ky",
    // },
    // {
    //     ten: "Nhập đầu kỳ tặng giấy khen của Chủ tịch UBND cấp huyện cho gia đình",
    //     giaoDien: ChuyenNganhGiayPhepGiaDinhDauKy,
    //     duongDan: "/chuyen-nganh-tdkt/so-6-dau-ky",
    // }
];

export const ROUTE_CN_DAU_KY_TDKT: RoutePropsI[] = [
    {
        ten: "Nhập đầu kỳ tặng Giấy khen của Chủ tịch UBND cấp huyện về thành tích đột xuất",
        giaoDien: ChuyenNganhSo1DauKy,
        duongDan: "/chuyen-nganh-tdkt/so-1-dau-ky",
    },
    {
        ten: "Nhập đầu kỳ tặng Giấy khen của Chủ tịch UBND cấp huyện về thành tích thi đua theo đợt, chuyên đề",
        giaoDien: ChuyenNganhThiDuaTheoDotChuyenDeDauKy,
        duongDan: "/chuyen-nganh-tdkt/so-2-dau-ky",
    },
    {
        ten: "Nhập đầu kỳ tặng giấy khen của chủ tịch ubnd cấp huyện về thành tích thực hiện nhiệm vụ chính trị",
        giaoDien: ChuyenNganhNhiemVuChinhTriDauKy,
        duongDan: "/chuyen-nganh-tdkt/so-3-dau-ky",
    },
    {
        ten: "Nhập đầu kỳ tặng danh hiệu Lao động tiên tiến",
        giaoDien: ChuyenNganhLaoDongTienTienDauKy,
        duongDan: "/chuyen-nganh-tdkt/so-4-dau-ky",
    },
    {
        ten: "Nhập đầu kỳ tặng danh hiệu Chiến sỹ thi đua cơ sở",
        giaoDien: ChuyenNganhChienSyThiDuaDauKy,
        duongDan: "/chuyen-nganh-tdkt/so-5-dau-ky",
    },
    {
        ten: "Nhập đầu kỳ tặng Giấy khen của Chủ tịch UBND cấp huyện cho gia đình",
        giaoDien: ChuyenNganhGiayPhepGiaDinhDauKy,
        duongDan: "/chuyen-nganh-tdkt/so-6-dau-ky",
    }
];

export const ROUTE_PAGE_TDKT: RoutePropsI[] = [
    {
        ten: "DM đơn vị cấp phép",
        giaoDien: DonViCapPhepComponent,
        duongDan: "/quan-ly-dm-don-vi-cap-phep",
    },
    {
        ten: "DM hình thức",
        giaoDien: DanhMucHinhThucMgt,
        duongDan: "/quan-ly-dm-hinh-thuc",
    },
    {
        ten: "DM loại hình khen thưởng",
        giaoDien: DanhMucLoaiHinhMgt,
        duongDan: "/quan-ly-dm-loai-hinh-khen-thuong",
    },
    {
        ten: "DM danh hiệu",
        giaoDien: DanhMucDanhHieuMgt,
        duongDan: "/quan-ly-dm-danh-hieu",
    },
    {
        ten: "Cập nhật quyết định KT",
        duongDan: "/cap-nhat-quyet-dinh-khen-thuong",
        giaoDien: CapNhatQuyetDinhKT,
    },
    {
        ten: "Tra cứu quyết định khen thưởng",
        duongDan: "/tra-cuu-quyet-dinh-khen-thuong",
        giaoDien: TraCuuQuyetDinhKT,
    },
    {
        ten: "Báo cáo thi đua khen thưởng",
        duongDan: "/bao-cao-thi-dua-khen-thuong",
        giaoDien: BaoCaoThiDuaKT,
    },
    {
        ten: "Thống kê thi đua khen thưởng",
        duongDan: "/thong-ke-thi-dua-khen-thuong",
        giaoDien: ThongKeThiDuaKT,
    },
    {
        ten: "Danh mục nguồn kinh phí",
        duongDan: '/quan-ly-dm-nguon-kinh-phi',
        giaoDien: NguonKinhPhiComponent,
    },
];

export const ROUTE_DS_TDKT: RoutePropsI[] = [
    {
        ten: "TRA CỨU QUYẾT ĐINH KHEN THƯỞNG",
        duongDan: "/tra-cuu-quyet-dinh-khen-thuong",
        giaoDien: TraCuuQuyetDinhKT,
    },
    {
        ten: "BÁO CÁO THI ĐUA KHEN THƯỞNG",
        duongDan: "/bao-cao-thi-dua-khen-thuong",
        giaoDien: BaoCaoThiDuaKT,
    },
    {
        ten: "THỐNG KÊ THI ĐUA KHEN THƯỞNG",
        duongDan: "/thong-ke-thi-dua-khen-thuong",
        giaoDien: ThongKeThiDuaKT,
    },
];

export const ROUTE_PAGE_PUBLIC_TDKT: RoutePropsI[] = [
    {
        ten: "Thống kê thi đua khen thưởng",
        duongDan: "/thong-ke-thi-dua-khen-thuong",
        giaoDien: <ThongKeThiDuaKT />,
    }
];