import AXIOS_INSTANCE from "..";
import { BaseResponse, BaseService } from "../baseService";

export class BangDoService extends BaseService {
    search = async (search: any): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await AXIOS_INSTANCE.post('bang-do/search', search, this.getTokenRequestHeaders()).then(response => {
            if (response && response.data && response.data.error_code === 'SUCCESSFUL') {
                result = {
                    isSuccess: response.data.error_code === 'SUCCESSFUL',
                    message: response.data.error_message,
                    data: response.data.data
                }
            } else {
                result = {
                    isSuccess: false,
                    message: response.data.error_message,
                    data: { data: [], total: 0 }
                }
            }
        }).catch(() => {
            result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' }
        });
        return result;
    }
}