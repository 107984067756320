import { RoutePropsI } from "."
import { NhapDauKyGiayChungNhanLPGChai } from "../App/Components/LPGChai/HoSoThuLy/DauKy/NhapDauKyGiayChungNhanLPGChai";
import { CapMoiGiayChungNhanLPGChai } from "../App/Components/LPGChai/HoSoThuLy/CapMoi/CapMoiGiayChungNhanLPGChai";
import { HoSoCapSoGiayPhepLPGChai } from "../App/Components/LPGChai/CapSoGiayPhep/HoSoCapSoGiayPhepLPGChai";
import { DanhSachGiayChungNhanLPGChai } from "../App/Components/LPGChai/DanhSach/danhSachGiayChungNhanLPGChai";
import { BaoCaoGiayPhepLPGChai } from "../App/Components/LPGChai/BaoCaoLPGChai/BaoCaoGiayPhepLPGChai";
import { BaoCaoThongKeGiayPhepLPGChai } from "../App/Components/LPGChai/BaoCaoThongKeLPGChai/BaoCaoThongKeGiayPhepLPGChai";
import { BieuDoThongKeTinhHinhCapPhep } from "../App/Components/LPGChai/BieuDoThongKe/BieuDoThongKeLPGChai";
import ChiTietGiayPhepKinhDoanhLPGChai from "../App/Components/LPGChai/ChiTiet/ChiTietGiayPhepLPGChai";
import HoanTraHuyGiayPhepLPGChai from "../App/Components/LPGChai/QuanLyGP/HoanTraHuyGPTL";
import HoanTraThuHoiGiayPhepLPGChai from "../App/Components/LPGChai/QuanLyGP/HoanTraThuHoiGPTL";
import HuyGiayPhepKinhDoanhLGPChai from "../App/Components/LPGChai/QuanLyGP/HuyGPTL";
import ThuHoiGiayPhepLPGChai from "../App/Components/LPGChai/QuanLyGP/ThuHoiGPTL";
import CapLaiGiayChungNhanLPGChai from "../App/Components/LPGChai/HoSoThuLy/CapLai/CapLaiLGPChaiComponent";
import DieuChinhGiayChungNhanLPGChai from "../App/Components/LPGChai/HoSoThuLy/CapSuaDoi/DieuChinhLGPChaiComponent";
import { BieuDoTronLPGChai } from "../App/Components/LPGChai/BieuDoTron/BieuDoTronLPGChai";

export const ROUTE_LGP_DAUKY = {
    ten: 'NHẬP ĐẦU KỲ GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN CỬA HÀNG BÁN LẺ LPG CHAI',
    giaoDien: NhapDauKyGiayChungNhanLPGChai,
    duongDan: '/giay-chung-nhan-lpgchai/nhap-dau-ky',
}

export const ROUTE_LGP_CAPMOI = {
    ten: 'CẤP GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN CỬA HÀNG BÁN LẺ LPG CHAI',
    giaoDien: CapMoiGiayChungNhanLPGChai,
    duongDan: '/giay-chung-nhan-lpgchai/cap-moi',
}

export const ROUTE_LGP_CAPLAI = {
    ten: 'CẤP LẠI GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN CỬA HÀNG BÁN LẺ LPG CHAI',
    giaoDien: CapLaiGiayChungNhanLPGChai,
    duongDan: '/giay-chung-nhan-lpgchai/cap-lai',
}

export const ROUTE_LGP_DIEUCHINH = {
    ten: 'ĐIỀU CHỈNH GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN CỬA HÀNG BÁN LẺ LPG CHAI',
    giaoDien: DieuChinhGiayChungNhanLPGChai,
    duongDan: '/giay-chung-nhan-lpgchai/dieu-chinh',
}
export const ROUTE_CN_LPG: RoutePropsI[] = [
    ROUTE_LGP_CAPMOI,
    ROUTE_LGP_CAPLAI,
    ROUTE_LGP_DIEUCHINH,
    {
        ten: 'THÔNG TIN CHI TIẾT GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: ChiTietGiayPhepKinhDoanhLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/chi-tiet',
    },
    {
        ten: 'HỦY GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: HuyGiayPhepKinhDoanhLGPChai,
        duongDan: '/giay-chung-nhan-lpgchai/huy-giay-phep',
    },
    {
        ten: 'HOÀN TRẢ HỦY GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: HoanTraHuyGiayPhepLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/hoan-tra-huy-giay-phep',
    },
    {
        ten: 'THU HỒI GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: ThuHoiGiayPhepLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/thu-hoi-giay-phep',
    },
    {
        ten: 'HOÀN TRẢ THU HỒI GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: HoanTraThuHoiGiayPhepLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/hoan-tra-thu-hoi-giay-phep',
    },
    ROUTE_LGP_DAUKY,
];

export const ROUTE_PAGE_LPG: RoutePropsI[] = [
    {
        ten: 'CẬP NHẬT SỐ GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN CỬA HÀNG BÁN LẺ LPG CHAI',
        giaoDien: HoSoCapSoGiayPhepLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/cap-so',
    },
    {
        ten: 'DANH SÁCH GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: DanhSachGiayChungNhanLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/danh-sach',
    },

    {
        ten: 'BÁO CÁO TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: BaoCaoGiayPhepLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/bao-cao-danh-sach',
    },
    {
        ten: 'THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: BaoCaoThongKeGiayPhepLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/bao-cao-thong-ke',
    },
    {
        ten: 'BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: BieuDoThongKeTinhHinhCapPhep,
        duongDan: '/giay-chung-nhan-lpgchai/bieu-do-thong-ke',
    },
    {
        ten: 'BIỂU ĐỒ TRÒN THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: BieuDoTronLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/bieu-do-tron-thong-ke',
    }
];

export const ROUTE_DS_LPG: RoutePropsI[] = [
    {
        ten: 'DANH SÁCH GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: DanhSachGiayChungNhanLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/danh-sach',
    },
    {
        ten: 'BÁO CÁO TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: BaoCaoGiayPhepLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/bao-cao-danh-sach',
    },
    {
        ten: 'THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: BaoCaoThongKeGiayPhepLPGChai,
        duongDan: '/giay-chung-nhan-lpgchai/bao-cao-thong-ke',
    },
];

export const ROUTE_PAGE_PUBLIC_LPG: RoutePropsI[] = [
    {
        ten: 'BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN LPG CHAI',
        giaoDien: <BieuDoThongKeTinhHinhCapPhep />,
        duongDan: '/giay-chung-nhan-lpgchai/bieu-do-thong-ke',
    }
];
