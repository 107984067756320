import React, { useEffect } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import moment from "moment";
import { HoSoThuLyBLThuocLa, HoSoThuLyBLThuocLaBienDong } from "../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";

interface ThongTinGPComponentComponentProps {
    data: HoSoThuLyBLThuocLaBienDong;
}

const ThongTinChungGPComponent = (props: ThongTinGPComponentComponentProps) => {
    const [data, setData] = React.useState({} as HoSoThuLyBLThuocLaBienDong);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <>
            {
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Số giấy phép</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soGiayPhep}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data.ngayCapPhep != 0 ? moment(data.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Tên thương nhân</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.tenThuongNhan}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Địa chỉ</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.diaChiDayDu}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Điện thoại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.dienThoai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Fax</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.fax}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Số Giấy CNĐKDN/CNĐKKD</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soCNDKKD}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Ngày cấp lần đầu</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.ngayDangKyLanDau != null && data?.ngayDangKyLanDau > 0) ? moment(data?.ngayDangKyLanDau).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Đăng ký thay đổi lần thứ</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.lanThayDoi}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày thay đổi</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.ngayThayDoi && data?.ngayThayDoi != 0 ? moment(new Date(data?.ngayThayDoi)).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Nơi cấp</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.noiCapCNDKKD}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Tình trạng</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data.tenTinhTrang}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Lần cấp lại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soLanCapLaiOrSuaDoi}</InputLabel>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
};
export default ThongTinChungGPComponent;
