import {
    Grid,
    InputLabel
} from '@material-ui/core';
import { useEffect, useState } from 'react';

interface chiTietProps {
    ketLuan: string;
    deXuat: string;
}

const ThongTinDeXuat = (props: chiTietProps) => {
    const [ketLuan, setKetLuan] = useState('');
    const [deXuat, setDeXuat] = useState('');
    useEffect(() => {
        setKetLuan(props.ketLuan);  
        setDeXuat(props.deXuat);  
    }, []);

    return (
        <>
            {  
                <Grid container spacing={2}>
                    <Grid item xs={2} className="grid-item" container justify="flex-end">
                            <InputLabel >Kết luận</InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                        <InputLabel className="detail-label">{ketLuan}</InputLabel>
                    </Grid>
                    <Grid item xs={2} className="grid-item" container justify="flex-end">
                            <InputLabel >Đề xuất</InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                        <InputLabel className="detail-label">{deXuat}</InputLabel>
                    </Grid>
             </Grid>
            }
        </>
    )
}
export default ThongTinDeXuat;