import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import { ThongKeTinhHinhCapPhepResponse } from "../../../../api/DKKD/model/ThongKeTinhHinhCapPhepDTO";
import { getObject } from "../../../../helpers/localStorage";
import { count } from "console";

interface IDataListProps {
  data: any;
  detailRequest: any;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  setDetailRequest: Dispatch<SetStateAction<any>>;
}

export const DanhSachThongKeCapPhep = (props: IDataListProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [data, setData] = useState<ThongKeTinhHinhCapPhepResponse>();

  useEffect(() => {
    setData(props.data);
    setRows(props.data?.thongKeTinhHinhCapPhepDTOs);
    console.log(props.data?.thongKeTinhHinhCapPhepDTOs);
  }, [props.data?.thongKeTinhHinhCapPhepDTOs]);

  const handleOnClickChiTietPhuongXa = (row: any, type: string, count: number) => {
    props.setOpenDialog(true);
    props.setDetailRequest({
      ...props.detailRequest,
      tinhTrang: type,
      maPhuongXaTotal: row.maPhuongXa,
      tenPhuongXa: row.tenPhuongXa,
      total: count,
    });
  };

  const handleOnClickChiTietQuanHuyen = (type: string, count: number) => {
    props.setOpenDialog(true);
    props.setDetailRequest({
      ...props.detailRequest,
      tinhTrang: type,
      maPhuongXaTotal: null,
      tenPhuongXa: null,
      total: count,
    });
  };

  return (
    <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <Cell component="th" scope="row" align="center" width="5%">
              STT
            </Cell>
            <Cell component="th" scope="row" align="center">
              Phường/ Xã/ Thị trấn
            </Cell>
            <Cell component="th" scope="row" align="center" width="10%">
              Cấp mới
            </Cell>
            <Cell component="th" scope="row" align="center" width="10%">
              Cấp đổi
            </Cell>
            <Cell component="th" scope="row" align="center" width="10%">
              Cấp lại
            </Cell>
            <Cell component="th" scope="row" align="center" width="10%">
              Tạm ngưng
            </Cell>
            <Cell component="th" scope="row" align="center" width="10%">
              Chấm dứt
            </Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row, i) => (
              <Row key={row?.id}>
                <Cell scope="row" align="center">
                  {i + 1}
                </Cell>
                <Cell align="left">
                  <span>{row.tenPhuongXa}</span>
                </Cell>
                <Cell align="center" className="text-center">
                  <span
                    style={{ cursor: row.capMoi > 0 ? "pointer" : "" }}
                    onClick={() => {
                      if (row.capMoi > 0) {
                        handleOnClickChiTietPhuongXa(row, "CM", row.capMoi);
                      }
                    }}
                  >
                    {row.capMoi}
                  </span>
                </Cell>
                <Cell align="center">
                  <span
                    style={{ cursor: row.capDoi > 0 ? "pointer" : "" }}
                    onClick={() => {
                      if (row.capDoi > 0) {
                        handleOnClickChiTietPhuongXa(row, "DC", row.capDoi);
                      }
                    }}
                  >
                    {row.capDoi}
                  </span>
                </Cell>
                <Cell align="center">
                  <span
                    style={{ cursor: row.capLai > 0 ? "pointer" : "" }}
                    onClick={() => {
                      if (row.capLai > 0) {
                        handleOnClickChiTietPhuongXa(row, "CL", row.capLai);
                      }
                    }}
                  >
                    {row.capLai}
                  </span>
                </Cell>
                <Cell align="center">
                  <span
                    style={{ cursor: row.tamNgung > 0 ? "pointer" : "" }}
                    onClick={() => {
                      if (row.tamNgung > 0) {
                        handleOnClickChiTietPhuongXa(row, "TN", row.tamNgung);
                      }
                    }}
                  >
                    {row.tamNgung}
                  </span>
                </Cell>
                <Cell align="center">
                  <span
                    style={{ cursor: row.chamDut > 0 ? "pointer" : "" }}
                    onClick={() => {
                      if (row.chamDut > 0) {
                        handleOnClickChiTietPhuongXa(row, "CD", row.chamDut);
                      }
                    }}
                  >
                    {row.chamDut}
                  </span>
                </Cell>
              </Row>
            ))
          ) : (
            <Row>
              <Cell colSpan={7} scope="row" align="center">
                Không có dữ liệu
              </Cell>
            </Row>
          )}
          {rows?.length > 0 ? (
            <Row>
              <Cell scope="row" align="center"></Cell>
              <Cell align="left" className="font-weight-bold">
                Tổng số
              </Cell>
              <Cell align="center" className="font-weight-bold text-center">
                <span
                  style={{
                    cursor: data?.capMoi && data?.capMoi > 0 ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (data?.capMoi && data?.capMoi > 0)
                      handleOnClickChiTietQuanHuyen(
                        "CM",
                        data?.capMoi ? data?.capMoi : 0
                      );
                  }}
                >
                  {data?.capMoi}
                </span>
              </Cell>
              <Cell align="center" className="font-weight-bold">
                <span
                  style={{
                    cursor: data?.capDoi && data?.capDoi > 0 ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (data?.capDoi && data?.capDoi > 0)
                      handleOnClickChiTietQuanHuyen(
                        "DC",
                        data?.capDoi ? data?.capDoi : 0
                      );
                  }}
                >
                  {data?.capDoi}
                </span>
              </Cell>
              <Cell align="center" className="font-weight-bold">
                <span
                  style={{
                    cursor: data?.capLai && data?.capLai > 0 ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (data?.capLai && data?.capLai > 0)
                      handleOnClickChiTietQuanHuyen(
                        "CL",
                        data?.capLai ? data?.capLai : 0
                      );
                  }}
                >
                  {data?.capLai}
                </span>
              </Cell>
              <Cell align="center" className="font-weight-bold">
                <span
                  style={{
                    cursor:
                      data?.tamNgung && data?.tamNgung > 0 ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (data?.tamNgung && data?.tamNgung > 0)
                      handleOnClickChiTietQuanHuyen(
                        "TN",
                        data?.tamNgung ? data?.tamNgung : 0
                      );
                  }}
                >
                  {data?.tamNgung}
                </span>
              </Cell>
              <Cell align="center" className="font-weight-bold">
                <span
                  style={{
                    cursor: data?.chamDut && data?.chamDut > 0 ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (data?.chamDut && data?.chamDut > 0)
                      handleOnClickChiTietQuanHuyen(
                        "CD",
                        data?.chamDut ? data?.chamDut : 0
                      );
                  }}
                >
                  {data?.chamDut}
                </span>
              </Cell>
            </Row>
          ) : (
            ""
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
