import { BaseService, Pagination } from "../baseService";
import api from "../index";
import { RequestThongKeDTO } from "../../pages/dashboard/DKKD/DashboardThanhLapHoKinhDoanh";
import { RequestBaoCaoThongKeDTO } from "../../App/Components/DKKD/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import { ThongKeTinhHinhCapPhepResponse } from "../DKKD/model/ThongKeTinhHinhCapPhepDTO";
import { GiayPhepBLThuocLaDTO } from "./model/GiayPhepBLThuocLaDTO";
import { BienDongDTO } from "../DKKD/model/BienDongDTO";
import { ChiTietThongKeCapPhepDTO } from "./model/ChiTietThongKeCapPhepDTO";

export class ThongKeService extends BaseService {
    thongKe = async (request: RequestThongKeDTO): Promise<ThongKeTinhHinhCapPhepResponse> => {
        let result = {} as ThongKeTinhHinhCapPhepResponse;
        await api.post('cptl/thong-ke/thong-ke-tinh-hinh-cap-phep', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data) {
                result = response.data.data;
            }
        }).catch();
        return result;
    }

    chiTietThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<ChiTietThongKeCapPhepDTO[]>> => {
        let result: Pagination<ChiTietThongKeCapPhepDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await api.post('cptl/thong-ke/thong-ke-tinh-hinh-cap-phep/chi-tiet', request, this.getTokenRequestHeaders()).then(response => {
            console.log(response);
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    baoCaoThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<GiayPhepBLThuocLaDTO[]>> => {
        let result: Pagination<GiayPhepBLThuocLaDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await api.post('cptl/thong-ke/bao-cao-thong-ke-giay-phep', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    danhMucBienDong = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<BienDongDTO[]>> => {
        let result: Pagination<BienDongDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await api.post('danh-muc/bien-dong', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    downloadFileBaoCao = (request: RequestBaoCaoThongKeDTO) => {
        return api.post('cptl/thong-ke/export-bao-cao-thong-ke-giay-phep', request, {
            responseType: 'blob'
        });

    }

    downloadFileThongKe = (request: RequestThongKeDTO) => {
        return api.post('cptl/thong-ke/export-thong-ke-tinh-hinh-cap-phep', request, {
            responseType: 'blob'
        });

    }

    thongKeBieuDoTron = (requestBody : RequestThongKeDTO) => {
        return api.post('cptl/thong-ke/bieu-do-tron',requestBody);
    }
}
