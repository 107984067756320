import {
    Button,
    createStyles,
    Grid,
    InputBase,
    InputLabel,
    Link,
    makeStyles,
    NativeSelect,
    SvgIcon,
    TextField,
    Theme,
    Snackbar,
} from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import { BaoCaoThongKeGPRuouRequest } from "../../../../api/CPBLR/model/BaoCaoThongKeGPRuouRequest";
import { BaoCaoThongKeGPRuouRequest_INIT } from "../../../../api/CPBLR/Init/CapMoiSXRuouInit";
import { LIST_TINHTRANG } from "../../../Constants/RuouConstants";
import { ThongKeRuouService } from "../../../../api/CPBLR/ThongKeRuouService";
import { ThongKeGiayPhepRuouDTO } from "../../../../api/CPBLR/model/ThongKeGiayPhepRuouDTO";
import DanhSachThongKeRuouComponent from "./DanhSachThongKeRuouComponent";
import { DanhMucDiaChiDTO } from "../../../../api/danhMuc/model/DanhMucDTO";
import { DanhMucService } from "../../../../api/danhMuc/danhMucService";
import StringUtil from "../../../utils/stringUtils";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { getObject } from "../../../../helpers/localStorage";
import { ChiTietThongKeCapPhep } from "./ChiTietThongKeCapPhep";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px"
        },
    }),
);

const ThongKeRuouComponent = () => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [total, setTotal] = useState(0);
    const [dataList, setDataList] = useState<ThongKeGiayPhepRuouDTO[]>([]);
    const [sum, setSum] = useState<ThongKeGiayPhepRuouDTO>({} as ThongKeGiayPhepRuouDTO);
    const [nams, setNams] = useState<number[]>([]);
    const [searchRequest, setSearchRequest] = useState<BaoCaoThongKeGPRuouRequest>({
        ...BaoCaoThongKeGPRuouRequest_INIT, pageSize: 30, maQuanHuyen: C_USER_DATA.maQuanHuyen,
        tenQuanHuyen: C_USER_DATA.tenQuanHuyen, nam: new Date().getFullYear()
    });
    const thongKeRuouService = new ThongKeRuouService();
    const [phuongTruSos, setPhuongTruSos] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'warning', open: false } as AlertMessage);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    
    useEffect(() => {
        async function getDm() {
            await danhMucService.getDanhMucDiaChi(String(92)).then((resp) => {
                setQuans(resp);
            });
        }
        getDm();
    }, []);
    const loadPhuongs = (maQuan: string) => {
        if (!StringUtil.isEmty(maQuan)) {
            danhMucService.getDanhMucDiaChi(maQuan).then(res => {
                setPhuongTruSos(res);
            }).catch(error => {
                console.log(error);
            });
        }

    }

    const [detailRequest, setDetailRequest] = useState<any>({ ...searchRequest });

    useEffect(() => {
        setDetailRequest({...detailRequest, ...searchRequest});
    }, [searchRequest]);

    useEffect(() => {
        loadDataTable(searchRequest.pageSize, searchRequest.pageNumber);
        loadSum(-1, -1);
        const namNows = [];
        for (var i = new Date().getFullYear() - 5; i <= new Date().getFullYear(); i++) {
            namNows.unshift(i);
        }
        setNams(namNows)
    }, [])

    const loadSum = async (pageSize: number, pageNumber: number) => {
        const searchRequestNew: BaoCaoThongKeGPRuouRequest = { ...searchRequest, pageSize: pageSize, pageNumber: pageNumber };
        dispatchLoading(showLoading());
        await thongKeRuouService.getSum(searchRequestNew).then((p) => {
            setSum(p);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const loadDataTable = async (pageSize: number, pageNumber: number) => {
        const searchRequestNew: BaoCaoThongKeGPRuouRequest = { ...searchRequest, pageSize: pageSize, pageNumber: pageNumber };
        dispatchLoading(showLoading());
        await thongKeRuouService.loadDanhSach(searchRequestNew).then((p) => {
            setDataList(p.rows);
            setTotal(p.total);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const handleSearch = () => {
        if (StringUtil.isEmty(searchRequest?.maQuanHuyen)) {
            showMessageAlert('Vui lòng chọn Huyện/Thị/TP', false);
            return;
        }
        loadDataTable(searchRequest.pageSize, searchRequest.pageNumber);
        loadSum(-1, -1);
    }

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSearchRequest({ ...searchRequest, pageSize: Number(event.target.value), pageNumber: 1 });
        loadDataTable(Number(event.target.value), 1);
    }
    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setSearchRequest({ ...searchRequest, pageNumber: value });
        loadDataTable(searchRequest.pageSize, value);
    };
    const downloadFile = async (extension: string) => {
        dispatchLoading(showLoading());
        const exportRequset = { ...searchRequest, pageNumber: -1, pageSize: -1, exportType: extension, exportFileName: 'Thống kê giấy phép rượu' };
        await thongKeRuouService.exportThongKeGiayPhep(exportRequset).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'Thống kê giấy phép rượu');
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setSearchRequest({
            ...searchRequest,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        });
    }

    const handleChangeChonNam = (e: any) => {
        if (e.target.value == 0) {
            setSearchRequest({
                ...searchRequest,
                tuNgay: 0,
                denNgay: 0,
                [e.target.name]: e.target.value
            });
        } else {
            setSearchRequest({
                ...searchRequest,
                [e.target.name]: e.target.value
            });
        }

    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'warning' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>THỐNG KÊ TÌNH HÌNH CẤP PHÉP KINH DOANH RƯỢU</span>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={4} className="gridItem">
                    <InputLabel >Kỳ báo cáo</InputLabel>
                    <NativeSelect value={searchRequest.ky} name="ky"
                        onChange={handleChange} input={<InputBase fullWidth />} >
                        <option key={'nam'} value={1}>Năm</option>
                        <option key={'khoangThoiGian'} value={4}>Khoảng thời gian</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={4} className="gridItem" hidden={searchRequest.ky != 1}>
                    <InputLabel >Năm</InputLabel>
                    <NativeSelect value={searchRequest.nam} name="nam"
                        onChange={handleChangeChonNam} input={<InputBase fullWidth />} >
                        <option key={''} value={0}>Chọn năm</option>
                        {
                            nams.map((row, i) => (
                                <option key={i} value={row}>{row}</option>
                            ))
                        }
                    </NativeSelect>
                </Grid>
                <Grid item xs={4} className="grid-item" hidden={searchRequest.ky != 4}>
                    <InputLabel >Ngày cấp từ</InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            placeholder="dd/MM/yyyy"
                            value={(searchRequest.tuNgay !== 0 && searchRequest.tuNgay && searchRequest.tuNgay !== null && searchRequest.tuNgay !== undefined) ? new Date(searchRequest.tuNgay) : null}
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => setSearchRequest({
                                ...searchRequest,
                                tuNgay: date?.toDate()?.getTime()
                            })
                            }
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={4} className="grid-item" hidden={searchRequest.ky != 4}>
                    <InputLabel >Ngày cấp đến</InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            placeholder="dd/MM/yyyy"
                            value={(searchRequest.denNgay !== 0 && searchRequest.denNgay && searchRequest.denNgay !== null && searchRequest.denNgay !== undefined) ? new Date(searchRequest.denNgay) : null}
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => setSearchRequest({
                                ...searchRequest,
                                denNgay: date?.toDate()?.getTime()
                            })}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} className="grid-item">
                    <InputLabel >Huyện/Thị/TP</InputLabel>
                    {
                        StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                        <Autocomplete id="maQuanHuyen" options={quans}
                            value={{ code: searchRequest.maQuanHuyen ? searchRequest.maQuanHuyen : "", name: searchRequest.tenQuanHuyen ? searchRequest.tenQuanHuyen : "" }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                setSearchRequest({
                                    ...searchRequest,
                                    maQuanHuyen: newValue.code,
                                    tenQuanHuyen: newValue.name,
                                    maPhuongXa: "",
                                    tenPhuongXa: ""
                                });
                            }}
                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <InputBase fullWidth {...params.inputProps} />
                                </div>
                            }}
                        />
                    }
                    {
                        !StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                        <InputBase disabled fullWidth value={C_USER_DATA.tenQuanHuyen} />
                    }
                </Grid>
                <Grid item xs={4} className="grid-item">
                    <InputLabel >Phường/Xã/TT</InputLabel>
                    <Autocomplete options={phuongTruSos}
                        getOptionLabel={(option) => option?.name}
                        value={{ code: searchRequest.maPhuongXa, name: searchRequest.tenPhuongXa }}
                        onFocus={(e) => loadPhuongs(searchRequest.maQuanHuyen)}
                        onChange={(event: any, newValue: any) => {
                            if (newValue) {
                                setSearchRequest({
                                    ...searchRequest,
                                    maPhuongXa: newValue.code,
                                    tenPhuongXa: newValue.name
                                });
                            } else {
                                setSearchRequest({
                                    ...searchRequest,
                                    maPhuongXa: '',
                                    tenPhuongXa: ''
                                });
                            }
                        }}

                        renderInput={(params) => {
                            return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                <TextField  {...params} variant="outlined" />
                            </div>
                        }}
                    />
                </Grid>
                <Grid item xs={4} className="gridItem" hidden={searchRequest.ky != 1}>
                    <InputLabel >Tình trạng</InputLabel>
                    <NativeSelect value={searchRequest.maTinhTrang} name="maTinhTrang"
                        onChange={handleChange} input={<InputBase fullWidth />} >
                        <option key={''} value={''}>Chọn tình trạng</option>
                        {
                            LIST_TINHTRANG.map((row, i) => (
                                <option key={i} value={row.ma}>{row.ten}</option>
                            ))
                        }
                    </NativeSelect>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} className="grid-item" container justify="flex-end">
                    <Button onClick={handleSearch} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                        Tìm kiếm
                    </Button>
                </Grid>
                <Grid item xs={12} className="grid-item" container justify="flex-end">
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('docx');
                    }}>
                        <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                        </SvgIcon>
                    </Link>
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('pdf');
                    }}>
                        <SvgIcon fontSize="large">
                            <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                        </SvgIcon>
                    </Link>
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('xlsx');
                    }}>
                        <SvgIcon fontSize="large">
                            <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                        </SvgIcon>
                    </Link>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <DanhSachThongKeRuouComponent 
                        dataList={dataList}
                        pageProps={{
                            page: searchRequest.pageNumber, count: total, rowsPerPage: searchRequest.pageSize,
                            sum: sum,
                            onChangePage: handleOnChangePage,
                            onChangeRowsPerPage: handOnChangeRowsPerPage
                        }}
                        detailRequest={detailRequest}
                        setDetailRequest={setDetailRequest}
                        setOpenDialog={setOpenDialog} 
                    />
                </Grid>
            </Grid>
            {openDialog && (
                <ChiTietThongKeCapPhep
                    request={detailRequest}
                    openDialog={openDialog}
                    onDialogClose={() => {
                        setOpenDialog(false);
                    }}
                />
            )}
            <OverlayLoading />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div >
    )
}
export default ThongKeRuouComponent;

