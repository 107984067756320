import { BaseService, Pagination } from "../baseService";
import { GiayPhepSXRuou } from "./model/HoSoThuLyBLR";
import AXIOS_INSTANCE from "..";
import { BaoCaoThongKeGPRuouRequest } from "./model/BaoCaoThongKeGPRuouRequest";

export class BaoCaoRuouService extends BaseService {

    loadDanhSach = async (request: BaoCaoThongKeGPRuouRequest): Promise<Pagination<GiayPhepSXRuou[]>> => {
        let result: Pagination<GiayPhepSXRuou[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('ruou/bao-cao-giay-phep/search', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }
    exportBaoCaoGiayPhep =  (request: BaoCaoThongKeGPRuouRequest)=> {
        return AXIOS_INSTANCE.get('ruou/bao-cao-giay-phep/export', {
            responseType: 'blob',
            params: request
        });

    }
}
