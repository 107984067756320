import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Button, NativeSelect } from '@material-ui/core';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { ChiTietHangMucDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import CloseIcon from '@material-ui/icons/Close';
import { ChiTietHangMucDTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import ChiTietHangMucModal, { ChiTietHangMucModalDataProps } from '../Modal/ChiTietHangMucModal';

interface ChiTietHangMucTableProps {
    data: ChiTietHangMucDTO[];
    handleAdd: (rowNew: ChiTietHangMucDTO) => void;
    handleDrop: (index: number) => void;
    handleUpdate: (index: number, rowNew: ChiTietHangMucDTO) => void;
}
const ChiTietHangMucTable = (props: ChiTietHangMucTableProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<ChiTietHangMucDTO[]>(new Array<ChiTietHangMucDTO>());
    const [dataEdit, setDataEdit] = useState<ChiTietHangMucModalDataProps>({ data: { ...ChiTietHangMucDTO_INIT }, show: false, indexEdit: -1 });
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = ((pageNumberNew - 1) * pageSizeNew);
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    }
    const handleToggle = () => {
        setDataEdit({ ...dataEdit, show: !dataEdit.show });
    }
    const handleEditToggle = (index: number) => {
        setDataEdit({ ...dataEdit, data: props.data[index], show: !dataEdit.show, indexEdit: index });
    }
    const handleAddToggle = () => {
        setDataEdit({ data: { ...ChiTietHangMucDTO_INIT }, show: !dataEdit.show, indexEdit: -1 });

    }
    return (
        <div>
            <table className="table table-bordered" style={{ fontSize: '14px' }}>
                <thead>
                    <tr className="table-secondary">
                        <th style={{ width: "5%" }}>STT</th>
                        <th>Chi tiết </th>
                        <th>Chiều cao (m)</th>
                        <th>Công thức</th>
                        <th>Diện tích</th>
                        <th style={{ width: "5%" }} className="text-center">Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {dataPagi?.length > 0 ? dataPagi?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="cell-number">{((pageNumber - 1) * pageSize) + index + 1}</td>
                                <td>{item.chiTiet}</td>
                                <td>{item.chieuCao}</td>
                                <td>{item.congThuc}</td>
                                <td>{item.dienTich}</td>
                                <td align="center">
                                    <Button onClick={(e) => props.handleDrop(((pageNumber - 1) * pageSize) + index)} startIcon={<CloseIcon color="secondary" />} size="small" disableRipple />
                                </td>
                            </tr>
                        )
                    }) :
                        <tr>
                            <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                        </tr>
                    }
                </tbody>
            </table>

            <div className="row">
                {/* pagination*/}
                <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                    <Pagination count={Math.ceil(props.data?.length / pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleChangePage} />
                    <div>
                        <span> Số mẫu tin trên trang </span>
                        <NativeSelect value={pageSize} input={<CustomInput />} onChange={handleChangePageSize} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                        <span>Trang {pageNumber}  / {props.data?.length === 0 ? 1 : Math.ceil(props.data?.length / pageSize)}</span>
                    </div>
                </div>
                <div className="col-md-2 text-right" style={{ fontSize: '14px' }}>
                    <Button onClick={handleAddToggle} variant="contained" color="primary" disableElevation>Thêm mới</Button>
                    <ChiTietHangMucModal data={dataEdit}
                        onAddData={props.handleAdd}
                        onUpdateData={props.handleUpdate} 
                        onToggle ={handleToggle}/>
                </div>

            </div>
        </div>
    )
}
export default ChiTietHangMucTable;