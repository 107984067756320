import React from "react";
import { Box, Grid, InputLabel } from "@material-ui/core";
import  { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import Button from "@material-ui/core/Button";
import { PreviewDropZone } from "../LayoutDropZone/PreviewDropZone";
import LayoutDrop from "../LayoutDropZone/LayoutDrop";
import { FileEntry } from "../../../../api/Common/fileEntry";
import PreviewDropzoneFromServer from "./PreviewFileFromServer";
import { getAll, getCookie } from "../../../../helpers/cookie";
//import Dropzone  from "react-dropzone-uploader";
import Dropzone from "./plugin/dist/Dropzone";

interface IDropZoneCustom {
    acceptFiles: String;
    attachFileServer: FileEntry[];
    url: String;
    handleChangeStatus: (file: IFileWithMeta) => void;
    handleDeleteFileServer: (file: FileEntry, index: number) => void;
    handleDownloadFile: (file: FileEntry) => void;
}

const DropZoneCustom: React.FC<IDropZoneCustom> = ({ acceptFiles, attachFileServer, url, handleDeleteFileServer, handleChangeStatus, handleDownloadFile }) => {

    return (
        <>
            <Grid item xs={2}>
                <InputLabel>Đính kèm</InputLabel>
            </Grid>
            <Grid item xs={10}>
                {attachFileServer && (
                    <label htmlFor="dzu-input">
                        <Box width="456px">
                            <PreviewDropzoneFromServer files={attachFileServer} onDelete={handleDeleteFileServer} onDownload={handleDownloadFile} />
                        </Box>
                    </label>
                )}
                <Box width="456px">
                    <Dropzone
                        accept={`${acceptFiles}`}
                        inputContent={
                            <Button variant="outlined" size="small" color="default" component="span" disableElevation style={{ marginRight: "10px" }}>
                                Chọn tập tin
                            </Button>
                        }
                        inputWithFilesContent={
                            <Button variant="outlined" size="small" color="default" component="span" disableElevation style={{ marginRight: "10px" }}>
                                Chọn tập tin
                            </Button>
                        }
                        multiple={true}

                        // getUploadParams={() => ({ url: `${url}` })}
                        getUploadParams={(file: IFileWithMeta) => {
                            const formData = new FormData();
                            let xhr : XMLHttpRequest = new XMLHttpRequest();
                            xhr.withCredentials = true;
                            file.xhr = xhr;
                            formData.append("file", file.file);
                            let headers: any = {
                                'csrf-token': localStorage.getItem("csrf-token"),
                            }
                            return {
                               // withCredentials: true,
                                url: `${url}`,
                                headers: headers,
                                body: formData,
                                withCredentials: true
                            };
                        }}
                        onChangeStatus={handleChangeStatus}
                        //InputComponent={(props: any) => <InputCustom acceptFiles={`${acceptFiles}`} {...props} />}
                        LayoutComponent={(props: any) => <LayoutDrop {...props} acceptFiles={acceptFiles} />}
                        PreviewComponent={(props: any) => <PreviewDropZone {...props} />}
                    />
                </Box>
            </Grid>
        </>
    );
};

export default DropZoneCustom;
