import {
    Box, NativeSelect, Table, TableBody, TableContainer, TableHead,
    TableRow, Link,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { Cell, Row } from "../../../../commons/components/table/tableCommons";
import { CustomInput } from "../../../../commons/components/input/customInput";
import moment from "moment";
import { FileEntry } from "../../../../../api/Common/fileEntry";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { FileServerService } from '../../../../../api/Common/FileServerService';
import StringUtil from '../../../../utils/stringUtils';

interface IDataListProps {
    dataList: FileEntry[];
}

export const TapDinhKem = (props: IDataListProps) => {

    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataTepms, setDataTepms] = useState<FileEntry[]>([]);
    const fileServerService = new FileServerService();
    useEffect(() => {
        setDataTepms(props.dataList);
        setTotal(props.dataList?.length);
        handleSearch({ page: 1, size: size });
    }, [props.dataList, total, size]);

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        handleSearch({ page: value, size: size });
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1)
        setSize(Number(event.target.value));
        handleSearch({ page: 1, size: Number(event.target.value) });
    }

    const handleSearch = (search: any) => {
        setRows(dataTepms?.slice((search.page - 1) * search.size, search.page * search.size));
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && StringUtil.isNotEmpty(file.url)){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="30%">Tên tập tin</Cell>
                        <Cell component="th" scope="row" align="center" width="25%">Người đính kèm</Cell>
                        <Cell component="th" scope="row" align="center" width="20%">Tập tin</Cell>
                        <Cell component="th" scope="row" align="center" width="20%">Ngày đính kèm</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {i + 1}
                                    </Cell>
                                    <Cell align="left">{row.name}</Cell>
                                    <Cell align="left">{row.userName}</Cell>
                                    <Cell align="center">
                                        {<Link title="Tải xuống" href="#" onClick={() => {
                                            handleDownloadFile(row);
                                        }}>
                                            <AttachFileIcon />
                                        </Link>}
                                    </Cell>
                                    <Cell align="center">{(row.createdDate !== 0 && row.createdDate !== null) ? moment(row.createdDate).format("DD/MM/YYYY") : ''}</Cell>
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={5} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            {rows?.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={Math.ceil(total / size)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {page * size} - {page * size > total ? total : (page * size)} / {total}</Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
