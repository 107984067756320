import { RoutePropsI } from ".";
import BaoCaoThongKeTinhHinhCapPhep from "../App/Components/ATTP/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import { BieuDoThongKeATTP } from "../App/Components/ATTP/BieuDoTK/BieuDoThongKeATTP";
import { BieuDoTronThongKeATTP } from "../App/Components/ATTP/BieuDoTron/BieuDoTronATTP";
import CapGiayPhepATTPComponent from "../App/Components/ATTP/CapGiayATTP/CapGiayPhepATTPComponent";
import { ThongKeTinhHinhCapPhep } from "../App/Components/ATTP/ThongKe/ThongKeTinhHinhCapPhep";
import HoanTraThuHoiGPVSATTPComponent from "../App/Components/ATTP/ThuLyHoSo/BienDongTrangThaiGP/HoanTraThuHoiGPVSATTPComponent";
import HoanTraTNGPVSATTPComponent from "../App/Components/ATTP/ThuLyHoSo/BienDongTrangThaiGP/HoanTraTNGPVSATTPComponent";
import TamNgungGPVSATTPComponent from "../App/Components/ATTP/ThuLyHoSo/BienDongTrangThaiGP/TamNgungGPVSATTPComponent";
import ThuHoiVSATTPComponent from "../App/Components/ATTP/ThuLyHoSo/BienDongTrangThaiGP/ThuHoiGPVSATTPComponent";
import DauKyVSATTPComponent from "../App/Components/ATTP/ThuLyHoSo/DauKy/DauKyVSATTPComponent";
import ChiTietGiayPhepATTPComponent from "../App/Components/ATTP/TraCuu/ChiTiet/ChiTietGiayPhepATTP";
import QuanLyGiayPhepATTP from "../App/Components/ATTP/TraCuu/quanLyGiayPhepATTP";


export const ROUTE_ATTP_DAUKY = {
    ten: 'NHẬP ĐẦU KỲ GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM',
    giaoDien: DauKyVSATTPComponent,
    duongDan: '/ve-sinh-an-toan-thuc-pham/dau-ky',
}
export const ROUTE_ATTP_THUHOI = {
    ten: 'Thu hồi',
    giaoDien: ThuHoiVSATTPComponent,
    duongDan: '/ve-sinh-an-toan-thuc-pham/thu-hoi',
}
export const ROUTE_ATTP_HOANTRA_THUHOI = {
    ten: 'Hoàn trả thu hồi',
    giaoDien: HoanTraThuHoiGPVSATTPComponent,
    duongDan: '/ve-sinh-an-toan-thuc-pham/hoan-tra-thu-hoi',
}
export const ROUTE_ATTP_CHITIETGIAYPHEP = {
    ten: 'Chi tiết ATTP',
    giaoDien: ChiTietGiayPhepATTPComponent,
    duongDan: '/attp/chi-tiet',
}
export const ROUTE_ATTP_TAMNGUNG = {
    ten: 'Tạm ngưng',
    giaoDien: TamNgungGPVSATTPComponent,
    duongDan: '/ve-sinh-an-toan-thuc-pham/tam-ngung',
}
export const ROUTE_ATTP_HOANTRA_TAMNGUNG = {
    ten: 'Hoàn trả',
    giaoDien: HoanTraTNGPVSATTPComponent,
    duongDan: '/ve-sinh-an-toan-thuc-pham/hoan-tra-tam-ngung',
}



export const ROUTE_CN_ATTP: RoutePropsI[] = [
    {
        ten: 'CẤP GIẤY CHỨNG NHẬN CƠ SỞ ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM ĐỐI VỚI CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG',
        giaoDien: CapGiayPhepATTPComponent,
        duongDan: '/cap-giay-phep-atvstp',
    }
];
export const ROUTE_PAGE_ATTP: RoutePropsI[] = [
    {
        ten: 'TRA CỨU THÔNG TIN GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM',
        giaoDien: QuanLyGiayPhepATTP,
        duongDan: '/tra-cuu-thong-tin-dk-attp',
    },
    {
        ten: 'BÁO CÁO TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN VỆ SINH AN TOÀN THỰC PHẨM',
        giaoDien: BaoCaoThongKeTinhHinhCapPhep,
        duongDan: '/bao-cao-thong-ke',
    },
    {
        ten: 'THỐNG KÊ TÌNH HÌNH CẤP PHÉP VỆ SINH AN TOÀN THỰC PHẨM',
        giaoDien: ThongKeTinhHinhCapPhep,
        duongDan: '/thong-ke-vsattp',
    },
    {
        ten: 'BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN VSATTP',
        giaoDien: BieuDoThongKeATTP,
        duongDan: '/an-toan-thuc-pham/bieu-do-thong-ke',
    },
    {
        ten: 'BIỂU ĐỒ TRÒN THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN VSATTP',
        giaoDien: BieuDoTronThongKeATTP,
        duongDan: '/an-toan-thuc-pham/bieu-do-tron-thong-ke',
    }
];

export const ROUTE_DS_ATTP: RoutePropsI[] = [
    {
        ten: 'TRA CỨU THÔNG TIN GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM',
        giaoDien: QuanLyGiayPhepATTP,
        duongDan: '/tra-cuu-thong-tin-dk-attp',
    },
    {
        ten: 'BÁO CÁO TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN VỆ SINH AN TOÀN THỰC PHẨM',
        giaoDien: BaoCaoThongKeTinhHinhCapPhep,
        duongDan: '/bao-cao-thong-ke',
    },
    {
        ten: 'THỐNG KÊ TÌNH HÌNH CẤP PHÉP VỆ SINH AN TOÀN THỰC PHẨM',
        giaoDien: ThongKeTinhHinhCapPhep,
        duongDan: '/thong-ke-vsattp',
    },
];

export const ROUTE_GUEST_ATTP: RoutePropsI[] = [
    ROUTE_ATTP_DAUKY,
    ROUTE_ATTP_CHITIETGIAYPHEP,
    ROUTE_ATTP_THUHOI,
    ROUTE_ATTP_HOANTRA_THUHOI,
    ROUTE_ATTP_TAMNGUNG,
    ROUTE_ATTP_HOANTRA_TAMNGUNG
];

export const ROUTE_PAGE_PUBLIC_ATTP: RoutePropsI[] = [
    {
        ten: 'BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN VSATTP',
        giaoDien: <BieuDoThongKeATTP />,
        duongDan: '/an-toan-thuc-pham/bieu-do-thong-ke',
    }
];




