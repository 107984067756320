import { Box, Button, Grid, InputBase, InputLabel, NativeSelect, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { DanhSachKhenThuongRequest, DanhSachKhenThuongService } from "../../../../../../api/KhenThuong/chuyenNganh/danhSachKhenThuongService";
import { Type } from "../../../../../../api/KhenThuong/danhMuc/danhMucKhenThuong";
import { KhenThuongCaNhanDTO, LoaiHinhKhenThuong, LoaiKhenThuong } from "../../../../../../api/KhenThuong/models/khenThuongDTO";
import { useDanhMucKhenThuong } from "../../../../../../hooks/useDanhMucKhenThuong";
import { useQuerySoBienNhan } from "../../../../../../hooks/useQuery";
import ButtonExt from "../../../../../commons/components/button/customButton";
import { ConfirmationDialogRaw } from "../../../../../commons/components/dialog/confirmDialog";
import { CustomNativeSelect } from "../../../../../commons/components/input/CustomNativeSelect";
import { TypeCell } from "../../../../../commons/components/table/commonTableChuyenNganh";
import { Column, CommonTableChuyenNganhLazy, Pageable } from "../../../../../commons/components/table/commonTableChuyenNganhLazy";
import { DANH_SACH_TEN_NGHIEP_VU } from "../../../../../Constants/constants";
import { initDefaultValue, KhenThuongCaNhanWrapperPropsI } from "./khenThuongCaNhanWrapper";
import { useLoaiHinhKhenThuong } from "../../../../../../hooks/useLoaiHinhKhenThuong";

interface FormMainPropsI extends KhenThuongCaNhanWrapperPropsI {
    onEditData: (data: KhenThuongCaNhanDTO) => void;
    showMessageAfterDelete: (content: string, isSuccess: boolean) => void;
}
export const FormMain = (props: FormMainPropsI) => {
    const soBienNhan = useQuerySoBienNhan();
    const dmLoaiHinh = useLoaiHinhKhenThuong(true);
    const dmHinhThuc = useDanhMucKhenThuong(Type.HINH_THUC);
    const dmDanhHieu = useDanhMucKhenThuong(Type.DANH_HIEU);

    const { onEditData, showMessageAfterDelete } = props;

    const [rows, setRows] = useState<KhenThuongCaNhanDTO[]>([]);
    const [itemSelected, setItemSelected] = useState<KhenThuongCaNhanDTO | null>(null);
    const [pageParams, setPageParams] = useState({ total: 0, page: 0, rowsPerPage: 5 } as Pageable);
    const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = useState(false);
    const [openImportDataConfirmDlg, setOpenImportDataConfirmDlg] = useState(false);
    const btnUploadRef = useRef<HTMLSpanElement>(null);

    const loadDataTable = async () => {
        let request: DanhSachKhenThuongRequest = {
            pageNumber: pageParams.page,
            pageSize: pageParams.rowsPerPage,
            type: LoaiHinhKhenThuong.CA_NHAN,
            soBienNhan: soBienNhan,
            thuTucId: DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_3.ID,
            ...formik.values.request,
        };
        await new DanhSachKhenThuongService().getCaNhanKhenThuong(request).then((p) => {
            setRows(p.rows ? p.rows : []);
            setPageParams((pageParams) => ({ ...pageParams, total: p.total }));
        });
    };

    const formik = useFormik({
        initialValues: {
            request: {} as KhenThuongCaNhanDTO,
        },
        onSubmit: handleSearch,
    });

    const columns: Column<KhenThuongCaNhanDTO>[] = [
        { title: "loại hình khen thưởng", field: "loaiHinhKhenThuongString", width: "5%" },
        { title: "Họ và tên", field: "hoTen", width: "15%" },
        { title: "Chức vụ", field: "chucVu", width: "10%" },
        { title: "Quê quán", field: "queQuan", width: "10%" },
        { title: "Hình thức khen thưởng", field: "hinhThucKhenThuongString", width: "10%" },
        { title: "Danh hiệu thi đua", field: "danhHieuThiDuaString", width: "10%" },
        { title: "Số tiền", field: "soTien", width: "10%", type: TypeCell.CURRENCY },
        { title: "Thành tích khen thưởng", field: "thanhTichKhenThuong", width: "10%" },
        { title: "Tóm tắt thành tích", field: "tomTatThanhTich", width: "10%" },
    ];

    useEffect(() => {
        loadDataTable();
    }, [pageParams.page, pageParams.rowsPerPage]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleOnChangePage = (value: number) => {
        setPageParams((pageParams) => ({ ...pageParams, page: value }));
    };

    const handOnChangeRowsPerPage = (value: number) => {
        setPageParams((pageParams) => ({ ...pageParams, page: 0, rowsPerPage: value }));
    };

    function handleSearch() {
        loadDataTable();
    }

    function handleDeleteData(data: KhenThuongCaNhanDTO) {
        if (data.id) {
            setItemSelected(data);
            setOpenDeleteConfirmDlg(true);
        }
    }

    const handleCloseDeleteConfirmDlg = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (itemSelected && itemSelected.id) {
                await new DanhSachKhenThuongService().deleteById(itemSelected.id).then((resp) => {
                    if (resp.isSuccess) {
                        loadDataTable();
                        showMessageAfterDelete(resp.message, true);
                    } else {
                        showMessageAfterDelete(resp.message, false);
                    }
                });
            }
        }
        setOpenDeleteConfirmDlg(false);
    };

    const handleCloseImportDataConfirmDlg = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (btnUploadRef.current) btnUploadRef.current.click();
        }
        setOpenImportDataConfirmDlg(false);
    };

    function handleClickAdd() {
        onEditData({
            ...initDefaultValue,
            soBienNhan: soBienNhan,
        });
    }

    function handleImportFileData(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            new DanhSachKhenThuongService().importDataCaNhan(soBienNhan, DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_3.ID, e.target.files[0]).then((resp) => {
                if (resp.isSuccess) {
                    loadDataTable();
                    showMessageAfterDelete(resp.message, true);
                } else {
                    showMessageAfterDelete(resp.message, false);
                }
            });
        }
    }

    function handleDownloadTemplateFile() {
        new DanhSachKhenThuongService()
            .downloadFileTemplateCaNhan(DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_5.ID)
            .then((response) => {
                let blob = new Blob([response.data], { type: response.data.type });
                let url = window.URL.createObjectURL(blob);
                let link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Template_import_canhan.xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                showMessageAfterDelete("Có lỗi khi thực hiện tải file template", false);
            });
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Loại hình khen thưởng</InputLabel>
                        <NativeSelect name="request.loaiHinhKhenThuongId" value={formik.values.request.loaiHinhKhenThuongId} onChange={formik.handleChange} input={<InputBase fullWidth />}>
                            <option value={""}>Chọn loại hình khen thưởng</option>
                            {dmLoaiHinh &&
                                dmLoaiHinh.map((item, i) => (
                                    <option key={i} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}

                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Họ và tên</InputLabel>
                        <InputBase
                            fullWidth
                            name="request.hoTen"
                            value={formik.values.request.hoTen}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel>Quê quán</InputLabel>
                        <InputBase
                            fullWidth
                            name="request.queQuan"
                            value={formik.values.request.queQuan}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel >Hình thức khen thưởng</InputLabel>
                        <NativeSelect
                            name="request.hinhThucKhenThuongId"
                            value={formik.values.request.hinhThucKhenThuongId}
                            onChange={formik.handleChange}
                            input={<InputBase fullWidth />}
                        >
                            <option value="">Chọn hình thức khen thưởng</option>
                            {dmHinhThuc &&
                                dmHinhThuc.map((item, i) => (
                                    <option key={i} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel >Danh hiệu thi đua</InputLabel>
                        <NativeSelect
                            name="request.danhHieuThiDuaId"
                            value={formik.values.request.danhHieuThiDuaId}
                            onChange={formik.handleChange}
                            input={<InputBase fullWidth />}
                        >
                            <option value="">Chọn danh hiệu thi đua</option>
                            {dmDanhHieu &&
                                dmDanhHieu.map((item, i) => (
                                    <option key={i} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={12} className="grid-item ">
                        <Box className="button-group-center" marginTop="5px">
                            <Button type="submit" variant="contained" color="primary" disableElevation>
                                Tìm kiếm
                            </Button>
                            <ButtonExt variant="contained" color="warning" disableElevation onClick={handleClickAdd}>
                                Thêm mới
                            </ButtonExt>
                            <Button variant="contained" color="primary" disableElevation onClick={handleDownloadTemplateFile}>
                                Tải mẫu
                            </Button>
                            <Button variant="contained" color="primary" disableElevation onClick={() => setOpenImportDataConfirmDlg(true)}>
                                Import
                            </Button>
                            <Box hidden>
                                <input
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={handleImportFileData}
                                    id="import-button-file-ktcn"
                                    type="file"
                                />
                                <label htmlFor="import-button-file-ktcn">
                                    <Button variant="contained" color="primary" component="span" ref={btnUploadRef} />
                                </label>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <Grid container>
                <Grid item xs={12} className="grid-item ">
                    <CommonTableChuyenNganhLazy
                        columns={columns}
                        rows={rows}
                        onEdit={onEditData}
                        onDelete={handleDeleteData}
                        pageParams={pageParams}
                        onChangePage={handleOnChangePage}
                        onChangeRowsPerPage={handOnChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
            <ConfirmationDialogRaw
                id="confirm-dialog-delete-ktcn"
                keepMounted
                open={openDeleteConfirmDlg}
                title="Xóa khen thưởng cá nhân"
                content="Bạn có đồng ý thực hiện hành động này?"
                onClose={handleCloseDeleteConfirmDlg}
            />

            <ConfirmationDialogRaw
                id="confirm-dialog-import-ktcn"
                keepMounted
                open={openImportDataConfirmDlg}
                title="Import dữ liệu khen thưởng cá nhân"
                content="Thực hiện import, sẽ thay thế cho tất cả dữ liệu hiện tại. Bạn có đồng ý không?"
                onClose={handleCloseImportDataConfirmDlg}
            />
        </>
    );
};
