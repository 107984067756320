import { StringLiteral } from "typescript";
import { FileEntry } from "../../Common/fileEntry";
import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { SearchRequest } from "../../Common/model/CommonModel";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";
export interface ChiTietHangMucDTO {
    id: number;
    hangMucId: number;
    chiTiet: string;
    chieuCao: Number | null;
    congThuc: string;
    dienTich: Number | null;
    hoatDong: boolean;
}
export interface ChiTietKhuonVienDTO {
    id: number;
    chiTietId: number;
    ghiChu: string;
    ma: string;
    dienTich: Number | null;
}
export interface HangMucXayDungDTO {
    id: number;
    giayPhepXayDungId: number;
    hangMuc: string;
    mauSac: string;
    congTrinhChinh: boolean;
    soTang: string;
    ketCau: string;
    dienTichTangMot: Number | null;
    tongDienTichSan: Number | null;
    chieuCaoCongTrinh: Number | null;
    chieuSauCongTrinh: Number | null;
    matDatXayDung: Number | null;
    heSoSuDungDat: string;
    ghiChu: string;
    chiTietHangMucDTOs: ChiTietHangMucDTO[];
    hoatDong: boolean;
    chieuCaoTangMot : Number | null;
    congThuc: string;
}

export interface DienTichKhuonVienDTO {
    id: number;
    giayPhepXayDungId: number;
    ghiChu: string;
    chiTietKhuonVienDTOs: ChiTietKhuonVienDTO[];
}

export interface GiayPhepXayDungDaCapDTO {
    id: number;
    giayPhepXayDungId: number;
    ngayCapNhat: number;
    ngayTao: number;
    maLoaiGiayPhep: string;
    tenLoaiGiayPhep: string;
    maThuTuc: string;
    maHoSo: string;
    soGiayPhep: string;
    ngayCapPhep: number;
    ngayHetHan: number;
    ghiChu: string;
    nguoiKy: string;
    mienGiayPhepXayDung: boolean;
    tenTrangThai: string;
    maTrangThai: string;
    tenDonViCapPhep: string;
    maDonViCapPhep: string;
    donViTuVan: string;
    

    chuDauTu: string;
    viTriXayDung: string;
    diaDiemHienTaiDayDu: string;
    diaChiDayDu: string;
    giayPhepXayDungDTO: GiayPhepXayDungDTO;
    hoatDong: boolean;
    lichSuGiayPhepDTOs : LichSuGiayPhepDTO[];
    dienTichXayDung: string;
    tongDienTichSan: string;
}
export interface ChuDauTuModel {
    id: number;
    giayPhepXayDungId: number;
    loaiDoiTuong: number;
    danhXung: string;
    hoTen: string;
    diaChiChiTiet: string;
    maGioiTinh: string;
    tenGioiTinh: string;
    duong: string;
    loaiGiayTo: number;
    tenLoaiGiayTo: string;
    tenTinh: string;
    maTinh: string;
    tenHuyen: string;
    maHuyen: string;
    tenPhuong: string;
    maPhuong: string;
    diaChiCu: string;
    soGiayTo: string;
    ngayCap: number;
    ngaySinh: number;
    tenNoiCap: string;
    maNoiCap: string;
    hoatDong: boolean;
    thongTinTimKiem: string;
    tenToChuc: string;
    loaiToChuc: string;
    nguoiDaiDien: string;
    diaChiDoanhNghiep: string;
    checked: number;
    chonTuKho: number;
}
export interface GiayToQuyenSuDungDatDTO {
    id: number;
    giayPhepXayDungId: Number | null;
    dienTichKhuonVien: Number | null;
    dienTichKhuonVienHienHuu:Number | null;
    dienTichBanCong: Number | null;
    dienTichChenhLech: Number | null;
    dienTichGiuLai: Number | null;
    matDoGiayPhep: Number | null;
    cotNen: string;
    lyDo: string;
    hoatDong: boolean;
}
export interface ThongTinGiaHanGiayPhepXayDungDTO {
    id: number;
    giayPhepXayDungId: number;
    soQuyetDinh: string;
    ngayQuyetDinh: number;
    ngayGiaHan: number;
    ngayGiaHanDen: number;
    ghiChu: string;
    hoatDong: boolean;
   
}
export interface GiayPhepChoCapSoDTO {
    giayPhepXayDungId: number;
    giayPhepXayDungDaCapId: number;
    soGiayPhep: string;
    maHoSo: string;
    chuDuTu: string;
    diaChi: string;
    tenLoaiGiayPhep: string;
    loaiGiayPhep: string;
    tenCongTrinh: string;
    maTrangThai: string;
    tenTrangThai: string;
    ngayCapPhep: number;
    ngayHetHan: number;
    ghiChu: string;
    nguoiKy: string;
    mienGiayPhepXayDung: boolean;
    soGiayPhepCu: string;
    files: FileEntry[];
    ketQuaGiayPhep?: KetQuaGiayPhepDTO;
    loaiKetQua: LoaiKetQuaEnum;
}
export interface LoDatXayDungDTO {
    id: number;
    giayPhepXayDungId: number;
    diaDiemHienTaiId: number;
    soTo: string;
    soThua: string;
    motNua: boolean;
    hoatDong: boolean;
}

export interface GiayPhepXayDungDTO {
    id: number;
    maThuTuc: string;
    hoSoId: number;
    giayPhepXayDungDaCapId: number;
    maHoSo: string;
    tenCongTrinh: string;
    maTinh: string;
    tenTinh: string;
    maHuyen: string;
    tenHuyen: string;
    maPhuong: string;
    tenPhuong: string;
    tenDuong: string;
    maDuong: string;
    soNha: string;
    diaChiCu: string;
    ranhGioiDiDoiDen: string;
    diaChiDayDu: string;

    maCapCongTrinh: string;
    tenCapLoaiCongTrinh: string;
    maLoaiCongTrinh: string;
    tenLoaiCongTrinh: string;
    maPhanLoaiCongTrinh: string;
    tenPhanLoaiCongTrinh: string;

    donViTuVanId: number;
    donViTuVan: string;
    donViKiemTraId: number;
    donViKiemTra: string;
    soKyHieuBangVeId: number;
    soKyHieuBangVe: string;
    ngayKiemTraBanVe: number;


    chuTriThietKe: string;
    donViThamDinh: string;
    chuTriThamTra: string;
    duKienHoanThanh: string;
    chiGioiDuongDo: string;
    chiGioiXayDung: string;
    thoiHanCongTrinh: string;
    ghiChu: string;
    mienPhiXayDung: false;
    giayToVeDat: string;
    ketLuan: string;
    deXuat: string;
    quyHoach: string;

    tenLoaiGiayPhep: string;
    maLoaiGiayPhep: string;
    tenLoaiBienDong: string;
    maLoaiBienDong: string;
    tenTrangThai: string;
    maTrangThai: string;

    soGiayPhep: string;
    soGiayPhepCu: string;
    //Check sau date
    ngayCapPhepCu: number;
    ngayHetHan: number;
    nguoiKy: string;
    hoatDong: boolean;

    giayToQuyenSuDungDat: GiayToQuyenSuDungDatDTO;
    xacMinhThucDiaDTO: XacMinhThucDiaDTO;
    thongTinSuaChuaDTO: ThongTinSuaChuaDTO;
    diaDiemHienTaiDTO: DiaDiemHienTaiDTO;
    diaDiemDenDTO: DiaDiemHienTaiDTO;
    loDatXayDungDTOs: LoDatXayDungDTO[];
    hangMucXayDungDTOs: HangMucXayDungDTO[];
    dienTichKhuonVienDTOs: DienTichKhuonVienDTO[];
    chuDauTuDTOs: ChuDauTuModel[];
    thongTinThamDinhDTOs: ThongTinThamDinhDTO[];
    thongTinGiaHanGiayPhepXayDungDTO: ThongTinGiaHanGiayPhepXayDungDTO;
    files: FileEntry[];

}
export interface ThongTinThamDinhDTO {
    id: number;
    giayPhepXayDungId: number;
    noiDung: string;
    files: FileEntry[];
    hoatDong: boolean;
}


export interface GiayPhepXayDungChoCapSoRequest {
    maQuan : string;
    maHoSo: string;
    chuDauTu: string;
    tenCongTrinh: string;
    diaChiCongTrinh: string;
    pageSize: number;
    pageNumber: number;
}
export interface XacMinhThucDiaDTO {
    id: number,
    giayPhepXayDungId: number,
    ngayNop: number;
    hoTen: string;
    ngayYeuCau: number;
    diaChiThuongTru: string;
    diaChiDangKy: string;
    veViec: string;
    noiDungYeuCau: string;
    banVeThietKe: string;
    cacVanDeKhac: string;
    nguoiKy: string;
    maHoSo: string;
    ngayKy: number;
    hoatDong: boolean;
}
export interface ThongTinSuaChuaDTO {
    id: number;
    giayPhepXayDungId: number;
    tongDienTichSan: number;
    dtXayDungTangMot: number;
    chieuCaoCongTrinh: string;
    soTang: string;
    maLoaiCongTrinh: string;
    tenLoaiCongTrinh: string;
    maCapCongTrinh: string;
    tenCapLoaiCongTrinh: string;
    dienTichCaiTao: number;
    tongDienTichSanSuaChua: number;
    chieuCao: string;
    soTangSuaChua: string;
    ngayGiayPhepCu: number;
    hoatDong: boolean;
}

export interface DiaDiemHienTaiDTO {
    maTinh: string;
    tenTinh: string;
    maHuyen: string;
    tenHuyen: string;
    maPhuong: string;
    tenPhuong: string;
    maDuong: string;
    tenDuong: string;
    soNha: string;
    diaChiCu: string;
    giayPhepXayDungId: number;
    loaiDiaDiem : number;
    loDatXayDungDTOs: LoDatXayDungDTO[];
}

export interface LichSuGiayPhepDTO{
    id: number;
    giayPhepXayDungId: number;
    giayPhepDaCapId: number;
    ngayCapNhat: number;
    ngayTao: number;
    maLoaiGiayPhep : String;
    tenLoaiGiayPhep: String;
    maLoaiBienDong: String;
    tenLoaiBienDong: String;
    maTinhTrang: String;
    tenTinhTrang: String;
    maHuyen: String;
    tenHuyen: String;
    maPhuong: String;
    tenPhuong: String;
    ngayThongKe: number;
    tenDonViCapPhep: String;
    maDonViCapPhep: String;
    hoatDong: Boolean;
    files : FileEntry[];
}


export interface ThuHoiHuyDTO{
    id: number;
    giayPhepXayDungId: number;
    giayPhepXayDungDaCapId: number;
    soQuyetDinh : string;
    ngayQuyetDinh: number;
    nguoiKy : String;
    lyDoHuy: String;
    ghiChu: String;
    maTinhTrang: String;
    tenTinhTrang: String;
    maLoaiBienDong : String;
    tenLoaiBienDong: String;
    files: FileEntry[];
}

export interface GiayPhepXayDungDaCapRequest extends SearchRequest {
    tenTinhTrang: string;
    maTinhTrang: string;
    soGiayPhep: string;
    maLoaiGiayPhep: string;
    tenLoaiGiayPhep: string;
    chuDauTu: string;
    maTinh: string;
    tenTinh: string;
    maHuyen: string;
    tenHuyen: string;
    maPhuong: string;
    tenPhuong: string;
    soNha: string;
    exportType: string;
    exportFileName: string;
    tenDonViCapPhep: string;
    maDonViCapPhep: string;
    tenCapLoaiCongTrinh: string;
    soKyHieuBangVe: string;
    maCapCongTrinh: string;
    soTo: string;
    soThua: string;
    
    ngayCapTu: number;
    ngayCapDen: number;
    kyThongKe: number;
    namThongke: number;
    tongDienTichSanTu: number;
    dienTichTangMotTu: number;
    tongDienTichSanDen: number;
    dienTichTangMotDen: number;
    isBaoCao : boolean;
}

export interface KiemTranNganChanDTO {
    id: number;
    giayPhepXayDungId: number;
    ngayNganChan: number;
    chuSoHuu: string;
    diaChiCuThe: string;
    soTo: string;
    maHuyen: string;
    tenHuyen: string;
    maPhuong: string;
    tenPhuong: string;
    soThua: string;
    soCN: string;
    soVaoSo: string;
    congVanNC: string;
    noiDung: string;
    chiTiet: string;
    ghiChu: string;
}
export interface KiemTranNganChanRequest {
    id: number;
    giayPhepXayDungId: number;
    ngayNganChanTu : number;
    pageNumber: number,
    pageSize: number,
    ngayNganChanDen : number;
    chuSoHuu: string;
    diaChiCuThe: string;
    soTo: string;
    maHuyen: string;
    tenHuyen: string;
    maPhuong: string;
    tenPhuong: string;
    soThua: string;
    soCN: string;
    soVaoSo: string;
    congVanNC: string;
}
export enum DoiTuongChonTuKho {
    CA_NHAN = "0",
    DOANH_NGHIEP = "1",
}
export const ChuDauTuModel_init: ChuDauTuModel ={
    id: 0,
    giayPhepXayDungId: 0,
    loaiDoiTuong: 0,
    danhXung: "",
    hoTen: '',
    diaChiChiTiet: '',
    maGioiTinh: '',
    tenGioiTinh: '',
    duong: '',
    loaiGiayTo: 0,
    tenLoaiGiayTo: '',
    tenTinh: '',
    maTinh: '',
    tenHuyen: '',
    maHuyen: '',
    tenPhuong: '',
    maPhuong: '',
    diaChiCu: '',
    soGiayTo: '',
    ngayCap: 0,
    ngaySinh: 0,
    tenNoiCap: '',
    maNoiCap: '',
    hoatDong: true,
    thongTinTimKiem: '',
    tenToChuc: '',
    loaiToChuc: '',
    nguoiDaiDien: '',
    diaChiDoanhNghiep: '',
    checked: 0,
    chonTuKho: 0,
}