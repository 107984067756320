import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { ConfirmationDialogRaw } from "../../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../../commons/components/tabs";
import { HoSoThuLyBLR, TinhTrang } from "../../../../../../api/CPBLR/model/HoSoThuLyBLR";
import { CAP_MOI_SX_RUOU_INIT } from "../../../../../../api/CPBLR/Init/CapMoiSXRuouInit";
import StringUtil from "../../../../../utils/stringUtils";
import { CapMoiSXRuouService } from "../../../../../../api/CPBLR/CapMoiSXRuouService";
import { BieuMauAttachFileDTO } from "../../../../../../api/QuanTri/Model/danhMucBieuMau";
import { createFileType, downLoadFile } from "../../../../../../api/Common/exportUtil";
import { AlertMessage, CustomAlert } from "../../../../../commons/components/alert";
import { useHistory } from 'react-router';
import DauKyBLRuouNoiDungComponent from './DauKyBLRuouNoiDungComponent';
import { LOAIBIENDONG_CAPMOI,LOAIBIENDONG_HETHAN,LOAIBIENDONG_HUY, LOAIBIENDONG_THUHOI, TINHTRANG_HETHAN, TINHTRANG_HOATDONG, TINHTRANG_HUY, TINHTRANG_THUHOI } from '../../../../../Constants/RuouConstants';
import { OverlayLoading } from '../../../../../commons/components/overlayLoading/overlayLoading';
import { getObject } from '../../../../../../helpers/localStorage';
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const DauKyBLRuouComponent = () => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = React.useState({} as HoSoThuLyBLR);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const capMoiSXRuouService = new CapMoiSXRuouService();
    const history = useHistory();


    useEffect(() => {
        initData();
    }, [])

    const initData = () => {
        const hoSoThuLyId = history?.location?.state?.hoSoThuLyId;
        if (hoSoThuLyId && hoSoThuLyId > 0) {
            capMoiSXRuouService.getByHoSoThuLyId(hoSoThuLyId).then(res => {
                if (res?.data && res?.data?.data && res?.data?.data !== null && res?.data?.data !== undefined) {
                    let resData: HoSoThuLyBLR = {
                        ...res?.data?.data,
                        giayPhepId: history?.location?.state?.id
                    };
                    setData(resData);
                } else {
                    const dataNew: HoSoThuLyBLR = {
                        ...CAP_MOI_SX_RUOU_INIT,
                        loaiGiayPhep: 'BLR',
                        maHoSo: history?.location?.state?.maHoSo,
                        maThuTuc: history?.location?.state?.maThuTuc,
                        maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                        maTinhTrang: TINHTRANG_HOATDONG.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    }
                    setData(dataNew);
                }
            }).catch(error => {
                console.log(error);
            });
        } else {
            const dataNew: HoSoThuLyBLR = {
                ...CAP_MOI_SX_RUOU_INIT,
                loaiGiayPhep: 'BLR',
                maHoSo: history?.location?.state?.maHoSo,
                maThuTuc: history?.location?.state?.maThuTuc,
                maBienDong: LOAIBIENDONG_CAPMOI.ma,
                tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                maTinhTrang: TINHTRANG_HOATDONG.ma,
                tenTinhTrang: TINHTRANG_HOATDONG.ten,
                maQuanTruSo: '',
                tenQuanTruSo: '',
            }
            setData(dataNew);
        }
    }

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }

    const resetData = () => {
        const dataNew = {
            ...CAP_MOI_SX_RUOU_INIT,
            id: data.id,
            loaiBienDong: LOAIBIENDONG_CAPMOI.ma,
            tinhTrang: TINHTRANG_HOATDONG.ma,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan)
            || StringUtil.isNullOrEmty(data.maPhuongTruSo)
            || StringUtil.isNullOrEmty(data.giayCNDK)
            || StringUtil.isNullOrEmty(data.soGiayPhep)) {
            setTabRequired(-0);
            return;
        }
        if (!(data?.ngayCapPhep !== undefined && data?.ngayCapPhep != 0)) {
            setTabRequired(-0);
            return;
        }
        setSubmit(false);
        let dataRequest = { ...data };
        if (dataRequest.maTinhTrang === TINHTRANG_HETHAN.ma) {
            dataRequest = {
                ...data,
                maBienDong: LOAIBIENDONG_HETHAN.ma,
                tenBienDong: LOAIBIENDONG_HETHAN.ten,
                tenTinhTrang : TINHTRANG_HETHAN.ten
            };
        } else if (dataRequest.maTinhTrang === TINHTRANG_HUY.ma) {
            dataRequest = {
                ...data,
                maBienDong: LOAIBIENDONG_HUY.ma,
                tenBienDong: LOAIBIENDONG_HUY.ten,
                tenTinhTrang : TINHTRANG_HUY.ten
            };
        } else if (dataRequest.maTinhTrang === TINHTRANG_THUHOI.ma) {
            dataRequest = {
                ...data,
                maBienDong: LOAIBIENDONG_THUHOI.ma,
                tenBienDong: LOAIBIENDONG_THUHOI.ten,
                tenTinhTrang : TINHTRANG_THUHOI.ten
            };
        }
        capMoiSXRuouService.insertOrUpdateDauKy({...dataRequest }).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL') {
                    let dataNew: HoSoThuLyBLR = { ...data, id: res.data.data.id, giayPhepId: res.data.data.giayPhepId };
                    setDataParam('data', dataNew);
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else {
                    showMessageAlert(res.data.error_message, false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        capMoiSXRuouService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    return (
        <>
            {
                <Paper square>
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <br></br>
                                <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    CẤP GIẤY PHÉP BÁN LẺ RƯỢU
                                </div>
                            </div>
                                {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange}>
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                </CustomTabs>
                            </Box>

                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <DauKyBLRuouNoiDungComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    data={data} />
                            </TabPanel>
                        }
                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <Button onClick={handleSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
                            <Button onClick={resetData} variant="contained" color="secondary" disableElevation> Xóa</Button>
                            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    );

};
export default DauKyBLRuouComponent;