import { ChiTietHangMucDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { ChiTietHangMucDTO_INIT, HangMucXayDungDTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import StringUtil from '../../../../utils/stringUtils';
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import React, { useEffect } from 'react';
import { Box, Button, Dialog,FormHelperText, Grid, InputAdornment, InputBase, InputLabel } from '@material-ui/core';
interface ChiTietHangMucModalProps {
    data: ChiTietHangMucModalDataProps;
    onToggle: () => void;
    onAddData: (value: ChiTietHangMucDTO) => void;
    onUpdateData: (index: number, value: ChiTietHangMucDTO) => void;
}
export interface ChiTietHangMucModalDataProps {
    data: ChiTietHangMucDTO;
    show: boolean;
    indexEdit: number;
}
const ChiTietHangMucModal = (props: ChiTietHangMucModalProps) => {
    const [data, setData] = React.useState({ ...ChiTietHangMucDTO_INIT });
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [indexEdit, setIndexEdit] = React.useState(-1);
    const { onToggle, onAddData, onUpdateData } = props;
    useEffect(() => {
        setData(props.data.data);
        setShow(props.data.show);
        setIndexEdit(props.data.indexEdit);
        setSubmit(false);
    }, [props.data]);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData(data => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }
    const handleChangeNumber = (e: any) => {
        let value = e.target.value;
        // if (Number.isNaN(Number.parseInt(value))) {
        //     value = 0;
        // }
        // if (Number.parseInt(value) < 0) {
        //     return;
        // }
        if(StringUtil.allowNumberOrDot(value)){
            let valueString : string =  StringUtil.getNumberDot(value);
            setData(data => ({
                ...data,
                [e.target.name]: valueString
            }));
        }

       
    }
    const handleSubmit = () => {
        setSubmit(true);
        if (validateFrom()) {
            if (indexEdit >= 0) {
                onUpdateData(indexEdit, data);
            } else {
                onAddData(data);
            }
            onToggle();
        }
    }

    const validateFrom = (): boolean => {
        let valid: boolean = true;
        if (StringUtil.isNullOrEmty(data.chiTiet)) {
            valid = false;
        }
        return valid;
    }
    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        {indexEdit >= 0 ? 'CẬP NHẬT CHI TIẾT HẠNG MỤC' : 'THÊM MỚI CHI TIẾT HẠNG MỤC'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Chi tiết</InputLabel>
                                    <InputBase fullWidth
                                        name="chiTiet"
                                        error={submit && StringUtil.isNullOrEmty(data.chiTiet)}
                                        value={data.chiTiet} onChange={handleChange} />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.chiTiet) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập chi tiết</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Chiều cao</InputLabel>
                                    <InputBase fullWidth
                                        name="chieuCao"
                                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data.chieuCao ? data.chieuCao.toString() : "") } onChange={handleChangeNumber} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Công thức</InputLabel>
                                    <InputBase fullWidth
                                        name="congThuc"
                                        value={data.congThuc} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Diện tích</InputLabel>
                                    <InputBase fullWidth
                                        name="dienTich"
                                        endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data.dienTich ? data.dienTich.toString() : "")} onChange={handleChangeNumber} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <Box marginTop="5px">
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={handleSubmit}>
                                            Lưu
                                </Button>
                                        <Button variant="contained" color="default" disableElevation
                                            onClick={onToggle}>
                                            Đóng
                                </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>

    )
}
export default ChiTietHangMucModal;