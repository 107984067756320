import { RoutePropsI } from "."
import { BaoCaoCPXD } from "../App/Components/CPXD/BaoCaoGPXD/BaoCaoCPXD"
import { BieuDoThongKeCPXD } from "../App/Components/CPXD/BieuDoTK/BieuDoThongKeCPXD"
import { CapGiayPhepXayDung } from "../App/Components/CPXD/CapSoGiayPhep/CapGiayPhepXayDung"
import QuanLyKiemTraNganChan from "../App/Components/CPXD/common/modal/QuanLyKiemTraNganChan"
import { CapCongTrinnhComponent } from "../App/Components/CPXD/DanhMuc/CapCongTrinh/main"
import { LoaiCongTrinhComponent } from "../App/Components/CPXD/DanhMuc/LoaiCongTrinh/main"
import { PhanLoaiCongTrinhComponent } from "../App/Components/CPXD/DanhMuc/PhanLoaiCongTrinh/main"
import NhaORiengLeCapLaiComponent from "../App/Components/CPXD/GiaoDienThuLy/CapLai/NhaORiengLeCapLaiComponent"
import CongTrinhCoThoiHanCapMoiComponent from "../App/Components/CPXD/GiaoDienThuLy/CapMoiCongTrinhCoThoiHan/CongTrinhCoThoiHanCapMoiComponent"
import NhaORiengLeCapMoiComponent from "../App/Components/CPXD/GiaoDienThuLy/CapMoiNhaORiengLe/NhaORiengLeCapMoiComponent"
import DiDoiComponent from "../App/Components/CPXD/GiaoDienThuLy/DiDoi/DiDoiComponent"
import NhaORiengLeDieuChinhComponent from "../App/Components/CPXD/GiaoDienThuLy/DieuChinh/NhaORiengLeDieuChinhComponent"
import NhaORiengLeGiaHanComponent from "../App/Components/CPXD/GiaoDienThuLy/GiaHan/NhaORiengLeGiaHanComponent"
import SuaChuaCaiTaoCPXD from "../App/Components/CPXD/GiaoDienThuLy/SuaChuaCaiTao/SuaChuaCaiTaoCPXD"
import ChiTietGiayPhepXayDungComponent from "../App/Components/CPXD/QuanLy/ChiTiet/ChiTietGiayPhepXayDung"
import QuanLyGiayPhepCPXD from "../App/Components/CPXD/QuanLy/QuanLyGiayPhepCPXD"
import ThuHoiGiayPhepXayDung from "../App/Components/CPXD/QuanLy/ThuHoi/ThuHoiGiayPhepXayDung"
import { ThongKeComponent } from "../App/Components/CPXD/ThongKe/ThongKeComponent"

export const ROUTE_CPXD_CAPCONGTRINHCOTHOIHAN = {
    ten: 'CẤP GIẤY PHÉP XÂY DỰNG CÔNG TRÌNH',
    giaoDien: CongTrinhCoThoiHanCapMoiComponent,
    duongDan: '/cap-phep-xay-dung/cap-phep-xay-dung-cong-trinh',
}
export const ROUTE_CPXD_SUACHUACAITAO = {
    ten: 'CẤP GIẤY PHÉP SỬA CHỮA, CẢI TẠO CÔNG TRÌNH/ NHÀ Ở RIÊNG LẺ',
    giaoDien: SuaChuaCaiTaoCPXD,
    duongDan: '/cap-phep-xay-dung/cap-giay-phep-sua-chua-cai-tao',
}

export const ROUTE_CPXD_NHAORIENGLE = {
    ten: 'CẤP GIẤY PHÉP XÂY DỰNG NHÀ Ở RIÊNG LẺ',
    giaoDien: NhaORiengLeCapMoiComponent,
    duongDan: '/cap-phep-xay-dung/cap-moi-nha-o-rieng-le',

}
export const ROUTE_CPXD_DIDOI = {
    ten: 'CẤP GIẤY PHÉP DI DỜI CÔNG TRÌNH',
    giaoDien: DiDoiComponent,
    duongDan: '/cap-phep-xay-dung/di-doi',

}
export const ROUTE_CPXD_CHITIETGIAYPHEP = {
    ten: 'CHI TIẾT GIẤY PHÉP XÂY DỰNG',
    giaoDien: ChiTietGiayPhepXayDungComponent,
    duongDan: '/cap-phep-xay-dung/chi-tiet-giay-phep-xay-dung',
}
export const ROUTE_CPXD_THUHOI_OR_HUY = {
    ten: 'THU HỒI HOẶC HỦY GIẤY PHÉP XÂY DỰNG',
    giaoDien: ThuHoiGiayPhepXayDung,
    duongDan: '/cap-phep-xay-dung/thao-tac-giay-phep',
}
export const ROUTE_CPXD_NHAPDAUKY: RoutePropsI[] = [
    ROUTE_CPXD_CAPCONGTRINHCOTHOIHAN,
    ROUTE_CPXD_SUACHUACAITAO,
    ROUTE_CPXD_NHAORIENGLE,
    ROUTE_CPXD_DIDOI
];
export const ROUTE_CN_CPXD: RoutePropsI[] = [
    ROUTE_CPXD_CAPCONGTRINHCOTHOIHAN,
    ROUTE_CPXD_SUACHUACAITAO,
    ROUTE_CPXD_NHAORIENGLE,
    ROUTE_CPXD_DIDOI,
    {
        ten: 'ĐIỀU CHỈNH GIẤY PHÉP XÂY DỰNG CÔNG TRÌNH/ NHÀ Ở RIÊNG LẺ',
        giaoDien: NhaORiengLeDieuChinhComponent,
        duongDan: '/cap-phep-xay-dung/dieu-chinh',

    },
    {
        ten: 'CẤP LẠI GIẤY PHÉP XÂY DỰNG',
        giaoDien: NhaORiengLeCapLaiComponent,
        duongDan: '/cap-phep-xay-dung/cap-lai',
    },
    {
        ten: 'GIA HẠN GIẤY PHÉP XÂY DỰNG',
        giaoDien: NhaORiengLeGiaHanComponent,
        duongDan: '/cap-phep-xay-dung/gia-han',
    }
];
export const ROUTE_PAGE_CPXD: RoutePropsI[] = [
    {
        ten: "QUẢN LÝ GIẤY PHÉP XÂY DỰNG",
        duongDan: '/cap-phep-xay-dung/quan-ly-giay-phep',
        giaoDien: QuanLyGiayPhepCPXD
    },
    {
        ten: "BÁO CÁO GIẤY PHÉP XÂY DỰNG",
        duongDan: '/cap-phep-xay-dung/bao-cao',
        giaoDien: BaoCaoCPXD
    },
    {
        ten: "CẬP NHẬT GIẤY PHÉP XÂY DỰNG",
        duongDan: '/cap-phep-xay-dung/cap-nhat-giay-phep',
        giaoDien: CapGiayPhepXayDung,
    },
    {
        ten: 'THỐNG KÊ TÌNH HÌNH CẤP PHÉP XÂY DỰNG',
        giaoDien: ThongKeComponent,
        duongDan: '/cap-phep-xay-dung/thong-ke-tinh-hinh-giay-phep-xay-dung',
    },
    {
        ten: 'BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP PHÉP XÂY DỰNG',
        giaoDien: BieuDoThongKeCPXD,
        duongDan: '/cap-phep-xay-dung/bieu-do-thong-ke',
    },
    {
        ten: "DANH MỤC CẤP CÔNG TRÌNH",
        duongDan: '/cpxd/dm-cap-cong-trinh',
        giaoDien: CapCongTrinnhComponent,
    },
    {
        ten: "DANH MỤC LOẠI CÔNG TRÌNH",
        duongDan: '/cpxd/dm-loai-cong-trinh',
        giaoDien: LoaiCongTrinhComponent,
    },
    {
        ten: "DANH MỤC PHÂN LOẠI CÔNG TRÌNH",
        duongDan: '/cpxd/dm-phan-loai-cong-trinh',
        giaoDien: PhanLoaiCongTrinhComponent,
    },
    {
        ten: "QUẢN LÝ KIỂM TRA NGĂN CHẶN",
        duongDan: '/cap-phep-xay-dung/quan-ly-kiem-tra-ngan-chan',
        giaoDien: QuanLyKiemTraNganChan,
    }
];

export const ROUTE_DS_CPXD: RoutePropsI[] = [
    {
        ten: "QUẢN LÝ GIẤY PHÉP XÂY DỰNG",
        duongDan: '/cap-phep-xay-dung/quan-ly-giay-phep',
        giaoDien: QuanLyGiayPhepCPXD
    },
    {
        ten: "BÁO CÁO GIẤY PHÉP XÂY DỰNG",
        duongDan: '/cap-phep-xay-dung/bao-cao',
        giaoDien: BaoCaoCPXD
    },
    {
        ten: 'THỐNG KÊ TÌNH HÌNH CẤP PHÉP XÂY DỰNG',
        giaoDien: ThongKeComponent,
        duongDan: '/cap-phep-xay-dung/thong-ke-tinh-hinh-giay-phep-xay-dung',
    },
];

export const ROUTE_PAGE_PUBLIC_CPXD: RoutePropsI[] = [
    {
        ten: 'BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP PHÉP XÂY DỰNG',
        giaoDien: <BieuDoThongKeCPXD />,
        duongDan: '/cap-phep-xay-dung/bieu-do-thong-ke',
    },
];

export const ROUTE_GUEST_CPXD: RoutePropsI[] = [
    ROUTE_CPXD_CHITIETGIAYPHEP,
    ROUTE_CPXD_THUHOI_OR_HUY,
];

