import AXIOS_INSTANCE from "..";

export default class LayKetQuaGiayPhepService {
    constructor() { }
    
    layKetQuaByMaHoSo = (maHoSo : string) => {
        return AXIOS_INSTANCE.get('lay-ket-qua/get-by-ma-ho-so/'+maHoSo, {timeout : 600000});
    }

    capNhatLayKetQua = () => {
        return AXIOS_INSTANCE.get('lay-ket-qua/cap-nhat-lay-ket-qua', {timeout : 600000});
    }
    capNhatNgayHenTra = () => {
        return AXIOS_INSTANCE.get('lay-ket-qua/cap-nhat-ngay-hen-tra', {timeout : 600000});
    }
}


