import { AttachFileDTO } from "./attachFile";

export enum LoaiHinhKhenThuong {
    CA_NHAN = "0",
    TAP_THE = "1",
}
export enum LoaiKhenThuong {
    NONE = "",
}

export enum DoiTuongKhenThuong {
    LANH_DAO = "0",
    CANBO = "1",
}

export enum NguonVon {
    NONE = "",
    NGAN_SACH = "0",
    TU_CO = "1",
    VAY_NGAN_HANG = "2",
}

export interface SubKhenThuongDTO {
    id: null | Number;
    hinhThucKhenThuongId?: Number;
    hinhThucKhenThuongString?: String;
    danhHieuThiDuaId?: Number;
    danhHieuThiDuaString?: String;
    soQuyetDinh?: String;
    ngayQuyetDinh?: Date | null;
    coQuanBanHanh?: String;
    attachFiles?: AttachFileDTO[];
    isDeleted?: boolean;
    danhSachKhenThuongId?: Number;
}

export interface BaseKhenThuongDTO {
    id: null | Number;
    soBienNhan?: string;
    thuTucId?: number;

    loaiHinhKhenThuongId?: Number;
    loaiHinhKhenThuongString?: String;

    hinhThucKhenThuongId?: Number;
    hinhThucKhenThuongString?: String;
    danhHieuThiDuaId?: Number;
    danhHieuThiDuaString?: String;
    soTien?: String;
    nguonKinhPhiId?: Number;
    nguonKinhPhi?: String;
    thanhTichKhenThuong?: String;
    tomTatThanhTich?: String;
    hiddenFormId?: String;
    subsDTO?: SubKhenThuongDTO[];
}

export interface KhenThuongCaNhanDTO extends BaseKhenThuongDTO {
    hoTen: String;
    doiTuong?: DoiTuongKhenThuong;
    ngaySinh: null | Date;
    ngheNghiep?: String;
    hocHam?: String;
    hocVi?: String;
    donVi?: String;
    chucVu?: String;
    nghiepVu?: String;
    trinhDoChuyenMon?: String;
    queQuan?: String;
    noiThuongTru?: String;
    quyenHanNhiemVuDamNhan?: String;
    hoSoThuLyId?: number;
}

export interface KhenThuongTapTheDTO extends BaseKhenThuongDTO {
    tenTapThe?: String;
    diaChi?: String;
    soDienThoai?: String;
    fax?: String;
    email?: String;
    webSite?: String;
    tongSoCanBo?: String;
    tongSoCongChuc?: String;
    tongSoVienChuc?: String;
    doanhNghiep?: boolean;
    coCauToChuc?: String;
    chucNangNhiemVu?: String;
    quaTrinhThanhLap?: String;
    dieuKienCoSoVatChat?: String;
    tongSoVonCoDinh?: String;
    vonLuuDong?: String;
    nguonVon?: NguonVon;
    hoSoThuLyId?: number;
}

export interface TableKhenThuong extends BaseKhenThuongDTO {
    soQuyetDinh?: String;
    ngayQuyetDinh?: null | Date;
    coQuanBanHanh?: String;
    tapDinhKem?: any;
}
