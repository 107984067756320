import { useEffect, useState } from 'react';
import { GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { Box, Grid, InputLabel, FormHelperText, InputBase } from '@material-ui/core';
import StringUtil from '../../../../utils/stringUtils';
import { Autocomplete } from '@material-ui/lab';
import moment from "moment";
import { GIAY_PHEP_XAY_DUNG_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { CapPhepXayDungService } from '../../../../../api/CPXD/CapPhepXayDungService';
import { TINHTRANG_HOATDONG } from '../../../../Constants/CapPhepXayDungConstants';
import ThongTinDiDoiComponent from './ThongTinDiDoiComponent';
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
interface NoiDungDiDoiProps {
    data: GiayPhepXayDungDTO;
    submit: boolean;
    isNhapDauKy: boolean;
    onUpdateData: (name: string, value: any) => void;
    loadGiayPhepDaCap: (soGiayPhepCu: string) => void;
    resetData: () => void;
}

const NoiDungDiDoiComponent = (props: NoiDungDiDoiProps) => {
    const [data, setData] = useState({ ...GIAY_PHEP_XAY_DUNG_INIT });
    const [dataAutocomple, setDataAutocomple] = useState<GiayPhepXayDungDTO[]>(new Array<GiayPhepXayDungDTO>());
    const [submit, setSubmit] = useState(false);
    const { onUpdateData, loadGiayPhepDaCap, isNhapDauKy, resetData } = props;
    const capPhepXayDungService = new CapPhepXayDungService();
    useEffect(() => {
        setData(props.data);
        setSubmit(props.submit);
    }, [props.data, props.submit, props.onUpdateData]);

    const handleSelectGiayPhepDaCap = (newValue: GiayPhepXayDungDTO) => {
        if (!isNhapDauKy) {
            onUpdateData('soGiayPhepCu', newValue.soGiayPhep);
        } else {
            onUpdateData('soGiayPhep', newValue.soGiayPhep);
        }
        loadGiayPhepDaCap(newValue.soGiayPhep);
    }
    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        onUpdateData('soGiayPhepCu', soGiayPhepCuNew);
        capPhepXayDungService.getAutocompleteBySoGiayPhep_MaTrangThai_PageSize(soGiayPhepCuNew, TINHTRANG_HOATDONG.ma, 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setDataAutocomple([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    }
    return (
        <>
            {
                <Box padding={0}>
                    <Box padding={0}>
                        {isNhapDauKy && (
                            <Grid container>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Số giấy phép</InputLabel>
                                    <InputBase fullWidth
                                        error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                        value={data.soGiayPhep} name="soGiayPhep" onChange={handleChange} />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập số giấy phép</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            error={submit && !(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined)}
                                            value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined) ? new Date(data.ngayCapPhepCu) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhepCu')}

                                        />
                                    </MuiPickersUtilsProvider>
                                    {
                                        submit && (data.ngayCapPhepCu === 0 || !data.ngayCapPhepCu || data.ngayCapPhepCu === null || data.ngayCapPhepCu === undefined) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập ngày cấp phép</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Ngày hết hạn</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            error={submit && !(data.ngayHetHan !== 0 && data.ngayHetHan && data.ngayHetHan !== null && data.ngayHetHan !== undefined)}
                                            value={(data.ngayHetHan !== 0 && data.ngayHetHan && data.ngayHetHan !== null && data.ngayHetHan !== undefined) ? new Date(data.ngayHetHan) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHan')}

                                        />
                                    </MuiPickersUtilsProvider>
                                    {
                                        submit && (data.ngayHetHan === 0 || !data.ngayHetHan || data.ngayHetHan === null || data.ngayHetHan === undefined) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập ngày hết hạn</FormHelperText>
                                    }
                                </Grid>
                            </Grid>
                        )
                        }
                        {!isNhapDauKy &&
                            <Grid container>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Số giấy phép </InputLabel>
                                    <Autocomplete id="soGiayPhepCu"
                                        options={dataAutocomple}
                                        value={data}
                                        noOptionsText={"Không có dữ liệu"}
                                        getOptionLabel={(option) => option.soGiayPhep}
                                        onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                        renderInput={(params) => {
                                            return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                                <InputBase fullWidth
                                                    error={submit && (StringUtil.isNullOrEmty(data.maLoaiGiayPhep) || StringUtil.isNullOrEmty(data.soGiayPhepCu))}
                                                    {...params.inputProps}
                                                    value={data.soGiayPhepCu}
                                                    onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}
                                                />
                                            </div>
                                        }}
                                    />
                                    {
                                        submit && (StringUtil.isNullOrEmty(data.maLoaiGiayPhep) || StringUtil.isNullOrEmty(data.soGiayPhepCu)) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập số giấy phép </FormHelperText>
                                    }

                                </Grid>
                                {
                                    !StringUtil.isNullOrEmty(data.maLoaiGiayPhep) &&
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel >Ngày cấp phép </InputLabel>
                                        <InputBase fullWidth
                                            value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu !== null) ? moment(data.ngayCapPhepCu).format("DD/MM/YYYY") : ''} disabled name="ngayCapPhepCu" />
                                    </Grid>
                                }
                            </Grid>
                        }
                        <ThongTinDiDoiComponent
                            onUpdateData={onUpdateData}
                            submit={submit}
                            isNhapDauKy={isNhapDauKy}
                            data={data}
                            resetData={resetData}
                            loadGiayPhepDaCap={loadGiayPhepDaCap} />
                    </Box>

                </Box>
            }
        </>
    )
}
export default NoiDungDiDoiComponent;