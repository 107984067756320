import { Button, Grid, makeStyles, Theme, createStyles, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { Dispatch, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ChuyenXuLyRequest, HoSoCuaToiMgtService, ThongTinBuocDTO } from '../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';
import { BieuMauAttachFileDTO } from '../../../../api/QuanTri/Model/danhMucBieuMau';
import { UserData } from '../../../../api/userMgt/userManagementService';
import { hideLoading, LoadingAction, showLoading } from '../../../../redux/actions/applicationAction';
import InBieuMauButton from "./InBieuMauButton";
import { useRef } from 'react';
import ReplyIcon from '@material-ui/icons/Reply';
import CloseIcon from '@material-ui/icons/Close';
import ChuyenXuLyHoSoModal from './chuyenXuLyHoSoModal';
import { withStyles } from '@material-ui/styles';
import { HoSoThuLyDTO } from '../../../../api/DKKD/model/hoSoThuLyDTO';

interface ThuLyButtonProps {
    onSubmit: () => void;
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    onDelete: () => void;
    showIn?: boolean;
    handleDuThao?: () => void;
    dataButton : HoSoThuLyDTO;
    handleChuyen?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
        margin: {
            margin: theme.spacing(0.5),
        },
    })
);

export const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: "none",
        },
        sizeSmall: {
            fontSize: "13px",
        },
        startIcon: {
            marginRight: theme.spacing(0.5),
        },
        "&:focus": {
            outlineWidth: "0px",
            outlineStyle: "none",
        },
    })
)(Button);

const ThuLyButtonComponent = (props: ThuLyButtonProps) => {
    const { onSubmit, onDownloadFileBieuMau, onDelete, showIn, handleDuThao, dataButton, handleChuyen } = props;
    const [openResetData , setOpenResetData] = useState(false);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [open, setOpen] = useState(false);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const classes = useStyles();
    const [buocChuyen, setBuocChuyen] = useState<ThongTinBuocDTO>({} as ThongTinBuocDTO);
    const [chuyenXuLyRequest, setChuyenXuLyRequest] = useState<ChuyenXuLyRequest>({} as ChuyenXuLyRequest);
    const [showModal, setShowModal] = useState(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showModalDanhSachKhongThuLy, setShowModalDanhSachKhongThuLy] = useState(false);
    let C_USER_DATA_DE = {} as UserData;
    if(localStorage.getItem("C_USER_DATA") != null){
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    const history = useHistory();

    const showHidePopperThaoTac = () => {
        dispatchLoading(showLoading());
        hoSoCuaToiMgtService.getThongTinXuLy(C_USER_DATA_DE.maCoQuan,props.dataButton.maHoSo).then(data => {
            const buocChuyensNew = [...data];
            setBuocChuyens(buocChuyensNew);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
        setOpen(!open);
    }
    
    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    
    const clickBuocChuyen = (buocChuyenData: ThongTinBuocDTO) => {
        setBuocChuyen(buocChuyenData);
        const chuyenXuLyRequestNew: ChuyenXuLyRequest = {
            ...chuyenXuLyRequest,
            maHoSo: props.dataButton.maHoSo ? props.dataButton.maHoSo : "",
            idBuocXuLyTiepTheo: buocChuyenData.idBuocXuLy,
            maNguoiGui: C_USER_DATA_DE.userName,
            nguoiNhanId: 0,
            noiDung: '',
            trichYeuDuThao: '',
            idLoaiVanBan: 0,
            hanXuLy: buocChuyenData.hanXuLyTheoGio
        }
        setChuyenXuLyRequest(chuyenXuLyRequestNew);
        setShowModal(!showModal);
    }
    
    const clickKhongThuLy = (buocChuyenData: ThongTinBuocDTO) => {
        setShowModalDanhSachKhongThuLy(!showModalDanhSachKhongThuLy);
    }
    
    const handleToggle = () => {
        setShowModal(!showModal);
    }

    return (
        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
            <Button onClick={onSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
            {
                showIn &&
                <InBieuMauButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
            }
            <Button onClick={onDelete} variant="contained" color="secondary" disableElevation> Xóa</Button>
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
            {/* {
                showIn &&
            <Button variant="contained" color="primary" disableElevation onClick ={showHidePopperThaoTac}>
                Chuyển
            </Button>
            }
            {
                showIn &&
            <Button onClick={handleDuThao} variant="contained" color="primary" disableElevation style={{margin: '0px 5px'}}> 
                Dự thảo
            </Button>
            } */}
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                    {
                                        buocChuyens.map((buocChuyen, k) => {
                                            return (
                                                <MenuItem key={k}>
                                                    <CustomButton className={classes.margin} startIcon={<ReplyIcon />}
                                                        size="small" color="primary" disableRipple
                                                        onClick={() => clickBuocChuyen(buocChuyen)}
                                                    >
                                                        {buocChuyen.tenBuocXuLy}
                                                    </CustomButton>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    <MenuItem key={"KhongThuLy"} onClick={() => clickKhongThuLy(buocChuyen)}>
                                        <Button startIcon={<CloseIcon />}
                                            size="small" color="secondary" disableRipple
                                            onClick={() => clickKhongThuLy(buocChuyen)}
                                        >
                                            Không thụ lý
                                        </Button>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <ChuyenXuLyHoSoModal thongTinBuocDTO={buocChuyen} onToggle={handleToggle} hoso={props.dataButton} show={showModal} />
        </Grid>
    )
}
export default ThuLyButtonComponent;
