import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, Grid, InputBase, InputLabel, InputAdornment } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import StringUtil from "../../../../utils/stringUtils";
import DropZoneCustom from "../../../../commons/components/CustomDropzone/DropZoneCustom";
import { FileEntry } from "../../../../../api/Common/fileEntry";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { FileServerService } from "../../../../../api/Common/FileServerService";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { SearchGiayPhepDTO } from "../../../../../api/CPBLTL/model/SearchGiayPhepDTO";
import SearchIcon from "@material-ui/icons/Search";
import AXIOS_INSTANCE from '../../../../../api';
import { DanhMucDiaChiDTO } from "../../../../../api/danhMuc/model/DanhMucDTO";
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import { HoSoThuLyLPGChaiDTO } from '../../../../../api/LPGChai/model/HoSoThuLyLPGChai';
import { HO_SO_THU_LY_LPGCHAI_INIT } from '../../../../../api/LPGChai/model/GiayPhepLGPChaiInit';
import { LPGChaiService } from '../../../../../api/LPGChai/LPGChaiService';
import { TINHTRANG_HOATDONG } from '../../../../Constants/LGPChaiConstants';

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

interface CapSuaDoiBLThuocLaNoiDungProps {
    data: HoSoThuLyLPGChaiDTO;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
    loadGiayPhepDaCap: (giayPhepId: number) => void;
    onSeachDoanhNghiep: (name : string) => void;
}

const DieuChinhLGPChaiNoiDungComponent = (props: CapSuaDoiBLThuocLaNoiDungProps) => {
    const [data, setData] = React.useState({ ...HO_SO_THU_LY_LPGCHAI_INIT } as HoSoThuLyLPGChaiDTO);
    const [listGiayPhep, setListGiayPhep] = React.useState([] as SearchGiayPhepDTO[]);
    const { onUpdateData, submit, loadGiayPhepDaCap, onSeachDoanhNghiep } = props;
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const [dataQuanHuyen, setDataQuanHuyen] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataXaPhuong, setDataXaPhuong] = useState<DanhMucDiaChiDTO[]>([]);
    const giayChungNhanLPGChaiService = new LPGChaiService();
    const fileServerService = new FileServerService();
    useEffect(() => {
        async function getDm() {
            await new DanhMucService().getDanhMucDiaChi(String(92)).then((resp) => {
                setDataQuanHuyen(resp);
            });
        }
        getDm();
    }, []);

    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
    }, [props.data, props.onUpdateData]);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    async function handleChangeQuanHuyen(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText, maPhuongXa: '', tenPhuongXa: '' };
        onUpdateData("data", dataNew);
    }

    async function handleChangePhuongXa(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function handleChangeDuong(value: any, nameValue: string, nameText: string) {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function onBlurPhuongXa() {
        if (data?.maQuanHuyen) {
            await new DanhMucService().getDanhMucDiaChi(data?.maQuanHuyen).then((resp) => {
                setDataXaPhuong(resp);
            });
        }
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data?.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            files: mems
        };
        onUpdateData("data", dataNew);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            files: mems,
        };
        setAttachFileServer(mems);
        onUpdateData("data", dataNew);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const getGiayPhepDaCapAutocomplete = (event: any) => {

        let soGiayPhepCuNew = event.target.value;
        let dataNew = {
            ...data,
            soGiayPhepCu: soGiayPhepCuNew
        };
        onUpdateData('data', dataNew);
        giayChungNhanLPGChaiService.autocompleteBySoGiayPhep(soGiayPhepCuNew, TINHTRANG_HOATDONG.ma, 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setListGiayPhep([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    const handleSelectGiayPhepDaCap = (newValue: SearchGiayPhepDTO) => {
        loadGiayPhepDaCap(newValue.id);
    }

    const handleSearchDoanhNghiep = (maSoDoanhNghiep: string) => {
        onSeachDoanhNghiep(maSoDoanhNghiep);
    }

    return (
        <>
            {
                <Box padding={0}>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Số giấy phép </InputLabel>
                                <Autocomplete id="soGiayPhep"
                                    noOptionsText="Không có dữ liệu"
                                    options={listGiayPhep}
                                    getOptionLabel={(option) => option?.soGiayPhep}
                                    onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}
                                                value={data?.soGiayPhepCu}
                                                error={submit && StringUtil.isNullOrEmty(data?.soGiayPhepCu)}
                                                onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.soGiayPhepCu) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số giấy phép </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày cấp </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.ngayCapPhep !== undefined && data.ngayCapPhep > 0) ? new Date(data.ngayCapPhep) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.ngayHetHanGP !== undefined && data.ngayHetHanGP > 0) ? new Date(data.ngayHetHanGP) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel required>Tên thương nhân</InputLabel>
                                <InputBase fullWidth name="tenThuongNhan" value={data.tenThuongNhan} onChange={handleChange}
                                    error={submit && StringUtil.isNullOrEmty(data?.tenThuongNhan)}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.tenThuongNhan) &&
                                    <FormHelperText className="-error">Vui lòng nhập tên thương nhân</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete id="maHuyen"
                                    options={dataQuanHuyen}
                                    value={{ code: data?.maQuanHuyen ? data?.maQuanHuyen : "", name: data?.tenQuanHuyen ? data?.tenQuanHuyen : "" }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    onChange={(event: any, newValue: any) => handleChangeQuanHuyen(newValue, 'maQuanHuyen', 'tenQuanHuyen')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maQuanHuyen)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maQuanHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={dataXaPhuong}
                                    onFocus={onBlurPhuongXa}
                                    value={{ code: data?.maPhuongXa ? data?.maPhuongXa : "", name: data?.tenPhuongXa ? data?.tenPhuongXa : "" }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    onChange={(event: any, newValue: any) => handleChangePhuongXa(newValue, 'maPhuongXa', 'tenPhuongXa')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maPhuongXa)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maPhuongXa) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Đường </InputLabel>
                                <InputBase fullWidth value={data.tenDuong} name="tenDuong" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Số nhà</InputLabel>
                                <InputBase fullWidth name="soNha" value={data.soNha} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Điện thoại</InputLabel>
                                <InputBase fullWidth name="soDienThoai" value={data.soDienThoai} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Fax</InputLabel>
                                <InputBase fullWidth name="fax" value={data.fax} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Số Giấy CNĐKDN/HKD</InputLabel>
                                <InputBase fullWidth name="soGiayCNDKKD"
                                    value={data.soGiayCNDKKD} onChange={handleChange}
                                    error={submit && StringUtil.isNullOrEmty(data?.soGiayCNDKKD)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {" "}
                                            <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "10px", marginLeft: "3px" }} onClick={(e) => handleSearchDoanhNghiep(data.soGiayCNDKKD)}/>
                                        </InputAdornment>
                                    }
                                />
                             
                                {
                                    submit && StringUtil.isNullOrEmty(data.soGiayCNDKKD) &&
                                    <FormHelperText className="-error">Vui lòng nhập số Giấy CNĐKDN/HKD</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayCapCNDKKD !== undefined && data?.ngayCapCNDKKD > 0) ? new Date(data?.ngayCapCNDKKD) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapCNDKKD')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Nơi cấp Giấy CNĐKDN/HKD</InputLabel>
                                <InputBase fullWidth name="noiCapCNDKKD" value={data.noiCapCNDKKD} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Mã số thuế</InputLabel>
                                <InputBase fullWidth name="maSoThue" value={data.maSoThue} onChange={handleChange}
                                    error={submit && StringUtil.isNullOrEmty(data?.maSoThue)}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maSoThue) &&
                                    <FormHelperText className="-error">Vui lòng nhập mã số thuế</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={`${acceptFiles}`}
                                    attachFileServer={attachFileServer}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/lpg"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
        </>
    )
};

export default DieuChinhLGPChaiNoiDungComponent;
