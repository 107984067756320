import React, {useEffect} from 'react';
import {createStyles, Grid, InputLabel, makeStyles} from '@material-ui/core';
import {HoSoThuLyBLThuocLa, HoSoThuLyBLThuocLaBienDong} from "../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import {TableChiNhanhVanPhongComponent} from "./Table/TableChiNhanhVanPhongComponent";
import {TableThuongNhanBanBuonComponent} from "./Table/TableThuongNhanBanBuonComponent";
import {TableDiaDiemToChucBanLeComponent} from "./Table/TableDiaDiemToChucBanLeComponent";
import ThongTinChungGPComponent from "../Modal/ThongTinChungGPComponent";

interface ThongTinGPComponentComponentProps {
    data: HoSoThuLyBLThuocLaBienDong;
}

makeStyles(() =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
    }),
);
const ThongTinGPComponent = (props: ThongTinGPComponentComponentProps) => {
    const [data, setData] = React.useState({} as HoSoThuLyBLThuocLaBienDong);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <>
            {
                <Grid container spacing={2}>
                    <Grid item xs={12} className="grid-item">
                        <ThongTinChungGPComponent data={data}/>
                    </Grid>
                    <Grid item xs={12} className="grid-item" >
                        <InputLabel >Chi nhánh, văn phòng đại diện hoặc địa điểm kinh doanh</InputLabel>
                        <TableChiNhanhVanPhongComponent dataList={data?.chiNhanhVanPhongDaiDiens}/>
                    </Grid>
                    <Grid item xs={12} className="grid-item" >
                        <InputLabel >Được phép mua sản phẩm thuốc lá của các thương nhân bán buôn sản phẩm thuốc lá có tên sau</InputLabel>
                        <TableThuongNhanBanBuonComponent dataList={data?.thuongNhanBanBuons}/>
                    </Grid>
                    <Grid item xs={12} className="grid-item" >
                        <InputLabel >Được phép tổ chức bán lẻ sản phẩm thuốc lá tại các địa điểm</InputLabel>
                        <TableDiaDiemToChucBanLeComponent dataList={data?.diaDiemToChucBanLes}/>
                    </Grid>
                </Grid>
            }
        </>
    )
};
export default ThongTinGPComponent;
