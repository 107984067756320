import { useEffect, useState } from "react";
import { DanhMucKhenThuongDTO, DanhMucKhenThuongRequest, DanhMucKhenThuongService, Type } from "../api/KhenThuong/danhMuc/danhMucKhenThuong";

export const useDanhMucKhenThuong = (type: Type) => {
    const [lstResult, setlstResult] = useState<DanhMucKhenThuongDTO[]>([]);

    useEffect(() => {
        async function func() {
            let request = { isActive: true, pageNumber: 0, type: type, pageSize: 2147483647 } as DanhMucKhenThuongRequest;
            await new DanhMucKhenThuongService().getDMKhenThuong(request).then(p => {
                setlstResult(p.rows);
            })
        }
        func();
    }, [])
    return lstResult;
}