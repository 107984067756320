import { createStyles, Tab, TabProps, Tabs, Theme, withStyles } from "@material-ui/core";
import React from "react";

interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

export const CustomTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            // maxWidth: 40,
            width: '100%',
            backgroundColor: '#797979',
        },
    },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps extends TabProps {
    label: string;
}

export const CustomTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            color: '#333333',
            fontWeight: 'bold',
            minWidth: 'max-content',
            '&:focus': {
                opacity: 1,
                border: 'none!important'
            },
        },
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);