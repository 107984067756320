import MomentUtils from "@date-io/moment";
import {
    Button,
    Card,
    CardContent,
    createStyles,
    Grid, InputLabel,
    makeStyles,
    NativeSelect, Snackbar, TextField,
    Theme
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useFormik } from "formik";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { PageRequest } from "../../../../api/baseService";
import { BieuDoThongkeRespone } from "../../../../api/VSATTP/Model/VSATTPModel";
import { ThongKeService } from "../../../../api/VSATTP/Services/ThongKeService";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { RequestThongKeDTO } from "../ThongKe/ThongKeTinhHinhCapPhep";

export interface RequestBieuDoThongKeDTO extends PageRequest {
    namThongKe: number;
    kyThongKe: number;
    tuNgay: number;
    denNgay: number;
    tenNamThongKe: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;"
        },
    }),
);

export const BieuDoTronThongKeATTP = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [showDate, setShowDate] = useState(true);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'warning', open: false } as AlertMessage);
    const [data, setData] = useState<BieuDoThongkeRespone>();

    useEffect(() => {
        namThongKeList();
        execute();
    }, []);

    const formik = useFormik({
        initialValues: {
            request: {
                kyThongKe: 1,
                namThongKe: new Date().getUTCFullYear(),
                tenNamThongKe: 'Năm ' + new Date().getUTCFullYear()
            } as RequestBieuDoThongKeDTO,
        },
        onSubmit: () => execute(),
    });

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            position: "bottom",
        },
        layout: {
            padding: 10,
        },
        type: "pie",
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem: any, data: any) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    const execute = async () => {
        if (formik.values.request?.kyThongKe == 2) {
            if (formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay) {
                showMessageAlert('Từ ngày không được lớn hơn đến này', false);
                return;
            }
        }
        let request = {
            ...formik.values.request,
        } as RequestThongKeDTO;
        dispatchLoading(showLoading());

        await new ThongKeService().thongKeBieuDoTron(request).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setData(res?.data?.data);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { nam: (namHienTai - i), name: 'Năm ' + (namHienTai - i) };
            temps.push(temp);
        }

        setDataNamThongKe(temps);
    }
    const handleChangeKyThongKe = (value: any) => {
        if (value == 1) {
            setShowDate(true);
        } else {
            setShowDate(false);
        }
    }
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'warning' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN VSATTP</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Kỳ thống kê</InputLabel>
                        <NativeSelect fullWidth disableUnderline name="kyThongKe" value={formik.values?.request?.kyThongKe} onChange={
                            (event: any) => {
                                formik.setFieldValue("request.kyThongKe", event.target.value);
                                handleChangeKyThongKe(event.target.value);
                            }
                        } >
                            <option value="1">Năm</option>
                            <option value="2">Khoảng thời gian</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={!showDate}>
                        <InputLabel >Năm </InputLabel>
                        <Autocomplete options={dataNamThongKe}
                            value={{ nam: formik.values?.request?.namThongKe ? formik.values.request?.namThongKe : 0, name: formik.values?.request?.tenNamThongKe ? formik.values.request?.tenNamThongKe : "" }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.namThongKe", newValue?.nam);
                                formik.setFieldValue("request.tenNamThongKe", newValue?.name);
                            }}

                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <TextField  {...params} variant="outlined" />
                                </div>
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={showDate}>
                        <InputLabel >Từ ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request.tuNgay > 0 ? new Date(formik.values.request.tuNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.tuNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={showDate}>
                        <InputLabel >Đến ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request?.denNgay > 0 ? new Date(formik.values.request?.denNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.denNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={3} className="grid-item">
                        <Button onClick={execute} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tổng hợp
                        </Button>
                    </Grid>

                    <Grid item xs={12} className="grid-item">
                        <Paper  >
                            <Card >
                                {/* <CardHeader title={"HỒ SƠ ĐÃ TIẾP NHẬN (" + dataTinhHinhXuLy.daTiepNhan + ")"} style={{ backgroundColor: "#f2f2f2", fontSize: "1em" }}></CardHeader> */}
                                <CardContent >
                                        <Pie 
                                        height={120}
                                        data={{
                                            labels: ["Đang hoạt động (" + data?.tongConHan + ")", "Sắp hết hạn (" + (data?.tongSapHetHan) + ")","Hết hạn (" + (data?.tongHetHan) + ")"],
                                            datasets: [
                                                {
                                                    data: [data?.tongConHan, data?.tongSapHetHan,data?.tongHetHan],
                                                    backgroundColor: ["#51A3A3", "#EAD94C", "#eb5050",]
                                                    //hoverBackgroundColor: chartColors
                                                }
                                            ]
                                        }} options={options} 
                                        />
                                  
                                </CardContent>
                            </Card>
                        </Paper>     
                    </Grid>
                </Grid>
            </form>
            <OverlayLoading />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div >
    )
}

