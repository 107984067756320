import { Box, Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Dispatch } from 'redux';
import { GIAY_PHEP_XAY_DUNG_INIT , GIAY_PHEP_XAY_DUNG_DA_CAP_DTO_INIT} from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { GiayPhepXayDungDaCapDTO, GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { LoadingAction } from '../../../../../redux/actions/applicationAction';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import ChiTietGiayPhepXayDungButtonComponent from './ChiTietGiayPhepXayDungButtonComponent';
import { CapPhepXayDungService } from '../../../../../api/CPXD/CapPhepXayDungService';
import ChiTietNoiDungGiayPhepXayDungComponent from './NoiDung/ChiTietNoiDungGiayPhepXayDungComponent';
import ChuDauTu from './ChuDauTu/ChuDauTu';
import GiayToVeDatDai from './GiayToDatDai/GiayToVeDatDai';
import ThongTinThamDinh from './ThongTinThamDinh/ThongTinThamDinh';
import ThongTinDeXuat from './ThongTinDeXuat/ThongTinDeXuat';
import LichSu from './LichSu/LichSu';
import TepTinDinhKem from './TepTin/TepTinDinhKem';
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
interface ChiTietGiayPhepXayDungProps {

}
const ChiTietGiayPhepXayDungComponent = (props: ChiTietGiayPhepXayDungProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation();
   /*  const [data, setData] = useState<GiayPhepXayDungDTO>({ ...GIAY_PHEP_XAY_DUNG_INIT }); */
    const [giayPhepXayDungDaCap, setGiayPhepXayDungDaCap] = useState<GiayPhepXayDungDaCapDTO>({ ...GIAY_PHEP_XAY_DUNG_DA_CAP_DTO_INIT });


    useEffect(() => {
        initData();
    }, []);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const initData = () => {
        let giayPhepXayDaCapId = location?.state?.giayPhepXayDaCapId;
        if (giayPhepXayDaCapId > 0) {
            new CapPhepXayDungService().getGiayPhepXayDungDaCapById(giayPhepXayDaCapId).then(res => {
                if (res?.data?.data?.giayPhepXayDungDTO !== null && res?.data?.data?.giayPhepXayDungDTO !== undefined) {
                    /* const dataNew = {
                        ...res?.data?.data?.giayPhepXayDungDTO,
                    } */
                    const giayPhepXayDungDaCap= {
                        ...res?.data?.data,
                    }
                    /* setData(dataNew); */
                    setGiayPhepXayDungDaCap(giayPhepXayDungDaCap);
                    
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setShowAlert(false)} severity={severity as Color}>{message}</Alert>
                    </Snackbar>

                    <Grid container style={{ float: 'left', paddingTop: '20px' }} item xs={12} className="grid-item" >
                        <div className="dash-title">
                            <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>{giayPhepXayDungDaCap?.giayPhepXayDungDTO?.tenLoaiGiayPhep}</h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChange} >
                                <CustomTab label="Nội dung giấy phép" />
                                <CustomTab label="Chủ đầu tư" />
                                <CustomTab label="Giấy tờ về đất đai" />
                                <CustomTab label="Thông tin thẩm định" />
                                <CustomTab label="Thông tin đề xuất" />
                                <CustomTab label="Lịch sử" />
                            </CustomTabs>
                        </Box>

                    </Grid>
                    {
                        indexTab === 0 &&
                        <TabPanel value={indexTab} index={0}>
                            <ChiTietNoiDungGiayPhepXayDungComponent data={giayPhepXayDungDaCap} />
                        </TabPanel>
                    }
                    {
                        indexTab === 1 &&
                        <TabPanel value={indexTab} index={1}>
                            <ChuDauTu data={giayPhepXayDungDaCap?.giayPhepXayDungDTO?.chuDauTuDTOs} />
                        </TabPanel>
                    }
                    {
                        indexTab === 2 &&
                        <TabPanel value={indexTab} index={2}>
                            <GiayToVeDatDai data={giayPhepXayDungDaCap?.giayPhepXayDungDTO?.giayToVeDat} />
                        </TabPanel>
                    }
                    {
                        indexTab === 3 &&
                        <TabPanel value={indexTab} index={3}>
                            <ThongTinThamDinh data ={giayPhepXayDungDaCap?.giayPhepXayDungDTO?.thongTinThamDinhDTOs}  />
                        </TabPanel>
                    }
                    {
                        indexTab === 4 &&
                        <TabPanel value={indexTab} index={4}>
                            <ThongTinDeXuat  
                               deXuat ={giayPhepXayDungDaCap?.giayPhepXayDungDTO?.deXuat} 
                               ketLuan = {giayPhepXayDungDaCap?.giayPhepXayDungDTO?.ketLuan}
                             />
                        </TabPanel>
                    }
                    {
                        indexTab === 5 &&
                        <TabPanel value={indexTab} index={5}>
                            <LichSu  
                              data ={giayPhepXayDungDaCap?.lichSuGiayPhepDTOs}
                              tenTrangThai = {giayPhepXayDungDaCap?.giayPhepXayDungDTO?.tenTrangThai}
                              tenLoaiGiayPhep = {giayPhepXayDungDaCap?.giayPhepXayDungDTO?.tenLoaiGiayPhep} 
                              />
                        </TabPanel>
                    }
                    <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <ChiTietGiayPhepXayDungButtonComponent />
                    </Grid>
                </Grid>

            }
        </>
        </div>
    )
}
export default ChiTietGiayPhepXayDungComponent;
