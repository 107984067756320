import React, { useEffect } from 'react';
import { Box, Button, Dialog, FormHelperText, Grid, InputBase, InputLabel } from '@material-ui/core';
import { ThongTinThamDinhDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { THONG_TIN_THAM_DINH_DTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import StringUtil from '../../../../utils/stringUtils';
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { IFileWithMeta } from 'react-dropzone-uploader';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import AXIOS_INSTANCE from '../../../../../api';
interface ThongTinThamDinhModalProps {
    data: ThongTinThamDinhModalDataProps;
    onToggle: () => void;
    onAddData: (value: ThongTinThamDinhDTO) => void;
    onUpdateData: (index: number, value: ThongTinThamDinhDTO) => void;

}
interface ThongTinThamDinhModalDataProps {
    data: ThongTinThamDinhDTO;
    show: boolean;
    indexEdit: number;
}

const ThongTinThamDinhModal = (props: ThongTinThamDinhModalProps) => {
    const [data, setData] = React.useState({ ...THONG_TIN_THAM_DINH_DTO_INIT });
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [indexEdit, setIndexEdit] = React.useState(-1);
    const { onToggle, onAddData, onUpdateData } = props;
    const fileServerService = new FileServerService();
    useEffect(() => {
        setData(props.data.data);
        setShow(props.data.show);
        setIndexEdit(props.data.indexEdit);
        setSubmit(false);
    }, [props.data]);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData(data => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }
    const handleSubmit = () => {
        setSubmit(true);
        if (validateFrom()) {
            if (indexEdit >= 0) {
                onUpdateData(indexEdit, data);
            } else {
                onAddData(data);
            }
            onToggle();
        }
    }

    const validateFrom = (): boolean => {
        let valid: boolean = true;
        if (StringUtil.isNullOrEmty(data.noiDung)) {
            valid = false;
        }
        return valid;
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = { ...data, files: mems };
        setData(dataNew);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = { ...data, files: mems };
        setData(dataNew);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        {indexEdit >= 0 ? 'CẬP NHẬT THÔNG TIN THẨM ĐỊNH' : 'THÊM MỚI THÔNG TIN THẨM ĐỊNH'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel required>Nội dung</InputLabel>
                                    <InputBase fullWidth
                                        multiline
                                        rows="10"
                                        name="noiDung"
                                        error={submit && StringUtil.isNullOrEmty(data.noiDung)}
                                        value={data.noiDung} onChange={handleChange} />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.noiDung) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập nội dung</FormHelperText>
                                    }
                                </Grid>
                                <DropZoneCustom
                                    acceptFiles={ACCEPT_FILES}
                                    attachFileServer={data.files}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/cpxd"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                                <Grid item xs={12} className="grid-item" >
                                    <Box marginTop="5px">
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={handleSubmit}>
                                            Lưu
                                    </Button>
                                        <Button variant="contained" color="default" disableElevation
                                            onClick={onToggle}>
                                            Đóng
                                    </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}
export default ThongTinThamDinhModal;