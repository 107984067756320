import { Box, FormControlLabel, Grid, InputBase, InputLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getIn, useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState, Dispatch } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useHistory } from "react-router-dom";
import AttachFileService from "../../../../../api/Common/attachFileService";
import { AttachFileDTO } from "../../../../../api/KhenThuong/models/attachFile";
import { HoSoKhenThuongDTO, MocThoiGianEnum } from "../../../../../api/KhenThuong/models/hoSoKhenThuongDTO";
import DropZoneCustomKT from "../../../../commons/components/CustomDropzone/dropzoneCustomeKT";
import { DANH_SACH_TEN_NGHIEP_VU, URL_UPLOAD_FILE_TO_ALFRESCO } from "../../../../Constants/constants";
import StringUtil from "../../../../utils/stringUtils";
import { initDefaultValue } from "../commonsPropsInterface";
import { InBieuMauButton } from "../inBieuMauButton";
import * as Yup from "yup";
const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

interface ThongTinChungPropsI {
    formValues: HoSoKhenThuongDTO;
    onSubmitFormThongTinChung: (thongTinChung: HoSoKhenThuongDTO) => void;
}

export const ThongTinChung = (props: ThongTinChungPropsI) => {
    const { onSubmitFormThongTinChung, formValues } = props;
 

    const [attachFileServer, setAttachFileServer] = useState<AttachFileDTO[]>([]);

    const history = useHistory();
    const validationSchema = Yup.object().shape({
        soQuyetDinh: Yup.string().required("Số quyết định bắt buộc nhập"),
        ngayQuyetDinh: Yup.date().nullable().required("Ngày quyết định bắt buộc nhập"),
    });
    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: false,
        onSubmit: () => submitData(),
        validationSchema
    });

    useEffect(() => {
        if (formValues.attachFiles) {
            setAttachFileServer([...formValues.attachFiles]);
        }
    }, [formValues.attachFiles]);

    useEffect(() => {
        formik.setValues(formValues);
        if (formValues.ngayToTrinh) {
            formik.setFieldValue("ngayToTrinh", new Date(formValues.ngayToTrinh));
        }
        formik.setFieldValue("tenThuTuc", DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_6.NAME);
        formik.setFieldValue("thuTucId", DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_6.ID);
    }, [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

    function submitData() {
        let hskt = { ...formik.values };
        hskt.attachFiles = [...attachFileServer];
        onSubmitFormThongTinChung(hskt);
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: AttachFileDTO) {
        if (item) {
            let newValues = attachFileServer.map((file: AttachFileDTO) => (file === item ? { ...file, isDeleted: true } : { ...file }));
            setAttachFileServer([...newValues]);
        } 
    }

    async function handleDownloadFile(file: AttachFileDTO) {
        if (file.motCua && StringUtil.isNotEmpty(file.url)){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }
            else
            {
                await new AttachFileService().downloadFile(file).then((res) => {
                    let blob = new Blob([res.data], { type: res.data.type });
                    let url = window.URL.createObjectURL(blob);
                    let link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${file.fileName}`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                });
            }
    
    }

    function handleReset() {
        formik.resetForm({ values: initDefaultValue });
        setAttachFileServer(attachFileServer.map((item: AttachFileDTO) => ({ ...item, isDeleted: true })));
    }

   
    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid item xs={6} className="grid-item">
                    <TextField
                        fullWidth
                        required
                        label={"Số quyết định"}
                        name="soQuyetDinh"
                        value={formik.values.soQuyetDinh}
                        onChange={formik.handleChange}
                        InputProps={{ disableUnderline: true }}
                        error={Boolean(getIn(formik.touched, "soQuyetDinh") && getIn(formik.errors, "soQuyetDinh"))}
                        FormHelperTextProps={{ className: "-error" }}
                        helperText={Boolean(getIn(formik.touched, "soQuyetDinh") && getIn(formik.errors, "soQuyetDinh")) && formik.errors?.soQuyetDinh}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <KeyboardDatePicker
                        required
                        label={"Ngày quyết định"}
                        value={formik.values.ngayQuyetDinh}
                        onChange={(date, _val) => {
                            formik.setFieldValue("ngayQuyetDinh", date);
                        }}
                        autoOk={true}
                        fullWidth
                        format="DD/MM/YYYY"
                        InputAdornmentProps={{ className: "button-date-picker" }}
                        variant="inline"
                        error={Boolean(getIn(formik.touched, "ngayQuyetDinh") && getIn(formik.errors, "ngayQuyetDinh"))}
                        InputProps={{ disableUnderline: true }}
                        invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                        FormHelperTextProps={{ className: "-error" }}
                        helperText={Boolean(getIn(formik.touched, "ngayQuyetDinh") && getIn(formik.errors, "ngayQuyetDinh")) && formik.errors?.ngayQuyetDinh}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Số tờ trình</InputLabel>
                    <InputBase
                        name="soToTrinh"
                        value={formik.values.soToTrinh}
                        onChange={formik.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel >Ngày tờ trình</InputLabel>
                    <KeyboardDatePicker
                        value={formik.values.ngayToTrinh}
                        onChange={(date, val) => formik.setFieldValue("ngayToTrinh", date)}
                        autoOk={true}
                        fullWidth
                        format="DD/MM/YYYY"
                        InputAdornmentProps={{ className: "button-date-picker" }}
                        variant="inline"
                        InputProps={{ disableUnderline: true }}
                        invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                    />
                </Grid>

                <Grid item xs={12} className="grid-item">
                    <InputLabel >Trích yếu</InputLabel>
                    <InputBase name="trichYeu" value={formik.values.trichYeu} onChange={formik.handleChange} fullWidth rows={2} multiline />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Căn cứ</InputLabel>
                    <InputBase name="canCu" value={formik.values.canCu} onChange={formik.handleChange} fullWidth rows={3} multiline />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Đề nghị</InputLabel>
                    <InputBase name="deNghi" value={formik.values.deNghi} onChange={formik.handleChange} fullWidth rows={3} multiline />
                </Grid>
                <Grid item xs={12} container className="gridItem">
                    <DropZoneCustomKT
                        acceptFiles={`${acceptFiles}`}
                        attachFileServer={attachFileServer}
                        url={URL_UPLOAD_FILE_TO_ALFRESCO.NGHIEPVU_SO_6}
                        handleChangeStatus={handleChangeStatus}
                        handleDeleteFileServer={handleDeleteFileServer}
                        handleDownloadFile={handleDownloadFile}
                    />
                </Grid>

                <Grid item xs={12} className="grid-item ">
                    <Box className="button-group" marginTop="5px">
                        <Button type="submit" variant="contained" color="primary" disableElevation>
                            Lưu
                        </Button>
                        {formValues.id && <InBieuMauButton idChuyenNganh={DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_6.ID} idHoSoKhenThuong={formValues.id} />}
                        <Button variant="contained" color="default" disableElevation onClick={handleReset}>
                            Xóa
                        </Button>
                        <Button variant="contained" color="primary" disableElevation onClick={() => history.go(-1)}>
                            Trở về
                        </Button>
                        {/* <Button variant="contained" color="primary" disableElevation>
                            Chuyển
                        </Button> */}
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};
