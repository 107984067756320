import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Dialog, FormHelperText, Grid, InputBase, InputLabel} from '@material-ui/core';
import {useFormik} from "formik";
import {DialogContent, DialogTitle} from "../../../../commons/components/dialog/dialogCommons";
import {ThuongNhanBanBuonDTO} from "../../../../../api/CPBLTL/model/TableBLThuocLa";
import * as Yup from 'yup';

interface ThuongNhanBanBuonModalProps {
    onSubmitData: (resp: ThuongNhanBanBuonDTO,  isEdit: Boolean) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    openEdit: boolean;
    itemEdit: ThuongNhanBanBuonDTO;

}

interface FormValues {
    requestDTO: ThuongNhanBanBuonDTO,
}

const ThuongNhanBanBuonModal = (props: ThuongNhanBanBuonModalProps) => {
    const autoCompleteRef = useRef<HTMLDivElement>(null);
    const {onSubmitData} = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    const initDefaultValue: FormValues = {
        requestDTO: {
            tenThuongNhan:'',
            diaChi:'',
            dienThoai:''
        } as ThuongNhanBanBuonDTO
    }

    const validateionSchema = Yup.object().shape({
        requestDTO: Yup.object().shape({
            tenThuongNhan: Yup.string().required('Vui lòng nhập tên!'),
            diaChi: Yup.string().required('Vui lòng nhập địa chỉ!'),
        })
    });

    const formik = useFormik({
        initialValues: initDefaultValue,
        validationSchema: validateionSchema,
        onSubmit: () => submitData(),
    });

    useEffect(() => {
        if(props.openEdit){
            formik.setValues({
                requestDTO: {
                    tenThuongNhan: props.itemEdit.tenThuongNhan,
                    diaChi: props.itemEdit.diaChi,
                    dienThoai: props.itemEdit.dienThoai,
                    ngayTao: props.itemEdit.ngayTao,
                } as ThuongNhanBanBuonDTO,
            });
        } else {
            formik.setValues({
                requestDTO: {
                    tenThuongNhan:'',
                    diaChi:'',
                    dienThoai:'',
                    ngayTao: 0
                } as ThuongNhanBanBuonDTO,
            });
        }
        setOpenEdit(props.openEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    const submitData = async () => {
        onSubmitData(formik.values.requestDTO, openEdit);
    }

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="sm" aria-labelledby="customized-dialog-title"
                    open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {'ĐƯỢC PHÉP MUA SẢN PHẨM CỦA THƯƠNG NHÂN'}
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel required>Tên</InputLabel>
                                    <InputBase fullWidth
                                               error={formik.touched.requestDTO?.tenThuongNhan && Boolean(formik.errors.requestDTO?.tenThuongNhan)}
                                               value={formik.values.requestDTO.tenThuongNhan} name="requestDTO.tenThuongNhan"
                                               onChange={formik.handleChange}/>
                                    {
                                        formik.touched.requestDTO?.tenThuongNhan && Boolean(formik.errors.requestDTO?.tenThuongNhan) &&
                                        <FormHelperText className="-error"
                                        >{formik.errors.requestDTO?.tenThuongNhan}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel required>Địa chỉ</InputLabel>
                                    <InputBase fullWidth multiline rows="3"
                                               error={formik.touched.requestDTO?.diaChi && Boolean(formik.errors.requestDTO?.diaChi)}
                                               value={formik.values.requestDTO.diaChi} name="requestDTO.diaChi"
                                               onChange={formik.handleChange}/>
                                    {
                                        formik.touched.requestDTO?.diaChi && Boolean(formik.errors.requestDTO?.diaChi) &&
                                        <FormHelperText className="-error"
                                        >{formik.errors.requestDTO?.diaChi}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel>Điện thoại</InputLabel>
                                    <InputBase fullWidth
                                               value={formik.values.requestDTO.dienThoai} name="requestDTO.dienThoai"
                                               onChange={formik.handleChange}/>
                                </Grid>
                                <Grid item xs={12} className="gridItem">
                                    <Box marginTop="5px">
                                        <Button type="submit" variant="contained" color="primary" disableElevation
                                                >
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="secondary" disableElevation
                                                onClick={() => {
                                                    if (autoCompleteRef.current) {
                                                        let clearButton = autoCompleteRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
                                                        clearButton.click();
                                                    }
                                                    formik.resetForm({values: initDefaultValue,});
                                                }}
                                        > Làm mới
                                        </Button>
                                        <Button variant="contained" color="primary" disableElevation
                                                onClick={props.onDialogClose}>
                                            Đóng
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default ThuongNhanBanBuonModal;
