import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Button, NativeSelect } from '@material-ui/core';
import { ICONS } from '../../../../Constants/constants';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { LoDatXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { iniNhomCaNhanDTO, NhomCaNhanDTO } from '../../../../../api/DKKD/model/hoSoThuLyDTO';
import NhomCaNhanModal from './NhomCaNhanModal';
import StringUtil from '../../../../utils/stringUtils';
import { DangKyKinhDoanhService } from '../../../../../api/DKKD/DangKyKinhDoanhService';
import { HoSoThuLyDTO_INIT } from '../../../../../api/DKKD/init/HoSoThuLyInit';
interface TableNhomCaNhanProps {
    data: NhomCaNhanDTO[];
    onUpdateData: (name: string, value: any) => void;
}
const TableNhomCaNhanComponent = (props: TableNhomCaNhanProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<NhomCaNhanDTO[]>(new Array<NhomCaNhanDTO>());
    const [dataNhomCaNhan, setDataNhomCaNhan] = useState<NhomCaNhanDTO>({...iniNhomCaNhanDTO});
    const [openModel, setOpenModel] = useState(false);
    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const [indexEdit, setIndexEdit] = useState(-1);
    const { onUpdateData } = props;
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data, dataNhomCaNhan]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = ((pageNumberNew - 1) * pageSizeNew);
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    }
    const clickButtonAdd = () => {
        setDataNhomCaNhan(
            {
                id: -1,
                tenThanhVien: '',
                tenQuocTich: 'Việt Nam',
                maQuocTich: 'VN',
                diaChiLienLac: '',
                diaChiThuongTru: '',
                soGiayToPhapLy: '',
                ghiChu: '',
                hoSoThuLyId: 0
            },
        );
        setOpenModel(true);
        //handleSearch(pageNumber, pageSize);
    }

    const handleAdd = (value: NhomCaNhanDTO) => {
        debugger
        const newData = [{ ...value }, ...props.data];
        // this.setDataParam('chuDauTuDTOs', this.state.data);
        onUpdateData('nhomCaNhans', newData);
        setOpenModel(!openModel);
    }

    const handleDrop = (index: number) => {
        let dataNew = [...props.data]  ;
        dataNew.splice(index, 1);
        onUpdateData('nhomCaNhans', dataNew);
       // handleSearch(pageNumber, pageSize);
    }

    const handleEdit = async (index: number) => {

        // await dangKyKinhDoanhService.getDataNhomCaNhans(index)
        //         .then((res) => {
        //             let dataNew = res?.data.data;
        //             if (dataNew !== null && dataNew !== undefined) {
        //                 setDataNhomCaNhan(dataNew);
        //             } 
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        let dataNew = [...props.data];
        onUpdateData('nhomCaNhan', dataNew[index]);
        setDataNhomCaNhan(dataNew[index]);
        setIndexEdit(index);
        setOpenModel(true);
    }

    const handleUpdate = (index: number, value: NhomCaNhanDTO) => {
        let data = [...props.data]  ;
        const newData = [...props.data?.slice(0, index), { ...props.data?[index]: value }, ...props.data?.slice(index + 1)]
        onUpdateData('nhomCaNhan', newData);
    }

    const handleToggle = () => {
        setIndexEdit(-1);
        setOpenModel(!openModel);
    }
    return (
        <div>
            <NhomCaNhanModal handleAdd={handleAdd} handleUpdate={handleUpdate} onToggle={handleToggle} show={openModel} data={dataNhomCaNhan} indexEdit={indexEdit}></NhomCaNhanModal>
            <table className="table table-bordered" style={{ fontSize: '14px' }}>
                <thead>
                    <tr className="table-secondary">
                        <th>STT</th>
                        <th>Tên thành viên</th>
                        <th>Quốc tịch</th>
                        <th>Địa chỉ liên lạc</th>
                        <th>Địa chỉ thường trú</th>
                        <th>Số giấy tờ pháp của cá nhân</th>
                        <th>Ghi chú</th>
                        <th style={{ width: "7%" }} className="text-center">Chỉnh sửa</th>
                        <th style={{ width: "5%" }} className="text-center">Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {dataPagi?.length > 0 ? dataPagi?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="cell-number">{((pageNumber - 1) * pageSize) + index + 1}</td>
                                <td>{item.tenThanhVien}</td>
                                <td>{item.tenQuocTich}</td>
                                <td>{item.diaChiLienLac}</td>
                                <td>{item.diaChiThuongTru}</td>
                                <td>{item.soGiayToPhapLy}</td>
                                <td>{item.ghiChu}</td>
                                <td align="center">
                                    <a onClick={(e) => handleEdit(((pageNumber - 1) * pageSize) + index)}><img src={ICONS.edit}></img></a>
                                </td>
                                <td align="center">
                                    <a onClick={(e) => handleDrop(((pageNumber - 1) * pageSize) + index)}><img src={ICONS.delete}></img></a>
                                </td>
                            </tr>
                        )
                    }) :
                        <tr>
                            <td className="text-center" colSpan={9}> Không có dữ liệu</td>
                        </tr>
                    }
                </tbody>
            </table>

            <div className="row">
                {/* pagination*/}
                <div className="col-md-9" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                    <Pagination count={Math.ceil(props.data?.length / pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleChangePage} />
                    <div>
                        <span> Số mẫu tin trên trang </span>
                        <NativeSelect value={pageSize} input={<CustomInput />} onChange={handleChangePageSize} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                        <span>Trang {pageNumber}  / {props.data?.length === 0 ? 1 : Math.ceil(props.data?.length / pageSize)}</span>
                    </div>
                </div>
                <div className="col-md-3 text-right" style={{ fontSize: '14px' }}>
                    <Button onClick={clickButtonAdd} variant="contained" color="primary" disableElevation>Thêm mới</Button>
                </div>
            </div>
        </div>
    )
}
export default TableNhomCaNhanComponent;