import {
    Button,
    FormControlLabel,
    Grid,
    InputBase,
    InputLabel,
    Radio,
    RadioGroup,
    Snackbar,
    TextField
} from "@material-ui/core";
import {useFormik} from "formik";
import React, {Dispatch, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {hideLoading, LoadingAction, showLoading} from "../../../../redux/actions/applicationAction";
import {OverlayLoading} from "../../../commons/components/overlayLoading/overlayLoading";
import {DanhSachHoSoCapSoGPKD} from "./danhSachHoSoCapSo";
import {CapSoGiayPhepKinhDoanhService, RequestDTO} from "../../../../api/DKKD/capSoGiayPhepKinhDoanhService";
import {HoSoThuLyDTO} from "../../../../api/DKKD/model/hoSoThuLyDTO";
import {BaseResponse} from "../../../../api/baseService";
import {AlertMessage, CustomAlert} from "../../../commons/components/alert";
import {PHUONG} from "../../../../api/QuanTri/DanhMucFakeService";
import {Autocomplete} from "@material-ui/lab";
import CapSoGiayPhepModal from "./CapSoGiayPhepModal";

export const HoSoCapSoGiayPhepKinhDoanh = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataList, setDataList] = useState<HoSoThuLyDTO[]>([]);
    const [itemSeleted, setItemSeleted] = useState<HoSoThuLyDTO>({} as HoSoThuLyDTO);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const formik = useFormik({
        initialValues: {
            request: {
            } as RequestDTO,
        },
        onSubmit: () => loadDataTable(),
    });

    const loadDataTable = async () => {
        let request = {
            ...formik.values.request,
            pageSize: size,
            pageNumber: page,
        } as RequestDTO;
        dispatchLoading(showLoading());
        await new CapSoGiayPhepKinhDoanhService().timKiem(request).then((p) => {
            setDataList(p.rows);
            setTotal(p.total);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    useEffect(() => {
        loadDataTable();
    }, [page, size])

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1);
        setSize(Number(event.target.value));
    }

    const showEditDialog = async (item: HoSoThuLyDTO) => {
        dispatchLoading(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatchLoading(hideLoading());
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleSubmitData = (resp: BaseResponse) => {
        if (resp.isSuccess) {
            setOpenDialog(false);
            setPage(1);
            loadDataTable();
            showMessageAlert('Cấp số giấy phép thành công!');
        } else {
            showMessageAlert(resp.message, false);
        }
    }
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>CẬP NHẬT SỐ GIẤY CHỨNG NHẬN ĐĂNG KÝ HỘ KINH DOANH</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel>Mã hồ sơ</InputLabel>
                        <InputBase fullWidth name="request.maHoSo"
                            value={formik.values.request.maHoSo} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel>Tên hộ kinh doanh</InputLabel>
                        <InputBase fullWidth name="request.tenHoKinhDoanh"
                            value={formik.values.request.tenHoKinhDoanh} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item custom__radio__button">
                        <InputLabel >Chủ thể thành lập hộ kinh doanh</InputLabel>
                        <RadioGroup row aria-label="position" name="request.loaiChuThe" onChange={formik.handleChange}>
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary" size="small" />}
                                label="Nhóm cá nhân"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio color="primary" size="small" />}
                                label="Cá nhân"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Phường/Xã/TT</InputLabel>
                        <Autocomplete id="maPhuong"
                            options={PHUONG}
                            value={{ ma: formik.values.request.maPhuong ? formik.values.request.maPhuong : "", ten: formik.values.request.tenPhuong ? formik.values.request.tenPhuong : "" }}
                            getOptionLabel={(option) => option.ten}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maPhuong", newValue.ma);
                                formik.setFieldValue("request.tenPhuong", newValue.ten);
                            }}

                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <TextField  {...params} variant="outlined" />
                                </div>
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className="button-group-center">
                        <Button onClick={loadDataTable} type="button" variant="contained" color="primary" disableElevation >
                            Tìm kiếm
                        </Button>
                    </Grid>

                    <Grid item xs={12} className="grid-item">
                        <DanhSachHoSoCapSoGPKD dataList={dataList}
                            pageProps={{
                                page: page, count: total, rowsPerPage: size,
                                onChangePage: handleOnChangePage,
                                onChangeRowsPerPage: handOnChangeRowsPerPage
                            }}
                            modalEdit={{ open: true, openEditDialog: showEditDialog }}
                        />
                    </Grid>
                    {
                        openDialog &&
                        <CapSoGiayPhepModal openDialog={openDialog} onDialogClose={() => { setOpenDialog(false) }} itemEdit={itemSeleted} onSubmitData={handleSubmitData} />
                    }
                </Grid>
            </form>
            <OverlayLoading />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div >
    )
}

