import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Box, Button, NativeSelect } from '@material-ui/core';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { DienTichKhuonVienDTO_INIT, HangMucXayDungDTO_INIT, THONG_TIN_THAM_DINH_DTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import HangMucModal from '../Modal/HangMucModal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DienTichKhuonVienDTO, HangMucXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import StringUtil from '../../../../utils/stringUtils';
import DienTichKhuonVienModal from '../Modal/DienTichKhuonVienModal';
interface DienTichKhuonVienTableProps {
    data: DienTichKhuonVienDTO[];
    onUpdateData: (name: string, value: any) => void;
}
 interface DienTichKhuonVienModalDataProps {
    data: DienTichKhuonVienDTO;
    show: boolean;
    indexEdit: number;
}
const DienTichKhuonVienTable = (props: DienTichKhuonVienTableProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<DienTichKhuonVienDTO[]>(new Array<DienTichKhuonVienDTO>());
    const [dataEdit, setDataEdit] = useState<DienTichKhuonVienModalDataProps>({ data: { ...DienTichKhuonVienDTO_INIT }, show: false, indexEdit: -1 });
    const { onUpdateData } = props;
    useEffect(() => {
        // handleSearch(pageNumber, pageSize);
        if (props.data && props.data.length>0){
            setDataEdit({ ...dataEdit, data: props.data[0], indexEdit: 0 });
        }

    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = ((pageNumberNew - 1) * pageSizeNew);
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    }
    const handleAdd = (rowNew: DienTichKhuonVienDTO) => {
        let dataNew = [...props.data];
        dataNew.unshift(rowNew);
        onUpdateData('dienTichKhuonVienDTOs', dataNew);
    }
    const handleDrop = (index: number) => {
        let dataNew = [...props.data];
        dataNew.splice(index, 1);
        onUpdateData('dienTichKhuonVienDTOs', dataNew);
    }
    const handleUpdate = (index: number, value: DienTichKhuonVienDTO) => {
        let dataNew = [...props.data];
        dataNew[index] = value;
        onUpdateData('dienTichKhuonVienDTOs', dataNew);
    }
    const handleToggle = () => {
        setDataEdit({ ...dataEdit, show: !dataEdit.show });
    }
    const handleEditToggle = (index: number) => {
        setDataEdit({ ...dataEdit, data: props.data[index], show: !dataEdit.show, indexEdit: index });
    }
    const handleAddToggle = () => {
        setDataEdit({ data: { ...DienTichKhuonVienDTO_INIT }, show: !dataEdit.show, indexEdit: -1 });
    }

    return (
        <div>
            <DienTichKhuonVienModal
                data={dataEdit}
                onToggle={handleToggle}
                onAddData={handleAdd}
                onUpdateData={handleUpdate}
            />
            {
                (props.data) && (props.data.length> 0) ? 
                (
                    <Button onClick={(e) => handleEditToggle(0)} startIcon={<AddCircleOutlineIcon color="primary" />}  disableRipple />
                ):
                (
                    <Button onClick={handleAddToggle} startIcon={<AddCircleOutlineIcon color="primary" />}  disableRipple />
                )
            }
        </div> 
    )
}
export default DienTichKhuonVienTable;