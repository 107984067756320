
import { Dispatch, useEffect, useState } from 'react';
import { Dialog, Box, Grid, InputLabel, FormHelperText, InputBase, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { DialogContent, DialogTitle } from '../../../commons/components/dialog/dialogCommons';
import StringUtil from '../../../utils/stringUtils';
import { HoSoThuLyBLR } from '../../../../api/CPBLR/model/HoSoThuLyBLR';
import { CAP_MOI_SX_RUOU_INIT } from '../../../../api/CPBLR/Init/CapMoiSXRuouInit';
import DropZoneCustom from '../../../commons/components/CustomDropzone/DropZoneCustom';
import { ACCEPT_FILES } from '../../../Constants/constants';
import AXIOS_INSTANCE from '../../../../api';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { FileEntry } from '../../../../api/Common/fileEntry';
import { FileServerService } from '../../../../api/Common/FileServerService';
import { createFileType, downLoadFile } from '../../../../api/Common/exportUtil';
import { CapSoDKRuouService } from '../../../../api/CPBLR/CapSoDKRuouService';
import { ConfirmationDialogRaw } from '../../../commons/components/dialog/confirmDialog';
import { LoaiGiayPhepEnum } from '../../../../api/loaiGiayPhep/loaiGiayPhepEnum';
import LayKetQuaGiayPhepService from '../../../../api/QuanTri/LayKetQuaGiayPhepService';
import { GiayPhepRequest } from '../../../../api/QuanTri/Model/MotCuaModel';
import { AlertMessage, CustomAlert } from '../../../commons/components/alert';
import { RssFeed } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { hideLoading, LoadingAction, showLoading } from '../../../../redux/actions/applicationAction';
import { KetQuaGiayPhepDTO } from '../../../../api/Common/KetQuaGiayPhepDTO';



interface CapSoGiayPhepXayDungModalProps {
    data: HoSoThuLyBLR;
    show: boolean;
    indexEdit: number;
    onToggle: () => void;
    onSubmit: (success: boolean, mess: string, index?: number, isWarning?: boolean) => void;

}
export const CapSoGiayPhepRuouModal = (props: CapSoGiayPhepXayDungModalProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [data, setData] = useState({ ...CAP_MOI_SX_RUOU_INIT } as HoSoThuLyBLR);
    const [show, setShow] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);
    const { onToggle, onSubmit } = props;
    const capSoDKRuouService = new CapSoDKRuouService();
    const fileServerService = new FileServerService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    useEffect(() => {
        loadDanhThongTin();
        setShow(props.show);
        setIndexEdit(props.indexEdit);
        setSubmit(false);
    }, [props.data, props.show, props.indexEdit]);

    const loadDanhThongTin = () => {
        const soGiayPhep = props.data.soGiayPhep !== undefined && props.data.soGiayPhep !== '' ? props.data.soGiayPhep : props.data.soGiayPhepCu;
        const dataNew = {
            ...props.data,
            soGiayPhep: soGiayPhep
        }
        if (dataNew.giayPhepId > 0) {
            capSoDKRuouService.danhSachFileCapSo(dataNew.giayPhepId).then(response => {
                if (response.data && response.data.data) {
                    setData({ ...dataNew, files: response.data.data });
                } else {
                    setData(dataNew);
                }
            }).catch(error => {
                console.log(error);
            });
        } else {
            setData(dataNew);
        }
    }

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";

        setData(data => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }

    const reset = () => {
        setOpenConfirm(true);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {

        setData(data => ({
            ...data,
            [filedName]: date?.toDate()?.getTime(),
        }));

    }
    const handleSubmit = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
            return;
        }
        if (!data.ngayCapPhep || data.ngayCapPhep === undefined || data.ngayCapPhep === 0) {
            return;
        }
        if (!data.ngayHetHanGP || data.ngayHetHanGP === undefined || data.ngayHetHanGP === 0) {
            return;
        }
        if (data.ngayCapPhep > data.ngayHetHanGP) {
            onSubmit(false, "Ngày cấp hết hạn phải sau ngày cấp phép");
            return;
        }
        if (indexEdit >= 0) {
            capSoDKRuouService.capSo(data).then(response => {
                if (response.data.error_code == 'SUCCESSFUL') {
                    onSubmit(true, "Yêu cầu thực hiện thành công", indexEdit);
                } else {
                    onSubmit(false, response.data.error_message);
                }
            }).catch(error => {
                onSubmit(false, "Yêu cầu thực hiện thất bại");
                console.log(error);

            });
        }
        
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        setData({ ...data, files: mems });
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && StringUtil.isNotEmpty(file.url)){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            setData({
                ...data,
                soGiayPhep: "",
                ngayCapPhep: 0,
                ngayHetHanGP: 0,
                ghiChu: "",
                nguoiKy: "",
                files: []
            });
        }
        setOpenConfirm(false);
    }

    const layKetQuaSerVice = new LayKetQuaGiayPhepService();
    const handleDongBoGiayPhep = async () => {
        dispatchLoading(showLoading());
        await layKetQuaSerVice.layKetQuaByMaHoSo(data.maHoSo).then((res) => {
            if (res !== null && res?.data?.error_code == "SUCCESSFUL" && res.data.data!==null) {
               
                if (res?.data?.data?.files && res?.data?.data?.files.length>0){
                    const files: FileEntry[] = [];
                    for (let i = 0 ; i<res?.data?.data?.files.length;i++){
                        const file : FileEntry = {
                            fileEntryId:0,
                            folderId: 0,
                            userId: "",
                            userName: "",
                            name: res.data.data.files[i].fileName,
                            createdDate: 0,
                            extension: "",
                            mimeType: "",
                            orginName: "",
                            description: "",
                            version: "",
                            docId: "",
                            fileParentId: 0,
                            motCua: true,
                            url: res.data.data.files[i].url,
                        }
                       files.push(file);
                    }
                    data.files= files;
                }
                data.soGiayPhep = res.data.data.soGiayPhep ? res.data.data.soGiayPhep : null;
                data.ngayCapPhep = res.data.data.ngayCap ? res.data.data.ngayCap : null;
                if (res.data.data.ngayHetHan!==null){
                    data.ngayHetHanGP = res.data.data.ngayHetHan ? res.data.data.ngayHetHan : null;
                }
                const ketQuaGiayPhep: KetQuaGiayPhepDTO={
                    soGiayPhep: res.data.data.soGiayPhep,
                    ngayHetHan : res.data.data.ngayHetHan,
                    files: res.data.data.files,
                    ngayCap: res.data.data.ngayCap,
                }
                data.ketQuaGiayPhep = ketQuaGiayPhep;
                onSubmit(true, res.data.error_message);
                console.log(data.ketQuaGiayPhep);
            }
            else {
                onSubmit(false, res.data.error_message, -1 , true);
            }
        }).finally(() => {
            dispatchLoading(hideLoading ());
        });
    }

    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                    <ConfirmationDialogRaw id="confirm-delete" keepMounted open={openConfirm}
                   
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        CẬP NHẬT VĂN BẢN
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={6} className="grid-item" >
                                    <InputLabel required>Số GPXD </InputLabel>
                                    <InputBase fullWidth
                                        error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                        value={data.soGiayPhep !== undefined && data.soGiayPhep !== '' ? data.soGiayPhep : data.soGiayPhepCu} name="soGiayPhep" onChange={handleChange} />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập số giấy phép</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Ngày cấp phép </InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            value={(data.ngayCapPhep !== null && data.ngayCapPhep !== undefined && data.ngayCapPhep !== 0) ? new Date(data.ngayCapPhep) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            error={submit && (data.ngayCapPhep === null || data.ngayCapPhep === 0 || data.ngayCapPhep === undefined)}
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}
                                        />
                                        {
                                            submit && (data.ngayCapPhep === null || data.ngayCapPhep === 0 || data.ngayCapPhep === undefined) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập ngày cấp phép</FormHelperText>
                                        }
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Ngày hết hạn </InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            value={(data.ngayHetHanGP !== null && data.ngayHetHanGP !== undefined && data.ngayHetHanGP !== 0) ? new Date(data.ngayHetHanGP) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            error={submit && (data.ngayHetHanGP === null || data.ngayHetHanGP === 0 || data.ngayHetHanGP === undefined)}
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                        />
                                        {
                                            submit && (data.ngayHetHanGP === null || data.ngayHetHanGP === 0 || data.ngayHetHanGP === undefined) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập ngày hết hạn</FormHelperText>
                                        }
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel >Ghi chú </InputLabel>
                                    <InputBase fullWidth
                                        multiline
                                        rows="8"
                                        name="ghiChu"
                                        value={data.ghiChu} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel >Người ký </InputLabel>
                                    <InputBase fullWidth
                                        value={data.nguoiKy} name="nguoiKy" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/ruou"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <Box marginTop="5px">
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={handleSubmit}>
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="secondary" disableElevation
                                            onClick={reset}>
                                            Xóa
                                        </Button>
                                        <Button variant="contained" color="default" disableElevation
                                            onClick={onToggle}>
                                            Đóng
                                        </Button>
                                        {data.soGiayPhep && data.maHoSo  ?
                                            <Button variant="outlined" disableElevation onClick={
                                                () => handleDongBoGiayPhep()
                                            }>
                                                Lấy kết quả
                                            </Button>
                                            : ""
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}



