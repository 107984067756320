import { Box, Button, Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Dispatch } from 'redux';
import { CapMoiSXRuouService } from '../../../../../api/CPBLR/CapMoiSXRuouService';
import { CAP_MOI_SX_RUOU_INIT, LichSuGiayPhepRuouDTO_INIT } from '../../../../../api/CPBLR/Init/CapMoiSXRuouInit';
import { GiayPhepSXRuou, HoSoThuLyBLR } from '../../../../../api/CPBLR/model/HoSoThuLyBLR';
import { LichSuGiayPhepRuouDTO } from '../../../../../api/CPBLR/model/LichSuGiayPhepRuouDTO';
import { QuanLyDKRuouService } from '../../../../../api/CPBLR/QuanLyDKRuouService';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import { LOAIBIENDONG_HOANTRA_HUY, TINHTRANG_HOATDONG } from '../../../../Constants/RuouConstants';
import StringUtil from '../../../../utils/stringUtils';
import ChiTietTepDinhKemGPRuou from '../ChiTiet/ChiTietTepDinhKemGPRuou';
import ChiTietHoanTraHuyThongTinGPRuou from './ChiTietHoanTraHuyThongTinGPRuou';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const HoanTraHuyGiayPhepRuouComponent = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [indexTab, setIndexTab] = useState(0);
    const [tabRequired, setTabRequired] = useState(-1);
    const location = useLocation();
    const [submit, setSubmit] = useState(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [giayPhep, setGiayPhep] = useState<GiayPhepSXRuou>({} as GiayPhepSXRuou);
    const [hoSo, setHoSo] = useState<HoSoThuLyBLR>({ ...CAP_MOI_SX_RUOU_INIT });
    const capMoiSXRuouService = new CapMoiSXRuouService();
    const quanLyDKRuouService = new QuanLyDKRuouService();

    const [lichSu, setLichSu] = useState<LichSuGiayPhepRuouDTO>({ ...LichSuGiayPhepRuouDTO_INIT });
    const history = useHistory();
    const [openResetData, setOpenResetData] = useState(false);
    useEffect(() => {
        setGiayPhep(location?.state);
        loadHoSo(location?.state.hoSoThuLyId);
        setLichSu({
            ...lichSu,
            maBienDong: LOAIBIENDONG_HOANTRA_HUY.ma,
            tenBienDong: LOAIBIENDONG_HOANTRA_HUY.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten,
            giayPhepId: location?.state.id
        });
    }, []);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const loadHoSo = (hoSoThuLyId: number) => {
        dispatchLoading(showLoading());
        capMoiSXRuouService.getByHoSoThuLyId(hoSoThuLyId).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setHoSo(res?.data?.data);
            }
            dispatchLoading(hideLoading());
        }).catch(error => {
            dispatchLoading(hideLoading());
            console.log(error);
        });

    }
    const setDataParam = (name: string, value: any) => {
        setLichSu({ ...lichSu, [name]: value });
    }
    const handleSubmit = () => {
        setSubmit(true);
        if (!(lichSu.ngayBienDong !== 0 && lichSu.ngayBienDong && lichSu.ngayBienDong !== null && lichSu.ngayBienDong !== undefined)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(lichSu.noiDung)) {
            setTabRequired(0);
            return;
        }

        dispatchLoading(showLoading());
        quanLyDKRuouService.capNhatTinhTrangGiayPhep(lichSu).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL') {
                    setLichSu(res.data.data);
                    setMessage('Yêu cầu thực hiện thành công');
                    setSeverity("success");
                    setShowAlert(true);
                } else {
                    setMessage(res.data.error_message);
                    setSeverity("error");
                    setShowAlert(true);
                }
            } else {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }
        }).catch(error => {
            setMessage('Yêu cầu thực hiện thất bại');
            setSeverity("error");
            setShowAlert(true);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });


    }
    const handleClickXoa = () => {
        setOpenResetData(!openResetData);
    }
    const handleConfirm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
        }
        setOpenResetData(!openResetData);
    };
    const resetData = () => {
        const dataNew: LichSuGiayPhepRuouDTO = { ...LichSuGiayPhepRuouDTO_INIT, id: lichSu.id, giayPhepId: lichSu.giayPhepId, hoSoThuLyId: lichSu.giayPhepId };
        setLichSu(dataNew);

    }
    return (

        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setShowAlert(false)} severity={severity as Color}>{message}</Alert>
                    </Snackbar>

                    <Grid container style={{ float: 'left', paddingTop: '20px' }} item xs={12} className="grid-item" >
                        <div className="dash-title">
                            <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>{giayPhep.loaiGiayPhep === 'BLR' ? 'THÔNG TIN CHI TIẾT GIẤY PHÉP BÁN LẺ RƯỢU' : 'THÔNG TIN CHI TIẾT GIẤY PHÉP SẢN XUẤT RƯỢU'}</h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChange} >
                                <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                <CustomTab label="Tập đính kèm" />
                            </CustomTabs>
                        </Box>

                    </Grid>
                    {
                        indexTab === 0 &&
                        <TabPanel value={indexTab} index={0}>
                            <ChiTietHoanTraHuyThongTinGPRuou submit={submit} onUpdateData={setDataParam} lichSu={lichSu} hoSo={hoSo} data={giayPhep} />
                        </TabPanel>
                    }
                    {
                        indexTab === 1 &&
                        <TabPanel value={indexTab} index={1}>
                            <ChiTietTepDinhKemGPRuou data={hoSo.files} />
                        </TabPanel>
                    }
                    <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <Button onClick={handleSubmit} variant="contained" color="primary" disableElevation> Lưu</Button>
                        <Button variant="contained" onClick={() => handleClickXoa()} color="secondary" disableElevation> Xóa</Button>
                        <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openResetData}
                            title="Xóa dữ liệu"
                            content="Bạn có đồng ý xóa?" onClose={handleConfirm}
                        />
                        <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
                    </Grid>
                </Grid>

            }
        </>
    )
}
export default HoanTraHuyGiayPhepRuouComponent;
