import MomentUtils from "@date-io/moment";
import { Box, Button, ClickAwayListener, Grid, Grow, InputBase, InputLabel, MenuItem, MenuList, Paper, Popper, Snackbar, TextField, Link, SvgIcon } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import { CapPhepXayDungService } from "../../../../api/CPXD/CapPhepXayDungService";
import { GIAY_PHEP_XAY_DUNG_DA_CAP_REQUEST_INIT } from "../../../../api/CPXD/init/CapPhepXayDungInit";
import { GiayPhepXayDungDaCapDTO, GiayPhepXayDungDaCapRequest } from "../../../../api/CPXD/model/CapPhepXayDungModel";
import { DanhMucService } from "../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../api/danhMuc/model/DanhMucDTO";
import { CO_QUAN, TINH_TP } from "../../../../api/QuanTri/DanhMucFakeService";
import { getObject } from "../../../../helpers/localStorage";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { ROUTE_CPXD_NHAPDAUKY } from "../../../../routes/route-cn-cpxd";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { LIST_LOAIGIAYPHEP, LIST_TINHTRANG } from "../../../Constants/CapPhepXayDungConstants";
import StringUtil from "../../../utils/stringUtils";
import DanhSachGiayPhepCPXD from "./DanhSachGiayPhepCPXD";

export const tinh = {
    maTinh: "92",
    tenTinh: "Thành phố Cần Thơ",
}

const QuanLyGiayPhepCPXD = () => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [dataList, setDataList] = useState<GiayPhepXayDungDaCapDTO[]>([]);
    const [totalData, setTotalData] = React.useState(0);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [search, setSeach] = useState<GiayPhepXayDungDaCapRequest>({ ...GIAY_PHEP_XAY_DUNG_DA_CAP_REQUEST_INIT, kyThongKe: 0, maHuyen: C_USER_DATA.maQuanHuyen, tenHuyen: C_USER_DATA.tenQuanHuyen });
    const capPhepXayDungService = new CapPhepXayDungService();
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const [openNhapDauKy, setOpenNhapDauKy] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const danhMucService = new DanhMucService();
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    useEffect(() => {
        loadDataTable(search);
        async function getDm() {
            await danhMucService.getDanhMucDiaChi(String(92)).then((resp) => {
                setQuans(resp);
            });
        }
        getDm();
    }, [])
    const loadPhuongs = async () => {
        if (StringUtil.isNotEmpty(search?.maHuyen)) {
            await danhMucService
                .getDanhMucDiaChi(search?.maHuyen)
                .then((res) => {
                    if (res && res.length > 0) {
                        setPhuongs(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...search, [nameValue]: value?.code, [nameText]: valueText, ['maPhuong']: '', ['tenPhuong']: '' };
        setSeach(dataNew);
    };

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...search, [nameValue]: value?.code, [nameText]: valueText };
        setSeach(dataNew);
    };

    const loadDataTable = async (requestBody: GiayPhepXayDungDaCapRequest) => {
        dispatchLoading(showLoading());
        await capPhepXayDungService.searchGiayPhepDaCap(requestBody).then((response) => {
            if (response.status == 200) {
                if (response.data.error_code === 'SUCCESSFUL') {
                    setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                    setDataList(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                }
            }
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const doSearch = () => {
        let searchNew = { ...search, pageNumber: 1 };
        setSeach(searchNew);
        loadDataTable(searchNew);
    }
    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageSize: Number(event.target.value), pageNumber: 1 };
        setSeach(searchNew);
        loadDataTable(searchNew);
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setSeach({
            ...search,
            [e.target.name]: value
        });
    }
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let searchNew = { ...search, [filedName]: date?.toDate()?.getTime() };
        setSeach(searchNew);
    }
    const handleCloseProper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenNhapDauKy(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenNhapDauKy(false);
        }
    }
    const handleOpenNhapDauKy = () => {
        setOpenNhapDauKy(!openNhapDauKy);
    };
    const triggerNalinkClick = (event: React.MouseEvent<HTMLElement>) => {
        event.currentTarget.getElementsByTagName('a')[0].click();
    };
    const downloadFile = async (extension: string) => {
        let request = {
            ...search,
            exportType: extension,
            exportFileName: 'danh_sach_giay_phep_cpxd'
        };
        dispatchLoading(showLoading());
        await new CapPhepXayDungService().downloadFileDanhSachCPXD(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'danh_sach_giay_phep_cpxd');
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>QUẢN LÝ GIẤY PHÉP XÂY DỰNG</span>
            </div>
            <Grid container>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Số giấy phép</InputLabel>
                    <InputBase fullWidth name="soGiayPhep"
                        value={search.soGiayPhep} onChange={handleChange} />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel >Loại giấy phép</InputLabel>
                    <Autocomplete
                        options={LIST_LOAIGIAYPHEP}
                        getOptionLabel={(option: any) => option.ten}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maLoaiGiayPhep', 'tenLoaiGiayPhep')}
                        renderInput={(params) => (
                            <TextField  {...params} variant="outlined" />
                        )}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel >Tình trạng</InputLabel>
                    <Autocomplete
                        options={LIST_TINHTRANG}
                        getOptionLabel={(option: any) => option.ten}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maTinhTrang', 'tenTinhTrang')}
                        renderInput={(params) => (
                            <TextField  {...params} variant="outlined" />
                        )}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Chủ đầu tư</InputLabel>
                    <InputBase fullWidth name="chuDauTu"
                        value={search.chuDauTu} onChange={handleChange} />
                </Grid>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Ngày cấp từ</InputLabel>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={(search.ngayCapTu !== 0 && search.ngayCapTu && search.ngayCapTu !== null && search.ngayCapTu !== undefined) ? new Date(search.ngayCapTu) : null}
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapTu')}

                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Ngày cấp đến</InputLabel>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={(search.ngayCapDen !== 0 && search.ngayCapDen && search.ngayCapDen !== null && search.ngayCapDen !== undefined) ? new Date(search.ngayCapDen) : null}
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapDen')}

                        />
                    </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Đơn vị cấp phép</InputLabel>
                    <Autocomplete
                        options={CO_QUAN}
                        getOptionLabel={(option: any) => option.ten}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maDonViCapPhep', 'tenDonViCapPhep')}
                        renderInput={(params) => (
                            <TextField  {...params} variant="outlined" />
                        )}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel >Tỉnh/Thành phố</InputLabel>
                    <Autocomplete
                        options={TINH_TP}
                        value={{ ma: tinh.maTinh ? tinh.maTinh : "", ten: tinh.tenTinh ? tinh.tenTinh : "" }}
                        getOptionLabel={(option: any) => option.ten}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maTinh', 'tenTinh')}
                        disabled={true}
                        renderInput={(params) => (
                            <TextField  {...params} variant="outlined" />
                        )}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel >Huyện/Thị/TP</InputLabel>
                    {
                        StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                        <Autocomplete id="maHuyen" options={quans}
                            value={{ code: search.maHuyen ? search.maHuyen : "", name: search.tenHuyen ? search.tenHuyen : "" }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                setSeach({
                                    ...search,
                                    maHuyen: newValue.code,
                                    tenHuyen: newValue.name,
                                    maPhuong: "",
                                    tenPhuong: ""
                                });
                            }}
                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <InputBase fullWidth {...params.inputProps} />
                                </div>
                            }}
                        />
                    }
                    {
                        !StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                        <InputBase disabled fullWidth value={C_USER_DATA.tenQuanHuyen} />
                    }
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel >Phường/Xã/TT</InputLabel>
                    <Autocomplete
                        options={phuongs}
                        onFocus={loadPhuongs}
                        noOptionsText={"Không có Phường/Xã/TT"}
                        getOptionLabel={(option) => option.name}
                        value={{ code: search.maPhuong, name: search.tenPhuong }}
                        onChange={(event: any, newValue: any) => {
                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                        }}
                        renderInput={(params) => (
                            <TextField  {...params} variant="outlined" />
                        )}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Đường, số nhà</InputLabel>
                    <InputBase fullWidth name="soNha"
                        value={search.soNha} onChange={handleChange} />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Số tờ</InputLabel>
                    <InputBase fullWidth name="soTo"
                        value={search.soTo} onChange={handleChange} />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>Số thửa</InputLabel>
                    <InputBase fullWidth name="soThua"
                        value={search.soThua} onChange={handleChange} />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel >Đơn vị tư vấn</InputLabel>
                    <InputBase fullWidth
                        name="soKyHieuBangVe"
                        value={search.soKyHieuBangVe} onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} className="grid-item" container direction="row" justify="flex-end" alignItems="center">
                    <Button onClick={doSearch} type="button" variant="contained" color="primary"
                        className="align-right" disableElevation >
                        Tìm kiếm
                    </Button>
                </Grid>
                <Grid item xs={6} className="grid-item" container direction="row" justify="flex-start" alignItems="center">
                    <Button type="button" variant="contained" color="primary" disableElevation ref={anchorRef} aria-haspopup="true"
                        aria-controls={openNhapDauKy ? 'menu-list-grow' : undefined} onClick={() => { handleOpenNhapDauKy() }}>
                        Nhập đầu kỳ
                    </Button>
                    <Popper open={openNhapDauKy} anchorEl={anchorRef.current} role={undefined} transition>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseProper}>
                                        <MenuList id="menu-list-grow" >
                                            {
                                                ROUTE_CPXD_NHAPDAUKY.map((item, k) =>
                                                    <MenuItem key={k}>
                                                        <NavLink style={{ width: '100%', textDecoration: 'none' }} exact to={{
                                                            pathname: item.duongDan,
                                                            state: { isNhapDauKy: true }
                                                        }}
                                                        >
                                                            <Box display="flex" alignItems="center">
                                                                <Box component="span" fontSize="15px"> {item.ten}</Box>
                                                            </Box>
                                                        </NavLink >
                                                    </MenuItem>
                                                )
                                            }
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
                <Grid item xs={12} className="grid-item" container justify="flex-end">
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('docx');
                    }}>
                        <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                        </SvgIcon>
                    </Link>
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('pdf');
                    }}>
                        <SvgIcon fontSize="large">
                            <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                        </SvgIcon>
                    </Link>
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('xlsx');
                    }}>
                        <SvgIcon fontSize="large">
                            <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                        </SvgIcon>
                    </Link>
                </Grid>
            </Grid>
            <DanhSachGiayPhepCPXD
                doSearch={doSearch}
                count={totalData}
                page={search.pageNumber}
                rowsPerPage={search.pageSize}
                onChangePage={handleOnChangePage}
                onChangeRowsPerPage={handOnChangeRowsPerPage}
                dataList={dataList}
            />
            <OverlayLoading />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div >
    )
}
export default QuanLyGiayPhepCPXD;