import { loadModules } from "esri-loader";
import React from "react";
import locatorImg from "../Assets/images/locator_1.png";
import locatorActive from "../Assets/images/locator_active.png";
import { MarkerDTO } from "./index";

export interface JsonLayerProps {
    view: any;
    map: any;
    arrMarker: MarkerDTO[];
    setLayer: (value: any) => void;
}

const JsonLayer: React.FC<JsonLayerProps> = ({ view, map, arrMarker, setLayer }) => {
    const [graphicLayers, setGraphicLayers] = React.useState<any>();

    React.useEffect(() => {
        loadModules([
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/geometry/Multipoint",
            "esri/layers/FeatureLayer",
            "esri/geometry/Point",
            "esri/widgets/Editor",
        ]).then(([Graphic, GraphicsLayer]) => {
            const graphicsLayer = new GraphicsLayer();
            map.add(graphicsLayer);
            setGraphicLayers(graphicsLayer);
            arrMarker.forEach((item) => {
                const pt = {
                    type: "point",
                    longitude: item.diaChiKinhDo, //check if the point is longitude and change accordingly
                    latitude: item.diaChiViDo, //check if the point is latitude and change accordingly
                };
                const popupTemplate = {
                    title: "{Name}",
                    content: item.fullDiaChi,
                };

                const attributes = {
                    Name: `${Math.round(Number(item.diaChiKinhDo) * 100000) / 100000}, ${Math.round(Number(item.diaChiViDo) * 100000) / 100000}`, // The name of the
                    Location: item.fullDiaChi, // The owner of the
                };

                const symbolPoint = {
                    type: "picture-marker", // autocasts as new PictureMarkerSymbol()
                    url: locatorImg,
                    width: "24px",
                    height: "35px",
                };
                const pointGraphic = new Graphic({
                    geometry: pt,
                    symbol: symbolPoint,
                    attributes: attributes,
                    popupTemplate: popupTemplate,
                });

                graphicsLayer.add(pointGraphic);
            });

            view.on("click", function (event: any) {
                view.hitTest(event).then(function (response: any) {
                    const results = response.results;
                    if (results.length > 0 && results[0].graphic) {
                        setLayer(results[0].graphic);
                    }
                });
            });
        });
    }, [map, arrMarker]);

    React.useEffect(() => {
        view.graphics.removeAll();
        view.popup.close();
        if (graphicLayers) {
            graphicLayers.removeAll();
        }
    }, [arrMarker]);

    React.useEffect(() => {
        if (view) {
            let pt = {
                latitude: 10.03572,
                longitude: 105.78712,
            };
            if (arrMarker.length === 0) {
                view.goTo({ geomery: pt, zoom: 12 });
            }
        }
    }, [arrMarker]);

    return null;
};

export default JsonLayer;
