import { Button, NativeSelect } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import 'toastr/build/toastr.min.css';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { ICONS } from '../../../../Constants/constants';
import NhaORiengLeChonTuKhoModal from '../Modal/NhaORiengLeChonTuKhoModal';
import NhaORiengLeChuDauTuModal from '../Modal/NhaORiengLeChuDauTuModal';


export default class NhaORiengLeChuDauTuComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 5,
            data: [],
            dataPagi: [],

            openModel: false,
            itemEdit: {},
            indexEdit: -1,

            openModelTuKho: false,
            itemEdit: {},
            indexEdit: -1,
        }
    }
    componentDidMount() {
        this.componentDidUpdate();
    }
    componentDidUpdate() {
        if (this.props.data != null && this.props.data !== undefined && this.props.data != this.state.data) {
            this.state.data = this.props.data;
            this.setState(this.state);
            this.handleSearch();
        }

    }
    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.pageNumber = valuePage;
        this.setState(this.state);
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.pageSize = value;
        this.state.pageNumber = 1;
        this.handleSearch();
    }
    handleSearch = () => {
        let offset = 0;
        if (this.state.pageNumber === 1) {
            offset = 0;
        } else if (this.state.pageNumber > 0 && this.state.pageSize > 0) {
            offset = ((this.state.pageNumber - 1) * this.state.pageSize);
        } else {
            offset = 0;
        }
        this.state.dataPagi = this.state.data?.slice(offset, this.state.pageSize + offset);
        this.setState(this.state);
    }
    //Modal child
    handleUpdate = (index, chuDauTu) => {
        this.state.data[index] = chuDauTu;
        this.props.onUpdateData('chuDauTuDTOs', this.state.data);
        this.handleSearch();
    }
    handleAdd = (chuDauTu) => {
        this.state.data.push(chuDauTu);
        this.props.onUpdateData('chuDauTuDTOs', this.state.data);
        this.handleSearch();
    }
    handleDrop = (index) => {
        if (this.state.data[index]?.id > 0) {
            // this.state.dataDrop.push(this.state.data[index]);
        }
        this.state.data.splice(index, 1);
        this.props.onUpdateData('chuDauTuDTOs', this.state.data);
        this.handleSearch();
    }
    handleToggle = () => {
        this.state.openModel = !this.state.openModel;
        this.setState(this.state);
    }

    handleToggleTuKho = () => {
        this.state.openModelTuKho = !this.state.openModelTuKho;
        this.setState(this.state);
    }
    handleEditToggle = (index) => {
        this.state.itemEdit = this.state.data[index];
        this.state.indexEdit = index;
        this.setState(this.state);
        this.handleToggle();
    }
    handleAddToggle = (index) => {
        this.state.itemEdit = {};
        this.state.indexEdit = -1;
        this.setState(this.state);
        this.handleToggle();
    }
    handleAddToggleChonTuKho = (index) => {
        this.state.itemEdit = {};
        this.state.indexEdit = -1;
        this.setState(this.state);
        this.handleToggleTuKho();
    }
    render() {
        return (
            <div style={{ width: '100%' }}>
                <table className="table table-bordered" style={{ fontSize: '14px' }}>
                    <thead>
                        <tr className="table-secondary">
                            <th style={{ width: "5%" }}>STT</th>
                            <th>Loại hình</th>
                            <th>Chủ đầu tư</th>
                            <th style={{ width: "5%" }} className="text-center">Sửa</th>
                            <th style={{ width: "5%" }} className="text-center">Xóa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dataPagi?.length > 0 ? this.state.dataPagi?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="cell-number">{((this.state.pageNumber - 1) * this.state.pageSize) + index + 1}</td>
                                    <td>{item.loaiDoiTuong == 1 ? 'Cá nhân' : (item.loaiDoiTuong == 2 ? 'Doanh nghiệp' : '')}</td>
                                    <td>{item.loaiDoiTuong == 1 ? item.hoTen : (item.loaiDoiTuong == 2 ? item.tenToChuc : '')}</td>
                                    {item.chonTuKho == 1 ? <td align="text-center"></td> : <td align="text-center"><a onClick={(e) => this.handleEditToggle(((this.state.pageNumber - 1) * this.state.pageSize) + index)}><img src={ICONS.edit}></img></a></td>}
                                    <td align="text-center"><a onClick={(e) => this.handleDrop(((this.state.pageNumber - 1) * this.state.pageSize) + index)}><img src={ICONS.delete}></img></a></td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <div className="row">
                    {/* pagination*/}
                    <div className="col-md-9" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                        <Pagination count={Math.ceil(this.state.data?.length / this.state.pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                        <div>
                            <span> Số mẫu tin trên trang </span>
                            <NativeSelect value={this.state.pageSize} input={<CustomInput />} onChange={this.handleChangePageSize} >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                            <span>Trang {this.state.pageNumber}  / {this.state.data?.length === 0 ? 1 : Math.ceil(this.state.data?.length / this.state.pageSize)}</span>
                        </div>
                    </div>
                    <div className="col-md-3 text-right" style={{ float: 'right', fontSize: '14px' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Button onClick={this.handleAddToggleChonTuKho} variant="contained" color="primary" disableElevation>Chọn từ kho</Button>
                                <Button onClick={this.handleAddToggle} variant="contained" color="primary" disableElevation>Thêm mới</Button>
                            </div>
                        </div>
                    </div>

                </div>
                <NhaORiengLeChuDauTuModal
                    indexEdit={this.state.indexEdit}
                    data={this.state.itemEdit}
                    onAddData={this.handleAdd}
                    onUpdateData={this.handleUpdate}
                    onToggle={this.handleToggle}
                    show={this.state.openModel} ></NhaORiengLeChuDauTuModal>
                <NhaORiengLeChonTuKhoModal
                    indexEdit={this.state.indexEdit}
                    data={this.state.itemEdit}
                    onAddData={this.handleAdd}
                    onUpdateData={this.handleUpdate}
                    onToggle={this.handleToggleTuKho}
                    show={this.state.openModelTuKho} ></NhaORiengLeChonTuKhoModal>
            </div>
        )
    }
}