import MomentUtils from '@date-io/moment';
import {
    Box,
    Button,
    Dialog,
    Grid,
    InputBase,
    InputLabel,
    Link,
    Snackbar, SvgIcon
} from '@material-ui/core';
import { Alert, Autocomplete, Color } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import 'toastr/build/toastr.min.css';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { KIEM_TRA_NGAN_CHAN_INIT, KIEM_TRA_NGAN_CHAN_REQUEST } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { KiemTraNganChanServices } from '../../../../../api/CPXD/KiemTraNganChanServices';
import { KiemTranNganChanDTO, KiemTranNganChanRequest } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { DanhMucDiaChiDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import StringUtil from '../../../../utils/stringUtils';
import TableKiemTraNganChans from '../table/TableKiemTraNganChans';
import CapMoiKiemTraNganChan from './CapMoiKiemTraNganChans';
interface ModalKiemTraNganChanProps {
    show: boolean;
    onToggle: () => void;

}
const ModalKiemTraNganChans = (props : ModalKiemTraNganChanProps) => {
    const [show, setShow] = React.useState(false);
    const [showAddPrevent, setShowAddPrevent] = React.useState(false);
    const [itemEdit, setItemEdit] = React.useState({...KIEM_TRA_NGAN_CHAN_INIT});
    const [search, setSeach] = useState({ ...KIEM_TRA_NGAN_CHAN_REQUEST});
    const { onToggle } = props;
    const [totalData, setTotalData] = React.useState(0);
    const [dataList, setDataList] = useState<KiemTranNganChanDTO[]>([]);
    const [id, setID] = useState(0);
    const kiemTraNganChanService = new KiemTraNganChanServices();
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success"
    });
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();  
    const [openResetData , setOpenResetData] = useState(false);
    const btnUploadRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        setShow(props.show);
        initData();
        loadQuans("92");
    }, [props.show]);

    const loadQuans = async (maThanhPho: string) => {
        await danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        
    };

    const initData = async () => { 
        loadDataTable(search);
    }

    const handleTogglePrevent = (isUpdate : boolean) => {
        if(isUpdate){
            setShowAddPrevent(!showAddPrevent);
        }else{
            setShowAddPrevent(!showAddPrevent);
            setItemEdit({...KIEM_TRA_NGAN_CHAN_INIT})
        }
    }
    const handleSubmit = (code : string) => {
        if(code === 'SUCCESSFUL'){
            setShowAddPrevent(!showAddPrevent);
            setAlert({
                showAlert: true,
                message: "Yêu cầu thực hiện thành công",
                severity: "success"
            });   
            loadDataTable(search);
        }
    }

    const handleOpenModalDL = (id: number)=> {
        setID(id);
        setOpenResetData(!openResetData);
    }
    const handleConfirm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            setOpenResetData(!openResetData);
            await kiemTraNganChanService.delete(id).then(res => {
                if (res != null) {
                    if (res.data.error_code === 'SUCCESSFUL' ) {
                        setAlert({
                            showAlert: true,
                            message: "Yêu cầu thực hiện thành công",
                            severity: "success"
                        });
                    } else {
                        setAlert({
                            showAlert: true,
                            message: "Yêu cầu thực hiện thất bại",
                            severity: "success"
                        });
                    }
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "success"
                    });
                }
            }).catch(error => {
            }).finally(() => {
                // dispatchLoading(hideLoading());
            });
            loadDataTable(search);    
        }
        setOpenResetData(false);
    };

    const handleEditItem =(data: KiemTranNganChanDTO) => {
        setItemEdit(data);
        setShowAddPrevent(true);
    }

    const handleChange = (e: any) => {
        setSeach(data => ({
            ...data,
            [e.target.name]:  e.target.value
        }));
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        setSeach(data => ({
            ...data,
            [filedName]: date?.toDate()?.getTime(),
        }));

    }

    const downloadFile = async (extension: string) => {
        const request = {
            ...search,
            exportType: extension,
            exportFileName: 'kiem_tra_ngan_chan'
        };
        dispatchLoading(showLoading());
        await kiemTraNganChanService.downloadFileKiemTraNganChan(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'kiem_tra_ngan_chan');
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

     /* Phân trang */
     const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageSize: Number(event.target.value), pageNumber: 1 };
        setSeach(searchNew);
        loadDataTable(searchNew);
    }
    const handleSearch = async () => {
        let searchNew = { ...search, pageNumber: 1 };
        await setSeach(searchNew);
        loadDataTable(searchNew);
    }

    const loadDataTable = async (requestBody: KiemTranNganChanRequest) => {
        dispatchLoading(showLoading());
        await kiemTraNganChanService.timKiem(requestBody).then((response) => {
            if (response.status == 200) {
                if (response.data.error_code === 'SUCCESSFUL') {
                    setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                    setDataList(response.data.data.content !== null && response.data.data.content !== undefined ? response.data.data.content : []);
                }
            }
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const handleChangeSelectQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...search, [nameValue]: value?.code, [nameText]: valueText,  ['maPhuong'] : '', ['tenPhuong'] : ''};
        setSeach(dataNew);
    };

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...search, [nameValue]: value?.code, [nameText]: valueText};
        setSeach(dataNew);
    };

    const loadPhuongs = async () => {
        if(StringUtil.isNotEmpty(search?.maHuyen)){
          await  danhMucService
          .getDanhMucDiaChi(search?.maHuyen)
          .then((res) => {
              if (res &&  res.length > 0) {
                  setPhuongs(res);
              }
          })
          .catch((error) => {
              console.log(error);
          });
      }  
  };

    return (
        <>
        <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
            <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                {'KIỂM TRA NGĂN CHẶN'}
            </DialogTitle>
            <DialogContent dividers>
                <Box padding={0}>
                    <Grid container>
                    <OverlayLoading />
                    <Snackbar open={alert.showAlert} onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>{alert.message}</Alert>
                    </Snackbar>
                        <Grid item xs={6} className="grid-item" >
                            <InputLabel >Chủ sử dụng</InputLabel>
                            <InputBase fullWidth value={search.chuSoHuu} name ="chuSoHuu"  onChange ={handleChange}
                                />
                        </Grid> 
                        <Grid item xs={6} className="grid-item" >
                            <InputLabel >Địa chỉ chi tiết</InputLabel>
                            <InputBase fullWidth value={search.diaChiCuThe} name="diaChiCuThe" onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6} className="grid-item" >
                                <InputLabel >Số tờ</InputLabel>
                                <InputBase fullWidth
                                    value={search.soTo} name="soTo" onChange={handleChange} />
                        </Grid>  
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Quận, huyện</InputLabel>
                                <Autocomplete id="maHuyen"
                                    options={quans}
                                    value={{ code: search.maHuyen ? search.maHuyen : "", name: search.tenHuyen ? search.tenHuyen : "" }}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectQuan(newValue, 'maHuyen', 'tenHuyen')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel  >Số thửa</InputLabel>
                                <InputBase fullWidth
                                    value={search.soThua} name="soThua" onChange={handleChange} 
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Phường xã</InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={phuongs}
                                    onFocus={loadPhuongs}
                                    value={{ code: search.maPhuong ? search.maPhuong : "", name: search.tenPhuong ? search.tenPhuong : "" }}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectPhuong(newValue, 'maPhuong', 'tenPhuong')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                            </Grid> 
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Ngày ngăn chặn từ</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={search.ngayNganChanTu && search.ngayNganChanTu !== 0 && search.ngayNganChanTu !== null && search.ngayNganChanTu !== undefined ? new Date(search.ngayNganChanTu) : null}
                                        format="DD/MM/YYYY"
                                        maxDate={new Date()}
                                        maxDateMessage={"Vui lòng nhập ngày cấp nhỏ hơn ngày hiện tại"}
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayNganChanTu')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid> 
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Ngày ngăn chặn đến</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={search.ngayNganChanDen && search.ngayNganChanDen !== 0 && search.ngayNganChanDen !== null && search.ngayNganChanDen !== undefined ? new Date(search.ngayNganChanDen) : null}
                                        format="DD/MM/YYYY"
                                        maxDateMessage={"Vui lòng nhập ngày cấp nhỏ hơn ngày hiện tại"}
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayNganChanDen')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid> 
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Số vào sổ </InputLabel>
                                <InputBase fullWidth
                                    value={search.soVaoSo} name="soVaoSo" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Công văn NC </InputLabel>
                                <InputBase fullWidth
                                    value={search.congVanNC} name="congVanNC" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Số giấy CN </InputLabel>
                                <InputBase fullWidth
                                    value={search.soCN} name="soCN" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >

                            </Grid>
                            <Grid item xs={6} className="grid-item" container direction="row" justify="flex-end" alignItems="center">
                                <Button onClick={handleSearch} type="button" variant="contained" color="primary"
                                    className="align-right" disableElevation >
                                    Tìm kiếm
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="grid-item" container direction="row" justify="flex-start" alignItems="center">
                                <Button  type="button" variant="contained" color="primary"
                                    className="align-right" disableElevation onClick={() =>handleTogglePrevent(false)} >
                                    Thêm mới
                                </Button>
                            </Grid>                
                            <Grid item xs={12} className="grid-item" container justify="flex-end">
                            <Link title="Tải xuống" href="#" onClick={() => {
                                downloadFile('docx');
                            }}>
                                <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                    <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                                </SvgIcon>
                            </Link>
                            <Link title="Tải xuống" href="#" onClick={() => {
                                downloadFile('pdf');
                            }}>
                                <SvgIcon fontSize="large">
                                    <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                                </SvgIcon>
                            </Link>
                            <Link title="Tải xuống" href="#" onClick={() => {
                                downloadFile('xlsx');
                            }}>
                                <SvgIcon fontSize="large">
                                    <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                                </SvgIcon>
                            </Link>
                        </Grid>   
                        <Grid item xs={12} className="grid-item">
                            <TableKiemTraNganChans
                                search = {handleSearch}  
                                count={totalData}
                                page={search.pageNumber}
                                rowsPerPage={search.pageSize}
                                onChangePage={handleOnChangePage}
                                onChangeRowsPerPage={handOnChangeRowsPerPage}
                                dataList={dataList}
                                onUpdate ={handleEditItem}
                                onDelete ={handleOpenModalDL}
                            />
                        </Grid>         
                    </Grid>   
                </Box>
                { openResetData && 
                    <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openResetData}
                        title="Xóa dữ liệu"
                        content="Bạn có đồng ý xóa?" onClose={handleConfirm}
                    />
                 }
            </DialogContent>    
        </Dialog>
        { showAddPrevent &&
            <CapMoiKiemTraNganChan 
             show={showAddPrevent} 
             onToggle = {handleTogglePrevent}  
             data={itemEdit} 
             addOrUpDate={handleSubmit}/>
        }

        
      </>  
    )


}

export default ModalKiemTraNganChans;
