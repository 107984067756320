import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { ThanhVien } from "../../../api/CongDan/model/ThongTinCongDan";
import { Cell, Row } from "../../commons/components/table/tableCommons";

interface IDataListProps {
    dataList: ThanhVien[];
}

export const DanhSachHoGiaDinh = (props: IDataListProps) => {
    const [rows, setRows] = useState<ThanhVien[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);

    useEffect(() => {
        setRows(props.dataList);
    }, [props.dataList]);

    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="35%">
                                Căn cước công dân 
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                               Thành viên
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                             (rows && rows.length > 0) ? rows.map((row, i) => (
                                <Row key={i}>
                                    <Cell scope="row" align="center">
                                        {i + 1}
                                    </Cell>
                                    <Cell align="left">{row.soDinhDanh}</Cell>
                                    <Cell align="left">{row?.thanhVien}</Cell>
                                </Row>
                               ))

                             :
                             <Row key={'empty'}>
                                 <Cell colSpan={3} scope="row" align="center">
                                     Không có dữ liệu
                             </Cell>
                             </Row>
                        }
                    </TableBody>
                </Table>
                {/* <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                <Pagination
                    count={totalPage}
                    page={props.pageProps.page}
                    defaultPage={1}
                    siblingCount={1}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    onChange={props.pageProps.onChangePage}
                />
                <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                    <Box component="span" paddingX="5px">
                        Số mẫu tin trên trang
                    </Box>
                    <NativeSelect input={<CustomInput />} value={props.pageProps.rowsPerPage} onChange={props.pageProps.onChangeRowsPerPage}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </NativeSelect>
                </Box>
                <Box component="span" paddingX="5px">
                    Mẫu tin {(props.pageProps.page - 1) * rowsPerPage + 1} -{" "}
                    {props.pageProps.page * rowsPerPage > props.pageProps.count ? props.pageProps.count : props.pageProps.page * rowsPerPage} /{" "}
                    {props.pageProps.count}
                </Box>
            </Box> */}
            </TableContainer>
        </>
    );
};