import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Dispatch, useRef, useState } from "react";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import {
    ROUTE_CPTL_CAPLAI,
    ROUTE_CPTL_CAPMOI,
    ROUTE_CPTL_CAPSUADOI,
    ROUTE_CPTL_DAUKY
} from "../../../../routes/route-cn-tl";
import { DanhSachGiayPhepBLThuocLaDTO } from "../../../../api/CPBLTL/model/DanhSachGiayPhepBLThuocLaDTO";
import { LOAIBIENDONG_CAPDOI, LOAIBIENDONG_CAPLAI, LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG, TINHTRANG_HUY, TINHTRANG_THUHOI } from "../../../Constants/ThuocLaConstants";
import StringUtil from "../../../utils/stringUtils";
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from "@material-ui/icons/Refresh";
import CloseIcon from '@material-ui/icons/Close';

export interface ThaoTacQuanLyBLThuocLaProps {
    row: DanhSachGiayPhepBLThuocLaDTO;
}

export const ThaoTacQuanLyBLThuocLa = (props: ThaoTacQuanLyBLThuocLaProps) => {

    const { row } = props;
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    let history = useHistory();

    const showHidePopperThaoTac = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(!open);
    }
    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const clickEdit = () => {
        // let duongDan = ROUTE_CPTL_DAUKY.duongDan;
        // if (row.maBienDong == LOAIBIENDONG_CAPMOI.ma && StringUtil.isNotEmpty(row.maHoSo)) {
        //     duongDan = ROUTE_CPTL_CAPMOI.duongDan;
        // } else if (row.maBienDong == LOAIBIENDONG_CAPLAI.ma) {
        //     duongDan = ROUTE_CPTL_CAPLAI.duongDan;
        // } else if (row.maBienDong == LOAIBIENDONG_CAPDOI.ma) {
        //     duongDan = ROUTE_CPTL_CAPSUADOI.duongDan;
        // }
        // history.push(duongDan, { ...row});

        let duongDan =  ROUTE_CPTL_DAUKY.duongDan;
        history.push(duongDan, { ...row});
    }

    return (
        <>
            <Button startIcon={<ListIcon />}
                size="small" color="primary" disableRipple
                ref={anchorRef} aria-haspopup="true"
                aria-controls={open ? 'menu-list-grow' : undefined}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => showHidePopperThaoTac(e)} >
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                    {
                                        row.maTinhTrang === TINHTRANG_HOATDONG.ma &&
                                        <>
                                            <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                <Button onClick={(e) => clickEdit()} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                                    Chỉnh sửa
                                                </Button>
                                            </MenuItem>
                                            <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                <Button onClick={(e) => history.push('/ban-le-thuoc-la/huy-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })} startIcon={<CloseIcon color="secondary" />} size="small" disableRipple>
                                                    Hủy
                                                </Button>
                                            </MenuItem>
                                            <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                <Button onClick={(e) => history.push('/ban-le-thuoc-la/thu-hoi-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })} startIcon={<RefreshIcon color="secondary" />} size="small" disableRipple>
                                                    Thu hồi
                                                </Button>
                                            </MenuItem>
                                        </>
                                    }
                                    {
                                        row.maTinhTrang === TINHTRANG_THUHOI.ma &&
                                        <>
                                            <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                <Button onClick={(e) => history.push('/ban-le-thuoc-la/hoan-tra-thu-hoi-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })} startIcon={<RefreshIcon color="primary" />} size="small" disableRipple>
                                                    Hoàn trả thu hồi
                                                </Button>
                                            </MenuItem>
                                            <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                <Button onClick={(e) => history.push('/ban-le-thuoc-la/thu-hoi-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                                    Cập nhật thu hồi
                                                </Button>
                                            </MenuItem>
                                        </>
                                    }
                                    {
                                        row.maTinhTrang === TINHTRANG_HUY.ma &&
                                        <>
                                            <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                <Button onClick={(e) => history.push('/ban-le-thuoc-la/hoan-tra-huy-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })} startIcon={<RefreshIcon color="primary" />} size="small" disableRipple>
                                                    Hoàn trả hủy
                                                </Button>
                                            </MenuItem>
                                            <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                <Button onClick={(e) => history.push('/ban-le-thuoc-la/huy-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                                    Cập nhật hủy
                                                </Button>
                                            </MenuItem>
                                        </>
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
