import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BrowserHistory } from 'history';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DanhMucService } from '../../../../api/danhMuc/danhMucService';
import { HoSoCuaToiMgtService } from '../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';
import { UserData } from '../../../../api/userMgt/userManagementService';
import { HoSoThuLyATTPDTO_INIT } from '../../../../api/VSATTP/Init/VSATTPModel_INIT';
import { HoSoThuLyATTPDTO } from '../../../../api/VSATTP/Model/VSATTPModel';
import { VSATTPService } from '../../../../api/VSATTP/Services/VSATTPService';
import { getObject } from '../../../../helpers/localStorage';
import { hideLoading, LoadingAction } from '../../../../redux/actions/applicationAction';
import { OverlayLoading } from '../../../commons/components/overlayLoading/overlayLoading';
import { CustomTab, CustomTabs } from '../../../commons/components/tabs';
import { LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from '../../../Constants/ATTPConstants';
import StringUtil from '../../../utils/stringUtils';
import { ThongTinChuyenNhanhUtil } from '../../../utils/thongTinChuyenNhanhUtil';
import { ThongTinChungVSATTPComponent } from './ThongTinChungATTPComponent';

type StateHoSo = {
    maThuTuc: string,
    maHoSo: string,
    maLoaiGiayPhep: string,
    tenLoaiGiayPhep: string
}

type GiayPhepATTPProps = {
    history: BrowserHistory<StateHoSo>
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function CapGiayPhepATTPComponent(props: GiayPhepATTPProps) {
    const { history } = props;
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const [indexTab, setIndexTab] = React.useState(0);
    const [ngayHenTra, setNgayHenTra] = useState<number>(0);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [vsattpData, setData] = React.useState<HoSoThuLyATTPDTO>({
        ...HoSoThuLyATTPDTO_INIT,
        maHuyenThiTpDSXKD: C_USER_DATA.maQuanHuyen,
        tenHuyenThiTpDSXKD: C_USER_DATA.tenQuanHuyen,
        maHuyenThiTp: C_USER_DATA.maQuanHuyen,
        tenHuyenThiTp: C_USER_DATA.tenQuanHuyen,
    });
    const vsattpService = new VSATTPService();
    const [submit, setSubmit] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    useEffect(() => {
        initData();
        vsattpData.ngayHenTra=ngayHenTra;
    }, []);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const initData = async () => {
        const maHoSoHistory = history?.location?.state?.maHoSo;
        const maThuTucHistory = history?.location?.state?.maThuTuc;
        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        if (!StringUtil.isNullOrEmty(C_USER_DATA.maCoQuan)) {
            hoSoCuaToiMgtService.chiTiet(C_USER_DATA.maCoQuan, history?.location?.state?.maHoSo).then((p) => {
                if (p.data && p.data.data) {
                    hoTenNguoiNop = p.data.data.nguoiNop;
                    soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
                    let date = new Date(p.data.data.ngayHenTra);
                    setNgayHenTra(date.getTime());
                   
                }
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
        if (maHoSoHistory === null || maHoSoHistory === undefined) {
            const dataNew: HoSoThuLyATTPDTO = {
                ...vsattpData,
                maHoSo: "",
                maThuTuc: "",
                maTrangThai: TINHTRANG_HOATDONG.ma,
                tenTrangThai: TINHTRANG_HOATDONG.ten,
                maBienDong: LOAIBIENDONG_CAPMOI.ma,
                tenBienDong: LOAIBIENDONG_CAPMOI.ten,
            };
            setData(dataNew);
        } else {
            vsattpService.getGiayPhepVSTTP(maHoSoHistory).then(async (res) => {
                if (res?.data?.error_code == 'SUCCESSFUL' && res?.data?.data !== null && res?.data?.data !== undefined) {
                    const dataNew: HoSoThuLyATTPDTO = {
                        ...res?.data?.data,
                        maHoSo: maHoSoHistory,
                        maThuTuc: maThuTucHistory,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    };
                    setData(dataNew);
                } else {
                    let maPhuong = "";
                    let tenPhuong = "";

                    let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHoSoHistory);

                    if (!StringUtil.isNullOrEmty(dataEform?.quanhuyen)) {
                        let dataQuan = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.xaphuong)) {
                        let dataHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                        maPhuong = dataHuyen[0].code;
                        tenPhuong = dataHuyen[0].name;
                    }
                    const dataNew = {
                        ...vsattpData,
                        maHoSo: maHoSoHistory,
                        maThuTuc: maThuTucHistory,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                        dienThoai: soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt,
                        tenChuCoSo: hoTenNguoiNop != "" ? hoTenNguoiNop : "",
                        maPhuongXaTT: maPhuong,
                        tenPhuongXaTT: tenPhuong,
                        fax: dataEform?.fax,
                        maSoGiayCNDKKDHKD: dataEform?.giaydkkd,
                        noiCapCNDKKDHKD: dataEform?.noicapdkkd,
                        ngayCapCNDKKDHKD: dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : 0,
                        ngayHenTra: ngayHenTra,
                    };
                    setData(dataNew);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    };
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const handleToggle = () => {
        setShowModal(!showModal);
    }
    const handleSubmit = (success: boolean, mess: string) => {
        if (success) {
            setMessage(mess);
            setSuccess(true);
            handleToggle();
        } else {
            setMessage(mess);
            setSuccess(false);
        }
        setShowAlert(true);

    }
    const resetForm = () => {
        let dataNew = {
            ...HoSoThuLyATTPDTO_INIT,
            id: vsattpData.id,
            giayPhepId: vsattpData.giayPhepId,
            maHoSo: vsattpData.maHoSo,
            maThuTuc: vsattpData.maThuTuc,
            maTrangThai: TINHTRANG_HOATDONG.ma,
            tenTrangThai: TINHTRANG_HOATDONG.ten,
            maBienDong: LOAIBIENDONG_CAPMOI.ma,
            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
        };
        setData(dataNew);
        setMessage("Đã xóa dư liệu");
        setSuccess(true);
        setShowAlert(true);


    }
    return (
        <>
            {<Paper square>
                <OverlayLoading />
                <Grid container>
                    {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                        <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                    <div className="dash-content">
                        <div className="dash-title">
                            <span>CẤP GIẤY CHỨNG NHẬN CƠ SỞ ĐỦ ĐIỀU KIỆN AN TOÀN THỰC PHẨM ĐỐI VỚI CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG: {vsattpData.maHoSo}</span>
                        </div>
                    </div>
                    {/* </h6>
                        <br></br>
                    </Grid> */}
                    <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setShowAlert(false)} severity={success ? 'success' : 'error'}>{message}</Alert>
                    </Snackbar>
                    <br />
                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChange}>
                                <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                        {indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <ThongTinChungVSATTPComponent
                                    vsattpData={vsattpData}
                                    submit={submit}
                                    resetForm={resetForm}
                                    handleSubmit={handleSubmit} 
                                    ngayHenTra={ngayHenTra}
                                    />
                            </TabPanel>}
                    </Grid>
                </Grid>
            </Paper>}
        </>
    );
}
export default CapGiayPhepATTPComponent;