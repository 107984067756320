import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { CustomTab, CustomTabs } from "../../../../App/commons/components/tabs";
import { ChangePassword } from "./changePassword";
import { PasswordHistory } from "./passwordHistory";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export interface PasswordManagementPropsI {
    hiddenHistory?: boolean;
}

export const PasswordManagement = (props: PasswordManagementPropsI) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Thay đổi mật khẩu</span>
            </div>
            <Paper square>
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                        <CustomTab label="Cập nhật mật khẩu" />
                        {!props.hiddenHistory && <CustomTab label="Lịch sử" />}

                    </CustomTabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ChangePassword />
                </TabPanel>
                {
                    !props.hiddenHistory &&
                    <TabPanel value={value} index={1}>
                        <PasswordHistory />
                    </TabPanel>
                }

            </Paper>
        </div>
    )
}