import MomentUtils from '@date-io/moment';
import {
    Box,
    Button,
    Dialog, FormHelperText, Grid,
    InputBase,
    InputLabel, Snackbar
} from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Dispatch } from 'redux';
import 'toastr/build/toastr.min.css';
import { XAC_MINH_THUC_DIA_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { KiemTraNganChanServices } from '../../../../../api/CPXD/KiemTraNganChanServices';
import { XacMinhThucDiaDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { XacMinhThucDiaService } from '../../../../../api/CPXD/XacMinhThucDiaService';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import StringUtil from '../../../../utils/stringUtils';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';

interface ModalKiemTraNganChanProps {
    show: boolean;
    onToggle: () => void;
    id: number;
}
const ModalXacMinhThucDia = (props : ModalKiemTraNganChanProps) => {
    const [show, setShow] = React.useState(false);
    const [showAddPrevent, setShowAddPrevent] = React.useState(false);
    const { onToggle } = props;
    const [id, setID] = useState(0);
    const kiemTraNganChanService = new KiemTraNganChanServices();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success"
    });
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();  
    const [openResetData , setOpenResetData] = useState(false);
    const btnUploadRef = useRef<HTMLSpanElement>(null);
    const [data, setData] = useState<XacMinhThucDiaDTO>({ ...XAC_MINH_THUC_DIA_INIT });
    const location = useLocation();
    const xacMinhThucDiaService = new XacMinhThucDiaService();
    const [submit, setSubmit] = useState(false);
    useEffect(() => {
        setShow(props.show);
        initData();

    }, [props.show]);
    const initData = async () => {
        // let maHoSo =  location?.state?.maHoSo;
        let ngayHenTra = location?.state?.ngayHenTra;
        xacMinhThucDiaService.getById(props.id).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.data.id > 0) {
                const dataNew = {
                    ...res?.data?.data,
                    ngayNop : ngayHenTra
                }
                setData(dataNew);
            }else{
                const dataNew = {
                    ...XAC_MINH_THUC_DIA_INIT,
                    ngayNop : ngayHenTra
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const handleSubmit = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.hoTen)) {
            return;
        }
        dispatchLoading(showLoading());    
        xacMinhThucDiaService.insertOrUpdate(data).then(res => {
            let dataNew = {
                ...data,
                id: parseInt(res.data.data.id),
            }
            setData(dataNew);
            setAlert({
                showAlert: true,
                message: "Yêu cầu thực hiện thành công",
                severity: "success"
            });  
        }).catch(error => {
            setAlert({
                showAlert: true,
                message: "Yêu cầu thực hiện thất bại",
                severity: "success"
            });  
        }).finally(() => {
            dispatchLoading(hideLoading());
        });   
    }

    const handleOpenModalDL = (id: number)=> {
        setID(id);
        setOpenResetData(!openResetData);
    }

    const handleReset = ()=> {
        const dataNew = {
            ...XAC_MINH_THUC_DIA_INIT,
            maHoSo: location?.state?.maHoSo,
            ngayNop : location?.state?.ngayHenTra
        }
        setData(dataNew);
        setSubmit(false);
    }

    const handleConfirm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleReset();
        }
        setOpenResetData(false);
    };


    const handleChange = (e: any) => {
        setData(data => ({
            ...data,
            [e.target.name]:  e.target.value
        }));
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        setData(data => ({
            ...data,
            [filedName]: date?.toDate()?.getTime(),
        }));

    }

    const handleClickXoa = () => {
        setOpenResetData(!openResetData);
    }

    return (
        <>
        <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
            <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                {'XÁC MINH THỰC ĐỊA'}
            </DialogTitle>
            <DialogContent dividers>
                <Box padding={0}>
                    <Grid container>
                    <OverlayLoading />
                    <Snackbar open={alert.showAlert} onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>{alert.message}</Alert>
                    </Snackbar>
                    <Grid container spacing={2}>
                        <Grid item xs={2} className="grid-item" container>
                            <InputLabel >Mã hồ sơ</InputLabel>
                        </Grid> 
                        <Grid item xs={4} className="grid-item"   >
                            <InputLabel className="detail-label" style={{color: "red"}}>{data?.maHoSo}</InputLabel>
                        </Grid>   
                        <Grid item xs={2} className="grid-item" >
                            <InputLabel >Ngày nộp</InputLabel>
                            
                        </Grid>  
                        <Grid item xs={4} className="grid-item"   >
                            <InputLabel  style={{color: "red"}} className="detail-label">{(data.ngayNop && data.ngayNop !== undefined && data.ngayNop !== null) ? moment(new Date(data.ngayNop)).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>   
                        <Grid item xs={6} className="grid-item" >
                           
                           <InputLabel required>Họ và tên</InputLabel>
                           <InputBase fullWidth
                                value={data?.hoTen} name="hoTen" onChange={handleChange} />
                              {
                                submit && StringUtil.isNullOrEmty(data?.hoTen) &&
                                <FormHelperText className="-error"  >Vui lòng nhập Họ và tên</FormHelperText>
                            }
                        </Grid> 
                        <Grid item xs={6} className="grid-item"   >
                            <InputLabel >Ngày yêu cầu</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={(data.ngayYeuCau !== 0 && data.ngayYeuCau && data.ngayYeuCau !== null && data.ngayYeuCau !== undefined) ? new Date(data.ngayYeuCau) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayYeuCau')}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>  
                        <Grid item xs={12} className="grid-item" >
                            <InputLabel  >Địa chỉ thường trú</InputLabel>
                            <InputBase fullWidth
                                value={data?.diaChiThuongTru} name="diaChiThuongTru" rows="2"
                                 onChange={handleChange} 
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item" >
                            <InputLabel  >Địa chỉ đăng ký</InputLabel>
                            <InputBase fullWidth
                                value={data?.diaChiDangKy} name="diaChiDangKy" rows="2"
                                 onChange={handleChange} 
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item" >
                            <InputLabel  >Về việc</InputLabel>
                            <InputBase fullWidth
                                value={data?.veViec} name="veViec" rows="4" multiline
                               onChange={handleChange} 
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item" >
                            <InputLabel  >Nội dung yêu cầu</InputLabel>
                            <InputBase fullWidth
                                value={data?.noiDungYeuCau} name="noiDungYeuCau" rows="4" multiline
                               onChange={handleChange} 
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item" >
                            <InputLabel  >Bản vẽ thiết kế</InputLabel>
                            <InputBase fullWidth
                                value={data?.banVeThietKe} name="banVeThietKe" rows="4" multiline
                               onChange={handleChange} 
                            />
                        </Grid>
                        <Grid item xs={12} className="grid-item" >
                            <InputLabel  >Các vấn đề khác</InputLabel>
                            <InputBase fullWidth
                                value={data?.cacVanDeKhac} name="cacVanDeKhac" rows="4" multiline
                               onChange={handleChange} 
                            />
                        </Grid>
                        <Grid item xs={6} className="grid-item" >
                            <InputLabel >Người ký</InputLabel>
                            <InputBase fullWidth
                                value={data?.nguoiKy} name="nguoiKy" onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6} className="grid-item" >
                            <InputLabel >Ngày ký</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={(data.ngayKy !== 0 && data.ngayKy && data.ngayKy !== null && data.ngayKy !== undefined) ? new Date(data.ngayKy) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayKy')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid> 
                    </Grid>    
                </Grid> 
                <Grid container style={{ float: 'left' }} item xs={12} className="grid-item"  justify="center">
                    <Box marginTop="10px">
                        <Button  className="btn-group-thuly" variant="contained" color="primary" disableElevation onClick={() => handleSubmit()} >Lưu</Button>
                        <Button  className="btn-group-thuly" variant="contained" color="secondary" disableElevation onClick={() => handleClickXoa()} > Xóa</Button>
                    </Box>
                </Grid> 
                </Box>

                { openResetData && 
                    <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openResetData}
                        title="Xóa dữ liệu"
                        content="Bạn có đồng ý xóa?" onClose={handleConfirm}
                    />
                 }
                
            </DialogContent>    
        </Dialog>
      </>  
    )


}

export default ModalXacMinhThucDia;
