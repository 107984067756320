import { Box, Button, Checkbox, FormControlLabel, FormHelperText, Grid, InputBase, InputLabel, NativeSelect, TextField } from "@material-ui/core";
import { getIn, useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Type } from "../../../../../../api/KhenThuong/danhMuc/danhMucKhenThuong";
import { KhenThuongTapTheDTO, LoaiKhenThuong, SubKhenThuongDTO, TableKhenThuong } from "../../../../../../api/KhenThuong/models/khenThuongDTO";
import { useDanhMucKhenThuong } from "../../../../../../hooks/useDanhMucKhenThuong";
import { useGetSubDSKhenThuong } from "../../../../../../hooks/useGetSubKhenThuong";
import { useLoaiHinhKhenThuong } from "../../../../../../hooks/useLoaiHinhKhenThuong";
import DialogKhenThuong from "../../../../../commons/components/dialogKhenThuong/dialogKhenThuong";
import { CustomNativeSelect } from "../../../../../commons/components/input/CustomNativeSelect";
import { ColumnTable, CommonTableChuyenNganh, TypeCell } from "../../../../../commons/components/table/commonTableChuyenNganh";
import { URL_UPLOAD_FILE_TO_ALFRESCO } from "../../../../../Constants/constants";
import StringUtil from "../../../../../utils/stringUtils";

interface EditFormKhenThuongCaNhanPropsI {
    onSave: (data: KhenThuongTapTheDTO) => void;
    onDoBack?: () => void;
    dataKhenThuong: KhenThuongTapTheDTO;
}

interface FormValues {
    formDTO: KhenThuongTapTheDTO;
}

export const EditFormKhenThuongTapThe = (props: EditFormKhenThuongCaNhanPropsI) => {
    const [rows, setRows] = useState<TableKhenThuong[]>([]);
    const [itemEdit, setItemEdit] = useState<SubKhenThuongDTO | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const { onDoBack, onSave, dataKhenThuong } = props;
    const dmLoaiHinh = useLoaiHinhKhenThuong(false);

    const dmHinhThuc = useDanhMucKhenThuong(Type.HINH_THUC);
    const dmDanhHieu = useDanhMucKhenThuong(Type.DANH_HIEU);
    const dataSubKhenThuong = useGetSubDSKhenThuong(props.dataKhenThuong.id);

    useEffect(() => {
        setRows((prevState) => [...dataSubKhenThuong]);
    }, [props.dataKhenThuong.id, dataSubKhenThuong]);

    const initDefaultValue: FormValues = {
        formDTO: dataKhenThuong,
    };

    const validateionSchema = Yup.object().shape({
        formDTO: Yup.object({
            loaiHinhKhenThuongId: Yup.string().required("loại hình khen thưởng bắt buộc nhập"),
            tenTapThe: Yup.string().required("Tên tập thể bắt buộc nhập"),
        }),
    });

    const formik = useFormik({
        initialValues: initDefaultValue,
        enableReinitialize: false,
        validationSchema: validateionSchema,
        onSubmit: onSubmitData,
    });

    // function onSubmitData() {
    //     const newRows = rows.map((item) => (item.ngayQuyetDinh ? { ...item, ngayQuyetDinh: new Date(item.ngayQuyetDinh) } : { ...item }));
    //     if (formik.values.formDTO.soTien) {
    //         formik.values.formDTO.soTien = formik.values.formDTO.soTien.replaceAll(/,/g, ""); 
    //     }
    //     onSave({
    //         ...formik.values.formDTO,
    //         subsDTO: [...newRows],
    //     });
    // }

    function onSubmitData() {
        onSave(formik.values.formDTO);
    }

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
        setItemEdit(null);
    }

    function handleAddTableKT(data: SubKhenThuongDTO) {
        setRows((prevState) => [...prevState, { ...data }]);
    }

    function handleDeleteKT(data: SubKhenThuongDTO) {
        const idx = rows.indexOf(data);
        setRows((prevState) => [...rows.slice(0, idx), ...rows.slice(idx + 1)]);
    }

    function handleEditKT(data: SubKhenThuongDTO) {
        setItemEdit(data);
        setOpenDialog(true);
    }

    function handleSubmitEdit(data: SubKhenThuongDTO, edit: SubKhenThuongDTO) {
        const idx = rows.indexOf(edit);
        const itemEdit = { ...rows[idx] };
        if (data.ngayQuyetDinh) {
            data.ngayQuyetDinh = new Date(data.ngayQuyetDinh);
        }
        setRows((prevState) => [...rows.slice(0, idx), { ...itemEdit, ...data }, ...rows.slice(idx + 1)]);
    }

    const columns: ColumnTable<TableKhenThuong>[] = [
        { title: "Danh hiệu thi đua", field: "danhHieuThiDuaString", width: "20%" },
        { title: "Hình thức khen thưởng", field: "hinhThucKhenThuongString", width: "20%" },
        { title: "Số quyết định", field: "soQuyetDinh", width: "10%" },
        { title: "Ngày quyết định", field: "ngayQuyetDinh", width: "10%", type: TypeCell.DATE },
        { title: "Cơ quan ban hành", field: "coQuanBanHanh", width: "25%" },
        { title: "Tập đính kèm", field: "attachFiles", width: "5%", type: TypeCell.FILE },
    ];

    return (
        <>
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel required >
                            Loại hình khen thưởng
                        </InputLabel>
                        <NativeSelect
                            value={formik.values.formDTO.loaiHinhKhenThuongId}
                            name="formDTO.loaiHinhKhenThuongId"
                            onChange={(e) => {
                                formik.setFieldValue("formDTO.loaiHinhKhenThuongId", e.target.value);
                                formik.setFieldValue("formDTO.loaiHinhKhenThuongString", e.target.options[e.target.selectedIndex].text);
                            }}
                            error={Boolean(getIn(formik.touched, "formDTO.loaiHinhKhenThuongId") && getIn(formik.errors, "formDTO.loaiHinhKhenThuongId"))}
                            input={<InputBase fullWidth  />}
                        >
                            <option value={""}>Chọn loại hình khen thưởng</option>
                            {dmLoaiHinh &&
                                dmLoaiHinh.map((item, i) => (
                                    <option key={i} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}

                        </NativeSelect>
                        {Boolean(getIn(formik.touched, "formDTO.loaiHinhKhenThuongId") && getIn(formik.errors, "formDTO.loaiHinhKhenThuongId")) && (
                            <FormHelperText className="-error" >
                                {formik.errors.formDTO?.loaiHinhKhenThuongId}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <TextField
                            fullWidth
                            required
                            label={"Tên tập thể"}
                            name="formDTO.tenTapThe"
                            value={formik.values.formDTO.tenTapThe}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                            error={Boolean(getIn(formik.touched, "formDTO.tenTapThe") && getIn(formik.errors, "formDTO.tenTapThe"))}
                            FormHelperTextProps={{ className: "-error" }}
                            helperText={Boolean(getIn(formik.touched, "formDTO.tenTapThe") && getIn(formik.errors, "formDTO.tenTapThe")) && formik.errors.formDTO?.tenTapThe}
                        />
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Địa chỉ"}
                            name="formDTO.diaChi"
                            value={formik.values.formDTO.diaChi}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                            error={Boolean(getIn(formik.touched, "formDTO.diaChi") && getIn(formik.errors, "formDTO.diaChi"))}
                            FormHelperTextProps={{ className: "-error" }}
                            helperText={Boolean(getIn(formik.touched, "formDTO.diaChi") && getIn(formik.errors, "formDTO.diaChi")) && formik.errors.formDTO?.diaChi}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Số điện thoại"}
                            name="formDTO.soDienThoai"
                            value={formik.values.formDTO.soDienThoai}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Fax"}
                            name="formDTO.fax"
                            value={formik.values.formDTO.fax}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Email"}
                            name="formDTO.email"
                            value={formik.values.formDTO.email}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Website"}
                            name="formDTO.webSite"
                            value={formik.values.formDTO.webSite}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Tổng số cán bộ"}
                            name="formDTO.tongSoCanBo"
                            value={formik.values.formDTO.tongSoCanBo}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Tổng số công chức"}
                            name="formDTO.tongSoCongChuc"
                            value={formik.values.formDTO.tongSoCongChuc}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Tổng số viên chức"}
                            name="formDTO.tongSoVienChuc"
                            value={formik.values.formDTO.tongSoVienChuc}
                            onChange={formik.handleChange}
                            
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <FormControlLabel
                            style={{ paddingTop: 25 }}
                            label={<span className="label-checkbox">Doanh nghiệp</span>}
                            control={<Checkbox checked={formik.values.formDTO.doanhNghiep} name="formDTO.doanhNghiep" color="default" size="small" onChange={formik.handleChange} />}
                        />
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel >Cơ cấu tổ chức</InputLabel>
                        <InputBase name="formDTO.coCauToChuc" value={formik.values.formDTO.coCauToChuc} onChange={formik.handleChange} fullWidth rows={3} multiline  />
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel >Chức năng, nhiệm vụ được giao</InputLabel>
                        <InputBase name="formDTO.chucNangNhiemVu" value={formik.values.formDTO.chucNangNhiemVu} onChange={formik.handleChange} fullWidth rows={3} multiline  />
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel >Hình thức khen thưởng</InputLabel>
                        <NativeSelect
                            name="formDTO.hinhThucKhenThuongId"
                            value={formik.values.formDTO.hinhThucKhenThuongId}
                            onChange={(e) => {
                                formik.setFieldValue("formDTO.hinhThucKhenThuongId", e.target.value);
                                formik.setFieldValue("formDTO.hinhThucKhenThuongString", e.target.options[e.target.selectedIndex].text);
                            }}
                            input={<InputBase fullWidth  />}
                        >
                            <option value="0">Chọn hình thức khen thưởng</option>
                            {dmHinhThuc &&
                                dmHinhThuc.map((item, i) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel >Danh hiệu thi đua</InputLabel>
                        <NativeSelect
                            name="formDTO.danhHieuThiDuaId"
                            value={formik.values.formDTO.danhHieuThiDuaId}
                            onChange={(e) => {
                                formik.setFieldValue("formDTO.danhHieuThiDuaId", e.target.value);
                                formik.setFieldValue("formDTO.danhHieuThiDuaString", e.target.options[e.target.selectedIndex].text);
                            }}
                            input={<InputBase fullWidth  />}
                        >
                            <option value="0">Chọn danh hiệu thi đua</option>
                            {dmDanhHieu &&
                                dmDanhHieu.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Số tiền"}
                            name="formDTO.soTien"
                            value={formik.values.formDTO.soTien}
                            onChange={(e) => {
                                formik.setFieldValue("formDTO.soTien", StringUtil.formatMoney(e.target.value));
                            }}
                            
                            InputProps={{ disableUnderline: true }}
                        />
                    </Grid>
                    <Grid item xs={6} className="grid-item" />
                    <Grid item xs={6} className="grid-item">
                        <InputLabel >Thành tích khen thưởng</InputLabel>
                        <InputBase
                            name="formDTO.thanhTichKhenThuong"
                            value={formik.values.formDTO.thanhTichKhenThuong}
                            onChange={formik.handleChange}
                            fullWidth
                            rows={3}
                            multiline
                            
                        />
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel >Tóm tắt thành tích</InputLabel>
                        <InputBase name="formDTO.tomTatThanhTich" value={formik.values.formDTO.tomTatThanhTich} onChange={formik.handleChange} fullWidth rows={3} multiline  />
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <CommonTableChuyenNganh onDelete={handleDeleteKT} onEdit={handleEditKT} columns={columns} rows={rows} onAdd={handleOpenDialog} />
                    </Grid>
                    <Grid item xs={12} className="grid-item ">
                        <Box className="button-group" marginTop="5px">
                            <Button type="submit" variant="contained" color="primary" disableElevation>
                                Lưu
                            </Button>
                            <Button variant="contained" color="default" disableElevation onClick={onDoBack}>
                                Trở về
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            {openDialog && (
                <DialogKhenThuong
                    handleCloseDialog={handleCloseDialog}
                    openDialog={openDialog}
                    danhHieuThiDua={dmDanhHieu}
                    hinhThucKhenThuong={dmHinhThuc}
                    handleAddTableKT={handleAddTableKT}
                    handleSubmitEdit={handleSubmitEdit}
                    urlAttachFileUpload={URL_UPLOAD_FILE_TO_ALFRESCO.NGHIEPVU_SO_6}
                    itemEdit={itemEdit}
                />
            )}
        </>
    );
};
