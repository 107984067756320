import MomentUtils from "@date-io/moment";
import { Button, Checkbox, FormControlLabel, Grid, Snackbar, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getIn, useFormik } from "formik";
import moment from "moment";
import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ChuHo, ThanhVien, TraCuuThongTinCDRequest, TRA_CUU_THONG_TIN_CONG_DAN_INIT } from "../../../api/CongDan/model/ThongTinCongDan";
import { TraCuuThongTinCongDanService } from "../../../api/CongDan/TraCuuThongTinCongDanService";
import { UserData } from "../../../api/userMgt/userManagementService";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { AlertMessage, CustomAlert } from "../../commons/components/alert";
import { bootstrapTextFieldHook } from "../../commons/components/input/bootstrap";
import { OverlayLoading } from "../../commons/components/overlayLoading/overlayLoading";
import { DanhSachHoGiaDinh } from "./DanhSachHoGiaDinh";

export const TraCuuHoGiaDinh = () => {
    // hook
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const [data, setData] = React.useState({} as ChuHo);
    const [total, setTotal] = React.useState(0);
    const [dataListPage, setDataListPage] = React.useState<ThanhVien[]>([]);
    let C_USER_DATA_DE = {} as UserData;
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    const [alertMessage, setAlertMessage] = React.useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);

    useEffect(() => {
    }, []);

    const validationSchema = Yup.object().shape(
        {
            CMND: Yup.string().nullable()
                  .required("Căn cước công dân bắt buộc nhập")
                  .matches(/^\d+$/, "Không đúng định dạng "),  
            hoVaTen: Yup.string().nullable().required("Họ và tên bắt buộc nhập"),
            ngaySinh: Yup.number().nullable().required("Ngày sinh bắt buộc nhập"),
            cmndThanNhan: Yup.string().nullable().required("CCCD thân nhân bắt buộc nhập"),
        }
    );

     const formik = useFormik({
        initialValues : {
            ...TRA_CUU_THONG_TIN_CONG_DAN_INIT,
            isCaNhan : false,
            maCanBo : C_USER_DATA_DE.userName,
        },
        onSubmit: (values) => handleSubmit(values),
        validationSchema,
        validateOnChange: false,
    });

    const handleSubmit = async(data: TraCuuThongTinCDRequest) => {
        dispatchLoading(showLoading());
        let soCMND = formik.values?.CMND;
        await new TraCuuThongTinCongDanService().traCuuThongTinCaNhan(data).then((res) => {
            if (res != null && res?.data?.error_code == 'SUCCESSFUL') {
                if(res?.data?.data?.maTrangThai && res?.data?.data?.maTrangThai == 1){
                    showMessageAlert(`Tra cứu căn cước công dân số ${soCMND} thành công`, 'success');
                }else{
                    showMessageAlert(`Công dân số ${soCMND} không phải chủ hộ`, 'warning');
                }
                setData(res?.data?.data);
            }

        }).finally(() => {
            dispatchLoading(hideLoading());
        });

    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        formik.setFieldValue(filedName, date?.toDate()?.getTime())
    }
    

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        // let hoSoCuaToiRequestNew = { ...hoSoCuaToiRequest, pageNumber: value };
        // setHoSoCuaToiRequest(hoSoCuaToiRequestNew);
        // loadRowPages(dataList, hoSoCuaToiRequestNew.pageSize, hoSoCuaToiRequestNew.pageNumber);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // let hoSoCuaToiRequestNew = { ...hoSoCuaToiRequest, pageNumber: 1, pageSize: Number(event.target.value) };
        // setHoSoCuaToiRequest(hoSoCuaToiRequestNew);
        // loadRowPages(dataList, hoSoCuaToiRequestNew.pageSize, hoSoCuaToiRequestNew.pageNumber);
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const showMessageAlert = (content: string, status : any) => {
        setAlertMessage({ open: true, content: content, severity: status });
    };

    return (
        <div className="dash-content">
            <Grid container>
                <OverlayLoading />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                </Snackbar>
                <Grid item xs={6} className="grid-item">
                    <TextField
                        fullWidth
                        required
                        onChange={formik.handleChange}
                        label={"Căn cước công dân (CCCD)"}
                        name="CMND"
                        InputLabelProps={{ classes: labelStyles }}
                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                        error={Boolean(getIn(formik.touched, "CMND") && getIn(formik.errors, "CMND"))}
                        helperText={Boolean(getIn(formik.touched, "CMND") && getIn(formik.errors, "CMND")) && formik.errors?.CMND}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <TextField
                        fullWidth
                        required
                        label={"Họ và tên"}
                        name="hoVaTen"
                        value={formik.values.hoVaTen}
                        onChange={formik.handleChange}
                        InputLabelProps={{ classes: labelStyles }}
                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                        error={Boolean(getIn(formik.touched, "hoVaTen") && getIn(formik.errors, "hoVaTen"))}
                        helperText={Boolean(getIn(formik.touched, "hoVaTen") && getIn(formik.errors, "hoVaTen")) && formik.errors?.hoVaTen}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <TextField
                        fullWidth
                        required
                        label={"CCCD thân nhân(CCCD;CCCD;CCCD)"}
                        name="cmndThanNhan"
                        value={formik.values.cmndThanNhan}
                        onChange={formik.handleChange}
                        InputLabelProps={{ classes: labelStyles }}
                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                        error={Boolean(getIn(formik.touched, "cmndThanNhan") && getIn(formik.errors, "cmndThanNhan"))}
                        helperText={Boolean(getIn(formik.touched, "cmndThanNhan") && getIn(formik.errors, "cmndThanNhan")) && formik.errors?.cmndThanNhan}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    {formik?.values?.chiNhapNamSinh ? 
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                name="ngaySinh"
                                label={"Ngày sinh"}
                                required
                                value={(formik.values.ngaySinh !== 0 && formik.values.ngaySinh && formik.values.ngaySinh !== null && formik.values.ngaySinh !== undefined) ? new Date(Number(formik.values.ngaySinh)) : null}
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngaySinh')}
                                autoOk={true}
                                fullWidth
                                format="YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "ngaySinh") && getIn(formik.errors, "ngaySinh"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "ngaySinh") && getIn(formik.errors, "ngaySinh")) && formik.errors.ngaySinh}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />  
                        </MuiPickersUtilsProvider> :
                         <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                name="ngaySinh"
                                label={"Ngày sinh"}
                                required
                                value={(formik.values.ngaySinh !== 0 && formik.values.ngaySinh && formik.values.ngaySinh !== null && formik.values.ngaySinh !== undefined) ? new Date(Number(formik.values.ngaySinh)) : null}
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngaySinh')}
                                autoOk={true}
                                fullWidth
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "ngaySinh") && getIn(formik.errors, "ngaySinh"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "ngaySinh") && getIn(formik.errors, "ngaySinh")) && formik.errors.ngaySinh}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />  
                     </MuiPickersUtilsProvider>   
                    }
                                 
                </Grid>
                <Grid item xs={3} className="grid-item" style={{paddingTop: '25px'}} >
                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox"> Chỉ nhập năm sinh</span>}
                        control={
                            <Checkbox name="chiNhapNamSinh" checked={formik.values.chiNhapNamSinh} color="default" size="small" 
                            value={formik.values.chiNhapNamSinh}  onChange={formik.handleChange} />
                        }
                    />
                </Grid>      
            </Grid>
            <Grid container>
                <Grid item xs={12} className="grid-item" container direction="row" justify="center" alignItems="center">
                    <Button  type="button" variant="contained" color="primary"
                        className="none-margin" disableElevation onClick={() => {
                            formik.handleSubmit();
                        }} >
                        Xác thực hộ gia đình
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <DanhSachHoGiaDinh  
                    dataList={data?.thanhViens}
                />
            </Grid>
        </div>
    );

}