import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../../utils/stringUtils';
import SoKyHieuBanVeService from '../../../../../api/CPXD/SoKyHieuBanVeService';
import { ICONS } from '../../../../Constants/constants';
import { Button } from '@material-ui/core';
export default class NhaORiengLeSoKyHieuBangVeAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            soKyHieuBanVe: {
                id: 0,
                noiDungGoiY: '',
                hoatDong: true,
            },
            show: false,
            errors: {
                noiDungGoiY: '',
            }
        }
        this.soKyHieuBanVeService = new SoKyHieuBanVeService();
    }
    componentDidUpdate() {
        if (this.props.show != this.state.show) {
            this.state.show = this.props.show;
            this.setState(this.state);
        }
        if (this.props.data != this.state.soKyHieuBanVe) {
            this.state.soKyHieuBanVe = this.props.data;
            this.setState(this.state);
        }
    }
    handleCloseModal = () => {
        this.clearData();
        this.props.onModalToggle();
    }
    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.state.soKyHieuBanVe[name] = value;
        this.state.errors = {
            noiDungGoiY: ''
        }
        this.setState(this.state);
    }
    handleSubmit = () => {
        if (this.validation()) {
            this.soKyHieuBanVeService.insertOrUpdate(this.state.soKyHieuBanVe).then(res => {
                if (res) {
                    this.handleCloseModal();
                    this.props.onUpdateData();
                    toastr.success('Thêm mới số ký hiệu bảng vẽ thành công');
                } else {
                    toastr.error('Thêm thất bại');
                }
            });

        }
    }
    validation = () => {
        let valid = true;
        this.state.errors = {};
        if (StringUtil.isNullOrEmty(this.state.soKyHieuBanVe.noiDungGoiY)) {
            this.state.errors.noiDungGoiY = 'Vui lòng nội dung gợi ý';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }
    clearData = () => {
        this.state.soKyHieuBanVe = {
            id: 0,
            noiDungGoiY: '',
            hoatDong: true,
        };
        this.state.errors = {
            noiDungGoiY: '',
        };
        this.setState(this.state);
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleCloseModal} on size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI SỐ KÝ HIỆU BẢNG VẼ</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={ICONS.delete} /></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="noiDungGoiY" style={{ fontWeight: 'bold', fontSize: ' ' }}>Nội dung gợi ý <span style={{ color: 'red' }}>(*)</span></label>
                                <input type="text" value={this.state.soKyHieuBanVe?.noiDungGoiY} className="form-control" id="noiDungGoiY" name="noiDungGoiY" onChange={this.handleChange} />
                            </div>
                            <div style={Boolean(this.state.errors?.noiDungGoiY) ? { display: 'block' } : {}} className="invalid-feedback" >
                                <span style={{ marginBottom: "5px" }}>{this.state.errors?.noiDungGoiY}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="text-right col-md-12">
                            <Button onClick={this.handleSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
                            <Button onClick={this.handleCloseModal} variant="contained" color="default" disableElevation>Đóng</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}