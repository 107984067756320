import { FileEntry } from "../../Common/fileEntry";
import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";
import { ChiTietHangMucDTO, ChiTietKhuonVienDTO, DienTichKhuonVienDTO, GiayPhepChoCapSoDTO, GiayPhepXayDungDaCapDTO, GiayPhepXayDungDaCapRequest, GiayPhepXayDungDTO, HangMucXayDungDTO, KiemTranNganChanDTO, KiemTranNganChanRequest, LichSuGiayPhepDTO, LoDatXayDungDTO, ThongTinThamDinhDTO, ThuHoiHuyDTO, XacMinhThucDiaDTO } from "../model/CapPhepXayDungModel";
export const ChiTietHangMucDTO_INIT: ChiTietHangMucDTO = {
    id: 0,
    hangMucId: 0,
    chiTiet: "",
    chieuCao: 0,
    congThuc: "",
    dienTich: 0,
    hoatDong: true
}
export const ChiTietKhuonVienDTO_INIT: ChiTietKhuonVienDTO = {
    id: 0,
    chiTietId: 0,
    ghiChu: "",
    ma:"",
    dienTich: 0,
}
export const HangMucXayDungDTO_INIT: HangMucXayDungDTO = {
    id: 0,
    giayPhepXayDungId: 0,
    hangMuc: "",
    mauSac: "",
    congTrinhChinh: true,
    soTang: "",
    ketCau: "",
    dienTichTangMot: 0,
    tongDienTichSan: 0,
    chieuCaoCongTrinh: 0,
    chieuSauCongTrinh: 0,
    matDatXayDung: 0,
    heSoSuDungDat: "",
    ghiChu: "",
    chiTietHangMucDTOs: new Array<ChiTietHangMucDTO>(),
    hoatDong: true,
    chieuCaoTangMot: 0,
    congThuc: "",
}

export const DienTichKhuonVienDTO_INIT: DienTichKhuonVienDTO = {
    id: 0,
    giayPhepXayDungId: 0,
    ghiChu: "",
    chiTietKhuonVienDTOs: new Array<ChiTietKhuonVienDTO>(),
}

export const GIAY_PHEP_XAY_DUNG_DA_CAP_REQUEST_INIT: GiayPhepXayDungDaCapRequest = {
    tenTinhTrang: "",
    maTinhTrang: "",
    soGiayPhep: "",
    maLoaiGiayPhep: "",
    tenLoaiGiayPhep: "",
    chuDauTu: "",
    maTinh: "",
    tenTinh: "",
    maHuyen: "",
    tenHuyen: "",
    maPhuong: "",
    tenPhuong: "",
    soNha: "",
    exportType: "",
    exportFileName: "",
    tenDonViCapPhep: "",
    maDonViCapPhep: "",
    tenCapLoaiCongTrinh: "",
    soKyHieuBangVe: "",
    maCapCongTrinh: "",
    soTo: "",
    soThua: "",
    ngayCapTu: 0,
    ngayCapDen: 0,
    kyThongKe: 1,
    namThongke: 0,
    pageSize: 5,
    pageNumber: 1,
    keywords: "",
    hoatDong: 1,
    tongDienTichSanTu: 0,
    dienTichTangMotTu: 0,
    tongDienTichSanDen: 0,
    dienTichTangMotDen: 0,
    isBaoCao: false
}
export const GIAY_PHEP_CHO_CAP_SO_INIT: GiayPhepChoCapSoDTO = {
    giayPhepXayDungId: 0,
    giayPhepXayDungDaCapId: 0,
    soGiayPhep: "",
    maHoSo: "",
    chuDuTu: "",
    diaChi: "",
    tenLoaiGiayPhep: "",
    loaiGiayPhep: "",
    tenCongTrinh: "",
    maTrangThai: "",
    tenTrangThai: "",
    ngayCapPhep: 0,
    ngayHetHan: 0,
    ghiChu: "",
    nguoiKy: "",
    mienGiayPhepXayDung: false,
    soGiayPhepCu: "",
    files: new Array<FileEntry>(),
    loaiKetQua: LoaiKetQuaEnum.CHUA_LAY_KET_QUA,
}

export const GIAY_PHEP_XAY_DUNG_INIT: GiayPhepXayDungDTO = {
    id: 0,
    maThuTuc: '',
    hoSoId: 0,
    giayPhepXayDungDaCapId: 0,
    maHoSo: '',
    tenCongTrinh: '',
    maTinh: '',
    tenTinh: '',
    maHuyen: '',
    tenHuyen: '',
    maPhuong: '',
    tenPhuong: '',
    tenDuong: '',
    maDuong: '',
    soNha: '',
    diaChiCu: '',
    ranhGioiDiDoiDen: '',
    diaChiDayDu: '',

    maCapCongTrinh: '',
    tenCapLoaiCongTrinh: '',
    maLoaiCongTrinh: '',
    tenLoaiCongTrinh: '',
    maPhanLoaiCongTrinh: '',
    tenPhanLoaiCongTrinh: '',

    donViTuVanId: 0,
    donViTuVan: '',
    donViKiemTraId: 0,
    donViKiemTra: '',
    soKyHieuBangVeId: 0,
    soKyHieuBangVe: '',
    ngayKiemTraBanVe: 0,

    chuTriThietKe: '',
    donViThamDinh: '',
    chuTriThamTra: '',
    duKienHoanThanh: '',
    chiGioiDuongDo: '',
    chiGioiXayDung: '',
    thoiHanCongTrinh: '',
    ghiChu: '',
    mienPhiXayDung: false,
    giayToVeDat: '',
    ketLuan: '',
    deXuat: '',
    quyHoach: '',

    tenLoaiGiayPhep: '',
    maLoaiGiayPhep: '',
    tenLoaiBienDong: '',
    maLoaiBienDong: '',
    tenTrangThai: '',
    maTrangThai: '',

    soGiayPhep: '',
    soGiayPhepCu: '',
    ngayCapPhepCu: 0,
    ngayHetHan: 0,
    nguoiKy: '',
    hoatDong: true,
    giayToQuyenSuDungDat: {
        id: 0,
        giayPhepXayDungId: 0,
        dienTichKhuonVien: 0,
        dienTichKhuonVienHienHuu: 0,
        dienTichBanCong: 0,
        dienTichChenhLech: 0,
        dienTichGiuLai: 0,
        matDoGiayPhep: 0,
        cotNen: '',
        lyDo: '',
        hoatDong: true
    },
    xacMinhThucDiaDTO: {
        id: 0,
        giayPhepXayDungId: 0,
        ngayNop: 0,
        hoTen: "",
        ngayYeuCau: 0,
        diaChiThuongTru: "",
        diaChiDangKy: "",
        veViec: "",
        noiDungYeuCau: "",
        banVeThietKe: "",
        cacVanDeKhac: "",
        nguoiKy: "",
        ngayKy: 0,
        maHoSo: "",
        hoatDong: true
    },
    thongTinSuaChuaDTO: {
        id: 0,
        giayPhepXayDungId: 0,
        tongDienTichSan: 0,
        dtXayDungTangMot: 0,
        chieuCaoCongTrinh: "",
        soTang: "",
        maLoaiCongTrinh: "",
        tenLoaiCongTrinh: "",
        maCapCongTrinh: "",
        tenCapLoaiCongTrinh: "",
        dienTichCaiTao: 0,
        tongDienTichSanSuaChua: 0,
        chieuCao: "",
        soTangSuaChua: "",
        ngayGiayPhepCu: 0,
        hoatDong: true
    },
    diaDiemHienTaiDTO: {
        maTinh: "",
        tenTinh: "",
        maHuyen: "",
        tenHuyen: "",
        maPhuong: "",
        tenPhuong: "",
        maDuong: "",
        tenDuong: "",
        soNha: "",
        diaChiCu: "",
        loaiDiaDiem: 1,
        giayPhepXayDungId: 0,
        loDatXayDungDTOs: new Array<LoDatXayDungDTO>()
    },
    diaDiemDenDTO: {
        maTinh: "",
        tenTinh: "",
        maHuyen: "",
        tenHuyen: "",
        maPhuong: "",
        tenPhuong: "",
        maDuong: "",
        tenDuong: "",
        soNha: "",
        diaChiCu: "",
        loaiDiaDiem: 2,
        giayPhepXayDungId: 0,
        loDatXayDungDTOs: new Array<LoDatXayDungDTO>()
    },
    loDatXayDungDTOs: new Array<LoDatXayDungDTO>(),
    hangMucXayDungDTOs: [],
    dienTichKhuonVienDTOs: [],
    chuDauTuDTOs: [],
    thongTinThamDinhDTOs: [],
    thongTinGiaHanGiayPhepXayDungDTO: {
        id: 0,
        giayPhepXayDungId: 0,
        soQuyetDinh: '',
        ngayQuyetDinh: 0,
        ngayGiaHan: 0,
        ngayGiaHanDen: 0,
        ghiChu: '',
        hoatDong: true
    },
    files: new Array<FileEntry>(),

}
export const GIAY_PHEP_XAY_DUNG_DA_CAP_DTO_INIT: GiayPhepXayDungDaCapDTO = {
    id: 0,
    giayPhepXayDungId: 0,
    ngayCapNhat: 0,
    ngayTao: 0,
    maLoaiGiayPhep: "",
    tenLoaiGiayPhep: "",
    maThuTuc: "",
    maHoSo: "",
    soGiayPhep: "",
    ngayCapPhep: 0,
    ngayHetHan: 0,
    ghiChu: "",
    nguoiKy: "",
    mienGiayPhepXayDung: false,
    tenTrangThai: "",
    maTrangThai: "",
    tenDonViCapPhep: "",
    maDonViCapPhep: "",
    donViTuVan: "",

    chuDauTu: "",
    viTriXayDung: "",
    diaDiemHienTaiDayDu: "",
    diaChiDayDu: "",
    giayPhepXayDungDTO: { ...GIAY_PHEP_XAY_DUNG_INIT },
    hoatDong: false,
    lichSuGiayPhepDTOs: new Array<LichSuGiayPhepDTO>(),
    dienTichXayDung: "",
    tongDienTichSan: "",
}

export const THONG_TIN_THAM_DINH_DTO_INIT: ThongTinThamDinhDTO = {
    id: 0,
    giayPhepXayDungId: 0,
    noiDung: "",
    files: new Array<FileEntry>(),
    hoatDong: true
}

export const THU_HOI_HUY_DTO_INIT: ThuHoiHuyDTO = {
    id: 0,
    giayPhepXayDungId: 0,
    giayPhepXayDungDaCapId: 0,
    soQuyetDinh: '',
    ngayQuyetDinh: 0,
    nguoiKy: '',
    lyDoHuy: '',
    ghiChu: '',
    maTinhTrang: '',
    tenTinhTrang: '',
    maLoaiBienDong: '',
    tenLoaiBienDong: '',
    files: new Array<FileEntry>(),
}


export const KIEM_TRA_NGAN_CHAN_INIT: KiemTranNganChanDTO = {
    id: 0,
    giayPhepXayDungId: 0,
    ngayNganChan: 0,
    chuSoHuu: '',
    diaChiCuThe: '',
    soTo: '',
    maHuyen: '',
    tenHuyen: '',
    maPhuong: '',
    tenPhuong: '',
    soThua: '',
    soCN: '',
    soVaoSo: '',
    congVanNC: '',
    noiDung: '',
    ghiChu: '',
    chiTiet : ''
}

export const KIEM_TRA_NGAN_CHAN_REQUEST: KiemTranNganChanRequest = {
    id: 0,
    giayPhepXayDungId: 0,
    ngayNganChanTu: 0,
    ngayNganChanDen: 0,
    pageNumber: 1,
    pageSize: 5,
    chuSoHuu: '',
    diaChiCuThe: '',
    soTo: '',
    maHuyen: '',
    tenHuyen: '',
    maPhuong: '',
    tenPhuong: '',
    soThua: '',
    soCN: '',
    soVaoSo: '',
    congVanNC: '',
}

export const XAC_MINH_THUC_DIA_INIT: XacMinhThucDiaDTO = {
    id: 0,
    giayPhepXayDungId: 0,
    ngayNop: 0,
    hoTen: "",
    ngayYeuCau: 0,
    diaChiThuongTru: "",
    diaChiDangKy: "",
    veViec: "",
    noiDungYeuCau: "",
    banVeThietKe: "",
    cacVanDeKhac: "",
    nguoiKy: "",
    maHoSo: "",
    ngayKy: 0,
    hoatDong: true

}