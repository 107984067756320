import React, { useEffect, useState } from 'react';
import { GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { Box, Grid, InputLabel, Snackbar, Typography, InputBase } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import NhaORiengLeChuDauTuComponent from '../Table/NhaORiengLeChuDauTuComponent';
import { GIAY_PHEP_XAY_DUNG_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { BrowserHistory } from 'history';
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import { CapPhepXayDungService } from '../../../../../api/CPXD/CapPhepXayDungService';
import StringUtil from '../../../../utils/stringUtils';
import ModalKiemTraNganChans from '../../common/modal/ModalKiemTraNganChans';
import { LOAIBIENDONG_CAPMOI, LOAIGIAYPHEP_DIDOI, TINHTRANG_HOATDONG } from '../../../../Constants/CapPhepXayDungConstants';
import GiayPhepXayDungButtonComponent from '../Action/GiayPhepXayDungButtonComponent';
import NoiDungDiDoiComponent from './NoiDungDiDoiComponent';
import { useLocation } from 'react-router';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import ThongTinThamDinhTable from '../Table/ThongTinThamDinhTable';
import { BieuMauAttachFileDTO } from '../../../../../api/QuanTri/Model/danhMucBieuMau';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import ModalXacMinhThucDia from '../../common/modal/ModalXacMinhThucDia';
import GiayPhepXayDungDauKyButtonComponent from '../Action/GiayPhepXayDungDauKyButtonComponent';
import SearchIcon from '@material-ui/icons/Search';
import DeXuatModal from '../Modal/DeXuatModal';
import GiayToVeDatDaiModal from '../Modal/GiayToVeDatDaiModal';
import KetLuanModal from '../Modal/KetLuanModal';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const DiDoiComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [indexTab, setIndexTab] = useState(0);

    const [data, setData] = useState({} as GiayPhepXayDungDTO);
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);

    const capPhepXayDungService = new CapPhepXayDungService();
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [openDialogThucDia, setOpenDialogThucDia] = useState(false);
    useEffect(() => {
        initData();
    }, []);

    const [openGiayToVeDatDaiModal, setOpenGiayToVeDatDaiModal] = useState(false);
    const [openDeXuatModal, setOpenDeXuatModal] = useState(false);
    const [openKetLuanModal, setOpenKetLuanModal] = useState(false);


    const handleGiayToVeDatDaiModalToggle = () => {
        setOpenGiayToVeDatDaiModal(!openGiayToVeDatDaiModal);
    }

    const handleDeXuatModalToggle = () => {
        setOpenDeXuatModal(!openDeXuatModal);
    }

    const handleKetLuanModalToggle = () => {
        setOpenKetLuanModal(!openKetLuanModal);
    }

    const initData = () => {
        let nhapDauKy = location?.state?.isNhapDauKy;
        let giayPhepXayDaCapId = location?.state?.giayPhepXayDaCapId;
        setIsNhapDauKy(nhapDauKy);
        if (nhapDauKy) {
            if (giayPhepXayDaCapId > 0) {
                capPhepXayDungService.getGiayPhepXayDungDaCapById(giayPhepXayDaCapId).then(res => {
                    if (res?.data?.data?.giayPhepXayDungDTO !== null && res?.data?.data?.giayPhepXayDungDTO !== undefined) {
                        const dataNew = {
                            ...res?.data?.data?.giayPhepXayDungDTO,
                        }
                        setData(dataNew);
                    }
                }).catch(error => {
                    console.log(error);
                });
            } else {
                const dataNew = {
                    ...GIAY_PHEP_XAY_DUNG_INIT,
                    maHoSo: location?.state?.maHoSo,
                    maThuTuc: location?.state?.maThuTuc,
                    tenLoaiGiayPhep: LOAIGIAYPHEP_DIDOI.ten,
                    maLoaiGiayPhep: LOAIGIAYPHEP_DIDOI.ma,
                    tenLoaiBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maLoaiBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenTrangThai: TINHTRANG_HOATDONG.ten,
                    maTrangThai: TINHTRANG_HOATDONG.ma,
                    maTinh: "92",
                    tenTinh: "Thành phố Cần Thơ",
                    diaDiemHienTaiDTO: {
                        ...GIAY_PHEP_XAY_DUNG_INIT.diaDiemHienTaiDTO,
                        maTinh: "92",
                        tenTinh: "Thành phố Cần Thơ",
                    },
                    diaDiemDenDTO: {
                        ...GIAY_PHEP_XAY_DUNG_INIT.diaDiemDenDTO,
                        maTinh: "92",
                        tenTinh: "Thành phố Cần Thơ",
                    }
                }
                setData(dataNew);
            }
        } else {
            capPhepXayDungService.getByMaHoSo({ maHoSo: location.state?.maHoSo }).then(res => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    const dataNew = {
                        ...res?.data?.data,
                        maHoSo: location?.state?.maHoSo,
                        maThuTuc: location?.state?.maThuTuc,
                        tenLoaiGiayPhep: LOAIGIAYPHEP_DIDOI.ten,
                        maLoaiGiayPhep: LOAIGIAYPHEP_DIDOI.ma,
                        tenLoaiBienDong: LOAIBIENDONG_CAPMOI.ten,
                        maLoaiBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maTrangThai: TINHTRANG_HOATDONG.ma
                    }
                    setData(dataNew);
                } else {
                    const dataNew = {
                        ...GIAY_PHEP_XAY_DUNG_INIT,
                        maHoSo: location?.state?.maHoSo,
                        maThuTuc: location?.state?.maThuTuc,
                        tenLoaiGiayPhep: LOAIGIAYPHEP_DIDOI.ten,
                        maLoaiGiayPhep: LOAIGIAYPHEP_DIDOI.ma,
                        tenLoaiBienDong: LOAIBIENDONG_CAPMOI.ten,
                        maLoaiBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        maTinh: "92",
                        tenTinh: "Thành phố Cần Thơ",
                        diaDiemHienTaiDTO: {
                            ...GIAY_PHEP_XAY_DUNG_INIT.diaDiemHienTaiDTO,
                            maTinh: "92",
                            tenTinh: "Thành phố Cần Thơ",
                        },
                        diaDiemDenDTO: {
                            ...GIAY_PHEP_XAY_DUNG_INIT.diaDiemDenDTO,
                            maTinh: "92",
                            tenTinh: "Thành phố Cần Thơ",
                        }
                    }
                    setData(dataNew);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            setData(data => ({
                ...value
            }));
        } else {
            setData(data => ({
                ...data,
                [name]: value
            }));
        }
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const loadGiayPhepDaCap = (soGiayPhep: string) => {
        capPhepXayDungService.getBySoGiayPhep_MaTrangThai({ soGiayPhep: soGiayPhep, maTrangThai: TINHTRANG_HOATDONG.ma }).then(res => {
            let giayPhepDaCap = res?.data.data;
            if (giayPhepDaCap !== null && giayPhepDaCap !== undefined) {
                let giayPhepXayDungDTO = giayPhepDaCap.giayPhepXayDungDTO;

                let hangMucXayDungDTOs = (giayPhepXayDungDTO !== null && giayPhepXayDungDTO !== undefined && giayPhepXayDungDTO.hangMucXayDungDTOs != null && giayPhepXayDungDTO.hangMucXayDungDTOs != undefined) ? giayPhepXayDungDTO.hangMucXayDungDTOs : [];
                hangMucXayDungDTOs.forEach((hangMucXayDungDTO: any, index: number) => {
                    hangMucXayDungDTOs[index] = {
                        ...hangMucXayDungDTO,
                        id: 0,
                        giayPhepXayDungId: 0
                    }
                });
                let chuDauTuDTOs = (giayPhepXayDungDTO !== null && giayPhepXayDungDTO !== undefined && giayPhepXayDungDTO.chuDauTuDTOs != null && giayPhepXayDungDTO.chuDauTuDTOs != undefined) ? giayPhepXayDungDTO.chuDauTuDTOs : [];
                chuDauTuDTOs.forEach((chuDauTuDTO: any, index: number) => {
                    chuDauTuDTOs[index] = {
                        ...chuDauTuDTO,
                        id: 0,
                        giayPhepXayDungId: 0
                    }
                });
                let thongTinThamDinhDTOs = (giayPhepXayDungDTO !== null && giayPhepXayDungDTO !== undefined && giayPhepXayDungDTO.thongTinThamDinhDTOs != null && giayPhepXayDungDTO.thongTinThamDinhDTOs != undefined) ? giayPhepXayDungDTO.thongTinThamDinhDTOs : [];
                thongTinThamDinhDTOs.forEach((thongTinThamDinhDTO: any, index: number) => {
                    thongTinThamDinhDTOs[index] = {
                        ...thongTinThamDinhDTO,
                        id: 0,
                        giayPhepXayDungId: 0
                    }
                });
                let giayToQuyenSuDungDat = (giayPhepXayDungDTO !== null && giayPhepXayDungDTO !== undefined && giayPhepXayDungDTO.giayToQuyenSuDungDat != null && giayPhepXayDungDTO.giayToQuyenSuDungDat != undefined) ? giayPhepXayDungDTO.giayToQuyenSuDungDat : {};
                let thongTinSuaChuaDTO = (giayPhepXayDungDTO !== null && giayPhepXayDungDTO !== undefined && giayPhepXayDungDTO.thongTinSuaChuaDTO != null && giayPhepXayDungDTO.thongTinSuaChuaDTO != undefined) ? giayPhepXayDungDTO.thongTinSuaChuaDTO : {};


                let loDatXayDungDTOs = (giayPhepXayDungDTO !== null && giayPhepXayDungDTO !== undefined && giayPhepXayDungDTO.loDatXayDungDTOs != null && giayPhepXayDungDTO.loDatXayDungDTOs != undefined) ? giayPhepXayDungDTO.loDatXayDungDTOs : [];
                loDatXayDungDTOs.forEach((loDatXayDungDTO: any, index: number) => {
                    loDatXayDungDTOs[index] = {
                        ...loDatXayDungDTO,
                        id: 0,
                        giayPhepXayDungId: 0
                    }
                });

                let diaDiemHienTaiDTO = {

                }
                let diaDiemDenDTO = (giayPhepXayDungDTO !== null && giayPhepXayDungDTO !== undefined && giayPhepXayDungDTO.diaDiemDenDTO != null && giayPhepXayDungDTO.diaDiemDenDTO != undefined) ? giayPhepXayDungDTO.diaDiemDenDTO : {};
                let dataNew: GiayPhepXayDungDTO = {
                    ...data,
                    soGiayPhepCu: isNhapDauKy ? "" : soGiayPhep,
                    soGiayPhep: isNhapDauKy ? soGiayPhep : "",
                    ngayCapPhepCu: giayPhepDaCap.ngayCapPhep,
                    ngayHetHan: giayPhepDaCap.ngayHetHan,

                    tenCongTrinh: giayPhepXayDungDTO?.tenCongTrinh,
                    maTinh: giayPhepXayDungDTO?.maTinh,
                    tenTinh: giayPhepXayDungDTO?.tenTinh,
                    maHuyen: giayPhepXayDungDTO?.maHuyen,
                    tenHuyen: giayPhepXayDungDTO?.tenHuyen,
                    maPhuong: giayPhepXayDungDTO?.maPhuong,
                    tenPhuong: giayPhepXayDungDTO?.tenPhuong,
                    tenDuong: giayPhepXayDungDTO?.tenDuong,
                    maDuong: giayPhepXayDungDTO?.maDuong,
                    soNha: giayPhepXayDungDTO?.soNha,
                    diaChiCu: giayPhepXayDungDTO?.diaChiCu,

                    maCapCongTrinh: giayPhepXayDungDTO?.maCapCongTrinh,
                    tenCapLoaiCongTrinh: giayPhepXayDungDTO?.tenCapLoaiCongTrinh,
                    maLoaiCongTrinh: giayPhepXayDungDTO?.maLoaiCongTrinh,
                    tenLoaiCongTrinh: giayPhepXayDungDTO?.tenLoaiCongTrinh,
                    maPhanLoaiCongTrinh: giayPhepXayDungDTO?.maPhanLoaiCongTrinh,
                    tenPhanLoaiCongTrinh: giayPhepXayDungDTO?.tenPhanLoaiCongTrinh,

                    donViTuVanId: giayPhepXayDungDTO?.donViTuVanId,
                    donViTuVan: giayPhepXayDungDTO?.donViTuVan,
                    donViKiemTraId: giayPhepXayDungDTO?.donViKiemTraId,
                    donViKiemTra: giayPhepXayDungDTO?.donViKiemTra,
                    soKyHieuBangVeId: giayPhepXayDungDTO?.soKyHieuBangVeId,
                    soKyHieuBangVe: giayPhepXayDungDTO?.soKyHieuBangVe,

                    duKienHoanThanh: giayPhepXayDungDTO?.duKienHoanThanh,
                    chiGioiDuongDo: giayPhepXayDungDTO?.chiGioiDuongDo,
                    chiGioiXayDung: giayPhepXayDungDTO?.chiGioiXayDung,
                    thoiHanCongTrinh: giayPhepXayDungDTO?.thoiHanCongTrinh,
                    mienPhiXayDung: giayPhepXayDungDTO?.mienPhiXayDung,
                    giayToVeDat: giayPhepXayDungDTO?.giayToVeDat,
                    ketLuan: giayPhepXayDungDTO?.ketLuan,
                    deXuat: giayPhepXayDungDTO?.deXuat,
                    nguoiKy: giayPhepXayDungDTO?.nguoiKy,

                    giayToQuyenSuDungDat: {
                        ...giayToQuyenSuDungDat,
                        id: 0
                    },
                    thongTinSuaChuaDTO: {
                        ...thongTinSuaChuaDTO,
                        id: 0
                    },
                    diaDiemHienTaiDTO: {
                        maTinh: "92",
                        tenTinh: "Thành phố Cần Thơ",
                        maHuyen: giayPhepXayDungDTO.maHuyen,
                        tenHuyen: giayPhepXayDungDTO.tenHuyen,
                        maPhuong: giayPhepXayDungDTO.maPhuong,
                        tenPhuong: giayPhepXayDungDTO.tenPhuong,
                        maDuong: giayPhepXayDungDTO.maDuong,
                        tenDuong: giayPhepXayDungDTO.tenDuong,
                        soNha: giayPhepXayDungDTO.soNha,
                        diaChiCu: "",
                        loaiDiaDiem: 1,
                        giayPhepXayDungId: 0,
                        loDatXayDungDTOs: loDatXayDungDTOs
                    },
                    diaDiemDenDTO: {
                        ...diaDiemDenDTO,
                        loaiDiaDiem: 2,
                        maTinh: "92",
                        tenTinh: "Thành phố Cần Thơ"
                    },
                    loDatXayDungDTOs: loDatXayDungDTOs,
                    hangMucXayDungDTOs: hangMucXayDungDTOs,
                    chuDauTuDTOs: chuDauTuDTOs,
                    thongTinThamDinhDTOs: thongTinThamDinhDTOs
                };
                setData(dataNew);
            } else {
                setMessage("Số giấy phép không tồn tại trong dữ liệu");
                setSeverity('warning');
                setShowAlert(true);
            }

        }).catch(error => {
            console.log(error);
        });
    }
    const setParams = (event: any) => {
        setTabRequired(-1);
        let name = event.target.name;
        let value = event.target.value;
        let dataNew = { ...data, [name]: value };
        setData(dataNew);
    }
    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (isNhapDauKy) {
            if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
                setTabRequired(0);
                return;
            }
            if (data.ngayCapPhepCu === null || !data.ngayCapPhepCu || data.ngayCapPhepCu === undefined || data.ngayCapPhepCu === 0) {
                setTabRequired(0);
                return;
            }
            if (data.ngayHetHan === null || !data.ngayHetHan || data.ngayHetHan === undefined || data.ngayHetHan === 0) {
                setTabRequired(0);
                return;
            }
        }
        if (!isNhapDauKy && StringUtil.isNullOrEmty(data.soGiayPhepCu)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.diaDiemHienTaiDTO.maTinh)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.diaDiemHienTaiDTO.maHuyen)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.diaDiemHienTaiDTO.tenDuong)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.diaDiemHienTaiDTO.tenDuong)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.diaDiemDenDTO.maTinh)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.diaDiemDenDTO.maHuyen)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.diaDiemDenDTO.maPhuong)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.diaDiemDenDTO.tenDuong)) {
            setTabRequired(0);
            return;
        }
        dispatchLoading(showLoading());
        if (isNhapDauKy) {
            capPhepXayDungService.insertOrUpdateNhapDauKy(data).then(res => {
                if (res != null) {
                    if (res.data.error_code == 'SUCCESSFUL') {
                        let dataNew = {
                            ...data,
                            id: parseInt(res.data.data.id),
                            giayPhepXayDungDaCapId: parseInt(res.data.data.giayPhepXayDungDaCapId)
                        }
                        setData(dataNew);
                        setMessage('Yêu cầu thực hiện thành công');
                        setSeverity("success");
                        setShowAlert(true);
                    } else if (res.data.error_code === '409') {
                        setMessage("Số giấy phép đã tồn tại");
                        setSeverity("error");
                        setShowAlert(true);
                    } else {
                        setMessage(res.data.error_message);
                        setSeverity("error");
                        setShowAlert(true);
                    }
                } else {
                    setMessage('Yêu cầu thực hiện thất bại');
                    setSeverity("error");
                    setShowAlert(true);
                }
            }).catch(error => {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        } else {
            capPhepXayDungService.insertOrUpdate(data).then(res => {
                if (res != null) {
                    if (res.data.error_code == 'SUCCESSFUL') {
                        let dataNew = {
                            ...data,
                            id: parseInt(res.data.data.id),
                            giayPhepXayDungDaCapId: parseInt(res.data.data.giayPhepXayDungDaCapId),
                            layKetQua: res.data.data.layKetQua===true? true:false,
                        }
                        setData(dataNew);
                        setMessage('Yêu cầu thực hiện thành công');
                        setSeverity("success");
                        setShowAlert(true);
                    } else {
                        setMessage(res.data.error_message);
                        setSeverity("error");
                        setShowAlert(true);
                    }
                } else {
                    setMessage('Yêu cầu thực hiện thất bại');
                    setSeverity("error");
                    setShowAlert(true);
                }
            }).catch(error => {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
    }
    const handleToggleModal = () => {
        setOpenDialog(!openDialog)
    }
    const handleToggleModalThucDia = () => {
        setOpenDialogThucDia(!openDialogThucDia);
        initData();
    }
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dispatchLoading(showLoading());
        if (StringUtil.isEmty(data.maHoSo)) {
            capPhepXayDungService.downloadFileByGiayPhepXayDungDaCapId({ templateId: bieuMau.fileId, giayPhepXayDungDaCapId: data.giayPhepXayDungDaCapId }).then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
            }).catch(error => {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        } else {
            capPhepXayDungService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
            }).catch(error => {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
    }
    const resetData = () => {
        setSubmit(false);
        const dataNew = {
            ...GIAY_PHEP_XAY_DUNG_INIT,
            giayPhepXayDaCapId: data.giayPhepXayDungDaCapId,
            maThuTuc: data.maThuTuc,
            maHoSo: data.maHoSo,
            id: data.id,
            tenLoaiGiayPhep: data.tenLoaiGiayPhep,
            maLoaiGiayPhep: data.maLoaiGiayPhep,
            tenLoaiBienDong: data.tenLoaiBienDong,
            maLoaiBienDong: data.maLoaiBienDong,
            tenTrangThai: data.tenTrangThai,
            maTrangThai: data.maTrangThai
        }
        setData(dataNew);

    }
    const showMessage = (mess: string, severity: string) => {
        setMessage(mess);
        setSeverity(severity);
        setShowAlert(true);
    }

    const handleUpdateData = (name : string ,value: string) => {
        let dataNew = {
            ...data,
            [name]: value
        };
        setData(dataNew);
    }
    return (
        <div className="dash-content">
            <>
                {
                    <Grid container>
                          <DeXuatModal
                            onUpdateData={handleUpdateData}
                            onModalToggle={handleDeXuatModalToggle}
                            show={openDeXuatModal} >
                        </DeXuatModal>

                        <KetLuanModal
                            onUpdateData={handleUpdateData}
                            onModalToggle={handleKetLuanModalToggle}
                            show={openKetLuanModal} >
                        </KetLuanModal>

                        <GiayToVeDatDaiModal
                            onUpdateData={handleUpdateData}
                            onModalToggle={handleGiayToVeDatDaiModalToggle}
                            show={openGiayToVeDatDaiModal} >
                        </GiayToVeDatDaiModal>
                        <OverlayLoading />
                        <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                            <Alert onClose={() => setShowAlert(false)} severity={severity as Color}>{message}</Alert>
                        </Snackbar>
                        <Grid container style={{ float: 'left', paddingTop: '20px' }} item xs={12} className="grid-item" >
                            <div className="dash-title">
                                <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>CẤP GIẤY PHÉP DI DỜI CÔNG TRÌNH {!isNhapDauKy ? "- MÃ HỒ SƠ :  " + data.maHoSo : ""} </h6>
                            </div>
                        </Grid>
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Nội dung giấy phép" />
                                    <CustomTab style={tabRequired == 1 ? { color: 'red' } : {}} className="test" label="Chủ đầu tư" />
                                    <CustomTab style={tabRequired == 2 ? { color: 'red' } : {}} label="Giấy tờ về đất đai" />
                                    <CustomTab style={tabRequired == 3 ? { color: 'red' } : {}} label="Thông tin thẩm định" />
                                    <CustomTab style={tabRequired == 4 ? { color: 'red' } : {}} label="Thông tin đề xuất" />
                                </CustomTabs>
                            </Box>

                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <NoiDungDiDoiComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    isNhapDauKy={isNhapDauKy}
                                    data={data}
                                    resetData={resetData}
                                    loadGiayPhepDaCap={loadGiayPhepDaCap} />
                            </TabPanel>
                        }
                        {
                            (indexTab === 1 && !StringUtil.isNullOrEmty(data.maLoaiGiayPhep)) &&
                            <TabPanel value={indexTab} index={1}>
                                <NhaORiengLeChuDauTuComponent
                                    onUpdateData={setDataParam}
                                    data={data.chuDauTuDTOs}
                                />
                            </TabPanel>

                        }
                        {
                            (indexTab === 2 && !StringUtil.isNullOrEmty(data.maLoaiGiayPhep)) &&
                            <TabPanel value={indexTab} index={2}>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Giấy tờ về đất đai </InputLabel>
                                        <InputBase style={{width: '90%'}}
                                            rows="8"
                                            multiline
                                            name="giayToVeDat"
                                            value={data.giayToVeDat} onChange={setParams} />
                                                                       <SearchIcon 
                                        fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleGiayToVeDatDaiModalToggle} />
                                    </Grid>
                                </Box>
                            </TabPanel>

                        }
                        {
                            (indexTab === 3 && !StringUtil.isNullOrEmty(data.maLoaiGiayPhep)) &&
                            <TabPanel value={indexTab} index={3}>
                                <ThongTinThamDinhTable
                                    onUpdateData={setDataParam}
                                    data={data.thongTinThamDinhDTOs}
                                />
                            </TabPanel>

                        }
                        {
                            (indexTab === 4 && !StringUtil.isNullOrEmty(data.maLoaiGiayPhep)) &&
                            <TabPanel value={indexTab} index={4}>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Kết luận </InputLabel>
                                        <InputBase style={{width: '90%'}}
                                            multiline
                                            rows="8"
                                            rowsMax="8"
                                            name="ketLuan"
                                            value={data.ketLuan} onChange={setParams} />      
                                            <SearchIcon 
                                            fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleKetLuanModalToggle} />
                                    </Grid>
                                </Box>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Đề xuất </InputLabel>
                                        <InputBase style={{width: '90%'}}
                                            multiline
                                            rows="8"
                                            name="deXuat"
                                            value={data.deXuat} onChange={setParams} />
                                                                         <SearchIcon 
                                        fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleDeXuatModalToggle} />
                                    </Grid>
                                </Box>
                            </TabPanel>

                        }
                        {
                            !StringUtil.isNullOrEmty(data.maLoaiGiayPhep) &&
                            <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                                <GiayPhepXayDungButtonComponent
                                    onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                    onSubmit={handleSubmit}
                                    showIn={data.id > 0 && !isNhapDauKy}
                                    resetData={resetData}
                                    onToggleModal={handleToggleModal}
                                    onToggleModalThucDia={handleToggleModalThucDia}
                            
                                ></GiayPhepXayDungButtonComponent>
                            </Grid>
                        }
                    </Grid>
                }
                {
                    openDialog &&
                    <ModalKiemTraNganChans show={openDialog} onToggle={handleToggleModal} ></ModalKiemTraNganChans>
                }
                {
                    openDialogThucDia &&
                    <ModalXacMinhThucDia show={openDialogThucDia} onToggle={handleToggleModalThucDia}
                    id={data.xacMinhThucDiaDTO.id}
                    ></ModalXacMinhThucDia>
                }
            </>
        </div>
    )
}
export default DiDoiComponent;