import React, {useEffect, useRef, useState} from 'react';
import {
    Box, Button,
    createStyles,
    Dialog,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    makeStyles,
    Theme
} from '@material-ui/core';
import * as Yup from 'yup';
import {useFormik} from "formik";
import {TBSanXuatRuouDTO} from "../../../../../../api/CPBLR/model/TBSanXuatRuouDTO";
import {DialogContent, DialogTitle} from "../../../../../commons/components/dialog/dialogCommons";
import {TBPhanPhoiRuouDTO} from "../../../../../../api/CPBLR/model/TBPhanPhoiRuouDTO";

interface PhanPhoiRuouModalProps {
    onSubmitData: (resp: TBPhanPhoiRuouDTO,  isEdit: Boolean) => void;
    openDialog: boolean;
    itemEdit: TBPhanPhoiRuouDTO;
    onDialogClose: () => void;
    openEdit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
    }),
);

interface FormValues {
    requestDTO: TBPhanPhoiRuouDTO,
}

const PhanPhoiRuouModal = (props: PhanPhoiRuouModalProps) => {
    const {onSubmitData} = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    const validateionSchema = Yup.object().shape({
        requestDTO: Yup.object().shape({
            tenCongTy: Yup.string().required('Vui lòng nhập tên công ty!'),
            diaChi: Yup.string().required('Vui lòng nhập địa chỉ!'),
        }).required('Vui lòng nhập thông tin phân phối rượu!')
    });

    const initDefaultValue: FormValues = {
        requestDTO: {
            tenCongTy: '',
            loaiRuou: '',
            diaChi: ''
        } as TBPhanPhoiRuouDTO
    }

    const formik = useFormik({
        initialValues: initDefaultValue,
        validationSchema: validateionSchema,
        onSubmit: () => submitData()
    });

    useEffect(() => {
        if(props.openEdit){
            formik.setValues({
                requestDTO: {
                    tenCongTy: props.itemEdit.tenCongTy,
                    loaiRuou: props.itemEdit.loaiRuou,
                    diaChi: props.itemEdit.diaChi,
                    ngayTao: props.itemEdit.ngayTao,
                } as TBPhanPhoiRuouDTO,
            });
        } else {
            formik.setValues({
                requestDTO: {
                    tenCongTy: '',
                    loaiRuou: '',
                    diaChi: ''
                } as TBPhanPhoiRuouDTO,
            });
        }
        setOpenEdit(props.openEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    const submitData = async () => {
        onSubmitData(formik.values.requestDTO, openEdit);
    }

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="sm" aria-labelledby="customized-dialog-title"
                    open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {'CẬP NHẬT THÔNG TIN CÔNG TY PHÂN PHỐI RƯỢU'}
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel  required>Tên công ty/Nhà cung cấp</InputLabel>
                                    <InputBase fullWidth
                                               error={formik.touched.requestDTO?.tenCongTy && Boolean(formik.errors.requestDTO?.tenCongTy)}
                                               value={formik.values.requestDTO.tenCongTy} name="requestDTO.tenCongTy"
                                               onChange={formik.handleChange}/>
                                    {
                                        formik.touched.requestDTO?.tenCongTy && Boolean(formik.errors.requestDTO?.tenCongTy) &&
                                        <FormHelperText className="-error"
                                        >{formik.errors.requestDTO?.tenCongTy}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel>Loại rượu</InputLabel>
                                    <InputBase fullWidth
                                               value={formik.values.requestDTO.loaiRuou} name="requestDTO.loaiRuou"
                                               onChange={formik.handleChange}/>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel  required>Địa chỉ</InputLabel>
                                    <InputBase fullWidth
                                               error={formik.touched.requestDTO?.diaChi && Boolean(formik.errors.requestDTO?.diaChi)}
                                               value={formik.values.requestDTO.diaChi} name="requestDTO.diaChi"
                                               onChange={formik.handleChange}/>
                                    {
                                        formik.touched.requestDTO?.diaChi && Boolean(formik.errors.requestDTO?.diaChi) &&
                                        <FormHelperText className="-error"
                                        >{formik.errors.requestDTO?.diaChi}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} className="gridItem">
                                    <Box marginTop="5px">
                                        <Button type="submit" variant="contained" color="primary" disableElevation
                                        >
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="secondary" disableElevation
                                                onClick={() => {
                                                    formik.resetForm({values: initDefaultValue,});
                                                }}
                                        > Làm mới
                                        </Button>
                                        <Button variant="contained" color="primary" disableElevation
                                                onClick={props.onDialogClose}>
                                            Đóng
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default PhanPhoiRuouModal;
