import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    createStyles,
    Dialog,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    makeStyles,
    Theme
} from '@material-ui/core';
import * as Yup from 'yup';
import { DialogContent, DialogTitle } from "../../../commons/components/dialog/dialogCommons";
import { BaseResponse } from "../../../../api/baseService";
import { useFormik } from "formik";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import DropZoneCustom from "../../../commons/components/CustomDropzone/DropZoneCustom";
import axios from "../../../../api";
import { FileEntry } from "../../../../api/Common/fileEntry";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import { FileServerService } from "../../../../api/Common/FileServerService";
import { HoSoThuLyLPGChaiDTO } from "../../../../api/LPGChai/model/HoSoThuLyLPGChai";
import { CapSoGiayPhepLGPChaiDTO } from "../../../../api/LPGChai/model/GiayPhepKinhDoanhLPGChai";
import { LPGChaiService } from "../../../../api/LPGChai/LPGChaiService";
import { getObject } from '../../../../helpers/localStorage';
import { LoaiGiayPhepEnum } from '../../../../api/loaiGiayPhep/loaiGiayPhepEnum';
import LayKetQuaGiayPhepService from '../../../../api/QuanTri/LayKetQuaGiayPhepService';
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { useDispatch } from "react-redux";
import { Dispatch } from 'redux';
import { KetQuaGiayPhepDTO } from '../../../../api/Common/KetQuaGiayPhepDTO';

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

interface CapSoGiayPhepModalProps {
    onSubmitData: (resp: BaseResponse, index?: number) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: HoSoThuLyLPGChaiDTO;
    index: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
    }),
);

interface FormValues {
    requestDTO: CapSoGiayPhepLGPChaiDTO,
}

const CapSoGiayPhepModal = (props: CapSoGiayPhepModalProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();

    const autoCompleteRef = useRef<HTMLDivElement>(null);
    const { onSubmitData } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const fileServerService = new FileServerService();
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const validateionSchema = Yup.object().shape({
        requestDTO: Yup.object().shape({
            soGiayPhep: Yup.string().required('Vui lòng nhập số giấy phép!'),
            ngayCapPhep: Yup.string().test(
                "test-ctype",
                "Vui lòng chọn ngày cấp phép!",
                function (value) {
                    return ((value && (value != '0' && value != undefined && value != 'NaN')) ? true : false);
                }
            ),
        })
    });

    const initDefaultValue: FormValues = {
        requestDTO: {
            id: 0,
            maHoSo: props.itemEdit.maHoSo,
            ngayCapPhep: 0,
            ngayHetHanGP: 0,
            soGiayPhep: "",
            ghiChu: "",
            nguoiKy: "",
            maBienDong: props.itemEdit.maBienDong,
            soGiayPhepCu: props.itemEdit.soGiayPhepCu,
            maQuanHuyen: C_USER_DATA.maQuanHuyen,
            files: [],
            fileTrash: [],
        }
    }

    const formik = useFormik({
        initialValues: initDefaultValue,
        validationSchema: validateionSchema,
        onSubmit: () => submitData(),
    });

    useEffect(() => {
        const json = { hoSoThuLyId: props.itemEdit.id }
        new LPGChaiService().getChiTiet(json).then(res => {
            if (res && res.data && res.data.data && res.data.data.id > 0) {
                let data = res.data.data;
                formik.setValues({
                    requestDTO: {
                        ...data,
                        maHoSo: props.itemEdit.maHoSo,
                        files: data.files ? data.files : [],
                        maBienDong: props.itemEdit.maBienDong,
                        soGiayPhepCu: props.itemEdit.soGiayPhepCu
                    }
                });
                if (data.files) {
                    console.log(data.files);
                    setAttachFileServer(data.files);
                }
            } else {
                formik.setValues({
                    requestDTO: {
                        id: 0,
                        maHoSo: props.itemEdit.maHoSo,
                        maBienDong: props.itemEdit.maBienDong,
                        soGiayPhepCu: props.itemEdit.soGiayPhepCu,
                        ngayCapPhep: 0,
                        ngayHetHanGP: 0,
                        soGiayPhep: "",
                        ghiChu: "",
                        nguoiKy: "",
                        maQuanHuyen: "",
                        files: [],
                        fileTrash: [],
                    },
                });
            }
        });

        setOpenDialog(props.openDialog);
    }, [props.openDialog]);

    const submitData = async () => {
        await new LPGChaiService().luuVaCapNhat(formik.values.requestDTO).then(res => {
            onSubmitData(res);
        });
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...formik.values.requestDTO?.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...formik.values.requestDTO,
            files: mems
        };
        formik.setFieldValue("requestDTO", dataNew);
        //onUpdateData("data", dataNew);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let mems = [...formik.values.requestDTO?.files];
        let memTrashs = formik.values.requestDTO?.fileTrash ? formik.values.requestDTO?.fileTrash : [];
        if (item?.fileParentId > 0) {
            memTrashs.push(item);
        }
        mems.splice(index, 1);
        let dataNew = {
            ...formik.values.requestDTO,
            files: mems,
            fileTrash: memTrashs
        };
        setAttachFileServer(mems);
        formik.setFieldValue("requestDTO", dataNew);
        //onUpdateData("data", dataNew);
    }


    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const layKetQuaSerVice = new LayKetQuaGiayPhepService();
    const handleDongBoGiayPhep = async () => {
        dispatchLoading(showLoading());
        await layKetQuaSerVice.layKetQuaByMaHoSo(formik.values.requestDTO.maHoSo).then((res) => {
            if (res != null && res.data.error_code == "SUCCESSFUL" && res.data.data!==null) {
                if (res?.data?.data?.files && res?.data?.data?.files.length>0){
                    const files: FileEntry[] = [];
                    for (let i = 0 ; i<res?.data?.data?.files.length;i++){
                        const file : FileEntry = {
                            fileEntryId:0,
                            folderId: 0,
                            userId: "",
                            userName: "",
                            name: res.data.data.files[i].fileName,
                            createdDate: 0,
                            extension: "",
                            mimeType: "",
                            orginName: "",
                            description: "",
                            version: "",
                            docId: "",
                            fileParentId: 0,
                            motCua: true,
                            url: res.data.data.files[i].url,
                        }
                       files.push(file);
                    }
                    setAttachFileServer(files);
                    formik.values.requestDTO.files = files;
                }
                formik.values.requestDTO.soGiayPhep = res.data.data.soGiayPhep ? res.data.data.soGiayPhep : null
                formik.values.requestDTO.ngayCapPhep = res.data.data.ngayCap ? res.data.data.ngayCap : null
                if (res.data.data.ngayHetHan!==null){
                    formik.values.requestDTO.ngayHetHanGP = res.data.data.ngayHetHan ? res.data.data.ngayHetHan : null
                }
                const ketQuaGiayPhep: KetQuaGiayPhepDTO={
                    soGiayPhep: res.data.data.soGiayPhep,
                    ngayHetHan : res.data.data.ngayHetHan,
                    files: res.data.data.files,
                    ngayCap: res.data.data.ngayCap,
                }
                formik.values.requestDTO.ketQuaGiayPhep = ketQuaGiayPhep;
                let response = {
                    message: res.data.error_message,
                    isSuccess: true,
                };
                onSubmitData(response);
            }
            else {
                let response = {
                    message: res.data.error_message,
                    isSuccess: false,
                    isWarning: true,
                };
                onSubmitData(response, props.index);
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title"
                open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {'CẬP NHẬT VĂN BẢN'}
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel required>Số Giấy chứng nhận</InputLabel>
                                    <InputBase fullWidth
                                        error={formik.touched.requestDTO?.soGiayPhep && Boolean(formik.errors.requestDTO?.soGiayPhep)}
                                        value={formik.values.requestDTO.soGiayPhep} name="requestDTO.soGiayPhep"
                                        onChange={formik.handleChange} />
                                    {
                                        formik.touched.requestDTO?.soGiayPhep && Boolean(formik.errors.requestDTO?.soGiayPhep) &&
                                        <FormHelperText className="-error">{formik.errors.requestDTO?.soGiayPhep}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel required>Ngày cấp phép</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            placeholder="dd/MM/yyyy"
                                            value={formik.values.requestDTO?.ngayCapPhep > 0 ? new Date(formik.values.requestDTO.ngayCapPhep) : null}
                                            error={formik.touched.requestDTO?.ngayCapPhep && Boolean(formik.errors.requestDTO?.ngayCapPhep)}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => formik.setFieldValue("requestDTO.ngayCapPhep", date?.toDate()?.getTime())}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {
                                        formik.touched.requestDTO?.ngayCapPhep && Boolean(formik.errors.requestDTO?.ngayCapPhep) &&
                                        <FormHelperText className="-error">{formik.errors.requestDTO?.ngayCapPhep}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel >Ngày hết hạn</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            placeholder="dd/MM/yyyy"
                                            value={formik.values.requestDTO?.ngayHetHanGP > 0 ? new Date(formik.values.requestDTO.ngayHetHanGP) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => formik.setFieldValue("requestDTO.ngayHetHanGP", date?.toDate()?.getTime())}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel >Ghi chú</InputLabel>
                                    <InputBase fullWidth
                                        multiline
                                        rows="4"
                                        name="requestDTO.ghiChu"
                                        value={formik.values.requestDTO.ghiChu} onChange={formik.handleChange} />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel >Người ký</InputLabel>
                                    <InputBase fullWidth
                                        value={formik.values.requestDTO.nguoiKy} name="requestDTO.nguoiKy" onChange={formik.handleChange} />
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={`${acceptFiles}`}
                                        attachFileServer={attachFileServer}
                                        url={axios.defaults.baseURL + "/file-server/upload/lpg"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                                <Grid item xs={12} className="gridItem">
                                    <Box marginTop="5px">
                                        <Button type="submit" variant="contained" color="primary" disableElevation
                                        >
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="secondary" disableElevation
                                            onClick={() => {
                                                if (autoCompleteRef.current) {
                                                    let clearButton = autoCompleteRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
                                                    clearButton.click();
                                                }
                                                formik.resetForm({ values: initDefaultValue, });
                                            }}
                                        > Xóa
                                        </Button>
                                        <Button variant="contained" color="primary" disableElevation

                                            onClick={props.onDialogClose}>
                                            Đóng
                                        </Button>
                                        {props.itemEdit.soGiayPhep && props.itemEdit.maHoSo  ?
                                            <Button variant="outlined" disableElevation onClick={
                                                () => handleDongBoGiayPhep()
                                            }>
                                                Lấy kết quả
                                            </Button>
                                            : ""
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default CapSoGiayPhepModal;
