import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, FormHelperText, Grid, InputBase, InputLabel, Radio, RadioGroup } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import StringUtil from '../../../../utils/stringUtils';
import { HoSoThuLyDTO } from '../../../../../api/DKKD/model/hoSoThuLyDTO'
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import axios from "../../../../../api/index";
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { GiayPhepKinhDoanhDTO } from "../../../../../api/DKKD/model/giayPhepKinhDoanhDTO";
import { DangKyKinhDoanhService } from "../../../../../api/DKKD/DangKyKinhDoanhService";
import { BienDong, TinhTrangString } from "../../../../../api/DKKD/model/BienDongTrangThaiGPKDDTO";
import { Autocomplete } from "@material-ui/lab";
import { SearchGiayPhepDTO } from "../../../../../api/DKKD/model/SearchGiayPhepDTO";
import { TINHTRANG_HOATDONG } from '../../../../Constants/DKKDConstants';
import DateUtil from '../../../../utils/dateUtils';

interface TamNgungHoatDongHKDProps {
    onUpdateData: (name: string, value: any) => void;
    loadGiayPhepDaCap: (soGiayPhep: string) => void;
    data: HoSoThuLyDTO;
    submit: boolean;
    openSoGiayPhepCu: boolean;
    listGiayPhep: SearchGiayPhepDTO[];
}

export const ThongTinChungComponent = (props: TamNgungHoatDongHKDProps) => {

    const [data, setData] = useState({} as HoSoThuLyDTO);
    const [listGiayPhep, setListGiayPhep] = React.useState([] as SearchGiayPhepDTO[]);
    const { onUpdateData, loadGiayPhepDaCap, submit, openSoGiayPhepCu, } = props;
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const fileServerService = new FileServerService();

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    }

    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const handleSelectGiayPhepDaCap = (newValue: GiayPhepKinhDoanhDTO) => {
        loadGiayPhepDaCap(newValue.soGiayPhep);
    }
    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        let dataNew = {
            ...data,
            soGiayPhepCu: soGiayPhepCuNew
        };
        onUpdateData('data', dataNew);
        dangKyKinhDoanhService.autocompleteBySoGiayPhep(soGiayPhepCuNew, TINHTRANG_HOATDONG.ma, 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setListGiayPhep([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
        setListGiayPhep(props.listGiayPhep);
    }, [props.onUpdateData, props.onUpdateData]);
    return (
        <>
            {
                <Box padding={0}>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel required>Số giấy chứng nhận </InputLabel>
                            <Autocomplete id="soGiayPhep"
                                noOptionsText="Không có dữ liệu"
                                options={listGiayPhep}
                                getOptionLabel={(option) => option?.soGiayPhep}
                                onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth
                                            {...params.inputProps}
                                            value={data?.soGiayPhepCu}
                                            error={submit && StringUtil.isNullOrEmty(data?.soGiayPhepCu)}
                                            onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}

                                        />
                                    </div>
                                }}
                            />
                            {
                                submit && StringUtil.isNullOrEmty(data?.soGiayPhepCu) &&
                                <FormHelperText className="-error"  >Vui lòng nhập số giấy chứng nhận </FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel>Ngày cấp phép </InputLabel>
                            <InputBase fullWidth disabled readOnly
                                value={DateUtil.timeToDDMMYYYY(data.ngayCapPhep)} />
                        </Grid>
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel>Ngày hết hạn </InputLabel>
                            <InputBase fullWidth disabled readOnly
                                value={DateUtil.timeToDDMMYYYY(data.ngayHetHan)} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" hidden={!openSoGiayPhepCu}>
                        <Grid item xs={12} className="grid-item">
                            <InputLabel >Thông tin về đại diện hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Họ tên</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.hoTen}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Giới tính</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenGioiTinh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày sinh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.ngaySinh !== null && data?.ngaySinh > 0) ? moment(data?.ngaySinh).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Dân tộc</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenDanToc}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Quốc tịch</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenQuocTich}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Loại giấy tờ tùy thân</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.loaiGiayTo == 1 ? 'Chứng minh nhân dân' : (data?.loaiGiayTo == 2 ? 'Căn cước công dân' : 'Hộ chiếu')}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số giấy tờ tùy thân</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.soGiayToTuyThan}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.ngayCap !== null && data?.ngayCap > 0) ? moment(data?.ngayCap).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Nơi cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenNoiCap}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày hết hạn</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.ngayHetHan !== null && data?.ngayHetHan > 0) ? moment(data?.ngayHetHan).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chủ thể thành lập hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item custom__radio__button">
                            <RadioGroup row aria-label="position">
                                <FormControlLabel
                                    checked={data?.loaiChuThe == 1}
                                    value="1"
                                    control={<Radio color="primary" size="small" />}
                                    label="Nhóm cá nhân"
                                    labelPlacement="end"
                                    disabled
                                />
                                <FormControlLabel
                                    checked={data?.loaiChuThe == 2}
                                    disabled
                                    value="2"
                                    control={<Radio color="primary" size="small" />}
                                    label="Cá nhân"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Nơi đăng ký hộ khẩu thường trú</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.hoKhau}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chỗ ở hiện tại</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.choO}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Tên hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenHoKinhDoanh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Tổng số vốn (VNĐ)</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tongVon}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số lượng lao động</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.soLuongLaoDong}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Lĩnh vực kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.tenLinhVuc}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Mặt hàng kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.matHang}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Ngành, nghề kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.tenDanhSachNganhNgheKinhDoanh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chi tiết ngành, nghề kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.chiTietNganhNghe}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Địa điểm kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.soNha}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Điện thoại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.dienThoai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Fax</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.fax}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Email</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.email}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Website</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.website}</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={6} className="grid-item">
                            <InputLabel required>Lý do tạm ngưng</InputLabel>
                            <InputBase fullWidth name="lyDoThayDoi" error={submit && StringUtil.isNullOrEmty(data?.lyDoThayDoi)}
                                value={data?.lyDoThayDoi} onChange={handleChange} />
                            {
                                submit && StringUtil.isNullOrEmty(data?.lyDoThayDoi) &&
                                <FormHelperText className="-error">Vui lòng nhập lý do cấp lại</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel required>Ngày tạm ngưng</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    error={submit && (!(data.ngayBienDong !== 0 && data.ngayBienDong !== null && data.ngayBienDong !== undefined) || data.ngayBienDong < data.ngayCapPhep)}
                                    value={(data.ngayBienDong !== 0 && data.ngayBienDong !== null && data.ngayBienDong !== undefined) ? new Date(data.ngayBienDong) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayBienDong')}

                                />
                                {
                                    submit && !(data.ngayBienDong !== 0 && data.ngayBienDong !== null && data.ngayBienDong !== undefined) &&
                                    <FormHelperText className="-error">Vui lòng nhập ngày tạm ngưng</FormHelperText>
                                }
                                {
                                    submit && data.ngayBienDong < data.ngayCapPhep &&
                                    <FormHelperText className="-error">Vui lòng nhập ngày sau ngày cấp phép đã được cấp</FormHelperText>
                                }
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Từ ngày</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    placeholder="dd/MM/yyyy"
                                    value={data.tuNgay > 0 ? new Date(data.tuNgay) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'tuNgay')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Đến ngày</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    placeholder="dd/MM/yyyy"
                                    value={data.denNgay > 0 ? new Date(data.denNgay) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'denNgay')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={12} className="grid-item">
                            <InputLabel>Ghi chú</InputLabel>
                            <InputBase fullWidth
                                multiline
                                rows="4"
                                value={data?.noiDungThayDoi} name="noiDungThayDoi" onChange={handleChange} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <InputLabel>Nơi nhận</InputLabel>
                        <InputBase rows={5} multiline fullWidth value={data?.noiNhan} name="noiNhan" onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} container className="grid-item">
                        <DropZoneCustom
                            acceptFiles={ACCEPT_FILES}
                            attachFileServer={attachFileServer}
                            url={axios.defaults.baseURL + "/file-server/upload/dkkd"}
                            handleChangeStatus={handleChangeStatus}
                            handleDeleteFileServer={handleDeleteFileServer}
                            handleDownloadFile={handleDownloadFile}
                        />
                    </Grid>
                </Box>
            }
        </>
    )
};
