import MomentUtils from "@date-io/moment";
import {
    Box, FormHelperText, Grid,
    InputBase,
    InputLabel,
    Snackbar
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { Dispatch, useEffect, useState } from 'react';
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import AXIOS_INSTANCE from "../../../../../../api";
import { createFileType, downLoadFile } from "../../../../../../api/Common/exportUtil";
import { FileEntry } from "../../../../../../api/Common/fileEntry";
import { FileServerService } from "../../../../../../api/Common/FileServerService";
import { CapMoiSXRuouService } from '../../../../../../api/CPBLR/CapMoiSXRuouService';
import { GiayPhepSXRuou, HoSoThuLyBLR } from "../../../../../../api/CPBLR/model/HoSoThuLyBLR";
import { TBDiaDiemBLRuouDTO } from "../../../../../../api/CPBLR/model/TBDiaDiemBLRuouDTO";
import { TBPhanPhoiRuouDTO } from "../../../../../../api/CPBLR/model/TBPhanPhoiRuouDTO";
import { TBSanXuatRuouDTO } from "../../../../../../api/CPBLR/model/TBSanXuatRuouDTO";
import { DanhMucService } from '../../../../../../api/danhMuc/danhMucService';
import { DanhMucDiaChiDTO } from '../../../../../../api/danhMuc/model/DanhMucDTO';
import DropZoneCustom from "../../../../../commons/components/CustomDropzone/DropZoneCustom";
import StringUtil from "../../../../../utils/stringUtils";
import TableDiaDiemBLRuouComponent from "../Table/TableDiaDiemBLRuouComponent";
import TablePhanPhoiRuouComponent from "../Table/TablePhanPhoiRuouComponent";
import TableSanXuatRuouComponent from '../Table/TableSanXuatRuouComponent';
import SearchIcon from '@material-ui/icons/Search';
import DateUtil from "../../../../../utils/dateUtils";
import { AlertMessage, CustomAlert } from "../../../../../commons/components/alert";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../../../redux/actions/applicationAction";

interface CapDoiSXRuouNoiDungProps {
    data: HoSoThuLyBLR;
    loadGiayPhepDaCap: (soGiayPhep: string) => void;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
}

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

const CapDoiSXRuouNoiDungComponent = (props: CapDoiSXRuouNoiDungProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = React.useState({} as HoSoThuLyBLR);
    const { onUpdateData, submit, loadGiayPhepDaCap } = props;
    const [showCapSo, setShowCapSo] = useState<boolean>(false);
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const fileServerService = new FileServerService();
    const capMoiSXRuouService = new CapMoiSXRuouService();
    const [dataAutocomple, setDataAutocomple] = useState<GiayPhepSXRuou[]>(new Array<GiayPhepSXRuou>());
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongTruSos, setPhuongTruSos] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongSXs, setPhuongSXs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);

    useEffect(() => {
        loadQuans('92');
    }, []);

    const loadQuans = (maThanhPho: string) => {
        if(!StringUtil.isEmty(maThanhPho)){
            danhMucService.getDanhMucDiaChi(maThanhPho).then(res => {
                if (res.length > 0) {
                    setQuans(res);
                }
            }).catch(error => {
                console.log(error);
            });
        }
       
    }
    const loadPhuongTruSos = (maQuan: string) => {
        danhMucService.getDanhMucDiaChi(maQuan).then(res => {
            setPhuongTruSos(res);
        }).catch(error => {
            console.log(error);
        });
    }
    const loadPhuongSXs = (maQuan: string) => {
        danhMucService.getDanhMucDiaChi(maQuan).then(res => {
            setPhuongSXs(res);
        }).catch(error => {
            console.log(error);
        });
    }
    const handleChangeSelectQuanTruSo = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, maPhuongTruSo: '', tenPhuongTruSo: '', [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    const handleChangeSelectQuanSX = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, maPhuongSX: '', tenPhuongSX: '', [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
        setShowCapSo(props.data?.donViCapPhep)
    }, [props.data, props.onUpdateData, props.loadGiayPhepDaCap]);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }

    const setSanXuatRuous = (memData: TBSanXuatRuouDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data.sanXuatRuous];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            sanXuatRuous: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const setPhanPhoiRuous = (memData: TBPhanPhoiRuouDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data.phanPhoiRuous];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            phanPhoiRuous: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const setDiaDiemBLRuou = (memData: TBDiaDiemBLRuouDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data.diaDiemBLRuous];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            diaDiemBLRuous: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const handleChangeDonViCapPhep = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setShowCapSo(value);
        onUpdateData(e.target.name, value);
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            files: mems
        };
        onUpdateData("data", dataNew);
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        let memTrashs = data.fileTrash ? data.fileTrash : [];
        if (item?.fileParentId > 0) {
            memTrashs.push(item);
        }
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            files: mems,
            fileTrash: memTrashs
        };
        setAttachFileServer(mems);
        onUpdateData("data", dataNew);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    const handleSelectGiayPhepDaCap = (newValue: GiayPhepSXRuou) => {
        onUpdateData('soGiayPhepCu', newValue.soGiayPhep);
        loadGiayPhepDaCap(newValue.soGiayPhep);
    }
    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        let dataNew = { ...data, soGiayPhepCu: soGiayPhepCuNew };
        onUpdateData("data", dataNew);
        capMoiSXRuouService.getAutocompleteBySoGiayPhep_LoaiGiayPhep_PageSize(soGiayPhepCuNew, 'SXR', 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setDataAutocomple([...dataAutocompleNew]);
            } else {
                setDataAutocomple([]);
            }
        }).catch(error => {
            setDataAutocomple([]);
            console.log(error);
        });
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const onSearchDKKD = (maSoDoanhNghiep: string) => {
        if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
            showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoDKKD({maSoDoanhNghiep: maSoDoanhNghiep}).then(res => {
            let dataNew = res?.data.data;
            if (dataNew !== null && dataNew !== undefined) {
                if (dataNew.error_code === 'SUCCESSFUL' && dataNew.data && dataNew.data != null) {
                const tested = DateUtil.stringDDMMYYYtoDate(dataNew.data.ngayThanhLap.split(' ')[0]);

                let dataNewDKKD = {
                    ...data,
                    dienThoaiTruSo: dataNew.data.dienThoai,
                    soNhaTruSo: dataNew.data.soNha,
                    faxTruSo: dataNew.data.fax,
                    ngayCap: tested!==null? tested.getTime():0,
                };
                onUpdateData("data", dataNewDKKD);
                }   else {
                    setAlertMessage({ open: true, content: "Giấy CNĐKKD/DN/HTX/HKD không tồn tại trong dữ liệu", severity: 'warning' });
                }
            } 
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    return (
        <>
            {
                <Box padding={0}>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Số giấy phép </InputLabel>
                                <Autocomplete id="soGiayPhepCu"
                                    options={dataAutocomple}
                                    noOptionsText={"Không có dữ liệu"}
                                    getOptionLabel={(option) => option.soGiayPhep}
                                    onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data.soGiayPhepCu)}
                                                {...params.inputProps}
                                                value={data.soGiayPhepCu}
                                                onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}
                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.soGiayPhepCu) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số giấy phép </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày cấp </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={true}
                                        readOnly={true}
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayCapPhep != null && data?.ngayCapPhep !== undefined && data?.ngayCapPhep != 0) ? new Date(data?.ngayCapPhep) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        disabled={true}
                                        readOnly={true}
                                        showTodayButton={true}
                                        value={(data?.ngayHetHanGP !== undefined && data?.ngayHetHanGP != 0) ? new Date(data?.ngayHetHanGP) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel>Tên tổ chức, cá nhân đề nghị </InputLabel>
                                <InputBase fullWidth
                                    value={data?.tenToChuc} name="tenToChuc" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Giấy CNĐKKD/DN/HTX/HKD </InputLabel>
                                <InputBase style={{ width: '85%' }}
                                    error={submit && StringUtil.isNullOrEmty(data?.giayCNDK)}
                                    value={data?.giayCNDK} name="giayCNDK" onChange={handleChange} />
                                    <SearchIcon fontSize="default" style={{ cursor: 'pointer', marginTop: '0px', marginLeft: '3px' }} onClick={(e) => onSearchDKKD(data?.giayCNDK)}/>
                                {
                                    submit && StringUtil.isNullOrEmty(data?.giayCNDK) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn giấy CNĐKKD/DN/HTX/HKD</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel required>Tên thương nhân </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.tenThuongNhan)}
                                    value={data?.tenThuongNhan} name="tenThuongNhan" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.tenThuongNhan) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên thương nhân</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Địa chỉ trụ sở chính </InputLabel>
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                {/* <InputBase fullWidth readOnly disabled
                                    value={data?.tenQuanTruSo} name="tenQuanTruSo" /> */}
                                <Autocomplete id="maQuanTruSo"
                                    options={quans}
                                    value={{ code: data?.maQuanTruSo ? data?.maQuanTruSo : "", name: data?.tenQuanTruSo ? data?.tenQuanTruSo : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectQuanTruSo(newValue, 'maQuanTruSo', 'tenQuanTruSo')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maQuanTruSo)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maQuanTruSo) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuongTruSo"
                                    options={phuongTruSos}
                                    value={{ code: data?.maPhuongTruSo ? data?.maPhuongTruSo : "", name: data?.tenPhuongTruSo ? data?.tenPhuongTruSo : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectPhuong(newValue, 'maPhuongTruSo', 'tenPhuongTruSo')}
                                    onFocus={(e) => loadPhuongTruSos(data.maQuanTruSo)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth

                                                error={submit && StringUtil.isNullOrEmty(data?.maPhuongTruSo)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maPhuongTruSo) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Đường </InputLabel>
                                <InputBase fullWidth value={data.tenDuongTruSo} name="tenDuongTruSo" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số nhà, đường </InputLabel>
                                <InputBase fullWidth
                                    value={data?.soNhaTruSo} name="soNhaTruSo" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Điện thoại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.dienThoaiTruSo} name="dienThoaiTruSo" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Fax </InputLabel>
                                <InputBase fullWidth
                                    value={data?.faxTruSo} name="faxTruSo" onChange={handleChange} />
                            </Grid> 
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayCap != null && data?.ngayCap !== undefined && data?.ngayCap != 0) ? new Date(data?.ngayCap) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCap')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Nơi cấp </InputLabel>
                                <InputBase fullWidth
                                    value={data?.noiCap} name="noiCap" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Địa chỉ sản xuất/kinh doanh </InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                {/* <InputBase fullWidth readOnly disabled
                                    value={data?.tenQuanSX} name="tenQuanSX" /> */}
                                <Autocomplete id="maQuanSX"
                                    options={quans}
                                    value={{ code: data?.maQuanSX ? data?.maQuanSX : "", name: data?.tenQuanSX ? data?.tenQuanSX : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectQuanSX(newValue, 'maQuanSX', 'tenQuanSX')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maQuanSX)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maQuanSX) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuongSX"
                                    options={phuongSXs}
                                    value={{ code: data?.maPhuongSX ? data?.maPhuongSX : "", name: data?.tenPhuongSX ? data?.tenPhuongSX : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectPhuong(newValue, 'maPhuongSX', 'tenPhuongSX')}
                                    onFocus={(e) => loadPhuongSXs(data.maQuanSX)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth

                                                error={submit && StringUtil.isNullOrEmty(data?.maPhuongSX)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maPhuongSX) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Đường </InputLabel>
                                <InputBase fullWidth value={data.tenPhuongSX} name="tenPhuongSX" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số nhà, đường </InputLabel>
                                <InputBase fullWidth
                                    value={data?.soNhaSX} name="soNhaSX" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Điện thoại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.dienThoaiSX} name="dienThoaiSX" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Fax </InputLabel>
                                <InputBase fullWidth
                                    value={data?.faxSX} name="faxSX" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Thông tin sản xuất rượu </InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <TableSanXuatRuouComponent data={data.sanXuatRuous} onUpdateData={setSanXuatRuous} />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Thông tin phân phối rượu </InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <TablePhanPhoiRuouComponent data={data.phanPhoiRuous} onUpdateData={setPhanPhoiRuous} />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Thông tin địa điểm bán lẻ rượu </InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <TableDiaDiemBLRuouComponent data={data.diaDiemBLRuous} onUpdateData={setDiaDiemBLRuou} />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required >Lý do sửa đổi bổ sung  </InputLabel>
                                <InputBase fullWidth
                                    value={data?.lyDoSuaDoi} name="lyDoSuaDoi" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.lyDoSuaDoi) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập lý do sửa đổi bổ sung </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Lần sửa đổi bổ sung</InputLabel>
                                <InputBase fullWidth
                                    value={data?.soLan} name="soLan" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày sửa đổi bổ sung</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngaySuaDoi != null && data?.ngaySuaDoi !== undefined && data?.ngaySuaDoi != 0) ? new Date(data?.ngaySuaDoi) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngaySuaDoi')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={`${acceptFiles}`}
                                    attachFileServer={attachFileServer}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/ruou"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
        </>
    )
}
export default CapDoiSXRuouNoiDungComponent;
