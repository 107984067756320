import StringUtil from '../../App/utils/stringUtils';
import * as Types from '../actions/actionTypes';
const initialState = {
    listInvestor: Array<any>(), total: 0
};
export const investorReducer = (state = initialState, action: any) => {
    const { index, investor} = action;
    switch (action.type) {
        case Types.ADD_INVESTOR:
            state.listInvestor.push(investor);   
            state.total= state.listInvestor ? state.listInvestor.length : 0 ; 
            return {
               ...state,
            };
        case Types.UPDATE_INVESTOR:
            console.log('update', investor);
            console.log('indet', index);
            state.listInvestor[index] = investor; 
            return {
                ...state,
             };
        case Types.DELETE_INVESTOR:
            state.listInvestor.splice(index, 1);
            state.total= state.listInvestor ? state.listInvestor.length : 0 ; 
            return {
                ...state,     
            };
        case Types.ADD_ALL_INVESTOR :
            const {investors} = action;
            const newList = [...investors];
            state.total  = StringUtil.isEmty(newList) ?  0 : newList.length ; 
            return {
                ...state,
                listInvestor : newList
            };
        default:
        return {...state};
    }
};