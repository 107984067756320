import { useEffect, useState } from 'react';
import { GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { Box, Grid, InputLabel, FormHelperText, InputBase } from '@material-ui/core';
import StringUtil from '../../../../utils/stringUtils';
import { Autocomplete } from '@material-ui/lab';
import moment from "moment";
import { GIAY_PHEP_XAY_DUNG_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { CapPhepXayDungService } from '../../../../../api/CPXD/CapPhepXayDungService';
import { LOAIGIAYPHEP_CTCTH, LOAIGIAYPHEP_DIDOI, LOAIGIAYPHEP_NORL, LOAIGIAYPHEP_SUACHUACAITAO, TINHTRANG_HOATDONG } from '../../../../Constants/CapPhepXayDungConstants';
import NhaORiengLeCapMoiNoiDungComponent from '../CapMoiNhaORiengLe/NhaORiengLeCapMoiNoiDungComponent';
import CongTrinhCoThoiHanNoiDungComponent from '../CapMoiCongTrinhCoThoiHan/CongTrinhCoThoiHanNoiDungComponent';
import ThongTinDiDoiComponent from '../DiDoi/ThongTinDiDoiComponent';
import NoiDungSuaChuaCaiTaoCPXD from '../SuaChuaCaiTao/NoiDungSuaChuaCaiTaoCPXD';
interface NhaORiengLeCapLaiNoiDungProps {
    data: GiayPhepXayDungDTO;
    submit: boolean;
    isNhapDauKy: boolean;
    onUpdateData: (name: string, value: any) => void;
    loadGiayPhepDaCap: (soGiayPhepCu: string) => void;
    resetData: () => void;
}

const NhaORiengLeCapLaiNoiDungComponent = (props: NhaORiengLeCapLaiNoiDungProps) => {
    const [data, setData] = useState({ ...GIAY_PHEP_XAY_DUNG_INIT });
    const [dataAutocomple, setDataAutocomple] = useState<GiayPhepXayDungDTO[]>(new Array<GiayPhepXayDungDTO>());
    const [submit, setSubmit] = useState(false);
    const { onUpdateData, loadGiayPhepDaCap, isNhapDauKy, resetData } = props;
    const capPhepXayDungService = new CapPhepXayDungService();
    useEffect(() => {
        setData(props.data);
        setSubmit(props.submit);
    }, [props.data, props.submit, props.onUpdateData]);

    const handleSelectGiayPhepDaCap = (newValue: GiayPhepXayDungDTO) => {
        onUpdateData('soGiayPhepCu', newValue.soGiayPhep);
        loadGiayPhepDaCap(newValue.soGiayPhep);
    }
    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        onUpdateData('soGiayPhepCu', soGiayPhepCuNew);
        capPhepXayDungService.getAutocompleteBySoGiayPhep_MaTrangThai_PageSize(soGiayPhepCuNew, TINHTRANG_HOATDONG.ma, 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setDataAutocomple([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    return (
        <>
            {
                <Box padding={0}>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Số giấy phép </InputLabel>
                                <Autocomplete id="soGiayPhepCu"
                                    options={dataAutocomple}
                                    value={data}
                                    noOptionsText={"Không có dữ liệu"}
                                    getOptionLabel={(option) => option.soGiayPhep}
                                    onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && (StringUtil.isNullOrEmty(data.maLoaiGiayPhep) || StringUtil.isNullOrEmty(data.soGiayPhepCu))}
                                                {...params.inputProps}
                                                value={data.soGiayPhepCu}
                                                onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}
                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && (StringUtil.isNullOrEmty(data.maLoaiGiayPhep) || StringUtil.isNullOrEmty(data.soGiayPhepCu)) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số giấy phép </FormHelperText>
                                }

                            </Grid>
                            {
                                !StringUtil.isNullOrEmty(data.maLoaiGiayPhep) &&
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Ngày cấp phép </InputLabel>
                                    <InputBase fullWidth
                                        value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu !== null) ? moment(data.ngayCapPhepCu).format("DD/MM/YYYY") : ''} disabled name="ngayCapPhepCu" />
                                </Grid>
                            }

                        </Grid>
                        <Grid container>
                            {
                                !StringUtil.isNullOrEmty(data.maLoaiGiayPhep) && data.maLoaiGiayPhep === LOAIGIAYPHEP_CTCTH.ma &&
                                <CongTrinhCoThoiHanNoiDungComponent
                                    onUpdateData={onUpdateData}
                                    submit={submit}
                                    data={data}
                                    isNhapDauKy={isNhapDauKy}
                                    
                                />
                            }
                            {
                                !StringUtil.isNullOrEmty(data.maLoaiGiayPhep) && data.maLoaiGiayPhep === LOAIGIAYPHEP_NORL.ma &&
                                <NhaORiengLeCapMoiNoiDungComponent
                                    onUpdateData={onUpdateData}
                                    submit={submit}
                                    data={data}
                                    isNhapDauKy={isNhapDauKy}
                                />
                            }
                            {
                                !StringUtil.isNullOrEmty(data.maLoaiGiayPhep) && data.maLoaiGiayPhep === LOAIGIAYPHEP_DIDOI.ma &&
                                <ThongTinDiDoiComponent
                                    onUpdateData={onUpdateData}
                                    submit={submit}
                                    isNhapDauKy={isNhapDauKy}
                                    data={data}
                                    resetData={resetData}
                                    loadGiayPhepDaCap={loadGiayPhepDaCap} />
                            }
                            {
                                !StringUtil.isNullOrEmty(data.maLoaiGiayPhep) && data.maLoaiGiayPhep === LOAIGIAYPHEP_SUACHUACAITAO.ma &&
                                <NoiDungSuaChuaCaiTaoCPXD
                                    onUpdateData={onUpdateData}
                                    submit={submit}
                                    data={data}
                                    isNhapDauKy={isNhapDauKy}
                                    loadGiayPhepDaCap={loadGiayPhepDaCap} />
                            }




                        </Grid>
                    </Box>

                </Box>
            }
        </>
    )
}
export default NhaORiengLeCapLaiNoiDungComponent;