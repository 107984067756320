import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageRequest } from '../../../../../api/baseService';
import { DanhMucGP, DanhMucGP_INIT, SearchGPTuDongRequest } from '../../../../../api/Common/model/CommonModel';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { DanhMucCoQuanDTO, DanhMucDiaChiDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { LinhVucCapSoGPService } from '../../../../../api/DKKD/LinhVucCapSoGPService';
import { LinhVucKinhDoanhService } from '../../../../../api/DKKD/LinhVucKinhDoanhService';
import { LinhVucNoiCapService } from '../../../../../api/DKKD/LinhVucNoiCapService';
import { UserData } from '../../../../../api/userMgt/userManagementService';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { AlertMessage, CustomAlert } from '../../../../commons/components/alert';
import ButtonExt from '../../../../commons/components/button/customButton';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import StringUtil from '../../../../utils/stringUtils';
import { DataList } from './DataList';
import { EditDialog } from './EditDialog';



export const DMCungCapGPComponent = () => {
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataList, setDataList] = useState<DanhMucGP[]>([]);
    const [itemSeleted, setItemSeleted] = useState<DanhMucGP>({} as DanhMucGP);
    const [coQuans, setCoQuans] = useState<DanhMucCoQuanDTO[]>([]);
    const [total, setTotal] = useState(0);
    const danhMucService = new DanhMucService();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    let C_USER_DATA_DE = {} as UserData;
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    const [searchRequest, setSearchRequest] = useState<SearchGPTuDongRequest>({
        pageSize: 5,
        pageNumber: 1,
        hoatDong: 0,
        keywords: "",
        tenCoQuan: C_USER_DATA_DE.tenCoQuan,
        maCoQuan: C_USER_DATA_DE.maCoQuan
    });

    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const service = new LinhVucCapSoGPService();

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const loadCoQuans = () => {
        danhMucService.getDanhMucCoQuan_HoatDong(true).then((p) => {
            setCoQuans(p);
        }).catch((e) => {
            console.log(e);
        });
    };

    const loadDataTable = (request: SearchGPTuDongRequest) => {

        dispatch(showLoading());
        service.search(request).then((p) => {
            if (p && p.data && p.data.data && p.data.error_code == 'SUCCESSFUL') {
                setDataList(p.data.data.data);
                setTotal(p.data.data.total);
            } else {
                showMessageAlert("Yêu cầu thực hiện thất bại", false)
            }

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            dispatch(hideLoading());
        });
    }
    useEffect(() => {
        loadDataTable(searchRequest);
        loadCoQuans();
    }, []);

    const doSearch = () => {
        let searchRequestNew = { ...searchRequest, pageNumber: 1 };
        setSearchRequest(searchRequestNew);
        loadDataTable(searchRequestNew);
    }
    const handleSubmitData = (content: string, isSuccess: boolean) => {
        console.log('isSuccess : ', isSuccess)
        if (isSuccess) {
            setOpenDialog(false);
            let searchRequestNew = { ...searchRequest, pageNumber: 1 };
            setSearchRequest(searchRequestNew);
            loadDataTable(searchRequestNew);
            showMessageAlert("Yêu cầu thực hiện thành công", true)
        } else {
            showMessageAlert("Yêu cầu thực hiện thất bại", false)
        }
    }
    const showEditDialog = (item: DanhMucGP) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchRequestNew = { ...searchRequest, pageNumber: value };
        setSearchRequest(searchRequestNew);
        loadDataTable(searchRequestNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchRequestNew = { ...searchRequest, pageSize: Number(event.target.value), pageNumber: 1 };
        setSearchRequest(searchRequestNew);
        loadDataTable(searchRequestNew);
    }

    const handleDeleteItem = (request: DanhMucGP) => {
        if (request) {
            setItemSeleted(request);
            setOpenDeleteUserDlg(true);
        }
    }

    const handleChangeSelect = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        setSearchRequest(searchRequest => ({
            ...searchRequest,
            [nameValue]: value?.ma,
            [nameText]: valueText,
        }));
    };

    const handleCloseDeleteRole = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (itemSeleted.id) {
                danhMucService.delete(itemSeleted.id).then((p) => {
                    if (p.data.error_code === 'SUCCESSFUL') {
                        showMessageAlert('Yêu cầu thực hiện thành công');
                        loadDataTable(searchRequest);
                    } else {
                        showMessageAlert('Yêu cầu thực hiện thất bại', false);
                    }
                }).catch((e) => {
                    showMessageAlert('Yêu cầu thực hiện thất bại', false);
                    console.log(e);
                }).finally(() => {
                    dispatchLoading(hideLoading());
                });
            }
        }
        setOpenDeleteUserDlg(false);
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }))
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Cấu hình số giấy phép</span>
            </div>
            <form noValidate autoComplete="off">
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={6}>
                        <InputLabel >Tên cơ quan</InputLabel>
                        {
                            StringUtil.isEmty(C_USER_DATA_DE.maCoQuan) &&
                            <Autocomplete
                                id="maCoQuan"
                                options={coQuans}
                                getOptionLabel={(option) => option?.ten}
                                value={{ ma: searchRequest.maCoQuan, ten: searchRequest.tenCoQuan }}
                                onChange={(event: any, newValue: any) => {
                                    handleChangeSelect(newValue, "maCoQuan", "tenCoQuan");
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined"

                                />}
                            />
                        }
                        {
                            !StringUtil.isEmty(C_USER_DATA_DE.maCoQuan) &&
                            <InputBase disabled fullWidth value={C_USER_DATA_DE.tenCoQuan} />
                        }
                    </Grid>
                    <Grid item xs={6} className="gridItem">
                        <InputLabel >Hoạt động</InputLabel>
                        <NativeSelect input={<InputBase fullWidth />}
                            value={searchRequest.hoatDong} onChange={(e) => setSearchRequest({ ...searchRequest, hoatDong: Number(e.target.value) })} >
                            <option value={0}></option>
                            <option value={1}>Hoạt động</option>
                            <option value={2}>Ngừng hoạt động</option>
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={12} className="button-group-center">
                        <Button variant="contained" color="primary" disableElevation
                            onClick={doSearch}>
                            Tìm kiếm
                        </Button>
                        <ButtonExt variant="contained" color="primary" disableElevation
                            onClick={() => showEditDialog({ ...DanhMucGP_INIT })}>
                            Thêm mới
                        </ButtonExt>
                    </Grid>
                </Grid>
                <div>
                    <DataList dataList={dataList} deleteItem={handleDeleteItem}
                        pageProps={{
                            page: searchRequest.pageNumber, count: total, rowsPerPage: searchRequest.pageSize,
                            onChangePage: handleOnChangePage,
                            onChangeRowsPerPage: handOnChangeRowsPerPage
                        }}
                        modalEdit={{ open: true, openEditDialog: showEditDialog }} />
                    {
                        openDialog &&
                        <EditDialog openDialog={openDialog}
                            onDialogClose={() => setOpenDialog(false)}
                            itemEdit={itemSeleted} onSubmitData={handleSubmitData} />
                    }
                </div>
                <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openDeleteUserDlg}
                    title="Xóa vai trò"
                    content="Bạn có đồng ý xóa dữ liệu này?" onClose={handleCloseDeleteRole}
                />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                </Snackbar>
            </form>
        </div >
    )
}