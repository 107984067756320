import { MuiThemeProvider } from "@material-ui/core";
import { Suspense } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { THEME_CUSTOM } from "../../App/commons/theme/theme";
import { ThemedSuspense } from "../../App/Components/themedSuspense";
import { ROUTE_PAGE_PUBLIC } from "../../routes/routerPublic";
export const PublicPage = () => {
    let { path, url } = useRouteMatch();
    return (
        <>
            {/* <ul className="router_mobile">
                {ROUTE_PAGE_PUBLIC.map((component, i) => (
                    <li>
                        <Link key={i} to={`${url}${component.duongDan}`}>
                            {component.ten}
                        </Link>
                    </li>
                ))}
            </ul> */}
            <MuiThemeProvider theme={THEME_CUSTOM}>
                <Suspense fallback={<ThemedSuspense />}>
                    <Switch>
                        {ROUTE_PAGE_PUBLIC.map((component, i) => (
                            <Route key={i} exact path={`${path}${component.duongDan}`} component={() => component.giaoDien} />
                        ))}
                    </Switch>
                </Suspense>
            </MuiThemeProvider>
        </>
    );
};
