import React, { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
    Box,
    Button,
    Dialog,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { ChuDauTuModel } from '../../../../../../api/CPXD/model/CapPhepXayDungModel';
import { DialogContent, DialogTitle } from '../../../../../commons/components/dialog/dialogCommons';
interface chiTiet {
    data: ChuDauTuModel;
    show: boolean;
    onToggle: () => void;
}

const ChuDauTuModal = (props: chiTiet) => {
    const [data, setData] = React.useState({ loaiDoiTuong: 1 } as ChuDauTuModel);
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [indexEdit, setIndexEdit] = React.useState(-1);
    const { onToggle} = props;
    useEffect(() => {
        setData(props.data);
        setShow(props.show);
    }, [props.data, props.show]);
    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth  maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        {'THÔNG TIN CHI TIẾT ĐỐI TƯỢNG CÁ NHÂN'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container spacing={1}>
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel >Họ và tên</InputLabel>
                                </Grid> 
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel >{data?.hoTen}</InputLabel>
                                </Grid> 
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel >Giới tính</InputLabel>
                                </Grid> 
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel >{data.tenGioiTinh}</InputLabel>
                                </Grid> 
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel >Số giấy tờ</InputLabel>
                                </Grid> 
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel >{data.soGiayTo}</InputLabel>
                                </Grid> 
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel >Ngày cấp</InputLabel>
                                </Grid> 
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel >{data.ngayCap ? moment(data.ngayCap).format("DD/MM/YYYY") : ''}</InputLabel>
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel >Nơi cấp</InputLabel>
                                </Grid> 
                                <Grid item xs={10} className="grid-item">
                                    <InputLabel >{data.tenNoiCap}</InputLabel>
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel >Địa chỉ</InputLabel>
                                </Grid> 
                                <Grid item xs={10} className="grid-item">
                                    <InputLabel >{data.diaChiChiTiet}</InputLabel>
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel >Địa chỉ cũ</InputLabel>
                                </Grid> 
                                <Grid item xs={10} className="grid-item">
                                    <InputLabel >{data.diaChiCu}</InputLabel>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}
export default ChuDauTuModal;