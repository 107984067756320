export type ThuocLaConstants = {
    ten: string;
    ma: string;
}

export const TINHTRANG_HOATDONG: ThuocLaConstants = {
    ten: "Đang hoạt động",
    ma: "HD"
}
export const TINHTRANG_THUHOI: ThuocLaConstants = {
    ten: 'Thu hồi',
    ma: 'TH'
}
export const TINHTRANG_HUY: ThuocLaConstants = {
    ten: 'Hủy',
    ma: 'HH'
}
export const LIST_TINHTRANG: ThuocLaConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_THUHOI,
    TINHTRANG_HUY,
]
export const LOAIBIENDONG_CAPMOI: ThuocLaConstants = {
    ten: "Cấp mới",
    ma: "CM"
}
export const LOAIBIENDONG_CAPLAI: ThuocLaConstants = {
    ten: "Cấp lại",
    ma: "CL"
}
export const LOAIBIENDONG_CAPDOI: ThuocLaConstants = {
    ten: "Cấp đổi",
    ma: "DC"
}
export const LOAIBIENDONG_HUY: ThuocLaConstants = {
    ten: "Hủy",
    ma: "HH"
}
export const LOAIBIENDONG_HOANTRAHUY: ThuocLaConstants = {
    ten: "Hoàn trả hủy",
    ma: "HT-HH"
}
export const LOAIBIENDONG_THUHOI: ThuocLaConstants = {
    ten: "Thu hồi",
    ma: "TH"
}
export const LOAIBIENDONG_HOANTRATHUHOI: ThuocLaConstants = {
    ten: "Hoàn trả thu hồi",
    ma: "HT-TH"
}

export const LIST_BIENDONG: ThuocLaConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_CAPLAI,
    LOAIBIENDONG_HUY,
    LOAIBIENDONG_HOANTRAHUY,
    LOAIBIENDONG_THUHOI,
    LOAIBIENDONG_HOANTRATHUHOI,
    LOAIBIENDONG_CAPDOI
]