import MomentUtils from "@date-io/moment";
import { Box, Button, FormHelperText, Grid, InputAdornment, InputBase, InputLabel, TextField, } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { useEffect, useState } from 'react';
import { IFileWithMeta } from 'react-dropzone-uploader';
import AXIOS_INSTANCE from '../../../../../api';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { DanhMucIView } from '../../../../../api/Common/model/CommonModel';
import { CapCongTrinhService } from '../../../../../api/CPXD/CapCongTrinhService';
import { LoaiCongTrinhService } from '../../../../../api/CPXD/LoaiCongTrinhService';
import { GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { PhanLoaiCongTrinhService } from '../../../../../api/CPXD/PhanLoaiCongTrinhService';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { DanhMucDiaChiDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { LoaiGiayPhepEnum } from "../../../../../api/loaiGiayPhep/loaiGiayPhepEnum";
import { TINH_TP } from '../../../../../api/QuanTri/DanhMucFakeService';
import LayKetQuaGiayPhepService from "../../../../../api/QuanTri/LayKetQuaGiayPhepService";
import { GiayPhepRequest } from "../../../../../api/QuanTri/Model/MotCuaModel";
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import StringUtil from '../../../../utils/stringUtils';
import GiayToVeDatDaiModal from "../Modal/GiayToVeDatDaiModal";
import NhaORiengLeDonViTuVanModal from '../Modal/NhaORiengLeDonViTuVanModal';
import NhaORiengLeSoKyHieuBangVeModal from '../Modal/NhaORiengLeSoKyHieuBangVeModal';
import HangMucTable from '../Table/HangMucTable';
import NhaORiengLeLoDatXayDungComponent from '../Table/NhaORiengLeLoDatXayDungComponent';
interface NoiDungProps {
    data: GiayPhepXayDungDTO;
    submit: boolean;
    onUpdateData: (name: string, value: any) => void;
    isNhapDauKy: boolean;
}


const CongTrinhCoThoiHanNoiDungComponent = (props: NoiDungProps) => {

    const [data, setData] = useState({} as GiayPhepXayDungDTO);
    const [submit, setSubmit] = useState(false);
    const [openDonViTuVanModal, setOpenDonViTuVanModal] = useState(false);
    const [openSoKyHieuBangVeModal, setOpenSoKyHieuBangVeModal] = useState(false);
    const { onUpdateData } = props;
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const fileServerService = new FileServerService();
    const [capCongTrinhs, setCapCongTrinhs] = useState<DanhMucIView[]>([]);
    const [loaiCongTrinhs, setLoaiCongTrinhs] = useState<DanhMucIView[]>([]);
    const [phanLoaiCongTrinhs, setPhanLoaiCongTrinhs] = useState<DanhMucIView[]>([]);
    const capCongTrinhService = new CapCongTrinhService();
    const loaiCongTrinService = new LoaiCongTrinhService();
    const phanLoaiCongTrinhService = new PhanLoaiCongTrinhService();
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);

    const [chiGioiDuongDo, setChiGioiDuongDo] = useState('');
    const [chiGioiXayDung, setChiGioiXayDung] = useState('');

    useEffect(() => {
        capCongTrinhService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setCapCongTrinhs(res.data.data);
            }
        });
        loaiCongTrinService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setLoaiCongTrinhs(res.data.data);
            }
        });
        phanLoaiCongTrinhService.getByHoatDong(true).then((res) => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                setPhanLoaiCongTrinhs(res.data.data);
            }
        });
        loadQuans("92");
    }, []);
    useEffect(() => {
        let dataNew = {
            ...props.data
        }
        setData(dataNew);
        setSubmit(props.submit);
        setIsNhapDauKy(props.isNhapDauKy);

        setChiGioiDuongDo(props.data.chiGioiDuongDo);
        setChiGioiXayDung(props.data.chiGioiXayDung);
    }, [props.data, props.submit, props.onUpdateData]);

    useEffect(() => {
        props.onUpdateData('chiGioiDuongDo', chiGioiDuongDo);
    }, [chiGioiDuongDo]);

    useEffect(() => {
        props.onUpdateData('chiGioiXayDung', chiGioiXayDung);
    }, [ chiGioiXayDung]);

    const handleChangeChiGioiDuongDo = (e: any) => {
        setChiGioiDuongDo(e.target.value);
    }

    const handleChangeChiGioiXayDung = (e: any) => {
        setChiGioiXayDung(e.target.value);
    }
    
    const loadQuans = async (maThanhPho: string) => {
        await danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res && res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };

    const loadPhuongs = async () => {
        if (StringUtil.isNotEmpty(data?.maHuyen)) {
            await danhMucService
                .getDanhMucDiaChi(data?.maHuyen)
                .then((res) => {
                    if (res && res.length > 0) {
                        setPhuongs(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChangeSelectQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText, ['maPhuong']: '', ['tenPhuong']: '' };
        onUpdateData("data", dataNew);
    };

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    };

    const setQuyenSuDungDatParam = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        let giayToQuyenSuDungDat = { ...data.giayToQuyenSuDungDat, [name]: value };
        setData(data => ({
            ...data,
            giayToQuyenSuDungDat: giayToQuyenSuDungDat
        }));
        onUpdateData('giayToQuyenSuDungDat', giayToQuyenSuDungDat);
    }
    const handleChangeNumberQuyenSuDungDat = (e: any) => {
        let value = e.target.value;
        if (StringUtil.allowNumberOrDot(value)) {
            let valueString: string = StringUtil.getNumberDot(value);
            let giayToQuyenSuDungDat = { ...data.giayToQuyenSuDungDat, [e.target.name]: valueString };
            onUpdateData('giayToQuyenSuDungDat', giayToQuyenSuDungDat);
        }
    }

    const handleChangeNumberDienTich = (e: any) => {
        let value = e.target.value;
        let name = e.target.name;
        if (StringUtil.allowNumberOrDot(value)) {
            let valueString: string = StringUtil.getNumberDot(value);
            let giayToQuyenSuDungDat;
            if (name === 'dienTichKhuonVien' && (data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu === 0 || data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu === null)) {
                giayToQuyenSuDungDat = {
                    ...data.giayToQuyenSuDungDat,
                    'dienTichKhuonVien': valueString,
                    'dienTichGiuLai': valueString,
                }
            } else if (name === 'dienTichKhuonVienHienHuu' && (data?.giayToQuyenSuDungDat?.dienTichKhuonVien === 0 || data?.giayToQuyenSuDungDat?.dienTichKhuonVien === null)) {
                giayToQuyenSuDungDat = {
                    ...data.giayToQuyenSuDungDat,
                    'dienTichKhuonVienHienHuu': valueString,
                }
            } else if ((name === 'dienTichKhuonVienHienHuu') &&
                (data?.giayToQuyenSuDungDat?.dienTichKhuonVien && data?.giayToQuyenSuDungDat?.dienTichKhuonVien >= 0 &&
                    Number(valueString) >= 0 &&
                    data?.giayToQuyenSuDungDat?.dienTichKhuonVien >= Number(valueString))) {
                giayToQuyenSuDungDat = {
                    ...data.giayToQuyenSuDungDat,
                    'dienTichKhuonVienHienHuu': valueString,
                    'dienTichGiuLai': StringUtil.tinhDienTich(data?.giayToQuyenSuDungDat?.dienTichKhuonVien, Number(valueString)),
                }
            } else if ((name === 'dienTichKhuonVien') &&
                (data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu && data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu >= 0 &&
                    Number(valueString) >= 0 &&
                    Number(valueString) >= data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu)) {
                giayToQuyenSuDungDat = {
                    ...data.giayToQuyenSuDungDat,
                    'dienTichKhuonVien': valueString,
                    'dienTichGiuLai': StringUtil.tinhDienTich(Number(valueString), data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu),
                }
            } else {
                giayToQuyenSuDungDat = { ...data.giayToQuyenSuDungDat, [e.target.name]: valueString };
            }
            onUpdateData('giayToQuyenSuDungDat', giayToQuyenSuDungDat);
        }
    }

    const handleUpdateData = (name: string, value: string) => {
        onUpdateData(name, value);
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    //đơn vị tư vấn
    const handleDonViTuVanModalToggle = () => {
        setOpenDonViTuVanModal(!openDonViTuVanModal);
    }
    //giấy tờ về đất đai
  
    //Số ký hiệu bảng vẽ
    const handleSoKyHieuBangVeModalToggle = () => {
        setOpenSoKyHieuBangVeModal(!openSoKyHieuBangVeModal);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

  


    return (
        <>
            {
                <Box padding={0}>
                    <NhaORiengLeSoKyHieuBangVeModal
                        onUpdateData={handleUpdateData}
                        onModalToggle={handleSoKyHieuBangVeModalToggle}
                        show={openSoKyHieuBangVeModal} />

                    <NhaORiengLeDonViTuVanModal
                        onUpdateData={handleUpdateData}
                        onModalToggle={handleDonViTuVanModalToggle}
                        show={openDonViTuVanModal} >
                    </NhaORiengLeDonViTuVanModal>
                    <Box padding={0}>
                        <Grid container>
                            {isNhapDauKy && (
                                <Grid container>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required>Số giấy phép</InputLabel>
                                        <InputBase fullWidth
                                            error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                            value={data.soGiayPhep} name="soGiayPhep" onChange={handleChange} />
                                        {
                                            submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập số giấy phép</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required>Ngày cấp</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                error={submit && !(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined)}
                                                value={(data.ngayCapPhepCu !== 0 && data.ngayCapPhepCu && data.ngayCapPhepCu !== null && data.ngayCapPhepCu !== undefined) ? new Date(data.ngayCapPhepCu) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhepCu')}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {
                                            submit && (data.ngayCapPhepCu === 0 || !data.ngayCapPhepCu || data.ngayCapPhepCu === null || data.ngayCapPhepCu === undefined) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập ngày cấp phép</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required>Ngày hết hạn</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                error={submit && !(data.ngayHetHan !== 0 && data.ngayHetHan && data.ngayHetHan !== null && data.ngayHetHan !== undefined)}
                                                value={(data.ngayHetHan !== 0 && data.ngayHetHan && data.ngayHetHan !== null && data.ngayHetHan !== undefined) ? new Date(data.ngayHetHan) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHan')}

                                            />
                                        </MuiPickersUtilsProvider>
                                        {
                                            submit && (data.ngayHetHan === 0 || !data.ngayHetHan || data.ngayHetHan === null || data.ngayHetHan === undefined) &&
                                            <FormHelperText className="-error"  >Vui lòng nhập ngày hết hạn</FormHelperText>
                                        }
                                    </Grid>
                                </Grid>
                            )
                            }
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Tên công trình </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data.tenCongTrinh)}
                                    value={data.tenCongTrinh} name="tenCongTrinh" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.tenCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên công trình</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Tỉnh/Thành phố </InputLabel>
                                <Autocomplete id="maTinh"
                                    options={TINH_TP}
                                    value={{ ma: data.maTinh ? data.maTinh : "", ten: data.tenTinh ? data.tenTinh : "" }}
                                    getOptionLabel={(option) => option.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maTinh', 'tenTinh')}
                                    disabled={true}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && (StringUtil.isNullOrEmty(data.maTinh) || StringUtil.isNullOrEmty(data.tenTinh))}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maTinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Tỉnh/Thành phố</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete
                                    id="maHuyen"
                                    options={quans}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: data.maHuyen, name: data.tenHuyen }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete
                                    id="maPhuong"
                                    options={phuongs}
                                    onFocus={loadPhuongs}
                                    getOptionLabel={(option) => option.name}
                                    value={{ code: data.maPhuong, name: data.tenPhuong }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maPhuong) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Cấp công trình </InputLabel>
                                <Autocomplete id="maCapCongTrinh"
                                    options={capCongTrinhs}
                                    value={{ ma: data.maCapCongTrinh ? data.maCapCongTrinh : "", ten: data.tenCapLoaiCongTrinh ? data.tenCapLoaiCongTrinh : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maCapCongTrinh', 'tenCapLoaiCongTrinh')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maCapCongTrinh)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maCapCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Cấp công trình </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Đường </InputLabel>
                                <InputBase fullWidth
                                    value={data.tenDuong} name="tenDuong" onChange={handleChange} />

                                {
                                    submit && StringUtil.isNullOrEmty(data.tenDuong) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập Đường </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Số nhà, đường </InputLabel>
                                <InputBase fullWidth
                                    value={data.soNha} name="soNha" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Loại công trình </InputLabel>
                                <Autocomplete id="maLoaiCongTrinh"
                                    options={loaiCongTrinhs}
                                    value={{ ma: data.maLoaiCongTrinh ? data.maLoaiCongTrinh : "", ten: data.tenLoaiCongTrinh ? data.tenLoaiCongTrinh : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maLoaiCongTrinh', 'tenLoaiCongTrinh')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maLoaiCongTrinh)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.maLoaiCongTrinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Loại công trình </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel >Địa chỉ cũ </InputLabel>
                                <InputBase fullWidth
                                    value={data.diaChiCu} name="diaChiCu" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} >
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel required>Phân loại xây dựng </InputLabel>
                                        <Autocomplete id="maPhanLoaiCongTrinh"
                                            options={phanLoaiCongTrinhs}
                                            value={{ ma: data.maPhanLoaiCongTrinh ? data.maPhanLoaiCongTrinh : "", ten: data.tenPhanLoaiCongTrinh ? data.tenPhanLoaiCongTrinh : "" }}
                                            getOptionLabel={(option) => option?.ten}
                                            onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maPhanLoaiCongTrinh', 'tenPhanLoaiCongTrinh')}
                                            renderInput={(params) => (
                                                <TextField  {...params} variant="outlined"
                                                    error={submit && StringUtil.isNullOrEmty(data.maPhanLoaiCongTrinh)}
                                                />
                                            )}
                                        />
                                        {
                                            submit && StringUtil.isNullOrEmty(data.maPhanLoaiCongTrinh) &&
                                            <FormHelperText className="-error"  >Vui lòng chọn Phân loại xây dựng </FormHelperText>
                                        }
                                    </Grid>
                                </Box>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Đơn vị tư vấn </InputLabel>
                                        <InputBase style={{ width: '90%' }}
                                            value={data.donViTuVan} name="donViTuVan" onChange={handleChange} />
                                        <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleDonViTuVanModalToggle} />
                                    </Grid>
                                </Box>
                                <Box padding={0}>
                                    <Grid item xs={12} className="grid-item" >
                                        <InputLabel >Số ký hiệu bản vẽ </InputLabel>
                                        <InputBase style={{ width: '90%' }}
                                            value={data.soKyHieuBangVe} name="soKyHieuBangVe" onChange={handleChange} />
                                        <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '3px' }} onClick={handleSoKyHieuBangVeModalToggle} />
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel >Lô đất xây dựng </InputLabel>
                                <NhaORiengLeLoDatXayDungComponent data={data.loDatXayDungDTOs} onUpdateData={handleUpdateData} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Chủ nhiệm, chủ trì thiết kế </InputLabel>
                                <InputBase fullWidth
                                    value={data.chuTriThietKe} name="chuTriThietKe" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Đơn vị thẩm định, thẩm tra </InputLabel>
                                <InputBase fullWidth
                                    value={data.donViThamDinh} name="donViThamDinh" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Chủ trì thẩm tra thiết kế </InputLabel>
                                <InputBase fullWidth
                                    value={data.chuTriThamTra} name="chuTriThamTra" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Thời hạn công trình </InputLabel>
                                <InputBase fullWidth
                                    value={data.thoiHanCongTrinh} name="thoiHanCongTrinh" onChange={handleChange} />
                            </Grid>
                            
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Chỉ giới đường đỏ </InputLabel>
                                <InputBase fullWidth
                                    multiline
                                    rows="4"
                                    rowsMax="4"
                                    value={chiGioiDuongDo} 
                                    name="chiGioiDuongDo" 
                                    onChange={handleChangeChiGioiDuongDo} />
                            </Grid>
                    
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Chỉ giới xây dựng </InputLabel>
                                <InputBase 
                                    fullWidth
                                    multiline
                                    rows="4"
                                    rowsMax="4"
                                    value={chiGioiXayDung} 
                                    name="chiGioiXayDung" 
                                    onChange={handleChangeChiGioiXayDung} />
                            </Grid>
                            
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Giấy tờ về QSD đất </InputLabel>
                                <Box style={{ borderBottom: " 1px solid #ced4da", marginBottom: "10px" }}></Box>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích khuôn viên đất </InputLabel>
                                <InputBase
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichKhuonVien ? data?.giayToQuyenSuDungDat?.dienTichKhuonVien.toString() : "")} name="dienTichKhuonVien" onChange={handleChangeNumberDienTich}
                                    fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích khuôn viên hiện hữu</InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu ? data?.giayToQuyenSuDungDat?.dienTichKhuonVienHienHuu.toString() : "")} name="dienTichKhuonVienHienHuu" onChange={handleChangeNumberDienTich} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích ban công</InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichBanCong ? data?.giayToQuyenSuDungDat?.dienTichBanCong.toString() : "")} name="dienTichBanCong" onChange={handleChangeNumberQuyenSuDungDat} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích chênh lệch</InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichChenhLech ? data?.giayToQuyenSuDungDat?.dienTichChenhLech.toString() : "")} name="dienTichChenhLech" onChange={handleChangeNumberQuyenSuDungDat} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Diện tích giữ lại </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.dienTichGiuLai ? data?.giayToQuyenSuDungDat?.dienTichGiuLai.toString() : "")} name="dienTichGiuLai" onChange={handleChangeNumberQuyenSuDungDat} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Mật độ cho phép xây dựng </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.matDoGiayPhep ? data?.giayToQuyenSuDungDat?.matDoGiayPhep.toString() : "")} name="matDoGiayPhep" onChange={handleChangeNumberQuyenSuDungDat} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Cốt nền</InputLabel>
                                <Tooltip title="Nền tầng trệt cao hơn nền vỉa hè 0,3m" placement="top-start">
                                    <InputBase fullWidth
                                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data?.giayToQuyenSuDungDat?.cotNen ? data?.giayToQuyenSuDungDat?.cotNen.toString() : "")} name="cotNen" onChange={handleChangeNumberQuyenSuDungDat} />
                                </Tooltip>

                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Lý do chênh lệch</InputLabel>
                                <InputBase fullWidth
                                    value={data?.giayToQuyenSuDungDat?.lyDo} name="lyDo" onChange={setQuyenSuDungDatParam} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Danh sách hạng mục </InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <HangMucTable
                                    data={data.hangMucXayDungDTOs}
                                    onUpdateData={handleUpdateData}
                                />
                            </Grid>
                            <DropZoneCustom 
                                acceptFiles={ACCEPT_FILES}
                                attachFileServer={data.files}
                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/cpxd"}
                                handleChangeStatus={handleChangeStatus}
                                handleDeleteFileServer={handleDeleteFileServer}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </Grid>
                     
                    </Box>

                </Box>
            }
        </>
    )
}
export default CongTrinhCoThoiHanNoiDungComponent;