import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Dispatch, useEffect, useState } from "react";
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  NativeSelect,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Pagination } from "@material-ui/lab";
import { CustomInput } from "../../../commons/components/input/customInput";
import { useTableFooter } from "../../../../hooks/useTableFooter";
import StringUtil from "../../../utils/stringUtils";
import moment from "moment";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RequestBaoCaoThongKeDTO } from "../BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import {
  hideLoading,
  LoadingAction,
  showLoading,
} from "../../../../redux/actions/applicationAction";
import { ThongKeService } from "../../../../api/VSATTP/Services/ThongKeService";
import { ROUTE_ATTP_CHITIETGIAYPHEP } from "../../../../routes/route-cn-attp";
import { getObject } from "../../../../helpers/localStorage";

interface IDataListProps {
  request: any;
  openDialog: boolean;
  onDialogClose: () => void;
}

export const ChiTietThongKeCapPhep = (props: IDataListProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [request, setRequest] = useState<any>({});
  const [openDialog, setOpenDialog] = useState(false);
  
  let C_USER_DATA = getObject("C_USER_DATA");
  const dispatch = useDispatch<Dispatch<LoadingAction>>();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSize(Number(event.target.value));
  };

  useEffect(() => {
    setRequest(props.request);
    setTotal(props.request.total);
    console.log(props.request);
  }, []);

  useEffect(() => {
    setOpenDialog(props.openDialog);
  }, [props.openDialog]);

  useEffect(() => {
    loadDataTable();
  }, [page, size]);

  const loadDataTable = async () => {
    dispatch(showLoading());
    await new ThongKeService()
      .chiTietThongKe({
        ...props.request,
        pageNumber: page,
        pageSize: size,
      })
      .then((p) => {
        setRows(p.rows);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const getTypeName = (type: string) => {
    switch (type) {
      case "CH":
        return "còn hạn";
      case "HH":
        return "hết hạn";
      default:
        return "";
    }
  };

  return (
    <Dialog
      onClose={props.onDialogClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <DialogTitle id="customized-dialog-title">
        <Box display="flex" alignItems="center" padding={0}>
          <Box flexGrow={1} style={{ fontSize: 15 }}>
            Thống kê tình hình giấy phép ATTP{" "}
            {getTypeName(props.request.tinhTrang)}{" "}
            {props.request.tenPhuongXa != null
              ? props.request.tenPhuongXa
              : C_USER_DATA.tenQuanHuyen}
          </Box>
          <Box>
            <IconButton onClick={props.onDialogClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <Cell component="th" scope="row" align="center" width="5%">
                  STT
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Giấy phép
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Ngày cấp
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Ngày hết hiệu lực
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Chủ cơ sở
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Địa chỉ
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Điện thoại
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Tình trạng
                </Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, i) => (
                  <Row key={row.id}>
                    <Cell scope="row" align="center">
                      {page * size + i + 1}
                    </Cell>
                    <Cell align="left">
                      <NavLink
                        style={{ textDecoration: "none" }}
                        exact
                        to={{
                          pathname: ROUTE_ATTP_CHITIETGIAYPHEP.duongDan,
                          state: { giayPhepATTPId: row.id },
                        }}
                      >
                        {row.soGiayPhep}
                      </NavLink>
                    </Cell>
                    <Cell align="center">
                      {row.ngayCapPhep &&
                      row.ngayCapPhep !== undefined &&
                      row.ngayCapPhep !== null
                        ? moment(new Date(row.ngayCapPhep)).format("DD/MM/YYYY")
                        : ""}
                    </Cell>
                    <Cell align="center">
                      {row.ngayHetHan &&
                      row.ngayHetHan !== undefined &&
                      row.ngayHetHan !== null
                        ? moment(new Date(row.ngayHetHan)).format("DD/MM/YYYY")
                        : ""}
                    </Cell>
                    <Cell align="center">{row.chuCoSo}</Cell>
                    <Cell align="left">{row.diaChiDayDu}</Cell>
                    <Cell align="center">{row.dienThoai}</Cell>
                    <Cell align="center">{row.tenTrangThai}</Cell>
                  </Row>
                ))
              ) : (
                <Row>
                  <Cell colSpan={10} scope="row" align="center">
                    {" "}
                    Không có dữ liệu
                  </Cell>
                </Row>
              )}
            </TableBody>
          </Table>
          <Box
            paddingTop="10px"
            display="flex"
            alignItems="center"
            fontSize="14px"
            color="#555555"
          >
            <Pagination
              count={Math.ceil(total / size)}
              defaultPage={1}
              siblingCount={1}
              variant="outlined"
              size="medium"
              color="primary"
              shape="rounded"
              showFirstButton
              showLastButton
              onChange={onChangePage}
            />
            <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
              <Box component="span" paddingX="5px">
                Số mẫu tin trên trang
              </Box>
              <NativeSelect
                input={<CustomInput />}
                onChange={onChangeRowsPerPage}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>20</option>
              </NativeSelect>
            </Box>
            <Box component="span" paddingX="5px">
              Mẫu tin {page * size + 1} -{" "}
              {size * (page + 1) > total ? total : size * (page + 1)} / {total}
            </Box>
          </Box>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};
