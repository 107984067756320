import MomentUtils from "@date-io/moment";
import {
    Box, Button,
    createStyles,
    FormHelperText,
    Grid, IconButton,
    InputAdornment,
    InputBase,
    InputLabel,
    makeStyles,
    NativeSelect,
    Snackbar,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Theme
} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from "@material-ui/lab";
import Pagination from '@material-ui/lab/Pagination';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { DanhMucService } from '../../../../api/danhMuc/danhMucService';
import { DanhMucDanTocDTO, DanhMucGioiTinhDTO, DanhMucQuocTichDTO } from '../../../../api/danhMuc/model/DanhMucDTO';
import { THANH_VIEN_GOP_VON_INIT } from "../../../../api/DKKD/init/ThanhVienGopVonInit";
import { ThanhVienGopVonDTO } from "../../../../api/DKKD/model/thanhVienGopVonDTO";
import { LOAI_GIAY_TO, LOAI_TAI_SAN } from "../../../../api/QuanTri/DanhMucFakeService";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { CustomInput } from "../../../commons/components/input/customInput";
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import DateUtil from '../../../utils/dateUtils';
import StringUtil from "../../../utils/stringUtils";
import ValidatorUtil from '../../../utils/validatorUtil';

interface IDataListProps {
    dataList: ThanhVienGopVonDTO[];
    onSetDataMems: (memData: ThanhVienGopVonDTO, isEdit: Boolean, index: number) => void;
    onDeleteMems: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
    }),
);

export const ThanhLapHKDThanhVienGopVon = (props: IDataListProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [submit, setSubmit] = React.useState(false);
    const [rows, setRows] = useState<ThanhVienGopVonDTO[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataTepms, setDataTepms] = useState<ThanhVienGopVonDTO[]>([]);

    const [data, setData] = React.useState({} as ThanhVienGopVonDTO);
    const [edit, setEdit] = React.useState(false);
    const [indexRow, setIndexRow] = useState(-1);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const { onSetDataMems } = props;
    const [dataDanToc, setDataDanToc] = useState<DanhMucDanTocDTO[]>([]);
    const [dataGioiTinh, setDataGioiTinh] = useState<DanhMucGioiTinhDTO[]>([]);
    const [dataQuocTich, setDataQuocTich] = useState<DanhMucQuocTichDTO[]>([]);

    useEffect(() => {
        setDataTepms(props.dataList);
        setTotal(props.dataList?.length);
        handleSearch({ page: 1, size: size });

        const dataNew = {
            ...THANH_VIEN_GOP_VON_INIT
        }
        setData(dataNew);
        async function getDmGioiTinh() {
            await new DanhMucService().getDanhMucGioiTinh('').then((resp) => {
                setDataGioiTinh(resp);
            });
        }
        getDmGioiTinh();
        async function getDmQuocTich() {
            await new DanhMucService().getDanhMucQuocTich('').then((resp) => {
                setDataQuocTich(resp);
            });
        }
        getDmQuocTich();
        async function getDmDanToc() {
            await new DanhMucService().getDanhMucDanToc('').then((resp) => {
                setDataDanToc(resp);
            });
        }
        getDmDanToc();
    }, [props.dataList, total, size]);

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        handleSearch({ page: value, size: size });
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1)
        setSize(Number(event.target.value));
        handleSearch({ page: 1, size: Number(event.target.value) });
    }

    const handleSearch = (search: any) => {
        setRows(dataTepms?.slice((search.page - 1) * search.size, (search.page * search.size)));
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    //handle event on form
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let name = e.target.name;
        let value = isCheckbox ? e.target.checked : e.target.value;
        let dataNew = {
            ...data,
            [name]: value
        };
        setData(dataNew);
    }

    const handleChangeNumber = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;

        let dataNew = {
            ...data,
            [name]: StringUtil.convertToNumber(value)
        };
        setData(dataNew);
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        setData(data => ({
            ...data,
            [filedName]: date?.toDate()?.getTime(),
        }));

    }

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = {
            ...data,
            [nameValue]: value?.ma,
            [nameText]: valueText
        };
        setData(dataNew);
    }

    const handleAddOrUpdate = () => {
        setSubmit(true);

        if (StringUtil.isNullOrEmty(data.hoTen)
            || StringUtil.isNullOrEmty(data.loaiGiayTo)
            || StringUtil.isNullOrEmty(data.soGiayToTuyThan)
            || StringUtil.isNullOrEmty(data.giaTriGopVon)
            || StringUtil.isNullOrEmty(data.tyLe)
            || StringUtil.isNullOrEmty(data.loaiTaiSan)) {
            return;
        }
        if (!ValidatorUtil.validatorSoGiayTo(data.loaiGiayTo, data.soGiayToTuyThan)) {
            return;
        }

        if (data.tyLe > 100) {
            showMessageAlert('Tỷ lệ không được lớn hơn 100%', false);
            return;
        }
        if(data?.ngaySinh && data?.ngaySinh > 0){
            if(!DateUtil.is18Years(data?.ngaySinh)){
                showMessageAlert('Ngày sinh phải lớn hơn hoặc bằng 18 tuổi', false);
                return;
            }

        }

        setSubmit(false);

        onSetDataMems(data, edit, indexRow);

        if (edit) {
            const idx = dataTepms.findIndex(e => e.ngayTao === data.ngayTao);
            dataTepms[idx] = data;
            setEdit(false);
        }
    }

    const clearInput = () => {
        setSubmit(false);

        const dataNew = {
            ...THANH_VIEN_GOP_VON_INIT
        }
        setData(dataNew);
    }

    const clickEdit = (item: ThanhVienGopVonDTO, index: number) => {
        setData(item);
        setIndexRow(index);
        setEdit(true);
    }

    const handleSearchForm = () => {
        setSubmit(false);

        // exclude column list from filter
        const excludeColumns = ["id"];

        Object.keys(data).forEach((k) => {
            if (StringUtil.isNullOrEmty(data[k])) excludeColumns.push(k);
        });

        const filteredData = dataTepms.filter(item => {
            return Object.keys(item).some(key =>
                excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(data[key].toString().toLowerCase())
            );
        });

        setRows(filteredData);
    }

    async function handleChangeGioiTinh(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
        setData(dataNew);
    }
    async function handleChangeQuocTich(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
        setData(dataNew);
    }
    async function handleChangeDanToc(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
        setData(dataNew);
    }

    const onSearchCMND = (cmnd: string) => {
        if (StringUtil.isNullOrEmty(cmnd)) {
            showMessageAlert("Số giấy tờ tùy thân không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoCMND({soCMND: cmnd}).then(res => {
            let dataNew = res?.data.data;
            if (dataNew !== null && dataNew !== undefined) {
                if(dataNew.error_code === 'SUCCESSFUL'  && dataNew.data && dataNew.data != null) {
                setData({
                    ...data,
                    ngayCap: dataNew.data.cmt_NgayCap,
                    hoTen: dataNew.data.tenCongDan,
                    ngaySinh: dataNew.data.ngayThangNamSinh,
                    noiCap: dataNew.data.cmt_NoiCap,
                    hoKhau: dataNew.data.diaChi,
                    
                });
                } else {
                    setAlertMessage({ open: true, content: "Số giấy phép không tồn tại trong dữ liệu", severity: 'warning' });
                }
            } 
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    };

    return (
        <>
            {
                <Box padding={0}>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Loại giấy tờ tùy thân </InputLabel>
                                <Autocomplete id="loaiGiayTo"
                                    options={LOAI_GIAY_TO}
                                    value={{ ma: data?.loaiGiayTo ? data?.loaiGiayTo : 0, ten: data?.tenGiayTo ? data?.tenGiayTo : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'loaiGiayTo', 'tenGiayTo')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && (StringUtil.isNullOrEmty(data?.loaiGiayTo) || data?.loaiGiayTo < 0)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && (StringUtil.isNullOrEmty(data?.loaiGiayTo) || data?.loaiGiayTo < 0) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn loại giấy tờ </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Số giấy tờ tùy thân </InputLabel>
                                <InputBase
                                    endAdornment={<InputAdornment position="end"> <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '3px' }} onClick={(e) => onSearchCMND(data?.soGiayToTuyThan)} /></InputAdornment>}
                                    fullWidth
                                    error={submit &&
                                        (
                                            StringUtil.isNullOrEmty(data?.soGiayToTuyThan)
                                            || !ValidatorUtil.validatorSoGiayTo(data.loaiGiayTo, data.soGiayToTuyThan)
                                        )
                                    }
                                    value={data?.soGiayToTuyThan}
                                    name="soGiayToTuyThan"
                                    onChange={handleChange}
                                />
                                {submit && StringUtil.isNullOrEmty(data?.soGiayToTuyThan) && (
                                    <FormHelperText className="-error">Vui lòng nhập số giấy tờ tùy thân</FormHelperText>
                                )}
                                {submit && !StringUtil.isNullOrEmty(data?.soGiayToTuyThan) && !ValidatorUtil.validatorSoGiayTo(data.loaiGiayTo, data.soGiayToTuyThan) && (
                                    <FormHelperText className="-error">
                                        Vui lòng nhập số giấy tờ hợp lệ
                                        <br />
                                        Chứng minh nhân dân : 9 hoặc 12 chữ số
                                        <br />
                                        Căn cước công dân : 12 chữ số
                                        <br />
                                        Hộ chiếu : Bắt đầu bằng ký tự chữ viết hoa và 8 chữ số kế tiếp
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Họ tên </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data.hoTen)}
                                    value={data?.hoTen} name="hoTen" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.hoTen) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên đại diện hộ kinh doanh</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Giới tính </InputLabel>
                                    <Autocomplete
                                        id="maGioiTinh"
                                        options={dataGioiTinh}
                                        value={{ code: data?.maGioiTinh ? data?.maGioiTinh : "", name: data?.tenGioiTinh ? data?.tenGioiTinh : "" }}
                                        getOptionLabel={(option) => option?.name}
                                        onChange={(event: any, newValue: any) => handleChangeGioiTinh(newValue, "maGioiTinh", "tenGioiTinh")}
                                        renderInput={(params) => {
                                            return (
                                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                                    <InputBase fullWidth {...params.inputProps} />
                                                </div>
                                            );
                                        }}
                                    />
                                {/* <NativeSelect fullWidth name="gioiTinh" value={data?.gioiTinh} input={<CustomNativeSelect />} onChange={handleChange} disableUnderline>
                                    <option value="">Chọn giới tính</option>
                                    <option value="1">Nam</option>
                                    <option value="2">Nữ</option>
                                    <option value="0">Khác</option>
                                </NativeSelect> */}
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày sinh</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngaySinh !== undefined && data?.ngaySinh != 0) ? new Date(data?.ngaySinh) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngaySinh')}
                                        disableFuture = {true}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Dân tộc </InputLabel>
                                <Autocomplete id="danToc"
                                    options={dataDanToc}
                                    value={{ code: data?.maDanToc ? data?.maDanToc : "", name: data?.tenDanToc ? data?.tenDanToc : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeDanToc(newValue, 'maDanToc', 'tenDanToc')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Quốc tịch </InputLabel>
                                <Autocomplete id="quocTich"
                                    options={dataQuocTich}
                                    value={{ code: data?.maQuocTich ? data?.maQuocTich : "", name: data?.tenQuocTich ? data?.tenQuocTich : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeQuocTich(newValue, 'maQuocTich', 'tenQuocTich')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayCap !== undefined && data?.ngayCap != 0) ? new Date(data.ngayCap) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCap')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Nơi cấp </InputLabel>
                                <InputBase fullWidth
                                    value={data?.noiCap} name="noiCap" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel >Ngày hết hạn</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayHetHan !== undefined && data?.ngayHetHan != 0) ? new Date(data.ngayHetHan) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHan')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Nơi đăng ký hộ khẩu thường trú </InputLabel>
                                <InputBase fullWidth
                                    value={data?.hoKhau} name="hoKhau" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={6} className="grid-item" >
                                <InputLabel >Chỗ ở hiện tại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.choO} name="choO" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Giá trị phần vốn góp (VNĐ) </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.giaTriGopVon)}
                                    value={StringUtil.formatMoney(data?.giaTriGopVon)} name="giaTriGopVon" onChange={handleChangeNumber} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.giaTriGopVon) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập giá trị phần vốn góp</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Tỷ lệ </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.tyLe)}
                                    value={StringUtil.formatMoney(data?.tyLe)} name="tyLe" onChange={handleChangeNumber} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.tyLe) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tỷ lệ vốn góp</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={3} className="grid-item">
                                <InputLabel required>Loại tài sản </InputLabel>
                                <Autocomplete id="loaiTaiSan"
                                    options={LOAI_TAI_SAN}
                                    value={{ ma: data?.loaiTaiSan ? data?.loaiTaiSan : 0, ten: data?.tenTaiSan ? data?.tenTaiSan : "" }}
                                    getOptionLabel={(option) => option?.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'loaiTaiSan', 'tenTaiSan')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.loaiTaiSan)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.loaiTaiSan) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn loại tài sản </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Số lượng </InputLabel>
                                <InputBase fullWidth
                                    value={StringUtil.formatMoney(data?.soLuong)} name="soLuong" onChange={handleChangeNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Giá trị tài sản góp vốn </InputLabel>
                                <InputBase fullWidth
                                    value={StringUtil.formatMoney(data?.giaTriTaiSanGopVon)} name="giaTriTaiSanGopVon" onChange={handleChangeNumber} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Thời điểm góp vốn </InputLabel>
                                <InputBase fullWidth
                                    value={data?.thoiDiemGopVon} name="thoiDiemGopVon" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <div>
                                    {/*<Button type="button" variant="contained" color="primary"
                                            onClick={handleSearchForm}
                                            style={{ fontSize: '14px', width: '100px' }}>Tìm kiếm
                                    </Button>&nbsp;&nbsp;*/}

                                    <Button type="button" variant="contained" color="primary"
                                        onClick={handleAddOrUpdate}
                                        style={{ fontSize: '14px', width: '100px' }}>Thêm
                                    </Button>&nbsp;&nbsp;

                                    <Button type="button" variant="contained" color="secondary"
                                        onClick={clearInput}
                                        style={{ fontSize: '14px', width: '100px' }}>Làm mới
                                    </Button>&nbsp;&nbsp;
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
            {/*table view*/}
            {
                <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                                <Cell component="th" scope="row" align="center" width="10%">Họ và tên</Cell>
                                <Cell component="th" scope="row" align="center" width="5%">Giới tính</Cell>
                                <Cell component="th" scope="row" align="center" width="10%">Ngày sinh</Cell>
                                <Cell component="th" scope="row" align="center" width="5%">Dân tộc</Cell>
                                <Cell component="th" scope="row" align="center" width="5%">Quốc tịch</Cell>
                                <Cell component="th" scope="row" align="center" width="14%">Số, ngày, cơ quan cấp CMND/CCCD/Hộ chiếu</Cell>
                                <Cell component="th" scope="row" align="center" width="8%">Nơi đăng ký hộ khẩu thường trú</Cell>
                                <Cell component="th" scope="row" align="center" width="8%">Chỗ ở hiện tại</Cell>
                                <Cell component="th" scope="row" align="center" width="10%">Giá trị phần vốn góp (VNĐ)</Cell>
                                <Cell component="th" scope="row" align="center" width="5%">Tỷ lệ (%)</Cell>
                                <Cell component="th" scope="row" align="center" width="10%">Loại tài sản, số lượng, giá trị tài sản góp vốn</Cell>
                                <Cell component="th" scope="row" align="center" width="5%">Thời điểm góp vốn</Cell>
                                <Cell component="th" scope="row" align="center" width="10%">Xử lý</Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows?.length > 0 ?
                                    rows.map((row, i) => (
                                        <Row key={row.id}>
                                            <Cell scope="row" align="center">
                                                {(page - 1) * size + i + 1}
                                            </Cell>
                                            <Cell align="left">{row.hoTen}</Cell>
                                            <Cell align="center">{row.maGioiTinh === '1' ? 'Chưa có thông tin' : (row.maGioiTinh === '2' ? 'Giới tính nam' : 'Giới tính nữ')}</Cell>
                                            <Cell align="center">{(row.ngaySinh !== 0 && row.ngaySinh !== null) ? moment(row.ngaySinh).format("DD/MM/YYYY") : ''}</Cell>
                                            <Cell align="center">{row.tenDanToc}</Cell>
                                            <Cell align="left">{row.tenQuocTich}</Cell>
                                            <Cell align="left">
                                                {row.soGiayToTuyThan ? <InputLabel >Số: </InputLabel> : ''}{row.soGiayToTuyThan}
                                                {row.ngayCap !== 0 && row.ngayCap !== null ? <InputLabel >Ngày cấp: </InputLabel> : ''}{(row.ngayCap !== 0 && row.ngayCap !== null) ? moment(row.ngayCap).format("DD/MM/YYYY") : ''}
                                                {row.noiCap ? <InputLabel >Nơi cấp: </InputLabel> : ''}{row.noiCap}
                                            </Cell>
                                            <Cell align="left">{row.hoKhau}</Cell>
                                            <Cell align="left">{row.choO}</Cell>
                                            <Cell align="left">{StringUtil.formatMoney(row.giaTriGopVon)}</Cell>
                                            <Cell align="center">{row.tyLe}</Cell>
                                            <Cell align="left">{row.tenTaiSan}</Cell>
                                            <Cell align="center">{row.thoiDiemGopVon}</Cell>
                                            <Cell align="center">
                                                <Box display="flex" justifyContent="center">
                                                    <IconButton onClick={(event) => clickEdit(row, i)} color="primary" aria-label="edit" size="small">
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton color="secondary" aria-label="delete" size="small"
                                                        onClick={() => { props.onDeleteMems(i) }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </Cell>
                                        </Row>
                                    )) :
                                    <Row>
                                        <Cell colSpan={14} scope="row" align="center"> Không có dữ liệu</Cell>
                                    </Row>
                            }
                        </TableBody>
                    </Table>
                    {rows?.length > 0 ?
                        <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                            <Pagination count={Math.ceil(total / size)} defaultPage={1} siblingCount={1} variant="outlined"
                                size="medium"
                                color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                            <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                                <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                                <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>20</option>
                                </NativeSelect>
                            </Box>
                            <Box component="span" paddingX="5px">Mẫu tin {(page * size) + 1} - {(page * size) + size} / {total}</Box>
                        </Box>
                        : ''
                    }
                </TableContainer>
            }
        </>
    )
}
