import StringUtil from '../../App/utils/stringUtils';
import * as Types from '../actions/actionTypes';
const initialState = {listAppraisal:Array<any>(), total : 0, delete:Array<any>()};
export const appraisalsReducer = (state = initialState, action: any) => {
    const { index ,appraisal} = action;
    switch (action.type) {
        case Types.ADD_APPRAISAL:
            state.listAppraisal.push(appraisal); 
            state.total = state.listAppraisal ? state.listAppraisal.length : 0 ; 
            return {
                ...state,
             };
        case Types.UPDATE_APPRAISAL:
            state.listAppraisal[index] = appraisal; 
            state.total = state.listAppraisal ? state.listAppraisal.length : 0 ; 
            return {
                ...state,
             };
        case Types.DELETE_APPRAISAL:
            state.listAppraisal.splice(index, 1);
            state.total = state.listAppraisal ? state.listAppraisal.length : 0 ; 
            return {
                ...state,     
            };
        case Types.ADD_ALL_APPRAISAL :
            const {appraisals} = action;
            const newList = [...appraisals];
            state.total  = StringUtil.isEmty(newList) ?  0 : newList.length   ; 
            return {
                ...state, 
                listAppraisal : newList
            };
        case Types.DELETE_APPRAISAL_BY_ID:
            state.delete.push(appraisal);   
            return {
                ...state,
        };          
        default:
        return {
            ...state,     
        };;
    }
};