import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Button, NativeSelect } from '@material-ui/core';
import NhaORiengLeLoDatXayDungModal from '../Modal/NhaORiengLeLoDatXayDungModal';
import { ICONS } from '../../../../Constants/constants';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { LoDatXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
interface NhaORiengLeLoDatXayDungProps {
    data: LoDatXayDungDTO[];
    onUpdateData: (name: string, value: any) => void;
}
const NhaORiengLeLoDatXayDungComponent = (props: NhaORiengLeLoDatXayDungProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<LoDatXayDungDTO[]>(new Array<LoDatXayDungDTO>());
    const [openModel, setOpenModel] = useState(false);
    const { onUpdateData } = props;
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = ((pageNumberNew - 1) * pageSizeNew);
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    }
    const handleAdd = (loDat: any) => {
        let dataNew =[...props.data]  ;
        dataNew.unshift(loDat);
        onUpdateData('loDatXayDungDTOs', dataNew);
        //handleSearch(pageNumber, pageSize);
    }
    const handleDrop = (index: number) => {
        let dataNew = [...props.data]  ;
        dataNew.splice(index, 1);
        onUpdateData('loDatXayDungDTOs', dataNew);
       // handleSearch(pageNumber, pageSize);
    }
    const handleToggle = () => {
        setOpenModel(!openModel);
    }
    return (
        <div>
            <NhaORiengLeLoDatXayDungModal onUpdateData={handleAdd} onToggle={handleToggle} show={openModel} ></NhaORiengLeLoDatXayDungModal>
            <table className="table table-bordered" style={{ fontSize: '14px' }}>
                <thead>
                    <tr className="table-secondary">
                        <th>STT</th>
                        <th>Số Tờ</th>
                        <th>Số thửa</th>
                        <th>Một phần thửa</th>
                        <th style={{ width: "5%" }} className="text-center">Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {dataPagi?.length > 0 ? dataPagi?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="cell-number">{((pageNumber - 1) * pageSize) + index + 1}</td>
                                <td>{item.soTo}</td>
                                <td>{item.soThua}</td>
                                <td className="text-center">
                                    <input type="checkbox" checked={item.motNua} readOnly={true} />
                                </td>
                                <td align="center"><a onClick={(e) => handleDrop(((pageNumber - 1) * pageSize) + index)}><img src={ICONS.delete}></img></a></td>
                            </tr>
                        )
                    }) :
                        <tr>
                            <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                        </tr>
                    }
                </tbody>
            </table>

            <div className="row">
                {/* pagination*/}
                <div className="col-md-9" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                    <Pagination count={Math.ceil(props.data?.length / pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleChangePage} />
                    <div>
                        <span> Số mẫu tin trên trang </span>
                        <NativeSelect value={pageSize} input={<CustomInput />} onChange={handleChangePageSize} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                        <span>Trang {pageNumber}  / {props.data?.length === 0 ? 1 : Math.ceil(props.data?.length / pageSize)}</span>
                    </div>
                </div>
                <div className="col-md-3 text-right" style={{ fontSize: '14px' }}>
                    <Button onClick={handleToggle} variant="contained" color="primary" disableElevation>Thêm mới</Button>
                </div>
            </div>
        </div>
    )
}
export default NhaORiengLeLoDatXayDungComponent;