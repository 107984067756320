import api from "../../index";
import { BaseResponse, BaseService } from "../../baseService";

export default class SubKhenThuongService extends BaseService {
    getSubDSKhenThuong = async (idSubKhenThuong: Number | null): Promise<BaseResponse> => {
        let result = { isSuccess: false } as BaseResponse;
        await api
            .get(`danh-sach-khen-thuong/get-sub-danh-sach-khen-thuong/${idSubKhenThuong}`, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status !== 200) {
                    result.message = "Có lỗi xảy ra, thực hiện lại!";
                } else {
                    result.isSuccess = response.data.error_code === "SUCCESSFUL";
                    if (result.isSuccess) {
                        result.data = response.data.data;
                    } else {
                    }
                }
            })
            .catch((error) => {
                result = { isSuccess: false, message: "Có lỗi khi thực hiện kết nối tới server" };
            });
        return result;
    };
}
