import {
    Box,
    Button,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    NativeSelect,
    Snackbar,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import EditIcon from '@material-ui/icons/Edit';
import { Alert, Autocomplete, Pagination } from "@material-ui/lab";
import { CustomInput } from "../../../commons/components/input/customInput";
import  { CapSoGiayPhepRuouModal } from "./CapSoGiayPhepRuouModal";
import { HoSoThuLyBLR, HoSoThuLySearch } from "../../../../api/CPBLR/model/HoSoThuLyBLR";
import { CapSoDKRuouService } from "../../../../api/CPBLR/CapSoDKRuouService";
import { LoadingAction } from "../../../../redux/actions/applicationAction";
import { CAP_MOI_SX_RUOU_INIT } from "../../../../api/CPBLR/Init/CapMoiSXRuouInit";
import { PHUONG } from "../../../../api/QuanTri/DanhMucFakeService";
import StringUtil from "../../../utils/stringUtils";
import { getObject } from "../../../../helpers/localStorage";
import { DanhMucDiaChiDTO } from "../../../../api/danhMuc/model/DanhMucDTO";
import { DanhMucService } from "../../../../api/danhMuc/danhMucService";


const CapSoGiayPhepRuouComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false);

    let C_USER_DATA: any = getObject("C_USER_DATA");
    const [total, setTotal] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState<HoSoThuLyBLR[]>([]);
    const [search, setSearch] = useState<HoSoThuLySearch>({
        maHoSo: '',
        tenThuongNhan: '',
        maQuanTruSo: C_USER_DATA.maQuanHuyen,
        tenQuanTruSo: C_USER_DATA.tenQuanHuyen,
        maPhuongTruSo: '',
        tenPhuongTruSo: '',
        keywords: '',
        hoatDong: 1,
        pageSize: 5,
        pageNumber: 1
    });
    const [indexRow, setIndexRow] = useState(-1);
    const [dataRow, setDataRow] = useState({ ...CAP_MOI_SX_RUOU_INIT } as HoSoThuLyBLR);
    const capSoDKRuouService = new CapSoDKRuouService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);

    useEffect(() => {
        getQuan('92');
        doSearch(search);
    }, []);

    const getQuan = async (maQuan : string) => {
        await new DanhMucService().getDanhMucDiaChi(String(maQuan)).then((resp) => {
            setQuans(resp);
        });
        
    }

       const doSearch = (searchRequest: HoSoThuLySearch) => {
        capSoDKRuouService.danhSachCapSo(searchRequest).then(response => {
            let result = response.data;
            if (result && result.data) {
                setData(result.data.data !== undefined ? result.data.data : []);
                setTotal(result.data.total !== undefined ? result.data.total : 0);
            } else {
                setData([]);
                setTotal(0);
            }
        }).catch(error => {
            setData([]);
            setTotal(0);
            console.log(error);
        });
    }

    

    const setParamSearch = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearch(search => ({
            ...search,
            [name]: value
        }));
    }
    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSearch(searchNew);
        doSearch(searchNew);
    };


    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageNumber: 1, pageSize: Number(event.target.value) };
        setSearch(searchNew);
        doSearch(searchNew);
    }

    const handSearch = () => {
        let searchNew = { ...search, pageNumber: 1, pageSize: 5 };
        setSearch(searchNew);
        doSearch(searchNew);
    }
    const handleToggle = () => {
        setShowModal(!showModal);
    }
 
    async function load (searchRequest: HoSoThuLySearch, index: number) {
        const data = await new CapSoDKRuouService().danhSachCapSo2(searchRequest);
        setData(data.rows);
        setTotal(data.total);
        setDataRow(data.rows[index]);
    }
 
    const handleSubmit = (success: boolean, mess: string, index?: number , isWarning?:boolean) => {
        if (index!==undefined && index >= 0 && success){
            setMessage(mess);
            setSuccess(true);
            load(search, index);
        }
        else if (success && !index){
            setMessage(mess);
            setSuccess(true);
            doSearch(search);
            // handleToggle();
        }else{
            if (isWarning){
                setWarning(true);
            }
            setMessage(mess);
            setSuccess(false);
        }
        setShowAlert(true);      
    } 

    const clickCapSo = (item: HoSoThuLyBLR, index: number) => {
        setDataRow(item);
        setIndexRow(index);
        setShowModal(true);
    }

    const loadPhuongs = async (maQuan : string) => { 
        if(StringUtil.isNotEmpty(maQuan)){
            await new DanhMucService().getDanhMucDiaChi(String(maQuan)).then((resp) => {
                setPhuongs(resp);
            });
           
        }  

    };

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...search, [nameValue]: value?.code, [nameText]: valueText};
        setSearch(dataNew);
    };

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>CẬP NHẬT SỐ GIẤY PHÉP RƯỢU</span>
            </div>
            <CapSoGiayPhepRuouModal
                indexEdit={indexRow}
                data={dataRow}
                onToggle={handleToggle}
                show={showModal}
                onSubmit={handleSubmit} />
            <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <Alert onClose={() => {
                    setShowAlert(false);
                    setWarning(false);
                }}
                severity={success ? 'success' : warning? 'warning':'error'}>{message}</Alert>
            </Snackbar>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={3} className="gridItem">
                    <InputLabel >Mã hồ sơ</InputLabel>
                    <InputBase fullWidth
                        name="maHoSo"
                        value={search.maHoSo} onChange={setParamSearch}
                        inputProps={{ 'aria-label': 'description' }} />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel >Huyện/Thị/TP</InputLabel>
                    {
                            StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                            <Autocomplete id="maQuan" options={quans}
                                value={{ code: search?.maQuanTruSo ? search?.maQuanTruSo : "", name: search?.tenQuanTruSo ? search?.tenQuanTruSo : "" }}
                                getOptionLabel={(option) => option?.name}
                                onChange={(event: any, newValue: any) => {
                                    setSearch({
                                        ...search,
                                        maQuanTruSo: newValue?.code,
                                        tenQuanTruSo: newValue?.name,
                                        maPhuongTruSo: "",
                                        tenPhuongTruSo: ""
                                    });
                                }}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth {...params.inputProps} />
                                    </div>
                                }}
                            />
                    }
                    {
                        !StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                        <InputBase disabled fullWidth value={C_USER_DATA.tenQuanHuyen} />
                    }
                </Grid>
                <Grid item xs={3} className="gridItem">
                    <InputLabel >Phường/Xã/TT</InputLabel>
                    <Autocomplete options={phuongs}
                        getOptionLabel={(option) => option?.name}
                        value={{ code: search.maPhuongTruSo, name: search.tenPhuongTruSo }}
                        onFocus={(e) => loadPhuongs(search?.maQuanTruSo)}
                        onChange = {(event: any, newValue: any) => handleChangeSelectPhuong(newValue, 'maPhuongTruSo', 'tenPhuongTruSo')}
                        renderInput={(params) => {
                            return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                <TextField  {...params} variant="outlined" />
                            </div>
                        }}
                    />
                </Grid>
                <Grid item xs={3} className="gridItem">
                    <InputLabel >Tên thương nhân</InputLabel>
                    <InputBase fullWidth
                        name="tenThuongNhan"
                        value={search.tenThuongNhan} onChange={setParamSearch}
                        inputProps={{ 'aria-label': 'description' }} />
                </Grid>

                <Grid item xs={12} className="button-group-center">
                    <Button variant="contained" color="primary" disableElevation
                        onClick={handSearch}>
                        Tìm kiếm
                    </Button>
                </Grid>

                <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                                <Cell component="th" scope="row" align="center" width="20%">MÃ HỒ SƠ</Cell>
                                <Cell component="th" scope="row" align="center" width="20%">TÊN THƯƠNG NHÂN</Cell>
                                <Cell component="th" scope="row" align="center" width="12%">SỐ GIẤY PHÉP</Cell>
                                <Cell component="th" scope="row" align="center" >ĐỊA CHỈ</Cell>
                                <Cell component="th" scope="row" align="center" width="8%">THAO TÁC</Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {total > 0 ?
                                data.map((row, i) => (
                                    <Row key={i}>
                                        <Cell scope="row" align="center">
                                            {((search.pageNumber - 1) * search.pageSize) + i + 1}
                                        </Cell>
                                        <Cell align="left">{row.maHoSo}</Cell>
                                        <Cell align="left">{row.tenThuongNhan}</Cell>
                                        <Cell align="left">{row.soGiayPhep}</Cell>
                                        <Cell align="left">{row.diaChiDayDu}</Cell>
                                        <Cell align="center">
                                            <Box display="flex" justifyContent="center">
                                                <IconButton onClick={(event) => clickCapSo(row, i)} color="primary" aria-label="edit" size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            </Box>
                                        </Cell>
                                    </Row>
                                ))
                                :
                                <Row key={'empty'}>
                                    <Cell colSpan={6} scope="row" align="center">
                                        Không có dữ liệu
                                        </Cell>
                                </Row>
                            }
                        </TableBody>
                    </Table>
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination count={Math.ceil(total / search.pageSize)} page={search.pageNumber} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium"
                            color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                            <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">Mẫu tin {((search.pageNumber - 1) * search.pageSize) + 1} -  {(search.pageNumber * search.pageSize)} / {total}</Box>
                    </Box>
                </TableContainer>
            </Grid>
        </div>
    )
}
export default CapSoGiayPhepRuouComponent;

