import { Snackbar } from "@material-ui/core";
import React, { useState } from "react";
import { DanhSachKhenThuongService } from "../../../../../../api/KhenThuong/chuyenNganh/danhSachKhenThuongService";
import { DoiTuongKhenThuong, KhenThuongCaNhanDTO, LoaiKhenThuong } from "../../../../../../api/KhenThuong/models/khenThuongDTO";
import { useQuerySoBienNhan } from "../../../../../../hooks/useQuery";
import { AlertMessage, CustomAlert } from "../../../../../commons/components/alert";
import { DANH_SACH_TEN_NGHIEP_VU } from "../../../../../Constants/constants";
import { EditFormKhenThuongCaNhan } from "./editFormKhenThuongCaNhan";
import { FormMain } from "./formMain";

export const initDefaultValue = {
    thuTucId: DANH_SACH_TEN_NGHIEP_VU.NGHIEPVU_SO_3.ID,
    id: null,
    loaiHinhKhenThuongId: 0,
    hoTen: "",
    doiTuong: DoiTuongKhenThuong.LANH_DAO,
    ngaySinh: null,
} as KhenThuongCaNhanDTO;

export interface KhenThuongCaNhanWrapperPropsI { }

export const KhenThuongCaNhanWrapper = () => {
    const soBienNhan = useQuerySoBienNhan();
    const [hideEditForm, setHideEditForm] = useState(true);
    const [dataKhenThuong, setDataKhenThuong] = useState<KhenThuongCaNhanDTO>(() => ({
        ...initDefaultValue,
        soBienNhan: soBienNhan,
    }));
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);

    function showMessageAlert(content: string, isSuccess: boolean = true) {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    }
    function handleCloseAlertMessage() {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    }

    async function handleSave(data: KhenThuongCaNhanDTO) {
        await new DanhSachKhenThuongService().insertOrUpdateKhenThuongCaNhan(data).then((resp) => {
            if (resp.isSuccess) {
                setHideEditForm(true);
                showMessageAlert(resp.message);
            } else {
                showMessageAlert(resp.message, false);
            }
        });
    }

    function handleEditData(data: KhenThuongCaNhanDTO) {
        setDataKhenThuong({
            ...data,
            ngaySinh: data.ngaySinh ? new Date(data.ngaySinh) : null,
        });
        setHideEditForm(false);
    }

    return (
        <>
            {hideEditForm ? (
                <FormMain showMessageAfterDelete={showMessageAlert} onEditData={handleEditData} />
            ) : (
                <EditFormKhenThuongCaNhan dataKhenThuong={dataKhenThuong} onSave={handleSave} onDoBack={() => setHideEditForm(true)} />
            )}
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                    {alertMessage.content}
                </CustomAlert>
            </Snackbar>
        </>
    );
};
