import { Box, Button, Grid, InputBase, InputLabel, NativeSelect, Paper,TextField,Checkbox,FormControlLabel, Snackbar,RadioGroup,Radio } from "@material-ui/core";
import React, { useEffect,Dispatch } from "react";
import { useHistory } from "react-router-dom";
import { bootstrapTextFieldHook } from "../../commons/components/input/bootstrap";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TRA_CUU_THONG_TIN_CONG_DAN_INIT ,TraCuuThongTinCDRequest, ChuHo, ThanhVien} from "../../../api/CongDan/model/ThongTinCongDan";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import GridView from "../../commons/components/gridView";
import { DanhSachThanhVienCongDan } from "./DanhSachThanhVienCongDan";
import { useDispatch } from "react-redux";
import {  hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { TraCuuThongTinCongDanService } from "../../../api/CongDan/TraCuuThongTinCongDanService";
import { UserData } from "../../../api/userMgt/userManagementService";
import { OverlayLoading } from "../../commons/components/overlayLoading/overlayLoading";
import { AlertMessage, CustomAlert } from "../../commons/components/alert";


export const TraCuuCaNhan = () => {
    // hook
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const [data, setData] = React.useState({} as ChuHo);
    const [total, setTotal] = React.useState(0);
    const labelLeftStyle = bootstrapTextFieldHook.labelLeft();
    const [dataListPage, setDataListPage] = React.useState<ThanhVien[]>([]);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    let C_USER_DATA_DE = {} as UserData;
    const [alertMessage, setAlertMessage] = React.useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const dispatchNotifiAction = useDispatch<Dispatch<NotificationAction>>();
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }

    
    const validationSchema = Yup.object().shape(
        {
            CMND: Yup.string().nullable()
                  .required("Căn cước công dân bắt buộc nhập")
                  .matches(/^\d+$/, "Không đúng định dạng "),  
            hoVaTen: Yup.string().nullable().required("Họ và tên bắt bắt buộc nhập"),
            ngaySinh: Yup.number().nullable().required("Ngày sinh bắt buộc nhập")
        }
    );

     const formik = useFormik({
        initialValues : {
            ...TRA_CUU_THONG_TIN_CONG_DAN_INIT,
            isCaNhan : true,
            maCanBo : C_USER_DATA_DE.userName,
        },
        onSubmit: (values) => handleSubmit(values),
        validationSchema,
        validateOnChange: false,
    });

    const handleSubmit = async(data: TraCuuThongTinCDRequest) => {
        dispatchLoading(showLoading());
        let soCMND = formik.values?.CMND;
        await new TraCuuThongTinCongDanService().traCuuThongTinCaNhan(data).then((res) => {
            if (res != null && res?.data?.error_code == 'SUCCESSFUL') {
                if(res?.data?.data?.maTrangThai && res?.data?.data?.maTrangThai == 1){
                    showMessageAlert(`Tra cứu căn cước công dân số ${soCMND} thành công`, 'success');
                }else{
                    showMessageAlert(`Tra cứu căn cước công dân không tìm thấy số ${soCMND} `, 'warning');
                }
                setData(res?.data?.data);
            }

        }).finally(() => {
            dispatchLoading(hideLoading());
        });

    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        formik.setFieldValue(filedName, date?.toDate()?.getTime())
    }
    
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }  

    const showMessageAlert = (content: string, status : any) => {
        setAlertMessage({ open: true, content: content, severity: status });
    };
    return (
        <div className="dash-content">
            <Grid container>
                <OverlayLoading />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                </Snackbar>
                <Grid item xs={12}>
                    <RadioGroup row style={{ flexWrap: "inherit" }} name="isCCCD" value ={Number(formik?.values?.isCCCD)} onChange={(e) => {
                            formik.setFieldValue("isCCCD", Number(e.target.value));
                        }} >
                        <FormControlLabel
                            value ={Number(0)}
                            control={<Radio size="small" color="default" />}
                            label="CCCD"
                        />
                        <FormControlLabel
                           value ={Number(1)}
                            control={<Radio size="small" color="default" />}
                            label="CMND"
                        />
                    </RadioGroup>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <TextField
                        fullWidth
                        required
                        onChange={formik.handleChange}
                        label={formik?.values.isCCCD === 0 ? "Căn cước công dân (CCCD)" : "CMND"}
                        name="CMND"
                        InputLabelProps={{ classes: labelStyles }}
                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                        error={Boolean(getIn(formik.touched, "CMND") && getIn(formik.errors, "CMND"))}
                        helperText={Boolean(getIn(formik.touched, "CMND") && getIn(formik.errors, "CMND")) && formik.errors?.CMND}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <TextField
                        fullWidth
                        required
                        label={"Họ và tên"}
                        name="hoVaTen"
                        value={formik.values.hoVaTen}
                        onChange={formik.handleChange}
                        InputLabelProps={{ classes: labelStyles }}
                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                        error={Boolean(getIn(formik.touched, "hoVaTen") && getIn(formik.errors, "hoVaTen"))}
                        helperText={Boolean(getIn(formik.touched, "hoVaTen") && getIn(formik.errors, "hoVaTen")) && formik.errors?.hoVaTen}
                    />
                </Grid>
                {/* <Grid item xs={6} className="grid-item">
                    <TextField
                        fullWidth
                        required
                        label={"CCCD thân nhân"}
                        name="cmndThanNhan"
                        value={formik.values.cmndThanNhan}
                        onChange={formik.handleChange}
                        InputLabelProps={{ classes: labelStyles }}
                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                        FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                        error={Boolean(getIn(formik.touched, "cmndThanNhan") && getIn(formik.errors, "cmndThanNhan"))}
                        helperText={Boolean(getIn(formik.touched, "cmndThanNhan") && getIn(formik.errors, "cmndThanNhan")) && formik.errors?.cmndThanNhan}
                    />
                </Grid> */}
                <Grid item xs={3} className="grid-item">
                    {formik?.values?.chiNhapNamSinh ? 
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                name="ngaySinh"
                                label={"Ngày sinh"}
                                required
                                value={(formik.values.ngaySinh !== 0 && formik.values.ngaySinh && formik.values.ngaySinh !== null && formik.values.ngaySinh !== undefined) ? new Date(Number(formik.values.ngaySinh)) : null}
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngaySinh')}
                                autoOk={true}
                                fullWidth
                                format="YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "ngaySinh") && getIn(formik.errors, "ngaySinh"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "ngaySinh") && getIn(formik.errors, "ngaySinh")) && formik.errors.ngaySinh}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />  
                        </MuiPickersUtilsProvider> :
                         <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                name="ngaySinh"
                                label={"Ngày sinh"}
                                required
                                value={(formik.values.ngaySinh !== 0 && formik.values.ngaySinh && formik.values.ngaySinh !== null && formik.values.ngaySinh !== undefined) ? new Date(Number(formik.values.ngaySinh)) : null}
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngaySinh')}
                                autoOk={true}
                                fullWidth
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                error={Boolean(getIn(formik.touched, "ngaySinh") && getIn(formik.errors, "ngaySinh"))}
                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                helperText={Boolean(getIn(formik.touched, "ngaySinh") && getIn(formik.errors, "ngaySinh")) && formik.errors.ngaySinh}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            />  
                     </MuiPickersUtilsProvider>   
                    }
                                 
                </Grid>
                <Grid item xs={3} className="grid-item" style={{paddingTop: '25px'}} >
                    <FormControlLabel style={{ marginLeft: '0px' }} label={<span className="label-checkbox"> Chỉ nhập năm sinh</span>}
                        control={
                            <Checkbox name="chiNhapNamSinh" checked={formik.values.chiNhapNamSinh} color="default" size="small" 
                            value={formik.values.chiNhapNamSinh}  onChange={formik.handleChange}/>
                           
                        }
                    />
                </Grid>      
            </Grid>
            <Grid container>
                <Grid item xs={12} className="grid-item" container direction="row" justify="center" alignItems="center">
                    <Button  type="button" variant="contained" color="primary"
                        className="none-margin" disableElevation  onClick={() => {
                            formik.handleSubmit();
                        }}>
                        Tra cứu công dân
                    </Button>
                </Grid>
                {/* <Grid item xs={6} className="grid-item" container direction="row" justify="flex-start" alignItems="center">
                    <Button onClick={() => history.push('/giay-phep-kinh-doanh/nhap-dau-ky')} type="button" variant="contained" color="primary"
                        className="none-margin" disableElevation>
                        Xác thực hộ gia đình
                    </Button>
                </Grid> */}
            </Grid>
            <Grid container>
                
                    <Grid item xs={12} className="grid-item">
                        <InputLabel classes={labelStyles}>Thông tin công dân</InputLabel>
                    </Grid>
                    <GridView
                        labelLeft="Họ và tên"
                        valueLeft={data?.hoVaTen}
                        labelRight="Số định danh"
                        valueRight={data?.soDinhDanh}
                    />    
                    <GridView
                        labelLeft="CMND"
                        valueLeft={data?.soCMND}
                        labelRight="Năm sinh"
                        valueRight={data?.ngaySinh}
                    />    
                    <GridView
                        labelLeft="Dân tộc"
                        valueLeft={data?.tenDanToc}
                        labelRight="Giới tính"
                        valueRight={data?.tenGioiTinh}
                    />    
                    <GridView
                        labelLeft="Tôn giáo"
                        valueLeft={data?.tenTonGiao}
                        labelRight="Hôn nhân"
                        valueRight={data?.tinhTrangHonNhan}
                    />    
                    <GridView
                        labelLeft="Quốc tịch"
                        valueLeft={data?.tenQuocTich}
                        labelRight="Sổ hộ khẩu"
                        valueRight={data?.soHoKhau}
                    />    
                    <Grid item xs={2} className="grid-item">
                        <InputLabel classes={labelLeftStyle}>Nơi đăng ký khai sinh </InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                         <InputLabel className="detail-label">{data?.noiDangKyKhaiSinh?.chiTiet}</InputLabel>
                    </Grid>
                    <Grid item xs={2} className="grid-item">
                        <InputLabel classes={labelLeftStyle}>Quê quán</InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                        <InputLabel className="detail-label">{data?.queQuan?.chiTiet}</InputLabel>
                    </Grid>
                    <Grid item xs={2} className="grid-item">
                        <InputLabel classes={labelLeftStyle}>Thường trú</InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                        <InputLabel className="detail-label">{[data?.thuongTru?.chiTiet, data?.thuongTru?.tenPhuongXa, data?.thuongTru?.tenQuanHuyen, data?.thuongTru?.tenTinhThanh].join(", ")}</InputLabel>  
                    </Grid>
                    <Grid item xs={2} className="grid-item">
                        <InputLabel classes={labelLeftStyle}>Nơi ở hiện tại</InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                        <InputLabel className="detail-label">{[data?.noiOHienTai?.chiTiet, data?.noiOHienTai?.tenPhuongXa, data?.noiOHienTai?.tenQuanHuyen, data?.noiOHienTai?.tenTinhThanh].join(", ")}</InputLabel>  
                    </Grid>

                    <Grid item xs={12} className="grid-item">
                        <DanhSachThanhVienCongDan
                            dataList={data?.thanhViens}
                        />
                </Grid>
            </Grid>
        </div>
    );

}