import React, { useEffect, useRef, useState } from 'react';
import {
    Box, Button,
    createStyles,
    Dialog,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    makeStyles,
    Theme
} from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { TBSanXuatRuouDTO } from "../../../../../../api/CPBLR/model/TBSanXuatRuouDTO";
import { DialogContent, DialogTitle } from "../../../../../commons/components/dialog/dialogCommons";

interface SanXuatRuouModalProps {
    onSubmitData: (resp: TBSanXuatRuouDTO, isEdit: Boolean) => void;
    openDialog: boolean;
    itemEdit: TBSanXuatRuouDTO;
    onDialogClose: () => void;
    openEdit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
    }),
);

interface FormValues {
    requestDTO: TBSanXuatRuouDTO,
}

const SanXuatRuouModal = (props: SanXuatRuouModalProps) => {
    const { onSubmitData } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    const validateionSchema = Yup.object().shape({
        requestDTO: Yup.object().shape({
            tenLoaiRuou: Yup.string().required('Vui lòng nhập tên loại rượu!'),
            quyMo: Yup.string().required('Vui lòng nhập quy mô!'),
        }).required('Vui lòng nhập thông tin sản xuất rượu!')
    });

    const initDefaultValue: FormValues = {
        requestDTO: {
            tenLoaiRuou: '',
            quyMo: ''
        } as TBSanXuatRuouDTO
    }

    const formik = useFormik({
        initialValues: initDefaultValue,
        validationSchema: validateionSchema,
        onSubmit: () => submitData()
    });

    useEffect(() => {
        if (props.openEdit) {
            formik.setValues({
                requestDTO: {
                    tenLoaiRuou: props.itemEdit.tenLoaiRuou,
                    quyMo: props.itemEdit.quyMo,
                    ngayTao: props.itemEdit.ngayTao,
                } as TBSanXuatRuouDTO,
            });
        } else {
            formik.setValues({
                requestDTO: {
                    tenLoaiRuou: '',
                    quyMo: ''
                } as TBSanXuatRuouDTO,
            });
        }
        setOpenEdit(props.openEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    const submitData = async () => {
        onSubmitData(formik.values.requestDTO, openEdit);
    }

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="sm" aria-labelledby="customized-dialog-title"
                open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {'CẬP NHẬT THÔNG TIN SẢN XUẤT RƯỢu'}
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel required>Tên loại rượu</InputLabel>
                                    <InputBase fullWidth
                                        error={formik.touched.requestDTO?.tenLoaiRuou && Boolean(formik.errors.requestDTO?.tenLoaiRuou)}
                                        value={formik.values.requestDTO.tenLoaiRuou} name="requestDTO.tenLoaiRuou"
                                        onChange={formik.handleChange} />
                                    {
                                        formik.touched.requestDTO?.tenLoaiRuou && Boolean(formik.errors.requestDTO?.tenLoaiRuou) &&
                                        <FormHelperText className="-error"
                                        >{formik.errors.requestDTO?.tenLoaiRuou}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel required>Quy mô</InputLabel>
                                    <InputBase fullWidth
                                        error={formik.touched.requestDTO?.quyMo && Boolean(formik.errors.requestDTO?.quyMo)}
                                        value={formik.values.requestDTO.quyMo} name="requestDTO.quyMo"
                                        onChange={formik.handleChange} />
                                    {
                                        formik.touched.requestDTO?.quyMo && Boolean(formik.errors.requestDTO?.quyMo) &&
                                        <FormHelperText className="-error"
                                        >{formik.errors.requestDTO?.quyMo}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} className="gridItem">
                                    <Box marginTop="5px">
                                        <Button type="submit" variant="contained" color="primary" disableElevation
                                        >
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="secondary" disableElevation
                                            onClick={() => {
                                                formik.resetForm({ values: initDefaultValue, });
                                            }}
                                        > Làm mới
                                        </Button>
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={props.onDialogClose}>
                                            Đóng
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SanXuatRuouModal;
