import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { App } from "./app";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { setDefaultOptions } from "esri-loader";
setDefaultOptions({ css: true });

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
reportWebVitals();
