import React from "react";
import StringUtil from "../../../utils/stringUtils";
import { Cell } from "./tableCommons";
import moment from "moment";
import { TypeCell } from "./commonTableChuyenNganh";
import { Button } from "@material-ui/core";
import AttachFileService from "../../../../api/Common/attachFileService";


interface IPropsCell<T> {
    item: T;
    field: keyof T;
    type?: TypeCell;
    align?: "inherit" | "left" | "center" | "right" | "justify";
}

async function handleDownloadFile(files: any) {
    for (const item of files) {
        await new AttachFileService().downloadFile(item).then((res) => {
            let blob = new Blob([res.data], { type: res.data.type });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${item.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }
}

const CustomCell = <T extends object>({ item, field, type, align }: IPropsCell<T>) => {
    switch (type) {
        case TypeCell.CURRENCY:
            return <Cell align={align}>{item[field] && StringUtil.formatMoney(`${item[field]}`)}</Cell>;
        case TypeCell.DATE:
            return <Cell align="center">{item[field] ? moment(item[field]).format("DD/MM/YYYY") : ""}</Cell>;
        case TypeCell.DATE_TIME:
            return <Cell align="center">{item[field] ? moment(item[field]).format("DD/MM/YYYY hh:mm:ss") : ""}</Cell>;
        case TypeCell.FILE:
            const files: any = item[field];
            return (
                <Cell>
                    {files.length > 0 ? (
                        <Button color="primary" onClick={() => handleDownloadFile(files)}>
                            Tải về
                        </Button>
                    ) : null}
                </Cell>
            );
        default:
            return <Cell align={align}>{item[field]}</Cell>;
    }
};

export default CustomCell;
