import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { BrowserHistory } from 'history';
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../commons/components/tabs";
import CapMoiBLThuocLaNoiDungComponent from "./CapMoiBLThuocLaNoiDungComponent";
import { HoSoThuLyBLThuocLa } from "../../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import { CAP_MOI_BL_THUOC_LA_INIT } from "../../../../../api/CPBLTL/init/CapMoiBLThuocLaInit";
import StringUtil from "../../../../utils/stringUtils";
import { CapMoiBLThuocLaService } from "../../../../../api/CPBLTL/CapMoiBLThuocLaService";
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { BieuMauAttachFileDTO } from "../../../../../api/QuanTri/Model/danhMucBieuMau";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from '../../../../Constants/ThuocLaConstants';
import ThuLyButtonComponent from '../../../../commons/components/hoso/thuly/ThuLyButtonComponent';
import { useGetThongTinChuyenNganh } from '../../../../../pages/hoSoCuaToi/useGetThongTinChuyenNganh';
import ThongTinChuyenNganhUtil from '../../../../../pages/hoSoCuaToi/ThongTinChuyenNganhUtil';
import { ThongTinChuyenNhanhUtil } from '../../../../utils/thongTinChuyenNhanhUtil';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { LinhVucCapSoGPService } from '../../../../../api/DKKD/LinhVucCapSoGPService';
import { UserData } from '../../../../../api/userMgt/userManagementService';
import { THUOCLA_CN } from '../../../../Constants/constants';
import { HoSoCuaToiMgtService } from '../../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';
type StateHoSo = {
    maThuTuc: string,
    maHoSo: string
}
type CapMoiSXRuouProps = {
    history: BrowserHistory<StateHoSo>
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const CapMoiBLThuocLaComponent = (props: CapMoiSXRuouProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [showLayKetQua, setShowLayKetQua] = React.useState(false);
    const [ngayHenTra, setNgayHenTra] = useState<number>(0);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = React.useState({} as HoSoThuLyBLThuocLa);
    const capMoiBLThuocLaService = new CapMoiBLThuocLaService();
    const { history } = props;
    const service = new LinhVucCapSoGPService();
    let C_USER_DATA_DE = {} as UserData;
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    const thongTinChuyenNganh = useGetThongTinChuyenNganh(history?.location?.state?.maHoSo);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    useEffect(() => {
        initData();
    }, []);   
    const initData = async () => {
        dispatchLoading(showLoading());
        let maHoSo = history?.location?.state?.maHoSo;
        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        let ngayHenTra = new Date();
        if (!StringUtil.isNullOrEmty(C_USER_DATA_DE.maCoQuan)) {
            hoSoCuaToiMgtService.chiTiet(C_USER_DATA_DE.maCoQuan, history?.location?.state?.maHoSo).then((p) => {
                if (p.data && p.data.data) {
                    hoTenNguoiNop = p.data.data.nguoiNop;
                    soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
                    let date = new Date(p.data.data.ngayHenTra);
                    setNgayHenTra(date.getTime());
                }
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
        await capMoiBLThuocLaService.getByMaHoSo({ maHoSo: history?.location?.state?.maHoSo }).then(async (res) => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let resData = {
                    ...res?.data?.data,
                    maBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten
                } as HoSoThuLyBLThuocLa;
                setData(resData);
                if (StringUtil.isNotEmpty(res?.data?.data.soGiayPhep) && StringUtil.isNotEmpty(res?.data?.data.maHoSo)){
                    setShowLayKetQua(true);
                }
            } else {
                let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHoSo);
                let maQuan = "";
                let tenQuan = "";
                let maPhuong = "";
                let tenPhuong = "";
                if (!StringUtil.isNullOrEmty(dataEform?.quanhuyen)) {
                    let dataQuan = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                    maQuan = dataQuan[0].code;
                    tenQuan = dataQuan[0].name;
                }
                if (!StringUtil.isNullOrEmty(dataEform?.xaphuong)) {
                    let dataHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                    maPhuong = dataHuyen[0].code;
                    tenPhuong = dataHuyen[0].name;
                }
                let soGiayPhepDayDu = "";
                await service.getGPTuDong(C_USER_DATA_DE.maCoQuan, THUOCLA_CN.value).then(res => {
                    if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                        soGiayPhepDayDu = res.data.data.soGPDayDu;
                    }
                }).catch(error => {
                    return;
                });
                const dataNew = {
                    ...CAP_MOI_BL_THUOC_LA_INIT,
                    maCoQuan: C_USER_DATA_DE.maCoQuan,
                    maChuyenNganh: THUOCLA_CN.value,
                    soGiayPhep: soGiayPhepDayDu,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    maBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    tenThuongNhan: hoTenNguoiNop != "" ? hoTenNguoiNop : dataEform?.hotennguoidaidien ? dataEform?.hotennguoidaidien : CAP_MOI_BL_THUOC_LA_INIT.tenThuongNhan,
                    fax: dataEform?.fax ? dataEform?.fax : CAP_MOI_BL_THUOC_LA_INIT.fax,
                    dienThoai: soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt ? dataEform?.sdt : CAP_MOI_BL_THUOC_LA_INIT.dienThoai,
                    soCNDKKD: dataEform?.giaydkkd ? dataEform?.giaydkkd : CAP_MOI_BL_THUOC_LA_INIT.soCNDKKD,
                    noiCapCNDKKD: dataEform?.noicapdkkd ? dataEform?.noicapdkkd : CAP_MOI_BL_THUOC_LA_INIT.noiCapCNDKKD,
                    ngayDangKyLanDau: dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : CAP_MOI_BL_THUOC_LA_INIT.ngayDangKyLanDau,
                    maQuanHuyen: dataEform?.quanhuyen ? dataEform?.quanhuyen : CAP_MOI_BL_THUOC_LA_INIT.maQuanHuyen,
                    tenQuanHuyen: tenQuan,
                    maPhuongXa: dataEform?.xaphuong ? dataEform?.xaphuong : CAP_MOI_BL_THUOC_LA_INIT.maPhuongXa,
                    tenPhuongXa: tenPhuong,
                    diaDiemKinhDoanh: dataEform?.diachidoanhnghiep ? dataEform?.diachidoanhnghiep : CAP_MOI_BL_THUOC_LA_INIT.diaDiemKinhDoanh,
                    tenDuong: dataEform?.diadiem ? dataEform?.diadiem : CAP_MOI_BL_THUOC_LA_INIT.tenDuong,
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });

    }
    const handleSubmit = () => {
        if (StringUtil.isNullOrEmty(data.maHoSo)) {
            setTabRequired(-0);
            showMessageAlert('Không có mã hồ sơ', false);
            return;
        }
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan)
            || StringUtil.isNullOrEmty(data.soCNDKKD)
            || StringUtil.isNullOrEmty(data.maPhuongXa)) {
            setTabRequired(-0);
            return;
        }
        if (data.donViCapPhep) {
            if (StringUtil.isNullOrEmty(data.soGiayPhep)
                || data.ngayCapPhep === 0) {
                setTabRequired(-0);
                return;
            }
        }
        setSubmit(false);
        let dataRequest: HoSoThuLyBLThuocLa = { ...data };
        if (!data.donViCapPhep) {
            dataRequest = {
                ...data,
                soGiayPhep: '',
                ngayCapPhep: 0,
                ngayHetHanGP: 0,
                ngayHenTra: ngayHenTra,
                maCoQuan: C_USER_DATA_DE.maCoQuan,
            }
        }
        capMoiBLThuocLaService.insertOrUpdate(dataRequest).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL' && parseInt(res.data.data.id) > 0) {
                    initData();
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else if (res.data.error_code == 'DUPLICATE_CODE') {
                    setAlertMessage({ open: true, content: 'Trùng số giấy phép', severity: 'warning' });
                } else {
                    showMessageAlert('Yêu cầu thực hiện thất bại', false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
      
    }
    const resetData = () => {
        const dataNew = {
            ...CAP_MOI_BL_THUOC_LA_INIT,
            maBienDong: LOAIBIENDONG_CAPMOI.ma,
            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten,
            id: data.id,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc,
            fileTrash: data?.files
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        capMoiBLThuocLaService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    }

    return (
        <div className="dash-content">
            <>
                {
                    <Paper square>
                        <OverlayLoading />
                        <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                            <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                        </Snackbar>
                        <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                            title="Xóa thông tin"
                            content="Bạn có đồng ý xóa thông tin đã nhập?"
                            onClose={handleCloseDeleteInfo}
                        />
                        <Grid container>
                            {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                                <br></br>
                                    <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    CẤP GIẤY PHÉP BÁN LẺ SẢN PHẨM THUỐC LÁ - MÃ HỒ SƠ : {data.maHoSo}
                                </div>
                            </div>
                            {/* </h6>
                                <br></br>
                            </Grid> */}
                            <br />
                            <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                                <Box mt="5px" bgcolor="#f2f2f2">
                                    <CustomTabs value={indexTab} onChange={handleChange}>
                                        <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                    </CustomTabs>
                                </Box>
                            </Grid>
                            {
                                indexTab === 0 &&
                                <TabPanel value={indexTab} index={0}>
                                    <CapMoiBLThuocLaNoiDungComponent
                                        onUpdateData={setDataParam}
                                        submit={submit}
                                        data={data} 
                                        showLayKetQua={showLayKetQua}
                                        />
                                </TabPanel>
                            }
                            <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                                <ThuLyButtonComponent showIn={data.id > 0} onSubmit={handleSubmit} onDownloadFileBieuMau={handleDownloadFileBieuMau} onDelete={handleDelete} />
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </>
        </div>
    )
};

export default CapMoiBLThuocLaComponent;
