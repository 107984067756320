import * as Types from '../actions/actionTypes';

var initialState: any[] = [];

export const consultingUnitsReducer = (state = initialState, action: any) => {
    const { index } = action;
    switch (action.type) {
        case Types.ADD_CONSULTING_UNIT:
            const { consulting } = action;
            state.push(consulting);
            return [...state];
        case Types.DELETE_CONSULTING:
            state.splice(index, 1);
            return [...state];
        default:
            return [...state];
    }
}