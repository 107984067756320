import AXIOS_INSTANCE from "..";
import { DanhMucI, SearchRequest } from "../Common/model/CommonModel";
export class LinhVucKinhDoanhService {
    constructor(){}
    search = (request : SearchRequest) => {
       return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-kinh-doanh/search', request);
    }
    getByHoatDong = (hoatDong : boolean) => {
       return AXIOS_INSTANCE.get('dkkd/dm-linh-vuc-kinh-doanh/get-by-hoat-dong/' + hoatDong);
    }
    addOrUpdate = (request : DanhMucI) => {
        return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-kinh-doanh/addOrUpdate', request);
     }
     delete = (id: number) => {
      return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-kinh-doanh/delete/'+ id);
    }
}
