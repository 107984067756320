import { Box, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import { CustomInput } from "../../../commons/components/input/customInput";
import { ThongKeGiayPhepRuouDTO } from '../../../../api/CPBLR/model/ThongKeGiayPhepRuouDTO';

interface TablePaginationActionsProps {
    sum: ThongKeGiayPhepRuouDTO;
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface IDataListProps {
    dataList: ThongKeGiayPhepRuouDTO[];
    pageProps: TablePaginationActionsProps;
    detailRequest: any;
    setOpenDialog: Dispatch<SetStateAction<boolean>>;
    setDetailRequest: Dispatch<SetStateAction<any>>;
}

const DanhSachThongKeRuouComponent = (props: IDataListProps) => {
    const [rows, setRows] = useState<ThongKeGiayPhepRuouDTO[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1);

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.page);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage, props.pageProps.sum]);

    const handleOnClickChiTietPhuongXa = (
      row: any,
      type: string,
      count: number
    ) => {
      props.setOpenDialog(true);
      props.setDetailRequest({
        ...props.detailRequest,
        maBienDong: type,
        maPhuongXaTotal: row.maPhuongXa,
        tenPhuongXa: row.ten,
        total: count,
      });
    };

    const handleOnClickChiTietQuanHuyen = (type: string, count: number) => {
      props.setOpenDialog(true);
      props.setDetailRequest({
        ...props.detailRequest,
        maBienDong: type,
        maPhuongXaTotal: null,
        tenPhuongXa: null,
        total: count,
      });
    };

    return (
      <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <Cell component="th" scope="row" align="center" width="5%">
                STT
              </Cell>
              <Cell component="th" scope="row" align="center" width="25%">
                Phường/ Xã/ Thị trấn
              </Cell>
              <Cell component="th" scope="row" align="center" width="9%">
                Cấp mới
              </Cell>
              <Cell component="th" scope="row" align="center" width="9%">
                Cấp lại
              </Cell>
              <Cell component="th" scope="row" align="center" width="9%">
                Thu hồi
              </Cell>
              <Cell component="th" scope="row" align="center" width="9%">
                Hủy
              </Cell>
              <Cell component="th" scope="row" align="center" width="9%">
                Hoàn trả huy
              </Cell>
              <Cell component="th" scope="row" align="center" width="9%">
                Hoàn trả thu hồi
              </Cell>
              <Cell component="th" scope="row" align="center" width="9%">
                Cấp đổi
              </Cell>
              <Cell component="th" scope="row" align="center" width="9%">
                Hết hạn
              </Cell>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 &&
              rows.map((row, i) => (
                <Row key={i}>
                  <Cell scope="row" align="center">
                    {(props.pageProps.page - 1) * props.pageProps.rowsPerPage +
                      i +
                      1}
                  </Cell>
                  <Cell align="left">{row.ten}</Cell>
                  <Cell align="center" className="text-center">
                    <span
                      style={{ cursor: row.capMoi > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.capMoi > 0) {
                          handleOnClickChiTietPhuongXa(row, "1", row.capMoi);
                        }
                      }}
                    >
                      {row.capMoi}
                    </span>
                  </Cell>
                  <Cell align="center">
                    <span
                      style={{ cursor: row.capLai > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.capLai > 0) {
                          handleOnClickChiTietPhuongXa(row, "2", row.capLai);
                        }
                      }}
                    >
                      {row.capLai}
                    </span>
                  </Cell>
                  <Cell align="center">
                    <span
                      style={{ cursor: row.thuHoi > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.thuHoi > 0) {
                          handleOnClickChiTietPhuongXa(row, "4", row.thuHoi);
                        }
                      }}
                    >
                      {row.thuHoi}
                    </span>
                  </Cell>
                  <Cell align="center">
                    <span
                      style={{ cursor: row.huy > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.huy > 0) {
                          handleOnClickChiTietPhuongXa(row, "3", row.huy);
                        }
                      }}
                    >
                      {row.huy}
                    </span>
                  </Cell>
                  <Cell align="center">
                    <span
                      style={{ cursor: row.hoanTraHuy > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.hoanTraHuy > 0) {
                          handleOnClickChiTietPhuongXa(row, "5", row.hoanTraHuy);
                        }
                      }}
                    >
                      {row.hoanTraHuy}
                    </span>
                  </Cell>
                  <Cell align="center">
                    <span
                      style={{ cursor: row.hoanTraThuHoi > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.hoanTraThuHoi > 0) {
                          handleOnClickChiTietPhuongXa(row, "6", row.hoanTraThuHoi);
                        }
                      }}
                    >
                      {row.hoanTraThuHoi}
                    </span>
                  </Cell>
                  <Cell align="center">
                    <span
                      style={{ cursor: row.capDoi > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.capDoi > 0) {
                          handleOnClickChiTietPhuongXa(row, "7", row.capDoi);
                        }
                      }}
                    >
                      {row.capDoi}
                    </span>
                  </Cell>
                  <Cell align="center">
                    <span
                      style={{ cursor: row.hetHan > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.hetHan > 0) {
                          handleOnClickChiTietPhuongXa(row, "8", row.hetHan);
                        }
                      }}
                    >
                      {row.hetHan}
                    </span>
                  </Cell>
                </Row>
              ))}
            {rows?.length > 0 && (
              <Row key={"sum"}>
                <Cell scope="row" align="center"></Cell>
                <Cell scope="row" align="left" className="font-weight-bold">
                  Tổng số
                </Cell>
                <Cell align="center" className="font-weight-bold text-center">
                  <span
                    style={{
                      cursor:
                        props.pageProps?.sum?.capMoi &&
                        props.pageProps?.sum?.capMoi > 0
                          ? "pointer"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        props.pageProps?.sum?.capMoi &&
                        props.pageProps?.sum?.capMoi > 0
                      )
                        handleOnClickChiTietQuanHuyen(
                          "1",
                          props.pageProps?.sum?.capMoi
                            ? props.pageProps?.sum?.capMoi
                            : 0
                        );
                    }}
                  >
                    {props.pageProps?.sum?.capMoi}
                  </span>
                </Cell>
                <Cell align="center" className="font-weight-bold">
                  <span
                    style={{
                      cursor:
                        props.pageProps?.sum?.capLai &&
                        props.pageProps?.sum?.capLai > 0
                          ? "pointer"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        props.pageProps?.sum?.capLai &&
                        props.pageProps?.sum?.capLai > 0
                      )
                        handleOnClickChiTietQuanHuyen(
                          "2",
                          props.pageProps?.sum?.capLai
                            ? props.pageProps?.sum?.capLai
                            : 0
                        );
                    }}
                  >
                    {props.pageProps?.sum?.capLai}
                  </span>
                </Cell>
                <Cell align="center" className="font-weight-bold">
                  <span
                    style={{
                      cursor:
                        props.pageProps?.sum?.thuHoi &&
                        props.pageProps?.sum?.thuHoi > 0
                          ? "pointer"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        props.pageProps?.sum?.thuHoi &&
                        props.pageProps?.sum?.thuHoi > 0
                      )
                        handleOnClickChiTietQuanHuyen(
                          "4",
                          props.pageProps?.sum?.thuHoi
                            ? props.pageProps?.sum?.thuHoi
                            : 0
                        );
                    }}
                  >
                    {props.pageProps?.sum?.thuHoi}
                  </span>
                </Cell>
                <Cell align="center" className="font-weight-bold">
                  <span
                    style={{
                      cursor:
                        props.pageProps?.sum?.huy &&
                        props.pageProps?.sum?.huy > 0
                          ? "pointer"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        props.pageProps?.sum?.huy &&
                        props.pageProps?.sum?.huy > 0
                      )
                        handleOnClickChiTietQuanHuyen(
                          "3",
                          props.pageProps?.sum?.huy
                            ? props.pageProps?.sum?.huy
                            : 0
                        );
                    }}
                  >
                    {props.pageProps?.sum?.huy}
                  </span>
                </Cell>
                <Cell align="center" className="font-weight-bold">
                  <span
                    style={{
                      cursor:
                        props.pageProps?.sum?.hoanTraHuy &&
                        props.pageProps?.sum?.hoanTraHuy > 0
                          ? "pointer"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        props.pageProps?.sum?.hoanTraHuy &&
                        props.pageProps?.sum?.hoanTraHuy > 0
                      )
                        handleOnClickChiTietQuanHuyen(
                          "5",
                          props.pageProps?.sum?.hoanTraHuy
                            ? props.pageProps?.sum?.hoanTraHuy
                            : 0
                        );
                    }}
                  >
                    {props.pageProps?.sum?.hoanTraHuy}
                  </span>
                </Cell>
                <Cell align="center" className="font-weight-bold">
                  <span
                    style={{
                      cursor:
                        props.pageProps?.sum?.hoanTraThuHoi &&
                        props.pageProps?.sum?.hoanTraThuHoi > 0
                          ? "pointer"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        props.pageProps?.sum?.hoanTraThuHoi &&
                        props.pageProps?.sum?.hoanTraThuHoi > 0
                      )
                        handleOnClickChiTietQuanHuyen(
                          "6",
                          props.pageProps?.sum?.hoanTraThuHoi
                            ? props.pageProps?.sum?.hoanTraThuHoi
                            : 0
                        );
                    }}
                  >
                    {props.pageProps?.sum?.hoanTraThuHoi}
                  </span>
                </Cell>
                <Cell align="center" className="font-weight-bold">
                  <span
                    style={{
                      cursor:
                        props.pageProps?.sum?.capDoi &&
                        props.pageProps?.sum?.capDoi > 0
                          ? "pointer"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        props.pageProps?.sum?.capDoi &&
                        props.pageProps?.sum?.capDoi > 0
                      )
                        handleOnClickChiTietQuanHuyen(
                          "7",
                          props.pageProps?.sum?.capDoi
                            ? props.pageProps?.sum?.capDoi
                            : 0
                        );
                    }}
                  >
                    {props.pageProps?.sum?.capDoi}
                  </span>
                </Cell>
                <Cell align="center" className="font-weight-bold">
                  <span
                    style={{
                      cursor:
                        props.pageProps?.sum?.hetHan &&
                        props.pageProps?.sum?.hetHan > 0
                          ? "pointer"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        props.pageProps?.sum?.hetHan &&
                        props.pageProps?.sum?.hetHan > 0
                      )
                        handleOnClickChiTietQuanHuyen(
                          "8",
                          props.pageProps?.sum?.hetHan
                            ? props.pageProps?.sum?.hetHan
                            : 0
                        );
                    }}
                  >
                    {props.pageProps?.sum?.hetHan}
                  </span>
                </Cell>
              </Row>
            )}
            {rows?.length <= 0 && (
              <Row>
                <Cell colSpan={10} scope="row" align="center">
                  {" "}
                  Không có dữ liệu
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
        {rows.length > 0 ? (
          <Box
            paddingTop="10px"
            display="flex"
            alignItems="center"
            fontSize="14px"
            color="#555555"
          >
            <Pagination
              count={totalPage}
              defaultPage={1}
              siblingCount={1}
              variant="outlined"
              page={props.pageProps.page}
              size="medium"
              color="primary"
              shape="rounded"
              showFirstButton
              showLastButton
              onChange={props.pageProps.onChangePage}
            />
            <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
              <Box component="span" paddingX="5px">
                Số mẫu tin trên trang
              </Box>
              <NativeSelect
                value={props.pageProps.rowsPerPage}
                input={<CustomInput />}
                onChange={props.pageProps.onChangeRowsPerPage}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </NativeSelect>
            </Box>
            <Box component="span" paddingX="5px">
              Mẫu tin {props.pageProps.page * rowsPerPage} -{" "}
              {props.pageProps.rowsPerPage * rowsPerPage > props.pageProps.count
                ? props.pageProps.count
                : props.pageProps.rowsPerPage * rowsPerPage}{" "}
              / {props.pageProps.count}
            </Box>
          </Box>
        ) : (
          ""
        )}
      </TableContainer>
    );
}
export default DanhSachThongKeRuouComponent;
