import React, { Dispatch, useEffect, useState } from 'react';
import {
    Button,
    Box, Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputAdornment,
    InputBase,
    InputLabel,
    Snackbar
} from '@material-ui/core';
import StringUtil from "../../../../../utils/stringUtils";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { HoSoThuLyBLR } from "../../../../../../api/CPBLR/model/HoSoThuLyBLR";
import { TBSanXuatRuouDTO } from "../../../../../../api/CPBLR/model/TBSanXuatRuouDTO";
import { TBPhanPhoiRuouDTO } from "../../../../../../api/CPBLR/model/TBPhanPhoiRuouDTO";
import { TBDiaDiemBLRuouDTO } from "../../../../../../api/CPBLR/model/TBDiaDiemBLRuouDTO";
import DropZoneCustom from "../../../../../commons/components/CustomDropzone/DropZoneCustom";
import { FileEntry } from "../../../../../../api/Common/fileEntry";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { createFileType, downLoadFile } from "../../../../../../api/Common/exportUtil";
import { FileServerService } from "../../../../../../api/Common/FileServerService";
import TablePhanPhoiRuouComponent from "../../SanXuat/Table/TablePhanPhoiRuouComponent";
import TableDiaDiemBLRuouComponent from "../../SanXuat/Table/TableDiaDiemBLRuouComponent";
import AXIOS_INSTANCE from "../../../../../../api";
import SearchIcon from '@material-ui/icons/Search';
import { DanhMucDiaChiDTO } from '../../../../../../api/danhMuc/model/DanhMucDTO';
import { DanhMucService } from '../../../../../../api/danhMuc/danhMucService';
import { AlertMessage, CustomAlert } from '../../../../../commons/components/alert';
import DateUtil from '../../../../../utils/dateUtils';
import { useDispatch } from 'react-redux';
import { hideLoading, LoadingAction, showLoading } from '../../../../../../redux/actions/applicationAction';
import { LoaiGiayPhepEnum } from '../../../../../../api/loaiGiayPhep/loaiGiayPhepEnum';
import LayKetQuaGiayPhepService from '../../../../../../api/QuanTri/LayKetQuaGiayPhepService';
import { GiayPhepRequest } from '../../../../../../api/QuanTri/Model/MotCuaModel';
import { KetQuaGiayPhepDTO } from '../../../../../../api/Common/KetQuaGiayPhepDTO';

interface CapMoiBLRuouNoiDungComponentProps {
    data: HoSoThuLyBLR;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
    showLayKetQua: boolean;

}

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

const CapMoiBLRuouNoiDungComponent = (props: CapMoiBLRuouNoiDungComponentProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = React.useState({} as HoSoThuLyBLR);
    const { onUpdateData, submit, showLayKetQua } = props;
    const [showCapSo, setShowCapSo] = useState<boolean>(false);
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const fileServerService = new FileServerService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongTruSos, setPhuongTruSos] = useState<DanhMucDiaChiDTO[]>([]);
    const [tinhs, setTinhs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    useEffect(() => {
        loadTinhs();
    }, []);
    const loadQuans = (maThanhPho: string) => {
        if(!StringUtil.isEmty(maThanhPho)){
            danhMucService.getDanhMucDiaChi(maThanhPho).then(res => {
                if (res.length > 0) {
                    setQuans(res);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    
    const loadTinhs = () => {
        danhMucService.getDanhMucDiaChi('').then(res => {
            if (res.length > 0) {
                setTinhs(res);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true, isWarning?: boolean) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : isWarning? "warning":"error" });
    };

    const loadPhuongs = (maQuan: string) => {
        if (maQuan != null && maQuan != "") {
            danhMucService.getDanhMucDiaChi(maQuan).then(res => {
                setPhuongTruSos(res);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    const handleChangeSelectTinhTruSo = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, maQuanTruSo: '', tenQuanTruSo: '',maPhuongTruSo: '', tenPhuongTruSo:'', [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChangeSelectQuanTruSo = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, maPhuongTruSo: '', tenPhuongTruSo: '', [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    
    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    useEffect(() => {
        setData(props.data);
        const dataNew: HoSoThuLyBLR = {
            ...props.data,
        }
        setData(dataNew);
        setAttachFileServer(props.data?.files);
        setShowCapSo(props.data?.donViCapPhep)
    }, [props.data, props.onUpdateData]);
  
 
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }

    const setSanXuatRuous = (memData: TBSanXuatRuouDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data.sanXuatRuous];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            sanXuatRuous: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const setPhanPhoiRuous = (memData: TBPhanPhoiRuouDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data.phanPhoiRuous];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            phanPhoiRuous: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const setDiaDiemBLRuou = (memData: TBDiaDiemBLRuouDTO, isEdit: Boolean, isDelete: Boolean) => {
        let mems = [...data.diaDiemBLRuous];
        if (isDelete) {
            const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
            mems.splice(idx, 1);
        } else {
            if (isEdit) {
                const idx = mems.findIndex(e => e.ngayTao === memData.ngayTao);
                mems[idx] = memData;
            } else {
                let mem = { ...memData, ngayTao: new Date().getTime() };
                mems.unshift(mem);
            }
        }

        let dataNew = {
            ...data,
            diaDiemBLRuous: mems
        };
        setData(dataNew);
        onUpdateData("data", dataNew);
    }

    const handleChangeDonViCapPhep = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setShowCapSo(value);
        onUpdateData(e.target.name, value);
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data?.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            files: mems
        };
        onUpdateData("data", dataNew);
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let mems = [...data.files];
        let memTrashs = data.fileTrash ? data.fileTrash : [];
        if (item?.fileParentId > 0) {
            memTrashs.push(item);
        }
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            files: mems,
            fileTrash: memTrashs
        };
        setAttachFileServer(mems);
        onUpdateData("data", dataNew);
    }

    const onSearchDKKD = (maSoDoanhNghiep: string) => {
        if (StringUtil.isNullOrEmty(maSoDoanhNghiep)) {
            showMessageAlert("Giấy CNĐKKD/DN/HTX/HKD không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoDKKD({maSoDoanhNghiep: maSoDoanhNghiep}).then(res => {
            let dataNew = res?.data.data;
            
            if (dataNew !== null && dataNew !== undefined) {
                if (dataNew.error_code === 'SUCCESSFUL' && dataNew.data && dataNew.data != null) {
                const tested = DateUtil.stringDDMMYYYtoDate(dataNew.data.ngayThanhLap.split(' ')[0]);
                
                let dataNewDKKD = {
                    ...data,
                    dienThoaiTruSo: dataNew.data.dienThoai,
                    soNhaTruSo: dataNew.data.soNha,
                    faxTruSo: dataNew.data.fax,
                    ngayCap: tested!==null? tested.getTime():0,
                };
                onUpdateData("data", dataNewDKKD);
                } else {
                setAlertMessage({ open: true, content: "Giấy CNĐKKD/DN/HTX/HKD không tồn tại trong dữ liệu", severity: 'warning' });
            }
            } 
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    // const layKetQuaSerVice = new LayKetQuaGiayPhepService();    
    // const handleDongBoGiayPhep =  async () => {
    //     dispatchLoading(showLoading());
    //     await layKetQuaSerVice.layKetQuaByMaHoSo(data.maHoSo) .then((res) => {  
    //         if (res != null && res?.data?.error_code == "SUCCESSFUL" && res?.data?.data!==null) {
    //             if (res?.data?.error_code == "SUCCESSFUL" && res?.data?.data!==null) {
    //                 if (res?.data?.data?.files && res?.data?.data?.files.length>0){
    //                     const files: FileEntry[] = [];
    //                     for (let i = 0 ; i<res?.data?.data?.files.length;i++){
    //                         const file : FileEntry = {
    //                             fileEntryId:0,
    //                             folderId: 0,
    //                             userId: "",
    //                             userName: "",
    //                             name: res.data.data.files[i].fileName,
    //                             createdDate: 0,
    //                             extension: "",
    //                             mimeType: "",
    //                             orginName: "",
    //                             description: "",
    //                             version: "",
    //                             docId: "",
    //                             fileParentId: 0,
    //                             motCua: true,
    //                             url: res.data.data.files[i].url,
    //                         }
    //                        files.push(file);
    //                     }
    //                     data.files = files;
    //                     setAttachFileServer(files);
    //                 }
    //                 data.soGiayPhep = res.data.data.soGiayPhep ? res.data.data.soGiayPhep : null
    //                 data.ngayCapPhep = res.data.data.ngayCap ? res.data.data.ngayCap : null
    //                 if (res.data.data.ngayHetHan!==null){
    //                     data.ngayHetHanGP = res.data.data.ngayHetHan ? res.data.data.ngayHetHan : null
    //                 }
    //                 const ketQuaGiayPhep: KetQuaGiayPhepDTO={
    //                     soGiayPhep: res.data.data.soGiayPhep,
    //                     ngayHetHan : res.data.data.ngayHetHan,
    //                     files: res.data.data.files,
    //                     ngayCap: res.data.data.ngayCap,
    //                 }
    //                 data.ketQuaGiayPhep = ketQuaGiayPhep;
    //                 showMessageAlert(res.data.error_message);
    //             } else{
    //                 showMessageAlert(res.data.error_message, false);
    //             }}
    //         }
    //     ).finally(() => {
    //         dispatchLoading(hideLoading());
    //     });
    // }

    return (
        <>
            {
                <Box padding={0}>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={12} className="grid-item">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend"></FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value={data?.donViCapPhep}
                                            onChange={handleChangeDonViCapPhep}
                                            name="donViCapPhep"
                                            control={<Checkbox color="primary" checked={data?.donViCapPhep == true ? true : false} />}
                                            label="Đơn vị cấp số giấy phép"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                                <InputLabel required>Số giấy phép </InputLabel>
                                <InputBase fullWidth
                                    value={data?.soGiayPhep} name="soGiayPhep"
                                    error={submit && StringUtil.isNullOrEmty(data?.soGiayPhep)}
                                    onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.soGiayPhep) &&
                                    <FormHelperText className="-error">Vui lòng nhập số giấy phép</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                                <InputLabel>Ngày cấp </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.ngayCapPhep != null && data.ngayCapPhep !== undefined && data.ngayCapPhep != 0) ? new Date(data.ngayCapPhep) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!showCapSo}>
                                <InputLabel>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.ngayHetHanGP != null && data.ngayHetHanGP !== undefined && data.ngayHetHanGP != 0) ? new Date(data.ngayHetHanGP) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            {/* {showLayKetQua && showCapSo  ?
                                        <Grid item xs={3} className="grid-item" >
                                                <Box className="button-group" marginTop="15px" >
                                                    <Button variant="outlined" disableElevation   onClick={
                                                        () => handleDongBoGiayPhep()
                                                        }>
                                                        Lấy kết quả
                                                    </Button>
                                                </Box>
                                        </Grid> :
                                        <Grid item xs={3} className="grid-item" >

                                        </Grid> 
                             }       */}

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel required>Tên tổ chức, cá nhân đề nghị </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.tenToChuc)}
                                    value={data?.tenToChuc} name="tenToChuc" onChange={handleChange} />
                                    {
                                    submit && StringUtil.isNullOrEmty(data?.tenToChuc) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên tổ chức, cá nhân đề nghị</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Giấy CNĐKKD/DN/HTX/HKD </InputLabel>
                                <InputBase fullWidth
                                    endAdornment={<InputAdornment position="end"> 
                                    <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '3px' }} 
                                    onClick={(e) => onSearchDKKD(data?.giayCNDK)}
                                    />
                                    </InputAdornment>}
                                    error={submit && StringUtil.isNullOrEmty(data?.giayCNDK)}
                                    value={data?.giayCNDK} name="giayCNDK" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.giayCNDK) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn giấy CNĐKKD/DN/HTX/HKD</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={9} className="grid-item" >
                                <InputLabel required>Tên thương nhân </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.tenThuongNhan)}
                                    value={data?.tenThuongNhan} name="tenThuongNhan" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.tenThuongNhan) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập tên thương nhân</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Địa chỉ trụ sở chính </InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Thành phố </InputLabel>
                                {/* <InputBase fullWidth readOnly
                                    value={data?.tenTinhThanh} name="tenTinhThanh" /> */}
                                <Autocomplete id="maTinhThanh"
                                    disableListWrap
                                    options={tinhs}
                                    value={{ code: data?.maTinhThanh ? data?.maTinhThanh : "", name: data?.tenTinhThanh ? data?.tenTinhThanh : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectTinhTruSo(newValue, 'maTinhThanh', 'tenTinhThanh')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maTinhThanh)}
                                                {...params.inputProps}  
                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.tenTinhThanh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Thành phố</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                {/* <InputBase fullWidth readOnly disabled
                                    value={data?.tenQuanTruSo} name="tenQuanTruSo" /> */}
                                <Autocomplete id="maHuyen"
                                    disableListWrap
                                    options={quans}
                                    value={{ code: data?.maQuanTruSo ? data?.maQuanTruSo : "", name: data?.tenQuanTruSo ? data?.tenQuanTruSo : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectQuanTruSo(newValue, 'maQuanTruSo', 'tenQuanTruSo')}
                                    onFocus={(e) => loadQuans(data.maTinhThanh)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maQuanTruSo)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maQuanTruSo) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={phuongTruSos}
                                    value={{ code: data?.maPhuongTruSo ? data?.maPhuongTruSo : "", name: data?.tenPhuongTruSo ? data?.tenPhuongTruSo : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectPhuong(newValue, 'maPhuongTruSo', 'tenPhuongTruSo')}
                                    onFocus={(e) => loadPhuongs(data.maQuanTruSo)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth

                                                error={submit && StringUtil.isNullOrEmty(data?.maPhuongTruSo)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.maPhuongTruSo) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Đường </InputLabel>
                                <InputBase fullWidth
                                    value={data?.tenDuongTruSo} name="tenDuongTruSo" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Số nhà, đường </InputLabel>
                                <InputBase fullWidth
                                    value={data?.soNhaTruSo} name="soNhaTruSo" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Điện thoại </InputLabel>
                                <InputBase fullWidth
                                    value={data?.dienThoaiTruSo} name="dienThoaiTruSo" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Fax </InputLabel>
                                <InputBase fullWidth
                                    value={data?.faxTruSo} name="faxTruSo" onChange={handleChange} />
                            </Grid>
                            
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel >Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true} 
                                        showTodayButton={true}
                                        value={data?.ngayCap && data?.ngayCap != 0 ? new Date(data.ngayCap) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCap')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Nơi cấp </InputLabel>
                                <InputBase fullWidth
                                    value={data?.noiCap} name="noiCap" onChange={handleChange} />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Thông tin công ty phân phối </InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <TablePhanPhoiRuouComponent data={data.phanPhoiRuous} onUpdateData={setPhanPhoiRuous} />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Thông tin địa điểm bán lẻ rượu </InputLabel>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <TableDiaDiemBLRuouComponent data={data.diaDiemBLRuous} onUpdateData={setDiaDiemBLRuou} />
                            </Grid>

                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={`${acceptFiles}`}
                                    attachFileServer={attachFileServer}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/ruou"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
        </>
    )
}
export default CapMoiBLRuouNoiDungComponent;
