import React, { Dispatch, useEffect, useState  } from 'react';
import { GIOI_TINH, HUYEN, LOAI_GIAY_TO, NOI_CAP, PHUONG, TINH_TP } from '../../../../../api/QuanTri/DanhMucFakeService';
import { ChuDauTuModel } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { Autocomplete } from '@material-ui/lab';
import {
    Box,
    Button,
    Dialog,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    TextField,
    InputAdornment,
    Snackbar,

} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import StringUtil from '../../../../utils/stringUtils';
import { DanhMucDiaChiDTO, DanhMucGioiTinhDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import SearchIcon from '@material-ui/icons/Search';
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { useDispatch } from 'react-redux';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import DateUtil from '../../../../utils/dateUtils';
import { useLocation } from 'react-router';
import { HoSoCuaToiMgtService } from '../../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';
import { UserData } from '../../../../../api/userMgt/userManagementService';

interface NhaORiengLeChuDauTuModalProps {
    data: ChuDauTuModel;
    show: boolean;
    indexEdit: number;
    onToggle: () => void;
    onAddData: (value: ChuDauTuModel) => void;
    onUpdateData: (index: number, value: ChuDauTuModel) => void;
}
const NhaORiengLeChuDauTuModal = (props: NhaORiengLeChuDauTuModalProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = React.useState({ loaiDoiTuong: 1 } as ChuDauTuModel);
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [indexEdit, setIndexEdit] = React.useState(-1);
    const { onToggle, onAddData, onUpdateData } = props;
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [tinhs, setTinhs] = useState<DanhMucDiaChiDTO[]>([]);
    const location = useLocation();
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [dataGioiTinh, setDataGioiTinh] = useState<DanhMucGioiTinhDTO[]>([]);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    let C_USER_DATA_DE = {} as UserData;
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    useEffect(() => {
        if (props.indexEdit >= 0) {
            setData(props.data);
        } else {
            setData(data => ({
                ...props.data,
                loaiDoiTuong: 1,
                ngayCap: 0,
                ngaySinh: 0,
                maTinh: "92",
                tenTinh: "Thành phố Cần Thơ",
            }));
        }
        setShow(props.show);
        setIndexEdit(props.indexEdit);
        setSubmit(false);
        loadTinhs();
        async function getDmGioiTinh() {
            await new DanhMucService().getDanhMucGioiTinh('').then((resp) => {
                setDataGioiTinh(resp);
            });
        }
        getDmGioiTinh();

        // if (indexEdit<0 && location?.state?.maHoSo && C_USER_DATA_DE){
        //     hoSoCuaToiMgtService.chiTiet(C_USER_DATA_DE.maCoQuan, location?.state?.maHoSo).then((p) => {
        //         if (p.data && p.data.data) {
                    
        //         }
        //     }).finally(() => {
        //     });
        // }
        
    }, [props.data, props.show, props.indexEdit]);


    const loadTinhs = async () => {
        await danhMucService
            .getAllTinh()
            .then((res) => {
                if (res && res.length > 0) {
                    setTinhs(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangeSelectQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData(data => ({
            ...data,
            [nameValue]: value?.code,
            [nameText]: valueText,
            ['maPhuong'] : '',
            ['tenPhuong'] : ''
        }));
    };
    const handleChangeSelectQuanTruSo = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: '',
            tenPhuong: '',
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectTinhTruSo = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, maTinh: '', tenHuyen: '', maPhuong: '', tenPhuong: '', [nameValue]: value?.code, [nameText]: valueText };
        setData(dataNew);
    }

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData(data => ({
            ...data,
            [nameValue]: value?.code,
            [nameText]: valueText,
        }));
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData(data => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        setData(data => ({
            ...data,
            [nameValue]: value?.ma,
            [nameText]: valueText
        }));
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {

        setData(data => ({
            ...data,
            [filedName]: date?.toDate()?.getTime(),
        }));

    }
    const handleSubmit = () => {
        setSubmit(true);
        if (validateFrom()) {
            if (indexEdit >= 0) {
                onUpdateData(indexEdit, data);
            } else {
                onAddData(data);
            }
            onToggle();
        }
    }

    const onSearchCMND = (cmnd: string) => {
        if (StringUtil.isNullOrEmty(cmnd)) {
            showMessageAlert("Số giấy tờ tùy thân không được trống", false);
            return;
        } else {
            dispatchLoading(showLoading());
            new DanhMucService().getBySoCMND({soCMND: cmnd}).then(res => {
            let dataNew = res?.data.data;
            if (dataNew !== null && dataNew !== undefined) {
                if(dataNew.error_code === 'SUCCESSFUL' && dataNew.data && dataNew.data != null) {
                
                    setData({
                        ...data,
                        ngayCap: dataNew.data.cmt_NgayCap,
                        hoTen: dataNew.data.hoVaTenCongDan,
                        ngaySinh: (dataNew.data.ngayThangNamSinh && !StringUtil.isNullOrEmty(dataNew.data.ngayThangNamSinh)) ? DateUtil.stringDDMMYYYtoDate(dataNew.data.ngayThangNamSinh) : dataNew.data.ngayThangNamSinh,
                        diaChiChiTiet:dataNew.data.diaChi,
                    });
                }else {
                    setAlertMessage({ open: true, content: "Số giấy tờ tùy thân không tồn tại trong dữ liệu", severity: 'warning' });
                }
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    };

    const loadPhuongs = async (maHuyen: string) => {
        if(StringUtil.isNotEmpty(data?.maHuyen)){
          await  danhMucService
          .getDanhMucDiaChi(data?.maHuyen)
          .then((res) => {
              if (res &&  res.length > 0) {
                  setPhuongs(res);
              }
          })
          .catch((error) => {
              console.log(error);
          });
      }  
  };

  async function handleChangeGioiTinh(value: any, nameValue: string, nameText: string) {
    let valueText = value?.code !== "" ? value?.name : "";
    // let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
    setData(data => ({
        ...data,
        [nameValue]: value?.code,
        [nameText]: valueText
    }));
}
const handleCloseAlertMessage = () => {
    setAlertMessage(alertMessage => ({
        ...alertMessage,
        open: false
    }));
}

  function validateFrom(): boolean {
        let valid: boolean = true;
        if (StringUtil.isNullOrEmty(data.hoTen)) {
            valid = false;
        } else if (StringUtil.isNullOrEmty(data.soGiayTo)) {
            valid = false;
        }else if(StringUtil.isNullOrEmty(data.maHuyen)){
            valid = false;
        }
        return valid;
    }
    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        {indexEdit >= 0 ? 'CẬP NHẬT CHỦ ĐẦU TƯ' : 'THÊM MỚI CHỦ ĐẦU TƯ'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Loại đối tượng </InputLabel>
                                    <InputBase fullWidth disabled
                                        value={'Cá nhân'} name="tenLoaDoiTuong" />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel required>Danh xưng</InputLabel>
                                    <InputBase fullWidth
                                        error={submit && StringUtil.isNullOrEmty(data.danhXung)}
                                        value={data.danhXung} name="danhXung" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} className="grid-item" >
                                    <InputLabel required>Họ tên</InputLabel>
                                    <InputBase fullWidth
                                        error={submit && StringUtil.isNullOrEmty(data.hoTen)}
                                        value={data.hoTen} name="hoTen" onChange={handleChange} />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.hoTen) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập họ tên</FormHelperText>
                                    }
                                </Grid>
                               
                                <Grid item xs={9} className="grid-item" >
                                    <InputLabel >Số nhà</InputLabel>
                                    <InputBase fullWidth
                                        value={data.diaChiChiTiet} name="diaChiChiTiet" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Giới tính </InputLabel>
                                    <Autocomplete id="gioiTinh"
                                        options={dataGioiTinh}
                                        value={{ code: data.maGioiTinh ? data.maGioiTinh : "", name: data.tenGioiTinh ? data.tenGioiTinh : "" }}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event: any, newValue: any) => handleChangeGioiTinh(newValue, 'maGioiTinh', 'tenGioiTinh')}
                                        renderInput={(params) => {
                                            return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                                <InputBase fullWidth
                                                    {...params.inputProps}

                                                />
                                            </div>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item" >
                                    <InputLabel >Đường </InputLabel>
                                    <InputBase fullWidth
                                        value={data.duong} name="duong" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} className="grid-item" >
                                    <InputLabel >Loại giấy tờ </InputLabel>
                                    <Autocomplete id="loaiGiayTo"
                                        options={LOAI_GIAY_TO}
                                        value={{ ma: data.loaiGiayTo, ten: data.tenLoaiGiayTo }}
                                        getOptionLabel={(option) => option.ten}
                                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'loaiGiayTo', 'tenLoaiGiayTo')}
                                        renderInput={(params) => {
                                            return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                                <InputBase fullWidth
                                                    {...params.inputProps}

                                                />
                                            </div>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item" >
                                <InputLabel>Thành phố </InputLabel>
                                {/* <InputBase fullWidth readOnly
                                        value={data?.tenTinhThanh} name="tenTinhThanh" /> */}
                                <Autocomplete id="maTinhThanh"
                                    disableListWrap
                                    options={tinhs}
                                    value={{ code: data?.maTinh ? data?.maTinh : "", name: data?.tenTinh ? data?.tenTinh : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectTinhTruSo(newValue, 'maTinh', 'tenTinh')}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maTinh)}
                                                {...params.inputProps}
                                            />
                                        </div>
                                    }}
                                />
                                {/* {
                                    submit && StringUtil.isNullOrEmty(data?.maTinh) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Thành phố</FormHelperText>
                                } */}
                                 </Grid>
                                <Grid item xs={6} className="grid-item" >
                                    <InputLabel required>CMND/CCCD </InputLabel>
                                    <InputBase fullWidth
                                        endAdornment={<InputAdornment position="end"> <SearchIcon fontSize="small" style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '3px' }} onClick={(e) => onSearchCMND(data.soGiayTo)} /></InputAdornment>}
                                        value={data.soGiayTo} name="soGiayTo"
                                        error={submit && StringUtil.isNullOrEmty(data.soGiayTo)}
                                        onChange={handleChange} />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.soGiayTo) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập CMND/CCCD</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={6} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                {/* <InputBase fullWidth readOnly disabled
                                    value={data?.tenQuanTruSo} name="tenQuanTruSo" /> */}
                                <Autocomplete id="maHuyen"
                                    disableListWrap
                                    options={quans}
                                    value={{ code: data?.maHuyen ? data?.maHuyen : "", name: data?.tenHuyen ? data?.tenHuyen : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectQuanTruSo(newValue, 'maHuyen', 'tenHuyen')}
                                    onFocus={(e) => loadQuans(data.maTinh)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                error={submit && StringUtil.isNullOrEmty(data?.maHuyen)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                
                                </Grid>
                                <Grid item xs={6} className="grid-item" >
                                    <InputLabel >Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCap && data.ngayCap !== 0 && data.ngayCap !== null && data.ngayCap !== undefined ? new Date(data.ngayCap) : null}
                                            format="DD/MM/YYYY"
                                            maxDate={new Date()}
                                            maxDateMessage={"Vui lòng nhập ngày cấp nhỏ hơn ngày hiện tại"}
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCap')}

                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={phuongs}
                                    value={{ code: data?.maPhuong ? data?.maPhuong : "", name: data?.tenPhuong ? data?.tenPhuong : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectPhuong(newValue, 'maPhuong', 'tenPhuong')}
                                    onFocus={(e) => loadPhuongs(data.maHuyen)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth

                                                error={submit && StringUtil.isNullOrEmty(data?.maPhuong)}
                                                {...params.inputProps}

                                            />
                                        </div>
                                    }}
                                />
                                 </Grid>
                                <Grid item xs={9} className="grid-item" >
                                    <InputLabel >Nơi cấp</InputLabel>
                                    <Autocomplete id="maNoiCap"
                                        options={NOI_CAP}
                                        value={{ ma: data.maNoiCap ? data.maNoiCap : "", ten: data.tenNoiCap ? data.tenNoiCap : "" }}
                                        getOptionLabel={(option) => option.ten}
                                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maNoiCap', 'tenNoiCap')}
                                        renderInput={(params) => {
                                            return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                                <InputBase fullWidth
                                                    {...params.inputProps}

                                                />
                                            </div>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item" >
                                    <InputLabel >Địa chỉ cũ</InputLabel>
                                    <InputBase fullWidth
                                        value={data.diaChiCu} name="diaChiCu"
                                        onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} className="grid-item" >
                                    <InputLabel >Ngày sinh</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            maxDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                                            maxDateMessage={"Vui lòng nhập ngày sinh đủ 15 tuổi"}
                                            value={data.ngaySinh && data.ngaySinh !== 0 && data.ngaySinh !== null && data.ngaySinh !== undefined ? new Date(data.ngaySinh) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngaySinh')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <Box marginTop="5px">
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={handleSubmit}>
                                            Lưu
                                    </Button>
                                        <Button variant="contained" color="default" disableElevation
                                            onClick={onToggle}>
                                            Hủy
                                    </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
                </Dialog>
            }
        </>
    )
}
export default NhaORiengLeChuDauTuModal;
