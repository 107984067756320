import { bootstrapHelperTextStyles, bootstrapInputStyles, bootstrapLabelLeftStyles, bootstrapLabelRightStyles, bootstrapLabelStyles, bootstrapTextareaStyles } from './bootstrapInputStyles';

export const bootstrapTextFieldHook = {
    useLabel: bootstrapLabelStyles,
    useInput: bootstrapInputStyles,
    useHelperText: bootstrapHelperTextStyles,
    labelLeft: bootstrapLabelLeftStyles,
    labelRight: bootstrapLabelRightStyles,
    //useTextarea : bootstrapTextareaStyles
};
