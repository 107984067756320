
import { HoSoCuaToiMgtService, ThongTinChuyenNganhDTO, ThongTinChuyenNganhDTO_INIT } from "../../api/HoSoCuaToi/HoSoCuaToiMgtService";
import { UserData } from "../../api/userMgt/userManagementService";
import { thongTinChuyenNganhConstants } from "../Constants/thongTinChuyenNganhConstants";
import StringUtil from "./stringUtils";
export class ThongTinChuyenNhanhUtil {

    mapToThongTinChuyenNganh = async (maHoSo : any): Promise<ThongTinChuyenNganhDTO> => {
        let C_USER_DATA_DE = {} as UserData;
        if (localStorage.getItem("C_USER_DATA") != null) {
            C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
        }
        let thongTinChuyenNganh = {...ThongTinChuyenNganhDTO_INIT} as ThongTinChuyenNganhDTO;
        if(!StringUtil.isNullOrEmty(maHoSo)){
            await new HoSoCuaToiMgtService().chiTiet(C_USER_DATA_DE.maCoQuan,maHoSo).then((p) => {
                if (p.data && p.data.data && p.data.data.thongTinChuyenNganh 
                    && p.data.data.thongTinChuyenNganh.length > 0) {
                    p.data.data.thongTinChuyenNganh.map((item : any, i : any) =>{
                        if(item?.data && item?.data.length > 0){
                            item?.data.map((chuyenNganh : any, j : any)=>{
                                 if(chuyenNganh?.giaTri != null && !StringUtil.isNullOrEmty(chuyenNganh?.giaTri)){
                                    if(chuyenNganh?.tenTruong === "phone"){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            phone : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NGAYTHANGHIENTAI){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            ngaythanghientai : chuyenNganh?.giaTri 
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.CMND){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            cmnd : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NGAYCAPCMND){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            ngaycapcmnd : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NOICAPCMND){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            noicapcmnd : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.HOKHAUTHUONGTRU){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            hokhauthuongtru : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NOIOHIENNAY){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            noiohiennay : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.HOVATEN){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            hovaten : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.HOVATENVIETHOA){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            hovatenviethoa : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NGAYTHANGSINH){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            ngaythangsinh : chuyenNganh?.giaTri 
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NGAYHIENTAI){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            ngayhientai : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.THANGHIENTAI){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            thanghientai : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NAMHIENTAI){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            namhientai : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.QUOCTICH){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            quoctich : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.TINHTHANH){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            tinhthanh : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.QUANHUYEN){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            quanhuyen : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.XAPHUONG){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            xaphuong : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.SDT){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            sdt : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.FAX){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            fax : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.EMAIL){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            email : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.GIOITINH){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            gioitinh : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.DIACHI){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            diachi : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.KINHGUI){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            kinhgui : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.TENDOANHNGHIEP){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            tendoanhnghiep : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.DIACHIDOANHNGHIEP){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            diachidoanhnghiep : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.WEBSITE){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            website : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.GIAYDKKD){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            giaydkkd : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.COQUANCAPDKKD){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            coquancapdkkd : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NGAYCAPDKKD){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            ngaycapdkkd :  chuyenNganh?.giaTri 
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.NOICAPDKKD){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            noicapdkkd : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.HOTENNGUOIDAIDIEN){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            hotennguoidaidien : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.CHUCVUNGUOIDAIDIEN){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            chucvunguoidaidien : chuyenNganh?.giaTri
                                        }
                                    }else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.DIADIEM){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            diadiem : chuyenNganh?.giaTri
                                        }
                                    }
                                    else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.SOGIAYTODAIDIEN){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            soGiayToDaiDien : chuyenNganh?.giaTri
                                        }
                                    }
                                    else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.TEXT2){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            text2 : chuyenNganh?.giaTri
                                        }
                                    }    
                                    else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.TENDOANHNGHIEPVIETHOA){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            tendoanhnghiepviethoa : chuyenNganh?.giaTri
                                        }
                                    }  
                                    else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.TEXT21){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            text21 : chuyenNganh?.giaTri
                                        }
                                    }  

                                    else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.TEXT22){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            text22 : chuyenNganh?.giaTri
                                        }
                                    }  
                                    else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.PHUONGXA){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            phuongxa : chuyenNganh?.giaTri
                                        }
                                    }
                                    else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.HOVATENVIETHOA1){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            hovatenviethoa1 : chuyenNganh?.giaTri
                                        }
                                    }
                                    else if (chuyenNganh?.tenTruong === thongTinChuyenNganhConstants.TEXT12){
                                        thongTinChuyenNganh = {
                                            ...thongTinChuyenNganh,
                                            text12 : chuyenNganh?.giaTri
                                        }
                                    }
                                 }
                            })
                           
                        }
                        
                    })  
                    
                }
            }).finally(() => {
            }); 
        }
        return thongTinChuyenNganh;
    };

    getTenThuongNhan(listThanhVien : Array<any>): string { 
        let tenThuongNhan = "";
        if(listThanhVien && listThanhVien.length > 0){
            listThanhVien.map((item : any, i : any) =>{
                if(item?.isNguoiDaiDienPhapLuat === "1"){
                    tenThuongNhan = item?.hoTen;
                }
            })

        }
        return tenThuongNhan;

    };
}
