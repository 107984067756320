import {
    Button,
    createStyles,
    Grid,
    InputBase,
    InputLabel,
    Link,
    makeStyles,
    Snackbar,
    SvgIcon,
    Theme,
    TextField
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { PageRequest } from "../../../../api/baseService";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import { DanhSachGPKDTLComponent } from "./DanhSachGPKDTLComponent";
import { QuanLyKDTLService } from "../../../../api/CPBLTL/QuanLyKDTLService";
import { useHistory } from "react-router-dom";
import { ROUTE_CPTL_DAUKY } from "../../../../routes/route-cn-tl";
import { DiaChiDonViHanhChinh } from "../../DanhMuc/DVHC3Cap/donViHanhChinh3Cap";
import { DanhSachGiayPhepBLThuocLaDTO } from "../../../../api/CPBLTL/model/DanhSachGiayPhepBLThuocLaDTO";
import { TINH_TRANG_TKTL } from "../../../../api/QuanTri/DanhMucFakeService";
import { Autocomplete } from "@material-ui/lab";
import { getObject } from "../../../../helpers/localStorage";
import { DanhMucDiaChiDTO } from "../../../../api/danhMuc/model/DanhMucDTO";
import { DanhMucService } from "../../../../api/danhMuc/danhMucService";
import StringUtil from "../../../utils/stringUtils";

export interface RequestBaoCaoThongKeDTO extends PageRequest {
    soGiayPhep: string;
    maQuanHuyen: string;
    maTinhTrang: string;
    tenTinhTrang: string;
    soDienThoai: string;
    maPhuongXa: string;
    tenQuanHuyen: string;
    tenPhuongXa: string;
    tenThongke: string;
    tuNgay: number;
    denNgay: number;
    keywords: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px"
        },
    }),
);

export const TraCuuKDTLComponent = () => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [dataList, setDataList] = useState<DanhSachGiayPhepBLThuocLaDTO[]>([]);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const history = useHistory();
    const [phuongTruSos, setPhuongTruSos] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    useEffect(() => {
        async function getDm() {
            await danhMucService.getDanhMucDiaChi(String(92)).then((resp) => {
                setQuans(resp);
            });
        }
        getDm();
    }, []);
    const formik = useFormik({
        initialValues: {
            request: {
                soGiayPhep: '',
                maPhuongXa: '',
                maQuanHuyen: C_USER_DATA.maQuanHuyen,
                maTinhTrang: '',
                tenTinhTrang: '',
                soDienThoai: '',
                tenPhuongXa: 'Chọn phường xã',
                tenQuanHuyen: C_USER_DATA.tenQuanHuyen,
                keywords: ''
            } as RequestBaoCaoThongKeDTO,
        },
        onSubmit: () => loadDataTable(),
    });

    useEffect(() => {
        loadDataTable();
    }, [page, size])


    const loadDataTable = async () => {
        if (formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay) {
            showMessageAlert('Từ ngày không được lớn hơn đến này', false);
            return;
        }
        let request = {
            ...formik.values.request,
            pageSize: size,
            pageNumber: page,
        } as RequestBaoCaoThongKeDTO;
        dispatchLoading(showLoading());
        await new QuanLyKDTLService().loadDanhSach(request).then((p) => {
            setDataList(p.rows);
            setTotal(p.total);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'warning' });
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1);
        setSize(Number(event.target.value));
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    const downloadFile = async (extension: string) => {
        let request = {
            ...formik.values.request,
            exportType: extension,
            exportFileName: 'Danh_sach_giay_phep',
            tenThongke: 'QUẢN LÝ KINH DOANH THUỐC LÁ'
        };
        dispatchLoading(showLoading());
        await new QuanLyKDTLService().downloadFileDanhSach(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'Danh_sach_giay_phep');
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const handleNhapDauKy = () => {
        let duongDan = ROUTE_CPTL_DAUKY.duongDan;
        history.push(duongDan);
    }
    const loadPhuongs = (maQuan: string) => {
        if (!StringUtil.isEmty(maQuan)) {
            danhMucService.getDanhMucDiaChi(maQuan).then(res => {
                setPhuongTruSos(res);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>QUẢN LÝ KINH DOANH THUỐC LÁ</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container direction="row">
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Số giấy phép</InputLabel>
                        <InputBase fullWidth name="request.soGiayPhep"
                            value={formik.values.request.soGiayPhep} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Tên thương nhân</InputLabel>
                        <InputBase fullWidth name="request.keywords"
                            value={formik.values.request.keywords} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Tình trạng</InputLabel>
                        <Autocomplete options={TINH_TRANG_TKTL}
                            value={{
                                ma: formik.values?.request?.maTinhTrang ? formik.values.request?.maTinhTrang : "",
                                ten: formik.values?.request?.tenTinhTrang ? formik.values?.request?.tenTinhTrang : ""
                            }}
                            getOptionLabel={(option) => option?.ten}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maTinhTrang", newValue?.ma);
                                formik.setFieldValue("request.tenTinhTrang", newValue?.ten);
                            }}
                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <TextField  {...params} variant="outlined" />
                                </div>
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Số điện thoại</InputLabel>
                        <InputBase fullWidth name="request.soDienThoai"
                            value={formik.values.request.soDienThoai} onChange={formik.handleChange}
                        />
                    </Grid>
                    {/* <DiaChiDonViHanhChinh formik={formik} diaChiOf={`request`} disabled={true} required={false}/> */}
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Huyện/Thị/TP</InputLabel>
                        {
                            StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                            <Autocomplete id="maQuanHuyen" options={quans}
                                value={{ code: formik.values?.request?.maQuanHuyen ? formik.values.request?.maQuanHuyen : "", name: formik.values?.request?.tenQuanHuyen ? formik.values.request.tenQuanHuyen : "" }}
                                getOptionLabel={(option) => option?.name}
                                onChange={(event: any, newValue: any) => {
                                    formik.setFieldValue("request.maPhuongXa", "");
                                    formik.setFieldValue("request.tenPhuongXa", "");
                                    formik.setFieldValue("request.maQuanHuyen", newValue?.code);
                                    formik.setFieldValue("request.tenQuanHuyen", newValue?.name);
                                }}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth {...params.inputProps} />
                                    </div>
                                }}
                            />
                        }
                        {
                            !StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                            <InputBase disabled fullWidth value={formik.values.request.tenQuanHuyen} />
                        }
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Phường/Xã/TT</InputLabel>
                        <Autocomplete options={phuongTruSos}
                            value={{ code: formik.values?.request?.maPhuongXa ? formik.values.request?.maPhuongXa : "", name: formik.values?.request?.tenPhuongXa ? formik.values.request.tenPhuongXa : "" }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maPhuongXa", newValue?.code);
                                formik.setFieldValue("request.tenPhuongXa", newValue?.name);
                            }}
                            onFocus={(e) => loadPhuongs(formik.values.request.maQuanHuyen)}
                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <TextField  {...params} variant="outlined" />
                                </div>
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Từ ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request.tuNgay > 0 ? new Date(formik.values.request.tuNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.tuNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Đến ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request?.denNgay > 0 ? new Date(formik.values.request?.denNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.denNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} className="grid-item" container justify="flex-end">
                        <Button onClick={loadDataTable} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tìm kiếm
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="grid-item" container justify="flex-start">
                        <Button onClick={(e) => handleNhapDauKy()} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Nhập đầu kỳ
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('docx');
                        }}>
                            <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('pdf');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                            </SvgIcon>
                        </Link>
                        <Link title="Tải xuống" href="#" onClick={() => {
                            downloadFile('xlsx');
                        }}>
                            <SvgIcon fontSize="large">
                                <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                            </SvgIcon>
                        </Link>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <DanhSachGPKDTLComponent dataList={dataList}
                            pageProps={{
                                page: page, count: total, rowsPerPage: size,
                                onChangePage: handleOnChangePage,
                                onChangeRowsPerPage: handOnChangeRowsPerPage
                            }}
                        />
                    </Grid>
                </Grid>
            </form>
            <OverlayLoading />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div >
    )
}

