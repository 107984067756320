import { Box, Checkbox, FormHelperText, Grid, InputBase, InputLabel, MenuItem, NativeSelect, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DanhMucGP, DanhMucGP_INIT } from '../../../../../api/Common/model/CommonModel';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { DanhMucCoQuanDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { LinhVucCapSoGPService } from '../../../../../api/DKKD/LinhVucCapSoGPService';
import { UserData } from '../../../../../api/userMgt/userManagementService';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import { LIST_CN } from '../../../../Constants/constants';
import StringUtil from '../../../../utils/stringUtils';

interface IModalProps {
    onSubmitData: (content: string, isSuccess: boolean) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: DanhMucGP;
}

type ErrorValidation = {
    name: string;
}

export const EditDialog = (props: IModalProps) => {

    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [openDialog, setOpenDialog] = useState(false);
    const [itemEditDTO, setItemEditDTO] = useState<DanhMucGP>({} as DanhMucGP);
    const [coQuans, setCoQuans] = useState<DanhMucCoQuanDTO[]>([]);
    const service = new LinhVucCapSoGPService();
    const danhMucService = new DanhMucService();
    const [showCN, setShowCN] = useState(false);
    let C_USER_DATA_DE = {} as UserData;
    const [submit, setSubmit] = useState(false);
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    useEffect(() => {
        setItemEditDTO(props.itemEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    useEffect(() => {
        loadCoQuan();
    }, []);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setItemEditDTO({
            ...itemEditDTO,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        })
    }
    const loadCoQuan = () => {
        danhMucService.findByToken().then((p) => {
            setCoQuans(p);
        }).catch((e) => {
            console.log(e);
        });
    };

    const handleChangeSelect = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        setItemEditDTO(itemEditDTO => ({
            ...itemEditDTO,
            [nameValue]: value?.ma,
            [nameText]: valueText,
        }));
    };

    const submitData = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(itemEditDTO.chuyenNganh)) {
            return;
        }
        if (StringUtil.isNullOrEmty(itemEditDTO.maCoQuan)) {
            return;
        }
        if (itemEditDTO.soGP == 0) {
            return;
        }
        let soGPDayDu = itemEditDTO.dauSo;
        if (itemEditDTO.showChuyenNganh) {
            soGPDayDu += itemEditDTO.chuyenNganh;
        }
        soGPDayDu += itemEditDTO.soGP;
        dispatchLoading(showLoading());

        service.addOrUpdate({ ...itemEditDTO, soGPDayDu: soGPDayDu }).then(res => {

            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                props.onSubmitData("Yêu cầu thực hiện thành công", true);
            } else {
                props.onSubmitData(res.data.error_message, false);
            }
        }).catch(error => {
            props.onSubmitData('Yêu cầu thực hiện thất bại', false);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });

    }

    const onResetData = () => {
        setItemEditDTO({
            ...DanhMucGP_INIT,
            id: itemEditDTO.id
        })
    }

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {!itemEditDTO.id ? 'Thêm mới' : 'Cập nhật'}
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid spacing={2} container>
                            <Grid item xs={12} className="gridItem">
                                <InputLabel required>Chọn cơ quan</InputLabel>
                                <Autocomplete
                                    id="maCoQuan"
                                    options={coQuans}
                                    getOptionLabel={(option) => option?.ten}
                                    value={{ ma: itemEditDTO.maCoQuan, ten: itemEditDTO.tenCoQuan }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelect(newValue, "maCoQuan", "tenCoQuan");
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined"

                                    />}

                                />
                                {
                                    submit && StringUtil.isNullOrEmty(itemEditDTO.maCoQuan) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn cơ quan</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={12} className="gridItem">
                                <InputLabel >Chuyên ngành</InputLabel>
                                <NativeSelect input={<InputBase fullWidth />}
                                    value={itemEditDTO.chuyenNganh}
                                    onChange={(e) =>
                                        setItemEditDTO({
                                            ...itemEditDTO,
                                            chuyenNganh: e.target.value,
                                        })}>
                                    {LIST_CN.map((item, index) => {
                                        return (
                                            <option key={`${index}`} value={item.value}>
                                                {item.ten}
                                            </option>
                                        );
                                    })}

                                </NativeSelect>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Đầu số</InputLabel>
                                <InputBase fullWidth
                                    name="dauSo"
                                    value={itemEditDTO.dauSo}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'description' }} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel required>Số giấy phép</InputLabel>
                                <InputBase fullWidth
                                    name="soGP"
                                    value={itemEditDTO.soGP}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'description' }} />
                                {submit && StringUtil.isNullOrEmty(itemEditDTO.soGP) && (
                                    <FormHelperText className="-error">Vui lòng nhập số giấy phép</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Số giấy phép đầy đủ</InputLabel>
                                <InputBase fullWidth disabled
                                    value={itemEditDTO.showChuyenNganh ? (itemEditDTO.dauSo + itemEditDTO.chuyenNganh + itemEditDTO.soGP ) : (itemEditDTO.dauSo  + itemEditDTO.soGP )}
                                    inputProps={{ 'aria-label': 'description' }} />
                            </Grid>
                            <Grid item xs={12} className="gridItem" style={{ paddingLeft: 5 }}>
                                <Checkbox name="showChuyenNganh" checked={itemEditDTO.showChuyenNganh} color="default" size="small"
                                    value={itemEditDTO.showChuyenNganh} onChange={handleChange} /> Hiển thị mã chuyên ngành
                            </Grid>
                            <Grid item xs={12} className="gridItem">
                                <Box marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={() => { submitData() }}>
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={onResetData} disableElevation >
                                        Xóa
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation
                                        onClick={props.onDialogClose}>
                                        Hủy
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}