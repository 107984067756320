import {Box, InputLabel, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, {useEffect, useState} from 'react';
import {Cell, Row} from "../../../commons/components/table/tableCommons";
import {CustomInput} from "../../../commons/components/input/customInput";
import moment from "moment";
import {ThanhVienGopVonDTO} from "../../../../api/DKKD/model/thanhVienGopVonDTO";
import { NhomCaNhanDTO } from '../../../../api/DKKD/model/hoSoThuLyDTO';
import StringUtil from '../../../utils/stringUtils';

interface IDataListProps {
    dataList: NhomCaNhanDTO[];
}

export const ThanhVienGopVon = (props: IDataListProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);
    const [dataTepms, setDataTepms] = useState<NhomCaNhanDTO[]>([]);
    useEffect(() => {
        setDataTepms(props.dataList);
        setTotal(props.dataList?.length);
        handleSearch({page : 1, size : size});
    }, [props.dataList, total, size]);

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        handleSearch({page : value, size : size});
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1)
        setSize(Number(event.target.value));
        handleSearch({page : 1, size : Number(event.target.value)});
    }

    const handleSearch = (search : any) => {
        setRows(dataTepms?.slice((search.page - 1) * search.size, search.page * search.size));
    }

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Họ và tên</Cell>
                        {/* <Cell component="th" scope="row" align="center" width="5%">Giới tính</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Ngày sinh</Cell>
                        <Cell component="th" scope="row" align="center" width="5%">Dân tộc</Cell>
                        <Cell component="th" scope="row" align="center" width="5%">Quốc tịch</Cell> 
                        <Cell component="th" scope="row" align="center" width="14%">Số, ngày, cơ quan cấp CMND/CCCD/Hộ chiếu</Cell>*/}
                        <Cell component="th" scope="row" align="center" width="14%">Số CMND (hoặc chứng thực cá nhân hợp pháp khác)</Cell>
                        <Cell component="th" scope="row" align="center" width="8%">Chỗ ở hiện tại</Cell>
                        <Cell component="th" scope="row" align="center" width="8%">Nơi đăng ký hộ khẩu thường trú</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Giá trị phần vốn góp (VNĐ)</Cell>
                        <Cell component="th" scope="row" align="center" width="5%">Tỷ lệ (%)</Cell>
                        <Cell component="th" scope="row" align="center" width="5%">Ghi chú</Cell>
                        {/* <Cell component="th" scope="row" align="center" width="10%">Loại tài sản, số lượng, giá trị tài sản góp vốn</Cell>
                        <Cell component="th" scope="row" align="center" width="5%">Thời điểm góp vốn</Cell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {(page - 1) * size + i + 1}
                                    </Cell>
                                    <Cell align="left">{row.tenThanhVien}</Cell>
                                    {/* <Cell align="center">{row.gioiTinh == 1 ? 'Nam' : (row.gioiTinh == 2 ? 'Nữ' : 'Khác')}</Cell>
                                    <Cell align="center">{(row.ngaySinh !== 0 && row.ngaySinh !== null)  ? moment(row.ngaySinh).format("DD/MM/YYYY") : ''}</Cell>
                                    <Cell align="center">{row.tenDanToc}</Cell>
                                    <Cell align="left">{row.tenQuocTich}</Cell>
                                    <Cell align="left">
                                        {row.soGiayToTuyThan ? <InputLabel >Số: </InputLabel> : ''}{row.soGiayToTuyThan}
                                        {row.ngayCap !== 0 && row.ngayCap !== null ? <InputLabel >Ngày cấp: </InputLabel> : ''}{(row.ngayCap !== 0 && row.ngayCap !== null)  ? moment(row.ngayCap).format("DD/MM/YYYY") : ''}
                                        {row.noiCap ? <InputLabel >Nơi cấp: </InputLabel> : ''}{row.noiCap}
                                    </Cell> */}
                                    <Cell align="left">{row.soCMND}</Cell>
                                    <Cell align="left">{row.choOHienTai}</Cell>
                                    <Cell align="left">{row.noiDangKyHK}</Cell>
                                    <Cell align="left">{StringUtil.formatForDot(row.giaTriGopVon)}</Cell>
                                    <Cell align="center">{row.tyLe}</Cell>
                                    <Cell align="center">{row.ghiChu}</Cell>
                                    {/* <Cell align="left">{row.tenTaiSan}</Cell>
                                    <Cell align="center">{row.thoiDiemGopVon}</Cell> */}
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={13} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            {rows?.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={Math.ceil(total / size)} defaultPage={1} siblingCount={1} variant="outlined"
                                size="medium"
                                color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {(page * size) + 1} - {(page * size) + size} / {total}</Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
