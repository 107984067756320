import React, {useEffect} from 'react';
import {Grid, InputLabel} from '@material-ui/core';
import moment from "moment";
import { GiayPhepATTP, HoSoThuLyATTPDTO } from '../../../../../api/VSATTP/Model/VSATTPModel';

interface ThongTinGPComponentComponentProps {
    data: GiayPhepATTP;
}

const ThongTinChungGPComponent = (props: ThongTinGPComponentComponentProps) => {
    const [data, setData] = React.useState({} as GiayPhepATTP);

    useEffect(() => {
        setData(props.data);
        
    }, [props.data]);

    return (
        <>
            {
                <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Số giấy chứng nhận</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.soGiayPhep}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.ngayCapPhep ? moment(data?.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Tên cơ sở</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.tenCoSo}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Chủ cơ sở</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.chuCoSo}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Địa chỉ</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel>{data?.diaChiDayDu}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Điện thoại</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.dienThoai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Fax</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.fax}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Số Giấy CNĐKDN/HKD</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.maSoGiayCNDKKDHKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.ngayCapCNDKKDHKD ? moment(data?.hoSoThuLyATTPDTO?.ngayCapCNDKKDHKD).format("DD/MM/YYYY") : ''}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Nơi cấp</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.noiCapCNDKKDHKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Điểm sản xuất kinh doanh</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.tenPhuongXaTTDSXKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Điện thoại điểm SXKD</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.dienThoaiDSXKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Fax tại điểm SXKD</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.faxDSXKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Quy mô kinh doanh dự kiến (tổng số suất ăn/lần phục vụ)</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.quyMoKinhDoanhDSXKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Số lượng người lao động</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.soLuongNguoiLaoDongDSXKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Số lượng người lao động trực tiếp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.soLuongLaoDongTrucTiepDSXKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Số lượng người lao động gián tiếp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.soLuongLaoDongGianTiepDSXKD}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Đủ điều kiện an toàn thực phẩm</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel>{data?.hoSoThuLyATTPDTO?.duDieuKienAnToanThucPham}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel >Tình trạng</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel>{data?.tenTrangThai}</InputLabel>
                </Grid>
            </Grid>
            }
        </>
    )
};
export default ThongTinChungGPComponent;
