import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Dispatch } from 'redux';
import { BussinessTemplateDownloadRequest, HoSoKhenThuongService } from '../../../../api/KhenThuong/chuyenNganh/hoSoKhenThuongService';
import { DanhMucBieuMauService } from '../../../../api/QuanTri/danhMucBieuMauService';
import { BieuMauAttachFileDTO } from '../../../../api/QuanTri/Model/danhMucBieuMau';
import { useQueryBussinessPath } from '../../../../hooks/useQuery';
import { hideLoading, LoadingAction, showLoading } from '../../../../redux/actions/applicationAction';
import { AttachIcon } from '../../../commons/components/AttachFileTooltip/AttachFileTooltip';


export interface InBieuMauButtonPropsI {
    idChuyenNganh: number;
    idHoSoKhenThuong: number;
}

export const InBieuMauButton = (props: InBieuMauButtonPropsI) => {
    const { idChuyenNganh, idHoSoKhenThuong } = props;
    const bussinessPath = useQueryBussinessPath();
    const [openInAn, setOpenInAn] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [bieuMauAttachFileDTOs, setBieuMauAttachFileDTOs] = useState<BieuMauAttachFileDTO[]>([]);
    const danhMucBieuMauService = new DanhMucBieuMauService();
    const handleCloseProper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenInAn(false);
    };
    const location = useLocation();
    async function handleOpenInAn() {
        dispatchLoading(showLoading());
        danhMucBieuMauService.findDanhSachBieuMauByPath(location.pathname).then(response => {
            if (response != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    setBieuMauAttachFileDTOs(response.data.data);
                }
            }
        }).catch(error => {

        }).finally(() => {
            dispatchLoading(hideLoading());
            setOpenInAn(!openInAn);
        });
    };

    async function handleDownloadTemplate(fileId: String, fileName: String) {
        let request = {
            idChuyenNganh: idChuyenNganh,
            idHoSoKhenThuong: idHoSoKhenThuong,
            filedId: fileId
        } as BussinessTemplateDownloadRequest;
        dispatchLoading(showLoading());
        await new HoSoKhenThuongService().handleDownloadTemplateFile(request).then((res) => {
            console.log('res ',res);
            
            let blob = new Blob([res.data], { type: res.data.type });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", String(fileName));
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    return (
        <>
            <Button variant="contained" color="primary" disableElevation
                ref={anchorRef} aria-haspopup="true"
                aria-controls={openInAn ? 'danh-sach-file-template' : undefined} onClick={handleOpenInAn}
            > In ấn
            </Button>
            <Popper open={openInAn} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow  {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseProper}>
                                <MenuList id="danh-sach-file-template" >
                                    {
                                        bieuMauAttachFileDTOs && bieuMauAttachFileDTOs.map((item, k) =>
                                            <MenuItem style={{ padding: 'unset' }} key={k}>
                                                <Button size="small" disableRipple
                                                    fullWidth
                                                    startIcon={<AttachIcon fileName={String(item.fileName)} />}
                                                    endIcon={<GetAppIcon color="primary" fontSize="small" />}
                                                    onClick={() => handleDownloadTemplate(item.fileId, item.fileName)}
                                                >
                                                    {
                                                        item?.screenName ?
                                                            <Box component="span" flexGrow={1} textAlign="left" >{
                                                                item?.screenName.length > 30 ? item.screenName.slice(0, 33) + "..." + item.screenName.slice(item.screenName.length - 10) : item.screenName}
                                                            </Box>
                                                            :
                                                            <Box component="span" flexGrow={1} textAlign="left" >{
                                                                item.fileName.length > 30 ? item.fileName.slice(0, 33) + "..." + item.fileName.slice(item.fileName.length - 10) : item.fileName}
                                                            </Box>
                                                    }
                                                </Button>
                                            </MenuItem>
                                        )
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>


    )
}