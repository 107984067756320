export const SO_GIAY_CNĐKKD_HKD = [
    {
        ten: 'Số Giấy CNĐKKD/HKD loại 1',
        ma: 'CNĐKKD1'
    },
    {
        ten: 'Số Giấy CNĐKKD/HKD loại 2',
        ma: 'CNĐKKD2'
    }
]
export const CO_QUAN = [
    {
        ten: 'Chọn cơ quan',
        ma: ''
    },
    {
        ten: 'Sở Thông tin và Truyền thông',
        ma: '000.00.13.H13'
    }
]
export const TINH_TP = [
    {
        ten: 'Chọn tỉnh thành',
        ma: ''
    },
    {
        ten: 'Thành phố Cần Thơ',
        ma: 'CT'
    },
    {
        ten: 'Hồ Chí Minh',
        ma: 'TPHCM'
    },
    {
        ten: 'Hà Nội',
        ma: 'HN'
    }

]
export const HUYEN = [
    {
        ten: 'Quận Ninh Kiều',
        ma: 'NK'
    },
    {
        ten: 'Quận Cái Răng',
        ma: 'CR'
    },
    {
        ten: 'Quận Thốt Nốt',
        ma: 'TN'
    },
    {
        ten: 'Quận Ô Môn',
        ma: 'OM'
    }

]
export const PHUONG = [
    {
        ten: 'Phường An Bình',
        ma: 'AB'
    },
    {
        ten: 'Phường An Cư',
        ma: 'AC'
    }

]
export const GIOI_TINH = [
    {
        ten: 'Nam',
        ma: 1
    },
    {
        ten: 'Nữ',
        ma: 2
    }
]
export const LOAI_GIAY_TO = [
    {
        ten: 'Chứng minh nhân dân',
        ma: 1
    },
    {
        ten: 'Căn cước công dân',
        ma: 2
    },
    {
        ten: 'Hộ chiếu',
        ma: 3
    }
]

export const CHU_THE_HKD = [
    {
        ten: 'Cá nhân',
        ma: 1
    },
    {
        ten: 'Thành viên hộ gia đình',
        ma: 2
    },
    
]

export const NOI_CAP = [
    {
        ten: 'Hồ Chí Minh',
        ma: 'HCM'
    },
    {
        ten: 'Cần thơ',
        ma: 'CT'
    }
]
export const DAN_TOC = [
    {
        ten: 'Kinh',
        ma: 'KK'
    },
    {
        ten: 'Mường',
        ma: 'MM'
    },
    {
        ten: 'Thái',
        ma: 'TT'
    }

]
export const QUOC_TICH = [
    {
        ten: 'Việt Nam',
        ma: 'VN'
    },
    {
        ten: 'Mỹ',
        ma: 'US'
    },
    {
        ten: 'TRUNG QUỐC',
        ma: 'CN'
    }

]
export const LOAI_TAI_SAN = [
    {
        ten: 'Đồng Việt Nam',
        ma: '01'
    },
    {
        ten: 'Ngoại tệ tự do chuyển đổi',
        ma: '02'
    },
    {
        ten: 'Vàng',
        ma: '03'
    },
    {
        ten: 'Giá trị quyền sử dụng đất, giá trị quyền sở hữu trí tuệ, công nghệ, bí quyết kỹ thuật',
        ma: '04'
    },
    {
        ten: 'Tài sản khác',
        ma: '05'
    }
]

export const TINH_TRANG = [
    {
        ten: 'Chọn tình trạng',
        ma: ''
    },
    {
        ten: 'Đang hoạt động',
        ma: 'HD'
    },
    {
        ten: 'Ngưng hoạt động',
        ma: 'NG'
    },
    {
        ten: 'Thu hồi',
        ma: 'TH'
    },
    {
        ten: 'Hủy',
        ma: 'HH'
    },
    {
        ten: 'Tạm ngưng hoạt động',
        ma: 'TN'
    },
    {
        ten: 'Chấm dứt',
        ma: 'CD'
    }
]

export const TINH_TRANG_TKTL = [
    {
        ten: 'Chọn tình trạng',
        ma: ''
    },
    {
        ten: 'Đang hoạt động',
        ma: 'HD'
    },
    {
        ten: 'Thu hồi',
        ma: 'TH'
    },
    {
        ten: 'Hủy',
        ma: 'HH'
    }
]

export const LOAI_BAO_CAO_TKTL = [
    {
        ten: 'Chọn tình trạng',
        ma: ''
    },
    {
        ten: 'Cấp mới',
        ma: 'CM'
    },
    {
        ten: 'Cấp đổi',
        ma: 'CAP_DOI'
    },
    {
        ten: 'Cấp lại',
        ma: 'CL'
    },
    {
        ten: 'Thu hồi',
        ma: 'TH'
    },
    {
        ten: 'Hủy',
        ma: 'HH'
    }
]

export const LOAI_BAO_CAO_DKKD = [
    {
        ten: 'Chọn tình trạng',
        ma: ''
    },
    {
        ten: 'Cấp mới',
        ma: 'CM'
    },
    {
        ten: 'Cấp đổi',
        ma: 'DC'
    },
    {
        ten: 'Cấp lại',
        ma: 'CL'
    },
    {
        ten: 'Tạm ngưng',
        ma: 'TN'
    },
    {
        ten: 'Chấm dứt',
        ma: 'CD'
    },
]

export const TINH_TRANG_DKKD = [
    {
        ten: 'Chọn tình trạng',
        ma: ''
    },
    {
        ten: 'Cấp mới',
        ma: 'CM'
    },
    {
        ten: 'Cấp đổi',
        ma: 'DC'
    },
    {
        ten: 'Cấp lại',
        ma: 'CL'
    },
    {
        ten: 'Tạm ngưng',
        ma: 'TN'
    },
    {
        ten: 'Chấm dứt',
        ma: 'CD'
    },
]
