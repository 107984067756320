import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from '@material-ui/core';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DongBoService from '../../../api/QuanTri/DongBoService';
import { DongBoRequest } from '../../../api/QuanTri/Model/DongBoModel';
import { AlertMessage, CustomAlert } from '../../../App/commons/components/alert';
import { ConfirmationDialogRaw } from '../../../App/commons/components/dialog/confirmDialog';
import { OverlayLoading } from '../../../App/commons/components/overlayLoading/overlayLoading';
import { hideLoading, LoadingAction, showLoading } from '../../../redux/actions/applicationAction';

export const DongBoDuLieuComponent = () => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<DongBoRequest>({ loaiDuLieu: 'DonViHanhChinh' });
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const service = new DongBoService();
    useEffect(() => {
    }, []);

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }))
    }

    const dongBo = () => {
        setOpenConfirm(true);
    }
    const handleDongBo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            dispatch(showLoading());
            service.dongBoDanhMucDungChung(data).then((p) => {
                if (p.data) {
                    showMessageAlert('Đồng bộ thành công', true);
                } else {
                    showMessageAlert('Đồng bộ thất bại', false);
                }
            }).catch(e => {
                showMessageAlert(e, false);
            }).finally(() => {
                dispatch(hideLoading());
            });
        }
        setOpenConfirm(false);
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Đồng bộ dữ liệu</span>
            </div>
            <Grid container spacing={2} direction="row" alignItems="center">
                <OverlayLoading />
                <Grid item xs={6} className="gridItem">
                    <InputLabel >Loại dữ liệu</InputLabel>
                    <NativeSelect input={<InputBase fullWidth />}
                        value={data.loaiDuLieu} onChange={(e) => setData({ ...data, loaiDuLieu: e.target.value })} >
                        <option value={'DonViHanhChinh'}>Đơn vị hành chính</option>
                        <option value={'DanToc'}>Dân tộc</option>
                        <option value={'GioiTinh'}>Giới tính</option>
                        <option value={'QuocGia'}>Quốc gia</option>
                        <option value={'TonGiao'}>Tôn giáo</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} className="button-group-center">
                    <Button variant="contained" color="primary" disableElevation
                        onClick={dongBo}>
                        Đồng bộ
                    </Button>
                </Grid>
            </Grid>
            <ConfirmationDialogRaw id="confirm-dialog-dong-bo" keepMounted open={openConfirm}
                title="Đồng bộ"
                content="Bạn có đồng ý thực hiện thao tác này?" onClose={handleDongBo}
            />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div>
    )
}