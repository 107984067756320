import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";
import { HoSoThuLyBLThuocLa } from "../model/HoSoThuLyBLThuocLa";
import { GIAY_PHEP_BL_THUOC_LA_INIT } from "./GiayPhepBLThuocLaInit";

export const CAP_MOI_BL_THUOC_LA_INIT: HoSoThuLyBLThuocLa = {
    id: 0,
    maHoSo: '',
    maThuTuc: '',
    tenThuongNhan: '',
    maQuanHuyen: '',
    tenQuanHuyen: '',
    maPhuongXa: '',
    tenPhuongXa: '',
    maDuong: '',
    tenDuong: '',
    soNha: '',
    dienThoai: '',
    fax: '',
    soCNDKKD: '',
    noiCapCNDKKD: '',
    ngayDangKyLanDau: 0,
    lanThayDoi: 0,
    ngayThayDoi: 0,
    maBienDong: '',
    tenBienDong: '',
    maTinhTrang: '',
    tenTinhTrang: '',
    ngayCapPhep: 0,
    ngayHetHanGP: 0,
    soGiayPhep: '',
    soGiayPhepCu: '',
    donViCapPhep: true,
    diaChiDayDu: '',
    lyDo: '',
    ngayCapLaiOrSuaDoi: 0,
    soLanCapLaiOrSuaDoi: 0,
    giayPhepId: 0,
    diaDiemKinhDoanh: '',
    trachNhiemThucHien: '',
    noiNhan: '',
    maCoQuan : '',
    maChuyenNganh : '',
    chiNhanhVanPhongDaiDiens: [],
    thuongNhanBanBuons: [],
    diaDiemToChucBanLes: [],
    files: [],
    fileTrash: [],
    lichSuBLThuocLas: [],
    giayPhep: GIAY_PHEP_BL_THUOC_LA_INIT,

    loaiKetQua: LoaiKetQuaEnum.CHUA_LAY_KET_QUA,
    ngayHenTra: 0,
}

export const CAP_LAI_BL_THUOC_LA_INIT: HoSoThuLyBLThuocLa = {
    id: 0,
    maHoSo: '',
    maThuTuc: '',
    tenThuongNhan: '',
    maQuanHuyen: '',
    tenQuanHuyen: '',
    maPhuongXa: '',
    tenPhuongXa: '',
    maDuong: '',
    tenDuong: '',
    soNha: '',
    dienThoai: '',
    fax: '',
    soCNDKKD: '',
    noiCapCNDKKD: '',
    ngayDangKyLanDau: 0,
    lanThayDoi: 0,
    ngayThayDoi: 0,
    maBienDong: '',
    tenBienDong: '',
    maTinhTrang: '',
    tenTinhTrang: '',
    ngayCapPhep: 0,
    ngayHetHanGP: 0,
    soGiayPhep: '',
    soGiayPhepCu: '',
    donViCapPhep: false,
    diaChiDayDu: '',
    lyDo: '',
    ngayCapLaiOrSuaDoi: 0,
    soLanCapLaiOrSuaDoi: 0,
    giayPhepId: 0,
    diaDiemKinhDoanh: '',
    trachNhiemThucHien: '',
    noiNhan: '',
    maCoQuan : '',
    maChuyenNganh : '',
    chiNhanhVanPhongDaiDiens: [],
    thuongNhanBanBuons: [],
    diaDiemToChucBanLes: [],
    files: [],
    fileTrash: [],
    lichSuBLThuocLas: [],
    giayPhep: GIAY_PHEP_BL_THUOC_LA_INIT,
    loaiKetQua: LoaiKetQuaEnum.CHUA_LAY_KET_QUA,
    ngayHenTra: 0,
}
