
import MomentUtils from "@date-io/moment";
import {
    Box,
    Button, Checkbox, Dialog, FormHelperText, Grid,
    InputBase,
    InputLabel,
    Snackbar,
    FormControlLabel
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { CapPhepXayDungService } from '../../../../api/CPXD/CapPhepXayDungService';
import { GIAY_PHEP_CHO_CAP_SO_INIT } from '../../../../api/CPXD/init/CapPhepXayDungInit';
import { GiayPhepChoCapSoDTO } from '../../../../api/CPXD/model/CapPhepXayDungModel';
import { DialogContent, DialogTitle } from '../../../commons/components/dialog/dialogCommons';
import { DUPLICATE_CODE } from '../../../Constants/CapPhepXayDungConstants';
import StringUtil from '../../../utils/stringUtils';
import { Alert } from "@material-ui/lab";
import DateUtil from "../../../utils/dateUtils";
import DropZoneCustom from "../../../commons/components/CustomDropzone/DropZoneCustom";
import { ACCEPT_FILES } from "../../../Constants/constants";
import AXIOS_INSTANCE from "../../../../api";
import { FileEntry } from "../../../../api/Common/fileEntry";
import { IFileWithMeta } from "react-dropzone-uploader";
import { FileServerService } from "../../../../api/Common/FileServerService";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import LayKetQuaGiayPhepService from "../../../../api/QuanTri/LayKetQuaGiayPhepService";
import { hideLoading, showLoading, LoadingAction } from "../../../../redux/actions/applicationAction";
import { Dispatch } from "redux";
import { KetQuaGiayPhepDTO } from "../../../../api/Common/KetQuaGiayPhepDTO";



interface CapSoGiayPhepXayDungModalProps {
    data: GiayPhepChoCapSoDTO;
    show: boolean;
    indexEdit: number;
    onToggle: () => void;
    onSubmit: (success: boolean, mess: string) => void;
    showMessageAlert?: (mess: string, success: boolean, isWarning?: boolean) => void;

}
const CapSoGiayPhepXayDungModal = (props: CapSoGiayPhepXayDungModalProps) => {
    const [data, setData] = useState({ ...GIAY_PHEP_CHO_CAP_SO_INIT } as GiayPhepChoCapSoDTO);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [show, setShow] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);
    const { onToggle, onSubmit, showMessageAlert } = props;
    const capPhepXayDungService = new CapPhepXayDungService();
    const [showAlert, setShowAlert] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const fileServerService = new FileServerService();
    useEffect(() => {
        setData(props.data);
        setShow(props.show);
        setIndexEdit(props.indexEdit);
        setSubmit(false);
    }, [props.data, props.show, props.indexEdit]);

    // useEffect(() => {
    //     if(data?.ngayCapPhep != null){
    //         setData({
    //             ...data,
    //             ngayHetHan : DateUtil.getOneYear(data?.ngayCapPhep)
    //         });
    //     }
    // }, [data?.ngayCapPhep]);   

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";

        setData(data => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }
    const reset = () => {
        setData(data => ({
            ...data,
            soGiayPhep: "",
            ngayCapPhep: 0,
            ngayHetHan: 0,
            ghiChu: "",
            nguoiKy: "",
            mienGiayPhepXayDung: false,
            files: [],
        }));
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {

        setData(data => ({
            ...data,
            [filedName]: date?.toDate()?.getTime(),
        }));

    }
    const handleSubmit = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
            return;
        }
        if (!data.ngayCapPhep || data.ngayCapPhep === undefined || data.ngayCapPhep === 0) {
            return;
        }
        if (!data.ngayHetHan || data.ngayHetHan === undefined || data.ngayHetHan === 0) {
            return;
        }
        // if (data.ngayCapPhep > StringUtil.dateSecond()) {
        //     setMessage("Ngày cấp phép không được lớn hơn ngày hiện tại");
        //     setSuccess(false);
        //     setShowAlert(true);
        //     return;
        // }

        if (indexEdit >= 0) {
            capPhepXayDungService.capNhatSoGiayPhep(data).then(response => {
                if (response.data.error_code == 'SUCCESSFUL') {
                    onSubmit(true, "Yêu cầu thực hiện thành công");
                } else if (response.data.error_code == DUPLICATE_CODE) {
                    onSubmit(false, "Số giấy phép đã tồn tại ");
                } else {
                    onSubmit(false, "Yêu cầu thực hiện thất bại");
                }
            }).catch(error => {
                onSubmit(false, "Yêu cầu thực hiện thất bại");
                console.log(error);

            });
        }
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        setData({
            ...data,
            files: mems
        })
        // onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        setData({
            ...data,
            files: mems
        })
        // onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url) {
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        } else {
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const handleChangeNgayCap = (date: any) => {
        if (data) {
            setData({
                ...data,
                ngayCapPhep: date?.toDate()?.getTime(),
                ngayHetHan: DateUtil.getOneYear(date?.toDate()?.getTime())
            })
        }
    };

    const layKetQuaSerVice = new LayKetQuaGiayPhepService();
    const handleDongBoGiayPhep = async () => {
        if (showMessageAlert !== undefined) {
            dispatchLoading(showLoading());
            await layKetQuaSerVice.layKetQuaByMaHoSo(data.maHoSo).then((res) => {
                if (res?.data?.error_code == "SUCCESSFUL" && res.data.data !== null) {
                    if (res?.data?.data?.files && res?.data?.data?.files.length > 0) {
                        const files: FileEntry[] = [];
                        for (let i = 0; i < res?.data?.data?.files.length; i++) {
                            const file: FileEntry = {
                                fileEntryId: 0,
                                folderId: 0,
                                userId: "",
                                userName: "",
                                name: res.data.data.files[i].fileName,
                                createdDate: 0,
                                extension: "",
                                mimeType: "",
                                orginName: "",
                                description: "",
                                version: "",
                                docId: "",
                                fileParentId: 0,
                                motCua: true,
                                url: res.data.data.files[i].url,
                            }
                            files.push(file);
                        }
                        data.files = files;
                    }
                    data.soGiayPhep = res.data.data.soGiayPhep;
                    data.ngayCapPhep = res.data.data.ngayCap;
                    if (res.data.data.ngayHetHanGP !== null) {
                        data.ngayHetHan = res.data.data.ngayHetHan;
                    }
                    const ketQuaGiayPhep: KetQuaGiayPhepDTO={
                        soGiayPhep: res.data.data.soGiayPhep,
                        ngayHetHan : res.data.data.ngayHetHan,
                        files: res.data.data.files,
                        ngayCap: res.data.data.ngayCap,
                    }
                    data.ketQuaGiayPhep = ketQuaGiayPhep;
                    showMessageAlert(res.data.error_message, true);
                }
             
                else {
                    showMessageAlert(res.data.error_message, false, true);
                }
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
    }


return (
    <>
        {

            <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                    CẬP NHẬT VĂN BẢN
                </DialogTitle>
                <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <Alert onClose={() => setShowAlert(false)} severity={success ? 'success' : 'error'}>{message}</Alert>
                </Snackbar>
                <DialogContent dividers>

                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required>Số GPXD </InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                    value={data.soGiayPhep !== undefined && data.soGiayPhep !== '' ? data.soGiayPhep : ''} name="soGiayPhep" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số giấy phép</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Ngày cấp phép </InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        value={(data.ngayCapPhep !== null && data.ngayCapPhep !== undefined && data.ngayCapPhep !== 0) ? new Date(data.ngayCapPhep) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        error={submit && (data.ngayCapPhep === null || data.ngayCapPhep === 0 || data.ngayCapPhep === undefined)}
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        InputProps={{ disableUnderline: true }}
                                        onChange={handleChangeNgayCap}
                                    />
                                    {
                                        submit && (data.ngayCapPhep === null || data.ngayCapPhep === 0 || data.ngayCapPhep === undefined) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập ngày cấp phép</FormHelperText>
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        value={(data.ngayHetHan !== null && data.ngayHetHan !== undefined && data.ngayHetHan !== 0) ? new Date(data.ngayHetHan) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        error={submit && (data.ngayHetHan === null || data.ngayHetHan === 0 || data.ngayHetHan === undefined)}
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        InputProps={{ disableUnderline: true }}
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHan')}
                                    />
                                    {
                                        submit && (data.ngayHetHan === null || data.ngayHetHan === 0 || data.ngayHetHan === undefined) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập ngày hết hạn</FormHelperText>
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Ghi chú </InputLabel>
                                <InputBase fullWidth
                                    multiline
                                    rows="8"
                                    name="ghiChu"
                                    value={data.ghiChu} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Người ký </InputLabel>
                                <InputBase fullWidth
                                    value={data.nguoiKy} name="nguoiKy" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <FormControlLabel
                                    value={data.mienGiayPhepXayDung}
                                    onChange={handleChange}
                                    name="mienGiayPhepXayDung"
                                    label="Công trình miễn giấy phép xây dựng"
                                    control={<Checkbox color="primary" checked={data.mienGiayPhepXayDung == true ? true : false} />}
                                />
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={ACCEPT_FILES}
                                    attachFileServer={data.files}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/cpxd"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <Box marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={handleSubmit}>
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="secondary" disableElevation
                                        onClick={reset}>
                                        Xóa
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation
                                        onClick={onToggle}>
                                        Đóng
                                    </Button>
                                    {data.soGiayPhep && data.maHoSo ?
                                        <Button variant="outlined" disableElevation onClick={
                                            () => handleDongBoGiayPhep()
                                        }>
                                            Lấy kết quả
                                        </Button>
                                        : ""
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        }
    </>
)}
    
export default CapSoGiayPhepXayDungModal;