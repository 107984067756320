import {
    Grid,
    InputLabel
} from '@material-ui/core';
import { useEffect, useState } from 'react';

interface chiTietProps {
    data: string;
}

const GiayToVeDatDai = (props: chiTietProps) => {
    const [data, setData] = useState('');
    useEffect(() => {
      setData(props.data);  
    }, [props.data]);

    return (
        <>
            {  
                <Grid container spacing={2}>
                    <Grid item xs={2} className="grid-item" container justify="flex-end">
                            <InputLabel >Giấy tờ về đất đai</InputLabel>
                    </Grid>
                    <Grid item xs={10} className="grid-item">
                        <InputLabel className="detail-label">{data}</InputLabel>
                    </Grid>
             </Grid>
            }
        </>
    )
}
export default GiayToVeDatDai;