import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Box, Button, NativeSelect } from '@material-ui/core';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { ThongTinThamDinhDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { THONG_TIN_THAM_DINH_DTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ThongTinThamDinhModal from '../Modal/ThongTinThamDinhModal';
import GetAppIcon from "@material-ui/icons/GetApp";
import { AttachIcon } from "../../../../commons/components/AttachFileTooltip/AttachFileTooltip";
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileServerService } from '../../../../../api/Common/FileServerService';
interface ThongTinThamDinhTableProps {
    data: ThongTinThamDinhDTO[];
    onUpdateData: (name: string, value: any) => void;
}
interface ThongTinThamDinhModalDataProps {
    data: ThongTinThamDinhDTO;
    show: boolean;
    indexEdit: number;
}
const ThongTinThamDinhTable = (props: ThongTinThamDinhTableProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<ThongTinThamDinhDTO[]>(new Array<ThongTinThamDinhDTO>());
    const [dataEdit, setDataEdit] = useState<ThongTinThamDinhModalDataProps>({ data: { ...THONG_TIN_THAM_DINH_DTO_INIT }, show: false, indexEdit: -1 });
    const { onUpdateData } = props;
    const fileServerService = new FileServerService();
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = ((pageNumberNew - 1) * pageSizeNew);
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    }
    const handleAdd = (rowNew: ThongTinThamDinhDTO) => {
        let dataNew = [...props.data];
        dataNew.unshift(rowNew);
        onUpdateData('thongTinThamDinhDTOs', dataNew);
    }
    const handleDrop = (index: number) => {
        let dataNew = [...props.data];
        dataNew.splice(index, 1);
        onUpdateData('thongTinThamDinhDTOs', dataNew);
    }
    const handleUpdate = (index: number, value: ThongTinThamDinhDTO) => {
        let dataNew = [...props.data];
        dataNew[index] = value;
        onUpdateData('thongTinThamDinhDTOs', dataNew);
    }
    const handleToggle = () => {
        setDataEdit({ ...dataEdit, show: !dataEdit.show });
    }
    const handleEditToggle = (index: number) => {
        setDataEdit({ ...dataEdit, data: props.data[index], show: !dataEdit.show, indexEdit: index });
    }
    const handleAddToggle = () => {
        setDataEdit({ data: { ...THONG_TIN_THAM_DINH_DTO_INIT }, show: !dataEdit.show, indexEdit: -1 });

    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    return (
        <div>
            <ThongTinThamDinhModal
                data={dataEdit}
                onToggle={handleToggle}
                onAddData={handleAdd}
                onUpdateData={handleUpdate}
            />
            <table className="table table-bordered" style={{ fontSize: '14px' }}>
                <thead>
                    <tr className="table-secondary">
                        <th>STT</th>
                        <th>Nội dung</th>
                        <th style={{ width: "10%" }} >Tập tin</th>
                        <th style={{ width: "5%" }} className="text-center">Sửa</th>
                        <th style={{ width: "5%" }} className="text-center">Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {dataPagi?.length > 0 ? dataPagi?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="cell-number">{((pageNumber - 1) * pageSize) + index + 1}</td>
                                <td>{item.noiDung}</td>
                                <td>
                                    <Box component="span" display="flex" flexDirection="column">


                                        {
                                            item.files && item.files.length > 0 ?
                                                item.files.map((item, indexFile) => {
                                                    return (
                                                        <Box component="span" display="flex" flexGrow="1" flexDirection="column" key={indexFile}>
                                                            <Button
                                                                size="small"
                                                                // className={classes.buttonFile}
                                                                disableRipple
                                                                startIcon={<AttachIcon fileName={item.name} />}
                                                                style={{
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    width: "410px",
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                                endIcon={<GetAppIcon color="primary" fontSize="small" />}
                                                                onClick={(e) => handleDownloadFile(item)}
                                                            >
                                                                <Box component="span" flexGrow={1} textAlign="left" >{item.name.length > 30 ? item.name.slice(0, 33) + "..." + item.name.slice(item.name.length - 10) : item.name}</Box>
                                                            </Button>
                                                        </Box>
                                                    )
                                                })
                                                :
                                                ""
                                        }
                                    </Box>
                                </td>
                                <td align="center">
                                    <Button onClick={(e) => handleEditToggle(((pageNumber - 1) * pageSize) + index)} startIcon={<EditIcon color="primary" />} size="small" disableRipple />
                                </td>
                                <td align="center">
                                    <Button onClick={(e) => handleDrop(((pageNumber - 1) * pageSize) + index)} startIcon={<CloseIcon color="secondary" />} size="small" disableRipple />
                                </td>
                            </tr>
                        )
                    }) :
                        <tr>
                            <td className="text-center" colSpan={5}> Không có dữ liệu</td>
                        </tr>
                    }
                </tbody>
            </table>

            <div className="row">
                {/* pagination*/}
                <div className="col-md-10" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                    <Pagination count={Math.ceil(props.data?.length / pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleChangePage} />
                    <div>
                        <span> Số mẫu tin trên trang </span>
                        <NativeSelect value={pageSize} input={<CustomInput />} onChange={handleChangePageSize} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                        <span>Trang {pageNumber}  / {props.data?.length === 0 ? 1 : Math.ceil(props.data?.length / pageSize)}</span>
                    </div>
                </div>
                <div className="col-md-2 text-right" style={{ fontSize: '14px' }}>
                    <Button onClick={handleAddToggle} variant="contained" color="primary" disableElevation>Thêm mới</Button>
                </div>

            </div>
        </div>
    )
}
export default ThongTinThamDinhTable;