import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { ThongKeTinhHinhCapPhepResponse } from '../../../../api/VSATTP/Model/VSATTPModel';
import {Cell, Row} from "../../../commons/components/table/tableCommons";

interface IDataListProps {
    data: any;
    detailRequest: any;
    setOpenDialog: Dispatch<SetStateAction<boolean>>;
    setDetailRequest: Dispatch<SetStateAction<any>>;
}

export const DanhSachThongKeCapPhep = (props: IDataListProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [data, setData] = useState<ThongKeTinhHinhCapPhepResponse>();
    useEffect(() => {
        setData(props.data);
        setRows(props.data?.thongKeTinhHinhCapPhepDTOs);
    }, [props.data?.thongKeTinhHinhCapPhepDTOs]);

    const handleOnClickChiTietPhuongXa = (row: any, type: string, count: number) => {
        props.setOpenDialog(true);
        props.setDetailRequest({
            ...props.detailRequest,
            tinhTrang: type,
            maPhuongXaTotal: row.maPhuongXa,
            tenPhuongXa: row.tenPhuongXa,
            total: count,
        });
    };

    const handleOnClickChiTietQuanHuyen = (type: string, count: number) => {
        props.setOpenDialog(true);
        props.setDetailRequest({
            ...props.detailRequest,
            tinhTrang: type,
            maPhuongXaTotal: null,
            tenPhuongXa: null,
            total: count,
        });
    };

    return (
      <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <Cell component="th" scope="row" align="center" width="5%">
                STT
              </Cell>
              <Cell component="th" scope="row" align="center" width="35%">
                Phường/ Xã/ Thị trấn
              </Cell>
              <Cell component="th" scope="row" align="center" width="15%">
                Còn hạn
              </Cell>
              <Cell component="th" scope="row" align="center" width="15%">
                Hết hạn
              </Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows.map((row, i) => (
                <Row key={row?.id}>
                  <Cell scope="row" align="center">
                    {i + 1}
                  </Cell>
                  <Cell align="left">{row.tenPhuongXa}</Cell>
                  <Cell align="center" className="text-center">
                    <span
                      style={{ cursor: row.conHan > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.conHan > 0) {
                          handleOnClickChiTietPhuongXa(row, "CH", row.conHan);
                        }
                      }}
                    >
                      {row.conHan}
                    </span>
                  </Cell>
                  <Cell align="center" className="text-center">
                    <span
                      style={{ cursor: row.hetHan > 0 ? "pointer" : "" }}
                      onClick={() => {
                        if (row.hetHan > 0) {
                          handleOnClickChiTietPhuongXa(row, "HH", row.hetHan);
                        }
                      }}
                    >
                      {row.hetHan}
                    </span>
                  </Cell>
                </Row>
              ))
            ) : (
              <Row>
                <Cell colSpan={7} scope="row" align="center">
                  {" "}
                  Không có dữ liệu
                </Cell>
              </Row>
            )}
            {rows?.length > 0 ? (
              <Row>
                <Cell scope="row" align="center"></Cell>
                <Cell align="left" className="font-weight-bold">
                  Tổng số
                </Cell>
                <Cell align="center" className="font-weight-bold text-center">
                  <span
                    style={{
                      cursor: data?.conHan && data?.conHan > 0 ? "pointer" : "",
                    }}
                    onClick={() => {
                      if (data?.conHan && data?.conHan > 0)
                        handleOnClickChiTietQuanHuyen(
                          "CH",
                          data?.conHan ? data?.conHan : 0
                        );
                    }}
                  >
                    {data?.conHan}
                  </span>
                </Cell>
                <Cell align="center" className="font-weight-bold text-center">
                  <span
                    style={{
                      cursor: data?.hetHan && data?.hetHan > 0 ? "pointer" : "",
                    }}
                    onClick={() => {
                      if (data?.hetHan && data?.hetHan > 0)
                        handleOnClickChiTietQuanHuyen(
                          "HH",
                          data?.hetHan ? data?.hetHan : 0
                        );
                    }}
                  >
                    {data?.hetHan}
                  </span>
                </Cell>
              </Row>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
}
