import { RequestThongKeDTO } from "../../App/Components/CPXD/BieuDoTK/BieuDoThongKeCPXD";
import { ThongKeTinhHinhRequest } from "../../App/Components/CPXD/ThongKe/ThongKeComponent";
import { BaseService, Pagination } from "../baseService";
import AXIOS_INSTANCE from "../index";
import { ThongKeTinhHinhRespone } from "./model/ThongKeTinhHinhCPXD";
export class ThongKeCPXDService extends BaseService {
    thongKe = async (requestBody: ThongKeTinhHinhRequest): Promise<ThongKeTinhHinhRespone> => {
        let result = {} as ThongKeTinhHinhRespone;
        await AXIOS_INSTANCE.post('/cpxd/thong-ke/tinh-hinh-cpxd', requestBody, this.getTokenRequestHeaders()).then(response => {

            if (response.data.data) {

                result.thongKeTinhHinhs = response.data.data;
            }
        })
        return result;
    }

    chiTietThongKe = async (request: ThongKeTinhHinhRequest): Promise<Pagination<ThongKeTinhHinhRespone[]>> => {
        let result: Pagination<ThongKeTinhHinhRespone[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post("/cpxd/thong-ke/tinh-hinh-cpxd/chi-tiet", request, this.getTokenRequestHeaders()).then(response => {
            console.log(response);
            if (response.data.data) {
                result = {
                    ...result,
                    total: response.data.data.length,
                    rows: response.data.data,
                }
            }
        }).catch();
        return result;
    }

    downloadFileThongKe = (request: ThongKeTinhHinhRequest) => {
        return AXIOS_INSTANCE.post('/cpxd/thong-ke/export', request, {
            responseType: 'blob'
        });

    }

    bieuDoThongke = (requestBody: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.post('/cpxd/thong-ke/bieu-do-thong-ke', requestBody);
    }
}