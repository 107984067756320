import { LichSuGiayPhepDTO_INIT } from "../../Common/init/CommonInit";
import { LichSuGiayPhepDTO } from "../../Common/model/CommonModel";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";
import { GiayPhepKinhDoanhLPGChaiDTO } from "./GiayPhepKinhDoanhLPGChai";
import { HoSoThuLyLPGChaiDTO } from "./HoSoThuLyLPGChai";

export const GIAY_PHEP_LGP_CHAI_INIT: GiayPhepKinhDoanhLPGChaiDTO = {
    id: 0,
    hoSoThuLyId: 0,
    maBienDong: '',
    tenBienDong: '',
    maTinhTrang: '',
    tenTinhTrang: '',
    soGiayPhep: '',
    soGiayPhepCu: '',
    ngayCapPhep: 0,
    ngayHetHanGP: 0,
    ghiChu: '',
    nguoiKy: '',
    ngayBienDong: 0,
    noiDungBienDong: '',
    lyDo: '',
    soLanCapLaiOrSuaDoi: 0,
    ngayCapLaiOrSuaDoi: 0,
    maHoSo: ''
}

export const HO_SO_THU_LY_LPGCHAI_INIT: HoSoThuLyLPGChaiDTO = {
    id: 0,

    tenThuongNhan: '',

    maHoSo: '',

    donViCapPhep: true,

    soNha: '',

    maDuong: '',

    tenDuong: '',

    maPhuongXa: '',

    tenPhuongXa: '',

    maQuanHuyen: '',

    tenQuanHuyen: '',

    soDienThoai: '',

    fax: '',

    maThuTuc: '',

    soGiayPhep: '',

    soGiayPhepCu: '',

    ngayCapPhep: 0,

    ngayHetHanGP: 0,

    ngayTao: 0,

    ngayCapNhat: 0,

    ngayThayDoi: 0,

    soGiayCNDKKD: '',

    noiCapCNDKKD: '',

    ngayCapCNDKKD: 0,

    maBienDong: '',

    tenBienDong: '',

    maTinhTrang: '',

    tenTinhTrang: '',

    lyDo: '',

    lanCap: 0,

    maSoThue: '',

    ngayBienDong: 0,

    diaChiDayDu: '',

    giayPhepId: 0,

    theoDeNghi: '',

    noiNhan: '',

    dieuQuyDinh: '',
    
    maLoaiGiayPhep: '',

    maCoQuan: '',
    maChuyenNganh: '',

    files: [],

    lichSus: [],

    lichSuGanNhat: LichSuGiayPhepDTO_INIT,

    loaiKetQua: LoaiKetQuaEnum.CHUA_LAY_KET_QUA,
}

