import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { ICONS } from '../../../../Constants/constants';
import DonViTuVanService from '../../../../../api/CPXD/DonViTuVanService';
import { Pagination } from '@material-ui/lab';
import { Button, NativeSelect } from '@material-ui/core';
import NhaORiengLeDonViTuVanAddModal from './NhaORiengLeDonViTuVanAddModal';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import { CustomInput } from '../../../../commons/components/input/customInput';
import { useEffect, useState } from "react";


export default class NhaORiengLeDonViTuVanModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reset: true,
            show: false,
            search: {
                keywords: '',
                hoatDong: 1,
                pageNumber: 1,
                pageSize: 5
            },
            data: {
            },
            donViTuVan: {
                donViTuVanId: 0,
                donViTuVan: ''
            },
            showAdd: false,
            showDelete: false,
            donViTuVanEdit: {
                id: 0,
                noiDungGoiY: '',
                hoatDong: true,
            },
            donViTuVanDelete: {
                id: 0,
                noiDungGoiY: '',
                hoatDong: true,
            }
        }
        this.donViTuVanService = new DonViTuVanService();
    }
    componentDidMount() {
        this.handleSearch();
    }
    componentDidUpdate() {
        if (this.props.show != this.state.show) {
            this.state.show = this.props.show;
            this.setState(this.state);
        }
        this.state.reset = true;
    }
    /*Phân trang*/
    handleChangePage = (event, valuePage) => {
        this.state.search.pageNumber = valuePage;
        this.setState(this.state);
        this.handleSearch();
    }
    handleChangePageSize = (event) => {
        let value = event.target.value;
        this.state.search.pageSize = value;
        this.state.search.pageNumber = 1;
        this.handleSearch();
    }
    clickSearch = (event) => {
        this.state.reset = false;
        this.state.search.pageNumber = 1;
        this.setState(this.state);
        this.handleSearch();
    }
  
    handleSearch = (event) => {
        this.state.donViTuVanEdit = {
            id: 0,
            noiDungGoiY: '',
            hoatDong: true,
        }
        this.donViTuVanService.timKiem(this.state.search).then(res => {
            if (res != null) {
                let totalPage = res.data.data?.total / this.state?.search?.pageSize;
                if (totalPage > parseInt(totalPage)) {
                    totalPage = parseInt(totalPage) + 1;
                }
                this.state.data = res.data.data;
                this.state.totalPage = totalPage;
                this.setState(this.state);
            } else {
                toastr.warning('Lỗi kết nối dịch vụ');
            }
        }).catch(error => {
            toastr.warning('Lỗi kết nối dịch vụ');
        });
    }
    handleModalToggle = () => {
        this.state.showAdd = !this.state.showAdd;
        this.setState(this.state);
    }
    handleAdd = () => {
        this.state.donViTuVanEdit = {
            id: 0,
            noiDungGoiY: '',
            hoatDong: true,
        }
        this.setState(this.state);
        this.handleModalToggle();

    }
    handleEdit = (donViTuVanEdit) => {
        this.state.donViTuVanEdit = donViTuVanEdit;
        this.setState(this.state);
        this.handleModalToggle();

    }
    handleCloseModal = () => {
        this.state.donViTuVanEdit = {
            id: 0,
            noiDungGoiY: '',
            hoatDong: true,
        }
        this.props.onModalToggle();
    }
    handleChooseRow = (event, value) => {
        this.state.donViTuVan = {
            donViTuVanId: value.id,
            donViTuVan: value.noiDungGoiY
        }
        this.setState(this.state);
    }
    handleChoose = () => {
        this.props.onUpdateData('donViTuVanId', this.state.donViTuVan.donViTuVanId);
        this.props.onUpdateData('donViTuVan', this.state.donViTuVan.donViTuVan);
        this.handleCloseModal();
    }
    handleDelete = (donViTuVanDelete) => {
        this.state.showDelete = true;
        this.state.donViTuVanDelete = donViTuVanDelete;
        this.setState(this.state);
    }
    confirmaDelete = async (isOk) => {
        if (Boolean(isOk)) {
            if (this.state.donViTuVanDelete.id > 0) {
                this.donViTuVanService.delete(this.state.donViTuVanDelete.id).then(res => {
                    if (res) {
                        toastr.success('Xoá đơn vị tư vấn thành công');
                        this.state.showDelete = false;
                        this.state.donViTuVanDelete = {
                            id: 0,
                            noiDungGoiY: '',
                            hoatDong: true,
                        }
                        this.setState(this.state);
                        this.handleSearch();
                    } else {
                        toastr.error('Xoá đơn vị tư vấn thất bại');
                    }
                });
            } else {
                toastr.error('Xoá đơn vị tư vấn thất bại');
            }
        } else {
            this.state.showDelete = false;
            this.setState(this.state);
        }
    }
    setParams = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.state.search[name] = value;
        this.setState(this.state);
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleCloseModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <NhaORiengLeDonViTuVanAddModal data={this.state.donViTuVanEdit} onModalToggle={this.handleModalToggle} show={this.state.showAdd} onUpdateData={this.handleSearch}>

                </NhaORiengLeDonViTuVanAddModal>
                <ConfirmationDialogRaw id="confirm" keepMounted open={this.state.showDelete}
                    title="Xóa Đơn vị tư vấn"
                    content="Bạn có muốn xóa ?"
                    onClose={this.confirmaDelete}
                />
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title" style={{ fontWeight: 'bold', color: "white" }}>ĐƠN VỊ TƯ VẤN</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={process.env.PUBLIC_URL + "/images/close.png"}></img></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}>Nội dung gợi ý</label>
                                <input type="text" className="form-control" id="keywords" name="keywords" onChange={this.setParams} />
                            </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop: '30px' }}>
                            <div className="form-group">
                                <label htmlFor="id" style={{ fontWeight: 'bold', fontSize: '14px' }}></label>
                                <Button onClick={this.clickSearch} variant="contained" color="primary" disableElevation>Tìm kiếm</Button>
                                <Button onClick={this.handleAdd} variant="contained" color="primary" disableElevation>Thêm mới</Button>
                                <Button onClick={this.handleChoose} variant="contained" color="primary" disableElevation>Chọn</Button>
                            </div>
                        </div>
                    </div>
                    <table className="table table-bordered" style={{ fontSize: '14px' }}>
                        <thead>
                            <tr className="table-secondary">
                                <th width="5%">STT</th>
                                <th width="5%"></th>
                                <th>Nội dung gợi ý</th>
                                <th width="10%" className="text-center">Sửa</th>
                                <th width="10%" className="text-center">Xóa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data?.data?.length > 0 ? this.state.data?.data?.map((item, index) => {
                                return (
                                    <tr>
                                        <td className="text-center">{((this.state.search.pageNumber - 1) * this.state.search.pageSize) + index + 1}</td>
                                        <td align="center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios" checked={this.state.donViTuVan.donViTuVanId === item.id} value={item.id} onChange={(e) => this.handleChooseRow(e, item)} />
                                            </div>
                                        </td>
                                        <td>{item.noiDungGoiY}</td>
                                        <td align="center"><a onClick={(e) => this.handleEdit(item)}><img src={ICONS.edit}></img></a></td>
                                        <td align="center"><a onClick={(e) => this.handleDelete(item)}><img src={ICONS.delete}></img></a></td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td className="text-center" colSpan={6}> Không có dữ liệu</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {
                        this.state.reset && (
                            <div className="row" style={{ paddingTop: '5px', display: 'flex', fontSize: '14px', color: '#555555', alignItems: 'center' }}>
                            <Pagination count={this.state.totalPage} va defaultPage={1} siblingCount={1} variant="outlined"
                                size="medium"
                                color="primary" shape="rounded" showFirstButton showLastButton onChange={this.handleChangePage} />
                            <div>
                                <span> Số mẫu tin trên trang </span>
                                <NativeSelect value={this.state.search.pageSize} input={<CustomInput />} onChange={this.handleChangePageSize} >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>20</option>
                                </NativeSelect>
                                <span>Trang {this.state.search.pageNumber}  / {this.state.totalPage}</span>
                            </div>
                        </div>
                        )
                    }
                  
                </div>
            </Modal>
        )
    }
}