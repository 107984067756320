import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import { Link, useHistory } from 'react-router-dom';
import { Dispatch, useRef, useState } from "react";
import {
    ROUTE_DKKD_CAPLAI, ROUTE_DKKD_CAPMOI, ROUTE_DKKD_CHAMDUT,
    ROUTE_DKKD_DAUKY, ROUTE_DKKD_DIEUCHINH, ROUTE_DKKD_TAMNGUNG
} from "../../../../routes/route-cn-dkkd";
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from "@material-ui/icons/Refresh";
import CloseIcon from '@material-ui/icons/Close';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import StopIcon from '@material-ui/icons/Stop';
import BlockIcon from '@material-ui/icons/Block';
import { DanhSachGiayPhepDKKDDTO } from "../../../../api/DKKD/model/DanhSachGiayPhepDKKDDTO";
import { LOAIBIENDONG_CAPLAI, LOAIBIENDONG_CAPMOI, LOAIBIENDONG_CHAMDUT, LOAIBIENDONG_DIEUCHINH, LOAIBIENDONG_TAMNGUNG, TINHTRANG_HOATDONG, TINHTRANG_HUY, TINHTRANG_NGUNGHOATDONG, TINHTRANG_TAMNGUNG, TINHTRANG_THUHOI } from "../../../Constants/DKKDConstants";
import StringUtil from "../../../utils/stringUtils";

export interface ThaoTacQuanLyDKKDProps {
    row: DanhSachGiayPhepDKKDDTO;
}

export const
    ThaoTacQuanLyDKKD = (props: ThaoTacQuanLyDKKDProps) => {

        const { row } = props;
        const anchorRef = useRef<HTMLButtonElement>(null);
        const [open, setOpen] = useState(false);
        let history = useHistory();
        const showHidePopperThaoTac = async (event: React.MouseEvent<HTMLButtonElement>, maTinhTrang: string, maLoaiGiayPhep: string) => {
            setOpen(!open);
        }
        const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
            if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
                return;
            }
            setOpen(false);
        };

        // const clickEdit = (maLoaiGiayPhep: string) => {
        //     if (maLoaiGiayPhep === BienDong.DIEU_CHINH) {
        //         setDuongDan(ROUTE_DKKD_DIEUCHINH.duongDan);
        //     } else if (maLoaiGiayPhep === BienDong.CAP_LAI) {
        //         setDuongDan(ROUTE_DKKD_CAPLAI.duongDan);
        //     } else if (maLoaiGiayPhep === 'DK') {
        //         setDuongDan(ROUTE_DKKD_DAUKY.duongDan);
        //     } else if (maLoaiGiayPhep === BienDong.TAM_NGUNG) {
        //         setDuongDan(ROUTE_DKKD_TAMNGUNG.duongDan);
        //     } else if (maLoaiGiayPhep === BienDong.CHAM_DUT) {
        //         setDuongDan(ROUTE_DKKD_CHAMDUT.duongDan);
        //     } else {
        //         setDuongDan(ROUTE_DKKD_CAPMOI.duongDan);
        //     }
        // }
        const clickEdit = () => {
            let duongDan = ROUTE_DKKD_DAUKY.duongDan;
            if (row.maBienDong == LOAIBIENDONG_CAPMOI.ma && StringUtil.isNotEmpty(row.maHoSo)) {
                duongDan = ROUTE_DKKD_CAPMOI.duongDan;
            } else if (row.maBienDong == LOAIBIENDONG_CAPLAI.ma && StringUtil.isNotEmpty(row.maHoSo)) {
                duongDan = ROUTE_DKKD_CAPLAI.duongDan;
            } else if (row.maBienDong == LOAIBIENDONG_DIEUCHINH.ma && StringUtil.isNotEmpty(row.maHoSo)) {
                duongDan = ROUTE_DKKD_DIEUCHINH.duongDan;
            } else if (row.maBienDong == LOAIBIENDONG_TAMNGUNG.ma && StringUtil.isNotEmpty(row.maHoSo)) {
                duongDan = ROUTE_DKKD_TAMNGUNG.duongDan;
            } else if (row.maBienDong == LOAIBIENDONG_CHAMDUT.ma && StringUtil.isNotEmpty(row.maHoSo)) {
                duongDan = ROUTE_DKKD_CHAMDUT.duongDan;
            }
            history.push(duongDan, { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id });
        }
        return (
            <>
                <Button startIcon={<ListIcon />}
                    size="small" color="primary" disableRipple
                    ref={anchorRef} aria-haspopup="true"
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => showHidePopperThaoTac(e, row.maTinhTrang, 'DK')} >
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" >
                                        <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                            <Button onClick={(e) => clickEdit()} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                                Chỉnh sửa
                                            </Button>
                                        </MenuItem>
                                        {
                                            row.maTinhTrang == TINHTRANG_HOATDONG.ma &&
                                            <>
                                                <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                    <Button onClick={(e) => history.push('/giay-phep-kinh-doanh/huy-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })} startIcon={<CloseIcon color="secondary" />} size="small" disableRipple>
                                                        Hủy
                                                    </Button>
                                                </MenuItem>
                                                <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                    <Button onClick={(e) => history.push('/giay-phep-kinh-doanh/cham-dut-hoat-dong-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })}
                                                        startIcon={<BlockIcon color="secondary" />} size="small" disableRipple>
                                                        Chấm dứt hoạt động
                                                    </Button>
                                                </MenuItem>
                                                {/* <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                    <Button onClick={(e) => history.push('/giay-phep-kinh-doanh/ngung-hoat-dong-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })}
                                                        startIcon={<StopIcon color="secondary" />} size="small" disableRipple>
                                                        Ngưng hoạt động
                                                    </Button>
                                                </MenuItem> */}
                                                <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                    <Button onClick={(e) => history.push('/giay-phep-kinh-doanh/tam-ngung-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })}
                                                        startIcon={<PauseCircleOutlineIcon color="secondary" />} size="small" disableRipple>
                                                        Tạm ngưng
                                                    </Button>
                                                </MenuItem>
                                                <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                    <Button onClick={(e) => history.push('/giay-phep-kinh-doanh/thu-hoi-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })}
                                                        startIcon={<RefreshIcon color="secondary" />} size="small" disableRipple>
                                                        Thu hồi
                                                    </Button>
                                                </MenuItem>
                                            </>
                                        }
                                        {
                                            row.maTinhTrang == TINHTRANG_HUY.ma &&
                                            <>
                                                <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                    <Button onClick={(e) => history.push('/giay-phep-kinh-doanh/hoan-tra-huy-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })}
                                                        startIcon={<RefreshIcon color="primary" />} size="small" disableRipple>
                                                        Hoàn trả hủy
                                                    </Button>
                                                </MenuItem>
                                            </>
                                        }
                                        {
                                            ( row.maTinhTrang == LOAIBIENDONG_CHAMDUT.ma || row.maTinhTrang == TINHTRANG_TAMNGUNG.ma || row.maTinhTrang == TINHTRANG_NGUNGHOATDONG.ma) &&
                                            <>
                                                <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                    <Button onClick={(e) => history.push('/giay-phep-kinh-doanh/khoi-phuc-trang-thai-giay-phep', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })}
                                                        startIcon={<RefreshIcon color="primary" />} size="small" disableRipple>
                                                        Khôi phục
                                                    </Button>
                                                </MenuItem>
                                            </>
                                        }
                                        {
                                            row.maTinhTrang == TINHTRANG_THUHOI.ma &&
                                            <>
                                                <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }}>
                                                    <Button onClick={(e) => history.push('/giay-phep-kinh-doanh/hoan-tra-thu-hoi-gpkd', { ...row, maHoSo: row.maHoSo, soGiayPhep: row.soGiayPhep, giayPhepId: row.id })}
                                                        startIcon={<RefreshIcon color="primary" />} size="small" disableRipple>
                                                        Hoàn trả thu hồi
                                                    </Button>
                                                </MenuItem>
                                            </>
                                        }

                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        )
    }
