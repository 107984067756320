import { DanhMucIView } from "../../api/Common/model/CommonModel";

export const LoaiDoiTuong : DanhMucIView[] = [
    {
        ma : "KD_THUOC_LA",
        ten : "Giấy Phép Kinh Doanh Thuốc Lá"
    },
    {
        ma : "SX_RUOU",
        ten : "Giấy Phép Kinh Doanh Rượu"
    },
    {
        ma : "ATTP",
        ten : "Giấy Phép An Toan Thực Phẩm"
    },
    {
        ma : "LPG_CHAI",
        ten : "Giấy Phép LPG Chai"
    },
    {
        ma : "QD_KT",
        ten : "Quyết Định Khen Thưởng"
    },
    {
        ma : "DKKD",
        ten : "Giấy Phép Đăng Ký Kinh Doanh"
    },
    {
        ma : "CAP_PHEP_XAY_DUNG",
        ten : "Giấy Phép Xây Dựng"
    },
]