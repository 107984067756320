import AXIOS_INSTANCE from "../index";
import api from "../index";
import { BaseResponse, BaseService, Pagination } from "../baseService";
import { DanhSachGiayPhepLGPChaiDTO } from "./model/DanhSachGiayPhepLGPChaiDTO";
import { HoSoThuLyLPGChaiDTO } from "./model/HoSoThuLyLPGChai";
import { RequestDTO } from "../../App/Components/LPGChai/DanhSach/danhSachGiayChungNhanLPGChai";
import { RequestCapSoDTO } from "../../App/Components/LPGChai/CapSoGiayPhep/HoSoCapSoGiayPhepLPGChai";
import { BienDongTrangThaiLGPChaiDTO, CapSoGiayPhepLGPChaiDTO } from "./model/GiayPhepKinhDoanhLPGChai";
import { RequestBaoCaoThongKeDTO } from "../../App/Components/DKKD/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";

export class LPGChaiService extends BaseService {

    getByMaHoSo = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-lpgchai/by-ma-ho-so', requestBody);
    }

    getBySoGiayPhep = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/by-so-giay-phep', requestBody);
    }

    getByGiayPhepId = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/get-by-giay-phep-id', requestBody);
    }

    getChiTiet = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/chi-tiet', requestBody);
    }

    getChiTietByGiayPhepId = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/chi-tiet-by-giay-phep-id', requestBody);
    }
    autocompleteBySoGiayPhep = (soGiayPhep: string, maTrangThai: string, limit: number) => {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-lpgchai/autocomplete-by-so-giay-phep', {
            soGiayPhep: soGiayPhep,
            maTrangThai: maTrangThai,
            limit: limit
        });
    }
    getDanhSach = async (request: RequestDTO): Promise<Pagination<DanhSachGiayPhepLGPChaiDTO[]>> => {
        let result: Pagination<DanhSachGiayPhepLGPChaiDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }

        await api.post('/giay-phep-kinh-doanh-lpgchai/danh-sach', request,
            this.getTokenRequestHeaders()).then(response => {
                if (response.data.data.data) {
                    result = {
                        ...result,
                        total: response.data.data.total,
                        rows: response.data.data.data,
                    }
                }
            }).catch();
        return result;
    }

    downloadFileDanhSach = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/export-danh-sach-giay-phep', requestBody,{
            responseType: 'blob'
        });
    }

    downloadFile(request: any) {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-lpgchai/download', request, {
            responseType: 'blob',
        });
    }

    //vunh29
    insertOrUpdate = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/ho-so-thu-ly-lpgchai/add-or-update', requestBody);
    }

    getChiTietByHoSoThuLyId = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/chi-tiet', requestBody);
    }
    luuVaCapNhatKhoiPhuc = async (khoiPhuc: BienDongTrangThaiLGPChaiDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/add-or-update-bien-dong', JSON.stringify(khoiPhuc), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message,
                data: response.data.data
            }
        }).catch(() => {
            result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' }
        });
        return result;
    }

    timKiem = async (request: RequestCapSoDTO): Promise<Pagination<HoSoThuLyLPGChaiDTO[]>> => {
        let result: Pagination<HoSoThuLyLPGChaiDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }

        await AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/danh-sach-cap-so', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    getCapPhepByMaHoSo = async (json: any): Promise<CapSoGiayPhepLGPChaiDTO> => {
        let result = {} as CapSoGiayPhepLGPChaiDTO;
        await AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/get-by-ho-so-thu-ly-id', JSON.stringify(json), this.getTokenRequestHeaders()).then(response => {
            if (response != null && response.data != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }
        }).catch(() => {
        });
        return result;
    }

    luuVaCapNhat = async (capPhep: CapSoGiayPhepLGPChaiDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api.post('/giay-phep-kinh-doanh-lpgchai/add-or-update-cap-so', JSON.stringify(capPhep), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_code == 'DUPLICATE_CODE' ? 'Số giấy phép đã được sử dụng' : 'Yêu cầu thực hiện thất bại'
            }
        }).catch(() => {
            result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' }
        });
        return result;
    }

    getGiayPhepBySoGP = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/get-by-giay-phep', requestBody);
    }

    getListGiayPhep = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh-lpgchai/danh-sach-giay-phep-all', requestBody);
    }
    
    getLanThayDoi = async (giayPhepId: Number, lanThayDoi: String, maHoSo: String, maBienDong : String): Promise<any> => {
        let result = {} as any;
        await api.post(`/giay-phep-kinh-doanh-lpgchai/get-lan-thay-doi`, { giayPhepId, lanThayDoi, maHoSo, maBienDong}).then((response) => {
            result = response.data.data;
        });

        return result;
    };
}
