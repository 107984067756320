import React, { useEffect, useState } from "react";
import { Box, NativeSelect } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import { CustomInput } from "../../../commons/components/input/customInput";
import { ThaoTacComponent } from './thaoTacComponent';
import { Link } from "react-router-dom";
import moment from "moment";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface IDataListProps {
    dataList: any[];
    deleteItem?: (id: string) => void;
    pageProps: TablePaginationActionsProps;
}

export const TableGiayPhepLPGChai = (props: IDataListProps) => {

    const [rows, setRows] = useState<any[]>([]);
    const [totalPage, setTotalPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1);

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.page);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Số giấy phép</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Ngày cấp</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Ngày hết hạn</Cell>
                        <Cell component="th" scope="row" align="center">Tên thương nhân</Cell>
                        <Cell component="th" scope="row" align="center">Địa chỉ</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Điện thoại</Cell>
                        <Cell component="th" scope="row" align="center" width="15%">Tình trạng</Cell>
                        <Cell component="th" scope="row" align="center" width="5%">Thao tác</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {((props.pageProps.page -1) * props.pageProps.rowsPerPage) + i + 1}
                                    </Cell>
                                    <Cell align="center">
                                        <Link to={{
                                            pathname: '/giay-chung-nhan-lpgchai/chi-tiet',
                                            state: { ...row, giayPhepId: row?.id }
                                        }}>
                                            {row.soGiayPhep}
                                        </Link>
                                    </Cell>
                                    <Cell align="center">{(row.ngayCapPhep !== 0 && row.ngayCapPhep !== null)  ? moment(row.ngayCapPhep).format("DD/MM/YYYY") : ''}</Cell>
                                    <Cell align="center">{(row.ngayHetHanGP !== 0 && row.ngayHetHanGP !== null)  ? moment(row.ngayHetHanGP).format("DD/MM/YYYY") : ''}</Cell>
                                    <Cell align="left">{row.tenThuongNhan}</Cell>
                                    <Cell align="left">{row.diaChiDayDu}</Cell>
                                    <Cell align="left">{row.dienThoai}</Cell>
                                    <Cell align="center">{row.tenTinhTrang}</Cell>
                                    <Cell align="center">
                                        <ThaoTacComponent row={row}/>
                                    </Cell>
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={9} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            {rows.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined" page={props.pageProps.page}
                                size="medium"
                                color="primary" shape="rounded" showFirstButton showLastButton onChange={props.pageProps.onChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {((props.pageProps.page -1) * props.pageProps.rowsPerPage) + 1} - {props.pageProps.rowsPerPage * rowsPerPage > props.pageProps.count ? props.pageProps.count : (props.pageProps.rowsPerPage * rowsPerPage)} / {props.pageProps.count}</Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
