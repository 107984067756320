import { LichSuGiayPhepDTO } from "../model/CommonModel";

export const LichSuGiayPhepDTO_INIT: LichSuGiayPhepDTO = {
    id: 0,

    ngayCapNhat: 0,

    ngayTao: 0,

    maBienDong: '',

    tenBienDong: '',

    maTinhTrang: '',

    tenTinhTrang: '',

    ngayBienDong: 0,

    soQuyetDinh: '',

    ngayQuyetDinh: 0,

    soGiayPhep: '',

    giayPhepId: 0,

    hoSoThuLyId: 0,

    hoSoThuLyCuId: 0,

    soLan: 0,

    noiDung: '',

    ghiChu: '',

    maQuanHuyen: '',

    tenQuanHuyen: '',

    maPhuongXa: '',

    tenPhuongXa: '',

    tenTinhThanh: '',

    maTinhThanh: '',

    hoatDong: true,
    files: []
}
