import { BaseService, PageRequest, Pagination } from "../../baseService";
import { GiayPhepATTP, RequestDTO } from "../Model/VSATTPModel";
import AXIOS_INSTANCE from "../../index";


export class VSATTPService extends BaseService {
    getGiayPhepVSTTP = (maHoSo: any) => {
        return AXIOS_INSTANCE.get(`vsattp/cap-so-giay-phep/chi-tiet?maHoSo=${maHoSo}`);
    }
    getByHoSoId = (hoSoId: number) => {
        return AXIOS_INSTANCE.get(`vsattp/cap-so-giay-phep/chi-tiet-by-ho-so-id?hoSoId=${hoSoId}`);
    }

    insertOrUpdateGiayPhepVSTTP = (vsattpData: any) => {
        return AXIOS_INSTANCE.post(`vsattp/cap-so-giay-phep/them-moi`, vsattpData);
    }


    loadDanhSach = async (request: RequestDTO): Promise<Pagination<GiayPhepATTP[]>> => {
        let result: Pagination<GiayPhepATTP[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }

        await AXIOS_INSTANCE.post('/giay-phep-kinh-doanh/danh-sach-quan-ly', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data && response.data.data && response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    loadLoaiBienDong(maTrangThai: string) {
        return AXIOS_INSTANCE.get('/danh-muc/danh-sach-bien-dong-theo-tinh-trang?maTinhTrang='+maTrangThai);
    }

    loadDanhMucTinhTrang() {
        return AXIOS_INSTANCE.get('/danh-muc/tinh-trang');
    }

    downloadFile = (request: RequestDTO) => {
        return AXIOS_INSTANCE.get('vsattp/cap-so-giay-phep/download-danh-sach-tra-cuu', {
            responseType: 'blob',
            params: request
        });
    }
    downloadFileGiayPhep(request: any) {
        return AXIOS_INSTANCE.post('vsattp/cap-so-giay-phep/download-giay-phep', request, {
            responseType: 'blob',
        });
    }
    searchDSGCNATTP(request: any) {
        return AXIOS_INSTANCE.post('vsattp/cap-so-giay-phep/search-danh-sach-giay-chung-nhan-attp', request);
    }
    getGiayPhepATTPById = (id: number) => {
        return AXIOS_INSTANCE.get('vsattp/cap-so-giay-phep/chi-tiet-giay-phep-attp/' + id);
    }
}