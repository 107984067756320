import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { BrowserHistory } from 'history';
import { ConfirmationDialogRaw } from "../../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../../commons/components/tabs";
import CapMoiSXRuouNoiDungComponent from "./CapDoiSXRuouNoiDungComponent";
import { HoSoThuLyBLR, TinhTrang } from "../../../../../../api/CPBLR/model/HoSoThuLyBLR";
import { CAP_MOI_SX_RUOU_INIT } from "../../../../../../api/CPBLR/Init/CapMoiSXRuouInit";
import StringUtil from "../../../../../utils/stringUtils";
import { CapMoiSXRuouService } from "../../../../../../api/CPBLR/CapMoiSXRuouService";
import DangKySanXuatRuouButtonComponent from "../../Action/DangKySanXuatRuouButtonComponent";
import { BieuMauAttachFileDTO } from "../../../../../../api/QuanTri/Model/danhMucBieuMau";
import { createFileType, downLoadFile } from "../../../../../../api/Common/exportUtil";
import { AlertMessage, CustomAlert } from "../../../../../commons/components/alert";
import CapDoiSXRuouNoiDungComponent from './CapDoiSXRuouNoiDungComponent';
import { LOAIBIENDONG_CAPDOI, TINHTRANG_HOATDONG } from '../../../../../Constants/RuouConstants';
import { OverlayLoading } from '../../../../../commons/components/overlayLoading/overlayLoading';

type StateHoSo = {
    maThuTuc: string,
    maHoSo: string
}
type CapDoiSXRuouProps = {
    history: BrowserHistory<StateHoSo>
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const CapDoiSXRuouComponent = (props: CapDoiSXRuouProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = React.useState({} as HoSoThuLyBLR);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);

    const capMoiSXRuouService = new CapMoiSXRuouService();
    const { history } = props;

    useEffect(() => {
        initData();
    }, [])

    const initData = () => {
        capMoiSXRuouService.getByMaHoSo({ maHoSo: history?.location?.state?.maHoSo }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let resData: HoSoThuLyBLR = {
                    ...res?.data?.data,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                };
                setData(resData);
            } else {
                const dataNew: HoSoThuLyBLR = {
                    ...CAP_MOI_SX_RUOU_INIT,
                    loaiGiayPhep: 'SXR',
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    maQuanTruSo: '',
                    tenQuanTruSo: '',
                    maQuanSX: '',
                    tenQuanSX: ''
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }

    const resetData = () => {
        const dataNew: HoSoThuLyBLR = {
            ...CAP_MOI_SX_RUOU_INIT,
            id: data.id,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc,
            maBienDong: LOAIBIENDONG_CAPDOI.ma,
            tenBienDong: LOAIBIENDONG_CAPDOI.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten,
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan)
            || StringUtil.isNullOrEmty(data.maQuanSX)
            || StringUtil.isNullOrEmty(data.maPhuongSX)
            || StringUtil.isNullOrEmty(data.maQuanTruSo)
            || StringUtil.isNullOrEmty(data.maPhuongTruSo)
            || StringUtil.isNullOrEmty(data.giayCNDK)
            || StringUtil.isNullOrEmty(data.lyDoSuaDoi)) {
            setTabRequired(-0);
            return;
        }
        if (data?.donViCapPhep) {
            if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
                setTabRequired(-0);
                return;
            }
        }
        setSubmit(false);
        capMoiSXRuouService.insertOrUpdate(data).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL') {
                    setDataParam('data', { ...data, id: res.data.data.id, giayPhepId: res.data.data.giayPhepId });
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else {
                    showMessageAlert(res.data.error_message, false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        capMoiSXRuouService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            if (res && res.data) {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
            }
        });
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const loadGiayPhepDaCap = (soGiayPhep: string) => {
        capMoiSXRuouService.getBySoGiayPhep_LoaiGiayPhep({ soGiayPhep: soGiayPhep, loaiGiayPhep: 'SXR' }).then(res => {
            let dataNew = res?.data.data;
            if (dataNew !== null && dataNew !== undefined) {
                setData({
                    ...dataNew,
                    id: data.id,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    donViCapPhep: false,
                    maBienDong: LOAIBIENDONG_CAPDOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    lyDo: data.lyDo,
                    soLan: dataNew.soLan + 1,
                    ngayYeuCau: data.ngayYeuCau,
                    files: data.files
                });
            } else {
                setAlertMessage({ open: true, content: "Số giấy phép không tồn tại trong dữ liệu", severity: 'warning' });
            }

        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <>
            {
                <Paper square>
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    CẤP SỬA ĐỔI, BỔ SUNG GIẤY PHÉP SẢN XUẤT RƯỢU THỦ CÔNG NHẰM MỤC ĐÍCH KINH DOANH {"- MÃ HỒ SƠ :  " + data.maHoSo} 
                                </div>
                            </div>
                                {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange}>
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                </CustomTabs>
                            </Box>

                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <CapDoiSXRuouNoiDungComponent
                                    onUpdateData={setDataParam}
                                    loadGiayPhepDaCap={loadGiayPhepDaCap}
                                    submit={submit}
                                    data={data} />
                            </TabPanel>
                        }

                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <DangKySanXuatRuouButtonComponent
                                showIn={data.id > 0}
                                onSubmit={handleSubmit}
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onDelete={handleDelete} />
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    );

};
export default CapDoiSXRuouComponent;