import {BaseResponse, BaseService} from "../baseService";
import api from "../index";
import {GiayPhepKinhDoanhDTO} from "./model/giayPhepKinhDoanhDTO";
import {ThanhVienGopVonDTO} from "./model/thanhVienGopVonDTO";
import {FileEntry} from "../Common/fileEntry";
import {BienDongTrangThaiGPKDDTO} from "./model/BienDongTrangThaiGPKDDTO";
import {HoSoThuLyDTO} from "./model/hoSoThuLyDTO";

export interface BienDongTrangThaiGPKDResponse {
    giayPhepKinhDoanh: HoSoThuLyDTO;
    giayPhepKinhDoanhPhucHoi: BienDongTrangThaiGPKDDTO;
    thanhVienGopVonDTOs: ThanhVienGopVonDTO[];
    fileEntryDKKDs: FileEntry[];
}

export class BienDongTrangThaiGPKDService extends BaseService{
    luuVaCapNhatKhoiPhuc = async (khoiPhuc: BienDongTrangThaiGPKDDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await api.post('/dkkd/giay-phep-kinh-doanh/bien-dong-trang-thai/add-or-update', JSON.stringify(khoiPhuc), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message,
                data: response.data.data
            }
        }).catch(() => {
            result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' }
        });
        return result;
    }

    getGiayPhepKinhDoanhId = async (json: any): Promise<HoSoThuLyDTO> => {
        let result = {} as HoSoThuLyDTO;
        await api.post('/dkkd/giay-phep-kinh-doanh/bien-dong-trang-thai/get-by-giay-phep-id', json, this.getTokenRequestHeaders()).then(response => {
            if (response != null && response.data != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }
        }).catch(() => {
        });
        return result;
    }

    downloadFile(request: any) {
        return api.post('/dkkd/giay-phep-kinh-doanh/download-thu-hoi', request, {
            responseType: 'blob',
        });
    }
}
