import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, Grid, InputBase, InputLabel, TextField, InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { HoSoThuLyLPGChaiDTO } from "../../../../../api/LPGChai/model/HoSoThuLyLPGChai";
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { IFileWithMeta } from 'react-dropzone-uploader';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import StringUtil from '../../../../utils/stringUtils';
import MomentUtils from "@date-io/moment";
import { Autocomplete } from '@material-ui/lab';
import { ACCEPT_FILES } from '../../../../../App/Constants/constants';
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../api/danhMuc/model/DanhMucDTO";
import AXIOS_INSTANCE from "../../../../../api";
import { LIST_TINHTRANG } from '../../../../Constants/LGPChaiConstants';
import SearchIcon from "@material-ui/icons/Search";
import { AlertMessage } from '../../../../commons/components/alert';
import DateUtil from '../../../../utils/dateUtils';
import { getObject } from '../../../../../helpers/localStorage';

interface NhapDauKyLPGChaiProps {
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
    data: HoSoThuLyLPGChaiDTO;
    onSeachDoanhNghiep: (name: string) => void;
}

export const ThongTinChungComponent = (props: NhapDauKyLPGChaiProps) => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const { onUpdateData, submit, onSeachDoanhNghiep } = props;
    const [data, setData] = useState({} as HoSoThuLyLPGChaiDTO);
    const [dataQuanHuyen, setDataQuanHuyen] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataXaPhuong, setDataXaPhuong] = useState<DanhMucDiaChiDTO[]>([]);
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const fileServerService = new FileServerService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);


    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
    }, [props.data, props.onUpdateData]);

    useEffect(() => {
        async function getDm() {
            await new DanhMucService().getDanhMucDiaChi(String(92)).then((resp) => {
                setDataQuanHuyen(resp);
            });
        }
        getDm();
    }, []);


    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    async function handleChangeQuanHuyen(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText, maPhuongXa: '', tenPhuongXa: '' };
        onUpdateData("data", dataNew);
    }

    async function handleChangePhuongXa(value: any, nameValue: string, nameText: string) {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function handleChangeDuong(value: any, nameValue: string, nameText: string) {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }

    async function onBlurPhuongXa() {
        if (C_USER_DATA.maQuanHuyen) {
            await new DanhMucService().getDanhMucDiaChi(C_USER_DATA.maQuanHuyen).then((resp) => {
                setDataXaPhuong(resp);
            });
        }
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }
    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let files = data?.files && data?.files != null ? data?.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            files: mems
        };
        onUpdateData("data", dataNew);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let files = data?.files && data?.files != null ? data?.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            files: mems,
        };
        setAttachFileServer(mems);
        onUpdateData("data", dataNew);
    }

    const handleDownloadFile = (file: FileEntry) => {
      
        try{
            if (file.motCua && file.url){
                let link = document.createElement("a");
                link.href = file?.url ? file?.url : "";
                link.setAttribute("download", `${file.name}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(file?.url ? file?.url : "");
            }else{
                fileServerService.downloadFileMethodGet(file).then((res) => {
                    console.log(res);
                    downLoadFile(res.data, createFileType(file.extension), file.name);
                });
            }
        }catch(e){
            return;
        }
       
    }


    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleSearchDoanhNghiep = (maSoDoanhNghiep: string) => {
        onSeachDoanhNghiep(maSoDoanhNghiep);
    }

    return (
        <>
            {
                <Box padding={0}>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel required>Số giấy chứng nhận LPG chai</InputLabel>
                            <InputBase fullWidth name="soGiayPhep" value={data.soGiayPhep} onChange={handleChange} />
                            {
                                submit && StringUtil.isNullOrEmty(data.soGiayPhep) &&
                                <FormHelperText className="-error">Vui lòng nhập số giấy phép</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel required>Ngày cấp</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={(data.ngayCapPhep !== undefined && data.ngayCapPhep > 0) ? new Date(data.ngayCapPhep) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline" InputLabelProps={{}}
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}
                                />
                            </MuiPickersUtilsProvider>
                            {
                                submit && (data.ngayCapPhep === 0 || data.ngayCapPhep === undefined) &&
                                <FormHelperText className="-error">Vui lòng nhập ngày cấp</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel>Ngày hết hạn</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={(data.ngayHetHanGP !== undefined && data.ngayHetHanGP > 0) ? new Date(data.ngayHetHanGP) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline" InputLabelProps={{}}
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel required>Số Giấy CNĐKDN/HKD</InputLabel>
                            <InputBase fullWidth name="soGiayCNDKKD" value={data.soGiayCNDKKD} onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {" "}
                                        <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "10px", marginLeft: "3px" }} onClick={(e) => handleSearchDoanhNghiep(data.soGiayCNDKKD)} />
                                    </InputAdornment>
                                }

                            />
                            {
                                submit && StringUtil.isNullOrEmty(data?.soGiayCNDKKD) &&
                                <FormHelperText className="-error">Vui lòng nhập số giấy CNĐKDN/HKD</FormHelperText>
                            }

                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel required>Tên thương nhân</InputLabel>
                            <InputBase fullWidth name="tenThuongNhan" value={data.tenThuongNhan} onChange={handleChange} />
                            {
                                submit && StringUtil.isNullOrEmty(data?.tenThuongNhan) &&
                                <FormHelperText className="-error">Vui lòng nhập tên thương nhân</FormHelperText>
                            }
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel required>Huyện/Thị/TP </InputLabel>
                            <InputBase fullWidth readOnly disabled
                                value={C_USER_DATA.tenQuanHuyen} name="maQuanHuyen" />
                            {/* <Autocomplete id="maHuyen"
                                options={dataQuanHuyen}
                                value={{ code: data?.maQuanHuyen ? data?.maQuanHuyen : "", name: data?.tenQuanHuyen ? data?.tenQuanHuyen : "" }}
                                getOptionLabel={(option) => `${option.name}`}
                                onChange={(event: any, newValue: any) => handleChangeQuanHuyen(newValue, 'maQuanHuyen', 'tenQuanHuyen')}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth
                                            error={submit && StringUtil.isNullOrEmty(data?.maQuanHuyen)}
                                            {...params.inputProps}

                                        />
                                    </div>
                                }}
                            /> */}
                            {
                                submit && StringUtil.isNullOrEmty(data?.maQuanHuyen) &&
                                <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item" >
                            <InputLabel required>Phường/Xã/TT </InputLabel>
                            <Autocomplete id="maPhuong"
                                options={dataXaPhuong}
                                onFocus={onBlurPhuongXa}
                                value={{ code: data?.maPhuongXa ? data?.maPhuongXa : "", name: data?.tenPhuongXa ? data?.tenPhuongXa : "" }}
                                getOptionLabel={(option) => `${option.name}`}
                                onChange={(event: any, newValue: any) => handleChangePhuongXa(newValue, 'maPhuongXa', 'tenPhuongXa')}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth
                                            error={submit && StringUtil.isNullOrEmty(data?.maPhuongXa)}
                                            {...params.inputProps}

                                        />
                                    </div>
                                }}
                            />
                            {
                                submit && StringUtil.isNullOrEmty(data?.maPhuongXa) &&
                                <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Đường </InputLabel>
                            <InputBase fullWidth value={data.tenDuong} name="tenDuong" onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Số nhà</InputLabel>
                            <InputBase fullWidth name="soNha" value={data.soNha} onChange={handleChange} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Điện thoại</InputLabel>
                            <InputBase fullWidth name="soDienThoai" value={data.soDienThoai} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Fax</InputLabel>
                            <InputBase fullWidth name="fax" value={data.fax} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Ngày cấp</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={(data?.ngayCapCNDKKD !== undefined && data?.ngayCapCNDKKD > 0) ? new Date(data?.ngayCapCNDKKD) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline" InputLabelProps={{}}
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapCNDKKD')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Nơi cấp Giấy CNĐKDN/HKD</InputLabel>
                            <InputBase fullWidth name="noiCapCNDKKD" value={data.noiCapCNDKKD} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Mã số thuế</InputLabel>
                            <InputBase fullWidth name="maSoThue" value={data.maSoThue} onChange={handleChange} />
                            {/* {
                                submit && StringUtil.isNullOrEmty(data?.maSoThue) &&
                                <FormHelperText className="-error">Vui lòng nhập mã số thuế</FormHelperText>
                            } */}
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>Tình trạng</InputLabel>
                            <Autocomplete id="maTinhTrang"
                                options={LIST_TINHTRANG}
                                value={{
                                    ma: data.maTinhTrang ? data.maTinhTrang : '',
                                    ten: data.tenTinhTrang ? data.tenTinhTrang : ''
                                }}
                                getOptionLabel={(option) => option?.ten}
                                onChange={(event: any, newValue: any) => handleChangeSelect(newValue, 'maTinhTrang', 'tenTinhTrang')}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref}>
                                        <TextField {...params} variant="outlined" />
                                    </div>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container className="grid-item">
                        <DropZoneCustom
                            acceptFiles={ACCEPT_FILES}
                            attachFileServer={attachFileServer}
                            url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/lpg"}
                            handleChangeStatus={handleChangeStatus}
                            handleDeleteFileServer={handleDeleteFileServer}
                            handleDownloadFile={handleDownloadFile}
                        />
                    </Grid>
                </Box>
            }
        </>
    )
};
