import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ListIcon from '@material-ui/icons/List';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { GiayPhepSXRuou } from "../../../../api/CPBLR/model/HoSoThuLyBLR";
import { RoutePropsI } from "../../../../routes";
import { ROUTE_DAUKY_BL, ROUTE_DAUKY_SX, ROUTE_HOANTRA_HUY_GP_RUOU, ROUTE_HOANTRA_THUHOI_GP_RUOU, ROUTE_HUY_GP_RUOU, ROUTE_THUHOI_GP_RUOU } from "../../../../routes/route-cn-ruou";
import { LOAIBIENDONG_HUY, TINHTRANG_HOATDONG, TINHTRANG_HUY, TINHTRANG_THUHOI } from "../../../Constants/RuouConstants";
export interface ThaoTacQuanLyKDRuouProps {
    row: GiayPhepSXRuou;
}

export const ThaoTacQuanLyKDRuou = (props: ThaoTacQuanLyKDRuouProps) => {

    const { row } = props;
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const [thaoTacs, setThaoTacs] = useState<RoutePropsI[]>([]);
    let history = useHistory();

    const showHidePopperThaoTac = async (event: React.MouseEvent<HTMLButtonElement>, maTinhTrang: string) => {
        const thaoTacNews = [];
        if (maTinhTrang === TINHTRANG_HOATDONG.ma) {
            thaoTacNews.push(ROUTE_THUHOI_GP_RUOU);
            thaoTacNews.push(ROUTE_HUY_GP_RUOU);
        } else if (maTinhTrang === TINHTRANG_HUY.ma) {
            thaoTacNews.push(ROUTE_HOANTRA_HUY_GP_RUOU);
        } else if (maTinhTrang === TINHTRANG_THUHOI.ma) {
            thaoTacNews.push(ROUTE_HOANTRA_THUHOI_GP_RUOU);
        }
        setThaoTacs(thaoTacNews);
        setOpen(!open);
    }
    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const clickEdit = (isChinhSua: boolean) => {
        if (isChinhSua) {
            let duongDan = row?.loaiGiayPhep == 'BLR' ? ROUTE_DAUKY_BL.duongDan : ROUTE_DAUKY_SX.duongDan;
            history.push(duongDan, row);
        } else {
            history.push(ROUTE_HUY_GP_RUOU.duongDan, { item: row, isChinhSuaHuy: true });

        }
    }

    const clickThuHoi = (item: RoutePropsI) => {
        let duongDan = item.duongDan;
        history.push(duongDan, row);
    }
    return (
        <>
            <Button startIcon={<ListIcon />}
                size="small" color="primary" disableRipple
                ref={anchorRef} aria-haspopup="true"
                aria-controls={open ? 'menu-list-grow' : undefined}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => showHidePopperThaoTac(e, row.maTinhTrang)} >
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                    <MenuItem style={{ padding: 'unset' }} >
                                        <Button onClick={(e) => clickEdit(true)} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                            Chỉnh sửa
                                        </Button>
                                    </MenuItem>
                                    {
                                        props.row.maTinhTrang === TINHTRANG_HUY.ma &&
                                        (
                                            <MenuItem style={{ padding: 'unset' }} >
                                                <Button onClick={(e) => clickEdit(false)} startIcon={<EditIcon color="error" />} size="small" disableRipple>
                                                    Cập nhật hủy
                                                </Button>
                                            </MenuItem>

                                        )
                                    }
                                    {
                                        thaoTacs.map((item, index) => {
                                            return (
                                                <MenuItem style={{ padding: 'unset' }} >
                                                    <Button onClick={(e) => clickThuHoi(item)} startIcon={ROUTE_THUHOI_GP_RUOU.duongDan === item.duongDan ? <RefreshIcon color="error" /> : <HighlightOffIcon color="error" />} size="small" disableRipple>
                                                        {item.ten}
                                                    </Button>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
