import {
    Grid,
    InputLabel
} from '@material-ui/core';
import moment from "moment";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { CAP_MOI_SX_RUOU_INIT } from '../../../../../api/CPBLR/Init/CapMoiSXRuouInit';
import { GiayPhepSXRuou, HoSoThuLyBLR } from '../../../../../api/CPBLR/model/HoSoThuLyBLR';
import TableDiaDiemBLRuouComponent from '../../ThuLyHoSo/SanXuat/Table/TableDiaDiemBLRuouComponent';
import TablePhanPhoiRuouComponent from '../../ThuLyHoSo/SanXuat/Table/TablePhanPhoiRuouComponent';
import TableSanXuatRuouComponent from '../../ThuLyHoSo/SanXuat/Table/TableSanXuatRuouComponent';
interface ChiTietThongTinGPRuouProps {
    // data: GiayPhepSXRuou;
    hoSo: HoSoThuLyBLR;
}
const ChiTietThongTinGPRuou = (props: ChiTietThongTinGPRuouProps) => {
    const [hoSo, setHoSo] = useState<HoSoThuLyBLR>({ ...CAP_MOI_SX_RUOU_INIT });
    useEffect(() => {
        // setData(props.data);
        setHoSo(props.hoSo);
    }, [ props.hoSo]);
    return (
        <>
            {
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel >Số giấy phép</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo?.soGiayPhep}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo?.ngayCapPhep ? moment(hoSo?.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Tên thương nhân</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{hoSo.tenThuongNhan}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Địa chỉ trụ sở chính</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{hoSo.diaChiDayDu}</InputLabel>
                    </Grid>

                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Điện thoại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo.dienThoaiTruSo}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Fax</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo.faxTruSo}</InputLabel>
                    </Grid>

                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Số Giấy CNĐKDN/CNĐKKD</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo?.giayCNDK}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Ngày cấp lần đầu</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo?.ngayCap ? moment(hoSo?.ngayCap).format("DD/MM/YYYY") : ''}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Nơi cấp</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel className="detail-label">{hoSo?.noiCap}</InputLabel>
                    </Grid>
                    {
                        hoSo.loaiGiayPhep === 'SXR' &&
                        <>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel > Địa chỉ sản xuất/kinh doanh</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item">
                                <InputLabel className="detail-label">{hoSo?.diaChiDayDuSX}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel >Điện thoại của điểm SX/KD</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel className="detail-label">{hoSo?.dienThoaiSX}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel > Fax của địa điểm SX/KD</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel className="detail-label">{hoSo?.faxSX}</InputLabel>
                            </Grid>
                        </>
                    }
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Tình trạng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo?.tenTinhTrang}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel > Lần cấp lại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel className="detail-label">{hoSo.soLan}</InputLabel>
                    </Grid>
                    <Grid item xs={12} className="grid-item" hidden={hoSo.loaiGiayPhep === 'BLR'}>
                        <InputLabel >Thông tin sản xuất rượu </InputLabel>
                    </Grid>
                    <Grid item xs={12} className="grid-item" hidden={hoSo.loaiGiayPhep === 'BLR'} >
                        <TableSanXuatRuouComponent view={true} data={hoSo.sanXuatRuous} />
                    </Grid>

                    <Grid item xs={12} className="grid-item" >
                        <InputLabel >Thông tin phân phối rượu </InputLabel>
                    </Grid>
                    <Grid item xs={12} className="grid-item" >
                        <TablePhanPhoiRuouComponent view={true} data={hoSo.phanPhoiRuous} />
                    </Grid>

                    <Grid item xs={12} className="grid-item" >
                        <InputLabel >Thông tin địa điểm bán lẻ rượu </InputLabel>
                    </Grid>
                    <Grid item xs={12} className="grid-item" >
                        <TableDiaDiemBLRuouComponent view={true} data={hoSo.diaDiemBLRuous} />
                    </Grid>
                </Grid>
            }
        </>
    )
}
export default ChiTietThongTinGPRuou;