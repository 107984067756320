import AXIOS_INSTANCE from "..";
import { DanhMucGP, SearchRequest } from "../Common/model/CommonModel";
export class LinhVucCapSoGPService {
   constructor() { }
   search = (request: SearchRequest) => {
      return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-cap-so-gp/search', request);
   }
   getByHoatDong = (hoatDong: boolean) => {
      return AXIOS_INSTANCE.get('dkkd/dm-linh-vuc-cap-so-gp/get-by-hoat-dong/' + hoatDong);
   }
   addOrUpdate = (request: DanhMucGP) => {
      return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-cap-so-gp/addOrUpdate', request);
   }

   getGPTuDong = (maCoQuan: string, chuyenNganh: string) => {
      return AXIOS_INSTANCE.post('dkkd/dm-linh-vuc-cap-so-gp/get-so-giay-phep', { maCoQuan: maCoQuan, chuyenNganh: chuyenNganh });
   }
}
