import AXIOS_INSTANCE from "..";
import { BaseService, Pagination } from "../baseService";
import { BaoCaoThongKeGPRuouRequest } from "./model/BaoCaoThongKeGPRuouRequest";
import { ThongKeGiayPhepRuouDTO } from "./model/ThongKeGiayPhepRuouDTO";

export class ThongKeRuouService extends BaseService {

    loadDanhSach = async (request: BaoCaoThongKeGPRuouRequest): Promise<Pagination<ThongKeGiayPhepRuouDTO[]>> => {
        let result: Pagination<ThongKeGiayPhepRuouDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('ruou/thong-ke/search', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    loadChiTiet = async (request: BaoCaoThongKeGPRuouRequest): Promise<Pagination<ThongKeGiayPhepRuouDTO[]>> => {
        let result: Pagination<ThongKeGiayPhepRuouDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('ruou/thong-ke/search/chi-tiet', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    getSum = async (request: BaoCaoThongKeGPRuouRequest): Promise<ThongKeGiayPhepRuouDTO> => {
        let result: ThongKeGiayPhepRuouDTO = {
            ten: 'TỔNG',
            capMoi: 0,
            capLai: 0,
            thuHoi: 0,
            huy: 0,
            hoanTraHuy: 0,
            hoanTraThuHoi: 0,
            capDoi: 0,
            hetHan: 0
        }
        await AXIOS_INSTANCE.post('ruou/thong-ke/sum', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data) {
                result = {
                    ...response.data.data,
                }
            }
        }).catch();
        return result;
    }
    exportThongKeGiayPhep = (request: BaoCaoThongKeGPRuouRequest) => {
        return AXIOS_INSTANCE.get('ruou/thong-ke/export', {
            responseType: 'blob',
            params: request
        });

    }

    thongKeBieuDoTron = (requestBody : BaoCaoThongKeGPRuouRequest) => {
        return AXIOS_INSTANCE.post('ruou/thong-ke/bieu-do-tron',requestBody);
    }
}
