import { BaseService, Pagination } from "../baseService";
import { RequestBaoCaoThongKeDTO } from "../../App/Components/DKKD/BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import { GiayPhepSXRuou, HoSoThuLyBLR, HoSoThuLySearch } from "./model/HoSoThuLyBLR";
import AXIOS_INSTANCE from "..";
import { LichSuGiayPhepRuouDTO } from "./model/LichSuGiayPhepRuouDTO";

export class CapSoDKRuouService extends BaseService {

    loadDanhSach = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<GiayPhepSXRuou[]>> => {
        let result: Pagination<GiayPhepSXRuou[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('ruou/giay-phep/danh-sach-quan-ly', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }
    getByGiayPhepId = (giayPhepId: number, pageNumber: number, pageSize: number) => {
        return AXIOS_INSTANCE.post('ruou/giay-phep/lich-su-by-giay-phep-id', { giayPhepId: giayPhepId, pageNumber: pageNumber, pageSize: pageSize }, this.getTokenRequestHeaders());

    }
    capNhatTinhTrangGiayPhep = (lichSuGiayPhepRuouDTO: LichSuGiayPhepRuouDTO) => {
        return AXIOS_INSTANCE.post('ruou/giay-phep/cap-nhap-tinh-trang-giay-phep', lichSuGiayPhepRuouDTO, this.getTokenRequestHeaders());

    }
    capSo = (request: HoSoThuLyBLR) => {
        return AXIOS_INSTANCE.post('ruou/cap-so/add-or-update', request, this.getTokenRequestHeaders());
    }
    danhSachCapSo = (request: HoSoThuLySearch) => {
        return AXIOS_INSTANCE.post('ruou/cap-so/danh-sach-cap-so', request, this.getTokenRequestHeaders());
    }
    danhSachFileCapSo = (giayPhepId: number) => {
        return AXIOS_INSTANCE.post('ruou/cap-so/danh-sach-file-cap-so', { giayPhepId: giayPhepId }, this.getTokenRequestHeaders());
    }
    downloadFileBaoCao = (request: RequestBaoCaoThongKeDTO) => {
        return AXIOS_INSTANCE.get('ruou/giay-phep/export-danh-sach-giay-phep', {
            responseType: 'blob',
            params: request
        });

    }
    danhSachCapSo2 = async (request: HoSoThuLySearch): Promise<Pagination<HoSoThuLyBLR[]>> => {
        let result: Pagination<HoSoThuLyBLR[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('ruou/cap-so/danh-sach-cap-so', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data && response.data.data.total > 0) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }
}
