import { FileEntry } from "../../Common/fileEntry";
import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { SearchRequest } from "../../Common/model/CommonModel";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";

export interface VSATTPModelRequest {
    maHoSo: string;
}
export interface RequestDTO extends PageRequest {
    soGiayPhep: string;
    chuCoSo: string;
    maQuan: string;
    tenQuan: string;
    maPhuong: string;
    tenPhuong : string;
    ngayCapPhepTu:number;
    ngayCapPhepDen: number;
    ngayHetHanTu:number;
    ngayHetHanDen: number;
    trangThai: string;
    tenTrangThai: string;
    exportType?: string;
    exportFileName?: string;
}
export interface PageRequest {
    pageSize: number;
    pageNumber: number;
    keywords?: string;
    active?: number;
}

export interface GiayPhepATTP extends SearchRequest {
    id: number;
    hoSoThuLyId : number;
    maHoSo: string;
    maThuTuc: string;
    soGiayPhep: string;
    ngayCapPhep: number;
    ngayCapPhepTu:number;
    ngayCapPhepDen: number;
    ngayHetHan: number;
    chuCoSo: string;
    tenCoSo: string;
    dienThoai: string;
    diaChiDayDu: string;
    fax: string;
    maSoGiayCNDKKDHKD: string;
    ngayCapCNDKKDHKD: string;
    noiCapCNDKKDHKD: string;
    tenPhuongXaTTDSXKD:string;
    dienThoaiDSXKD:string;
    faxDSXKD:string;
    quyMoKinhDoanhDSXKD:string;
    soLuongNguoiLaoDongDSXKD:string;
    soLuongLaoDongTrucTiepDSXKD:string;
    soLuongLaoDongGianTiepDSXKD: string;
    duDieuKienAnToanThucPham: string;
    nguoiThamDinh: string;
    ngayThamDinh: number;
    coSoChuaDuDieuKienATTP: boolean;
    ketQuaThamDinh: string;
    maTrangThai: string;
    maLoaiGiayPhep: string;
    tenDonViCapPhep: string;
    maDonViCapPhep: number;
    tenTrangThai: string;
    maBienDong: string;
    tenBienDong: string;
    hoSoThuLyATTPDTO:HoSoThuLyATTPDTO;
    lichSuGiayPhepATTPDTOs: LichSuGiayPhepATTPDTO[];
    files: FileEntry[];
    lichSuDTO:  LichSuGiayPhepATTPDTO;
    maMauTrangThai : Number;

}
export interface LichSuGiayPhepATTPDTO {
    id: number;
    giayPhepId: number;
    hoSoId: number;
    maDonViCapPhep: string;
    tenDonViCapPhep: string;
    maHuyen: string;
    tenHuyen: string;
    maloaibiendong: string;
    tenLoaiBienDong: string;
    maPhuong: string;
    tenPhuong: string;
    noiDung: string;
    lyDoTamNgung: string;
    lyDoHTTamNgung: string;
    maTrangThai: string;
    tenTrangThai: string;
    ngayCapNhat: string;
    ngayKhoiTao: string;
    ghiChu: string;
    ngayBienDong:number;
    ngayThongKe: string;

}
export interface HoSoThuLyATTPDTO {
    id:number;
    
    giayPhepId:number;
    
    maHoSo: string;

    maCoQuan: string;

    ngayHenTra: number;
    
    maThuTuc: string;
    
    soGiayPhep: string;
    
    ngayCapPhep:number;
    
    ngayHetHan:number;
    
    tenChuCoSo: string;
    
    chuCoSo: string;
    
    tenCoSo: string;
    
    conHan:number;
    
    hetHan:number;
    
    maTinhTp: string;
    
    tenTinhTp: string;
    
    maHuyenThiTp: string;
    
    tenHuyenThiTp: string;
    
    maPhuongXaTT: string;
    
    tenPhuongXaTT: string;
    
    maDuong: string;
    
    tenDuong: string;
    
    soNha: string;

    khuVucAp: string;
    
    dienThoai: string;
    
    fax: string;
    
    maSoGiayCNDKKDHKD: string;
    
    tenSoGiayCNDKKDHKD: string;
    
   ngayCapCNDKKDHKD :number;
    
    noiCapCNDKKDHKD: string;
    
    maTinhTpDSXKD: string;
    
    tenTinhTpDSXKD: string;
    
    maHuyenThiTpDSXKD: string;
    
    tenHuyenThiTpDSXKD: string;
    
    maPhuongXaTTDSXKD: string;
    
    tenPhuongXaTTDSXKD: string;
    
    maDuongDSXKD: string;

    khuVucApDSXKD: string;
    
    tenDuongDSXKD: string;
    
    soNhaDSXKD: string;
    
    dienThoaiDSXKD: string;
    
    faxDSXKD: string;
    
    quyMoKinhDoanhDSXKD: string;
    
    soLuongNguoiLaoDongDSXKD: string;
    
    soLuongLaoDongTrucTiepDSXKD: string;
    
    soLuongLaoDongGianTiepDSXKD: string;
    
    duDieuKienAnToanThucPham: string;
    
    nguoiThamDinh: string;
    
    ngayThamDinh : number;
    
    coSoChuaDuDieuKienATTP : boolean;
    
    ketQuaThamDinh: string;
    
    maTrangThai: string;
    
    tenTrangThai: string;
    
    maBienDong: string;
    
    tenBienDong: string;
    
    files: FileEntry[];

    loaiKetQua:LoaiKetQuaEnum;

    ketQuaGiayPhep?: KetQuaGiayPhepDTO;

}

export enum BienDong{
    CAP_MOI = "CM",
    CAP_LAI = "CL",
    DIEU_CHINH = "DC",
    NGUNG_HOAT_DONG = "NG",
    TAM_NGUNG = "TN",
    CHAM_DUT = "CD",
    HUY= "HH",
    HOAN_TRA_HUY = "HT-HH",
    THU_HOI = "TH",
    HOAN_TRA_THU_HOI = "HT-TH",
    KHOI_PHUC = "KP-TT",
    DAU_KY="DK"
}
export enum TinhTrangString{
    HOAT_DONG = "HD",
    THU_HOI = "TH",
    HUY = "HH",
    TAM_NGUNG = "TN",
    CHAM_DUT = "CD"
}
export enum TinhTrang{
    CM,
    CL,
    DC,
    NHD,
    TN,
    CD
}
export interface BienDongDTO {
    id ?: number;
    maLoaiBienDong: string;
    tenLoaiBienDong: string;
    duongDan?:  string;
    thongKe?: boolean;
    trangThai?: number;
    duongDanTL?:  string;
}
export interface ThongKeTinhHinhCapPhepDTO {
    conHan: number;
    hetHan: number;
    tenPhuongXa: string;
    thang: string;
}

export interface ThongKeTinhHinhCapPhepResponse {
    conHan: number;
    hetHan: number;
    thongKeTinhHinhCapPhepDTOs: ThongKeTinhHinhCapPhepDTO[]
}
export interface BieuDoThongkeRespone {
    tongConHan : number;
    tongHetHan : number;
    tongSapHetHan : number;
    thang : number;
} 