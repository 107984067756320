import { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Button, NativeSelect, Box } from '@material-ui/core';
import { CustomInput } from '../../../../../commons/components/input/customInput';
import { ChuDauTuModel } from '../../../../../../api/CPXD/model/CapPhepXayDungModel';
import { ICONS } from '../../../../../Constants/constants';
import ChuDauTuModal from './ChuDauTuModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Cell,Row } from '../../../../../commons/components/table/tableCommons';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface chiTietProps {
    data: ChuDauTuModel[];
}

const ChuDauTu = (props: chiTietProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<ChuDauTuModel[]>(new Array<ChuDauTuModel>());
    const [openDialog, setOpenDialog] = useState(false);
    const [item, setItem] = useState<ChuDauTuModel>({
        id: 0,
        giayPhepXayDungId: 0,
        loaiDoiTuong: 1,
        hoTen: '',
        diaChiChiTiet: '',
        maGioiTinh: '',
        tenGioiTinh: '',
        duong: '',
        loaiGiayTo: 1,
        tenLoaiGiayTo: '',
        tenTinh: '',
        maTinh: '',
        tenHuyen: '',
        maHuyen: '',
        tenPhuong: '',
        maPhuong: '',
        diaChiCu: '',
        soGiayTo: '',
        ngayCap: 0,
        ngaySinh: 0,
        tenNoiCap: '',
        maNoiCap: '',
        hoatDong: true,
    } as ChuDauTuModel);
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    }
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    }
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = ((pageNumberNew - 1) * pageSizeNew);
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    }
    const handleTogle = () => {
        setOpenDialog(!openDialog);
    }
    const handleEditToggle = (item : any, loai : String) => {
        if(loai === "update"){
            setItem(item);
        }
        handleTogle();
       
    }    
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" >STT</Cell> 
                            <Cell component="th" scope="row" align="center" >Loại hình</Cell> 
                            <Cell component="th" scope="row" align="center" >Chủ đầu tư</Cell> 
                            <Cell component="th" scope="row" align="center" >Chi tiết</Cell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        dataPagi?.length > 0 ? dataPagi?.map((item, index) => (
                            <Row key={item.id}>
                                <Cell>
                                    {((pageNumber - 1) * pageSize) + index + 1}
                                </Cell>
                                <Cell>
                                    {item.loaiDoiTuong == 1 ? 'Cá nhân' : (item.loaiDoiTuong == 2 ? 'Doanh nghiệp' : '')}
                                </Cell>
                                <Cell>
                                    {item.hoTen}
                                </Cell>
                                <Cell className="text-center">
                                    <Button    onClick={() => handleEditToggle(item, "update")}>
                                        <VisibilityIcon style={{ cursor: 'pointer', color: '#367fa9' }}  />
                                    </Button>
                                </Cell>
                            </Row>
                            )) :
                            <Row>
                                <Cell colSpan={4} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row> 
                        }    
                    </TableBody>
                </Table>
                {(dataPagi  && dataPagi?.length> 0) &&
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination count={Math.ceil(props.data?.length / pageSize)} defaultPage={1} siblingCount={1} variant="outlined"
                            size="medium" color="primary" shape="rounded"
                            showFirstButton showLastButton onChange={handleChangePage} />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                            <NativeSelect input={<CustomInput />} onChange={handleChangePageSize} >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">Mẫu tin {pageNumber}  / {(props.data?.length === 0 ||  props.data == null) ? 1 : Math.ceil(props.data?.length / pageSize)}</Box>
                    </Box>
                }
            </TableContainer>
            {openDialog &&
                <ChuDauTuModal
                   data = {item}
                   show ={openDialog}
                   onToggle = {handleTogle}
                />
            }
        </>
        
    )
}
export default ChuDauTu;