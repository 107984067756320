import { Box, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { Cell, Row } from "../../table/tableCommons";
import { CustomInput } from "../../input/customInput";
import moment from "moment";
import { TepDinhKemPopper } from '../tepTin/TepDinhKemPopper';
import { LichSuGiayPhepDTO } from '../../../../../api/Common/model/CommonModel';

interface IDataListProps {
    dataList: LichSuGiayPhepDTO[];
}

export const LichSuGiayPhepComponent = (props: IDataListProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [dataTepms, setDataTepms] = useState<LichSuGiayPhepDTO[]>([]);
    useEffect(() => {
        setDataTepms(props.dataList);
        setTotal(props.dataList?.length);
        handleSearch({ page: 1, size: size });
    }, [props.dataList, total, size]);

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        handleSearch({ page: value, size: size });
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1)
        setSize(Number(event.target.value));
        handleSearch({ page: 1, size: Number(event.target.value) });
    }

    const handleSearch = (search: any) => {
        if(dataTepms && dataTepms != null && dataTepms.length > 0){
            setRows(dataTepms?.slice((search.page - 1) * search.size, search.page * search.size));
        }
    }

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="25%">Loại biến động</Cell>
                        <Cell component="th" scope="row" align="center" width="20%">Ngày biến động</Cell>
                        <Cell component="th" scope="row" align="center" width="20%">Số lần</Cell>
                        <Cell component="th" scope="row" align="center" >Nội dung</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Tệp tin
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {(page - 1) * size + i + 1}
                                    </Cell>
                                    <Cell align="left">{row.tenBienDong}</Cell>
                                    <Cell align="center">{(row.ngayBienDong !== 0 && row.ngayBienDong !== null) ? moment(row.ngayBienDong).format("DD/MM/YYYY") : ''}</Cell>
                                    <Cell align="center">{row.soLan}</Cell>
                                    <Cell align="left">{row.noiDung}</Cell>
                                    <Cell>
                                        {(row.files && row.files.length > 0) &&
                                            <TepDinhKemPopper data={row.files} />
                                        }
                                    </Cell>
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={13} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            {rows.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={Math.ceil(total / size)} defaultPage={1} siblingCount={1} variant="outlined" page={page}
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={handleOnChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {((page - 1) * size) + 1} - {size * page > total ? total : (size * page)} / {total}</Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
