import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, MenuProps } from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import StringUtil from "../../../utils/stringUtils";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize: "14px",
        "&:focus": {
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

interface MenuDropI<T> {
    onDelete?: (data: T) => void;
    onView?: (data: T) => void;
    onEdit?: (data: T) => void;
    item: T;
}

const MenuDrop = <T extends object>({ onDelete, onEdit, onView, item }: MenuDropI<T>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    return (
        <Box display="flex" justifyContent="center">
            <IconButton
                color="default"
                aria-label="edit"
                size="small"
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
            >
                <ViewListIcon />
            </IconButton>
            <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} elevation={0}>
                {onEdit && (
                    <StyledMenuItem disableRipple
                        onClick={
                            onEdit
                                ? () => {
                                    onEdit(item);
                                    setAnchorEl(null);
                                }
                                : () => { }
                        }
                    >
                        <IconButton size="small">
                            <EditIcon fontSize="small" color="primary" />
                        </IconButton>

                        Chỉnh sửa
                    </StyledMenuItem>
                )}
                {onDelete && (
                    <StyledMenuItem
                        disableRipple
                        onClick={
                            onDelete
                                ? () => {
                                    onDelete(item);
                                    setAnchorEl(null);
                                }
                                : () => { }
                        }
                    >
                        <IconButton size="small">
                            <CloseIcon fontSize="small" color="secondary" />
                        </IconButton>
                        {StringUtil.isNotEmpty(item['tinhTrang' as keyof T])  ? 'Cập nhật quyết định hủy' : 'Hủy quyết định' }
                    </StyledMenuItem>
                )}
                {onView && (
                    <StyledMenuItem
                        disableRipple
                        onClick={
                            onView
                                ? () => {
                                    onView(item);
                                    setAnchorEl(null);
                                }
                                : () => { }
                        }
                    >
                        <IconButton size="small">
                            <VisibilityIcon fontSize="small" style={{ color: green[500] }} />
                        </IconButton>
                        Xem chi tiết
                    </StyledMenuItem>
                )}
            </StyledMenu>
        </Box>
    );
};

export default MenuDrop;
