import {
    Box,
    Button,
    Card,
    CardContent,
    createStyles,
    Grid,
    InputBase,
    InputLabel,
    makeStyles,
    NativeSelect, TextField,
    Theme
} from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { PageRequest } from "../../../../api/baseService";
import { ThongKeService } from "../../../../api/LPGChai/ThongKeService";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { ThongKeTinhHinhCapPhepDTO } from "../../../../api/LPGChai/model/BaoCaoThongKeDTO";
import Paper from '@material-ui/core/Paper';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Legend,
    Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, EventTracker } from '@devexpress/dx-react-chart';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { AlertMessage } from "../../../commons/components/alert";
import { Bar, Pie } from "react-chartjs-2";
import { RequestBieuDoThongKeDTO } from "../BieuDoTron/BieuDoTronLPGChai";

export interface RequestThongKeDTO extends PageRequest {
    namThongKe: number;
    kyThongKe: number;
    loaiBieuDo: number;
    tuNgay: number;
    denNgay: number;
    tenNamThongKe: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;"
        },
    }),
);

export const BieuDoThongKeTinhHinhCapPhep = () => {

    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<ThongKeTinhHinhCapPhepDTO[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [showDate, setShowDate] = useState(true);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'warning', open: false } as AlertMessage);
    const [rows, setRows] = useState<ThongKeTinhHinhCapPhepDTO[]>([]);
    const [showBD, setShowBD] = useState(true);
    const [dataBDTron, setDataBDTron] = useState<ThongKeTinhHinhCapPhepDTO>();
    const dataCapMoi = [...rows.map((item) => item.capMoi)];
    const dataCapLai = [...rows.map((item) => item.capLai)];
    const dataThuHoi = [...rows.map((item) => item.thuHoi)];
    const dataHuy = [...rows.map((item) => item.huy)];
    useEffect(() => {
        namThongKeList();
        if (showBD) {
            execute();
        } else {
            executeBieuDoTron();
        }
    }, [showBD]);

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            position: "bottom",
        },
        layout: {
            padding: 10,
        },
        type: "pie",
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem: any, data: any) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            request: {
                kyThongKe: 1,
                namThongKe: new Date().getUTCFullYear(),
                tenNamThongKe: 'Năm ' + new Date().getUTCFullYear()
            } as RequestThongKeDTO,
        },
        onSubmit: () => execute(),
    });

    const handleButtonBD = () => {
        if (showBD) {
            execute();
        } else {
            executeBieuDoTron();
        }
    }

    const execute = async () => {
        if (formik.values.request?.kyThongKe == 2) {
            if (formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay) {
                showMessageAlert('Từ ngày không được lớn hơn đến này', false);
                return;
            }
        }
        let request = {
            ...formik.values.request,
        } as RequestThongKeDTO;
        dispatchLoading(showLoading());

        await new ThongKeService().chartThongKe(request).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setData(res?.data?.data);
                setRows(res?.data?.data);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const executeBieuDoTron = async () => {
        if (formik.values.request?.kyThongKe == 2) {
            if (formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay) {
                showMessageAlert('Từ ngày không được lớn hơn đến này', false);
                return;
            }
        }
        let request = {
            ...formik.values.request,
        } as RequestBieuDoThongKeDTO;
        dispatchLoading(showLoading());

        await new ThongKeService().thongKeBieuDoTron(request).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setDataBDTron(res?.data?.data);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { nam: (namHienTai - i), name: 'Năm ' + (namHienTai - i) };
            temps.push(temp);
        }

        setDataNamThongKe(temps);
    }

    const handleChangeKyThongKe = (value: any) => {
        if (value == 1) {
            setShowDate(true);
        } else {
            setShowDate(false);
        }
    }
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'warning' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const optionsBD = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };
    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Cấp mới",
                backgroundColor: "#00c292",
                data: dataCapMoi,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Cấp lại",
                backgroundColor: "#ffb229",
                data: dataCapLai,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Thu hồi",
                backgroundColor: "#8faadc",
                data: dataThuHoi,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Hủy",
                backgroundColor: "#c9c9c9",
                data: dataHuy,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
        ],
    };

    const handleChangeLoaiBieuDo = (value: any) => {
        if (value == 1) {
            setShowBD(true);
        } else {
            setShowBD(false);
        }
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN LPG CHAI</span>
            </div>
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Loại biểu đồ</InputLabel>
                        <NativeSelect fullWidth disableUnderline name="loaiBieuDo" value={formik.values?.request?.loaiBieuDo} onChange={
                            (event: any) => {
                                formik.setFieldValue("request.loaiBieuDo", event.target.value);
                                handleChangeLoaiBieuDo(event.target.value);
                            }
                        } >
                            <option value="1">Biểu đồ thống kê</option>
                            <option value="2">Biểu đồ tròn</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Kỳ thống kê</InputLabel>
                        <NativeSelect fullWidth disableUnderline name="kyThongKe" value={formik.values?.request?.kyThongKe} onChange={
                            (event: any) => {
                                formik.setFieldValue("request.kyThongKe", event.target.value);
                                handleChangeKyThongKe(event.target.value);
                            }
                        } >
                            <option value="1">Năm</option>
                            <option value="2">Khoảng thời gian</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={!showDate}>
                        <InputLabel >Năm </InputLabel>
                        <Autocomplete options={dataNamThongKe}
                            value={{ nam: formik.values?.request?.namThongKe ? formik.values.request?.namThongKe : 0, name: formik.values?.request?.tenNamThongKe ? formik.values.request?.tenNamThongKe : "" }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.namThongKe", newValue?.nam);
                                formik.setFieldValue("request.tenNamThongKe", newValue?.name);
                            }}

                            renderInput={(params) => {
                                return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                    <TextField  {...params} variant="outlined" />
                                </div>
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={showDate}>
                        <InputLabel >Từ ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request.tuNgay > 0 ? new Date(formik.values.request.tuNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.tuNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={showDate}>
                        <InputLabel >Đến ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request?.denNgay > 0 ? new Date(formik.values.request?.denNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.denNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <Button onClick={handleButtonBD} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tổng hợp
                        </Button>
                    </Grid>
                    {showBD &&
                    <Grid item xs={12} className="grid-item">
                        <Card classes={classes}>
                            <CardContent>
                                <Bar data={dataBD} options={optionsBD} />
                            </CardContent>
                        </Card>
                        {/* <Paper>
                            <Chart data={data}>
                                <ArgumentAxis />
                                <ValueAxis
                                    labelComponent={(props) => {
                                        return Number.isInteger(Number(props.text)) ? <text {...props}>{Number(props.text)}</text> : <text {...props}></text>;
                                    }}
                                />

                                <BarSeries
                                    name="Cấp mới"
                                    valueField="capMoi"
                                    argumentField="thang"
                                    color="#00c292"
                                />
                                <BarSeries
                                    name="Cấp lại"
                                    valueField="capLai"
                                    argumentField="thang"
                                    color="#ffb229"
                                />
                                <BarSeries
                                    name="Thu hồi"
                                    valueField="thuHoi"
                                    argumentField="thang"
                                    color="#8faadc"
                                />
                                <BarSeries
                                    name="Hủy"
                                    valueField="huy"
                                    argumentField="thang"
                                    color="#c9c9c9"
                                />
                                <Animation />
                                <Legend position="bottom"
                                    rootComponent={(root: Legend.RootProps) => {
                                        return (
                                            <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                                {root.children}
                                            </Box>
                                        )
                                    }}
                                    itemComponent={(label: Legend.ItemProps) => {
                                        return (
                                            <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                                {label.children}
                                            </Box>
                                        )
                                    }} />
                                <Stack />
                                <EventTracker></EventTracker>
                                <Tooltip />
                            </Chart>
                        </Paper> */}
                    </Grid>
                    }
                    {!showBD &&
                        <Grid item xs={12} className="grid-item">
                            <Paper  >
                                <Card >
                                    {/* <CardHeader title={"HỒ SƠ ĐÃ TIẾP NHẬN (" + dataTinhHinhXuLy.daTiepNhan + ")"} style={{ backgroundColor: "#f2f2f2", fontSize: "1em" }}></CardHeader> */}
                                    <CardContent >
                                        <Pie
                                            height={120}
                                            data={{
                                                labels: ["Cấp mới (" + dataBDTron?.capMoi + ")", "Cấp lại (" + (dataBDTron?.capLai) + ")", "Thu hồi (" + (dataBDTron?.thuHoi) + ")", "Hủy (" + (dataBDTron?.huy) + ")"],
                                                datasets: [
                                                    {
                                                        data: [dataBDTron?.capMoi, dataBDTron?.capLai, dataBDTron?.thuHoi, dataBDTron?.huy],
                                                        backgroundColor: ["#00c292", "#ffb229", "#8faadc", "#c9c9c9"]
                                                        //hoverBackgroundColor: chartColors
                                                    }
                                                ]
                                            }} options={options}
                                        />

                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>
                    }
                </Grid>
            </form>
            <OverlayLoading />
        </div >
    )
}

