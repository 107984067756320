import React, { Dispatch, useEffect, useState } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { BrowserHistory } from 'history';
import { ConfirmationDialogRaw } from "../../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../../commons/components/tabs";
import CapMoiSXRuouNoiDungComponent from "./CapMoiSXRuouNoiDungComponent";
import { HoSoThuLyBLR } from "../../../../../../api/CPBLR/model/HoSoThuLyBLR";
import { CAP_MOI_SX_RUOU_INIT } from "../../../../../../api/CPBLR/Init/CapMoiSXRuouInit";
import StringUtil from "../../../../../utils/stringUtils";
import { CapMoiSXRuouService } from "../../../../../../api/CPBLR/CapMoiSXRuouService";
import { BieuMauAttachFileDTO } from "../../../../../../api/QuanTri/Model/danhMucBieuMau";
import { createFileType, downLoadFile } from "../../../../../../api/Common/exportUtil";
import { AlertMessage, CustomAlert } from "../../../../../commons/components/alert";
import { LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from '../../../../../Constants/RuouConstants';
import ThuLyButtonComponent from '../../../../../commons/components/hoso/thuly/ThuLyButtonComponent';
import { useGetThongTinChuyenNganh } from '../../../../../../pages/hoSoCuaToi/useGetThongTinChuyenNganh';
import ThongTinChuyenNganhUtil from '../../../../../../pages/hoSoCuaToi/ThongTinChuyenNganhUtil';
import { DanhMucService } from '../../../../../../api/danhMuc/danhMucService';
import { ThongTinChuyenNhanhUtil } from '../../../../../utils/thongTinChuyenNhanhUtil';
import { OverlayLoading } from '../../../../../commons/components/overlayLoading/overlayLoading';
import { LinhVucCapSoGPService } from '../../../../../../api/DKKD/LinhVucCapSoGPService';
import { UserData } from '../../../../../../api/userMgt/userManagementService';
import { SX_RUOU_CN } from '../../../../../Constants/constants';
import { HoSoCuaToiMgtService } from '../../../../../../api/HoSoCuaToi/HoSoCuaToiMgtService';
import { hideLoading, LoadingAction } from '../../../../../../redux/actions/applicationAction';
import { useDispatch } from 'react-redux';

type StateHoSo = {
    maThuTuc: string,
    maHoSo: string
}
type CapMoiSXRuouProps = {
    history: BrowserHistory<StateHoSo>
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const CapMoiSXRuouComponent = (props: CapMoiSXRuouProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = React.useState({} as HoSoThuLyBLR);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const capMoiSXRuouService = new CapMoiSXRuouService();
    const { history } = props;
    const service = new LinhVucCapSoGPService();
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    let C_USER_DATA_DE = {} as UserData;
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    useEffect(() => {
        initData();
    }, [])

    const initData = async () => {
        let maHoSo = history?.location?.state?.maHoSo;
        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        if (!StringUtil.isNullOrEmty(C_USER_DATA_DE.maCoQuan)) {
            hoSoCuaToiMgtService.chiTiet(C_USER_DATA_DE.maCoQuan, history?.location?.state?.maHoSo).then((p) => {
                if (p.data && p.data.data) {
                    hoTenNguoiNop = p.data.data.nguoiNop;
                    soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
                }
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
        capMoiSXRuouService.getByMaHoSo({ maHoSo: history?.location?.state?.maHoSo }).then(async (res) => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let resData: HoSoThuLyBLR = {
                    ...res?.data?.data,
                    maBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                };
                setData(resData);
            } else {
                let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHoSo);
                let maQuan = "";
                let tenQuan = "";
                let maPhuong = "";
                let tenPhuong = "";
                if (!StringUtil.isNullOrEmty(dataEform?.quanhuyen)) {
                    let dataQuan = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                    maQuan = dataQuan[0].code;
                    tenQuan = dataQuan[0].name;
                }
                if (!StringUtil.isNullOrEmty(dataEform?.xaphuong)) {
                    let dataHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                    maPhuong = dataHuyen[0].code;
                    tenPhuong = dataHuyen[0].name;
                }
                let soGiayPhepDayDu = "";
                await service.getGPTuDong(C_USER_DATA_DE.maCoQuan, SX_RUOU_CN.value).then(res => {
                    if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                        soGiayPhepDayDu = res.data.data.soGPDayDu;
                    }
                }).catch(error => {
                    return;
                });
                const dataNew: HoSoThuLyBLR = {
                    ...CAP_MOI_SX_RUOU_INIT,
                    soGiayPhep: soGiayPhepDayDu,
                    maCoQuan: C_USER_DATA_DE.maCoQuan,
                    maChuyenNganh: SX_RUOU_CN.value,
                    loaiGiayPhep: 'SXR',
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    maBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten,
                    tenThuongNhan: hoTenNguoiNop != "" ? hoTenNguoiNop : dataEform?.hotennguoidaidien ? dataEform?.hotennguoidaidien : CAP_MOI_SX_RUOU_INIT.tenThuongNhan,
                    faxTruSo: dataEform?.fax ? dataEform?.fax : CAP_MOI_SX_RUOU_INIT.faxTruSo,
                    dienThoaiTruSo: soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt ? dataEform?.sdt : CAP_MOI_SX_RUOU_INIT.dienThoaiTruSo,
                    giayCNDK: dataEform?.giaydkkd ? dataEform?.giaydkkd : CAP_MOI_SX_RUOU_INIT.giayCNDK,
                    noiCap: dataEform?.noicapdkkd ? dataEform?.noicapdkkd : CAP_MOI_SX_RUOU_INIT.noiCap,
                    ngayCap: dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : CAP_MOI_SX_RUOU_INIT.ngayCap,
                    maQuanTruSo: dataEform?.quanhuyen ? dataEform?.quanhuyen : CAP_MOI_SX_RUOU_INIT.maQuanTruSo,
                    tenQuanTruSo: tenQuan,
                    maPhuongTruSo: dataEform?.xaphuong ? dataEform?.xaphuong : CAP_MOI_SX_RUOU_INIT.maPhuongTruSo,
                    tenPhuongTruSo: tenPhuong,
                    tenDuongTruSo: dataEform?.diadiem ? dataEform?.diadiem : CAP_MOI_SX_RUOU_INIT.tenDuongTruSo,
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }

    const resetData = () => {
        const dataNew: HoSoThuLyBLR = {
            ...CAP_MOI_SX_RUOU_INIT,
            id: data.id,
            maBienDong: LOAIBIENDONG_CAPMOI.ma,
            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
            maTinhTrang: TINHTRANG_HOATDONG.ma,
            tenTinhTrang: TINHTRANG_HOATDONG.ten,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan)
            || StringUtil.isNullOrEmty(data.maPhuongSX)
            || StringUtil.isNullOrEmty(data.maPhuongTruSo)
            || StringUtil.isNullOrEmty(data.giayCNDK)) {
            setTabRequired(-0);
            return;
        }
        if (data?.donViCapPhep) {
            if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
                setTabRequired(-0);
                return;
            }
        }
        if (!(data?.ngayCapPhep !== undefined && data?.ngayCapPhep != 0)) {
            setTabRequired(-0);
            return;
        }
        setSubmit(false);
        let dataRequest: HoSoThuLyBLR = { ...data };
        if (!data.donViCapPhep) {
            dataRequest = {
                ...data,
                soGiayPhep: '',
                ngayCapPhep: 0,
                ngayHetHanGP: 0
            }
        }
        capMoiSXRuouService.insertOrUpdate(dataRequest).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL') {
                    setDataParam('data', res.data.data);
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else {
                    showMessageAlert(res.data.error_message, false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        capMoiSXRuouService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }

    return (
        <>
            {
                <Paper square>
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <br></br>
                                <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                        <div className="dash-content">
                            <div className="dash-title">
                                CẤP GIẤY PHÉP SẢN XUẤT RƯỢU THỦ CÔNG NHẰM MỤC ĐÍCH KINH DOANH {"- MÃ HỒ SƠ :  " + data.maHoSo}
                            </div>
                        </div>
                        {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange}>
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                </CustomTabs>
                            </Box>

                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <CapMoiSXRuouNoiDungComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    data={data} />
                            </TabPanel>
                        }

                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <ThuLyButtonComponent
                                showIn={data.id > 0}
                                onSubmit={handleSubmit}
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onDelete={handleDelete}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    );

};
export default CapMoiSXRuouComponent;