import AXIOS_INSTANCE from "..";
import { RequestBaoCaoThongKeDTO } from "../../App/Components/CPBLTL/TraCuuKDTL/TraCuuKDTLComponent";
import { BaseResponse, BaseService, Pagination } from "../baseService";
import { RequestDTO } from "../DKKD/capSoGiayPhepKinhDoanhService";
import { CapSoGiayPhepKinhDoanhThuocLaDTO } from "./model/BienDongTrangThaiGPKTLDTO";
import { DanhSachGiayPhepBLThuocLaDTO } from "./model/DanhSachGiayPhepBLThuocLaDTO";
import { HoSoThuLyBLThuocLa } from "./model/HoSoThuLyBLThuocLa";

export class QuanLyKDTLService extends BaseService {

    loadDanhSach = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<DanhSachGiayPhepBLThuocLaDTO[]>> => {
        let result: Pagination<DanhSachGiayPhepBLThuocLaDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE.post('giay-phep-kinh-doanh-thuoc-la/danh-sach-quan-ly', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    downloadFileDanhSach = (request: RequestBaoCaoThongKeDTO) => {
        return AXIOS_INSTANCE.post('giay-phep-kinh-doanh-thuoc-la/export-danh-sach-giay-phep', request, {
            responseType: 'blob'
        });

    }

    getChiTietGPKDById = (requestBody: any) => {
        return AXIOS_INSTANCE.post('giay-phep-kinh-doanh-thuoc-la/chi-tiet-giay-phep', requestBody);
    }

    loadLoaiBienDong(maTinhTrang: string) {
        return AXIOS_INSTANCE.get('/danh-muc/danh-sach-bien-dong-theo-tinh-trang?maTinhTrang=' + maTinhTrang);
    }

    timKiem = async (request: RequestDTO): Promise<Pagination<HoSoThuLyBLThuocLa[]>> => {
        let result: Pagination<HoSoThuLyBLThuocLa[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        }

        await AXIOS_INSTANCE.post('giay-phep-kinh-doanh-thuoc-la/danh-sach-cap-so', request, this.getTokenRequestHeaders()).then(response => {
            if (response.data.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.data,
                }
            }
        }).catch();
        return result;
    }

    luuVaCapNhat = async (capPhep: CapSoGiayPhepKinhDoanhThuocLaDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await AXIOS_INSTANCE.post('giay-phep-kinh-doanh-thuoc-la/add-or-update', JSON.stringify(capPhep), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_code == 'DUPLICATE_CODE' ? 'Số giấy phép đã được sử dụng' : 'Yêu cầu thực hiện thành công'
            }
        }).catch(() => {
            result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' }
        });
        return result;
    }

    getCapSoByGiayPhepId = async (json: any): Promise<CapSoGiayPhepKinhDoanhThuocLaDTO> => {
        let result = {} as CapSoGiayPhepKinhDoanhThuocLaDTO;
        await AXIOS_INSTANCE.post('giay-phep-kinh-doanh-thuoc-la/get-cap-so-by-giay-phep-id', JSON.stringify(json), this.getTokenRequestHeaders()).then(response => {
            if (response != null && response.data != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }
        }).catch(() => {
        });
        return result;
    }
}
