import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Snackbar, Typography } from "@material-ui/core";
import { BrowserHistory } from "history";
import { HoSoThuLyDTO } from "../../../../api/DKKD/model/hoSoThuLyDTO";
import { DangKyKinhDoanhService } from "../../../../api/DKKD/DangKyKinhDoanhService";
import { ThanhVienGopVonDTO } from "../../../../api/DKKD/model/thanhVienGopVonDTO";
import StringUtil from "../../../utils/stringUtils";
import { ConfirmationDialogRaw } from "../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../commons/components/tabs";
import { ThanhLapHKDThanhVienGopVon } from "../Modal/ThanhLapHKDThanhVienGopVon";
import NhapDaukyThanhLapHKDNoiDungComponent from "./NhapDaukyThanhLapHKDNoiDungComponent";
import { TINH_TRANG } from "../../../../api/QuanTri/DanhMucFakeService";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { GIAY_PHEP_LGP_CHAI_INIT } from "../../../../api/LPGChai/model/GiayPhepLGPChaiInit";
import { API_KEY_MAP } from "../../../Constants/const";
import { loadModules } from "esri-loader";
import { HoSoThuLyDTO_INIT } from "../../../../api/DKKD/init/HoSoThuLyInit";
import { LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from "../../../Constants/DKKDConstants";
import ValidatorUtil from "../../../utils/validatorUtil";
import DateUtil from "../../../utils/dateUtils";
import { BieuMauAttachFileDTO } from "../../../../api/QuanTri/Model/danhMucBieuMau";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import NhapDauKyButtonComponent from "../thuly/NhapDauKyButtonComponent";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";

type StateHoSo = {
    hoSoThuLyId: number;
};
type DauKyDKKDProps = {
    history: BrowserHistory<StateHoSo>;
};
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
const NhapDauKyThanhLapHKDComponent = (props: DauKyDKKDProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [data, setData] = React.useState({...HoSoThuLyDTO_INIT} as HoSoThuLyDTO);
    const [diaChiKinhDo, setDiaChiKinhDo] = useState<Number>();
    const [diaChiViDo, setDiaChiViDo] = useState<Number>();
    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const { history } = props;

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        dangKyKinhDoanhService
            .getChiTietGPKDById({ hoSoThuLyId: history?.location?.state?.hoSoThuLyId })
            .then((res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    let resData = {
                        ...res?.data?.data,
                        maHoSo: "",
                        // tinhTrang: TINHTRANG_HOATDONG.ma,
                        // tenTinhTrang: TINHTRANG_HOATDONG.ten,
                        // maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        // tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    } as HoSoThuLyDTO;
                    setData(resData);
                } else {
                    const dataNew = {
                        ...HoSoThuLyDTO_INIT,
                        maLoaiGiayPhep: "DK",
                        tinhTrang: TINHTRANG_HOATDONG.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value,
            };
            setData(dataNew);
        }
    };
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataMems = (memData: ThanhVienGopVonDTO, isEdit: Boolean, index: number) => {
        let mems = [...data.thanhVienGopVons];
        if (isEdit) {
            const idx = mems.findIndex((e) => e.ngayTao === memData.ngayTao);
            mems[idx] = memData;
        } else {
            let mem = { ...memData, ngayTao: new Date().getTime() };
            mems.push(mem);
        }

        let dataNew = {
            ...data,
            thanhVienGopVons: mems,
        };
        setData(dataNew);
    };

    const onDeleteMems = (index: number) => {
        let mems = [...data.thanhVienGopVons];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            thanhVienGopVons: mems,
        };
        setData(dataNew);
    };

    const resetData = () => {
        const dataNew = {
            ...HoSoThuLyDTO_INIT,
            maHoSo: "",
            id: data.id,
            maLoaiGiayPhep: "DK",
            // tinhTrang: TINHTRANG_HOATDONG.ma,
            // tenTinhTrang: TINHTRANG_HOATDONG.ten,
            // maBienDong: LOAIBIENDONG_CAPMOI.ma,
            // tenBienDong: LOAIBIENDONG_CAPMOI.ten,
        };
        setData(dataNew);
    };

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dangKyKinhDoanhService.downloadFile({ templateId: bieuMau.fileId, maHoSo: String(data.maHoSo), soGiayPhep :data.soGiayPhep }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    };

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (
            StringUtil.isNullOrEmty(data.hoTen) ||
            data.loaiGiayTo <= 0 ||
            StringUtil.isNullOrEmty(data.soGiayToTuyThan) ||
            // data.loaiChuThe <= 0 ||
            StringUtil.isNullOrEmty(data.tenHoKinhDoanh) ||
            // !data.nganhNgheKinhDoanhs || data.nganhNgheKinhDoanhs.length <= 0 ||
            StringUtil.isNullOrEmty(data.maQuan) ||
            StringUtil.isNullOrEmty(data.tenDuong) ||
            StringUtil.isNullOrEmty(data.soGiayPhep) ||
            StringUtil.isNullOrEmty(data.ngayCapPhep)
        ) {
            setTabRequired(-0);
            return;
        }
        if (!ValidatorUtil.validatorSoGiayTo(data.loaiGiayTo, data.soGiayToTuyThan)) {
            setTabRequired(-0);
            return;
        }
        if(data?.ngaySinh && data?.ngaySinh > 0){
            if(!DateUtil.is18Years(data?.ngaySinh)){
                showMessageAlert('Ngày sinh phải lớn hơn hoặc bằng 18 tuổi', false);
                setTabRequired(-0);
                return;
            }

        }
        setSubmit(false);
        dangKyKinhDoanhService
            .insertOrUpdateDauKy({ ...data, diaChiKinhDo, diaChiViDo })
            .then((res) => {
                if (res != null) {
                    if (res.data.error_code == "SUCCESSFUL") {
                        let resData = {
                            ...res?.data?.data,
                        };
                        setData(resData);
                        showMessageAlert("Yêu cầu thực hiện thành công", true);
                    } else if (res.data.error_code == "DUPLICATE_CODE") {
                        setAlertMessage({ open: true, content: "Trùng số giấy phép", severity: "warning" });
                    } else {
                        showMessageAlert("Yêu cầu thực hiện thất bại", false);
                    }
                } else {
                    showMessageAlert("Yêu cầu thực hiện thất bại", false);
                }
            })
            .catch((error) => {
                showMessageAlert("Yêu cầu thực hiện thất bại", false);
            });
    };

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert("Yêu cầu thực hiện thành công", true);
        }
        setOpenDeleteUserDlg(false);
    };

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const [locater, setLocater] = useState<any>();

    useEffect(() => {
        loadModules(["esri/config", "esri/tasks/Locator"]).then(([esriConfig, Locator]) => {
            esriConfig.apiKey = API_KEY_MAP;
            const locater = new Locator({
                url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
            });
            setLocater(locater);
        });
    }, []);

    useEffect(() => {
        if (locater) {
            const address = {
                SingleLine: StringUtil.diaChiToString({
                    diaChiTinhName: "Thành phố Cần Thơ",
                    diaChiHuyenName: data.tenQuan,
                    diaChiXaName: data.tenPhuong,
                    duong: data.tenDuong,
                    soNha: data.soNha,
                }),
            };

            const params = {
                address: address,
                forStorage: false,
            };
            locater.addressToLocations(params).then((response: any) => {
                if (response && response.length > 0) {
                    const { location } = response[0];
                    const { longitude, latitude } = location;
                    setDiaChiKinhDo(longitude);
                    setDiaChiViDo(latitude);
                }
            });
        }
    }, [locater, data]);

    return (
        <>
            {
                <Paper square>
                    <OverlayLoading />
                    <Snackbar
                        open={alertMessage.open}
                        onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                            {alertMessage.content}
                        </CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw
                        id="confirm-dialog-delete-dkkd"
                        keepMounted
                        open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                    
                        {/* <Grid container style={{ float: "left" }} item xs={12} className="grid-item"> */}
                        <div className="dash-content">
                            <div className="dash-title">
                                <span>NHẬP ĐẦU KỲ GIẤY PHÉP KINH DOANH</span>
                            </div>
                        </div> 
                        {/* </Grid> */}
                        <br />
                        <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange}>
                                    <CustomTab style={tabRequired == 0 ? { color: "red" } : {}} label="Thông tin chung" />
                                    {/* <CustomTab style={tabRequired == 1 ? { color: "red" } : {}} label="Thành viên góp vốn" /> */}
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {indexTab === 0 && (
                            <TabPanel value={indexTab} index={0}>
                                <NhapDaukyThanhLapHKDNoiDungComponent onUpdateData={setDataParam} submit={submit} data={data} />
                            </TabPanel>
                        )}
                        {/* {indexTab === 1 && (
                            <TabPanel value={indexTab} index={1}>
                                <ThanhLapHKDThanhVienGopVon onSetDataMems={setDataMems} onDeleteMems={onDeleteMems} dataList={data.thanhVienGopVons} />
                            </TabPanel>
                        )} */}

                        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                            <NhapDauKyButtonComponent 
                                showIn={data.id > 0} 
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onSubmit={handleSubmit} 
                                onDelete={handleDelete} 
                            />
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    );
};
export default NhapDauKyThanhLapHKDComponent;
