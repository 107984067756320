import React, { useEffect } from 'react';
import {
    createStyles,
    FormControlLabel,
    Grid,
    InputLabel,
    makeStyles,
    Radio,
    RadioGroup
} from '@material-ui/core';
import moment from "moment";
import { HoSoThuLyDTO } from "../../../../../api/DKKD/model/hoSoThuLyDTO";

interface ThayDoiHKDNoiDungCuComponentProps {
    data: HoSoThuLyDTO;
}

makeStyles(() =>
    createStyles({
        button: {
            margin: "0px 5px"
        },
    }),
);
const ThayDoiHKDNoiDungCuComponent = (props: ThayDoiHKDNoiDungCuComponentProps) => {
    const [data, setData] = React.useState({} as HoSoThuLyDTO);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <>
            {
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số giấy chứng nhận</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.soGiayPhep}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.giayPhepCu?.ngayCapPhep != null && data?.giayPhepCu?.ngayCapPhep > 0) ? moment(new Date(data?.giayPhepCu?.ngayCapPhep)).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <InputLabel >Thông tin về đại diện hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Họ tên</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.hoTen}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Giới tính</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.gioiTinh == 1 ? 'Nam' : (data?.giayPhepCu?.gioiTinh == 2 ? 'Nữ' : data?.giayPhepCu?.gioiTinh == 0 ? 'Khác' : '')}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày sinh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.giayPhepCu?.ngaySinh != null && data?.giayPhepCu?.ngaySinh > 0) ? moment(data?.giayPhepCu?.ngaySinh).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Dân tộc</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.tenDanToc}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Quốc tịch</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.tenQuocTich}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Loại giấy tờ tùy thân</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.loaiGiayTo == 1 ? 'Chứng minh nhân dân' : (data?.giayPhepCu?.loaiGiayTo == 2 ? 'Căn cước công dân' : data?.giayPhepCu?.loaiGiayTo == 3 ? 'Hộ chiếu' : '')}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số giấy tờ tùy thân</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.soGiayToTuyThan}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.giayPhepCu?.ngayCap != null && data?.giayPhepCu?.ngayCap > 0) ? moment(data?.giayPhepCu?.ngayCap).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Nơi cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.noiCap}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Ngày hết hạn</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{(data?.giayPhepCu?.ngayHetHan != null && data?.giayPhepCu?.ngayHetHan > 0) ? moment(data?.giayPhepCu?.ngayHetHan).format("DD/MM/YYYY") : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chủ thể thành lập hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            {
                                data?.loaiChuThe == 1 &&
                                <InputLabel>{data?.tenChuThe}</InputLabel>
                            }
                        </Grid>
                        {/* <Grid item xs={9} className="grid-item custom__radio__button">
                            <RadioGroup row aria-label="position">
                                <FormControlLabel
                                    checked={data?.giayPhepCu?.loaiChuThe == 1}
                                    value="1"
                                    control={<Radio color="primary" size="small" />}
                                    label="Nhóm cá nhân"
                                    labelPlacement="end"
                                    disabled
                                />
                                <FormControlLabel
                                    checked={data?.giayPhepCu?.loaiChuThe == 2}
                                    disabled
                                    value="2"
                                    control={<Radio color="primary" size="small" />}
                                    label="Cá nhân"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </Grid> */}
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Nơi đăng ký hộ khẩu thường trú</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.hoKhau}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chỗ ở hiện tại</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.choO}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Tên hộ kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.tenHoKinhDoanh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Tổng số vốn (VNĐ)</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.tongVon}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Số lượng lao động</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.soLuongLaoDong}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Lĩnh vực kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.linhVuc}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Mặt hàng kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.matHang}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel>Ngành, nghề kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.tenDanhSachNganhNgheKinhDoanh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Chi tiết ngành, nghề kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.chiTietNganhNghe}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Địa điểm kinh doanh</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.soNha}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Điện thoại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.dienThoai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Fax</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.fax}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Email</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.email}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel >Website</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel>{data?.giayPhepCu?.website}</InputLabel>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
};
export default ThayDoiHKDNoiDungCuComponent;
