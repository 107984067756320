import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { CapMoiBLThuocLaService } from "../../../../../api/CPBLTL/CapMoiBLThuocLaService";
import { CAP_MOI_BL_THUOC_LA_INIT } from "../../../../../api/CPBLTL/init/CapMoiBLThuocLaInit";
import { HoSoThuLyBLThuocLa } from "../../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import { QuanLyKDTLService } from "../../../../../api/CPBLTL/QuanLyKDTLService";
import { BieuMauAttachFileDTO } from "../../../../../api/QuanTri/Model/danhMucBieuMau";
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { CustomTab, CustomTabs } from "../../../../commons/components/tabs";
import { LOAIBIENDONG_CAPMOI, LOAIBIENDONG_HUY, LOAIBIENDONG_THUHOI, TINHTRANG_HOATDONG, TINHTRANG_HUY, TINHTRANG_THUHOI } from '../../../../Constants/ThuocLaConstants';
import StringUtil from "../../../../utils/stringUtils";
import NhapDauKyButtonComponent from "../../../DKKD/NhapDauKyHKD/NhapDauKyButtonComponent";
import DauKyBLThuocLaNoiDungComponent from "./DauKyBLThuocLaNoiDungComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const DauKyBLThuocLaComponent = () => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    let [data, setData] = React.useState({} as HoSoThuLyBLThuocLa);
    const capMoiBLThuocLaService = new CapMoiBLThuocLaService();
    const quanLyKDTLService = new QuanLyKDTLService();
    const history = useHistory();

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        quanLyKDTLService.getChiTietGPKDById({ giayPhepId: history?.location?.state?.id }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let resData = {
                    ...res?.data?.data,
                    // maBienDong: LOAIBIENDONG_CAPMOI.ma,
                    // tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    // maTinhTrang: TINHTRANG_HOATDONG.ma,
                    // tenTinhTrang: TINHTRANG_HOATDONG.ten
                } as HoSoThuLyBLThuocLa;
                setData(resData);
            } else {
                const dataNew = {
                    ...CAP_MOI_BL_THUOC_LA_INIT,
                    maLoaiGiayPhep: 'DK',
                    donViCapPhep: true,
                    maBienDong: LOAIBIENDONG_CAPMOI.ma,
                    tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                    maTinhTrang: TINHTRANG_HOATDONG.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG.ten
                }
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });

    }

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.tenThuongNhan)
            || StringUtil.isNullOrEmty(data.soCNDKKD)
            || StringUtil.isNullOrEmty(data.maPhuongXa)) {
            setTabRequired(-0);
            return;
        }
        if (!(data.ngayCapPhep !== 0 && data.ngayCapPhep !== null && data.ngayCapPhep !== undefined)) {
            setTabRequired(-0);
            return;
        }
        setSubmit(false);
        let dataRequest = { ...data };
        if (data.maTinhTrang == TINHTRANG_HUY.ma) {
            dataRequest = {
                ...data,
                maBienDong: LOAIBIENDONG_HUY.ma,
                tenBienDong: LOAIBIENDONG_HUY.ten,
                tenTinhTrang : TINHTRANG_HUY.ten
            }
        } else if (data.maTinhTrang == TINHTRANG_THUHOI.ma) {
            dataRequest = {
                ...data,
                maBienDong: LOAIBIENDONG_THUHOI.ma,
                tenBienDong: LOAIBIENDONG_THUHOI.ten,
                tenTinhTrang : TINHTRANG_THUHOI.ten
            }
        }
        capMoiBLThuocLaService.insertOrUpdate(dataRequest).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL' && parseInt(res.data.data.id) > 0) {
                    let resData = {
                        ...res?.data?.data
                    };
                    setData(resData);
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else if (res.data.error_code == 'DUPLICATE_CODE') {
                    setAlertMessage({ open: true, content: 'Trùng số giấy phép', severity: 'warning' });
                } else {
                    showMessageAlert('Yêu cầu thực hiện thất bại', false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }
    const resetData = () => {
        const dataNew = {
            ...CAP_MOI_BL_THUOC_LA_INIT,
            maLoaiGiayPhep: 'DK',
            id: data.id,
            giayPhepId: data.giayPhepId,
            donViCapPhep: true,
            maBienDong: data.maBienDong,
            tenBienDong: data.tenBienDong,
            maTinhTrang: data.maTinhTrang,
            tenTinhTrang: data.tenTinhTrang
        }
        setData(dataNew);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        capMoiBLThuocLaService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    }

    return (
        <>
            {
                <Paper square>
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <br></br>
                                <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    {data?.id > 0 ? 'GIẤY PHÉP KINH DOANH THUỐC LÁ' : 'NHẬP ĐẦU KỲ GIẤY PHÉP KINH DOANH THUỐC LÁ'}
                                </div>
                            </div>
                                {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange}>
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <DauKyBLThuocLaNoiDungComponent
                                    onUpdateData={setDataParam}
                                    submit={submit}
                                    data={data} />
                            </TabPanel>
                        }
                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item">
                            <NhapDauKyButtonComponent onSubmit={handleSubmit} onDelete={handleDelete} />
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    )
};

export default DauKyBLThuocLaComponent;
