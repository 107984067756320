import { Box, Button, Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Dispatch } from 'redux';
import { CapMoiSXRuouService } from '../../../../../api/CPBLR/CapMoiSXRuouService';
import { CAP_MOI_SX_RUOU_INIT } from '../../../../../api/CPBLR/Init/CapMoiSXRuouInit';
import { GiayPhepSXRuou, HoSoThuLyBLR } from '../../../../../api/CPBLR/model/HoSoThuLyBLR';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import ChiTietThongTinGPRuou from './ChiTietThongTinGPRuou';
import ChiTietTepDinhKemGPRuou from './ChiTietTepDinhKemGPRuou';
import { QuanLyDKRuouService } from '../../../../../api/CPBLR/QuanLyDKRuouService';
import { LichSuGiayPhepRuouDTO } from '../../../../../api/CPBLR/model/LichSuGiayPhepRuouDTO';
import ChiTietLichSuGPRuou from './ChiTietLichSuGPRuou';
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
interface ChiTietGPRuouProps {

}
const ChiTietGPRuou = (props: ChiTietGPRuouProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation();
    const [giayPhep, setGiayPhep] = useState<GiayPhepSXRuou>({} as GiayPhepSXRuou);
    const [hoSo, setHoSo] = useState<HoSoThuLyBLR>({ ...CAP_MOI_SX_RUOU_INIT });
    const capMoiSXRuouService = new CapMoiSXRuouService();
    const quanLyDKRuouService = new QuanLyDKRuouService();
    

    const history = useHistory();

    useEffect(() => {
        setGiayPhep(location?.state);
        loadHoSo(location?.state.hoSoThuLyId);
    }, []);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const loadHoSo = (hoSoThuLyId: number) => {
        dispatchLoading(showLoading());
        capMoiSXRuouService.getByHoSoThuLyId(hoSoThuLyId).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setHoSo(res?.data?.data);
            }
            dispatchLoading(hideLoading());
        }).catch(error => {
            dispatchLoading(hideLoading());
            console.log(error);
        });

    }
    console.log('firest', hoSo);
    return (
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Grid container style={{ float: 'left', paddingTop: '20px' }} item xs={12} className="grid-item" >
                        <div className="dash-title">
                            <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>{giayPhep.loaiGiayPhep === 'BLR' ? 'THÔNG TIN CHI TIẾT GIẤY PHÉP BÁN LẺ RƯỢU' : 'THÔNG TIN CHI TIẾT GIẤY PHÉP SẢN XUẤT RƯỢU'}</h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChange} >
                                <CustomTab label="Thông tin" />
                                <CustomTab label="Lịch sử" />
                            </CustomTabs>
                        </Box>

                    </Grid>
                    {
                        indexTab === 0 &&
                        <TabPanel value={indexTab} index={0}>
                            <ChiTietThongTinGPRuou hoSo={hoSo} />
                        </TabPanel>
                    }
                    {
                        indexTab === 1 &&
                        <TabPanel value={indexTab} index={1}>
                            <ChiTietLichSuGPRuou giayPhepId ={giayPhep.id}/>
                        </TabPanel>
                    }
                    <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
                    </Grid>
                </Grid>

            }
        </>
    )
}
export default ChiTietGPRuou;
