export default class ValidatorUtil {
    static validatorSoGiayTo(loaiGiayTo: number, soGiayTo: string): boolean {
        if (loaiGiayTo == 1) {
            const regExpNumber = new RegExp("^([0-9]{9}|[0-9]{12})$");
            if (!regExpNumber.test(soGiayTo)) {
                return false;
            }
        }
        if (loaiGiayTo == 2) {
            const regExpNumber = new RegExp("^[0-9]{12}$");
            if (!regExpNumber.test(soGiayTo)) {
                return false;
            }
        }
        if (loaiGiayTo == 3) {
            const regExpNumber = new RegExp("^[A-Z][0-9]{7}$");
            if (!regExpNumber.test(soGiayTo)) {
                return false;
            }
        }
        return true;
    }

}
