import { Modal } from 'react-bootstrap';
import React from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import StringUtil from '../../../../utils/stringUtils';
import GiayToVeDatDaiService from '../../../../../api/CPXD/GiayToVeDatDaiService';
import { ICONS } from '../../../../Constants/constants';
import { Button } from '@material-ui/core';
export default class GiayToVeDatDaiAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            giayToVeDatDai: {
                id: 0,
                noiDungGiayTo: '',
                hoatDong: true,
            },
            show :  false,
            errors : {
                noiDungGiayTo : '',
            }
        }
        this.giayToVeDatDaiService = new GiayToVeDatDaiService();
    }
    componentDidUpdate(){
        if(this.props.show != this.state.show){
            this.state.show = this.props.show;
            this.setState(this.state);
        }
        if(this.props.data != this.state.giayToVeDatDai){
            this.state.giayToVeDatDai = this.props.data;
            this.setState(this.state);
        }
    }
    handleCloseModal =()=> {
        this.clearData();
        this.props.onModalToggle();
    }
    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.state.giayToVeDatDai[name] = value;
        this.state.errors={
            noiDungGiayTo : ''
        }
        this.setState(this.state);
    }
    handleSubmit = () => {
        if(this.validation()){
            this.giayToVeDatDaiService.insertOrUpdate(this.state.giayToVeDatDai).then(res => {
                if (res) {
                    this.handleCloseModal();
                    this.props.onUpdateData();
                    toastr.success('Thêm mới thành công');
                } else {
                    toastr.error('Thêm thất bại');
                }
            });
          
        }  
    }
    validation = () => {
        let valid = true;
        this.state.errors = {};
        if(StringUtil.isNullOrEmty(this.state.giayToVeDatDai.noiDungGiayTo)){
            this.state.errors.noiDungGiayTo = 'Vui lòng nhập nội dung';
            valid = false;
        }
        this.setState(this.state);
        return valid;
    }
    clearData = () => {
        this.state.giayToVeDatDai=  {
            id: 0,
            noiDungGiayTo: '',
            hoatDong: true,
        };
        this.state.errors = {
            noiDungGiayTo : '',
        };
        this.setState(this.state);
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleCloseModal} on size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-header" style={{ backgroundColor: "#aaaaaa", padding: '0.5rem' }}>
                    <h5 className="modal-title"  style={{ fontWeight: 'bold', color: "white" }}>THÊM MỚI GIẤY TỜ ĐẤT ĐAI</h5>
                    <button type="button" className="close"  aria-label="Close" onClick={this.handleCloseModal}>
                        <span aria-hidden="true"><img src={ICONS.delete}></img></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                    <label htmlFor="noiDungGiayTo" style={{ fontWeight: 'bold', fontSize: ' ' }}>Nội dung gợi ý <span style={{ color: 'red' }}>(*)</span></label>
                                        <input type="text" value={this.state.giayToVeDatDai?.noiDungGiayTo} className="form-control" id="noiDungGiayTo" name="noiDungGiayTo" onChange={this.handleChange} />
                            </div>
                            <div style={Boolean(this.state.errors?.noiDungGiayTo) ? { display: 'block' } : {}} className="invalid-feedback" >
                                        <span style={{marginBottom:"5px"}}>{this.state.errors?.noiDungGiayTo}</span> 
                            </div> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="text-right col-md-12">
                            <Button onClick={this.handleSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
                            <Button onClick={this.handleCloseModal} variant="contained" color="default" disableElevation>Đóng</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}