import * as Types from '../actions/actionTypes';

var initialState = {};
export const examineReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Types.UPDATE_EXAMINE:
            return action.examine;
        default:
            return {...state};    
    }
}