import { Box, Checkbox, FormHelperText, Grid, InputBase, InputLabel, MenuItem, NativeSelect, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { CoQuanDTO, DanhMucService } from '../../../api/danhMuc/danhMucService';
import { DialogContent, DialogTitle } from '../../../App/commons/components/dialog/dialogCommons';
import { hideLoading, LoadingAction, showLoading } from '../../../redux/actions/applicationAction';
import { Autocomplete } from "@material-ui/lab";
import StringUtil from '../../../App/utils/stringUtils';
import { DanhMucDiaChiDTO } from '../../../api/danhMuc/model/DanhMucDTO';
interface IModalProps {
    onSubmitData: (content: string, isSuccess: boolean) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: CoQuanDTO;
}

type ErrorValidation = {
    name: string;
}

export const EditDialog = (props: IModalProps) => {

    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [openDialog, setOpenDialog] = useState(false);
    const [itemEditDTO, setItemEditDTO] = useState<CoQuanDTO>({} as CoQuanDTO);
    const [submit, setSubmit] = useState(false);
    const [dataQuanHuyen, setDataQuanHuyen] = useState<DanhMucDiaChiDTO[]>([]);
    const [quanHuyen, setQuanHuyen] = useState<DanhMucDiaChiDTO>({} as DanhMucDiaChiDTO);
    const service = new DanhMucService();

    useEffect(() => {
        setItemEditDTO(props.itemEdit);
        setOpenDialog(props.openDialog);
        setQuanHuyen({ name: props.itemEdit.tenQuanHuyen, code: props.itemEdit.maQuanHuyen } as DanhMucDiaChiDTO);
    }, [props.itemEdit, props.openDialog]);

    useEffect(() => {
        async function getDm() {
            await service.getDanhMucDiaChi(String(92)).then((resp) => {
                setDataQuanHuyen(resp);
            });
        }
        getDm();
    }, []);
    const submitData = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(quanHuyen.name)) {
            return;
        }
        if (StringUtil.isNullOrEmty(quanHuyen.code)) {
            return;
        }
        dispatchLoading(showLoading());
        service.configCoQuan(itemEditDTO.id, quanHuyen.code, quanHuyen.name).then(res => {
            if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                props.onSubmitData("Yêu cầu thực hiện thành công", true);
            } else {
                props.onSubmitData(res.data.error_message, false);
            }
        }).catch(error => {
            props.onSubmitData('Yêu cầu thực hiện thất bại', false);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });

    }

    const onResetData = () => {
        setItemEditDTO({
            hoatDong: true,
            id: itemEditDTO.id,
            kyHieu: '',
            ten: '',
            ma: '',
            thamGiaHeThong: '',
            maQuanHuyen: '',
            tenQuanHuyen: ''
        });
        setQuanHuyen({ name: '', code: '' });
    }

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    Cấu hình
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid spacing={2} container>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete
                                    id="maHuyen"
                                    options={dataQuanHuyen}
                                    value={{ code: (quanHuyen && quanHuyen.code) ? quanHuyen.code : "", name: (quanHuyen && quanHuyen.name) ? quanHuyen.name : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => setQuanHuyen(newValue)}
                                    renderInput={(params) => {
                                        return (
                                            <div className="padding-top-8px" ref={params.InputProps.ref}>
                                                <InputBase fullWidth error={submit && StringUtil.isNullOrEmty(quanHuyen?.code)} {...params.inputProps} />
                                            </div>
                                        );
                                    }}
                                />
                                {submit && StringUtil.isNullOrEmty(quanHuyen.code) && (
                                    <FormHelperText className="-error">Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} className="gridItem">
                                <Box marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={() => { submitData() }}>
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={onResetData} disableElevation >
                                        Xóa
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation
                                        onClick={props.onDialogClose}>
                                        Hủy
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}