import StringUtil from '../../App/utils/stringUtils';
import * as Types from '../actions/actionTypes';
const initialState = {
    listLand: Array<any>(), total: 0, total2: 0,delete: Array<any>(), listLand2: Array<any>()
};
export const landsReducer = (state = initialState, action: any) => {
    const { index, land } = action;
    switch (action.type) {
        case Types.ADD_LAND:
            state.listLand.push(land);
            state.total = state.listLand ? state.listLand.length : 0 ; 
            return {
                ...state,
            };
        case Types.ADD_LAND_TWO:
            state.listLand2.push(land);
            state.total2 = state.listLand2 ? state.listLand2.length : 0 ;   
            return {
                ...state,
            };
        case Types.UPDATE_LAND:
            state.listLand[index] = {
                ...state.listLand[index],
                motNua : ! state.listLand[index].motNua    
            };
            state.total=  StringUtil.isEmty(state.listLand) ?  0 : state.listLand.length   ;
            return {
                ...state,     
            };
        case Types.UPDATE_LAND_TWO:
            state.listLand2[index] = {
                ...state.listLand2[index],
                motNua : ! state.listLand2[index].motNua    
            };
            state.total2=  StringUtil.isEmty(state.listLand2) ?  0 : state.listLand2.length   ;
            return {
                ...state,     
            };
        case Types.DELETE_LAND:
            state.listLand.splice(index, 1);
            state.total  = StringUtil.isEmty( state.listLand) ?  0 : state.listLand.length   ; 
            return {
                ...state,     
            };
        case Types.DELETE_LAND_TWO:
            state.listLand2.splice(index, 1);
            state.total2  = StringUtil.isEmty( state.listLand2) ?  0 : state.listLand2.length   ; 
            return {
                ...state,     
            };
        case Types.DELETE_LAND_BY_ID:
            state.delete.push(land);   
            return {
                ...state,
            };    
        case Types.ADD_ALL_LAND :
            const {lands} = action;
            const newList = [...lands];
            state.total  = StringUtil.isEmty(newList) ?  0 : newList.length   ; 
            return {
                ...state,
                listLand : newList
            };     
        case Types.ADD_ALL_LAND_TWO :
            const {lands2} = action;
            const newList2 = [...lands2];
            state.total2  = StringUtil.isEmty(newList2) ?  0 : newList2.length   ; 
            return {
                ...state,
                listLand2 : newList2
            };     
        default:
            return {
                ...state,
            };
    }
};