import { Box, Button, ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import React, { useEffect, useRef } from "react";
import { Link, NavLink, useHistory } from 'react-router-dom';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ListIcon from '@material-ui/icons/List';
import { GiayPhepXayDungDaCapDTO } from '../../../../api/CPXD/model/CapPhepXayDungModel';
import EditIcon from '@material-ui/icons/Edit';
import { LOAIGIAYPHEP_DIDOI, LOAIGIAYPHEP_NORL, LOAIGIAYPHEP_SUACHUACAITAO, TINHTRANG_HOATDONG, TINHTRANG_HUY, TINHTRANG_THUHOI } from '../../../Constants/CapPhepXayDungConstants';
import { ROUTE_CPXD_CAPCONGTRINHCOTHOIHAN, ROUTE_CPXD_DIDOI, ROUTE_CPXD_NHAORIENGLE, ROUTE_CPXD_SUACHUACAITAO, ROUTE_CPXD_THUHOI_OR_HUY } from '../../../../routes/route-cn-cpxd';
import RefreshIcon from '@material-ui/icons/Refresh';

interface ThaoTacQuanLyGiayPhepCPXDProps {
    data: GiayPhepXayDungDaCapDTO;
}
const ThaoTacQuanLyGiayPhepCPXD = (props: ThaoTacQuanLyGiayPhepCPXDProps) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    let history = useHistory();

    useEffect(() => {
    }, []);
    const showHidePopper = async () => {
        setOpen(!open);
    }
    const handleClosePopper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const clickEdit = () => {
        let duongDan = ROUTE_CPXD_CAPCONGTRINHCOTHOIHAN.duongDan;
        if (props.data.maLoaiGiayPhep === LOAIGIAYPHEP_SUACHUACAITAO.ma) {
            duongDan = ROUTE_CPXD_SUACHUACAITAO.duongDan;
        } else if (props.data.maLoaiGiayPhep === LOAIGIAYPHEP_NORL.ma) {
            duongDan = ROUTE_CPXD_NHAORIENGLE.duongDan;
        } else if (props.data.maLoaiGiayPhep === LOAIGIAYPHEP_DIDOI.ma) {
            duongDan = ROUTE_CPXD_DIDOI.duongDan;
        }
        history.push(duongDan, { isNhapDauKy: true, giayPhepXayDaCapId: props.data.id });
    }
    const handleThuHoiOrHuy = (isThuHoi: boolean) => {
        history.push(ROUTE_CPXD_THUHOI_OR_HUY.duongDan, { giayPhepXayDungId: props.data.giayPhepXayDungId, giayPhepXayDungDaCapId: props.data.id, isThuHoi: isThuHoi });
    }
    return (
        <>

            <Button startIcon={<ListIcon />}
                size="small" color="primary" disableRipple
                aria-haspopup="true"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined} onClick={(e) => {
                    showHidePopper();
                }} >
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopper}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={(e) => setOpen(false)}>
                                    <MenuItem key={"menu-item-edit"} style={{ padding: 'unset' }} >
                                        <Button onClick={(e) => clickEdit()} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                            Chỉnh sửa
                                        </Button>
                                    </MenuItem>
                                    {
                                        props.data.maTrangThai === TINHTRANG_HOATDONG.ma &&
                                        (
                                            <MenuItem key={"menu-item-thuhoi"} style={{ padding: 'unset' }} onClick={(e) => handleThuHoiOrHuy(true)}>
                                                <Button startIcon={<RefreshIcon color="error" />} size="small" disableRipple>
                                                    Thu hồi
                                                </Button>
                                            </MenuItem>

                                        )
                                    }
                                    {
                                        props.data.maTrangThai === TINHTRANG_HOATDONG.ma &&
                                        (
                                            <MenuItem key={"menu-item-huy"} style={{ padding: 'unset' }}>
                                                <Button startIcon={<HighlightOffIcon color="error" />} size="small" disableRipple onClick={(e) => handleThuHoiOrHuy(false)}>
                                                    Hủy
                                                </Button>
                                            </MenuItem>

                                        )
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </>
    )
}
export default ThaoTacQuanLyGiayPhepCPXD;
