export type CapPhepXayDungConstants = {
    ten: string;
    ma: string;
}
export const LOAIBIENDONG_CAPMOI: CapPhepXayDungConstants = {
    ten: "Cấp mới",
    ma: "1"
}
export const LOAIBIENDONG_CAPLAI: CapPhepXayDungConstants = {
    ten: "Cấp lại",
    ma: "2"
}
export const LOAIBIENDONG_DIEUCHINH: CapPhepXayDungConstants = {
    ten: "Điều chỉnh",
    ma: "3"
}
export const LOAIBIENDONG_DIDOI: CapPhepXayDungConstants = {
    ten: "Di dời",
    ma: "4"
}
export const LOAIBIENDONG_GIANHAN: CapPhepXayDungConstants = {
    ten: "Gia hạn",
    ma: "5"
}
export const LOAIBIENDONG_HUY: CapPhepXayDungConstants = {
    ten: "Hủy",
    ma: "6"
}
export const LOAIBIENDONG_THUHOI: CapPhepXayDungConstants = {
    ten: "Thu hồi",
    ma: "7"
}
export const LIST_BIENDONG: CapPhepXayDungConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_CAPLAI,
    LOAIBIENDONG_DIEUCHINH,
    LOAIBIENDONG_DIDOI,
    LOAIBIENDONG_GIANHAN,
    LOAIBIENDONG_HUY,
    LOAIBIENDONG_THUHOI
]
export const TINHTRANG_HOATDONG: CapPhepXayDungConstants = {
    ten: "Hoạt động",
    ma: "1"
}
export const TINHTRANG_HETHAN: CapPhepXayDungConstants = {
    ten: "Hết hạn",
    ma: "2"
}
export const TINHTRANG_THUHOI: CapPhepXayDungConstants = {
    ten: "Thu hồi",
    ma: "3"
}
export const TINHTRANG_HUY: CapPhepXayDungConstants = {
    ten: "Hủy",
    ma: "4"
}
export const LIST_TINHTRANG: CapPhepXayDungConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_HETHAN,
    TINHTRANG_THUHOI,
    TINHTRANG_HUY
]
export const LOAIGIAYPHEP_DEFAULT: CapPhepXayDungConstants = {
    ten: "CHỌN LOẠI GIẤY PHÉP",
    ma: ""
}
export const LOAIGIAYPHEP_CTCTH: CapPhepXayDungConstants = {
    ten: "CẤP GIẤY PHÉP XÂY DỰNG CÔNG TRÌNH",
    ma: "1"
}
export const LOAIGIAYPHEP_NORL: CapPhepXayDungConstants = {
    ten: "CẤP GIẤY PHÉP XÂY DỰNG NHÀ Ở RIÊNG LẺ",
    ma: "2"
}
export const LOAIGIAYPHEP_DIDOI: CapPhepXayDungConstants = {
    ten: "CẤP GIẤY PHÉP DI DỜI CÔNG TRÌNH",
    ma: "3"
}
export const LOAIGIAYPHEP_SUACHUACAITAO: CapPhepXayDungConstants = {
    ten: "CẤP GIẤY PHÉP SỬA CHỮA, CẢI TẠO",
    ma: "4"
}
export const LIST_LOAIGIAYPHEP: CapPhepXayDungConstants[] = [
    LOAIGIAYPHEP_DEFAULT,
    LOAIGIAYPHEP_CTCTH,
    LOAIGIAYPHEP_NORL,
    LOAIGIAYPHEP_DIDOI,
    LOAIGIAYPHEP_SUACHUACAITAO
]
export const TK_THEODONVI: CapPhepXayDungConstants = {
    ten: "Thống kê theo địa chỉ cấp phép",
    ma: "1"
}
export const TK_THEOLOAIGIAYPHEP: CapPhepXayDungConstants = {
    ten: "Thống kê theo loại giấy phép",
    ma: "2"
}
export const TK_THEOTINHTRANG: CapPhepXayDungConstants = {
    ten: "Thống kê theo loại tình trạng",
    ma: "3"
}
export const LIST_THONGKE: CapPhepXayDungConstants[] = [
    TK_THEODONVI,
    TK_THEOLOAIGIAYPHEP,
    TK_THEOTINHTRANG
]

export const DUPLICATE_CODE = 'DUPLICATE_CODE';