import { useEffect, useState } from "react";
import { DanhMucI } from "../api/Common/model/CommonModel";
import { NguonKinhPhiService } from "../api/KhenThuong/danhMuc/nguonKinhPhiService";

export const useDanhMucNguonKinhPhi = () => {
    const [lstResult, setlstResult] = useState<DanhMucI[]>([]);

    useEffect(() => {
        async function func() {
            await new NguonKinhPhiService().getByHoatDong(true).then(p => {
                if (p && p.data && p.data.data && p.data.error_code == 'SUCCESSFUL') {
                    setlstResult( p?.data?.data);
                }
            })
        }
        func();
    }, [])
    
    return lstResult;
}