import {GiayPhepBLThuocLaDTO} from "../model/GiayPhepBLThuocLaDTO";

export const GIAY_PHEP_BL_THUOC_LA_INIT : GiayPhepBLThuocLaDTO  = {
    id: 0,
    hoSoThuLyId: 0,
    maBienDong: '',
    maTinhTrang: '',
    tenTinhTrang: '',
    soGiayPhep: '',
    soGiayPhepCu: '',
    ngayCapPhep: 0,
    ngayHetHanGP: 0,
    ghiChu: '',
    nguoiKy: '',
    ngayBienDong: 0,
    noiDungBienDong: '',
    lyDo: '',
    soLanCapLaiOrSuaDoi: 0,
    ngayCapLaiOrSuaDoi: 0,
    maHoSo:''
}

