import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Dispatch, useEffect, useState } from "react";
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  NativeSelect,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Pagination } from "@material-ui/lab";
import { CustomInput } from "../../../commons/components/input/customInput";
import { useTableFooter } from "../../../../hooks/useTableFooter";
import StringUtil from "../../../utils/stringUtils";
import moment from "moment";
import { Link } from "react-router-dom";
import { ThongKeService } from "../../../../api/DKKD/ThongKeService";
import { useDispatch } from "react-redux";
import { RequestBaoCaoThongKeDTO } from "../BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import {
  hideLoading,
  LoadingAction,
  showLoading,
} from "../../../../redux/actions/applicationAction";

interface IDataListProps {
  request: any;
  openDialog: boolean;
  onDialogClose: () => void;
}

export const ChiTietThongKeCapPhep = (props: IDataListProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [request, setRequest] = useState<any>({});
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch<Dispatch<LoadingAction>>();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSize(Number(event.target.value));
  };

  useEffect(() => {
    setRequest(props.request);
    setTotal(props.request.total);
    console.log(props.request);
  }, []);

  useEffect(() => {
    setOpenDialog(props.openDialog);
  }, [props.openDialog]);

  useEffect(() => {
    loadDataTable();
  }, [page, size]);

  const loadDataTable = async () => {
    dispatch(showLoading());
    await new ThongKeService()
      .chiTietThongKe({
        ...props.request,
        pageNumber: page,
        pageSize: size,
      })
      .then((p) => {
        setRows(p.rows);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const getTypeName = (type: string) => {
    switch (type) {
      case "CM":
        return "cấp mới";
      case "CL":
        return "cấp lại";
      case "DC":
        return "cấp đổi";
      case "TN":
        return "tạm ngưng";
      case "CD":
        return "chấm dứt";
      default:
        return "";
    }
  };

  return (
    <Dialog
      onClose={props.onDialogClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <DialogTitle id="customized-dialog-title">
        <Box display="flex" alignItems="center" padding={0}>
          <Box flexGrow={1} style={{ fontSize: 15 }}>
            Thống kê tình hình {getTypeName(props.request.tinhTrang)} giấy phép
            kinh doanh{" "}
            {props.request.tenPhuongXa != null
              ? props.request.tenPhuongXa
              : props.request.tenQuanHuyen}
          </Box>
          <Box>
            <IconButton onClick={props.onDialogClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <Cell component="th" scope="row" align="center" width="5%">
                  STT
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Số giấy chứng nhận
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Số giấy tờ tùy thân
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Ngày cấp
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Họ tên
                </Cell>
                <Cell component="th" scope="row" align="center" width="12%">
                  Tên hộ kinh doanh
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Địa chỉ
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Vốn đầu tư
                </Cell>
                <Cell component="th" scope="row" align="center" width="10%">
                  Trạng thái
                </Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, i) => (
                  <Row key={row.id}>
                    <Cell scope="row" align="center">
                      {page * size + i + 1}
                    </Cell>
                    <Cell align="left">
                      <Link
                        to={{
                          pathname:
                            "/giay-phep-kinh-doanh/chi-tiet-giay-phep-kinh-doanh",
                          state: { ...row, hoSoThuLyId: row?.id },
                        }}
                      >
                        {row.soGiayPhep}
                      </Link>
                    </Cell>
                    <Cell align="center">{row.soGiayToTuyThan}</Cell>
                    <Cell align="center">
                      {row.ngayCapPhep !== 0 && row.ngayCapPhep !== null
                        ? moment(row.ngayCapPhep).format("DD/MM/YYYY")
                        : ""}
                    </Cell>
                    <Cell align="center">{row.hoTen}</Cell>
                    <Cell align="center">{row.tenHoKinhDoanh}</Cell>
                    <Cell align="left">{row.diaChiDayDu}</Cell>
                    <Cell align="center">
                      {StringUtil.formatForDot(row.tongVon)}
                    </Cell>
                    <Cell align="center">{row.tenTinhTrang}</Cell>
                  </Row>
                ))
              ) : (
                <Row>
                  <Cell colSpan={10} scope="row" align="center">
                    {" "}
                    Không có dữ liệu
                  </Cell>
                </Row>
              )}
            </TableBody>
          </Table>
          <Box
            paddingTop="10px"
            display="flex"
            alignItems="center"
            fontSize="14px"
            color="#555555"
          >
            <Pagination
              count={Math.ceil(total / size)}
              defaultPage={1}
              siblingCount={1}
              variant="outlined"
              size="medium"
              color="primary"
              shape="rounded"
              showFirstButton
              showLastButton
              onChange={onChangePage}
            />
            <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
              <Box component="span" paddingX="5px">
                Số mẫu tin trên trang
              </Box>
              <NativeSelect
                input={<CustomInput />}
                onChange={onChangeRowsPerPage}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>20</option>
              </NativeSelect>
            </Box>
            <Box component="span" paddingX="5px">
              Mẫu tin {page * size + 1} -{" "}
              {size * (page + 1) > total
                ? total
                : size * (page + 1)}{" "}
              / {total}
            </Box>
          </Box>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};
