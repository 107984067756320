
import axios from 'axios';
import { resetAuthenticatedSession } from '../actions/session';
import { AUTHORIZE_REQUEST } from '../App/Components/Login/sso/config';
import StringUtil from '../App/utils/stringUtils';
const HOST_API = {
  DEV: "http://localhost:5001/qh-apis",
  FIS: "http://118.69.123.51:9009/qh-apis",
  LIVE: "https://quanhuyen.congchuc.cantho.gov.vn/qh-apis",
  KIEMTHU: "http://14.238.93.157:8082/qh-apis"
}
const AXIOS_INSTANCE = axios.create({
  baseURL: HOST_API.LIVE,
  timeout: 600000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, csrf-token',
    'Access-Control-Expose-Headers': 'csrf-token',
  }
});

AXIOS_INSTANCE.interceptors.request.use((request) => {
  if (!StringUtil.isNullOrEmty(localStorage.getItem("csrf-token"))) {
    let headers = {
      ...request.headers,
      'csrf-token': localStorage.getItem("csrf-token"),
    };
    request.headers = { ...headers };

  }
  return request;
}, error => {
})

AXIOS_INSTANCE.interceptors.response.use((response) => {
  if (!StringUtil.isNullOrEmty(response.headers['csrf-token'])) {
    localStorage.setItem('csrf-token', response.headers['csrf-token']);
  }
  return response;
}, error => {
  if (error.response && error.response.status == 401 || error.response.status == 403 || error.response.status == 406) {
    if (localStorage.getItem("PRIVATE_LOGIN") && localStorage.getItem("PRIVATE_LOGIN") === "1") {
      window.location.href = '/login';
    } else {
      resetAuthenticatedSession();
      window.location.href = AUTHORIZE_REQUEST;
    }

  } else {
    //todo
  }
});
// XMLHttpRequest.prototype.open = (method: string, url: string | URL, async: boolean, username?: string | null, password?: string | null): void => {

// }

export default AXIOS_INSTANCE;
