import { Box, Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Dispatch } from 'redux';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { CapPhepXayDungService } from '../../../../../api/CPXD/CapPhepXayDungService';
import { GIAY_PHEP_XAY_DUNG_DA_CAP_DTO_INIT, GIAY_PHEP_XAY_DUNG_INIT, THU_HOI_HUY_DTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { GiayPhepXayDungDaCapDTO, GiayPhepXayDungDTO, ThuHoiHuyDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { hideLoading, LoadingAction, showLoading } from '../../../../../redux/actions/applicationAction';
import { ConfirmationDialogRaw } from '../../../../commons/components/dialog/confirmDialog';
import { OverlayLoading } from '../../../../commons/components/overlayLoading/overlayLoading';
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import { LOAIBIENDONG_HUY, LOAIBIENDONG_THUHOI, TINHTRANG_HUY, TINHTRANG_THUHOI } from '../../../../Constants/CapPhepXayDungConstants';
import StringUtil from '../../../../utils/stringUtils';
import ChuDauTu from '../ChiTiet/ChuDauTu/ChuDauTu';
import GiayToVeDatDai from '../ChiTiet/GiayToDatDai/GiayToVeDatDai';
import LichSu from '../ChiTiet/LichSu/LichSu';
import TepTinDinhKem from '../ChiTiet/TepTin/TepTinDinhKem';
import ThongTinDeXuat from '../ChiTiet/ThongTinDeXuat/ThongTinDeXuat';
import ThongTinThamDinh from '../ChiTiet/ThongTinThamDinh/ThongTinThamDinh';
import ChiTietNoiDungThuHoiComponent from './ChiTietNoiDungThuHoiComponent';
import ThuHoiButtonComponent from './ThuHoiButtonComponent';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const ThuHoiGiayPhepXayDung = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation();
    const [data, setData] = useState<GiayPhepXayDungDTO>({ ...GIAY_PHEP_XAY_DUNG_INIT });
    const [giayPhepXayDungDaCap, setGiayPhepXayDungDaCap] = useState<GiayPhepXayDungDaCapDTO>({ ...GIAY_PHEP_XAY_DUNG_DA_CAP_DTO_INIT });
    const [thuHoiHuy, setThuHoiHuy] = useState<ThuHoiHuyDTO>({...THU_HOI_HUY_DTO_INIT});
    const [tabRequired, setTabRequired] = useState(-1);
    const [submit, setSubmit] = useState(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const capPhepXayDungService = new CapPhepXayDungService();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    useEffect(() => {
        initData();
    }, []);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const initData = async () => {
        let giayPhepXayDungId = location?.state?.giayPhepXayDungId;
        let giayPhepXayDungDaCapId = location?.state?.giayPhepXayDungDaCapId;
        let isThuHoi  = location?.state?.isThuHoi;
        const request = {
            giayPhepXayDungId : giayPhepXayDungId,
            giayPhepXayDungDaCapId : giayPhepXayDungDaCapId,
            maTinhTrang : isThuHoi ? TINHTRANG_THUHOI.ma : TINHTRANG_HUY.ma
        }
        dispatchLoading(showLoading());
        /* Thông tin thu hồi hủy */
        await capPhepXayDungService.chiTietThuHoiHuy(request).then(res => {
            let data = res?.data?.data;
            if (data && data !== undefined && data.id > 0) {
                setThuHoiHuy(data); 
            }else{
                const dataNew = {
                    ...thuHoiHuy,
                    giayPhepXayDungId : giayPhepXayDungId,
                    giayPhepXayDungDaCapId : giayPhepXayDungDaCapId,
                    maTinhTrang : isThuHoi ? TINHTRANG_THUHOI.ma : TINHTRANG_HUY.ma,
                    tenTinhTrang : isThuHoi ? TINHTRANG_THUHOI.ten : TINHTRANG_HUY.ten,
                    maLoaiBienDong : isThuHoi ? LOAIBIENDONG_THUHOI.ma : LOAIBIENDONG_HUY.ma,
                    tenLoaiBienDong :isThuHoi ? LOAIBIENDONG_THUHOI.ten : LOAIBIENDONG_HUY.ten,
                }
                setThuHoiHuy(dataNew);
            }
        }).catch(error => {
            setMessage('Lỗi kết nối máy chủ');
            setSeverity("error");
            setShowAlert(true);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
        /* Thông tin giấy phép */    
        if (giayPhepXayDungDaCapId > 0) {
            await new CapPhepXayDungService().getGiayPhepXayDungDaCapById(giayPhepXayDungDaCapId).then(res => {
                if (res?.data?.data?.giayPhepXayDungDTO !== null && res?.data?.data?.giayPhepXayDungDTO !== undefined) {
                    const dataNew = {
                        ...res?.data?.data?.giayPhepXayDungDTO,
                    }
                    const giayPhepXayDungDaCap= {
                        ...res?.data?.data,
                    }
                    setData(dataNew);
                    setGiayPhepXayDungDaCap(giayPhepXayDungDaCap);
                    
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        let dataNew = {
            ...thuHoiHuy,
            [name]: value
        };
       setThuHoiHuy(dataNew);
    }
    const handleSubmit = async () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(thuHoiHuy.soQuyetDinh)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(thuHoiHuy.ngayQuyetDinh)) {
            setTabRequired(0);
            return;
        }
        dispatchLoading(showLoading());
        await capPhepXayDungService.insertOrUpdateThuHoi(thuHoiHuy).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL' && parseInt(res.data.data) > 0) {
                    setDataParam('id', parseInt(res.data.data));
                    setMessage('Yêu cầu thực hiện thành công');
                    setSeverity("success");
                    setShowAlert(true);
                } else {
                    setMessage(res.data.error_message);
                    setSeverity("error");
                    setShowAlert(true);
                }
            } else {
                setMessage('Yêu cầu thực hiện thất bại');
                setSeverity("error");
                setShowAlert(true);
            }
        }).catch(error => {
            setMessage('Yêu cầu thực hiện thất bại');
            setSeverity("error");
            setShowAlert(true);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const resetData = () => {
        const dataNew = {
            ...thuHoiHuy,
            files : new Array<FileEntry>(), 
            soQuyetDinh : '',
            ngayQuyetDinh : 0,
            nguoiKy : '',
            lyDoHuy: '',
            ghiChu: ''
        }
        setThuHoiHuy(dataNew);
    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            setMessage('Yêu cầu thực hiện thành công');
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    }
    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar open={showAlert} onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <Alert onClose={() => setShowAlert(false)} severity={severity as Color}>{message}</Alert>
                    </Snackbar>

                    <Grid container style={{ float: 'left', paddingTop: '20px' }} item xs={12} className="grid-item" >
                        <div className="dash-title">
                            <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>{data?.tenLoaiGiayPhep}</h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChange} >
                                <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Nội dung giấy phép" />
                                <CustomTab label="Chủ đầu tư" />
                                <CustomTab label="Giấy tờ về đất đai" />
                                <CustomTab label="Thông tin thẩm định" />
                                <CustomTab label="Thông tin đề xuất" />
                                <CustomTab label="Tệp đính kèm" />
                                <CustomTab label="Lịch sử" />
                            </CustomTabs>
                        </Box>

                    </Grid>
                    {
                        indexTab === 0 &&
                        <TabPanel value={indexTab} index={0}>
                            <ChiTietNoiDungThuHoiComponent 
                               onUpdateData={setDataParam}   
                               data={data}
                               thuHoiHuy ={thuHoiHuy}
                               submit={submit}
                           />
                        </TabPanel>
                    }
                    {
                        indexTab === 1 &&
                        <TabPanel value={indexTab} index={1}>
                            <ChuDauTu data={data?.chuDauTuDTOs} />
                        </TabPanel>
                    }
                    {
                        indexTab === 2 &&
                        <TabPanel value={indexTab} index={2}>
                            <GiayToVeDatDai data={data?.giayToVeDat} />
                        </TabPanel>
                    }
                    {
                        indexTab === 3 &&
                        <TabPanel value={indexTab} index={3}>
                            <ThongTinThamDinh data ={data?.thongTinThamDinhDTOs}  />
                        </TabPanel>
                    }
                    {
                        indexTab === 4 &&
                        <TabPanel value={indexTab} index={4}>
                            <ThongTinDeXuat  
                               deXuat ={data?.deXuat} 
                               ketLuan = {data?.ketLuan}
                             />
                        </TabPanel>
                    }
                    {
                        indexTab === 5 &&
                        <TabPanel value={indexTab} index={5}>
                            <LichSu  
                              data ={giayPhepXayDungDaCap?.lichSuGiayPhepDTOs}
                              tenTrangThai = {data.tenTrangThai}
                              tenLoaiGiayPhep = {data.tenLoaiGiayPhep} 
                              />
                        </TabPanel>
                    }
                    <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <ThuHoiButtonComponent 
                            onSubmit={handleSubmit}
                            onOpenDialog={handleDelete}
                        />
                    </Grid>
                    {openDeleteUserDlg &&
                        <ConfirmationDialogRaw id="confirm-dialog-delete-cpxd" keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?" onClose={handleCloseDeleteInfo}/>   
                     }
                </Grid>
                
            }
        </>
        </div>
    )
}
export default ThuHoiGiayPhepXayDung;
