import AXIOS_INSTANCE from "../index";

export class DangKyKinhDoanhService {

    getByMaHoSo =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('dkkd/ho-so-thu-ly/by-ma-ho-so', requestBody);
    }

    insertOrUpdate =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('dkkd/ho-so-thu-ly/add-or-update', requestBody);
    }

    saveTamNgungHoatDongHKD = (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/dkkd/ho-so-thu-ly/save-tam-ngung-hd-hkd', requestBody);
    }

    saveChamDutHoatDongHKD = (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/dkkd/ho-so-thu-ly/save-cham-dut-hd-hkd', requestBody);
    }

    saveCapLaiGCNDangKyHKD = (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/dkkd/ho-so-thu-ly/save-cap-lai-gcn-dk-hkd', requestBody);
    }

    getGiayPhepKDBySoGP =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh/by-so-giay-phep', requestBody);
    }

    getChiTietGPKDById =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh/chi-tiet-giay-phep-hdkd', requestBody);
    }
    getChiTietGPKDByIdDauKy =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh/chi-tiet-giay-phep-dau-ky', requestBody);
    }

    getChiTietGPKDBySoGiayPhep = (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh/chi-tiet-giay-phep-by-so-giay-phep', requestBody);
    }

    getListGiayPhepCombo =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/danh-muc/danh-sach-so-giay-phep', requestBody);
    }

    getListGiayPhep =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('/giay-phep-kinh-doanh/tim-kiem', requestBody);
    }

	insertOrUpdateDauKy =  (requestBody : any)=> {
        return AXIOS_INSTANCE.post('dkkd/ho-so-thu-ly/add-or-update-dau-ky', requestBody);
    }

    downloadFile(request: any) {
        return AXIOS_INSTANCE.post('dkkd/ho-so-thu-ly/download', request, {
            responseType: 'blob',
        });
    }
    //Giấy phép đã cấp
    getInfoAutoBySoGiayPhep = (soGiayPhep : string,maTinhTrang : string) => {
        return AXIOS_INSTANCE.post('giay-phep-kinh-doanh/select-autocomplete-by-so-giay-phep-tinh-trang', {soGiayPhep : soGiayPhep,maTinhTrang : maTinhTrang});
    }

    getInfoByKeyWord = (soGiayToTuyThan : string) => {
        return AXIOS_INSTANCE.post('dkkd/ho-so-thu-ly/get-data-keyword', {soGiayToTuyThan : soGiayToTuyThan});
    }
    autocompleteBySoGiayPhep = (soGiayPhep: string,maTinhTrang : string, pageSize: number) => {
        let body = {
            soGiayPhep: soGiayPhep,
            maTinhTrang : maTinhTrang,
            pageSize: pageSize
        }
        return AXIOS_INSTANCE.post('giay-phep-kinh-doanh/autocomplete-by-so-giay-phep-tinh-trang', body);
    }
    autocompleteByThuTucChamDut = (soGiayPhep: string, pageSize: number) => {
        let body = {
            soGiayPhep: soGiayPhep,
            pageSize: pageSize
        }
        return AXIOS_INSTANCE.post('giay-phep-kinh-doanh/autocomplete-by-thu-tuc-cham-dut', body);
    }

    getByAutocompleteNoiCap(noiCap: string, limit: number) {
        let body = {
           noiCap: noiCap,
           limit: limit,
        };
        return AXIOS_INSTANCE.get("giay-phep-kinh-doanh/get-autocomplete-by-noi-cap", {
            params: body,
        });
    }

    autocompleteBySoNoiCap = (noiCap: string, pageSize: number) => {
        let body = {
            noiCap: noiCap,
            pageSize: pageSize
        }
        return AXIOS_INSTANCE.post('giay-phep-kinh-doanh/autocomplete-by-noi-cap', body);
    }

    getDataNhomCaNhans = async (hoSoThuLyId : number) => {
        return await AXIOS_INSTANCE.post('dkkd/ho-so-thu-ly/get-data-nhom-ca-nhan', {hoSoThuLyId  : hoSoThuLyId });
    }
}
