import { useEffect, useState } from "react";
import { DanhMucLoaiHinhKhenThuongDTO, DanhMucLoaiHinhKhenThuongRequest, DanhMucLoaiHinhKhenThuongService } from "../api/KhenThuong/danhMuc/danhMucLoaiHinhKhenThuong";

export const useLoaiHinhKhenThuong = (caNhanOrTapThe : boolean) => {
    const [lstResult, setlstResult] = useState<DanhMucLoaiHinhKhenThuongDTO[]>([]);

    useEffect(() => {
        async function func() {
            let request = { isActive: true, pageNumber: 0,  pageSize: 2147483647, caNhanOrTapThe: caNhanOrTapThe} as DanhMucLoaiHinhKhenThuongRequest;
            await new DanhMucLoaiHinhKhenThuongService().getDMLoaiHinhKhenThuong(request).then(p => {
                setlstResult(p.rows);
            })
        }
        func();
    }, [])
    return lstResult;
}