import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, Grid, InputBase, InputLabel } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import StringUtil from "../../../../utils/stringUtils";
import { HoSoThuLyBLThuocLa } from "../../../../../api/CPBLTL/model/HoSoThuLyBLThuocLa";
import { CAP_MOI_BL_THUOC_LA_INIT } from "../../../../../api/CPBLTL/init/CapMoiBLThuocLaInit";
import DropZoneCustom from "../../../../commons/components/CustomDropzone/DropZoneCustom";
import { FileEntry } from "../../../../../api/Common/fileEntry";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { FileServerService } from "../../../../../api/Common/FileServerService";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { SearchGiayPhepDTO } from "../../../../../api/CPBLTL/model/SearchGiayPhepDTO";
import moment from "moment";
import AXIOS_INSTANCE from '../../../../../api';
import { CapMoiBLThuocLaService } from '../../../../../api/CPBLTL/CapMoiBLThuocLaService';
import { TINHTRANG_HOATDONG } from '../../../../Constants/ThuocLaConstants';

const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

interface CapSuaDoiBLThuocLaNoiDungProps {
    data: HoSoThuLyBLThuocLa;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
    loadGiayPhepDaCap: (giayPhepId: number) => void;
}

const CapLaiBLThuocLaNoiDungComponent = (props: CapSuaDoiBLThuocLaNoiDungProps) => {
    const [data, setData] = React.useState({ ...CAP_MOI_BL_THUOC_LA_INIT } as HoSoThuLyBLThuocLa);
    const [listGiayPhep, setListGiayPhep] = React.useState([] as SearchGiayPhepDTO[]);
    const { onUpdateData, loadGiayPhepDaCap, submit } = props;
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const fileServerService = new FileServerService();
    useEffect(() => {
        setData(props.data);
        setAttachFileServer(props.data?.files);
    }, [props.data, props.onUpdateData]);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        onUpdateData("data", dataNew);
    }

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data?.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            files: mems
        };
        onUpdateData("data", dataNew);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let mems = [...data.files];
        let memTrashs = data.fileTrash ? data.fileTrash : [];
        if (item?.fileParentId > 0) {
            memTrashs.push(item);
        }
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            files: mems,
            fileTrash: memTrashs
        };
        setAttachFileServer(mems);
        onUpdateData("data", dataNew);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const getGiayPhepDaCapAutocomplete = (event: any) => {
        let soGiayPhepCuNew = event.target.value;
        let dataNew = {
            ...data,
            soGiayPhepCu: soGiayPhepCuNew
        };
        onUpdateData('data', dataNew);
        new CapMoiBLThuocLaService().autocompleteBySoGiayPhep(soGiayPhepCuNew, TINHTRANG_HOATDONG.ma, 10).then(res => {
            let dataAutocompleNew = res?.data.data;
            if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                setListGiayPhep([...dataAutocompleNew]);
            }
        }).catch(error => {
            console.log(error);
        });
    }
    const handleSelectGiayPhepDaCap = (newValue: SearchGiayPhepDTO) => {
        loadGiayPhepDaCap(newValue.id);
    }

    const handleChangeNumber =  (e: any) => {
        let value =  e.target.value;
        onUpdateData(e.target.name, StringUtil.checkInputNumber(value) || value === "" ? value :  "");
    }
    
    return (
        <>
            {
                <Box padding={0}>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required>Số giấy phép </InputLabel>
                                <Autocomplete id="soGiayPhep"
                                    noOptionsText="Không có dữ liệu"
                                    options={listGiayPhep}
                                    getOptionLabel={(option) => option?.soGiayPhep}
                                    onChange={(event: any, newValue: any) => handleSelectGiayPhepDaCap(newValue)}
                                    renderInput={(params) => {
                                        return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                            <InputBase fullWidth
                                                {...params.inputProps}
                                                value={data?.soGiayPhepCu}
                                                error={submit && StringUtil.isNullOrEmty(data?.soGiayPhepCu)}
                                                onChange={(e: any) => getGiayPhepDaCapAutocomplete(e)}

                                            />
                                        </div>
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.soGiayPhepCu) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập số giấy phép </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày cấp </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.ngayCapPhep !== undefined && data.ngayCapPhep > 0) ? new Date(data.ngayCapPhep) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapPhep')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel>Ngày hết hạn </InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data.ngayHetHanGP !== undefined && data.ngayHetHanGP > 0) ? new Date(data.ngayHetHanGP) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayHetHanGP')}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel >Tên thương nhân</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>{data?.tenThuongNhan}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>Địa chỉ</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>{data?.diaChiDayDu}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel >Điện thoại</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>{data?.dienThoai}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel >Fax</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>{data?.fax}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>Số Giấy CNĐKDN/CNĐKKD</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>{data?.soCNDKKD}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel >Ngày cấp</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>{(data.ngayCapPhep != null && data.ngayCapPhep > 0) ? moment(data.ngayCapPhep).format("DD/MM/YYYY") : ''}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>Nơi cấp</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item" hidden={!(data.giayPhepId > 0)}>
                                <InputLabel>{data?.noiCapCNDKKD}</InputLabel>
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel required>Lý do cấp lại</InputLabel>
                                <InputBase fullWidth
                                    error={submit && StringUtil.isNullOrEmty(data?.lyDo)}
                                    value={data?.lyDo} name="lyDo" onChange={handleChange} />
                                {
                                    submit && StringUtil.isNullOrEmty(data?.lyDo) &&
                                    <FormHelperText className="-error">Vui lòng nhập lý do cấp lại</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Lần cấp lại</InputLabel>
                                <InputBase fullWidth 
                                    value={data?.soLanCapLaiOrSuaDoi} name="soLanCapLaiOrSuaDoi" onChange={handleChangeNumber} type="number" />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel>Ngày cấp lại</InputLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={(data?.ngayCapLaiOrSuaDoi !== undefined && data?.ngayCapLaiOrSuaDoi > 0) ? new Date(data?.ngayCapLaiOrSuaDoi) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, 'ngayCapLaiOrSuaDoi')}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={`${acceptFiles}`}
                                    attachFileServer={attachFileServer}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/thuocla"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
        </>
    )
};

export default CapLaiBLThuocLaNoiDungComponent;
