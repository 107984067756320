import React, { useEffect, useState } from "react";
import { Box, Button, NativeSelect } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Cell, Row } from "../../../commons/components/table/tableCommons";
import { CustomInput } from "../../../commons/components/input/customInput";
import { ThaoTacQuanLyDKKD } from './thaoTacQuanLyDKKD';
import moment from "moment";
import { Link } from "react-router-dom";
import StringUtil from "../../../utils/stringUtils";

interface TablePaginationActionsProps {
    page: number;
    count: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  
}
interface IDataListProps {
    dataList: any[];
    deleteItem?: (id: string) => void;
    pageProps: TablePaginationActionsProps;
    isAnUong : boolean;
}

export const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
        },
        sizeSmall: {
            fontSize: '13px'
        },
        startIcon: {
            marginRight: theme.spacing(0.5)
        },
        '&:focus': {
            outlineWidth: '0px',
            outlineStyle: 'none'
        }
    }),
)(Button);

export const DanhSachGiayPhepKD = (props: IDataListProps) => {

    const [rows, setRows] = useState<any[]>([]);
    const [totalPage, setTotalPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1);
    const isAnUong = props.isAnUong;
    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.page);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Số giấy chứng nhận</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Số giấy tờ tùy thân</Cell>
                        <Cell component="th" scope="row" align="center" width="8%">Ngày cấp</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Họ tên</Cell>
                        <Cell component="th" scope="row" align="center" width="12%">Tên hộ kinh doanh</Cell>
                        <Cell component="th" scope="row" align="center" width="12%">Ngành nghề kinh doanh</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Địa chỉ</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Vốn đầu tư</Cell>
                        <Cell component="th" scope="row" align="center" width="10%">Trạng thái</Cell>
                        <Cell component="th" scope="row" align="center" width="5%" hidden ={isAnUong}>Thao tác</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.length > 0 ?
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {(props.pageProps.page - 1) * props.pageProps.rowsPerPage + i + 1}
                                    </Cell>
                                    <Cell align="left">
                                        <Link to={{
                                            pathname: '/giay-phep-kinh-doanh/chi-tiet-giay-phep-kinh-doanh',
                                            state: { ...row, giayPhepId: row?.id }
                                        }}>
                                            {row.soGiayPhep}
                                        </Link>
                                    </Cell>
                                    <Cell align="center">{row.soGiayToTuyThan}</Cell>
                                    <Cell align="center">{(row.ngayCapPhep !== 0 && row.ngayCapPhep !== null) ? moment(row.ngayCapPhep).format("DD/MM/YYYY") : ''}</Cell>
                                    <Cell align="center">{row.hoTen}</Cell>
                                    <Cell align="center">{row.tenHoKinhDoanh}</Cell>
                                    <Cell align="center">{row.nganhNgheKD}</Cell>
                                    <Cell align="left">{row.diaChiDayDu}</Cell>
                                    <Cell align="center">{StringUtil.formatForDot(row.tongVon)}</Cell>
                                    <Cell align="center">{row.tenTinhTrang}</Cell>
                                    <Cell align="center" hidden ={isAnUong}>
                                        <ThaoTacQuanLyDKKD row={row} />
                                    </Cell>
                                </Row>
                            )) :
                            <Row>
                                <Cell colSpan={isAnUong ? 9 :  11} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
            {rows.length > 0 ?
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined" page={props.pageProps.page}
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={props.pageProps.onChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {((props.pageProps.page - 1) * props.pageProps.rowsPerPage) + 1} - {props.pageProps.rowsPerPage * rowsPerPage > props.pageProps.count ? props.pageProps.count : (props.pageProps.rowsPerPage * rowsPerPage)} / {props.pageProps.count}</Box>
                </Box>
                : ''
            }
        </TableContainer>
    )
}
