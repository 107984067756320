import { getObject } from "../../helpers/localStorage";
import { BaseResponse, BaseService, PageRequest, Pagination } from "../baseService";
import AXIOS_INSTANCE from "../index";
import { UserData } from "../userMgt/userManagementService";
import { DanhMucBieuMauDTO } from "./Model/danhMucBieuMau";

export interface RequestDM extends PageRequest {
    name?: String;
    isActive?: Boolean;
    maCoQuan?: string;
}

export class DanhMucBieuMauService extends BaseService {
    uploadFile = async (data: File): Promise<BaseResponse> => {
        let result: BaseResponse = {
            isSuccess: false,
            message: "",
        };
        let body = new FormData();
        body.append("file", data);
        await AXIOS_INSTANCE
            .post("/file-server/upload/bieumau", body, {
                headers: {
                    "Content-Type": "multipart/form-data;charset=UTF-8",
                },
            }).then((response) => {
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data
                }
            })
            .catch(() => result = { ...result, message: 'Có lỗi xảy ra khi thực hiện upload file' });
        return result;
    };
    saveDanhMucBieuMau = async (data: DanhMucBieuMauDTO): Promise<BaseResponse> => {
        let result: BaseResponse = {
            isSuccess: false,
            message: "",
        };
        let C_USER_DATA_DE = getObject("C_USER_DATA") as UserData;
        await AXIOS_INSTANCE
            .post("danh-muc-bieu-mau/insertOrUpdate", { ...data, maCoQuan: data.maCoQuan ? data.maCoQuan : C_USER_DATA_DE.maCoQuan, tenCoQuan: data.tenCoQuan ? data.tenCoQuan : C_USER_DATA_DE.tenCoQuan }, this.getTokenRequestHeaders()).then((response) => {
                result = {
                    isSuccess: response.data.error_code == 'SUCCESSFUL',
                    message: response.data.error_message,
                    data: response.data.data
                }
            }).catch(() => result = { ...result, message: 'Có lỗi thực hiện kết nối tới server' });
        return result;
    };

    searchDanhMucBieuMau = async (data: RequestDM): Promise<Pagination<DanhMucBieuMauDTO[]>> => {
        let C_USER_DATA_DE = getObject("C_USER_DATA") as UserData;
        let result = {
            maCoQuan: data.maCoQuan ? data.maCoQuan : C_USER_DATA_DE.maCoQuan,
            page: data.pageNumber,
            size: data.pageSize,
            total: 0,
            rows: []
        }
        await AXIOS_INSTANCE
            .get("danh-muc-bieu-mau/search", {
                params: { ...data, maCoQuan: data.maCoQuan ? data.maCoQuan : C_USER_DATA_DE.maCoQuan },
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    result.rows = response.data.data.data;
                    result.total = response.data.data.total;
                }
            })
            .catch();
        return result;
    };
    getDetailDanhMucBieuMau = async (idBieuMau: Number): Promise<DanhMucBieuMauDTO> => {
        let result = {} as DanhMucBieuMauDTO;
        await AXIOS_INSTANCE
            .get(`danh-muc-bieu-mau/get-by-id/${idBieuMau}`, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    result = { ...response.data.data };
                }
            })
            .catch();
        return result;
    };
    deleteBieuMau = async (item: DanhMucBieuMauDTO): Promise<BaseResponse> => {
        let result: BaseResponse = { isSuccess: false, message: "" };
        await AXIOS_INSTANCE
            .post(`danh-muc-bieu-mau/delete`, { id: item.id }, this.getTokenRequestHeaders())
            .then((response) => {
                result = {
                    isSuccess: response.data.error_code == 'SUCCESSFUL',
                    message: response.data.error_message,
                    data: response.data.data
                }
            }).catch(() => result = { ...result, message: 'Có lỗi thực hiện kết nối tới server' });
        return result;
    };
    findDanhSachBieuMauByPath = (path: string) => {
        let C_USER_DATA_DE = getObject("C_USER_DATA") as UserData;
        return AXIOS_INSTANCE.post(`danh-muc-bieu-mau/find-danh-sach-bieu-mau-by-path`, { maCoQuan: C_USER_DATA_DE.maCoQuan, path: path }, this.getTokenRequestHeaders());
    };
}
