import { Box, Grid, Paper, Snackbar, Typography } from "@material-ui/core";
import { loadModules } from "esri-loader";
import { BrowserHistory } from "history";
import React, { Dispatch, useEffect, useState } from "react";
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { DanhMucService } from "../../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../api/danhMuc/model/DanhMucDTO";
import { DangKyKinhDoanhService } from "../../../../../api/DKKD/DangKyKinhDoanhService";
import { HoSoThuLyDTO_INIT } from "../../../../../api/DKKD/init/HoSoThuLyInit";
import { HoSoThuLyDTO } from "../../../../../api/DKKD/model/hoSoThuLyDTO";
import { ThanhVienGopVonDTO } from "../../../../../api/DKKD/model/thanhVienGopVonDTO";
import { BieuMauAttachFileDTO } from "../../../../../api/QuanTri/Model/danhMucBieuMau";
import { useGetThongTinChuyenNganh } from "../../../../../pages/hoSoCuaToi/useGetThongTinChuyenNganh";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../commons/components/tabs";
import { API_KEY_MAP } from "../../../../Constants/const";
import { LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from "../../../../Constants/DKKDConstants";
import DateUtil from "../../../../utils/dateUtils";
import StringUtil from "../../../../utils/stringUtils";
import { ThongTinChuyenNhanhUtil } from "../../../../utils/thongTinChuyenNhanhUtil";
import ValidatorUtil from "../../../../utils/validatorUtil";
import { ThanhLapHKDThanhVienGopVon } from "../../Modal/ThanhLapHKDThanhVienGopVon";
import ThanhLapHKDCapMoiNoiDungComponent from "./ThanhLapHKDCapMoiNoiDungComponent";
import { useDispatch } from 'react-redux';
import { OverlayLoading } from "../../../../commons/components/overlayLoading/overlayLoading";
import DanhSachDuThaoModal from "../../thuly/danhSachDuThaoModal";
import { HoSoCuaToiMgtService, ThongTinBuocDTO, ThongTinChuyenNganhDTO } from "../../../../../api/HoSoCuaToi/HoSoCuaToiMgtService";
import { UserData } from "../../../../../api/userMgt/userManagementService";
import ThuLyButtonComponent from "../../thuly/ThuLyButtonComponent";
import { LinhVucCapSoGPService } from "../../../../../api/DKKD/LinhVucCapSoGPService";
import { DanhMucGP } from "../../../../../api/Common/model/CommonModel";
import { boolean } from "yup/lib/locale";
import { DKKD_CN } from "../../../../Constants/constants";
import { NhomCaNhanDTO } from '../../../../../api/DKKD/model/hoSoThuLyDTO';

type StateHoSo = {
    maThuTuc: string;
    maHoSo: string;
    cmnd: string;
};
type ThanhLapHkdProps = {
    history: BrowserHistory<StateHoSo>;
};
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
const ThanhLapHKDCapMoiComponent = (props: ThanhLapHkdProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [submit, setSubmit] = React.useState(false);
    const [showLayKetQua, setShowLayKetQua] = React.useState(false);
    const [tabRequired, setTabRequired] = React.useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [data, setData] = React.useState<HoSoThuLyDTO>({ ...HoSoThuLyDTO_INIT });
    const [diaChiKinhDo, setDiaChiKinhDo] = useState<Number>();
    const [diaChiViDo, setDiaChiViDo] = useState<Number>();
    const [ngayHenTra, setNgayHenTra] = useState<Number>(0);

    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const { history } = props;
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const service = new LinhVucCapSoGPService();
    let C_USER_DATA_DE = {} as UserData;
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    useEffect(() => {
        initData();
        // getAllGPTuDong();
    }, []);

    async function initData() {
        dispatch(showLoading());
        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        let maHoSo = history?.location?.state?.maHoSo;
        //Chi Tiet - Mot Cua
        let maGT_ChiTiet = "", tenGT_ChiTiet = "";
        let loaiGiayTo_ChiTiet = 2, tenGiayTo_ChiTiet = "";
        let soGiayToTuyThan_ChiTiet = "";
        let maTinh_ChiTiet = "", tenTinh_ChiTiet = "";
        let maQuan_ChiTiet = "", tenQuan_ChiTiet = "";
        let maPhuong_ChiTiet = "", tenPhuong_ChiTiet = "";
        let diaChiNguoiNop_ChiTiet = "";
        let choOHienTai_ChiTiet = "";
        if (!StringUtil.isNullOrEmty(C_USER_DATA_DE.maCoQuan)) {
            hoSoCuaToiMgtService.chiTiet(C_USER_DATA_DE.maCoQuan, history?.location?.state?.maHoSo).then(async (p) => {
                if (p.data && p.data.data) {
                    let dataMotCua = p.data.data;
                    hoTenNguoiNop = p.data.data.nguoiNop;
                    soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
                    let date = new Date(p.data.data.ngayHenTra);
                    setNgayHenTra(date.getTime());
                    //HienLT52 - Filter data một cửa điền vào form đăng ký
                    if(StringUtil.isNotEmpty(dataMotCua.gioiTinh)) {
                        switch (dataMotCua.gioiTinh) {
                            case "Nam":
                                maGT_ChiTiet = "1";
                                tenGT_ChiTiet = "Nam";
                                break;
                            case "Nữ":
                                maGT_ChiTiet = "2";
                                tenGT_ChiTiet = "Nữ";
                                break;
                            default:
                                maGT_ChiTiet = "3";
                                tenGT_ChiTiet = "Chưa có thông tin";
                                break;
                        }
                    }
                    if(StringUtil.isNotEmpty(dataMotCua.soCCCD)){
                        loaiGiayTo_ChiTiet = 2;
                        tenGiayTo_ChiTiet = "Căn cước công dân";
                        soGiayToTuyThan_ChiTiet = dataMotCua.soCCCD;
                    }
                    if (!StringUtil.isNullOrEmty(dataMotCua?.DiaChi_MaTinh)) {
                        let dataTinhThanh = await new DanhMucService().getDanhMucDiaChiByCode(dataMotCua?.DiaChi_MaTinh);
                        maTinh_ChiTiet = dataTinhThanh[0].code;
                        tenTinh_ChiTiet = dataTinhThanh[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataMotCua?.DiaChi_MaQuanHuyen)) {
                        let dataQuanHuyen = await new DanhMucService().getDanhMucDiaChiByCode(dataMotCua?.DiaChi_MaQuanHuyen);
                        maQuan_ChiTiet = dataQuanHuyen[0].code;
                        tenQuan_ChiTiet = dataQuanHuyen[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataMotCua?.DiaChi_MaQuanHuyen)) {
                        let dataPhuongXa = await new DanhMucService().getDanhMucDiaChiByCode(dataMotCua?.DiaChi_MaPhuongXa);
                        maPhuong_ChiTiet = dataPhuongXa[0].code;
                        tenPhuong_ChiTiet = dataPhuongXa[0].name;
                    }
                    if(!StringUtil.isNullOrEmty(dataMotCua?.diaChiNguoiNop)) {
                        diaChiNguoiNop_ChiTiet = dataMotCua?.diaChiNguoiNop;
                        choOHienTai_ChiTiet = diaChiNguoiNop_ChiTiet + " " + tenPhuong_ChiTiet + " " + tenQuan_ChiTiet + " " + tenTinh_ChiTiet;
                        console.log(choOHienTai_ChiTiet);
                    }
                }
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }
        if (StringUtil.isNotEmpty(maHoSo)) {
            dangKyKinhDoanhService.getByMaHoSo({ maHoSo: maHoSo }).then(async (res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    let resData = {
                        ...res?.data?.data,
                        maHoSo: history?.location?.state?.maHoSo,
                        maThuTuc: history?.location?.state?.maThuTuc,
                        tinhTrang: TINHTRANG_HOATDONG.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                        nhomCaNhans: res?.data?.data.nhomCaNhans != null ? res?.data?.data.nhomCaNhans : new Array<NhomCaNhanDTO>(),
                    };
                    setData(resData);
                    if (StringUtil.isNotEmpty(res.data.data.maHoSo) && StringUtil.isNotEmpty(res.data.data.soGiayPhep)){
                        setShowLayKetQua(true);
                    }
                    dispatch(hideLoading());
                } else {
                    let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHoSo);
                    let maQuan = "";
                    let tenQuan = "";
                    let maPhuong = "";
                    let tenPhuong = "";
                    let maQT = "", tenQT = "";
                    let maGT = "", tenGT = "";
                    if (!StringUtil.isNullOrEmty(dataEform?.quanhuyen)) {
                        let dataQuan = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                        maQuan = dataQuan[0].code;
                        tenQuan = dataQuan[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.phuongxa)) {
                        let dataPhuong = await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.phuongxa);
                        maPhuong = dataPhuong[0].code;
                        tenPhuong = dataPhuong[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.quoctich)) {
                        let dataQuocTich = await new DanhMucService().getDanhMucQuocTich(dataEform?.quoctich);
                        maQT = dataQuocTich[0].code;
                        tenQT = dataQuocTich[0].name;
                    }
                    if (!StringUtil.isNullOrEmty(dataEform?.gioitinh)) {
                        let dataGioiTinh = await new DanhMucService().getDanhMucGioiTinh(dataEform?.gioitinh);
                        maGT = dataGioiTinh[0].code;
                        tenGT = dataGioiTinh[0].name;
                    }
                    let soGiayPhepDayDu = "";
                    await service.getGPTuDong(C_USER_DATA_DE.maCoQuan, DKKD_CN.value).then(res => {
                        if (res && res.data && res.data.error_code == 'SUCCESSFUL') {
                            soGiayPhepDayDu = res.data.data.soGPDayDu;
                        }
                    }).catch(error => {
                        return;
                    });

                    const dataNew = {
                        ...HoSoThuLyDTO_INIT,
                        userName: C_USER_DATA_DE.userName,
                        nguoiTao: C_USER_DATA_DE.userName,
                        nguoiThuLy: C_USER_DATA_DE.userName,
                        maCoQuan: C_USER_DATA_DE.maCoQuan,
                        maLoaiGiayPhep: LOAIBIENDONG_CAPMOI.ma,
                        maHoSo: history?.location?.state?.maHoSo,
                        maThuTuc: history?.location?.state?.maThuTuc,
                        tinhTrang: TINHTRANG_HOATDONG.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                        loaiChuThe: 1,
                        // soGiayToTuyThan: dataEform?.cmnd ? dataEform?.cmnd : HoSoThuLyDTO_INIT.soGiayToTuyThan,
                        loaiGiayTo: loaiGiayTo_ChiTiet,
                        tenGiayTo: tenGiayTo_ChiTiet,
                        soGiayToTuyThan: soGiayToTuyThan_ChiTiet != null ? soGiayToTuyThan_ChiTiet : getSoGiayToTuyThan(dataEform),
                        noiCap: dataEform?.noicapcmnd,
                        ngayCap: dataEform?.ngaycapcmnd ? StringUtil.stringDateToNumber(dataEform?.ngaycapcmnd) : HoSoThuLyDTO_INIT.ngayCap,
                        //  hoTen: dataEform?.hovaten ? dataEform?.hovaten : HoSoThuLyDTO_INIT.hoTen,
                        // hoTen: chiTiet.nguoiNop != null ? chiTiet.nguoiNop : getHoTen(dataEform),
                        hoTen: hoTenNguoiNop != "" ? hoTenNguoiNop : getHoTen(dataEform),
                        ngaySinh: dataEform?.ngaythangsinh ? StringUtil.stringDateToNumber(dataEform?.ngaythangsinh) : HoSoThuLyDTO_INIT.ngaySinh,
                        hoKhau: dataEform?.text2 ? dataEform?.text2 : HoSoThuLyDTO_INIT.hoKhau,
                        choO: choOHienTai_ChiTiet != null ? choOHienTai_ChiTiet : dataEform?.noiohiennay ? dataEform?.noiohiennay : HoSoThuLyDTO_INIT.choO,
                        maQuan: maQuan_ChiTiet != null ? maQuan_ChiTiet : dataEform?.quanhuyen ? dataEform?.quanhuyen : HoSoThuLyDTO_INIT.maQuan,
                        tenQuan: tenQuan_ChiTiet != null ? tenQuan_ChiTiet : tenQuan,
                        maPhuong: maPhuong_ChiTiet != null ? maPhuong_ChiTiet : dataEform?.phuongxa ? dataEform?.phuongxa : HoSoThuLyDTO_INIT.maPhuong,
                        tenPhuong: tenPhuong_ChiTiet != null ? tenPhuong_ChiTiet: tenPhuong,
                        // dienThoai: dataEform?.sdt ? dataEform?.sdt : HoSoThuLyDTO_INIT.dienThoai,
                        dienThoai: soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt ? dataEform?.sdt : HoSoThuLyDTO_INIT.dienThoai,
                        fax: dataEform?.fax ? dataEform?.fax : HoSoThuLyDTO_INIT.fax,
                        email: dataEform?.email ? dataEform?.email : HoSoThuLyDTO_INIT.email,
                        website: dataEform?.website ? dataEform?.website : HoSoThuLyDTO_INIT.website,
                        maQuocTich: maQT != null && maQT != undefined && maQT != "" ? maQT : HoSoThuLyDTO_INIT.maQuocTich,
                        tenQuocTich: tenQT != null && tenQT != undefined && tenQT != "" ? tenQT : HoSoThuLyDTO_INIT.tenQuocTich,
                        maGioiTinh: maGT != null && maGT != undefined && maGT != "" ? maGT : maGT_ChiTiet,
                        tenGioiTinh: tenGT != null && tenGT != undefined && tenGT != "" ? tenGT : tenGT_ChiTiet,
                        soGiayPhep: soGiayPhepDayDu,
                        maSoThue: dataEform?.maSoThue ? dataEform?.maSoThue : HoSoThuLyDTO_INIT.maSoThue,
                        tenHoKinhDoanh: dataEform?.tendoanhnghiepviethoa ? dataEform?.tendoanhnghiepviethoa : HoSoThuLyDTO_INIT.tenHoKinhDoanh,
                        tongVon: dataEform?.text21 ? Number(dataEform?.text21) : HoSoThuLyDTO_INIT.tongVon,
                        soLuongLaoDong: dataEform?.text22 ? Number(dataEform?.text22) : HoSoThuLyDTO_INIT.tongVon,
                        soNha: dataEform?.text12 ? dataEform?.text12 : HoSoThuLyDTO_INIT.soNha,
                        tenDuong: diaChiNguoiNop_ChiTiet,
                    };
                    setData(dataNew);
                }
            })
                .catch((error) => {
                })
                .finally(() => {
                    dispatch(hideLoading());
                });

        }
    };

    const getHoTen = (dataEform: ThongTinChuyenNganhDTO) => {
        if (dataEform?.hovatenviethoa1 && !StringUtil.isNullOrEmty(dataEform?.hovatenviethoa1)) {
            return dataEform?.hovatenviethoa1;
        } else if (dataEform?.hovatenviethoa && !StringUtil.isNullOrEmty(dataEform?.hovatenviethoa)) {
            return dataEform?.hovatenviethoa;
        }
        return HoSoThuLyDTO_INIT.hoTen;
    }

    const getSoGiayToTuyThan = (dataEform: ThongTinChuyenNganhDTO) => {
        if (dataEform?.cmnd && !StringUtil.isNullOrEmty(dataEform?.cmnd)) {
            return dataEform?.cmnd;
        } else if (dataEform?.soGiayToDaiDien && !StringUtil.isNullOrEmty(dataEform?.soGiayToDaiDien)) {
            return dataEform?.soGiayToDaiDien;
        }
        return HoSoThuLyDTO_INIT.soGiayToTuyThan;
    }

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value }
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value,
            };
            setData(dataNew);
        }
        if (name === "refresh"){
            initData();
        }
    };
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const setDataMems = (memData: ThanhVienGopVonDTO, isEdit: Boolean, index: number) => {
        let thanhVienGopVons = data.thanhVienGopVons ? data.thanhVienGopVons : [];
        let mems = [...thanhVienGopVons];
        if (isEdit) {
            const idx = mems.findIndex((e) => e.ngayTao === memData.ngayTao);
            mems[idx] = memData;
        } else {
            let mem = { ...memData, ngayTao: new Date().getTime() };
            mems.push(mem);
        }

        let sum_tyle = mems.reduce((accumulator, current) => +accumulator + +current.tyLe, 0);
        if (sum_tyle > 100) {
            showMessageAlert("Tổng tỷ lệ của các thành viên góp vốn không được lớn hơn 100%", false);
            return;
        }

        let sum_vongop = mems.reduce((accumulator, current) => +accumulator + +current.giaTriGopVon, 0);
        if (sum_vongop > data.tongVon) {
            showMessageAlert("Tổng Giá trị phần góp vốn của thành viên góp vốn lớn hơn Tổng số vốn", false);
            return;
        }

        let dataNew = {
            ...data,
            thanhVienGopVons: mems,
        };
        setData(dataNew);
    };

    const onDeleteMems = (index: number) => {
        let mems = [...data.thanhVienGopVons];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            thanhVienGopVons: mems,
        };
        setData(dataNew);
    };

    const resetData = () => {
        // const dataNew = {
        //     ...HoSoThuLyDTO_INIT,
        //     maHoSo: history?.location?.state?.maHoSo,
        //     maThuTuc: history?.location?.state?.maThuTuc,
        //     tinhTrang: TINHTRANG_HOATDONG.ma,
        //     tenTinhTrang: TINHTRANG_HOATDONG.ten,
        //     maBienDong: LOAIBIENDONG_CAPMOI.ma,
        //     tenBienDong: LOAIBIENDONG_CAPMOI.ten,
        //     fileTrash: []
        // };
        // setData(dataNew);
        console.log(data);
    };
    const handleSubmit = () => {
        dispatchLoading(showLoading());
        if (StringUtil.isNullOrEmty(data.maHoSo)) {
            setTabRequired(-0);
            showMessageAlert("Không có mã hồ sơ", false);
            dispatchLoading(hideLoading());
            return;
        }

        setSubmit(true);
        setTabRequired(-1);
        if (
            StringUtil.isNullOrEmty(data.hoTen) ||
            data.loaiGiayTo <= 0 ||
            StringUtil.isNullOrEmty(data.soGiayToTuyThan) ||
            // data.loaiChuThe <= 0 ||
            StringUtil.isNullOrEmty(data.tenHoKinhDoanh) ||
            StringUtil.isNullOrEmty(data.maQuan) ||
            StringUtil.isNullOrEmty(data.maPhuong) ||
            StringUtil.isNullOrEmty(data.tenDuong)
        ) {
            setTabRequired(-0);
            return;
        }
        if (!ValidatorUtil.validatorSoGiayTo(data.loaiGiayTo, data.soGiayToTuyThan)) {
            setTabRequired(-0);
            return;
        }
        // if (data?.ngaySinh && data?.ngaySinh > 0) {
        //     if (!DateUtil.is18Years(data?.ngaySinh)) {
        //         showMessageAlert('Ngày sinh phải lớn hơn hoặc bằng 18 tuổi', false);
        //         setTabRequired(-0);
        //         dispatchLoading(hideLoading());
        //         return;
        //     }
        // }
        setSubmit(false);
        console.log(data);
        dangKyKinhDoanhService
            .insertOrUpdate({ ...data, diaChiKinhDo, diaChiViDo, maCoQuan: C_USER_DATA_DE.maCoQuan, chuyenNganh: DKKD_CN.value, ngayHenTra: ngayHenTra })
            .then((res) => {
                if (res != null) {
                    if (res.data.error_code == "SUCCESSFUL") {
                        showMessageAlert("Yêu cầu thực hiện thành công", true);
                    } else if (res.data.error_code == "DUPLICATE_CODE") {
                        setAlertMessage({ open: true, content: "Trùng số giấy phép", severity: "warning" });
                    } else {
                        showMessageAlert("Yêu cầu thực hiện thất bại", false);
                    }
                } else {
                    showMessageAlert("Yêu cầu thực hiện thất bại", false);
                }
            })
            .catch((error) => {
                showMessageAlert("Yêu cầu thực hiện thất bại", false);
            }).finally(() => {
                initData();
                dispatchLoading(hideLoading());
            });
    };

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert("Yêu cầu thực hiện thành công", true);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dangKyKinhDoanhService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    };

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const [locater, setLocater] = useState<any>();

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const showHidePopperThaoTac = () => {
        dispatchLoading(showLoading());
        hoSoCuaToiMgtService.getThongTinXuLy(C_USER_DATA_DE.maCoQuan, data.maHoSo).then(data => {
            const buocChuyensNew = [...data];
            setBuocChuyens(buocChuyensNew);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
        setOpen(!open);
    }

    useEffect(() => {
        loadModules(["esri/config", "esri/tasks/Locator"]).then(([esriConfig, Locator]) => {
            esriConfig.apiKey = API_KEY_MAP;
            const locater = new Locator({
                url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
            });
            setLocater(locater);
        });
    }, []);

    useEffect(() => {
        if (locater) {
            const address = {
                SingleLine: StringUtil.diaChiToString({
                    diaChiTinhName: "Thành phố Cần Thơ",
                    diaChiHuyenName: data.tenQuan,
                    diaChiXaName: data.tenPhuong,
                    duong: data.tenDuong,
                    soNha: data.soNha,
                }),
            };

            const params = {
                address: address,
                forStorage: false,
            };
            locater.addressToLocations(params).then((response: any) => {
                if (response && response.length > 0) {
                    const { location } = response[0];
                    const { longitude, latitude } = location;
                    setDiaChiKinhDo(longitude);
                    setDiaChiViDo(latitude);
                }
            });
        }
    }, [locater, data.tenQuan, data.tenPhuong, data.tenDuong, data.soNha]);

    return (
        <>
            {
                <Paper square>
                    <OverlayLoading />
                    <Snackbar
                        open={alertMessage.open}
                        onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                            {alertMessage.content}
                        </CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw
                        id="confirm-dialog-delete-dkkd"
                        keepMounted
                        open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                            <br></br>
                            <h6 style={{ fontSize: "15px", textDecoration: "underline", fontWeight: "bold" }}> */}
                        <div className="dash-content">
                            <div className="dash-title">
                                ĐĂNG KÝ THÀNH LẬP HỘ KINH DOANH - MÃ HỒ SƠ : {data.maHoSo}
                            </div>
                        </div>
                        {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange}>
                                    <CustomTab style={tabRequired == 0 ? { color: "red" } : {}} label="Thông tin chung" />
                                    {/* {data.loaiChuThe == 1 ? <CustomTab style={tabRequired == 1 ? { color: "red" } : {}} label="Thành viên góp vốn" /> : ""} */}
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {indexTab === 0 && (
                            <TabPanel value={indexTab} index={0}>
                                <ThanhLapHKDCapMoiNoiDungComponent onUpdateData={setDataParam} submit={submit} data={data}
                                showLayKetQua={showLayKetQua} 
                                />
                            </TabPanel>
                        )}
                        {/* {indexTab === 1 && (
                            <TabPanel value={indexTab} index={1}>
                                <ThanhLapHKDThanhVienGopVon onSetDataMems={setDataMems} onDeleteMems={onDeleteMems} dataList={data.thanhVienGopVons} />
                            </TabPanel>
                        )} */}

                        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                            <ThuLyButtonComponent
                                showIn={data.id > 0}
                                onSubmit={handleSubmit}
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onDelete={handleDelete}
                                handleDuThao={showHidePopperDuThao}
                                dataButton={data}
                                handleChuyen={showHidePopperThaoTac}
                            />

                            {/* <ThuLyButtonComponent
                                showIn={data.id > 0}
                                onSubmit={handleSubmit}
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onDelete={handleDelete}
                            /> */}
                        </Grid>
                    </Grid>
                </Paper>
            }
            {showDanhSachDuThaoModal && (
                <DanhSachDuThaoModal hoso={data} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}
        </>
    );
};

export default ThanhLapHKDCapMoiComponent;
