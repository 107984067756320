import { Box, FormHelperText, Grid, InputBase, InputLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { IFileWithMeta } from 'react-dropzone-uploader';
import AXIOS_INSTANCE from '../../../../../api';
import { createFileType, downLoadFile } from '../../../../../api/Common/exportUtil';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { FileServerService } from '../../../../../api/Common/FileServerService';
import { GIAY_PHEP_XAY_DUNG_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import { GiayPhepXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { DanhMucService } from '../../../../../api/danhMuc/danhMucService';
import { DanhMucDiaChiDTO } from '../../../../../api/danhMuc/model/DanhMucDTO';
import { TINH_TP } from '../../../../../api/QuanTri/DanhMucFakeService';
import DropZoneCustom from '../../../../commons/components/CustomDropzone/DropZoneCustom';
import { ACCEPT_FILES } from '../../../../Constants/constants';
import StringUtil from '../../../../utils/stringUtils';
import NhaORiengLeLoDatXayDungComponent from '../Table/NhaORiengLeLoDatXayDungComponent';
interface ThongTinDiDoiProps {
    data: GiayPhepXayDungDTO;
    submit: boolean;
    isNhapDauKy: boolean;
    onUpdateData: (name: string, value: any) => void;
    loadGiayPhepDaCap: (soGiayPhepCu: string) => void;
    resetData: () => void;
}

const ThongTinDiDoiComponent = (props: ThongTinDiDoiProps) => {
    const [data, setData] = useState({ ...GIAY_PHEP_XAY_DUNG_INIT });
    const [submit, setSubmit] = useState(false);
    const { onUpdateData, loadGiayPhepDaCap, isNhapDauKy, resetData } = props;
    const fileServerService = new FileServerService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [quanHienTais, setQuanHienTais] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongDens, setPhuongDens] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongHienTais, setPhuongHienTais] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    useEffect(() => {
        setData(props.data);
        loadQuans("92");
    }, []);
    useEffect(() => {
        setData(props.data);
        setSubmit(props.submit);
    }, [props.data, props.submit]);

    const loadQuans = (maThanhPho: string) => {
        // let diaDiemDenDTONew = {
        //     ...data.diaDiemDenDTO,
        //     ['maTinh'] : "92",
        //     ['tenTinh'] : "Thành phố Cần Thơ"
        // }
        // onUpdateData("diaDiemDenDTO", diaDiemDenDTONew);
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res && res.length > 0) {
                    setQuans(res);
                    setQuanHienTais(res);

                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadPhuongHienTais = async () => {
        if (StringUtil.isNotEmpty(data?.diaDiemHienTaiDTO?.maHuyen)) {
            await danhMucService
                .getDanhMucDiaChi(data?.diaDiemHienTaiDTO?.maHuyen)
                .then((res) => {
                    if (res && res.length > 0) {
                        setPhuongHienTais(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const loadPhuongDens = async () => {
        if (StringUtil.isNotEmpty(data?.diaDiemDenDTO?.maHuyen)) {
            await danhMucService
                .getDanhMucDiaChi(data?.diaDiemDenDTO?.maHuyen)
                .then((res) => {
                    if (res && res.length > 0) {
                        setPhuongDens(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }
    //DiaDiemDen
    const handleChangeSelectDiaDiemDenQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let diaDiemDenDTONew = {
            ...data.diaDiemDenDTO,
            [nameValue]: value?.code,
            [nameText]: valueText,
            ['maPhuong']: '',
            ['tenPhuong']: ''
        }
        onUpdateData("diaDiemDenDTO", diaDiemDenDTONew);
    }
    const handleChangeSelectDiaDiemDenPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let diaDiemDenDTONew = {
            ...data.diaDiemDenDTO,
            [nameValue]: value?.code,
            [nameText]: valueText
        }
        onUpdateData("diaDiemDenDTO", diaDiemDenDTONew);
    }

    const handleChangeDiaDiemDenDTO = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        let diaDiemDenDTONew = {
            ...data.diaDiemDenDTO,
            [e.target.name]: value
        }
        onUpdateData("diaDiemDenDTO", diaDiemDenDTONew);
    }
    const handleUpdateDiaDiemDenDTO = (name: string, value: any) => {
        let diaDiemDenDTONew = { ...data.diaDiemDenDTO, [name]: value };
        onUpdateData("diaDiemDenDTO", diaDiemDenDTONew);
    }

    //DiaDiemHienTai
    const handleChangeSelectDiaDiemHienTaiQuan = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let diaDiemHienTaiNew = {
            ...data.diaDiemHienTaiDTO,
            [nameValue]: value?.code,
            [nameText]: valueText,
            ['maPhuong']: '',
            ['tenPhuong']: ''
        }
        onUpdateData("diaDiemHienTaiDTO", diaDiemHienTaiNew);
    }
    const handleChangeSelectDiaDiemHienTaiPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let diaDiemHienTaiNew = {
            ...data.diaDiemHienTaiDTO,
            [nameValue]: value?.code,
            [nameText]: valueText
        }
        onUpdateData("diaDiemHienTaiDTO", diaDiemHienTaiNew);
    }

    const handleChangeDiaDiemHienTaiDTO = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        let diaDiemHienTaiNew = {
            ...data.diaDiemHienTaiDTO,
            [e.target.name]: value
        }
        onUpdateData("diaDiemHienTaiDTO", diaDiemHienTaiNew);
    }
    const handleUpdateDiaDiemHienTaiDTO = (name: string, value: any) => {
        let diaDiemHienTaiNew = { ...data.diaDiemHienTaiDTO, [name]: value };
        onUpdateData("diaDiemHienTaiDTO", diaDiemHienTaiNew);
    }

    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }
    return (
        <div className="dash-content">
        <>
            {
                <Box padding={0}>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={2} className="grid-item" >
                                <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>Địa điểm hiện tại</h6>
                            </Grid>
                            <Grid item xs={10} className="grid-item" >
                                <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                            </Grid>
                            <Grid item xs={4} className="grid-item" >
                                <InputLabel required>Tỉnh/Thành phố </InputLabel>
                                <InputBase disabled fullWidth value={data.diaDiemHienTaiDTO?.tenTinh} />
                            </Grid>
                            <Grid item xs={4} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete
                                    id="maHuyen"
                                    options={quanHienTais}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: data.diaDiemHienTaiDTO?.maHuyen, name: data.diaDiemHienTaiDTO?.tenHuyen }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectDiaDiemHienTaiQuan(newValue, "maHuyen", "tenHuyen");
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.diaDiemHienTaiDTO?.maHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={4} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete
                                    id="maPhuong"
                                    options={phuongHienTais}
                                    getOptionLabel={(option) => option.name}
                                    onFocus={loadPhuongHienTais}
                                    value={{ code: data.diaDiemHienTaiDTO?.maPhuong, name: data.diaDiemHienTaiDTO?.tenPhuong }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectDiaDiemHienTaiPhuong(newValue, "maPhuong", "tenPhuong");
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.diaDiemHienTaiDTO?.maPhuong) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={4} className="grid-item" >
                                <InputLabel required>Đường </InputLabel>
                                <InputBase fullWidth
                                    value={data.diaDiemHienTaiDTO?.tenDuong} name="tenDuong" onChange={handleChangeDiaDiemHienTaiDTO} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.diaDiemHienTaiDTO?.tenDuong) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập Đường </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={8} className="grid-item" >
                                <InputLabel >Số nhà, đường </InputLabel>
                                <InputBase fullWidth
                                    value={data.diaDiemHienTaiDTO?.soNha} name="soNha" onChange={handleChangeDiaDiemHienTaiDTO} />
                            </Grid>
                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Lô đất xây dựng </InputLabel>
                                <NhaORiengLeLoDatXayDungComponent data={data.diaDiemHienTaiDTO?.loDatXayDungDTOs} onUpdateData={handleUpdateDiaDiemHienTaiDTO} />
                            </Grid>


                            <Grid item xs={2} className="grid-item" >
                                <h6 style={{ fontSize: '15px', fontWeight: 'bold' }}>Địa điểm đến</h6>
                            </Grid>
                            <Grid item xs={10} className="grid-item" >
                                <hr style={{ marginTop: '10px', border: '1px solid #ced4da' }}></hr>
                            </Grid>
                            <Grid item xs={4} className="grid-item" >
                                <InputLabel required>Tỉnh/Thành phố </InputLabel>
                                <InputBase disabled fullWidth value={data.diaDiemDenDTO?.tenTinh} />
                            </Grid>
                            <Grid item xs={4} className="grid-item" >
                                <InputLabel required>Huyện/Thị/TP </InputLabel>
                                <Autocomplete id="maHuyen"
                                    options={quanHienTais}
                                    value={{ code: data.diaDiemDenDTO?.maHuyen ? data.diaDiemDenDTO?.maHuyen : "", name: data.diaDiemDenDTO?.tenHuyen ? data.diaDiemDenDTO?.tenHuyen : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectDiaDiemDenQuan(newValue, 'maHuyen', 'tenHuyen')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.diaDiemDenDTO?.maHuyen)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.diaDiemDenDTO?.maHuyen) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={4} className="grid-item" >
                                <InputLabel required>Phường/Xã/TT </InputLabel>
                                <Autocomplete id="maPhuong"
                                    options={phuongDens}
                                    onFocus={loadPhuongDens}
                                    value={{ code: data.diaDiemDenDTO?.maPhuong ? data.diaDiemDenDTO?.maPhuong : "", name: data.diaDiemDenDTO?.tenPhuong ? data.diaDiemDenDTO?.tenPhuong : "" }}
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(event: any, newValue: any) => handleChangeSelectDiaDiemDenPhuong(newValue, 'maPhuong', 'tenPhuong')}
                                    renderInput={(params) => (
                                        <TextField  {...params} variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.diaDiemDenDTO?.maPhuong)}
                                        />
                                    )}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.diaDiemDenDTO?.maPhuong) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={4} className="grid-item" >
                                <InputLabel required>Đường </InputLabel>
                                <InputBase fullWidth
                                    value={data.diaDiemDenDTO?.tenDuong} name="tenDuong" onChange={handleChangeDiaDiemDenDTO} />
                                {
                                    submit && StringUtil.isNullOrEmty(data.diaDiemDenDTO?.tenDuong) &&
                                    <FormHelperText className="-error"  >Vui lòng chọn Đường </FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={8} className="grid-item" >
                                <InputLabel >Số nhà, đường </InputLabel>
                                <InputBase fullWidth
                                    value={data.diaDiemDenDTO?.soNha} name="soNha" onChange={handleChangeDiaDiemDenDTO} />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Lô đất xây dựng </InputLabel>
                                <NhaORiengLeLoDatXayDungComponent data={data.diaDiemDenDTO?.loDatXayDungDTOs ? data.diaDiemDenDTO?.loDatXayDungDTOs : []} onUpdateData={handleUpdateDiaDiemDenDTO} />
                            </Grid>

                            <Grid item xs={12} className="grid-item" >
                                <InputLabel >Ranh giới di dời đến </InputLabel>
                                <InputBase fullWidth
                                    multiline
                                    rows="8"
                                    name="ranhGioiDiDoiDen"
                                    value={data.ranhGioiDiDoiDen} onChange={handleChange} />
                            </Grid>
                            <DropZoneCustom
                                acceptFiles={ACCEPT_FILES}
                                attachFileServer={data.files}
                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/cpxd"}
                                handleChangeStatus={handleChangeStatus}
                                handleDeleteFileServer={handleDeleteFileServer}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </Grid>
                    </Box>

                </Box>
            }
        </>
        </div>
    )
}
export default ThongTinDiDoiComponent;