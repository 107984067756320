import AXIOS_INSTANCE from "../..";
import { DanhMucI, SearchRequest } from "../../Common/model/CommonModel";

export class NguonKinhPhiService {
    constructor(){}
    search = (request : SearchRequest) => {
       return AXIOS_INSTANCE.post('kt/dm-nguon-kinh-phi/search', request);
    }
    getByHoatDong = (hoatDong : boolean) => {
       return AXIOS_INSTANCE.get('kt/dm-nguon-kinh-phi/get-by-hoat-dong/' + hoatDong);
    }
    addOrUpdate = (request : DanhMucI) => {
        return AXIOS_INSTANCE.post('kt/dm-nguon-kinh-phi/addOrUpdate', request);
     }
     delete = (id: number) => {
      return AXIOS_INSTANCE.post('kt/dm-nguon-kinh-phi/delete/'+ id);
    }
}
