import {
    Button,
    createStyles,
    Grid,
    InputBase,
    InputLabel,
    Link,
    makeStyles,
    NativeSelect,
    Snackbar,
    SvgIcon,
    TextField,
    Theme
} from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { PageRequest } from "../../../../api/baseService";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { ThongKeTinhHinhCapPhepResponse } from "../../../../api/DKKD/model/ThongKeTinhHinhCapPhepDTO";
import { DanhSachThongKeCapPhep } from "./DanhSachThongKeCapPhep";
import { ThongKeService } from "../../../../api/DKKD/ThongKeService";
import { HUYEN, PHUONG, TINH_TRANG_DKKD } from "../../../../api/QuanTri/DanhMucFakeService";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { BienDongDTO } from "../../../../api/DKKD/model/BienDongDTO";
import { useFormik } from "formik";
import { RequestBaoCaoThongKeDTO } from "../BaoCaoThongKe/BaoCaoThongKeTinhHinhCapPhep";
import { createFileType, downLoadFile } from "../../../../api/Common/exportUtil";
import { DanhMucService } from "../../../../api/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../api/danhMuc/model/DanhMucDTO";
import StringUtil from "../../../utils/stringUtils";
import { getObject } from "../../../../helpers/localStorage";
import { ChiTietThongKeCapPhep } from "./ChiTietThongKeCapPhep";

export interface RequestThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
    maQuanHuyen: string;
    maPhuongXa: string;
    tenQuanHuyen: string;
    tenPhuongXa: string;
    tinhTrang: string;
    tenTinhTrang: string;
    tuNgay: number;
    denNgay: number;
    kyThongKe: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px"
        },
    }),
);

export const ThongKeTinhHinhCapPhep = () => {
    let C_USER_DATA: any = getObject("C_USER_DATA");
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<ThongKeTinhHinhCapPhepResponse>();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'warning', open: false } as AlertMessage);
    const [openDialog, setOpenDialog] = useState(false);
    const [showDate, setShowDate] = useState(true);
    const [dataBienDong, setDataBienDong] = useState<BienDongDTO[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);

    const thongKeService = new ThongKeService();
    const [phuongTruSos, setPhuongTruSos] = useState<DanhMucDiaChiDTO[]>([]);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();

    const loadPhuongs = (maQuan: string) => {
        if (!StringUtil.isEmty(maQuan)) {
            danhMucService.getDanhMucDiaChi(maQuan).then(res => {
                setPhuongTruSos(res);
                console.log(res);
            }).catch(error => {
                console.log(error);
            });
        }
    }
    
    useEffect(() => {
        async function getDm() {
            await danhMucService.getDanhMucDiaChi(String(92)).then((resp) => {
                setQuans(resp);
            });
        }
        getDm();
        namThongKeList();
        loadDataTable();
        //loadDataBienDong();
    }, []);

    const formik = useFormik({
        initialValues: {
            request: {
                maPhuongXa: '',
                maQuanHuyen: C_USER_DATA.maQuanHuyen,
                tenPhuongXa: 'Chọn phường xã',
                tenQuanHuyen: C_USER_DATA.tenQuanHuyen,
                tinhTrang: '',
                tenTinhTrang: 'Chọn tình trạng',
                kyThongKe: 1,
                namThongKe: 0,
                tenNamThongKe: 'Chọn năm'
            } as RequestThongKeDTO,
        },
        onSubmit: () => loadDataTable(),
    });

    const [detailRequest, setDetailRequest] = useState<any>({ ...formik.values.request });

    useEffect(() => {
        setDetailRequest({...detailRequest, ...formik.values.request});
    }, [formik.values]);

    const loadDataTable = async () => {
        if (formik.values.request?.kyThongKe == 2) {
            if (formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay) {
                showMessageAlert('Từ ngày không được lớn hơn đến này', false);
                return;
            }
        }
        if (StringUtil.isEmty(formik.values.request?.maQuanHuyen)) {
            showMessageAlert('Vui lòng chọn Huyện/Thị/TP', false);
            return;
        }
        let request = {
            ...formik.values.request,
        } as RequestThongKeDTO;
        setDetailRequest({ ...formik.values.request });
        dispatchLoading(showLoading());
        await new ThongKeService().thongKe(request).then((p) => {
            setData(p);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ nam: 0, name: 'Chọn năm' }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { nam: (namHienTai - i), name: 'Năm ' + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { nam: (namHienTai + i), name: 'Năm ' + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    }

    const loadDataBienDong = async () => {
        let request = {
            ...formik.values.request
        } as RequestBaoCaoThongKeDTO;
        dispatchLoading(showLoading());
        await new ThongKeService().danhMucBienDong(request).then((p) => {
            setDataBienDong(p.rows);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'warning' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const handleChangeKyThongKe = (value: any) => {
        if (value == 1) {
            setShowDate(true);
        } else {
            setShowDate(false);
        }
    }
    const downloadFile = async (extension: string) => {
        let request = {
            ...formik.values.request,
            exportType: extension,
            exportFileName: 'Danh_sach_thong_ke_cap_phep'
        };
        dispatchLoading(showLoading());
        await new ThongKeService().downloadFileThongKe(request).then((res) => {
            downLoadFile(res.data, createFileType(extension), 'Danh_sach_thong_ke_cap_phep');
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    return (
      <div className="dash-content">
        <div className="dash-title">
          <span>THỐNG KÊ TÌNH HÌNH CẤP PHÉP KINH DOANH</span>
        </div>

        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3} className="grid-item">
              <InputLabel>Kỳ thống kê</InputLabel>
              <NativeSelect
                fullWidth
                disableUnderline
                name="kyThongKe"
                value={formik.values?.request?.kyThongKe}
                onChange={(event: any) => {
                  formik.setFieldValue("request.kyThongKe", event.target.value);
                  handleChangeKyThongKe(event.target.value);
                }}
              >
                <option value="1">Năm</option>
                <option value="2">Khoảng thời gian</option>
              </NativeSelect>
            </Grid>
            <Grid item xs={3} className="grid-item" hidden={!showDate}>
              <InputLabel>Năm </InputLabel>
              <Autocomplete
                options={dataNamThongKe}
                value={{
                  nam: formik.values?.request?.namThongKe
                    ? formik.values.request?.namThongKe
                    : 0,
                  name: formik.values?.request?.tenNamThongKe
                    ? formik.values.request?.tenNamThongKe
                    : "",
                }}
                getOptionLabel={(option) => option?.name}
                onChange={(event: any, newValue: any) => {
                  formik.setFieldValue("request.namThongKe", newValue?.nam);
                  formik.setFieldValue("request.tenNamThongKe", newValue?.name);
                }}
                renderInput={(params) => {
                  return (
                    <div
                      className="padding-top-8px"
                      ref={params.InputProps.ref}
                    >
                      <TextField {...params} variant="outlined" />
                    </div>
                  );
                }}
              />
            </Grid>
            <Grid item xs={3} className="grid-item" hidden={showDate}>
              <InputLabel>Từ ngày</InputLabel>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  showTodayButton={true}
                  placeholder="dd/MM/yyyy"
                  value={
                    formik.values.request.tuNgay > 0
                      ? new Date(formik.values.request.tuNgay)
                      : null
                  }
                  format="DD/MM/YYYY"
                  fullWidth
                  InputAdornmentProps={{ className: "button-date-picker" }}
                  variant="inline"
                  InputProps={{ disableUnderline: true }}
                  invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                  onChange={(date: any, value: any) =>
                    formik.setFieldValue(
                      "request.tuNgay",
                      date?.toDate()?.getTime()
                    )
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} className="grid-item" hidden={showDate}>
              <InputLabel>Đến ngày</InputLabel>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  showTodayButton={true}
                  placeholder="dd/MM/yyyy"
                  value={
                    formik.values.request?.denNgay > 0
                      ? new Date(formik.values.request?.denNgay)
                      : null
                  }
                  format="DD/MM/YYYY"
                  fullWidth
                  InputAdornmentProps={{ className: "button-date-picker" }}
                  variant="inline"
                  InputProps={{ disableUnderline: true }}
                  invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                  onChange={(date: any, value: any) =>
                    formik.setFieldValue(
                      "request.denNgay",
                      date?.toDate()?.getTime()
                    )
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} className="grid-item" hidden={showDate}></Grid>
            <Grid item xs={6} className="grid-item" hidden={!showDate}></Grid>
            <Grid item xs={3} className="grid-item">
              <InputLabel>Huyện/Thị/TP</InputLabel>
              {
                  StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                  <Autocomplete id="maQuanHuyen" options={quans}
                      value={{ code: formik.values?.request?.maQuanHuyen ? formik.values.request?.maQuanHuyen : "", name: formik.values?.request?.tenQuanHuyen ? formik.values.request.tenQuanHuyen : "" }}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event: any, newValue: any) => {
                          formik.setFieldValue("request.maPhuong", "");
                          formik.setFieldValue("request.tenPhuong", "");
                          formik.setFieldValue("request.maQuanHuyen", newValue?.code);
                          formik.setFieldValue("request.tenQuanHuyen", newValue?.name);
                      }}
                      renderInput={(params) => {
                          return <div className='padding-top-8px' ref={params.InputProps.ref} >
                              <InputBase fullWidth {...params.inputProps} />
                          </div>
                      }}
                  />
              }
              {
                  !StringUtil.isEmty(C_USER_DATA.maQuanHuyen) &&
                  <InputBase disabled fullWidth value={formik.values.request.tenQuanHuyen} />
              }
            </Grid>
            <Grid item xs={3} className="grid-item">
              <InputLabel>Phường/Xã/TT</InputLabel>
              <Autocomplete
                id="maPhuongXa"
                options={phuongTruSos}
                onFocus={(e) =>
                  loadPhuongs(formik.values?.request?.maQuanHuyen)
                }
                value={{
                  code: formik.values?.request?.maPhuongXa
                    ? formik.values.request?.maPhuongXa
                    : "",
                  name: formik.values?.request?.tenPhuongXa
                    ? formik.values.request.tenPhuongXa
                    : "",
                }}
                getOptionLabel={(option) => option?.name}
                onChange={(event: any, newValue: any) => {
                  formik.setFieldValue("request.maPhuongXa", newValue?.code);
                  formik.setFieldValue("request.tenPhuongXa", newValue?.name);
                }}
                renderInput={(params) => {
                  return (
                    <div
                      className="padding-top-8px"
                      ref={params.InputProps.ref}
                    >
                      <InputBase fullWidth {...params.inputProps} />
                    </div>
                  );
                }}
              />
            </Grid>

            <Grid item xs={3} className="grid-item">
              <InputLabel>Tình trạng</InputLabel>
              <Autocomplete
                options={TINH_TRANG_DKKD}
                value={{
                  ma: formik.values?.request?.tinhTrang
                    ? formik.values.request?.tinhTrang
                    : "",
                  ten: formik.values?.request?.tenTinhTrang
                    ? formik.values?.request?.tenTinhTrang
                    : "",
                }}
                getOptionLabel={(option) => option?.ten}
                onChange={(event: any, newValue: any) => {
                  formik.setFieldValue("request.tinhTrang", newValue?.ma);
                  formik.setFieldValue("request.tenTinhTrang", newValue?.ten);
                }}
                renderInput={(params) => {
                  return (
                    <div
                      className="padding-top-8px"
                      ref={params.InputProps.ref}
                    >
                      <TextField {...params} variant="outlined" />
                    </div>
                  );
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              className="grid-item"
              container
              justify="flex-end"
            >
              <InputLabel></InputLabel>
              <Button
                onClick={loadDataTable}
                type="button"
                variant="contained"
                color="primary"
                disableElevation
                className={classes.button}
              >
                Tổng hợp
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              className="grid-item"
              container
              justify="flex-end"
            >
              <Link
                title="Tải xuống"
                href="#"
                onClick={() => {
                  downloadFile("docx");
                }}
              >
                <SvgIcon
                  fontSize="large"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#007bff"
                    d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                  />
                </SvgIcon>
              </Link>
              <Link
                title="Tải xuống"
                href="#"
                onClick={() => {
                  downloadFile("pdf");
                }}
              >
                <SvgIcon fontSize="large">
                  <path
                    fill="#dc3545"
                    d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                  />
                </SvgIcon>
              </Link>
              <Link
                title="Tải xuống"
                href="#"
                onClick={() => {
                  downloadFile("xlsx");
                }}
              >
                <SvgIcon fontSize="large">
                  <path
                    fill="#28a745"
                    d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                  />
                </SvgIcon>
              </Link>
            </Grid>
            <Grid item xs={12} className="grid-item">
              <DanhSachThongKeCapPhep
                data={data}
                detailRequest={detailRequest}
                setDetailRequest={setDetailRequest}
                setOpenDialog={setOpenDialog}
              />
            </Grid>
          </Grid>
        </form>
        {openDialog && (
          <ChiTietThongKeCapPhep
            request={detailRequest}
            openDialog={openDialog}
            onDialogClose={() => {
              setOpenDialog(false);
            }}
          />
        )}
        <OverlayLoading />
        <Snackbar
          open={alertMessage.open}
          onClose={handleCloseAlertMessage}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={3000}
        >
          <CustomAlert
            onClose={handleCloseAlertMessage}
            severity={alertMessage.severity}
          >
            {alertMessage.content}
          </CustomAlert>
        </Snackbar>
      </div>
    );
}

