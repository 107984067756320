import { Animation, EventTracker, SeriesRef, Stack } from '@devexpress/dx-react-chart';
import {
    ArgumentAxis,
    BarSeries, Chart,
    Legend, ValueAxis, Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Box, createStyles, Grid, InputBase, InputLabel, makeStyles, Theme, TextField, NativeSelect, Card, CardContent } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageRequest } from '../../../api/baseService';
import { OverlayLoading } from '../../../App/commons/components/overlayLoading/overlayLoading';
import { hideLoading, LoadingAction, showLoading } from '../../../redux/actions/applicationAction';
import moment from "moment";
import { DashboardKhenThuongDTO, DashboarKhenThuongService } from '../../../api/KhenThuong/chuyenNganh/DashboarKhenThuongService';
import { OptionYears } from '../../../App/commons/components/options/optionCommons';
import { Bar } from 'react-chartjs-2';
export interface RequestThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
    maLoaiGiayPhep: string;
    tenLoaiGiayPhep: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;"
        },
    }),
);

export const DashboardBieuDoKhenThuong = () => {
    const classes = useStyles();
    const [data, setData] = useState<DashboardKhenThuongDTO[]>([]);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [year, setYear] = React.useState<number>(Number(moment().year()));
    const service = new DashboarKhenThuongService();
    const [target, setTarget] = useState<SeriesRef>();
    const [rows, setRows] = useState<DashboardKhenThuongDTO[]>([]);

    const dataCaNhan = [...rows.map((item) => item.caNhan)];
    const dataToChuc = [...rows.map((item) => item.toChuc)];
    const dataTapTheXuatSac = [...rows.map((item) => item.tapTheXuatSac)];
    const dataChienSyThiDua = [...rows.map((item) => item.chienSyThiDua)];
    const dataCoThiDua = [...rows.map((item) => item.coThiDua)];
    useEffect(() => {
        loadDataTable(year);
    }, []);
    const loadDataTable = async (year: number) => {
        setYear(year);
        dispatchLoading(showLoading());
        await service
            .loadDataDashboard(year)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setData(response.data.data);
                        setRows(response.data.data);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const changeTargetItem = (SeriesRef: SeriesRef) => {
        setTarget(SeriesRef);
    }
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };
    
    
    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Cá nhân",
                backgroundColor: "#006600",
                data: dataCaNhan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Tổ chức",
                backgroundColor: "#cccc00",
                data: dataToChuc,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Tập thể lao động xuất sắc",
                backgroundColor: "#e65c00",
                data: dataTapTheXuatSac,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Chiến sỹ thi đua",
                backgroundColor: "#003399",
                data: dataChienSyThiDua,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Tặng cờ thi đua",
                backgroundColor: "silver",
                data: dataCoThiDua,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
           
        ],
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>LĨNH VỰC THI ĐUA KHEN THƯỞNG</span>
            </div>
            <Grid item xs={3} >
                <InputLabel >Năm</InputLabel>
                <NativeSelect
                    name="year"
                    value={year}
                    onChange={(e: any) => loadDataTable(Number(e.target.value))}
                    input={<InputBase fullWidth />}
                >
                    <OptionYears />
                </NativeSelect>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <Card classes={classes}>
                    <CardContent>
                        <Bar data={dataBD} options={options} />
                    </CardContent>
                </Card>
                {/* <Paper>
                    <Chart
                        data={data}
                    >
                        <ArgumentAxis />
                        <ValueAxis
                            labelComponent={(props) => {
                                return Number.isInteger(Number(props.text)) ? <text {...props}>{Number(props.text)}</text> : <text {...props}></text>;
                            }}
                        />

                        <BarSeries
                            name="Cá nhân"
                            valueField="caNhan"
                            argumentField="tenThang"
                            color="#006600"
                        />
                        <BarSeries
                            name="Tổ chức"
                            valueField="toChuc"
                            argumentField="tenThang"
                            color="#cccc00"
                        />
                        <BarSeries
                            name="Tập thể lao động xuất sắc"
                            valueField="tapTheXuatSac"
                            argumentField="tenThang"
                            color="#e65c00"
                        />
                        <BarSeries
                            name="Chiến sỹ thi đua"
                            valueField="chienSyThiDua"
                            argumentField="tenThang"
                            color="#003399"
                        />
                        <BarSeries
                            name="Tặng cờ thi đua"
                            valueField="coThiDua"
                            argumentField="tenThang"
                            color="silver"
                        />
                        <Animation />
                        <Legend position="bottom"
                            rootComponent={(root: Legend.RootProps) => {
                                return (
                                    <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                        {root.children}
                                    </Box>
                                )
                            }}
                            itemComponent={(label: Legend.ItemProps) => {
                                return (
                                    <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                        {label.children}
                                    </Box>
                                )
                            }} />
                        <Stack />
                        <EventTracker></EventTracker>
                        <Tooltip targetItem={target} onTargetItemChange={changeTargetItem} />
                    </Chart>
                </Paper> */}
            </Grid>
            <OverlayLoading />
        </div>
    )
}

