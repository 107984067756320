import { ThanhVienGopVonDTO } from "./thanhVienGopVonDTO";
import { FileEntry } from "../../Common/fileEntry";
import { GiayPhepKinhDoanhCuDTO, GiayPhepKinhDoanhDTO } from "./giayPhepKinhDoanhDTO";
import { LichSuGiayPhepDTO } from "../../Common/model/CommonModel";
import { DanhMucDiaChiDTO } from "../../danhMuc/model/DanhMucDTO";
import { KetQuaGiayPhepDTO } from "../../Common/KetQuaGiayPhepDTO";
import { LoaiKetQuaEnum } from "../../loaiGiayPhep/loaiKetQuaEnum";


export interface NganhNgheKinhDoanh {
    nganhNgheId: number;
    maNganhNgheCap1: string;
    maNganhNgheCap2: string;
    maNganhNgheCap3: string;
    maNganhNgheCap4: string;
    maNganhNgheCap5: string;
    tenQuyDinh: string;
    tenSuDung: string;
    cap: number;
    used: boolean;
    orderBy: string;
}
export interface HoSoThuLyDTO {
    id: number;

    userName: string;

    nguoiTao: string;

    nguoiThuLy: string;

    maCoQuan?: string;

    chuyenNganh?: string;

    maHoSo: string;

    maThuTuc: string;

    hoTen: string;

    maGioiTinh: string;     //1: nam, 2: nữ, 3: khác 

    tenGioiTinh: string;

    ngaySinh: number;

    maDanToc: string;

    tenDanToc: string;

    maQuocTich: string;

    tenQuocTich: string;

    loaiGiayTo: number; // 1:cmnd, 2: căn cước, 3: hộ chiếu

    tenGiayTo: string;
    soGiayToTuyThan: string;

    ngayCap: number;

    tenNoiCap: string;

    maNoiCap: string;

    ngayHetHan: number;

    ngayCapPhep: number;

    loaiChuThe: number;  //1: nhóm cá nhân, 2: cá nhân

    tenChuThe: string;

    tenNhomCaNhan: string;

    hoKhau: string;

    choO: string;

    tenHoKinhDoanh: string;

    tongVon: number;

    soLuongLaoDong: number;

    tenDanhSachNganhNgheKinhDoanh: string;

    chiTietNganhNghe: string;

    tenLinhVuc: string;

    maLinhVuc: string;

    matHang: string;

    diaChi: ThongTinDiaChiDTO;

    readonly maQuan: string;

    readonly tenQuan: string;

    readonly maPhuong: string;

    readonly tenPhuong: string;

    maDuong: string;

    tenDuong: string;

    soNha: string;

    diaChiKinhDo: number;

    diaChiViDo: number;

    dienThoai: string;

    fax: string;

    email: string;

    website: string;

    ngayTao: number;

    ngayCapNhat: number;

    diaChiDayDu: string;

    noiSinh: string;

    chucVu: string;

    maBienDong: string;

    tenBienDong: string;

    tinhTrang: string;

    tenTinhTrang: string;

    soGiayPhep: string;

    soGiayPhepCu: string;

    ngayBienDong: number;

    lyDoThayDoi: string;

    noiDungThayDoi: string;

    giayPhepId: number;
    //ReadAndWrite
    files: FileEntry[];
    thanhVienGopVons: ThanhVienGopVonDTO[];
    nganhNgheKinhDoanhs: NganhNgheKinhDoanh[];
    //Read
    lichSuGiayPheps: LichSuGiayPhepDTO[];
    giayPhepCu?: GiayPhepKinhDoanhCuDTO;
    giayPhep: GiayPhepKinhDoanhDTO;
    checkHKTT: boolean;
    maSoThue: string;
    checkNamSinh: boolean;
    soLanCapDoi: number;
    soLanCapLai: number;
    ngayCapDoi: number;
    ngayCapLai: number;

    tuNgay: number;
    denNgay: number;
    noiNhan: string;
    nhomCaNhans: NhomCaNhanDTO[];

    ketQuaGiayPhep?: KetQuaGiayPhepDTO;
    
    loaiKetQua: LoaiKetQuaEnum;
    ngayHenTra: number;

}

export interface NhomCaNhanDTO {
    id: number;
    tenThanhVien: string;
    tenQuocTich: string;
    maQuocTich: string;
    diaChiLienLac: string;
    diaChiThuongTru: string;
    soGiayToPhapLy: string;
    ghiChu: string;
    hoSoThuLyId: number;
}

export interface ThongTinDiaChiDTO {

    maDuong: String;
    tenDuong: String;

    maPhuongXa: String;
    tenPhuongXa: String;

    maQuanHuyen: String;
    tenQuanHuyen: String;

    maTinhThanh: String;
    tenTinhThanh: String;

    soDienThoai?: String;
    fax?: String;
    mail?: String;
}

export const initialValuesDiaChi: ThongTinDiaChiDTO = {

    maDuong: "",
    tenDuong: "",

    maPhuongXa: "",
    tenPhuongXa: "",

    maQuanHuyen: "",
    tenQuanHuyen: "",

    maTinhThanh: "92",
    tenTinhThanh: "Thành phố Cần Thơ",

    mail: "",
    fax: "",
    soDienThoai: "",
};

export const iniNhomCaNhanDTO: NhomCaNhanDTO = {
    id: -1,
    tenThanhVien: '',
    tenQuocTich: 'Việt Nam',
    maQuocTich: 'VN',
    diaChiLienLac: '',
    diaChiThuongTru: '',
    soGiayToPhapLy: '',
    ghiChu: '',
    hoSoThuLyId: 0
};