import React, { Dispatch, useEffect, useState } from 'react';
import { Box, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { ConfirmationDialogRaw } from "../../../../commons/components/dialog/confirmDialog";
import { CustomTab, CustomTabs } from '../../../../commons/components/tabs';
import { HoSoThuLyDTO } from "../../../../../api/DKKD/model/hoSoThuLyDTO";
import { DangKyKinhDoanhService } from "../../../../../api/DKKD/DangKyKinhDoanhService";
import { ThongTinChungComponent } from './ThongTinChungComponent';
import { ThanhVienGopVon } from '../../Modal/thanhVienGopVon';
import { FileEntry } from '../../../../../api/Common/fileEntry';
import { useLocation } from 'react-router';
import StringUtil from '../../../../utils/stringUtils';
import { createFileType, downLoadFile } from "../../../../../api/Common/exportUtil";
import { BieuMauAttachFileDTO } from '../../../../../api/QuanTri/Model/danhMucBieuMau';
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { useDispatch } from "react-redux";
import { BrowserHistory } from 'history';
import { AlertMessage, CustomAlert } from "../../../../commons/components/alert";
import { OverlayLoading } from "../../../../commons/components/overlayLoading/overlayLoading";
import { SearchGiayPhepDTO } from "../../../../../api/CPBLTL/model/SearchGiayPhepDTO";
import { HoSoThuLyDTO_INIT } from '../../../../../api/DKKD/init/HoSoThuLyInit';
import { LOAIBIENDONG_TAMNGUNG, TINHTRANG_HOATDONG, TINHTRANG_TAMNGUNG } from '../../../../Constants/DKKDConstants';
import ThuLyButtonComponent from '../../../../commons/components/hoso/thuly/ThuLyButtonComponent';
import { UserData } from '../../../../../api/userMgt/userManagementService';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

type StateHoSo = {
    maThuTuc: string,
    maHoSo: string
}

type TamNgungHKDProps = {
    history: BrowserHistory<StateHoSo>
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const TamNgungHoatDongHKD = (props: TamNgungHKDProps) => {

    const [indexTab, setIndexTab] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState({} as HoSoThuLyDTO);
    const location = useLocation();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const [openSoGiayPhepCu, setOpenSoGiayPhepCu] = useState(false);
    const [listGiayPhep, setListGiayPhep] = useState<SearchGiayPhepDTO[]>([]);
    const { history } = props;
    let C_USER_DATA_DE = {} as UserData;
    if (localStorage.getItem("C_USER_DATA") != null) {
        C_USER_DATA_DE = JSON.parse(localStorage.getItem("C_USER_DATA") as any) as UserData;
    }
    const dangKyKinhDoanhService = new DangKyKinhDoanhService();

    useEffect(() => {
        initData();
        loadGiayPhep();
    }, []);

    const initData = () => {
        dispatchLoading(showLoading());
        let maHoSo = location?.state?.maHoSo;
        dangKyKinhDoanhService.getByMaHoSo({ "maHoSo": maHoSo }).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                let resData = {
                    ...res?.data?.data,
                    maLoaiGiayPhep: LOAIBIENDONG_TAMNGUNG.ma,
                    tinhTrang: TINHTRANG_TAMNGUNG.ma,
                    tenTinhTrang: TINHTRANG_TAMNGUNG.ten,
                    maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                    tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                };
                setData(resData);
                if (res?.data?.data.soGiayPhepCu) {
                    setOpenSoGiayPhepCu(true);
                }
            } else {
                let newData = {
                    ...HoSoThuLyDTO_INIT,
                    userName: C_USER_DATA_DE.userName,
                    nguoiTao: C_USER_DATA_DE.userName,
                    nguoiThuLy: C_USER_DATA_DE.userName,
                    maCoQuan: C_USER_DATA_DE.maCoQuan,
                    maHoSo: history?.location?.state?.maHoSo,
                    maThuTuc: history?.location?.state?.maThuTuc,
                    maLoaiGiayPhep: LOAIBIENDONG_TAMNGUNG.ma,
                    tinhTrang: TINHTRANG_TAMNGUNG.ma,
                    tenTinhTrang: TINHTRANG_TAMNGUNG.ten,
                    maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                    tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                    files: new Array<FileEntry>()
                }
                setData(newData);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const handleSubmit = () => {
        if (StringUtil.isNullOrEmty(data.maHoSo)) {
            setTabRequired(-0);
            showMessageAlert('Không có mã hồ sơ', false);
            return;
        }

        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.maHoSo)
            || StringUtil.isNullOrEmty(data.soGiayPhepCu)
            || StringUtil.isNullOrEmty(data.lyDoThayDoi)
            || data.ngayBienDong === 0
            || data.ngayBienDong < data.ngayCapPhep) {
            setTabRequired(0);
            return;
        }
        setSubmit(false);
        dangKyKinhDoanhService.insertOrUpdate(data).then(res => {
            if (res != null) {
                if (res.data.error_code == 'SUCCESSFUL') {
                    setDataParam('data', res.data.data);
                    showMessageAlert('Yêu cầu thực hiện thành công', true);
                } else {
                    showMessageAlert(res.data.error_message, false);
                }
            } else {
                showMessageAlert('Yêu cầu thực hiện thất bại', false);
            }
        }).catch(error => {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        });
    }

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const loadGiayPhepDaCap = (soGiayPhep: string) => {
        dangKyKinhDoanhService.getInfoAutoBySoGiayPhep(soGiayPhep, TINHTRANG_HOATDONG.ma)
            .then((res) => {
                let dataNew = res?.data.data;
                if (dataNew !== null && dataNew !== undefined) {
                    setData({
                        ...dataNew,
                        id: data.id,
                        files: data.files,
                        soGiayPhepCu: soGiayPhep,
                        maHoSo: history?.location?.state?.maHoSo,
                        maThuTuc: history?.location?.state?.maThuTuc,
                        maLoaiGiayPhep: LOAIBIENDONG_TAMNGUNG.ma,
                        tinhTrang: TINHTRANG_TAMNGUNG.ma,
                        tenTinhTrang: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                        lyDoThayDoi: data.lyDoThayDoi,
                        ngayBienDong: data.ngayBienDong,
                        noiDungThayDoi: data.noiDungThayDoi
                    });
                    setOpenSoGiayPhepCu(true);
                } else {
                    setAlertMessage({ open: true, content: "Số giấy phép không tồn tại trong dữ liệu", severity: "warning" });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }
    const resetData = () => {
        const dataNew = {
            ...HoSoThuLyDTO_INIT,
            maLoaiGiayPhep: LOAIBIENDONG_TAMNGUNG.ma,
            tinhTrang: TINHTRANG_TAMNGUNG.ma,
            tenTinhTrang: TINHTRANG_TAMNGUNG.ten,
            maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
            tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
            id: data.id,
            maHoSo: history?.location?.state?.maHoSo,
            maThuTuc: history?.location?.state?.maThuTuc,
            fileTrash: data?.files,
            files: new Array<FileEntry>()
        }
        setData(dataNew);
    }
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            resetData();
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dangKyKinhDoanhService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo, soGiayPhep: data.soGiayPhep }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    }

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const loadGiayPhep = () => {
        if (listGiayPhep != null && listGiayPhep.length == 0) {
            dangKyKinhDoanhService.autocompleteBySoGiayPhep('', TINHTRANG_HOATDONG.ma, 10).then(res => {
                let dataAutocompleNew = res?.data.data;
                if (dataAutocompleNew !== null && dataAutocompleNew !== undefined && dataAutocompleNew.length > 0) {
                    setListGiayPhep([...dataAutocompleNew]);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    return (
        <>
            {
                <Paper square >
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd"
                        keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}>
                                TẠM NGỪNG HOẠT ĐỘNG HỘ KINH DOANH - MÃ HỒ SƠ :  {data.maHoSo}</h6>
                        </Grid>
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                    <CustomTab label="Thành viên góp vốn" />
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <ThongTinChungComponent
                                    onUpdateData={setDataParam}
                                    loadGiayPhepDaCap={loadGiayPhepDaCap}
                                    submit={submit}
                                    data={data}
                                    listGiayPhep={listGiayPhep}
                                    openSoGiayPhepCu={openSoGiayPhepCu} />

                                <Grid container direction="row">
                                    <Grid style={{ float: 'left' }} item xs={12} className="grid-item">
                                        <ThuLyButtonComponent
                                            showIn={data.id > 0}
                                            onSubmit={handleSubmit}
                                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                            onDelete={handleDelete}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        }
                        {
                            indexTab === 1 &&
                            <TabPanel value={indexTab} index={1}>
                                <ThanhVienGopVon dataList={data?.nhomCaNhans} />
                            </TabPanel>
                        }
                    </Grid>
                </Paper>
            }
        </>
    )
}
