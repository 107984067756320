
export  const thongTinChuyenNganhConstants = {
    NGAYTHANGHIENTAI : "ngaythanghientai",
    CMND : "cmnd",
    NGAYCAPCMND : "ngaycapcmnd",
    NOICAPCMND : "noicapcmnd",
    HOKHAUTHUONGTRU : "hokhauthuongtru",
    NOIOHIENNAY : "noiohiennay",
    HOVATEN : "hovaten",
    HOVATENVIETHOA : "hovatenviethoa",
    NGAYTHANGSINH : "ngaythangsinh",
    NGAYHIENTAI : "ngayhientai",
    THANGHIENTAI : "thanghientai",
    NAMHIENTAI : "namhientai",
    QUOCTICH : "quoctich",
    TINHTHANH : "tinhthanh",
    QUANHUYEN : "quanhuyen",
    XAPHUONG : "xaphuong",
    SDT : "sdt",
    FAX : "fax",
    EMAIL : "email",
    GIOITINH : "gioitinh",
    DIACHI : "diachi",
    KINHGUI : "kinhgui",
    TENDOANHNGHIEP : "tendoanhnghiep",
    DIACHIDOANHNGHIEP : "diachidoanhnghiep",
    WEBSITE : "website",
    GIAYDKKD : "giaydkkd",
    COQUANCAPDKKD : "coquancapdkkd",
    NGAYCAPDKKD : "ngaycapdkkd",
    NOICAPDKKD : "noicapdkkd",
    HOTENNGUOIDAIDIEN : "hotennguoidaidien",
    CHUCVUNGUOIDAIDIEN : "chucvunguoidaidien",
    DIADIEM : "diadiem",
    PHONE : "phone",
    SOGIAYTODAIDIEN : "sogiaytotuythannguoidaidien",
    MASOTHUE : "masothue",
    TEXT2: "text2",
    TENDOANHNGHIEPVIETHOA : "tendoanhnghiepviethoa",
    TEXT21: "text21",
    TEXT22: "text22",
    PHUONGXA : "phuongxa",
    HOVATENVIETHOA1: "hovatenviethoa1",
    TEXT12: "text12",
    
}
