import { ChiTietHangMucDTO, HangMucXayDungDTO } from '../../../../../api/CPXD/model/CapPhepXayDungModel';
import { HangMucXayDungDTO_INIT } from '../../../../../api/CPXD/init/CapPhepXayDungInit';
import StringUtil from '../../../../utils/stringUtils';
import { DialogContent, DialogTitle } from '../../../../commons/components/dialog/dialogCommons';
import React, { useEffect } from 'react';
import { Box, Button, Checkbox, Dialog, FormControlLabel, FormHelperText, Grid, InputAdornment, InputBase, InputLabel } from '@material-ui/core';
import ChiTietHangMucTable from '../Table/ChiTietHangMucTable';
interface HangMucModalProps {
    data: HangMucModalDataProps;
    onToggle: () => void;
    onAddData: (value: HangMucXayDungDTO) => void;
    onUpdateData: (index: number, value: HangMucXayDungDTO) => void;
}
interface HangMucModalDataProps {
    data: HangMucXayDungDTO;
    show: boolean;
    indexEdit: number;
}
const HangMucModal = (props: HangMucModalProps) => {
    const [data, setData] = React.useState({ ...HangMucXayDungDTO_INIT });
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [indexEdit, setIndexEdit] = React.useState(-1);
    const { onToggle, onAddData, onUpdateData } = props;
    useEffect(() => {
        setData(props.data.data);
        setShow(props.data.show);
        setIndexEdit(props.data.indexEdit);
        setSubmit(false);
    }, [props.data]);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData(data => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }
    const handleChangeNumber = (e: any) => {
        
        // if (Number.isNaN(Number.parseInt(value))) {
        //     value = 0;
        // }
        // if (Number.parseInt(value) < 0) {
        //     return;
        // }
        // setData(data => ({
        //     ...data,
        //     [e.target.name]: Number.parseInt(value)
        // }));
        let value = e.target.value;
        if(StringUtil.allowNumberOrDot(value)){
            let valueString : string =  StringUtil.getNumberDot(value);
            setData(data => ({
                ...data,
                [e.target.name] : valueString
            }));    
        }


    }
    const handleSubmit = () => {
        setSubmit(true);
        if (validateFrom()) {
            if (indexEdit >= 0) {
                onUpdateData(indexEdit, data);
            } else {
                onAddData(data);
            }
            onToggle();
        }
    }

    const validateFrom = (): boolean => {
        let valid: boolean = true;
        // if (StringUtil.isNullOrEmty(data.hangMuc)) {
        //     valid = false;
        // }
        //HienLT52 set 0 when value null
        if(StringUtil.isNullOrEmty(data.chieuCaoTangMot)){
            data.chieuCaoTangMot = 0;
        }
        if(StringUtil.isNullOrEmty(data.dienTichTangMot)){
            data.dienTichTangMot = 0;
        }
        if(StringUtil.isNullOrEmty(data.tongDienTichSan)){
            data.tongDienTichSan = 0;
        }
        if(StringUtil.isNullOrEmty(data.chieuCaoCongTrinh)){
            data.chieuCaoCongTrinh = 0;
        }
        if(StringUtil.isNullOrEmty(data.matDatXayDung)){
            data.matDatXayDung = 0;
        }
        if(StringUtil.isNullOrEmty(data.chieuSauCongTrinh)){
            data.chieuSauCongTrinh = 0;
        }
        return valid;
    }
    const handleChiTietAdd = (rowNew: ChiTietHangMucDTO) => {
        let chiTietHangMucDTOsNew = [...data.chiTietHangMucDTOs];
        chiTietHangMucDTOsNew.push(rowNew);
        setData({ ...data, chiTietHangMucDTOs: chiTietHangMucDTOsNew });
    }
    const handleChiTietDrop = (index: number) => {
        let chiTietHangMucDTOsNew = [...data.chiTietHangMucDTOs];
        chiTietHangMucDTOsNew.splice(index, 1);
        setData({ ...data, chiTietHangMucDTOs: chiTietHangMucDTOsNew });
    }
    const handleChiTietUpdate = (index: number, value: ChiTietHangMucDTO) => {
        let chiTietHangMucDTOsNew = [...data.chiTietHangMucDTOs];
        chiTietHangMucDTOsNew[index] = value;
        setData({ ...data, chiTietHangMucDTOs: chiTietHangMucDTOsNew });

    }
    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show} >
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        {indexEdit >= 0 ? 'CẬP NHẬT HẠNG MỤC XÂY DỰNG' : 'THÊM MỚI HẠNG MỤC XÂY DỰNG'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel >Hạng mục</InputLabel>
                                    <InputBase fullWidth
                                        name="hangMuc"
                                        // error={submit && StringUtil.isNullOrEmty(data.hangMuc)}
                                        value={data.hangMuc} onChange={handleChange} />
                                    {/* {
                                        submit && StringUtil.isNullOrEmty(data.hangMuc) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập hạng mục</FormHelperText>
                                    } */}
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Màu sắc</InputLabel>
                                    <InputBase fullWidth
                                        name="mauSac"
                                        value={data.mauSac} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={data.congTrinhChinh}
                                            onChange={handleChange}
                                            value={data.congTrinhChinh}
                                            name="congTrinhChinh"
                                            color="primary"

                                        />}
                                        label="Là công trình chính"
                                        style={{ marginTop: '20px' }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Số tầng</InputLabel>
                                    <InputBase fullWidth
                                        name="soTang"
                                        value={data.soTang} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <InputLabel>Kết cấu</InputLabel>
                                    <InputBase fullWidth
                                        name="ketCau"
                                        value={data.ketCau} onChange={handleChange} />
                                </Grid>
                                
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Chiều cao tầng một</InputLabel>
                                    <InputBase fullWidth
                                        name="chieuCaoTangMot"
                                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data.chieuCaoTangMot ? data.chieuCaoTangMot.toString() : "")} onChange={handleChangeNumber} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Công thức</InputLabel>
                                    <InputBase fullWidth
                                        name="congThuc"
                                        value={data.congThuc} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Diện tích xây dựng tầng một</InputLabel>
                                    <InputBase fullWidth
                                        name="dienTichTangMot"
                                        value={StringUtil.viewNumberDot(data.dienTichTangMot ? data.dienTichTangMot.toString() : "")} onChange={handleChangeNumber}
                                        endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Tổng DT sàn </InputLabel>
                                    <InputBase fullWidth
                                        name="tongDienTichSan"
                                        endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data.tongDienTichSan ? data.tongDienTichSan.toString() : "")} onChange={handleChangeNumber} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Chiều cao công trình </InputLabel>
                                    <InputBase fullWidth
                                        name="chieuCaoCongTrinh"
                                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data.chieuCaoCongTrinh ? data.chieuCaoCongTrinh.toString() : "")} onChange={handleChangeNumber} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Mật độ xây dựng</InputLabel>
                                    <InputBase fullWidth
                                        name="matDatXayDung"
                                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data.matDatXayDung ? data.matDatXayDung.toString() : "")} onChange={handleChangeNumber} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Hệ số sử dụng đất </InputLabel>
                                    <InputBase fullWidth
                                        name="heSoSuDungDat"
                                        value={data.heSoSuDungDat} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Nội dung khác / Ghi chú </InputLabel>
                                    <InputBase fullWidth
                                        name="ghiChu"
                                        value={data.ghiChu} onChange={handleChange} />
                                </Grid>
                                
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Chiều sâu công trình </InputLabel>
                                    <InputBase fullWidth
                                        name="chieuSauCongTrinh"
                                        endAdornment={<InputAdornment position="end">m</InputAdornment>}
                                        value={StringUtil.viewNumberDot(data.chieuSauCongTrinh ? data.chieuSauCongTrinh.toString() : "")} onChange={handleChangeNumber} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                <InputLabel>Thông tin các tầng khác</InputLabel>
                                    <ChiTietHangMucTable data={data.chiTietHangMucDTOs}
                                        handleAdd={handleChiTietAdd}
                                        handleDrop={handleChiTietDrop}
                                        handleUpdate={handleChiTietUpdate} />
                                </Grid>
                                <Grid item xs={12} className="grid-item" >
                                    <Box marginTop="5px">
                                        <Button variant="contained" color="primary" disableElevation
                                            onClick={handleSubmit}>
                                            Lưu
                                </Button>
                                        <Button variant="contained" color="default" disableElevation
                                            onClick={onToggle}>
                                            Đóng
                                </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>

    )
}
export default HangMucModal;