import React, { useEffect, useRef, useState } from 'react';
import { BrowserHistory } from "history";
import { Box, Button, FormHelperText, Grid, InputBase, InputLabel, Paper, Snackbar, Typography } from "@material-ui/core";
import { CustomTab, CustomTabs } from "../../../commons/components/tabs";
import { BaseResponse } from "../../../../api/baseService";
import { AlertMessage, CustomAlert } from "../../../commons/components/alert";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { TapDinhKem } from "../Modal/tapDinhKem";
import { ThanhVienGopVon } from "../Modal/thanhVienGopVon";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { BienDong, BienDongTrangThaiGPKDDTO, TinhTrangString } from "../../../../api/DKKD/model/BienDongTrangThaiGPKDDTO";
import {
    BienDongTrangThaiGPKDResponse,
    BienDongTrangThaiGPKDService
} from "../../../../api/DKKD/BienDongTrangThaiGPKDService";
import ThongTinChungGPComponent from "../Modal/ThongTinChungGPComponent";
import { HoSoThuLyDTO } from "../../../../api/DKKD/model/hoSoThuLyDTO";
import { DangKyKinhDoanhService } from "../../../../api/DKKD/DangKyKinhDoanhService";
import { ConfirmationDialogRaw } from "../../../commons/components/dialog/confirmDialog";
import { LOAIBIENDONG_TAMNGUNG, TINHTRANG_TAMNGUNG } from '../../../Constants/DKKDConstants';
import DropZoneCustom from '../../../commons/components/CustomDropzone/DropZoneCustom';
import { FileEntry } from '../../../../api/Common/fileEntry';
import { FileServerService } from '../../../../api/Common/FileServerService';
import { createFileType, downLoadFile } from '../../../../api/Common/exportUtil';
import { IFileWithMeta } from 'react-dropzone-uploader';
import AXIOS_INSTANCE from '../../../../api';
import { BieuMauAttachFileDTO } from '../../../../api/QuanTri/Model/danhMucBieuMau';
import InBieuMauButton from "./InBieuMauButton";

type StateHoSo = {
    hoSoThuLyId: string,
}

type NhaORiengLeProps = {
    history: BrowserHistory<StateHoSo>
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
interface FormValues {
    requestDTO: BienDongTrangThaiGPKDDTO,
}

const TamNgungGiayPhepKinhDoanh = (props: NhaORiengLeProps) => {
    const [indexTab, setIndexTab] = React.useState(0);
    const [data, setData] = React.useState({} as HoSoThuLyDTO);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const autoCompleteRef = useRef<HTMLDivElement>(null);
    const bienDongTrangThaiGPKDService = new BienDongTrangThaiGPKDService();
    const dangKyKinhDoanhService = new DangKyKinhDoanhService();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const fileServerService = new FileServerService();
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);
    const acceptFiles = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

    const { history } = props;

    const validateionSchema = Yup.object().shape({
        requestDTO: Yup.object().shape({
            noiDung: Yup.string().required('Vui lòng nhập lý do tạm ngưng!'),
            ngayBienDong: Yup.number().test(
                "test-batbuoc",
                "Vui lòng chọn ngày tạm ngưng giấy phép!",
                function (value) {
                    return value !== 0 ? true : false;
                }
            ).test(
                "test-data",
                "Vui lòng chọn ngày tạm ngưng sau ngày cấp phép!",
                function (value) {
                    return (value && value > data.ngayCapPhep) ? true : false;
                }
            ).required('Vui lòng chọn ngày tạm ngưng giấy phép!')
        })
    });

    const initDefaultValue: FormValues = {
        requestDTO: {
            hoSoThuLyId: data?.id,
            giayPhepId: data?.giayPhepId,
            soGiayPhep: data?.soGiayPhep,
            ngayBienDong: 0,
            ngayQuyetDinh: 0,
            soQuyetDinh: '',
            ghiChu: "",
            noiDung: "",
            id: 0,
            maTinhTrang: TINHTRANG_TAMNGUNG.ma,
            tenTinhTrang: TINHTRANG_TAMNGUNG.ten,
            maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
            tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
            files: [],
            tuNgay: 0,
            denNgay: 0,
            tenQuan: "",
            maQuan: "",
            maPhuong: "",
            tenPhuong: "",
            noiNhan: "",
        } as BienDongTrangThaiGPKDDTO
    }

    const formik = useFormik({
        initialValues: initDefaultValue,
        validationSchema: validateionSchema,
        onSubmit: () => submitData(),
    });

    useEffect(() => {
        initData();
    }, []);

    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            setDataFiles([...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry, index: number) {
        onDeleteFiles(item, index);
    }

    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...formik.values.requestDTO?.files];
        mems = mems.concat(memData);
        formik.setFieldValue("requestDTO.files", mems);
    }

    const onDeleteFiles = (item: FileEntry, index: number) => {
        let mems = [...formik.values.requestDTO?.files];
        mems.splice(index, 1);
        setAttachFileServer(mems);
        formik.setFieldValue("requestDTO.files", mems);
    }

    const handleDownloadFile = (file: FileEntry) => {
        if (file.motCua && file.url){
            let link = document.createElement("a");
            link.href = file?.url ? file?.url : "";
            link.setAttribute("download", `${file.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(file?.url ? file?.url : "");
        }else{
            fileServerService.downloadFileMethodGet(file).then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            });
        }
    }

    const initData = () => {
        var temp = { hoSoThuLyId: history?.location?.state?.hoSoThuLyId };
        dangKyKinhDoanhService.getChiTietGPKDById(temp).then(res => {
            if (res?.data?.data) {
                setData(res?.data?.data);
                formik.setValues({
                    requestDTO: {
                        hoSoThuLyId: res?.data?.data?.id,
                        giayPhepId: res?.data?.data?.giayPhepId,
                        soGiayPhep: res?.data?.data?.soGiayPhep,
                        ngayBienDong: 0,
                        ngayQuyetDinh: 0,
                        soQuyetDinh: '',
                        ghiChu: "",
                        noiDung: "",
                        id: 0,
                        maTinhTrang: TINHTRANG_TAMNGUNG.ma,
                        tenTinhTrang: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                        files: [],
                        tuNgay: 0,
                        denNgay: 0,
                        tenQuan: "",
                        maQuan: "",
                        maPhuong: "",
                        tenPhuong: "",
                        noiNhan: "",
                    } as BienDongTrangThaiGPKDDTO,
                });
            } else {
                const dataNew = {} as HoSoThuLyDTO;
                setData(dataNew);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const submitData = async () => {
        await bienDongTrangThaiGPKDService.luuVaCapNhatKhoiPhuc(formik.values.requestDTO).then(res => {
            handleSubmitData(res);
        });
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const handleSubmitData = (resp: BaseResponse) => {
        if (resp.isSuccess) {
            if (formik.values.requestDTO.id > 0) {
                formik.setFieldValue("requestDTO", resp.data);
            } else {
                formik.setFieldValue("requestDTO", resp.data);
            }
            showMessageAlert('Yêu cầu thực hiện thành công!');
        } else {
            showMessageAlert('Yêu cầu thực hiện thất bại', false);
        }
    }
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            formik.resetForm({ values: initDefaultValue, });
            showMessageAlert('Yêu cầu thực hiện thành công', true);
        }
        setOpenDeleteUserDlg(false);
    }

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dangKyKinhDoanhService.downloadFile({ templateId: bieuMau.fileId, maHoSo: data.maHoSo ? data.maHoSo : '', soGiayPhep: data.soGiayPhep }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName);
        });
    }

    return (
        <>
            {
                <Paper square >
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                    <ConfirmationDialogRaw id="confirm-dialog-delete-dkkd" keepMounted open={openDeleteUserDlg}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <Grid container>
                        {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <br></br>
                            <h6 style={{ fontSize: '15px', textDecoration: 'underline', fontWeight: 'bold' }}> */}
                            <div className="dash-content">
                                <div className="dash-title">
                                    TẠM NGỪNG HOẠT ĐỘNG GIẤY PHÉP KINH DOANH ĐÃ CẤP
                                </div>
                            </div>
                                {/* </h6>
                            <br></br>
                        </Grid> */}
                        <br />
                        <Grid style={{ float: 'left' }} item xs={12} className="grid-item" >
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChange} >
                                    <CustomTab label="Thông tin chung" />
                                    <CustomTab label="Thành viên góp vốn" />
                                    {/* <CustomTab label="Tập đính kèm" /> */}
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {
                            indexTab === 0 &&
                            <TabPanel value={indexTab} index={0}>
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className="grid-item">
                                            <ThongTinChungGPComponent data={data} />
                                        </Grid>
                                        <Grid item xs={6} className="grid-item">
                                            <InputLabel required>Lý do tạm ngưng</InputLabel>
                                            <InputBase fullWidth
                                                error={formik.touched.requestDTO?.noiDung && Boolean(formik.errors.requestDTO?.noiDung)}
                                                value={formik.values.requestDTO?.noiDung} name="requestDTO.noiDung"
                                                onChange={formik.handleChange} />
                                            {
                                                formik.touched.requestDTO?.noiDung && Boolean(formik.errors.requestDTO?.noiDung) &&
                                                <FormHelperText className="-error"
                                                >{formik.errors.requestDTO?.noiDung}</FormHelperText>
                                            }
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel required>Ngày tạm ngưng</InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    placeholder="dd/MM/yyyy"
                                                    value={formik.values.requestDTO?.ngayBienDong > 0 ? new Date(formik.values.requestDTO?.ngayBienDong) : null}
                                                    error={formik.touched.requestDTO?.ngayBienDong && Boolean(formik.errors.requestDTO?.ngayBienDong)}
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => formik.setFieldValue("requestDTO.ngayBienDong", date?.toDate()?.getTime())}
                                                />
                                            </MuiPickersUtilsProvider>
                                            {
                                                formik.touched.requestDTO?.ngayBienDong && Boolean(formik.errors.requestDTO?.ngayBienDong) &&
                                                <FormHelperText className="-error"
                                                >{formik.errors.requestDTO?.ngayBienDong}</FormHelperText>
                                            }
                                        </Grid>
                                        <Grid item xs={3} className="grid-item"></Grid>
                                        <Grid item xs={12} className="grid-item"></Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <InputLabel >Thời gian tạm ngưng</InputLabel>
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel>Từ ngày</InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    placeholder="dd/MM/yyyy"
                                                    value={formik.values.requestDTO?.tuNgay > 0 ? new Date(formik.values.requestDTO?.tuNgay) : null}
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => formik.setFieldValue("requestDTO.tuNgay", date?.toDate()?.getTime())}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel>Đến ngày</InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    placeholder="dd/MM/yyyy"
                                                    value={formik.values.requestDTO?.denNgay > 0 ? new Date(formik.values.requestDTO?.denNgay) : null}
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => formik.setFieldValue("requestDTO.denNgay", date?.toDate()?.getTime())}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <InputLabel >Ghi chú</InputLabel>
                                            <InputBase fullWidth
                                                multiline
                                                rows="4"
                                                value={formik.values.requestDTO?.ghiChu} name="requestDTO.ghiChu" onChange={formik.handleChange} />
                                        </Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <InputLabel>Nơi nhận</InputLabel>
                                            <InputBase rows={5} multiline fullWidth value={formik.values.requestDTO?.noiNhan} name="requestDTO.noiNhan" onChange={formik.handleChange} />
                                        </Grid>
                                        <Grid item xs={12} container className="grid-item">
                                            <DropZoneCustom
                                                acceptFiles={`${acceptFiles}`}
                                                attachFileServer={attachFileServer}
                                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dkkd"}
                                                handleChangeStatus={handleChangeStatus}
                                                handleDeleteFileServer={handleDeleteFileServer}
                                                handleDownloadFile={handleDownloadFile}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="gridItem">
                                            <Box marginTop="5px">
                                                <Button type="submit" variant="contained" color="primary" disableElevation
                                                > Lưu
                                                </Button>
                                                <InBieuMauButton onDownloadFileBieuMau={handleDownloadFileBieuMau} />
                                                <Button variant="contained" color="secondary" disableElevation

                                                    onClick={() => {
                                                        if (autoCompleteRef.current) {
                                                            let clearButton = autoCompleteRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
                                                            clearButton.click();
                                                        }
                                                        handleDelete();
                                                    }}
                                                > Xóa
                                                </Button>
                                                <Button type="button" variant="contained" color="primary"
                                                    onClick={(e) => history.go(-1)}
                                                    style={{ fontSize: '14px', width: '100px' }}>Trở về
                                                </Button>&nbsp;&nbsp;
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </TabPanel>
                        }
                        {
                            indexTab === 1 &&
                            <TabPanel value={indexTab} index={1}>
                                <ThanhVienGopVon dataList={data?.nhomCaNhans} />
                            </TabPanel>

                        }
                        {/* {
                            indexTab === 2 &&
                            <TabPanel value={indexTab} index={2}>
                                <TapDinhKem dataList={data?.files} />
                            </TabPanel>
                        } */}
                    </Grid>
                    <OverlayLoading />
                    <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                    </Snackbar>
                </Paper>
            }
        </>
    )
}
export default TamNgungGiayPhepKinhDoanh;
