import { Animation, Stack } from '@devexpress/dx-react-chart';
import {
    ArgumentAxis,
    BarSeries, Chart,
    Legend, Title, ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import { Box, Button, createStyles, Grid, InputBase, InputLabel, makeStyles, Theme, TextField, Card, CardContent, NativeSelect } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageRequest } from "../../../../api/baseService";
import { ThongKeService } from "../../../../api/VSATTP/Services/ThongKeService";
import { BieuDoThongkeRespone } from '../../../../api/VSATTP/Model/VSATTPModel';
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../commons/components/overlayLoading/overlayLoading";
import { Bar, Pie } from "react-chartjs-2";
import { RequestThongKeDTO } from "../ThongKe/ThongKeTinhHinhCapPhep";
import { AlertMessage } from '../../../commons/components/alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from "@date-io/moment";

export interface RequestBieuDoThongKeDTO extends PageRequest {
    namThongKe: number;
    kyThongKe: number;
    tuNgay: number;
    denNgay: number;
    tenNamThongKe: string;
    loaiBieuDo: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;"
        },
    }),
);

export const BieuDoThongKeATTP = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<BieuDoThongkeRespone[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [rows, setRows] = useState<BieuDoThongkeRespone[]>([]);
    const [showBD, setShowBD] = useState(true);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'warning', open: false } as AlertMessage);
    const dataConHan = [...rows.map((item) => item.tongConHan)];
    const dataHetHan = [...rows.map((item) => item.tongHetHan)];
    const [dataBDTron, setDataBDTron] = useState<BieuDoThongkeRespone>();
    const [showDate, setShowDate] = useState(true);

    useEffect(() => {
        formik.setValues({
            request: {
                kyThongKe: 1,
                namThongKe: new Date().getUTCFullYear(),
                tenNamThongKe: 'Năm ' + new Date().getUTCFullYear()
            } as RequestBieuDoThongKeDTO,
        });
        namThongKeList();
        if (showBD) {
            execute();
            setShowDate(true);
        } else {
            executeBDTron();
        }
    }, [showBD]);

    const formik = useFormik({
        initialValues: {
            request: {
                namThongKe: new Date().getUTCFullYear(),
                tenNamThongKe: 'Năm ' + new Date().getUTCFullYear()
            } as RequestBieuDoThongKeDTO,
        },
        onSubmit: () => execute(),
    });

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'warning' });
    }

    const executeBDTron = async () => {
        if (formik.values.request?.kyThongKe == 2) {
            if (formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay) {
                showMessageAlert('Từ ngày không được lớn hơn đến này', false);
                return;
            }
        }
        let request = {
            ...formik.values.request,
        } as RequestBieuDoThongKeDTO;
        dispatchLoading(showLoading());

        await new ThongKeService().thongKeBDTron(request).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setDataBDTron(res?.data?.data);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const execute = async () => {
        console.log("execute");
        let request = {
            ...formik.values.request,
        } as RequestBieuDoThongKeDTO;
        dispatchLoading(showLoading());
        await new ThongKeService().bieuDoThongke(request).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setData(res?.data?.data);
                setRows(res?.data?.data);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Đang hoạt động",
                backgroundColor: "#51A3A3",
                data: dataConHan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Hết hạn",
                backgroundColor: "#EAD94C",
                data: dataHetHan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },

        ],
    };
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };

    const optionsBDTron = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            position: "bottom",
        },
        layout: {
            padding: 10,
        },
        type: "pie",
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem: any, data: any) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ nam: 0, name: 'Chọn năm' }];
        for (let i = namHienTai; i >= namHienTai - 5; --i) {
            var temp = { nam: i, name: 'Năm ' + i };
            temps.push(temp);
        }
        setDataNamThongKe(temps);
    }

    const handleChangeLoaiBieuDo = (value: any) => {
        if (value == 1) {
            setShowBD(true);
            setShowDate(true);
        } else {
            setShowBD(false);
        }
    }

    const handleButtonBD = () => {
        if (showBD) {
            execute();
        } else {
            executeBDTron();
        }
    }

    const handleChangeKyThongKe = (value: any) => {
        if (value == 1) {
            setShowDate(true);
        } else {
            setShowDate(false);
        }
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN VSATTP</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel >Loại biểu đồ</InputLabel>
                        <NativeSelect fullWidth disableUnderline name="loaiBieuDo" value={formik.values?.request?.loaiBieuDo} onChange={
                            (event: any) => {
                                formik.setFieldValue("request.loaiBieuDo", event.target.value);
                                handleChangeLoaiBieuDo(event.target.value);
                            }
                        } >
                            <option value="1">Biểu đồ thống kê</option>
                            <option value="2">Biểu đồ tròn</option>
                        </NativeSelect>
                    </Grid>
                    {showBD &&
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Năm </InputLabel>
                            <Autocomplete options={dataNamThongKe}
                                value={{ nam: formik.values?.request?.namThongKe ? formik.values.request?.namThongKe : 0, name: formik.values?.request?.tenNamThongKe ? formik.values.request?.tenNamThongKe : "" }}
                                getOptionLabel={(option) => option?.name}
                                onChange={(event: any, newValue: any) => {
                                    formik.setFieldValue("request.namThongKe", newValue?.nam);
                                    formik.setFieldValue("request.tenNamThongKe", newValue?.name);
                                }}
                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <InputBase fullWidth
                                            {...params.inputProps}

                                        />
                                    </div>
                                }}
                            />
                        </Grid>
                    }
                    {!showBD &&
                        <Grid item xs={3} className="grid-item">
                            <InputLabel >Kỳ thống kê</InputLabel>
                            <NativeSelect fullWidth disableUnderline name="kyThongKe" value={formik.values?.request?.kyThongKe} onChange={
                                (event: any) => {
                                    formik.setFieldValue("request.kyThongKe", event.target.value);
                                    handleChangeKyThongKe(event.target.value);
                                }
                            } >
                                <option value="1">Năm</option>
                                <option value="2">Khoảng thời gian</option>
                            </NativeSelect>
                        </Grid>
                    }
                    {!showBD &&
                        <Grid item xs={3} className="grid-item" hidden={!showDate}>
                            <InputLabel >Năm </InputLabel>
                            <Autocomplete options={dataNamThongKe}
                                value={{ nam: formik.values?.request?.namThongKe ? formik.values.request?.namThongKe : 0, name: formik.values?.request?.tenNamThongKe ? formik.values.request?.tenNamThongKe : "" }}
                                getOptionLabel={(option) => option?.name}
                                onChange={(event: any, newValue: any) => {
                                    formik.setFieldValue("request.namThongKe", newValue?.nam);
                                    formik.setFieldValue("request.tenNamThongKe", newValue?.name);
                                }}

                                renderInput={(params) => {
                                    return <div className='padding-top-8px' ref={params.InputProps.ref} >
                                        <TextField  {...params} variant="outlined" />
                                    </div>
                                }}
                            />
                        </Grid>
                    }
                    {!showBD &&
                        <Grid item xs={3} className="grid-item" hidden={showDate}>
                            <InputLabel >Từ ngày</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    placeholder="dd/MM/yyyy"
                                    value={formik.values.request.tuNgay > 0 ? new Date(formik.values.request.tuNgay) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => formik.setFieldValue("request.tuNgay", date?.toDate()?.getTime())}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    }
                    {!showBD &&
                        <Grid item xs={3} className="grid-item" hidden={showDate}>
                            <InputLabel >Đến ngày</InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    placeholder="dd/MM/yyyy"
                                    value={formik.values.request?.denNgay > 0 ? new Date(formik.values.request?.denNgay) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => formik.setFieldValue("request.denNgay", date?.toDate()?.getTime())}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    }
                    {/* <Grid item xs={6} className="grid-item">
                        <InputLabel >Loại giấy phép</InputLabel>
                        <Autocomplete
                            value={{ ma: formik.values?.request?.maLoaiGiayPhep ? formik.values.request?.maLoaiGiayPhep : "", ten: formik.values?.request?.tenLoaiGiayPhep ? formik.values.request?.tenLoaiGiayPhep : "" }}
                            options={LIST_LOAIGIAYPHEP}
                            getOptionLabel={(option: any) => option.ten}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maLoaiGiayPhep", newValue?.ma);
                                formik.setFieldValue("request.tenLoaiGiayPhep", newValue?.ten);
                            }}
                            renderInput={(params) => (
                                <TextField  {...params} variant="outlined" />
                            )}
                    />     
                   </Grid>              */}
                    <Grid item xs={3} className="grid-item">
                        <Button onClick={handleButtonBD} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tổng hợp
                        </Button>
                    </Grid>

                    {showBD &&
                        <Grid item xs={12} className="grid-item">
                            <Card classes={classes}>
                                <CardContent>
                                    <Bar data={dataBD} options={options} />
                                </CardContent>
                            </Card>
                            {/* <Paper>
                            <Chart
                            data ={data}
                            >
                                <ArgumentAxis />
                                <ValueAxis />

                                <BarSeries
                                    name="Còn hạn"
                                    valueField="tongConHan"
                                    argumentField="thang"
                                    color="#00c292"
                                />
                                <BarSeries
                                    name="Hết hạn"
                                    valueField="tongHetHan"
                                    argumentField="thang"
                                    color="orange"
                                />
                                <Animation />
                                <Legend position="bottom"
                                    rootComponent={(root: Legend.RootProps) => {
                                        return (
                                            <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                                {root.children}
                                            </Box>
                                        )
                                    }}
                                    itemComponent={(label: Legend.ItemProps) => {
                                        return (
                                            <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                                {label.children}
                                            </Box>
                                        )
                                    }} />
                                <Stack />
                            </Chart>
                        </Paper> */}
                        </Grid>
                    }
                    {!showBD &&
                        <Grid item xs={12} className="grid-item">
                            <Paper  >
                                <Card >
                                    {/* <CardHeader title={"HỒ SƠ ĐÃ TIẾP NHẬN (" + dataTinhHinhXuLy.daTiepNhan + ")"} style={{ backgroundColor: "#f2f2f2", fontSize: "1em" }}></CardHeader> */}
                                    <CardContent >
                                        <Pie
                                            height={120}
                                            data={{
                                                labels: ["Đang hoạt động (" + dataBDTron?.tongConHan + ")", "Sắp hết hạn (" + (dataBDTron?.tongSapHetHan) + ")", "Hết hạn (" + (dataBDTron?.tongHetHan) + ")"],
                                                datasets: [
                                                    {
                                                        data: [dataBDTron?.tongConHan, dataBDTron?.tongSapHetHan, dataBDTron?.tongHetHan],
                                                        backgroundColor: ["#51A3A3", "#EAD94C", "#eb5050",]
                                                        //hoverBackgroundColor: chartColors
                                                    }
                                                ]
                                            }} options={optionsBDTron}
                                        />

                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>
                    }
                </Grid>
            </form>
            <OverlayLoading />
        </div >
    )
}

